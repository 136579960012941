import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, IconButton, Chip } from '@material-ui/core';
import InputBase from 'components/input/Form';
import StyledButton from 'components/button/Base';
import { useServiceList } from 'hooks/service';
import {
    convertFloatingToDateTime,
    convertMinsToHrsMins,
    getFriendlyWeekDate,
    isSameDate,
    mapTimeToTime12
} from 'utils/timing';
import { useSelectedDate } from 'hooks/calendar';
import * as calendarActions from 'views/calender/actions';
import { useDispatch } from 'react-redux';
import {
    BOOKING_SERVICE_STATES,
    BOOKING_SERVICE_STATUS_TO_LABEL,
    BOOKING_STATES,
    getBookingServiceStatusColor,
    getBookingStatusColor,
    LIMIT_BOOKING_HISTORY
} from 'const';
import Tooltip from 'components/tooltip';
import SearchIcon from '@material-ui/icons/Search';
import { useClientBookingsByClientId, useClientBookingsStatisticByClientId } from 'hooks/client';
import { getBookingServiceStatus } from 'utils/app/booking';
import { useMerchantCurrency } from 'hooks/merchant';
import { getFullName } from 'utils/naming';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import * as clientActions from 'views/clients/actions';
import ShowCase from 'components/statistic/ShowCase';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
    root: {},
    expand: {
        flex: 1
    },
    tableCell: {
        border: 'none',
        lineHeight: `1.5rem`,
        fontSize: `0.875rem`,
        fontFamily: `"Public Sans", sans-serif`,
        fontWeight: 600,
        display: `table-cell`,
        verticalAlign: 'inherit',
        textAlign: 'left',
        padding: '16px',
        borderBottom: 'none',
        color: `rgb(99, 115, 129)`,
        backgroundColor: theme.colors.hover,
        '&:first-of-type': {
            paddingLeft: '24px',
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
            boxShadow: `rgb(255 255 255) 8px 0px 0px inset`
        },
        '&:last-of-type': {
            paddingLeft: '24px',
            borderTopRightRadius: '8px',
            borderBottomRightRadius: '8px',
            boxShadow: `rgb(255 255 255) -8px 0px 0px inset`
        }
    },
    input: {
        color: 'black'
    }
}));

function ClientLogs({ client, clientId, onClose, isEdit, booking }) {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const selectedDate = useSelectedDate();
    const [isEditNote, setIsEditNote] = React.useState(false);
    const [clientNote, setClientNote] = React.useState(isEdit ? booking?.client?.note : '');

    const services = useServiceList();
    const currency = useMerchantCurrency();

    const statistic = useClientBookingsStatisticByClientId(clientId);
    const bookings = useClientBookingsByClientId(clientId);

    const clientNoteDefault = React.useMemo(() => {
        return client?.client?.note;
    }, [client]);

    React.useEffect(() => {
        setClientNote(clientNoteDefault);
    }, [clientNoteDefault]);

    const findBooking = React.useCallback(
        ({ bkId, date }) => {
            if (!isSameDate({ date1: date, date2: selectedDate })) {
                dispatch(calendarActions.setSelectedDate(date));
            }

            dispatch(calendarActions.setFindBookingId(bkId));
            onClose && onClose();
        },
        [onClose, dispatch, selectedDate]
    );

    const TABLE_COLUMNS = React.useMemo(() => {
        return [
            {
                width: 220,
                label: 'Start Time',
                id: 'startTime'
            },
            {
                width: 350,
                label: 'Service',
                id: 'serviceAndStaffAndDuration',
                format: ({ value, rowData }) => (
                    <div>
                        <div>{value}</div>
                        <Typography variant="caption">{rowData?.note}</Typography>
                    </div>
                )
            },
            {
                width: 50,
                label: `Price`,
                id: 'price'
            },
            {
                width: 30,
                label: `Link`,
                id: 'bkId',
                format: ({ value, rowData }) =>
                    [BOOKING_SERVICE_STATES.CANCELED, BOOKING_SERVICE_STATES.NO_SHOW]?.includes(
                        rowData?.bkStatus
                    ) ? null : (
                        <Tooltip title="Jump to booking">
                            <IconButton onClick={() => findBooking({ bkId: value, date: rowData?.date })} size="small">
                                <SearchIcon />
                            </IconButton>
                        </Tooltip>
                    )
            }
        ];
    }, [findBooking]);

    const bookingServices = React.useMemo(() => {
        const result = [];

        for (let booking of bookings) {
            const isFuture = booking?.isFuture;
            const bkServices = booking?.bookingServices || [];
            const isCancel = booking?.state === BOOKING_STATES.CLIENT_CANCEL;

            bkServices.forEach((service) => {
                const serviceDetail = services?.find((sv) => +sv?.id === +service?.serviceId);
                const bookingServiceStatus = service?.status;
                const bkStatus =
                    isFuture &&
                    ![BOOKING_SERVICE_STATES.CANCELED, BOOKING_SERVICE_STATES.NO_SHOW].includes(bookingServiceStatus)
                        ? 8
                        : isCancel
                        ? BOOKING_SERVICE_STATES.CANCELED
                        : bookingServiceStatus;

                const serviceBody = {
                    bkStatus,
                    date: booking?.startDate,
                    startTime: (
                        <Typography variant="body2" display="inline">
                            {mapTimeToTime12(convertFloatingToDateTime(service?.startTime))},{' '}
                            {getFriendlyWeekDate(booking?.startDate)}{' '}
                            <Chip
                                size="small"
                                style={{
                                    background: getBookingStatusColor?.[bkStatus],
                                    color: theme.palette.common.white
                                }}
                                label={getBookingServiceStatus(bkStatus)}
                            />
                        </Typography>
                    ),
                    price: `${currency}${service?.price}`,
                    serviceAndStaffAndDuration: `${convertMinsToHrsMins(service?.duration, 'h')} ${
                        serviceDetail?.title
                    } ${service?.love ? `❤️` : `with`} ${getFullName(service?.staff)}`,
                    bkId: booking?.id,
                    note: booking?.note
                };

                result.push(serviceBody);
            });
        }

        return result?.slice(0, LIMIT_BOOKING_HISTORY);
    }, [services, bookings, theme, currency]);

    const options = React.useMemo(() => {
        return [
            {
                title: 'Bookings',
                amount: statistic?.allBookings,
                color: getBookingServiceStatusColor?.[BOOKING_SERVICE_STATUS_TO_LABEL?.[BOOKING_SERVICE_STATES.ARRIVED]]
            },
            {
                title: 'Completed',
                amount: statistic?.completed,
                color: getBookingServiceStatusColor?.[
                    BOOKING_SERVICE_STATUS_TO_LABEL?.[BOOKING_SERVICE_STATES.COMPLETED]
                ]
            },
            {
                title: 'Canceled',
                amount: statistic?.canceled,
                color: getBookingServiceStatusColor?.[
                    BOOKING_SERVICE_STATUS_TO_LABEL?.[BOOKING_SERVICE_STATES.CANCELED]
                ]
            },
            {
                title: 'No Shows',
                amount: statistic?.noShow,
                color: getBookingServiceStatusColor?.[BOOKING_SERVICE_STATUS_TO_LABEL?.[BOOKING_SERVICE_STATES.NO_SHOW]]
            }
        ];
    }, [statistic]);

    const handleClientNoteChange = React.useCallback((e) => {
        setClientNote(e.target.value);
    }, []);

    const handleEditNote = React.useCallback(() => {
        if (!clientNote) return;
        const bodyUpdate = {
            note: clientNote,
            firstName: client?.client?.firstName
        };

        dispatch(
            clientActions.updateClient({
                clientId,
                body: bodyUpdate,
                successCallback: () => {
                    setIsEditNote(false);
                },
                errorCallback: (error) => {
                    setIsEditNote(false);
                }
            })
        );
    }, [clientId, clientNote, dispatch, client]);

    return (
        <Grid container style={{ position: 'relative', marginTop: theme.spacing(3) }} direction="column">
            {!client?.client?.isSystem && (
                <Grid item style={{ marginBottom: theme.spacing(2) }}>
                    <Grid container direction="row" alignItems="flex-end" spacing={2}>
                        <Grid item className={classes.expand}>
                            <InputBase
                                multiline
                                maxRows={6}
                                label={
                                    <Grid container direction="row" alignItems="center" spacing={1}>
                                        <Grid item>
                                            <Typography variant="body1">Notes</Typography>
                                        </Grid>
                                        <StyledButton
                                            size="small"
                                            color="primary"
                                            onClick={!isEditNote ? () => setIsEditNote(true) : handleEditNote}
                                            variant="contained"
                                            startIcon={!isEditNote ? <EditIcon /> : <SaveIcon />}
                                        >
                                            {!isEditNote ? 'Edit' : 'Save'}
                                        </StyledButton>
                                    </Grid>
                                }
                                name="note"
                                value={clientNote || ''}
                                disabled={!isEditNote}
                                onChange={handleClientNoteChange}
                                classes={{
                                    input: classes.input
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}

            <Grid item>
                <Grid container direction="column" spacing={1}>
                    <Grid item>
                        <Grid container direction="row" spacing={3} wrap="nowrap">
                            {options.map((option) => (
                                <Grid xs={3} key={option?.title} item>
                                    <ShowCase {...option} />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Paper
                    elevation={0}
                    style={{
                        pt: 2,
                        width: '100%',
                        overflow: 'hidden',
                        background: theme.palette.common.white,
                        color: `rgb(33, 43, 54)`,
                        backgroundImage: 'none',
                        position: 'relative',
                        zIndex: 0,
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <Grid item>
                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow sx={{}}>
                                        {TABLE_COLUMNS?.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                className={classes.tableCell}
                                                style={{ minWidth: column.minWidth }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {bookingServices?.length === 0 && (
                                        <TableRow sx={{ textAlign: 'center' }}>
                                            <TableCell style={{ border: 'none' }}>No histories</TableCell>
                                        </TableRow>
                                    )}
                                    {bookingServices?.map((row) => {
                                        return (
                                            <TableRow
                                                sx={{
                                                    '&:hover': {
                                                        cursor: 'pointer',
                                                        background: `${theme.colors.hover} !important`
                                                    }
                                                }}
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={row.id}
                                            >
                                                {TABLE_COLUMNS.map((column) => {
                                                    const value = row?.[column.id];

                                                    return (
                                                        <TableCell
                                                            style={{ border: 'none' }}
                                                            key={column.id}
                                                            align={column.align}
                                                        >
                                                            {column.format
                                                                ? column.format({
                                                                      rowData: row,
                                                                      value
                                                                  })
                                                                : value}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}

export default ClientLogs;
