import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Slider, Typography } from '@material-ui/core';
import { useZoomSettingValue } from 'hooks/calendar';
import { useDispatch } from 'react-redux';
import * as calendarActions from 'views/calender/actions';
import { useTranslation } from 'react-i18next';
import IOSSwitcher from 'components/switch/IOS';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: `10px 30px`
    }
}));

function SlideZoom({ isVertical = false, isClosedDate, toggleCloseDate }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const zoomReduxValue = useZoomSettingValue();

    const marks = React.useMemo(
        () => [
            {
                value: 0,
                label: t(`large`)
            },
            {
                value: 1,
                label: t(`medium`)
            },
            {
                value: 2,
                label: t(`small`)
            }
        ],
        [t]
    );

    const dispatch = useDispatch();
    const [value, setValue] = React.useState(1);
    const timer = React.useRef();

    React.useEffect(() => {
        setValue(zoomReduxValue);
    }, [zoomReduxValue]);

    const handleChange = (e, valueNumber) => {
        if (timer.current) clearTimeout(timer.current);
        setValue(valueNumber);
        const roundedNumber = Math.round(valueNumber);
        timer.current = setTimeout(() => {
            if (roundedNumber < 1) {
                dispatch(calendarActions.updateZoomSetting(0));
            } else if (roundedNumber >= 1 && roundedNumber < 2) {
                dispatch(calendarActions.updateZoomSetting(1));
            } else {
                dispatch(calendarActions.updateZoomSetting(2));
            }
        }, 100);
    };

    const handleToggleCloseDate = React.useCallback(() => {
        dispatch(calendarActions.toggleCloseDate());
    }, [dispatch]);

    return (
        <Grid className={classes.root} container direction="column" wrap="nowrap" spacing={3}>
            <Grid item container direction="column">
                <Grid item>
                    <Typography id="zoom-setting" gutterBottom variant="body1">
                        {t(`zoom_setting`)}
                    </Typography>
                </Grid>

                <Grid item style={{ width: isVertical ? 'auto' : 200, height: isVertical ? 200 : 'auto' }}>
                    <Slider
                        value={value}
                        min={0}
                        step={0.1}
                        max={2}
                        scale={(x) => x ** 10}
                        onChange={handleChange}
                        aria-labelledby="non-linear-slider"
                        marks={marks}
                    />
                </Grid>
            </Grid>
            {isClosedDate && (
                <Grid item>
                    <Grid container direction="row" alignItems="center" spacing={2}>
                        <Grid item>
                            <IOSSwitcher onChange={handleToggleCloseDate} checked={toggleCloseDate} />
                        </Grid>
                        <Grid item>
                            <Typography id="zoom-setting" gutterBottom variant="body2">
                                {t(`turn_off_close_date`)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
}

export default React.memo(SlideZoom);
