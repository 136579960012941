import React from 'react';
import BookingTabs from './Tabs';

import { useDispatch } from 'react-redux';
import * as staffActions from 'views/staff/action';
import * as serviceActions from 'views/services/action/service';

function Bookings() {
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(
            staffActions.getStaff({
                successCallback: () => {
                    console.log('getStaff success');
                },
                errorCallback: () => {
                    console.log('getStaff failed');
                }
            })
        );

        dispatch(
            serviceActions.getServices({
                successCallback: () => {
                    console.log('getServices success');
                },
                errorCallback: () => {
                    console.log('getServices failed');
                }
            })
        );
    }, [dispatch]);

    return <BookingTabs />;
}

export default React.memo(Bookings);
