import React from 'react';
import { makeStyles, alpha } from '@material-ui/core/styles';
import { Grid, Box, Typography } from '@material-ui/core';
import { useStaffById } from 'hooks/staff';
import { useServicesMapping } from 'hooks/service';
import { getFullName } from 'utils/naming';
import { useMerchantTime12 } from 'hooks/merchant';
import { mapTimeToTime12, convertFloatingToDateTime, convertDurationAndStartTimeToDate } from 'utils/timing';
import { DEFAULT_COLOR } from 'utils/color';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
        background: theme.palette.common.white,
        borderRadius: 4
    }
}));

function BookingService({ id, serviceId, staffId, startTime, duration, love }) {
    const classes = useStyles();
    const staffMapping = useStaffById(staffId);
    const servicesMapping = useServicesMapping();
    const TIME_12 = useMerchantTime12();

    const staffDetail = React.useMemo(() => {
        return staffMapping?.[staffId];
    }, [staffMapping, staffId]);

    const serviceDetail = React.useMemo(() => {
        return servicesMapping?.[serviceId];
    }, [serviceId, servicesMapping]);

    const color = React.useMemo(() => {
        return serviceDetail?.category?.color || DEFAULT_COLOR;
    }, [serviceDetail]);
    return (
        <Box
            className={classes.root}
            style={{
                borderLeft: `4px solid ${color}`,
                background: alpha(color, 0.8)
            }}
        >
            <Grid container direction="column">
                <Grid item>
                    <Typography variant="body2" className={classes.timeBooking} align="left">
                        {`${
                            TIME_12
                                ? mapTimeToTime12(convertFloatingToDateTime(startTime))
                                : convertFloatingToDateTime(startTime)
                        } - ${
                            TIME_12
                                ? mapTimeToTime12(convertDurationAndStartTimeToDate(startTime, duration))
                                : convertDurationAndStartTimeToDate(startTime, duration)
                        }`}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body2">
                        {serviceDetail?.title} with {love && '❤️'} {getFullName(staffDetail)}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
}

export default BookingService;
