import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BORDER_RADIUS, INPUT_HEIGHT } from 'const/style';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'block',
        fontSize: 15,
        color: '#444',
        cursor: 'pointer',
        lineHeight: 1.3,
        borderRadius: BORDER_RADIUS,
        height: (props) => (props.fullHeight ? '100%' : INPUT_HEIGHT),
        paddingRight: `1.4em`,
        paddingLeft: `0.8em`,
        width: '100%',
        maxWidth: `100%` /* useful when width is set to anything other than 100% */,
        boxSizing: `border-box`,
        margin: 0,
        border: (props) => (props.noBorder ? 'none' : `1px solid #ced4da`),
        appearance: `none`,
        backgroundColor: (props) => (props?.disabled ? 'transparent' : theme.palette.common.white),
        backgroundImage: `url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E')`,
        backgroundRepeat: `no-repeat, repeat`,
        /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
        backgroundPosition: `right .7em top 50%, 0 0`,
        /* icon size, then gradient */
        backgroundSize: `.65em auto, 100%`,
        '&::-ms-expand': {
            display: `none`
        },
        '&:hover': {
            borderColor: `#888`
        },
        '&:focus': {
            borderColor: `#aaa`,
            /* It'd be nice to use -webkit-focus-ring-color here but it doesn't work on box-shadow */
            color: `#222`,
            outline: 'none'
        },
        '& option': {
            fontWeight: `normal`
        }
    },
    label: {
        fontSize: 12,
        fontFamily: `Roboto, sans-serif, apple-system, BlinkMacSystemFont, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei",SimSun`,
        fontWeight: 700,
        lineHeight: `1.75`,
        color: theme.colors.secondaryText,
        textTransform: 'uppercase'
    }
}));

function OptionSelect({ options, label, selectedValue, onChange, noBorder, className, fullHeight, disabled }) {
    const classes = useStyles({ noBorder, fullHeight, disabled });

    return (
        <>
            {label && <div className={classes.label}>{label}</div>}
            <select
                className={`${classes.root} ${className}`}
                onChange={(e) => {
                    onChange(options.find((opt) => opt.value === e.target.value));
                }}
                value={selectedValue}
            >
                {options.map((option) => {
                    return (
                        <option disabled={option?.disabled} key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    );
                })}
            </select>
        </>
    );
}

export default React.memo(OptionSelect);
