import request from './request';
import isEmpty from 'lodash/isEmpty';
import { objectToQueryParams } from 'utils/queryParams';

const checkoutApis = {
    checkouts: `/checkouts`,
    delete: (id) => `/checkouts/${id}`,
    analyze: `/checkouts/analyze`,
    print: (id) => `/checkouts/${id}/print`,
    printLongBill: `/checkouts/print-long`,
    findCheckoutByGiftCodeId: (gcId) => `/checkouts/find-checkout/${gcId}`
};

export async function findCheckoutByGiftCodeIdApi(gcId) {
    const response = await request.get(`${checkoutApis.findCheckoutByGiftCodeId(gcId)}`);
    return response?.data;
}

export async function createCheckoutApi(body) {
    const response = await request.post(checkoutApis.checkouts, body);
    return response.data;
}

export async function deleteCheckoutApi(checkoutId) {
    const response = await request.delete(checkoutApis.delete(checkoutId));
    return response.data;
}

export async function getAnalyzeCheckoutApi(query) {
    let extraUrl = checkoutApis.analyze;
    if (!isEmpty(query)) {
        extraUrl += `?${objectToQueryParams(query)}`;
    }
    const response = await request.get(extraUrl);
    return response.data;
}

export async function getCheckoutsApi(query) {
    let extraUrl = checkoutApis.checkouts;
    if (!isEmpty(query)) {
        extraUrl += `?${objectToQueryParams(query)}`;
    }
    const response = await request.get(extraUrl);
    return response.data;
}

export async function printCheckoutApi(checkoutId) {
    const response = await request.post(checkoutApis?.print(checkoutId));
    return response?.data;
}

export async function printLongCheckoutApi(query) {
    let extraUrl = checkoutApis?.printLongBill;
    if (!isEmpty(query)) {
        extraUrl += `?${objectToQueryParams(query)}`;
    }

    const response = await request.post(extraUrl);
    return response?.data;
}
