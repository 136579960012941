import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, InputBase, Dialog } from '@material-ui/core';
import LayoutTabContent from 'views/layout/LayoutTabContent';
import ContentLayout from '../layout/ContentLayout';
import Table from 'components/table/NewTable';
import { PADDING_LIST_ITEM_LEFT_RIGHT } from 'const/style';
import { PAGINATION_OPTIONS_PER_PAGE, PAGINATION_LIMIT_DEFAULT, SMS_TOPIC_STATUS } from 'const';
import CloseSVG from 'assets/images/svg/CloseSVG';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import AddSms from './addSms';
import ConfirmDialog from 'components/dialog/Confirm';
import * as actionSchedule from './action';
import { useDispatch } from 'react-redux';
import { useSmsSchedule } from 'hooks/sms';
import { getFriendlyTime } from 'utils/timing';
import NativeSelect from 'components/select/Native';
import Chip from '@material-ui/core/Chip';
import { useSmsRemaining } from 'hooks/merchant';
import * as merchantActions from 'merchant/actions';
import { OPERATOR } from 'const/condition';
import { columnTypes } from 'const/columnTypes';
import AddIcon from '@material-ui/icons/AddCircle';
import Button from 'components/button/Base';

const STATUS_LABEL = {
    0: 'Waiting',
    1: 'In progress',
    2: 'Sent',
    3: 'Stuck'
};

function getColorByStatusCode(status, theme) {
    switch (status) {
        case 0:
            return theme.palette.primary.light;
        case 1:
            return theme.colors.creamBrulee;
        case 2:
            return theme.colors.waterLeaf;
        case 3:
            return theme.colors.red;
        default:
            return theme.palette.primary.light;
    }
}

const useStyles = makeStyles((theme) => ({
    root: {
        overflowY: 'hidden',
        height: '100%',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            maxHeight: `-webkit-fill-available`,
            marginTop: 0
        }
    },
    wrapContent: {
        height: '100%',
        overflowY: 'hidden',
        backgroundColor: theme.palette.common.white,
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            marginBottom: 0
        }
    },

    header: {
        padding: theme.spacing(2)
    },

    wrapInput: {
        position: 'relative'
    },

    customInput: {
        'label + &': {
            color: theme.colors.secondaryText
        },
        backgroundColor: theme.palette.common.white,
        paddingLeft: PADDING_LIST_ITEM_LEFT_RIGHT,
        paddingRight: 22,
        borderRadius: 3,
        height: 35,
        fontSize: 14,
        fontWeight: 'normal',
        color: theme.colors.primaryText,
        width: '100%'
    },
    clearIcon: {
        position: 'absolute',
        cursor: 'pointer',
        right: 5,
        top: 7,
        zIndex: 9000
    },
    phones: {
        maxHeight: 45,
        overflowY: 'auto'
    }
}));

const Actions = ({ value, rowData, t }) => {
    const dispatch = useDispatch();

    const [isOpenEditSmsDialog, setIsOpenEditSmsDialog] = React.useState(false);
    const handleOpenEditSmsDialog = ({ smsSchedule }) => {
        setIsOpenEditSmsDialog(true);
    };
    const handleCloseEditSmsDialog = () => {
        setIsOpenEditSmsDialog(false);
    };

    const [openDeleteConfirm, setOpenDeleteConfirm] = React.useState(false);

    const handleClickEdit = (e) => {
        stopPropagation(e);
        handleOpenEditSmsDialog({ smsSchedule: rowData });
    };

    const handleClickDelete = (e) => {
        stopPropagation(e);
        setOpenDeleteConfirm(true);
    };

    const handleAgreeDelete = (e) => {
        dispatch(
            actionSchedule.deleteSmsSchedule({
                id: rowData?.id,
                errorCallback: () => {
                    console.log('Delete sms schedule failed');
                },
                successCallback: ({ id }) => {
                    console.log(`Delete sms schedule ${id} success`);

                    dispatch(
                        merchantActions.fetchMerchantInfo({
                            successCallback: () => {
                                console.log('fetch merchant info success');
                            },
                            errorCallback: () => {
                                console.log('fetch merchant info failed');
                            }
                        })
                    );
                }
            })
        );
        stopPropagation(e);

        setOpenDeleteConfirm(false);
    };

    const handleDisagreeDelete = (e) => {
        stopPropagation(e);
        setOpenDeleteConfirm(false);
    };

    const stopPropagation = (e) => {
        e.stopPropagation();
        e.preventDefault();
    };

    return (
        <>
            {rowData.status === SMS_TOPIC_STATUS.WAITING && (
                <>
                    <EditOutlinedIcon fontSize="small" onClick={handleClickEdit} style={{ margin: '0px 10px' }} />
                    <DeleteOutlineOutlinedIcon fontSize="small" onClick={handleClickDelete} />

                    <Dialog fullScreen={true} padding={0} open={isOpenEditSmsDialog} onClose={handleCloseEditSmsDialog}>
                        <AddSms onClose={handleCloseEditSmsDialog} isEdit={true} editSmsSchedule={rowData} />
                    </Dialog>

                    <ConfirmDialog
                        open={openDeleteConfirm}
                        title={`Are you sure to delete`}
                        text={`This action will remove sms schedule forever?`}
                        onAgree={handleAgreeDelete}
                        onDisagree={handleDisagreeDelete}
                    />
                </>
            )}
        </>
    );
};

const ActionsMemo = React.memo(Actions);

const initialValueFilter = {
    id: {
        operator: OPERATOR.equal,
        value: '',
        type: columnTypes.NUMBER
    },
    name: {
        operator: OPERATOR.contains,
        value: '',
        type: columnTypes.TEXT
    },
    message: {
        operator: OPERATOR.contains,
        value: '',
        type: columnTypes.TEXT
    },
    status: {
        operator: OPERATOR.equal,
        value: '',
        type: columnTypes.NUMBER
    }
};

function List() {
    const classes = useStyles();
    const theme = useTheme();

    const dispatch = useDispatch();

    const smsSchedule = useSmsSchedule();
    const smsRemaining = useSmsRemaining();
    const timerRef = React.useRef();
    const [isFetching, setIsFetching] = React.useState(false);

    const [isOpenCreateSmsDialog, setIsOpenCreateSmsDialog] = React.useState(false);
    const handleOpenCreateSmsDialog = () => setIsOpenCreateSmsDialog(true);
    const handleCloseCreateSmsDialog = () => setIsOpenCreateSmsDialog(false);

    const [limit, setLimit] = React.useState(PAGINATION_LIMIT_DEFAULT);
    const [totalPages, setTotalPages] = React.useState(0);
    const [page, setPage] = React.useState(1);

    const [filter, setFilter] = React.useState(initialValueFilter);

    const columns = [
        {
            id: 'id',
            label: 'Scheduled Id',
            width: 150,
            searchable: true
        },
        { id: 'name', label: 'Name', width: 150, searchable: true },
        {
            id: 'message',
            label: 'Message',
            width: 150,
            searchable: true
        },
        {
            id: 'phones',
            label: 'Phones',
            width: 200,
            searchable: false,
            format: ({ value }) => (
                <Typography variant="body2" className={classes.phones}>
                    {value?.join(', ')}
                </Typography>
            )
        },

        {
            id: 'sendDatetime',
            label: 'Sent Datetime',
            width: 200,
            searchable: false,
            format: ({ value, rowData }) => {
                return <Typography variant="body2">{getFriendlyTime(value || rowData?.updatedAt)}</Typography>;
            }
        },
        {
            id: 'status',
            label: 'Status',
            width: 120,
            searchable: false,
            selectable: true,
            format: (props) => <StatusRenderer {...props} />
        },
        {
            id: 'action',
            label: 'Action',
            width: 80,
            format: (props) => <ActionsMemo {...props} />
        }
    ];

    React.useEffect(() => {
        if (timerRef.current) clearTimeout(timerRef.current);

        timerRef.current = setTimeout(() => {
            setIsFetching(true);

            dispatch(
                actionSchedule.fetchSmsSchedule({
                    searchCondition: filter,
                    page,
                    limit,
                    errorCallback: () => {
                        setIsFetching(false);
                    },
                    successCallback: (totalPages) => {
                        setIsFetching(false);
                        setTotalPages(totalPages);
                    }
                })
            );
        }, 500);
    }, [dispatch, filter, limit, page]);

    const StatusRenderer = ({ value, rowData }) => {
        const status = rowData?.status;
        return (
            <Chip
                label={STATUS_LABEL?.[status]}
                style={{ backgroundColor: getColorByStatusCode(status, theme), color: theme.colors.primaryText }}
            />
        );
    };

    const headerRenderer = ({ column }) => {
        return <Typography variant="body1">{column.label}</Typography>;
    };

    const handleFilterChange = React.useCallback(
        (id, value) => {
            setFilter({
                ...filter,
                [id]: {
                    ...filter?.[id],
                    value
                }
            });
        },
        [filter]
    );

    const handleClearInput = React.useCallback(
        (id) => {
            const newFilter = {
                ...filter,
                [id]: {
                    ...filter?.[id],
                    value: ''
                }
            };
            setFilter(newFilter);
        },
        [filter]
    );

    const handleChangeStatus = (e) => {
        const newFilter = {
            ...filter,
            status: {
                ...filter?.status,
                value: e?.target?.value
            }
        };
        setFilter(newFilter);
    };

    const statusOptions = React.useMemo(() => {
        let options = [];
        options.push({ label: 'All', value: '' });
        Object.keys(STATUS_LABEL).forEach((key) => {
            options.push({ label: STATUS_LABEL[key], value: key });
        });

        return options;
    }, []);

    const selectableRenderer = ({ column }) => {
        return (
            <NativeSelect
                onChange={handleChangeStatus}
                selectedValue={filter?.status?.value}
                options={statusOptions}
                placeholder={`Choose an option`}
            />
        );
    };

    const filterHeaderRenderer = React.useCallback(
        ({ column }) => {
            const filterValue = filter?.[column?.id]?.value;

            return (
                <div className={classes.wrapInput}>
                    <InputBase
                        className={classes.customInput}
                        placeholder={'Type to filter'}
                        value={filterValue}
                        onChange={(e) => {
                            if (column?.id === 'id') e.target.value = e.target.value.replace(/\D/, '');
                            handleFilterChange(column?.id, e.target.value);
                        }}
                    />
                    {filterValue && (
                        <div className={classes.clearIcon} onClick={() => handleClearInput(column?.id)}>
                            <CloseSVG color="#8181A5" size="small" />
                        </div>
                    )}
                </div>
            );
        },
        [classes, filter, handleClearInput, handleFilterChange]
    );

    const onRowClicked = ({ rowData }) => {};

    const onChangePageOptions = (e) => {
        setPage(1);
        setLimit(e.target.value);
    };

    const onChangePage = (event, page) => {
        setPage(page);
    };

    return (
        <ContentLayout
            title={`SMS Schedule (Remaining:  ${smsRemaining}) `}
            action={
                <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleOpenCreateSmsDialog}>
                    New schedule
                </Button>
            }
        >
            <LayoutTabContent isLoading={isFetching && !smsSchedule}>
                <Grid container className={classes.root} direction="column" wrap="nowrap">
                    <Grid item className={classes.wrapContent}>
                        <Table
                            headerRenderer={headerRenderer}
                            filterHeaderRenderer={filterHeaderRenderer}
                            rows={smsSchedule || []}
                            columns={columns}
                            onRowClicked={onRowClicked}
                            page={page}
                            limit={limit}
                            totalPages={totalPages}
                            rowPerPageOptions={PAGINATION_OPTIONS_PER_PAGE}
                            onChangePageOptions={onChangePageOptions}
                            onChangePage={onChangePage}
                            selectableRenderer={selectableRenderer}
                            classNameBody={classes.tableBody}
                        />
                    </Grid>

                    <Dialog
                        fullScreen={true}
                        padding={0}
                        open={isOpenCreateSmsDialog}
                        onClose={handleCloseCreateSmsDialog}
                    >
                        <AddSms onClose={handleCloseCreateSmsDialog} />
                    </Dialog>
                </Grid>
            </LayoutTabContent>
        </ContentLayout>
    );
}

export default List;
