import React from 'react';

function VisaSVG({ color = '#41415C', ...rest }) {
    return (
        <svg
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            style={{ enableBackground: 'new 0 0 512 512' }}
            xmlSpace="preserve"
            width="200px"
            {...rest}
        >
            <path
                style={{ fill: '#FFFFFF' }}
                d="M512,402.281c0,16.716-13.55,30.267-30.265,30.267H30.265C13.55,432.549,0,418.997,0,402.281V109.717
	c0-16.716,13.55-30.266,30.265-30.266h451.47c16.716,0,30.265,13.551,30.265,30.266V402.281L512,402.281z"
            />
            <path
                style={{ fill: '#F79F1A' }}
                d="M113.64,258.035l-12.022-57.671c-2.055-7.953-8.035-10.319-15.507-10.632H30.993l-0.491,2.635
	C73.431,202.774,101.836,227.88,113.64,258.035z"
            />
            <g>
                <polygon
                    style={{ fill: '#059BBF' }}
                    points="241.354,190.892 205.741,190.892 183.499,321.419 219.053,321.419 	"
                />
                <path
                    style={{ fill: '#059BBF' }}
                    d="M135.345,321.288l56.01-130.307h-37.691l-34.843,89.028l-3.719-13.442
		c-6.83-16.171-26.35-39.446-49.266-54.098l31.85,108.863L135.345,321.288z"
                />
                <path
                    style={{ fill: '#059BBF' }}
                    d="M342.931,278.75c0.132-14.819-9.383-26.122-29.887-35.458c-12.461-6.03-20.056-10.051-19.965-16.17
		c0-5.406,6.432-11.213,20.368-11.213c11.661-0.179,20.057,2.367,26.624,5.003l3.218,1.475l4.826-28.277
		c-7.059-2.637-18.094-5.451-31.895-5.451c-35.157,0-59.904,17.691-60.128,43.064c-0.224,18.763,17.692,29.216,31.181,35.469
		c13.847,6.374,18.493,10.453,18.404,16.171c-0.089,8.743-11.035,12.73-21.264,12.73c-14.25,0-21.8-1.965-33.509-6.843l-4.55-2.09
		l-4.998,29.249c8.303,3.629,23.668,6.801,39.618,6.933C318.361,323.342,342.663,305.876,342.931,278.75z"
                />
                <path
                    style={{ fill: '#059BBF' }}
                    d="M385.233,301.855c4.065,0,40.382,0.045,45.566,0.045c1.072,4.545,4.333,19.565,4.333,19.565h33.011
		L439.33,191.027h-27.472c-8.533,0-14.874,2.323-18.628,10.809l-52.845,119.629h37.392
		C377.774,321.465,383.848,305.386,385.233,301.855z M409.622,238.645c-0.176,0.357,2.95-7.549,4.737-12.463l2.411,11.256
		c0,0,6.792,31.182,8.22,37.704h-29.528C398.411,267.638,409.622,238.645,409.622,238.645z"
                />
                <path
                    style={{ fill: '#059BBF' }}
                    d="M481.735,79.451H30.265C13.55,79.451,0,93.001,0,109.717v31.412h512v-31.412
		C512,93.001,498.451,79.451,481.735,79.451z"
                />
            </g>
            <path
                style={{ fill: '#F79F1A' }}
                d="M481.735,432.549H30.265C13.55,432.549,0,418.998,0,402.283v-31.412h512v31.412
	C512,418.998,498.451,432.549,481.735,432.549z"
            />
            <path
                style={{ opacity: 0.15, fill: '#202121', enableBackground: 'new' }}
                d="M21.517,402.281V109.717
	c0-16.716,13.551-30.266,30.267-30.266h-21.52C13.55,79.451,0,93.001,0,109.717v292.565c0,16.716,13.55,30.267,30.265,30.267h21.52
	C35.069,432.549,21.517,418.997,21.517,402.281z"
            />
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
        </svg>
    );
}

export default React.memo(VisaSVG);
