import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, Chip, IconButton } from '@material-ui/core';
import hexToRgba from 'hex-to-rgba';
import Spinner from 'components/spinner';
import { useServiceList } from 'hooks/service';
import { getFullName } from 'utils/naming';
import { convertFloatingToDateTime, getFriendlyWeekDate, convertMinsToHrsMins, isSameDate } from 'utils/timing';
import { BOOKING_SERVICE_STATES, BOOKING_STATES, getBookingStatusColor } from 'const';
import { getBookingServiceStatus } from 'utils/app/booking';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useMerchantInfo } from 'hooks/merchant';
import { useSelectedDate } from 'hooks/calendar';
import { useDispatch } from 'react-redux';
import * as calendarActions from 'views/calender/actions';
import Tooltip from 'components/tooltip';
import SearchIcon from '@material-ui/icons/Search';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
        overflow: 'auto',
        position: 'relative',
        height: 'auto'
    },
    overlay: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        background: hexToRgba(theme.palette.common.white, 0.7),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    tableCell: {
        border: 'none',
        lineHeight: `1.5rem`,
        fontSize: `0.875rem`,
        fontFamily: `"Public Sans", sans-serif`,
        fontWeight: 600,
        display: `table-cell`,
        verticalAlign: 'inherit',
        textAlign: 'left',
        padding: '16px',
        borderBottom: 'none',
        color: `rgb(99, 115, 129)`,
        backgroundColor: theme.colors.hover,
        '&:first-of-type': {
            paddingLeft: '24px',
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
            boxShadow: `rgb(255 255 255) 8px 0px 0px inset`
        },
        '&:last-of-type': {
            paddingLeft: '24px',
            borderTopRightRadius: '8px',
            borderBottomRightRadius: '8px',
            boxShadow: `rgb(255 255 255) -8px 0px 0px inset`
        }
    }
}));

function Appointments({ isLoading, bookings, onClose, isInGrid }) {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();

    const serviceList = useServiceList();
    const merchant = useMerchantInfo();
    const selectedDate = useSelectedDate();
    const history = useHistory();

    const findBooking = React.useCallback(
        ({ bkId, date }) => {
            if (!isInGrid) {
                history.push('/calendar');
            }

            if (!isSameDate({ date1: date, date2: selectedDate })) {
                dispatch(calendarActions.setSelectedDate(date));
            }

            dispatch(calendarActions.setFindBookingId(bkId));
            onClose && onClose();
        },
        [onClose, dispatch, selectedDate, history, isInGrid]
    );

    const TABLE_COLUMNS = React.useMemo(() => {
        return [
            {
                width: 220,
                label: 'Start Time',
                id: 'startTime'
            },
            {
                width: 350,
                label: 'Service',
                id: 'serviceAndStaffAndDuration'
            },
            {
                width: 50,
                label: `Price`,
                id: 'price'
            },
            {
                width: 30,
                label: `Link`,
                id: 'bkId',
                format: ({ value, rowData }) =>
                    [BOOKING_SERVICE_STATES.CANCELED, BOOKING_SERVICE_STATES.NO_SHOW]?.includes(
                        rowData?.bkStatus
                    ) ? null : (
                        <Tooltip title="Jump to booking">
                            <IconButton onClick={() => findBooking({ bkId: value, date: rowData?.date })} size="small">
                                <SearchIcon />
                            </IconButton>
                        </Tooltip>
                    )
            }
        ];
    }, [findBooking]);

    const bookingServices = React.useMemo(() => {
        const result = [];

        for (let booking of bookings) {
            const isFuture = booking?.isFuture;

            const bkServices = booking?.bookingServices || [];
            const isCancel = booking?.state === BOOKING_STATES.CLIENT_CANCEL;

            bkServices.forEach((service) => {
                const serviceDetail = serviceList?.find((sv) => +sv?.id === +service?.serviceId);
                const bookingServiceStatus = service?.status;

                const bkStatus =
                    isFuture &&
                    ![BOOKING_SERVICE_STATES.CANCELED, BOOKING_SERVICE_STATES.NO_SHOW].includes(bookingServiceStatus)
                        ? 8
                        : isCancel
                        ? BOOKING_SERVICE_STATES.CANCELED
                        : bookingServiceStatus;

                const serviceBody = {
                    startTime: (
                        <Typography variant="body2" display="inline">
                            {convertFloatingToDateTime(service?.startTime)}, {getFriendlyWeekDate(booking?.startDate)}{' '}
                            <Chip
                                size="small"
                                style={{
                                    background: getBookingStatusColor?.[bkStatus],
                                    color: theme.palette.common.white
                                }}
                                label={getBookingServiceStatus(bkStatus)}
                            />
                        </Typography>
                    ),
                    price: `${merchant?.currency}${service?.price}`,
                    serviceAndStaffAndDuration: `${convertMinsToHrsMins(service?.duration, 'h')} ${
                        serviceDetail?.title
                    } ${service?.love ? `❤️` : `with`} ${getFullName(service?.staff)}`,
                    bkStatus,
                    date: booking?.startDate,
                    bkId: booking?.id
                };

                result.push(serviceBody);
            });
        }

        return result;
    }, [serviceList, bookings, theme, merchant]);

    return (
        <Grid container className={classes.root}>
            {isLoading && (
                <Grid className={classes.overlay}>
                    <Spinner size={40} thick={4} />
                </Grid>
            )}
            {!isLoading && bookings?.length === 0 && (
                <Grid container alignItems="center" justifyContent="center">
                    <Grid item>
                        <Typography variant="body2">There is no Appointments yet!</Typography>
                    </Grid>
                </Grid>
            )}

            {!isLoading && bookings?.length > 0 && (
                <Grid container direction="column">
                    <Paper
                        elevation={0}
                        style={{
                            pt: 2,
                            width: '100%',
                            overflow: 'hidden',
                            background: theme.palette.common.white,
                            color: `rgb(33, 43, 54)`,
                            backgroundImage: 'none',
                            position: 'relative',
                            zIndex: 0,
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <Grid item>
                            <TableContainer>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow sx={{}}>
                                            {TABLE_COLUMNS?.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    className={classes.tableCell}
                                                    style={{ minWidth: column.minWidth }}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {bookingServices?.length === 0 && (
                                            <TableRow sx={{ textAlign: 'center' }}>
                                                <TableCell style={{ border: 'none' }}>No histories</TableCell>
                                            </TableRow>
                                        )}
                                        {bookingServices?.map((row) => {
                                            return (
                                                <TableRow
                                                    sx={{
                                                        '&:hover': {
                                                            cursor: 'pointer',
                                                            background: `${theme.colors.hover} !important`
                                                        }
                                                    }}
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={row.id}
                                                >
                                                    {TABLE_COLUMNS.map((column) => {
                                                        const value = row?.[column.id];

                                                        return (
                                                            <TableCell
                                                                style={{ border: 'none' }}
                                                                key={column.id}
                                                                align={column.align}
                                                            >
                                                                {column.format
                                                                    ? column.format({
                                                                          rowData: row,
                                                                          value
                                                                      })
                                                                    : value}
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Paper>
                </Grid>
            )}
        </Grid>
    );
}

export default React.memo(Appointments);
