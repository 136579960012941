import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        width: (props) => props.width,
        height: (props) => props.height
    }
}));

const SIZES = {
    small: {
        height: 16,
        width: 16
    },
    medium: {
        width: 24,
        height: 24
    },
    large: {
        width: 32,
        height: 32
    }
};

function getSize({ size }) {
    if (typeof size === 'string') return SIZES[size] || size['small'];
    return { width: size, height: size };
}

function CloseSVG({ color = '#41415C', size = 'small', className, ...rest }) {
    const { width, height } = getSize({ size });
    const classes = useStyles({ width, height });

    return (
        <svg
            className={`${classes.root} ${className}`}
            {...rest}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.53176 12.8585L4.19171 7.51833C4.0681 7.39482 4 7.22994 4 7.05414C4 6.87833 4.0681 6.71346 4.19171 6.58995L4.58487 6.19668C4.84107 5.94078 5.25745 5.94078 5.51326 6.19668L9.99751 10.6809L14.4867 6.19171C14.6104 6.06819 14.7751 6 14.9508 6C15.1267 6 15.2915 6.06819 15.4152 6.19171L15.8083 6.58497C15.9319 6.70858 16 6.87336 16 7.04916C16 7.22497 15.9319 7.38984 15.8083 7.51335L10.4634 12.8585C10.3394 12.9823 10.1738 13.0503 9.99781 13.0499C9.82112 13.0503 9.65566 12.9823 9.53176 12.8585Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(CloseSVG);
