import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, IconButton } from '@material-ui/core';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { getCurrentDateFormat, getDateBefore, getAppendMerchantTimezoneToDate, getDatetimeFormat } from 'utils/timing';
import moment from 'moment-timezone';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker } from 'react-dates';
import useMediaQueries from 'hooks/useMediaQueries';
import { useMerchantAnalyze } from 'hooks/settings';
import * as settingActions from './actions';
import { useDispatch } from 'react-redux';
import { CloudDownload } from '@material-ui/icons';
import TooltipLabel from 'components/tooltip';
import { exportBookingServicesApi } from 'services/bookings';

const useStyles = makeStyles((theme) => ({
    root: {},
    chartWrapper: {
        boxSizing: 'border-box',
        background: theme.palette.common.white,
        padding: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2)
        }
    },
    chart: {
        height: 300,
        width: '100%',
        paddingTop: theme.spacing(3)
    },
    chartDetail: {},
    centerText: {
        textAlign: 'center'
    }
}));

function Analyze({ merchant }) {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const [startDate, setStartDate] = React.useState(getDateBefore({ date: new Date(), numOfDay: 7 }));
    const [endDate, setEndDate] = React.useState(moment(new Date()));
    const [focusedInput, setFocusedInput] = React.useState(null);

    const merchantAnalyze = useMerchantAnalyze(+merchant?.id);

    const totalNewClients = React.useMemo(() => {
        return merchantAnalyze?.totalNewClients || 0;
    }, [merchantAnalyze]);

    const totalSmsSchedule = React.useMemo(() => {
        return merchantAnalyze?.totalSmsSchedule || 0;
    }, [merchantAnalyze]);

    const totalNegativeReviews = React.useMemo(() => {
        return merchantAnalyze?.totalNegativeReviews || 0;
    }, [merchantAnalyze]);

    const totalSms = React.useMemo(() => {
        return merchantAnalyze?.totalSms || 0;
    }, [merchantAnalyze]);

    const totalEmails = React.useMemo(() => {
        return merchantAnalyze?.totalEmails || 0;
    }, [merchantAnalyze]);

    const bookings = React.useMemo(() => {
        return merchantAnalyze?.bookings;
    }, [merchantAnalyze]);

    const newClients = React.useMemo(() => {
        return merchantAnalyze?.newClients;
    }, [merchantAnalyze]);

    const smsSchedules = React.useMemo(() => {
        return merchantAnalyze?.smsSchedules;
    }, [merchantAnalyze]);

    const negativeReviews = React.useMemo(() => {
        return merchantAnalyze?.negativeReviews;
    }, [merchantAnalyze]);

    const sms = React.useMemo(() => {
        return merchantAnalyze?.sms;
    }, [merchantAnalyze]);

    const emails = React.useMemo(() => {
        return merchantAnalyze?.emails;
    }, [merchantAnalyze]);

    const { isMobile } = useMediaQueries();

    const onDatesChange = ({ startDate, endDate }) => {
        setStartDate(startDate);
        setEndDate(endDate);
    };

    const orientation = React.useMemo(() => {
        return isMobile ? 'vertical' : 'horizontal';
    }, [isMobile]);

    React.useEffect(() => {
        if (startDate && endDate && merchant) {
            const start = getAppendMerchantTimezoneToDate(getDatetimeFormat(startDate));
            const end = getAppendMerchantTimezoneToDate(getDatetimeFormat(endDate));
            dispatch(
                settingActions.fetchMerchantAnalyze({
                    merchantId: +merchant?.id,
                    startDate: start,
                    endDate: end,
                    successCallback: () => {
                        console.log('fetch analyze success');
                    },
                    errorCallback: () => {
                        console.log('failed to fetch analyze');
                    }
                })
            );
        }
    }, [dispatch, startDate, endDate, merchant]);

    const handleDownload = React.useCallback(async () => {
        const start = getAppendMerchantTimezoneToDate(getDatetimeFormat(startDate));
        const end = getAppendMerchantTimezoneToDate(getDatetimeFormat(endDate));

        await exportBookingServicesApi({
            merchantId: +merchant?.id,
            startDate: start,
            endDate: end
        });
    }, [startDate, endDate, merchant]);

    return (
        <Grid container wrap="nowrap" className={classes.merchantContent} direction="column" spacing={4}>
            <Grid item>
                <Grid container direction="row" alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <Grid container direction="row" alignItems="center" spacing={2}>
                            <Grid item>
                                <Typography variant="body1">{isMobile ? `` : `Select date`} ranges:</Typography>
                            </Grid>
                            <Grid item>
                                <DateRangePicker
                                    startDate={startDate}
                                    startDateId="startDate"
                                    endDate={endDate}
                                    endDateId="endDate"
                                    onDatesChange={onDatesChange}
                                    focusedInput={focusedInput}
                                    onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                                    appendToBody={true}
                                    orientation={orientation}
                                    enableOutsideDays={true}
                                    isOutsideRange={() => false}
                                    minimumNights={0}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container wrap="nowrap" direction="row" spacing={4}>
                            <Grid item>
                                <Grid container direction="row" alignItems="center" spacing={2}>
                                    <Grid item>
                                        <Typography variant="h4" style={{ fontWeight: 'normal' }} display="inline">
                                            New Clients:
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            style={{ color: theme.colors.blue }}
                                            variant={isMobile ? 'h3' : 'h2'}
                                            display="inline"
                                        >
                                            {totalNewClients}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction="row" alignItems="center" spacing={2}>
                                    <Grid item>
                                        <Typography variant="h4" style={{ fontWeight: 'normal' }} display="inline">
                                            Total Sms Schedule:
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            style={{ color: theme.colors.blue }}
                                            variant={isMobile ? 'h3' : 'h2'}
                                            display="inline"
                                        >
                                            {totalSmsSchedule}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction="row" alignItems="center" spacing={2}>
                                    <Grid item>
                                        <Typography variant="h4" style={{ fontWeight: 'normal' }} display="inline">
                                            Negative reviews:
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            style={{ color: theme.colors.blue }}
                                            variant={isMobile ? 'h3' : 'h2'}
                                            display="inline"
                                        >
                                            {totalNegativeReviews}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Grid container direction="row" alignItems="center" spacing={2}>
                                    <Grid item>
                                        <Typography variant="h4" style={{ fontWeight: 'normal' }} display="inline">
                                            Total sms:
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            style={{ color: theme.colors.blue }}
                                            variant={isMobile ? 'h3' : 'h2'}
                                            display="inline"
                                        >
                                            {totalSms}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction="row" alignItems="center" spacing={2}>
                                    <Grid item>
                                        <Typography variant="h4" style={{ fontWeight: 'normal' }} display="inline">
                                            Total emails:
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            style={{ color: theme.colors.blue }}
                                            variant={isMobile ? 'h3' : 'h2'}
                                            display="inline"
                                        >
                                            {totalEmails}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item style={{ display: 'flex' }}>
                                <TooltipLabel title="Download Booking Services CSV">
                                    <IconButton onClick={handleDownload}>
                                        <CloudDownload />
                                    </IconButton>
                                </TooltipLabel>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.chartDetail}>
                <Grid container direction="row" spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Grid
                            className={classes.chartWrapper}
                            container
                            alignItems="center"
                            justifyContent="center"
                            direction="column"
                        >
                            <Grid item>
                                <Typography className={classes.centerText} variant="body1">
                                    Bookings from: {getCurrentDateFormat(startDate)} → {getCurrentDateFormat(endDate)}
                                </Typography>
                            </Grid>
                            <Grid item className={classes.chart}>
                                <ResponsiveContainer width="100%" height="100%">
                                    <BarChart
                                        width={500}
                                        height={300}
                                        data={bookings}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="label" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Bar dataKey="success" fill={theme.colors.green} />
                                        <Bar dataKey="noshow" fill={theme.colors.red} />
                                        <Bar dataKey="cancel" fill={theme.colors.gullGray} />
                                    </BarChart>
                                </ResponsiveContainer>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid
                            className={classes.chartWrapper}
                            container
                            alignItems="center"
                            justifyContent="center"
                            direction="column"
                        >
                            <Grid item>
                                <Typography className={classes.centerText} variant="body1">
                                    Sms from: {getCurrentDateFormat(startDate)} → {getCurrentDateFormat(endDate)}
                                </Typography>
                            </Grid>
                            <Grid item className={classes.chart}>
                                <ResponsiveContainer width="100%" height="100%">
                                    <BarChart
                                        width={500}
                                        height={300}
                                        data={sms}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="label" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Bar dataKey="sms" fill={theme.colors.anakiwa} />
                                    </BarChart>
                                </ResponsiveContainer>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid
                            className={classes.chartWrapper}
                            container
                            alignItems="center"
                            justifyContent="center"
                            direction="column"
                        >
                            <Grid item>
                                <Typography className={classes.centerText} variant="body1">
                                    Emails from: {getCurrentDateFormat(startDate)} → {getCurrentDateFormat(endDate)}
                                </Typography>
                            </Grid>
                            <Grid item className={classes.chart}>
                                <ResponsiveContainer width="100%" height="100%">
                                    <BarChart
                                        width={500}
                                        height={300}
                                        data={emails}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="label" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Bar dataKey="emails" fill={theme.colors.pink} />
                                    </BarChart>
                                </ResponsiveContainer>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid
                            className={classes.chartWrapper}
                            container
                            alignItems="center"
                            justifyContent="center"
                            direction="column"
                        >
                            <Grid item>
                                <Typography className={classes.centerText} variant="body1">
                                    New clients from: {getCurrentDateFormat(startDate)} →{' '}
                                    {getCurrentDateFormat(endDate)}
                                </Typography>
                            </Grid>
                            <Grid item className={classes.chart}>
                                <ResponsiveContainer width="100%" height="100%">
                                    <BarChart
                                        width={500}
                                        height={300}
                                        data={newClients}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="label" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Bar dataKey="clients" fill={theme.colors.creamBrulee} />
                                    </BarChart>
                                </ResponsiveContainer>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid
                            className={classes.chartWrapper}
                            container
                            alignItems="center"
                            justifyContent="center"
                            direction="column"
                        >
                            <Grid item>
                                <Typography className={classes.centerText} variant="body1">
                                    Sms schedules from: {getCurrentDateFormat(startDate)} →{' '}
                                    {getCurrentDateFormat(endDate)}
                                </Typography>
                            </Grid>
                            <Grid item className={classes.chart}>
                                <ResponsiveContainer width="100%" height="100%">
                                    <BarChart
                                        width={500}
                                        height={300}
                                        data={smsSchedules}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="label" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Bar dataKey="schedules" fill={theme.colors.portage} />
                                    </BarChart>
                                </ResponsiveContainer>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid
                            className={classes.chartWrapper}
                            container
                            alignItems="center"
                            justifyContent="center"
                            direction="column"
                        >
                            <Grid item>
                                <Typography className={classes.centerText} variant="body1" display="inline">
                                    Negative review from: {getCurrentDateFormat(startDate)} →{' '}
                                    {getCurrentDateFormat(endDate)}
                                </Typography>{' '}
                                <Typography variant="body1"></Typography>
                            </Grid>
                            <Grid item className={classes.chart}>
                                <ResponsiveContainer width="100%" height="100%">
                                    <BarChart
                                        width={500}
                                        height={300}
                                        data={negativeReviews}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="label" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Bar dataKey="reviews" fill={theme.colors.gullGray} />
                                    </BarChart>
                                </ResponsiveContainer>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Analyze;
