import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from 'components/dialog/Title';
import DialogContent from 'components/dialog/Content';
import { useDispatch } from 'react-redux';
import * as staffActions from './action';
import { convertServiceGroupOptions } from 'utils/app/booking';
import { useServiceOptions } from 'hooks/service';
import { getFullName } from 'utils/naming';
import NativeSelect from 'components/select/Native';
import { generateNumberOptions } from 'utils/timing';
import { DEFAULT_COLOR } from 'utils/color';

const commissionOptions = generateNumberOptions(1, 0, 100).map((time, index) => ({
    label: `${time}%`,
    value: time,
    id: index
}));

const useStyles = makeStyles((theme) => ({
    root: {},
    content: {
        minWidth: 600
    },
    formContent: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(3)
    },
    fullWidth: {
        width: '100%'
    },
    active: {
        background: theme.colors.blue,
        color: theme.palette.common.white,
        fontWeight: 'bold'
    },
    btnSubmit: {
        position: 'relative'
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    category: {
        background: theme.colors.ghostwhite,
        borderRadius: 4,
        padding: theme.spacing(3),
        marginBottom: theme.spacing(2)
    },
    commission: {
        marginBottom: theme.spacing(2)
    }
}));

function Commission({ title, staff, serviceId, id, commissionRate, extraCommission }) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const commissionChange = React.useCallback(
        (body) => {
            dispatch(
                staffActions.updateServiceStaff({
                    id,
                    body: {
                        ...body,
                        staffId: +staff?.id,
                        serviceId: +serviceId
                    },
                    errorCallback: () => {},
                    successCallback: () => {}
                })
            );
        },
        [id, dispatch, staff, serviceId]
    );

    const onCommissionRateChange = React.useCallback(
        (e) => {
            const value = +e?.target?.value;
            commissionChange({ commissionRate: value, extraCommission });
        },
        [commissionChange, extraCommission]
    );

    // const onExtraCommissionChange = React.useCallback(
    //     (e) => {
    //         const value = +e?.target?.value;
    //         commissionChange({ commissionRate, extraCommission: value });
    //     },
    //     [commissionChange, commissionRate]
    // );

    return (
        <Grid container wrap="nowrap" direction="column" spacing={2}>
            <Grid item>
                <Typography variant="body2" display="inline">
                    Service Name:
                </Typography>{' '}
                <Typography display="inline" variant="h5">
                    {title}
                </Typography>
            </Grid>
            <Grid item>
                <Grid container direction="row" spacing={2}>
                    <Grid item xs>
                        <Grid container spacing={1} direction="row" wrap="nowrap" alignItems="center">
                            <Grid item>
                                <Typography variant="body2">Commission:</Typography>
                            </Grid>
                            <Grid item className={classes.fullWidth}>
                                <NativeSelect
                                    onChange={onCommissionRateChange}
                                    selectedValue={commissionRate}
                                    options={commissionOptions}
                                    placeholder={`Choose commission percent`}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* <Grid item xs>
                        <Grid container spacing={1} direction="row" wrap="nowrap" alignItems="center">
                            <Grid item>
                                <Typography variant="body2">Extra:</Typography>
                            </Grid>
                            <Grid item className={classes.fullWidth}>
                                <NativeSelect
                                    onChange={onExtraCommissionChange}
                                    selectedValue={extraCommission}
                                    options={commissionOptions}
                                    placeholder={`Choose commission percent`}
                                />
                            </Grid>
                        </Grid>
                    </Grid> */}
                </Grid>
            </Grid>
        </Grid>
    );
}

function ChangeStaffCredential({ staff }) {
    const classes = useStyles();

    const serviceList = useServiceOptions();

    const serviceGroupOptions = React.useMemo(() => {
        return convertServiceGroupOptions(serviceList);
    }, [serviceList]);

    const staffServices = React.useMemo(() => {
        return staff?.staffServices;
    }, [staff]);

    const stopPropagation = (e) => {
        e.stopPropagation();
        e.preventDefault();
    };

    return (
        <form onClick={stopPropagation} className={classes.form}>
            <DialogTitle className={classes.title}>Config {getFullName(staff)}'s services commission</DialogTitle>
            <DialogContent className={classes.content}>
                <Grid container onClick={stopPropagation} className={classes.formContent} direction="column">
                    {serviceGroupOptions?.map((group) => {
                        const options = group?.options || [];
                        const groupName = group?.groupName;
                        const color = group?.color;

                        return (
                            <Grid item key={groupName} className={classes.category}>
                                <Grid container spacing={3} direction="column" wrap="nowrap">
                                    <Grid item>
                                        <Grid container direction="row" spacing={1} alignItems="center">
                                            <Grid item>
                                                <Typography variant="body2">Category:</Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                style={{
                                                    width: 20,
                                                    height: 20,
                                                    borderRadius: 2,
                                                    background: color || DEFAULT_COLOR
                                                }}
                                            ></Grid>
                                            <Grid item>
                                                <Typography variant="h4">{groupName}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {options?.map((service) => {
                                        const staffService = staffServices?.find(
                                            (sSv) => sSv?.serviceId === service?.id
                                        );
                                        if (!staffService) return null;
                                        return (
                                            <Grid key={service.id} item className={classes.commission}>
                                                <Commission title={service?.title} staff={staff} {...staffService} />
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            </DialogContent>
        </form>
    );
}

export default React.memo(ChangeStaffCredential);
