export const STAFF_MARK_DONE = '[TURN]STAFF_MARK_DONE';
export const OWNER_KICK_STAFF_OUT_OF_TURN_LIST = '[TURN]OWNER_KICK_STAFF_OUT_OF_TURN_LIST';

export const RESET_IN_TURN_LIST = '[TURN]RESET_IN_TURN_LIST';
export const REORDER_STAFF_TURN = '[TURN]RESET_STAFF_TURN';

export const GET_OUT_TURN_LIST = '[TURN]GET_OUT_TURN_LIST';
export const GET_IN_TURN_LIST = '[TURN]GET_IN_TURN_LIST';

export const REGISTER_STAFF_TO_IN_TURN_SOCKET = '[TURN]REGISTER_STAFF_TO_IN_TURN_SOCKET';
export const REGISTER_STAFF_TO_OUT_TURN_SOCKET = '[TURN]REGISTER_STAFF_TO_OUT_TURN_SOCKET';
export const REALTIME_REORDER_STAFF_IN_TURN = '[TURN]REALTIME_REORDER_STAFF_IN_TURN';

export const REORDER_TURN = '[TURN]REORDER_TURN';
export const REORDER_TURN_SUCCESS = '[TURN]REORDER_TURN_SUCCESS';
export const REORDER_TURN_FAILED = '[TURN]REORDER_TURN_FAILED';
