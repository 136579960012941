import * as types from './types';

const INITIAL_STATE = {
    inTurnStaffs: [],
    outTurnStaffs: []
};

export default function turn(state = INITIAL_STATE, { type, payload }) {
    switch (type) {
        /**
         * OUT TURN
         */

        case types.GET_OUT_TURN_LIST: {
            const { staffs } = payload;
            return {
                ...state,
                outTurnStaffs: staffs
            };
        }

        case types.STAFF_MARK_DONE: {
            const { staffId } = payload;
            const { outTurnStaffs } = state;

            const newOutTurnStaffs = outTurnStaffs?.filter((staff) => +staff?.id !== +staffId);

            return {
                ...state,
                outTurnStaffs: newOutTurnStaffs
            };
        }

        case types.REGISTER_STAFF_TO_OUT_TURN_SOCKET: {
            const { staff } = payload;
            const { outTurnStaffs } = state;
            const index = outTurnStaffs?.findIndex((stf) => +stf?.id === +staff?.id);

            const newOutTurnStaffs = index === -1 ? [...outTurnStaffs, staff] : [...outTurnStaffs];
            return {
                ...state,
                outTurnStaffs: newOutTurnStaffs
            };
        }

        /**
         * IN TURN
         */

        case types.GET_IN_TURN_LIST: {
            const { staffs } = payload;
            return {
                ...state,
                inTurnStaffs: staffs
            };
        }

        case types.OWNER_KICK_STAFF_OUT_OF_TURN_LIST: {
            const { staffId } = payload;
            const { inTurnStaffs } = state;
            const newInturnStaffs = inTurnStaffs?.filter((staff) => +staff?.id !== +staffId);
            return {
                ...state,
                inTurnStaffs: newInturnStaffs
            };
        }

        case types.RESET_IN_TURN_LIST: {
            return {
                ...state,
                inTurnStaffs: []
            };
        }

        case types.REGISTER_STAFF_TO_IN_TURN_SOCKET: {
            const { staff } = payload;
            const { inTurnStaffs } = state;

            console.log('REGISTER_STAFF_TO_IN_TURN_SOCKET', staff);
            console.log('inTurnStaffs', inTurnStaffs);

            const index = inTurnStaffs?.findIndex((stf) => +stf?.id === +staff?.id);

            const newInturnStaffs = index === -1 ? [...inTurnStaffs, staff] : [...inTurnStaffs];
            return {
                ...state,
                inTurnStaffs: newInturnStaffs
            };
        }

        case types.REORDER_STAFF_TURN: {
            const { inTurnStaffs } = payload;

            return {
                ...state,
                inTurnStaffs: inTurnStaffs
            };
        }

        default:
            return state;
    }
}
