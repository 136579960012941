import React from 'react';
import { makeStyles, IconButton } from '@material-ui/core';
import hexToRgba from 'hex-to-rgba';

const useStyles = makeStyles((theme) => ({
    root: {
        width: (props) => props.width,
        height: (props) => props.height,
        borderRadius: theme.radius(2),
        background: (props) => (props.background ? props.background : hexToRgba(theme.palette.primary.main, 0.1))
    }
}));

const SIZES = {
    small: {
        height: 24,
        width: 24
    },
    medium: {
        width: 36,
        height: 36
    },
    large: {
        width: 48,
        height: 48
    }
};

function getSize({ size }) {
    if (typeof size === 'string') return SIZES[size] || size['small'];
    return { width: size, height: size };
}

function ButtonWrapper({ background, icon, size = 'medium', className, ...rest }) {
    const { width, height } = getSize({ size });
    const classes = useStyles({ width, height, background });

    return (
        <IconButton className={`${classes.root} ${className}`} {...rest}>
            {typeof icon === 'function' ? icon() : icon}
        </IconButton>
    );
}

export default React.memo(ButtonWrapper);
