import React from 'react';

function SmsSVG({ color = '#41415C', ...rest }) {
    return (
        <svg {...rest} width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.5" clipPath="url(#clipVoucher)">
                <path
                    d="M17.861 7.14783H17.1479V2.15677C17.1479 1.76297 16.8285 1.44383 16.4347 1.44391C16.3439 1.44391 16.254 1.46129 16.1697 1.49509L5.63778 5.72182H2.13904C0.957679 5.72182 0 6.6795 0 7.86085V10.7129C0 11.8943 0.957679 12.852 2.13904 12.852H2.17467V17.8431C2.17467 18.2369 2.4939 18.5561 2.8877 18.5561H5.73976C6.13356 18.5561 6.45279 18.2369 6.45279 17.8431V13.1785L16.1697 17.0787C16.254 17.1129 16.3441 17.1304 16.435 17.1301C16.8288 17.1301 17.148 16.8108 17.148 16.417V11.4259H17.861C19.0424 11.4259 20.0001 10.4682 20.0001 9.28686C20.0001 8.10551 19.0423 7.14783 17.861 7.14783ZM5.02674 17.13H3.60073V12.852H5.02674V17.13ZM5.06237 11.4259H2.13904C1.74524 11.4259 1.42601 11.1067 1.42601 10.7129V7.86081C1.42601 7.46701 1.74524 7.14779 2.13904 7.14779H5.06237V11.4259ZM15.7219 15.3624L6.48843 11.6548V6.91684L15.7219 3.21129V15.3624ZM17.861 9.99989H17.1479V8.57388H17.861C18.2548 8.57388 18.574 8.89311 18.574 9.28691C18.574 9.68071 18.2548 9.99989 17.861 9.99989Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clipVoucher">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default React.memo(SmsSVG);
