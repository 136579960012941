import * as types from './types';
import produce from 'immer';
import { DEFAULT_DATE } from 'utils/timing';

const DEFAULT_ORDER = {
    slug: null,
    services: [],
    staff: null,
    date: DEFAULT_DATE,
    time: null,
    step: 1,
    dateIndex: 0,
    categoryIndex: 0,
    token: null
};

const INITIAL_STATE = {
    staffs: null,
    categories: null,
    isInitializing: false,
    order: DEFAULT_ORDER,
    timeSlots: null,
    isFetchingTimeSlots: false,
    isFilteringStaffs: false,
    inComingBookings: [],
    isConflicted: false
};

export default function Booking(state = INITIAL_STATE, { type, payload }) {
    switch (type) {
        case types.INITIAL_BOOKING:
        case types.FETCH_STAFF: {
            return {
                ...state,
                isInitializing: true
            };
        }

        case types.INITIAL_BOOKING_FAILED:
        case types.FETCH_STAFF_FAILED: {
            return {
                ...state,
                isInitializing: false
            };
        }

        case types.FETCH_STAFF_SUCCESS: {
            const { staffs } = payload;
            return {
                ...state,
                isInitializing: false,
                staffs: staffs || []
            };
        }

        case types.INITIAL_BOOKING_SUCCESS: {
            const { categories, merchant } = payload;
            return {
                ...state,
                categories: categories || [],
                merchant: {
                    ...merchant,
                    TIME_12: true
                }
            };
        }

        case types.ADD_SERVICE: {
            const { service } = payload;
            const { order } = state;

            const newOrder = produce(order, (draft) => {
                const servicesClone = [...draft.services];
                const newServices =
                    servicesClone.findIndex((sv) => +sv.id === +service.id) === -1
                        ? [...servicesClone, service]
                        : servicesClone.filter((sv) => +sv.id !== +service.id);
                draft.services = newServices;
            });

            return {
                ...state,
                order: newOrder
            };
        }

        case types.ADD_STAFF: {
            const { staff } = payload;
            const { order } = state;

            const newOrder = produce(order, (draft) => {
                draft.staff = staff;
            });
            return {
                ...state,
                order: newOrder
            };
        }

        case types.ADD_DATE: {
            const { date, dateIndex } = payload;
            const { order } = state;

            const newOrder = produce(order, (draft) => {
                draft.date = date;
                draft.dateIndex = dateIndex;
            });
            return {
                ...state,
                order: newOrder
            };
        }

        case types.ADD_TIME: {
            const { time } = payload;
            const { order } = state;

            const newOrder = produce(order, (draft) => {
                draft.time = time;
            });
            return {
                ...state,
                order: newOrder
            };
        }

        case types.GO_NEXT_STEP: {
            const { order } = state;

            const newOrder = produce(order, (draft) => {
                draft.step = draft.step + 1;
            });
            return {
                ...state,
                order: newOrder
            };
        }

        case types.GO_PREV_STEP: {
            const { order } = state;

            const newOrder = produce(order, (draft) => {
                draft.step = draft.step - 1;
            });
            return {
                ...state,
                order: newOrder
            };
        }

        case types.SET_CURRENT_DATE_INDEX: {
            const { index } = payload;
            const { order } = state;

            const newOrder = produce(order, (draft) => {
                draft.dateIndex = index;
            });
            return {
                ...state,
                order: newOrder
            };
        }

        case types.SET_CURRENT_CATEGORY_INDEX: {
            const { index } = payload;
            const { order } = state;

            const newOrder = produce(order, (draft) => {
                draft.categoryIndex = index;
            });
            return {
                ...state,
                order: newOrder
            };
        }

        case types.FETCH_TIME_SLOTS: {
            return {
                ...state,
                isFetchingTimeSlots: true
            };
        }

        case types.FETCH_TIME_SLOTS_FAILED: {
            return {
                ...state,
                isFetchingTimeSlots: false
            };
        }

        case types.FETCH_TIME_SLOTS_SUCCESS: {
            const { timeSlots } = payload;
            return {
                ...state,
                isFetchingTimeSlots: false,
                timeSlots
            };
        }

        case types.BOOKING_ORDER_SUCCESS: {
            return {
                ...state
            };
        }

        case types.RESET_ORDER: {
            return {
                ...state,
                order: DEFAULT_ORDER,
                isConflicted: false
            };
        }

        case types.BOOKING_VERIFY_OTP_SUCCESS: {
            const { token } = payload;
            return {
                ...state,
                order: {
                    ...state.order,
                    token
                }
            };
        }

        case types.GO_BACK_TO_CHOOSING_STAFF: {
            return {
                ...state,
                order: {
                    ...state.order,
                    token: null,
                    date: DEFAULT_DATE,
                    time: null,
                    step: 2,
                    dateIndex: 0,
                    categoryIndex: 0
                }
            };
        }

        case types.FETCH_INCOMING_BOOKINGS: {
            const { inComingBookings } = payload;
            return {
                ...state,
                inComingBookings
            };
        }

        case types.FETCH_CHECKING_DATA_SUCCESS: {
            const { categories, staffs } = payload;
            return {
                ...state,
                categories,
                staffs
            };
        }

        case types.SET_BOOKING_CONFLICTED: {
            const { conflicted } = payload;
            return {
                ...state,
                isConflicted: conflicted
            };
        }

        default:
            return state;
    }
}
