import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, Chip } from '@material-ui/core';
import { getAppendMerchantTimezoneToDate, getDatetimeFormat, getFriendlyTime } from 'utils/timing';
import { useDispatch } from 'react-redux';
import * as analyzeActions from './actions';
import {
    useOfflineGifts,
    useOnlineGifts,
    useRedeemedValue,
    useRemainingValue,
    useOnlineGiftIncome,
    useOfflineGiftIncome,
    useCodes,
    useRedeemHistories
} from 'hooks/statistic';
import LayoutTabContent from 'views/layout/LayoutTabContent';
import PortalDatePicker from 'components/datepicker/PortalReactDatePickerForm';
import moment from 'moment-timezone';
import useMediaQueries from 'hooks/useMediaQueries';
import { useTranslation } from 'react-i18next';
import ArrowNextSVG from 'assets/images/svg/ArrowNextSVG';
import Spinner from 'components/spinner';
import { useMerchantDetail } from 'hooks/merchant';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { orderBy } from 'lodash';
import { REDEEM_TYPES, GIFT_CODE_PAYMENT_TYPES } from 'const';

const useStyles = makeStyles((theme) => ({
    root: {},
    wrapper: {
        padding: theme.spacing(2)
    },
    statisticItem: {
        padding: theme.spacing(2),
        background: theme.palette.common.white,
        borderRadius: theme.radius(3),
        boxSizing: 'border-box',
        minHeight: 200,
        boxShadow: theme.shadows[1]
    },
    title: {
        color: theme.colors.blue,
        fontWeight: 400
    },
    dateItem: {
        minWidth: 250,
        [theme.breakpoints.down('sm')]: {
            minWidth: 'auto',
            width: '100%'
        }
    },
    giftDetail: {
        marginTop: theme.spacing(2),
        overflowY: 'auto',
        overflowX: 'hidden',
        maxHeight: `calc(100% - 16px - 69px)`,
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
            height: `calc(100vh - 48px - 24px - 82px - 82px - 46px)`,
            maxHeight: `-webkit-fill-available`
        },
        position: 'relative'
    },
    spinnerWrapper: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex'
    }
}));

function GiftStatistic() {
    const classes = useStyles();
    const theme = useTheme();
    const { isMobile } = useMediaQueries();

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const offlineGifts = useOfflineGifts();
    const onlineGifts = useOnlineGifts();
    const onlineGiftIncome = useOnlineGiftIncome();
    const offlineGiftIncome = useOfflineGiftIncome();
    const redeemedValue = useRedeemedValue();
    const remainingRedeemValue = useRemainingValue();
    const [startDate, setStartDate] = React.useState(new Date());
    const [endDate, setEndDate] = React.useState(new Date());
    const [startDatePresent, setStartDatePresent] = React.useState('');
    const [endDatePresent, setEndDatePresent] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);
    const merchant = useMerchantDetail();
    const codes = useCodes();
    const redeemHistories = useRedeemHistories();

    const sellCodeHistories = React.useMemo(() => {
        return orderBy(codes, ['createdAt'], ['asc']);
    }, [codes]);

    const currency = React.useMemo(() => {
        return merchant?.currency;
    }, [merchant]);

    const totalGiftIncome = React.useMemo(() => {
        return onlineGiftIncome + offlineGiftIncome;
    }, [onlineGiftIncome, offlineGiftIncome]);

    React.useEffect(() => {
        if (startDate && endDate) {
            const start = getAppendMerchantTimezoneToDate(getDatetimeFormat(startDate));
            const end = getAppendMerchantTimezoneToDate(getDatetimeFormat(endDate));
            setIsLoading(true);

            dispatch(
                analyzeActions.fetchGiftStatistic({
                    query: {
                        startDate: start,
                        endDate: end
                    },
                    successCallback: () => {
                        console.log('fetch analyze success');
                        setIsLoading(false);
                    },
                    errorCallback: () => {
                        console.log('failed to fetch analyze');
                        setIsLoading(false);
                    }
                })
            );
        }
    }, [dispatch, startDate, endDate]);

    const handleStartDateChange = React.useCallback((date) => {
        setStartDate(date);
        setStartDatePresent(moment(date).format(`dddd, DD MMMM YYYY`));
    }, []);

    const handleEndDateChange = React.useCallback((date) => {
        setEndDate(date);
        setEndDatePresent(moment(date).format(`dddd, DD MMMM YYYY`));
    }, []);

    const SELL_CODE_COLUMNS = React.useMemo(() => {
        return [
            {
                width: 150,
                label: 'Sold at',
                id: 'createdAt',
                format: ({ value }) => getFriendlyTime(value)
            },
            {
                width: 100,
                label: 'Amount',
                id: 'retailPrice',
                format: ({ value }) => `${currency}${value?.toFixed(2)}`
            },
            {
                width: 350,
                label: 'Gift',
                id: 'giftName'
            },
            {
                width: 350,
                label: 'Type',
                id: 'paymentType',
                align: 'right',
                format: ({ value }) => {
                    return <Chip label={GIFT_CODE_PAYMENT_TYPES?.[value]} color={'primary'} />;
                }
            }
        ];
    }, [currency]);

    const TABLE_COLUMNS = React.useMemo(() => {
        return [
            {
                width: 150,
                label: 'Redeemed at',
                id: 'createdAt',
                format: ({ value }) => getFriendlyTime(value)
            },
            {
                width: 100,
                label: 'Amount',
                id: 'amount',
                format: ({ value }) => `${currency}${value?.toFixed(2)}`
            },
            // {
            //     width: 350,
            //     label: 'Gift',
            //     id: 'giftName'
            // },
            {
                width: 350,
                label: 'Type',
                id: 'type',
                align: 'right',

                format: ({ value }) => {
                    const isTopUp = value === REDEEM_TYPES.TOP_UP;
                    return <Chip label={isTopUp ? 'Top Up' : 'Offline'} color={isTopUp ? 'secondary' : 'primary'} />;
                }
            }
        ];
    }, [currency]);

    return (
        <LayoutTabContent>
            <Grid container style={{ height: '100%' }} wrap="nowrap" direction="column">
                <Grid item>
                    <Grid container direction="row" alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <Grid container direction="row" alignItems="center" spacing={2}>
                                <Grid item className={classes.dateItem}>
                                    <PortalDatePicker
                                        maxDate={endDatePresent ? endDate : ''}
                                        onChange={handleStartDateChange}
                                        defaultValue={startDate}
                                        label={t(`start_date`)}
                                        placeholderText={t(`closed_date_start_date_placeholder`)}
                                        withPortal={isMobile}
                                        defaultDatePresent={startDatePresent ? startDatePresent : 'Selected date'}
                                        isUsedDatePresent={true}
                                    />
                                </Grid>
                                {!isMobile && (
                                    <Grid item style={{ display: 'flex', position: 'relative', top: 12 }}>
                                        <ArrowNextSVG color={theme.colors.black} />
                                    </Grid>
                                )}
                                <Grid item className={classes.dateItem}>
                                    <PortalDatePicker
                                        minDate={startDatePresent ? startDate : ''}
                                        onChange={handleEndDateChange}
                                        defaultValue={endDate}
                                        defaultDatePresent={endDatePresent ? endDatePresent : 'Selected date'}
                                        label={t(`end_date`)}
                                        withPortal={isMobile}
                                        isUsedDatePresent={true}
                                        placeholderText={t(`closed_date_end_date_placeholder`)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography variant="h4" display="inline">
                                Total:
                            </Typography>{' '}
                            <Typography color="primary" variant="h3" display="inline">
                                {currency}
                                {totalGiftIncome?.toFixed(2)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item direction="column" className={classes.giftDetail}>
                    {isLoading && (
                        <div className={classes.spinnerWrapper}>
                            <Spinner size={40} thick={4} />
                        </div>
                    )}

                    <Grid item direction="column">
                        <Grid item>
                            <Typography variant="h4">Statistics</Typography>
                        </Grid>
                        <Grid item>
                            <Grid container direction="row">
                                <Grid xs={12} md={6} lg={3} item className={classes.wrapper}>
                                    <Grid container direction="column" className={classes.statisticItem}>
                                        <Grid item>
                                            <Typography className={classes.title} variant="h5">
                                                Offline
                                            </Typography>
                                        </Grid>
                                        <Grid item style={{ marginBottom: theme.spacing(2) }}>
                                            <Typography variant="h3">{offlineGifts}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.title} variant="h5">
                                                Offline Income
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h3">
                                                {currency}
                                                {offlineGiftIncome?.toFixed(2)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid xs={12} md={6} lg={3} item className={classes.wrapper}>
                                    <Grid container direction="column" className={classes.statisticItem}>
                                        <Grid item>
                                            <Typography className={classes.title} variant="h5">
                                                Online
                                            </Typography>
                                        </Grid>
                                        <Grid item style={{ marginBottom: theme.spacing(2) }}>
                                            <Typography variant="h3">{onlineGifts}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.title} variant="h5">
                                                Online Income
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h3">
                                                {currency}
                                                {onlineGiftIncome?.toFixed(2)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid xs={12} md={6} lg={3} item className={classes.wrapper}>
                                    <Grid container direction="column" className={classes.statisticItem}>
                                        <Grid item>
                                            <Typography className={classes.title} variant="h5">
                                                Redeemed Value
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h3">
                                                {currency}
                                                {redeemedValue?.toFixed(2)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid xs={12} md={6} lg={3} item className={classes.wrapper}>
                                    <Grid container direction="column" className={classes.statisticItem}>
                                        <Grid item>
                                            <Typography className={classes.title} variant="h5">
                                                Remaining Redeem Value
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h3">
                                                {currency}
                                                {remainingRedeemValue?.toFixed(2)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container direction="row" spacing={2} style={{ overflowX: 'hidden' }}>
                        <Grid item style={{ flex: 1 }}>
                            <Grid container direction="column">
                                <Grid item>
                                    <Typography variant="h4">Sell code histories</Typography>
                                </Grid>
                                <Grid item>
                                    <Grid container direction="column">
                                        <Paper
                                            elevation={0}
                                            style={{
                                                pt: 2,
                                                width: '100%',
                                                overflow: 'hidden',
                                                background: theme.palette.common.white,
                                                color: `rgb(33, 43, 54)`,
                                                backgroundImage: 'none',
                                                position: 'relative',
                                                zIndex: 0,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                marginTop: theme.spacing(2),
                                                boxShadow: theme.shadows[1]
                                            }}
                                        >
                                            <Grid item>
                                                <TableContainer>
                                                    <Table stickyHeader aria-label="sticky table">
                                                        <TableHead>
                                                            <TableRow sx={{}}>
                                                                {SELL_CODE_COLUMNS?.map((column) => (
                                                                    <TableCell
                                                                        key={column.id}
                                                                        align={column.align}
                                                                        className={classes.tableCell}
                                                                        style={{
                                                                            minWidth: column.minWidth,
                                                                            fontWeight: 'bold   '
                                                                        }}
                                                                    >
                                                                        {column.label}
                                                                    </TableCell>
                                                                ))}
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {sellCodeHistories?.length === 0 && (
                                                                <TableRow sx={{ textAlign: 'center' }}>
                                                                    <TableCell style={{ border: 'none' }}>
                                                                        No record found
                                                                    </TableCell>
                                                                </TableRow>
                                                            )}
                                                            {sellCodeHistories?.map((row) => {
                                                                return (
                                                                    <TableRow
                                                                        sx={{
                                                                            '&:hover': {
                                                                                cursor: 'pointer',
                                                                                background: `${theme.colors.hover} !important`
                                                                            }
                                                                        }}
                                                                        role="checkbox"
                                                                        tabIndex={-1}
                                                                        key={row.id}
                                                                    >
                                                                        {SELL_CODE_COLUMNS.map((column) => {
                                                                            const value = row?.[column.id];

                                                                            return (
                                                                                <TableCell
                                                                                    style={{ border: 'none' }}
                                                                                    key={column.id}
                                                                                    align={column.align}
                                                                                >
                                                                                    {column.format
                                                                                        ? column.format({
                                                                                              rowData: row,
                                                                                              value
                                                                                          })
                                                                                        : value}
                                                                                </TableCell>
                                                                            );
                                                                        })}
                                                                    </TableRow>
                                                                );
                                                            })}
                                                        </TableBody>
                                                        <TableHead>
                                                            <TableRow
                                                                sx={{
                                                                    '&:hover': {
                                                                        cursor: 'pointer',
                                                                        background: `${theme.colors.hover} !important`
                                                                    }
                                                                }}
                                                                role="checkbox"
                                                                tabIndex={-1}
                                                            >
                                                                {SELL_CODE_COLUMNS?.map((column, index) => {
                                                                    const notDisplay =
                                                                        index !== 0 &&
                                                                        index !== SELL_CODE_COLUMNS.length - 1;

                                                                    return (
                                                                        <TableCell
                                                                            key={column.id}
                                                                            align={column.align}
                                                                            className={classes.tableCell}
                                                                            style={{
                                                                                minWidth: column.minWidth,
                                                                                fontWeight: 'bold',
                                                                                fontSize: 20
                                                                            }}
                                                                        >
                                                                            {notDisplay
                                                                                ? ``
                                                                                : index === 0
                                                                                ? 'Total'
                                                                                : `${currency}${sellCodeHistories
                                                                                      .reduce((t, code) => {
                                                                                          return code?.retailPrice + t;
                                                                                      }, 0)
                                                                                      .toFixed(2)}`}
                                                                        </TableCell>
                                                                    );
                                                                })}
                                                            </TableRow>
                                                        </TableHead>
                                                    </Table>
                                                </TableContainer>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item style={{ flex: 1 }}>
                            <Grid container direction="column" style={{ marginTop: theme.spacing(3) }}>
                                <Grid item>
                                    <Typography variant="h4">Redeemed histories</Typography>
                                </Grid>
                                <Grid item>
                                    <Grid container direction="column">
                                        <Paper
                                            elevation={0}
                                            style={{
                                                pt: 2,
                                                width: '100%',
                                                overflow: 'hidden',
                                                background: theme.palette.common.white,
                                                color: `rgb(33, 43, 54)`,
                                                backgroundImage: 'none',
                                                position: 'relative',
                                                zIndex: 0,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                marginTop: theme.spacing(2),
                                                boxShadow: theme.shadows[1]
                                            }}
                                        >
                                            <Grid item>
                                                <TableContainer>
                                                    <Table stickyHeader aria-label="sticky table">
                                                        <TableHead>
                                                            <TableRow sx={{}}>
                                                                {TABLE_COLUMNS?.map((column) => (
                                                                    <TableCell
                                                                        key={column.id}
                                                                        align={column.align}
                                                                        className={classes.tableCell}
                                                                        style={{
                                                                            minWidth: column.minWidth,
                                                                            fontWeight: 'bold   '
                                                                        }}
                                                                    >
                                                                        {column.label}
                                                                    </TableCell>
                                                                ))}
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {redeemHistories?.length === 0 && (
                                                                <TableRow sx={{ textAlign: 'center' }}>
                                                                    <TableCell style={{ border: 'none' }}>
                                                                        No record found
                                                                    </TableCell>
                                                                </TableRow>
                                                            )}
                                                            {redeemHistories?.map((row) => {
                                                                return (
                                                                    <TableRow
                                                                        sx={{
                                                                            '&:hover': {
                                                                                cursor: 'pointer',
                                                                                background: `${theme.colors.hover} !important`
                                                                            }
                                                                        }}
                                                                        role="checkbox"
                                                                        tabIndex={-1}
                                                                        key={row.id}
                                                                    >
                                                                        {TABLE_COLUMNS.map((column) => {
                                                                            const value = row?.[column.id];

                                                                            return (
                                                                                <TableCell
                                                                                    style={{ border: 'none' }}
                                                                                    key={column.id}
                                                                                    align={column.align}
                                                                                >
                                                                                    {column.format
                                                                                        ? column.format({
                                                                                              rowData: row,
                                                                                              value
                                                                                          })
                                                                                        : value}
                                                                                </TableCell>
                                                                            );
                                                                        })}
                                                                    </TableRow>
                                                                );
                                                            })}
                                                        </TableBody>
                                                        <TableHead>
                                                            <TableRow
                                                                sx={{
                                                                    '&:hover': {
                                                                        cursor: 'pointer',
                                                                        background: `${theme.colors.hover} !important`
                                                                    }
                                                                }}
                                                                role="checkbox"
                                                                tabIndex={-1}
                                                            >
                                                                {TABLE_COLUMNS?.map((column, index) => {
                                                                    const notDisplay =
                                                                        index !== 0 &&
                                                                        index !== TABLE_COLUMNS.length - 1;

                                                                    return (
                                                                        <TableCell
                                                                            key={column.id}
                                                                            align={column.align}
                                                                            className={classes.tableCell}
                                                                            style={{
                                                                                minWidth: column.minWidth,
                                                                                fontWeight: 'bold',
                                                                                fontSize: 20
                                                                            }}
                                                                        >
                                                                            {notDisplay ? (
                                                                                ``
                                                                            ) : index === 0 ? (
                                                                                'Total'
                                                                            ) : (
                                                                                <div>
                                                                                    <div>
                                                                                        Redeem:{' '}
                                                                                        {`${currency}${redeemHistories
                                                                                            ?.filter(
                                                                                                (history) =>
                                                                                                    history?.type !==
                                                                                                    REDEEM_TYPES.TOP_UP
                                                                                            )
                                                                                            ?.reduce((t, history) => {
                                                                                                return (
                                                                                                    history?.amount + t
                                                                                                );
                                                                                            }, 0)
                                                                                            .toFixed(2)}`}
                                                                                    </div>
                                                                                    <div>
                                                                                        Topup:{' '}
                                                                                        {`${currency}${redeemHistories
                                                                                            ?.filter(
                                                                                                (history) =>
                                                                                                    history?.type ===
                                                                                                    REDEEM_TYPES.TOP_UP
                                                                                            )
                                                                                            ?.reduce((t, history) => {
                                                                                                return (
                                                                                                    history?.amount + t
                                                                                                );
                                                                                            }, 0)
                                                                                            .toFixed(2)}`}
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </TableCell>
                                                                    );
                                                                })}
                                                            </TableRow>
                                                        </TableHead>
                                                    </Table>
                                                </TableContainer>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </LayoutTabContent>
    );
}

export default GiftStatistic;
