import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LIST_ITEM_HEIGHT, PADDING_LIST_ITEM_LEFT_RIGHT } from 'const/style';
import CheckIconSVG from 'assets/images/svg/CheckIconSVG';
import ButtonIconWrapper from 'components/button/ButtonIconWrapper';
import hexToRgba from 'hex-to-rgba';

const useStyles = makeStyles((theme) => ({
    root: {
        cursor: 'pointer',
        height: (props) => props.height || LIST_ITEM_HEIGHT,
        '&:hover ': {
            background: theme.colors.hover,
            borderRadius: theme.radius(2)
        },
        padding: PADDING_LIST_ITEM_LEFT_RIGHT
    },
    selected: {
        background: hexToRgba(theme.palette.primary.main, 0.2)
        // fontWeight: 900
    },
    icon: {
        display: 'flex',
        marginRight: PADDING_LIST_ITEM_LEFT_RIGHT
    }
    // expand: {
    //     flex: 1
    // }
}));

function ListItem({
    isSelected,
    className,
    name,
    description,
    icon: Icon,
    moreIcon: MoreIcon,
    noPadding = false,
    height,
    ...rest
}) {
    const classes = useStyles({ noPadding, height });

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            wrap="nowrap"
            className={`${classes.root} ${className} ${isSelected ? classes.selected : ''}`}
            {...rest}
        >
            <Grid item>
                <Grid container alignItems="center" justifyContent="space-between" direction="row" wrap="nowrap">
                    <Grid item className={classes.expand}>
                        <Grid container alignItems="center" wrap="nowrap">
                            {Icon && (
                                <Grid item className={classes.icon}>
                                    <ButtonIconWrapper background={'transparent'} color="primary" icon={Icon} />
                                </Grid>
                            )}
                            <Grid item>
                                <Typography component="div" variant="body2">
                                    {name}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container alignItems="center" wrap="nowrap">
                            {MoreIcon && (
                                <Grid item>
                                    <MoreIcon />
                                </Grid>
                            )}
                            {isSelected && (
                                <Grid item>
                                    <CheckIconSVG />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {description && (
                <Grid item>
                    <Typography variant="body1" component="div">
                        {description}
                    </Typography>
                </Grid>
            )}
        </Grid>
    );
}

export default React.memo(ListItem);
