import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputBase from 'components/input/Form';
import ButtonBase from 'components/button/Base';
import { useFormik } from 'formik';
import { string, object } from 'yup';
import { CircularProgress, Typography, Grid } from '@material-ui/core';
import ColorPalette from 'components/colorPalette';
import { colorPaletteOptions } from 'utils/color';
import * as categoryActions from './action/category';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DialogActions from 'components/dialog/Action';
import DialogTitle from 'components/dialog/Title';
import DialogContent from 'components/dialog/Content';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/AddCircle';
import SaveIcon from '@material-ui/icons/Save';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

const useStyles = makeStyles((theme) => ({
    spacing: {
        marginBottom: 18
    },
    input: {
        minWidth: 320
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    btnSubmit: {
        position: 'relative',
        width: '100%'
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    ColorPalette: {
        paddingTop: theme.spacing(2)
    },
    actions: {
        paddingLeft: 0,
        paddingRight: 0
    },
    title: {
        paddingLeft: 0,
        paddingRight: 0
    },
    editor: {
        '& .editor-wrapper': {
            border: `1px solid ${theme.colors.border}`,
            borderRadius: 10
        },
        '& .editor': {
            minHeight: 150,
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingBottom: theme.spacing(2)
        },
        '& .public-DraftStyleDefault-block': {
            margin: `2px 0px!important`
        }
    }
}));

const CategorySchema = object({
    title: string().required('This filed is required')
});

function AddCategory({ isEdit, editedCategory, onClose, onAfterSuccess }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [selectedColor, setSelectedColor] = React.useState(isEdit ? editedCategory.color : colorPaletteOptions[0]);

    const categoryDescriptionDefaultState = React.useMemo(() => {
        const blocksFromHtml = htmlToDraft(editedCategory?.description || ``);
        const { contentBlocks, entityMap } = blocksFromHtml;
        return ContentState.createFromBlockArray(contentBlocks, entityMap);
    }, [editedCategory]);

    const defaultDescription = React.useMemo(() => {
        return editedCategory?.description
            ? EditorState.createWithContent(categoryDescriptionDefaultState)
            : EditorState.createEmpty();
    }, [categoryDescriptionDefaultState, editedCategory]);

    const [description, setDescription] = React.useState(defaultDescription);

    const formik = useFormik({
        initialValues: {
            title: isEdit ? editedCategory?.title || '' : ''
        },
        validationSchema: CategorySchema,
        onSubmit: (values, { setSubmitting, setStatus }) => {
            setStatus({ error: null });
            setSubmitting(true);

            const newDescription = draftToHtml(convertToRaw(description.getCurrentContent()));

            if (!isEdit) {
                dispatch(
                    categoryActions.createCategory({
                        body: {
                            ...values,
                            description: newDescription,
                            color: selectedColor
                        },
                        errorCallback: () => {
                            console.log('create category failed');
                            setSubmitting(false);
                        },
                        successCallback: (ct) => {
                            console.log('create category success');
                            onClose && onClose();
                            onAfterSuccess && onAfterSuccess(ct);
                        }
                    })
                );
            } else {
                dispatch(
                    categoryActions.updateCategory({
                        id: editedCategory.id,
                        body: {
                            ...values,
                            description: newDescription,
                            color: selectedColor
                        },
                        errorCallback: () => {
                            setSubmitting(false);

                            console.log('create category failed');
                        },
                        successCallback: (ct) => {
                            onClose && onClose();
                            onAfterSuccess && onAfterSuccess(ct);
                        }
                    })
                );
            }

            setStatus({ error: null });
        }
    });
    const { values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid } = formik;

    const handleColorChange = ({ e, color }) => {
        setSelectedColor(color);
    };

    return (
        <form onSubmit={handleSubmit} className={classes.form}>
            <DialogTitle>
                <Typography variant="h5">
                    {isEdit ? t('update') : t('add')} {t('category')}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <InputBase
                    fullWidth
                    className={`${classes.spacing} ${classes.input}`}
                    label={t('category_name')}
                    placeholder={t('category_name_placeholder')}
                    name="title"
                    value={values.title || ''}
                    disabled={isSubmitting}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.title && touched.title}
                    errorText={errors.title}
                    autoFocus={true}
                />
                {/* <InputBase
                    fullWidth
                    multiline
                    className={classes.input}
                    label={t('category_description')}
                    placeholder={t('category_description_placeholder')}
                    name="description"
                    value={values.description || ''}
                    disabled={isSubmitting}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.description && touched.description}
                    errorText={errors.description}
                    isShowChecked={false}
                /> */}
                <Grid container direction="column" spacing={1}>
                    <Grid item>
                        <Typography variant="body1">{t('category_description')}</Typography>
                    </Grid>
                    <Grid item className={classes.editor}>
                        <Editor
                            editorState={description}
                            wrapperClassName="editor-wrapper"
                            editorClassName="editor"
                            onEditorStateChange={(editor) => setDescription(editor)}
                            toolbar={{
                                options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign'],
                                inline: {
                                    options: ['bold', 'italic', 'underline', 'strikethrough']
                                },
                                list: { options: ['unordered', 'ordered'] },
                                link: { inDropdown: true },
                                textAlign: { inDropdown: true }
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid container direction="column" className={classes.ColorPalette}>
                    <Grid item style={{ paddingBottom: 14 }}>
                        <Typography variant="body1">{t('choose_color')}</Typography>
                    </Grid>
                    <Grid item style={{ paddingBottom: 14 }}>
                        <ColorPalette
                            width={30}
                            height={30}
                            svg={20}
                            selectedColor={selectedColor}
                            onChange={handleColorChange}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container alignItems="center" direction="row" spacing={2} justifyContent="flex-end">
                    <Grid item>
                        <ButtonBase startIcon={<CloseIcon />} onClick={onClose} variant="outlined">
                            {t('close')}
                        </ButtonBase>
                    </Grid>
                    <Grid item>
                        <ButtonBase
                            disabled={isSubmitting || !isValid}
                            variant="contained"
                            color="primary"
                            type="submit"
                            startIcon={isEdit ? <SaveIcon /> : <AddIcon />}
                        >
                            {isEdit ? t('save') : t('create')}
                        </ButtonBase>
                    </Grid>
                </Grid>
                {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
            </DialogActions>
        </form>
    );
}

export default React.memo(AddCategory);
