import { useSelector } from 'react-redux';
import { generateOptionsMinutes, isSameToday } from 'utils/timing';
import { BOOKING_CHECKOUT_STATES, BOOKING_SERVICE_STATES, BOOKING_STATES, ROW_HEIGHT } from 'const';
import { isEqual, orderBy } from 'lodash';
import { convertStaffFiltered } from 'utils/app/staff';
import { convertDefaultMinutes, convertSnapY } from 'utils/app/calendar/index';

export function useBookings() {
    return useSelector((state) => state.calendar?.bookings);
}

export function useGiftCodes() {
    return useSelector((state) => state.calendar?.giftCodes);
}

export function useStaffBookings(staffId) {
    return useSelector(
        (state) => {
            const staffBookings = state?.calendar?.bookings;
            const bookingServices = staffBookings?.[staffId];
            return bookingServices
                ?.filter((bkSv) => {
                    return bkSv?.status !== BOOKING_SERVICE_STATES.WAIT_FOR_CHECK_IN;
                })
                ?.filter((bkSv) => {
                    return ![BOOKING_STATES.PENDING_PAYMENT, BOOKING_STATES.SEND_OTP].includes(bkSv?.booking?.state);
                });
        },
        (prev, current) => isEqual(prev, current)
    );
}

export function useStaffWorkingHours() {
    return useSelector((state) => state.calendar?.staffWorkingHours);
}

export function useStaffWorkingHoursByStaffId(staffId) {
    return useSelector(
        (state) => state.calendar?.staffWorkingHours?.[+staffId],
        (prev, current) => isEqual(prev, current)
    );
}

export function useStaffOriginalStaffSchedule() {
    return useSelector((state) => state.calendar?.originalStaffSchedule);
}

export function useStaffOriginalStaffScheduleByStaffId(staffId) {
    return useSelector(
        (state) => state.calendar?.originalStaffSchedule?.[+staffId],
        (prev, current) => isEqual(prev, current)
    );
}

export function useStaffBlockedTimes() {
    return useSelector((state) => state.calendar?.blockedTimes);
}

export function useStaffBlockedTimeByStaffId(staffId) {
    return useSelector(
        (state) => state.calendar?.blockedTimes?.[+staffId],
        (prev, current) => isEqual(prev, current)
    );
}

export function useInitializing() {
    return useSelector((state) => state.calendar?.isInitializing);
}

export function useIsFirstLoaded() {
    return useSelector((state) => state.calendar?.isFirstLoaded);
}

export function useIsFetchingBookings() {
    return useSelector((state) => state.calendar?.isFetchingBookings);
}

export function useToggleCloseDate() {
    return useSelector((state) => state.calendar?.toggleCloseDate);
}

export function useClientBookings() {
    return useSelector((state) => {
        const bookingByStaffs = state.calendar?.bookings;
        if (!bookingByStaffs) return {};

        const staffIds = Object.keys(bookingByStaffs);

        const bookingByClients = {};

        for (const staffId of staffIds) {
            const bookingServices = bookingByStaffs?.[staffId];

            for (const bkSv of bookingServices) {
                const clientId = bkSv?.booking?.clientId;
                if (clientId) {
                    if (!bookingByClients?.[clientId]) {
                        bookingByClients[clientId] = [];
                    }
                    bookingByClients[clientId] = [...bookingByClients?.[clientId], bkSv];
                }
            }
        }

        return bookingByClients;
    });
}

export function useClientArrivedBookings() {
    return useSelector((state) => {
        const bookingByStaffs = state.calendar?.bookings;
        if (!bookingByStaffs) return {};

        const staffIds = Object.keys(bookingByStaffs);

        const clientsArrived = {};
        const clientDetail = {};

        for (const staffId of staffIds) {
            const bookingServices = bookingByStaffs?.[staffId];

            for (const bkSv of bookingServices) {
                const isArrived = bkSv?.booking?.state === BOOKING_STATES.CLIENT_ARRIVED;
                const clientId = bkSv?.booking?.clientId || `_fake_${bkSv?.booking?.id}`;
                if (isArrived && clientId) {
                    if (!clientsArrived?.[clientId]) {
                        clientsArrived[clientId] = [];
                        clientDetail[clientId] = {};
                    }
                    clientsArrived[clientId] = [...clientsArrived?.[clientId], bkSv];
                    const client = bkSv?.booking?.client;
                    clientDetail[clientId] = {
                        ...client,
                        firstName: client?.firstName || bkSv?.booking?.title?.split(' ')?.[0],
                        lastName: client?.lastName || bkSv?.booking?.title?.split(' ')?.[1]
                    };
                }
            }
        }

        return { clientsArrived, clientDetail };
    });
}

export function useArrivedListSortedByUpdatedTime() {
    return useSelector((state) => {
        const bookingByStaffs = state.calendar?.bookings;
        const services = state.service?.serviceList;
        const serviceMapping = services?.reduce((obj, service) => {
            obj[service?.id] = service;
            return obj;
        }, {});

        if (!bookingByStaffs) return [];

        const staffIds = Object.keys(bookingByStaffs);
        const clientsArrived = [];
        let bookingCached = {};

        for (const staffId of staffIds) {
            const bookingServices = bookingByStaffs?.[staffId];

            for (const bkSv of bookingServices) {
                const booking = bkSv?.booking;
                const isAllBkSvArrived = !booking?.bookingServices?.some(
                    (bkSv) => bkSv?.status !== BOOKING_SERVICE_STATES.ARRIVED
                );
                const isBookingArrived = booking?.state === BOOKING_STATES.CLIENT_ARRIVED;

                if (!bookingCached?.[bkSv?.bookingId]) {
                    if (isAllBkSvArrived && isBookingArrived) {
                        const client = booking?.client;
                        const clientDetail = {
                            ...booking?.client,
                            firstName: client?.firstName || booking?.title?.split(' ')?.[0],
                            lastName: client?.lastName || booking?.title?.split(' ')?.[1],
                            updatedAt: booking?.updatedAt,
                            bookingId: booking?.id,
                            canDoCategories: booking?.bookingServices?.map(
                                (bkSv) => serviceMapping?.[bkSv?.serviceId]?.category?.color
                            )
                        };
                        clientsArrived.push(clientDetail);
                    }
                }

                bookingCached[bkSv?.bookingId] = true;
            }
        }

        return orderBy(
            clientsArrived,
            [
                (obj) => {
                    return new Date(obj.updatedAt);
                }
            ],
            ['asc']
        );
    });
}

export function useWaitBookings() {
    const bookingByStaffs = useBookings();

    if (!bookingByStaffs) return [];

    const staffIds = Object.keys(bookingByStaffs);
    const waitBookings = [];
    const cached = {};

    for (const staffId of staffIds) {
        const bookingServices = bookingByStaffs?.[staffId];
        for (const bkSv of bookingServices) {
            const booking = bkSv?.booking;

            const isWait = booking?.state === BOOKING_STATES.WAIT;

            if (isWait && !cached?.[booking?.id]) {
                waitBookings.push(booking);
                cached[booking?.id] = true;
            }
        }
    }

    return waitBookings;
}

export function useDoneBookingsWithoutPaid() {
    return useSelector((state) => {
        const bookingByStaffs = state.calendar?.bookings;
        if (!bookingByStaffs) return {};

        const staffIds = Object.keys(bookingByStaffs);

        const clientsDone = {};
        const clientDoneDetail = {};

        for (const staffId of staffIds) {
            const bookingServices = bookingByStaffs?.[staffId];

            for (const bkSv of bookingServices) {
                const isDone = bkSv?.booking?.state === BOOKING_STATES.END;
                const isNotPaid = bkSv?.booking?.paid === BOOKING_CHECKOUT_STATES.NOT_PAY;
                const clientId = bkSv?.booking?.clientId || `_fake_${bkSv?.booking?.id}`;

                if (isDone && clientId && isNotPaid) {
                    if (!clientsDone?.[clientId]) {
                        clientsDone[clientId] = [];
                        clientDoneDetail[clientId] = {};
                    }
                    clientsDone[clientId] = [...clientsDone?.[clientId], bkSv];

                    const client = bkSv?.booking?.client;
                    clientDoneDetail[clientId] = {
                        ...client,
                        firstName: client?.firstName || bkSv?.booking?.title?.split(' ')?.[0],
                        lastName: client?.lastName || bkSv?.booking?.title?.split(' ')?.[1]
                    };
                }
            }
        }

        return { clientsDone, clientDoneDetail };
    });
}

export function useZoom() {
    return useSelector((state) => state.calendar.zoom);
}

export function useRowHeight() {
    return useSelector((state) => state.calendar.ROW_HEIGHT);
}

export function useDefaultMinutes() {
    const zoom = useZoom();
    return convertDefaultMinutes(zoom);
}

export function useRatio() {
    const defaultMinutes = useDefaultMinutes();
    return ROW_HEIGHT / defaultMinutes;
}

export function useCalendarOptions() {
    const defaultMinutes = useDefaultMinutes();
    return generateOptionsMinutes(defaultMinutes);
}

export function useCalendarOptionsLength() {
    const calendarOptions = useCalendarOptions();
    return calendarOptions?.length;
}

export function useSnapY() {
    const zoom = useZoom();
    return convertSnapY(zoom);
}
export function useZoomSettingValue() {
    return useSelector((state) => state.calendar?.zoom);
}

export function useSelectedDate() {
    return useSelector((state) => state.calendar?.selectedDate);
}

export function useIsNewCheckIn() {
    return useSelector((state) => state.calendar?.isNewCheckIn);
}

export function useBookingLogsByBookingId(bookingId) {
    return useSelector((state) => {
        return state.calendar?.bookingLogs?.[bookingId]?.logs;
    });
}

export function useIsOpenClientArrived() {
    return useSelector((state) => {
        return state.calendar?.isOpenClientArrived;
    });
}

export function useIsOpenCheckout() {
    return useSelector((state) => {
        return state.calendar?.isOpenCheckout;
    });
}

export function useIsSameToday() {
    return useSelector((state) => {
        return isSameToday(state?.calendar?.selectedDate);
    });
}

export function useFilteredStaff() {
    return useSelector((state) => {
        return state.calendar?.filteredStaff;
    });
}

export function useStaffFilteredIds() {
    return useSelector(
        (state) => {
            const filteredStaff = state.calendar?.filteredStaff;
            const staffs = state?.staff?.list;
            const staffWorkingHours = state.calendar?.staffWorkingHours;
            const bookingsByStaff = state.calendar?.bookings;
            const results = convertStaffFiltered({ staffs, filteredStaff, staffWorkingHours, bookingsByStaff });
            return results?.map((staff) => staff?.id);
        },
        (prev, current) => isEqual(prev, current)
    );
}

export function useFindBookingId() {
    return useSelector((state) => {
        return state?.calendar?.findBookingId;
    });
}

export function useIsDragging() {
    return useSelector((state) => {
        return state?.calendar?.isDragging;
    });
}

export function useDragBkSv() {
    return useSelector((state) => {
        return state?.calendar?.dragBkSv;
    });
}

export function useScheduleType() {
    return useSelector((state) => {
        return state?.calendar?.scheduleType;
    });
}

export function useCloneTime() {
    return useSelector((state) => {
        return state?.calendar?.cloneTime;
    });
}

export function useCloneStaff() {
    return useSelector((state) => {
        return state?.calendar?.cloneStaff;
    });
}

export function useBookingServiceById({ staffId, bkSvId }) {
    return useSelector(
        (state) => {
            const staffBookings = state?.calendar?.bookings?.[staffId];
            return staffBookings?.find((bkSv) => +bkSv?.id === +bkSvId);
        },
        (prev, current) => {
            return isEqual(prev, current);
        }
    );
}
