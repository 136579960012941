import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import store from './store/configStore';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import './i18n';
import { PRODUCTION } from 'config/env';
import { ENV } from 'config';
import 'simplebar/dist/simplebar.min.css';
import './main.scss';
import 'react-dates/initialize';
import { CssBaseline } from '@material-ui/core';

if (ENV === PRODUCTION) {
    console.log = function () {};
}

ReactDOM.render(
    <React.Fragment>
        <Provider store={store}>
            <React.Fragment>
                <CssBaseline />
                <App />
            </React.Fragment>
        </Provider>
    </React.Fragment>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
