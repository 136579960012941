import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';

const StyledDialogContent = withStyles((theme) => ({
    root: {
        background: theme.palette.common.white,
        padding: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1)
        }
    }
}))(DialogContent);

export default StyledDialogContent;
