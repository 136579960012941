import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import LayoutTabContent from 'views/layout/LayoutTabContent';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment-timezone';
import './main.css';
import useMediaQueries from 'hooks/useMediaQueries';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { getCurrentDateFormat, getAppendMerchantTimezoneToDate, getDatetimeFormat } from 'utils/timing';
import { useDispatch } from 'react-redux';
import * as analyzeActions from './actions';
import {
    useIncomeTicketsData,
    useTop5Services,
    useTop5Technicians,
    useTotalIncome,
    useTotalTickets
} from 'hooks/analyze';
import Spinner from 'components/spinner';
import PortalDatePicker from 'components/datepicker/PortalReactDatePickerForm';
import { useTranslation } from 'react-i18next';
import ArrowNextSVG from 'assets/images/svg/ArrowNextSVG';

const useStyles = makeStyles((theme) => ({
    root: {},
    chartWrapper: {
        boxSizing: 'border-box',
        background: theme.palette.common.white,
        padding: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2)
        }
    },
    chart: {
        height: 300,
        width: '100%',
        paddingTop: theme.spacing(3)
    },
    chartDetail: {
        height: `calc(100vh - 48px - 24px - 76px - 68px)`,
        overflowY: 'auto',
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
            height: `calc(100vh - 48px - 24px - 82px - 82px - 46px)`,
            maxHeight: `-webkit-fill-available`
        },
        position: 'relative'
    },
    centerText: {
        textAlign: 'center'
    },
    spinnerWrapper: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex'
    },
    fullWidth: {
        width: '100%'
    },
    dateItem: {
        minWidth: 250,
        [theme.breakpoints.down('sm')]: {
            minWidth: 'auto',
            width: '100%'
        }
    }
}));

// const STATISTIC_MODE = {
//     DAY: 'DAY',
//     WEEK: `WEEK`
// };

function Dashboard() {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { isMobile } = useMediaQueries();
    const [startDate, setStartDate] = React.useState(new Date());
    const [endDate, setEndDate] = React.useState(new Date());
    const [startDatePresent, setStartDatePresent] = React.useState('');
    const [endDatePresent, setEndDatePresent] = React.useState('');

    // const [focusedInput, setFocusedInput] = React.useState(null);
    const top5Services = useTop5Services();
    const top5Technicians = useTop5Technicians();
    const incomeTicketsData = useIncomeTicketsData();
    const totalIncome = useTotalIncome();
    const totalTickets = useTotalTickets();
    // const [mode, setMode] = React.useState(STATISTIC_MODE.DAY);
    const [isLoading, setIsLoading] = React.useState(false);

    // const onDatesChange = ({ startDate, endDate }) => {
    //     setStartDate(startDate);
    //     setEndDate(endDate);
    // };

    React.useEffect(() => {
        if (startDate && endDate) {
            const start = getAppendMerchantTimezoneToDate(getDatetimeFormat(startDate));
            const end = getAppendMerchantTimezoneToDate(getDatetimeFormat(endDate));
            setIsLoading(true);
            dispatch(
                analyzeActions.fetchAnalyzeRange({
                    startDate: start,
                    endDate: end,
                    successCallback: () => {
                        console.log('fetch analyze success');
                        setIsLoading(false);
                    },
                    errorCallback: () => {
                        console.log('failed to fetch analyze');
                        setIsLoading(false);
                    }
                })
            );
        }
    }, [dispatch, startDate, endDate]);

    // const onSingleDateChange = React.useCallback((date) => {
    //     setStartDate(date.format());
    //     setEndDate(date.format());
    // }, []);

    // const onWeekDateChange = React.useCallback((range) => {
    //     if (range?.length > 0) {
    //         const dateStart = range?.[0]?.date;
    //         const dateEnd = range?.[range?.length - 1]?.date;
    //         setStartDate(dateStart);
    //         setEndDate(dateEnd);
    //     }
    // }, []);

    // const orientation = React.useMemo(() => {
    //     return isMobile ? 'vertical' : 'horizontal';
    // }, [isMobile]);

    const handleStartDateChange = React.useCallback((date) => {
        setStartDate(date);
        setStartDatePresent(moment(date).format(`dddd, DD MMMM YYYY`));
    }, []);

    const handleEndDateChange = React.useCallback((date) => {
        setEndDate(date);
        setEndDatePresent(moment(date).format(`dddd, DD MMMM YYYY`));
    }, []);

    return (
        <LayoutTabContent isLoading={false}>
            <Grid container wrap="nowrap" className={classes.root} direction="column" spacing={4}>
                <Grid item>
                    <Grid
                        container
                        direction={isMobile ? 'column' : 'row'}
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        {/* <Grid item className={isMobile ? classes.fullWidth : ``}>
                            <ButtonGroup
                                fullWidth={isMobile}
                                color="primary"
                                aria-label="outlined primary button group"
                            >
                                <Button
                                    variant={mode === STATISTIC_MODE.DAY ? 'contained' : 'outlined'}
                                    onClick={() => setMode(STATISTIC_MODE.DAY)}
                                >
                                    Day
                                </Button>
                                <Button
                                    variant={mode === STATISTIC_MODE.WEEK ? 'contained' : 'outlined'}
                                    onClick={() => setMode(STATISTIC_MODE.WEEK)}
                                >
                                    Week
                                </Button>
                            </ButtonGroup>
                        </Grid> */}

                        <Grid item className={isMobile ? classes.fullWidth : ``}>
                            <Grid container direction="row" alignItems="center" spacing={2}>
                                <Grid item className={classes.dateItem}>
                                    <PortalDatePicker
                                        maxDate={endDatePresent ? endDate : ''}
                                        onChange={handleStartDateChange}
                                        defaultValue={startDate}
                                        label={t(`start_date`)}
                                        placeholderText={t(`closed_date_start_date_placeholder`)}
                                        withPortal={isMobile}
                                        defaultDatePresent={startDatePresent ? startDatePresent : 'Selected date'}
                                        isUsedDatePresent={true}
                                    />
                                </Grid>
                                {!isMobile && (
                                    <Grid item style={{ display: 'flex', position: 'relative', top: 12 }}>
                                        <ArrowNextSVG color={theme.colors.black} />
                                    </Grid>
                                )}
                                <Grid item className={classes.dateItem}>
                                    <PortalDatePicker
                                        minDate={startDatePresent ? startDate : ''}
                                        onChange={handleEndDateChange}
                                        defaultValue={endDate}
                                        defaultDatePresent={endDatePresent ? endDatePresent : 'Selected date'}
                                        label={t(`end_date`)}
                                        withPortal={isMobile}
                                        isUsedDatePresent={true}
                                        placeholderText={t(`closed_date_end_date_placeholder`)}
                                    />
                                </Grid>
                            </Grid>
                            {/* <Grid container direction="row" alignItems="center" spacing={2}>
                                <Grid
                                    item
                                    className={isMobile ? classes.fullWidth : ``}
                                    style={{
                                        marginTop: isMobile ? theme.spacing(2) : ``,
                                        marginBottom: isMobile ? theme.spacing(2) : ``
                                    }}
                                >
                                    {mode === STATISTIC_MODE?.DAY ? (
                                        <SingleDate disabled={isLoading} onChange={onSingleDateChange} />
                                    ) : (
                                        <DateRange disabled={isLoading} onRangeChanged={onWeekDateChange} />
                                    )}
                                    
                                </Grid>
                            </Grid> */}
                        </Grid>
                        <Grid item>
                            <Grid container wrap="nowrap" direction="row" spacing={4}>
                                <Grid item>
                                    <Grid container direction="row" alignItems="center" spacing={2}>
                                        <Grid item>
                                            <Typography variant="h4" style={{ fontWeight: 'normal' }} display="inline">
                                                Income:
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                style={{ color: theme.colors.blue }}
                                                variant={isMobile ? 'h3' : 'h2'}
                                                display="inline"
                                            >
                                                ${totalIncome?.toLocaleString()}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container direction="row" alignItems="center" spacing={2}>
                                        <Grid item>
                                            <Typography variant="h4" style={{ fontWeight: 'normal' }} display="inline">
                                                Tickets:
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                style={{ color: theme.colors.blue }}
                                                variant={isMobile ? 'h3' : 'h2'}
                                                display="inline"
                                            >
                                                {totalTickets}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.chartDetail}>
                    {isLoading && (
                        <div className={classes.spinnerWrapper}>
                            <Spinner size={40} thick={4} />
                        </div>
                    )}
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Grid
                                className={classes.chartWrapper}
                                container
                                alignItems="center"
                                justifyContent="center"
                                direction="column"
                            >
                                <Grid item>
                                    <Typography className={classes.centerText} variant="body1">
                                        Total Income from: {getCurrentDateFormat(startDate)} →{' '}
                                        {getCurrentDateFormat(endDate)}
                                    </Typography>
                                </Grid>
                                <Grid item className={classes.chart}>
                                    <ResponsiveContainer width="100%" height="100%">
                                        <BarChart
                                            width={500}
                                            height={300}
                                            data={incomeTicketsData}
                                            margin={{
                                                top: 5,
                                                right: 30,
                                                left: 20,
                                                bottom: 5
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="label" />
                                            <YAxis />
                                            <Tooltip />
                                            <Legend />
                                            <Bar dataKey="income" fill={theme.colors.blue} />
                                        </BarChart>
                                    </ResponsiveContainer>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Grid
                                className={classes.chartWrapper}
                                container
                                alignItems="center"
                                justifyContent="center"
                                direction="column"
                            >
                                <Grid item>
                                    <Typography className={classes.centerText} variant="body1">
                                        Total Tickets from: {getCurrentDateFormat(startDate)} →{' '}
                                        {getCurrentDateFormat(endDate)}
                                    </Typography>
                                </Grid>
                                <Grid item className={classes.chart}>
                                    <ResponsiveContainer width="100%" height="100%">
                                        <BarChart
                                            width={500}
                                            height={300}
                                            data={incomeTicketsData}
                                            margin={{
                                                top: 5,
                                                right: 30,
                                                left: 20,
                                                bottom: 5
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="label" />
                                            <YAxis />
                                            <Tooltip />
                                            <Legend />
                                            <Bar dataKey="tickets" fill={theme.colors.green} />
                                        </BarChart>
                                    </ResponsiveContainer>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid
                                className={classes.chartWrapper}
                                container
                                alignItems="center"
                                justifyContent="center"
                                direction="column"
                            >
                                <Grid item>
                                    <Typography className={classes.centerText} variant="body1">
                                        Top 5 Technicians Income from: {getCurrentDateFormat(startDate)} →{' '}
                                        {getCurrentDateFormat(endDate)}
                                    </Typography>
                                </Grid>
                                <Grid item className={classes.chart}>
                                    <ResponsiveContainer width="100%" height="100%">
                                        <BarChart
                                            width={500}
                                            height={300}
                                            data={top5Technicians}
                                            margin={{
                                                top: 5,
                                                right: 30,
                                                left: 20,
                                                bottom: 5
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="label" />
                                            <YAxis />
                                            <Tooltip />
                                            <Legend />
                                            <Bar dataKey="income" fill={theme.colors.pink} />
                                        </BarChart>
                                    </ResponsiveContainer>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid
                                className={classes.chartWrapper}
                                container
                                alignItems="center"
                                justifyContent="center"
                                direction="column"
                            >
                                <Grid item>
                                    <Typography className={classes.centerText} variant="body1" display="inline">
                                        Top 5 Services from: {getCurrentDateFormat(startDate)} →{' '}
                                        {getCurrentDateFormat(endDate)}
                                    </Typography>{' '}
                                    <Typography variant="body1"></Typography>
                                </Grid>
                                <Grid item className={classes.chart}>
                                    <ResponsiveContainer width="100%" height="100%">
                                        <BarChart
                                            width={500}
                                            height={300}
                                            data={top5Services}
                                            margin={{
                                                top: 5,
                                                right: 30,
                                                left: 20,
                                                bottom: 5
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="label" />
                                            <YAxis />
                                            <Tooltip />
                                            <Legend />
                                            <Bar dataKey="income" fill={theme.colors.darkPink} />
                                        </BarChart>
                                    </ResponsiveContainer>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </LayoutTabContent>
    );
}

export default React.memo(Dashboard);
