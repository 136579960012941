import React from 'react';
import { Paper } from '@material-ui/core';
import CurrentTimeline from '../CurrentTimeline';
import sortBy from 'lodash/sortBy';
import orderBy from 'lodash/orderBy';
import {
    getEndTimeByStartTimeAndDuration,
    getCalendarStyleByTimeShift,
    getCalendarStyleByStartTime
} from 'utils/timing';
import BookingResizable from './BookingResizable';
import {
    useRatio,
    useStaffBlockedTimeByStaffId,
    useStaffBookings,
    useStaffWorkingHoursByStaffId
} from 'hooks/calendar';
import useMediaQueries from 'hooks/useMediaQueries';
import { useStaffById } from 'hooks/staff';

function getConflictIdsWidthOrders({ bkSvId, conflictObj, bookingServicesSortedByStartTime }) {
    const otherIds = conflictObj?.[bkSvId];
    let ids = [];
    for (let id of otherIds) {
        const anotherIds = conflictObj?.[id];
        ids = ids.concat(anotherIds);
    }
    ids = [...new Set(ids)];
    const BkIdIndex = ids?.map((id) => {
        const findIndex = bookingServicesSortedByStartTime?.findIndex((bkSv) => +bkSv?.id === +id);
        return {
            id,
            order: findIndex
        };
    });
    const bkSvOrdered = orderBy(BkIdIndex, ['order'], 'asc');

    return bkSvOrdered?.map((bkSv) => +bkSv?.id);
}

function Bookings({
    staffId,
    onRefreshStaffBlockedTimes,
    onReSchedule,
    onReBooking,
    onOpenBooking,
    onCloseBooking,
    findClientId
}) {
    console.log('re-render Staff Bookings Wrapper');

    return (
        <>
            <StaffWorkingHours staffId={staffId} />
            <BookingResizableWrapper
                staffId={staffId}
                onRefreshStaffBlockedTimes={onRefreshStaffBlockedTimes}
                onReSchedule={onReSchedule}
                onReBooking={onReBooking}
                onOpenBooking={onOpenBooking}
                onCloseBooking={onCloseBooking}
                findClientId={findClientId}
            />
            <BlockedTimeWrapper
                staffId={staffId}
                onRefreshStaffBlockedTimes={onRefreshStaffBlockedTimes}
                onReSchedule={onReSchedule}
                onReBooking={onReBooking}
            />
            <CurrentTimeline />
        </>
    );
}

function BlockedTimeWrapper({ staffId, onRefreshStaffBlockedTimes, onReSchedule, onReBooking }) {
    const { isMobile, isTablet } = useMediaQueries();
    const RATIO = useRatio();
    const blockedTimes = useStaffBlockedTimeByStaffId(staffId);
    const staff = useStaffById(staffId);

    if (!blockedTimes) return null;

    return sortBy(blockedTimes, ['timeStart'])?.map((blockedTime) => {
        const style = getCalendarStyleByStartTime({
            startTime: blockedTime.timeStart,
            duration: blockedTime.duration,
            minutePerPx: RATIO
        });

        return (
            <BookingResizable
                isMobile={isMobile}
                isTablet={isTablet}
                onRefreshStaffBlockedTimes={onRefreshStaffBlockedTimes}
                staff={staff}
                isBlockedTime={true}
                order={blockedTime}
                bkSvId={blockedTime.id}
                style={{ ...style, width: '100%' }}
                onReSchedule={onReSchedule}
                onReBooking={onReBooking}
            />
        );
    });
}

function BookingResizableWrapper({
    staffId,
    onRefreshStaffBlockedTimes,
    onReSchedule,
    onReBooking,
    onOpenBooking,
    onCloseBooking,
    findClientId
}) {
    const { isMobile } = useMediaQueries();
    const RATIO = useRatio();
    // const staff = useStaffById(staffId);
    const bookingServices = useStaffBookings(staffId);

    const bookingServicesSortedByStartTime = React.useMemo(() => {
        return sortBy(bookingServices, ['startTime']);
    }, [bookingServices]);

    const conflictObj = React.useMemo(() => {
        let objHold = {};
        const bkSvs = bookingServicesSortedByStartTime || [];

        for (const bkSv of bkSvs) {
            const startTime = bkSv?.startTime;
            const duration = bkSv?.duration;
            const endTime = getEndTimeByStartTimeAndDuration({ startTime, duration });

            const leftBkServices = bkSvs?.filter((sv) => +bkSv?.id !== +sv?.id);

            if (!objHold?.[+bkSv?.id]) {
                objHold[+bkSv?.id] = [+bkSv?.id];
            }

            for (const leftBkSv of leftBkServices) {
                const leftBkSvStartTime = leftBkSv?.startTime;
                const leftBkSvDuration = leftBkSv?.duration;
                const leftBkSvEndTime = getEndTimeByStartTimeAndDuration({
                    startTime: leftBkSvStartTime,
                    duration: leftBkSvDuration
                });

                if (
                    !(
                        (leftBkSvStartTime <= startTime && leftBkSvEndTime <= startTime) ||
                        (leftBkSvStartTime >= endTime && leftBkSvEndTime >= endTime)
                    )
                ) {
                    const childBkSvIds = objHold?.[+leftBkSv?.id] || [];
                    objHold[+bkSv?.id] = [...new Set([...objHold[+bkSv?.id], +leftBkSv?.id, ...childBkSvIds])];
                }
            }
        }

        return objHold;
    }, [bookingServicesSortedByStartTime]);

    if (!bookingServicesSortedByStartTime) return null;

    return bookingServicesSortedByStartTime?.map((order) => {
        const style = getCalendarStyleByStartTime({
            startTime: order?.startTime,
            duration: order?.duration,
            minutePerPx: RATIO
        });

        const conflictIds = getConflictIdsWidthOrders({
            conflictObj,
            bkSvId: +order?.id,
            bookingServicesSortedByStartTime
        });

        const conflictLength = conflictIds?.length;
        const isDuplicateBooking = conflictLength > 1;
        const width = 100 / conflictLength;

        const index = conflictIds?.findIndex((id) => +id === +order?.id);

        return (
            <BookingResizable
                isMobile={isMobile}
                onRefreshStaffBlockedTimes={onRefreshStaffBlockedTimes}
                staffId={staffId}
                order={order}
                key={order?.id}
                style={{
                    ...style,
                    width: isDuplicateBooking ? `${width}%` : `100%`,
                    marginLeft: isDuplicateBooking ? `${index * width}%` : ``,
                    boxSizing: 'border-box'
                }}
                onReSchedule={onReSchedule}
                onReBooking={onReBooking}
                onOpenBooking={onOpenBooking}
                onCloseBooking={onCloseBooking}
                findClientId={findClientId}
            />
        );
    });
}

function StaffWorkingHours({ staffId }) {
    const RATIO = useRatio();
    const scheduleShifts = useStaffWorkingHoursByStaffId(staffId);

    if (!scheduleShifts) return null;

    return scheduleShifts?.map((schedule, index) => {
        const style = getCalendarStyleByTimeShift({
            timeStart: schedule.timeStart,
            timeEnd: schedule.timeEnd,
            minutePerPx: RATIO
        });

        return (
            <Paper
                key={index}
                style={{
                    ...style,
                    position: 'absolute',
                    left: -1,
                    width: '100%',
                    borderRadius: 0,
                    cursor: 'pointer',
                    pointerEvents: 'none',
                    zIndex: 1
                }}
                elevation={0}
            ></Paper>
        );
    });
}

export default React.memo(Bookings);
