import * as types from '../types';
import {
    getCategoriesApi,
    reOrderCategoryApi,
    createCategoryApi,
    deleteCategoryApi,
    updateCategoryApi
} from 'services/category';
import { enqueueSnackbar } from 'notifier/actions';
import orderBy from 'lodash/orderBy';
import { reOrderServiceApi } from 'services/service';

export function getCategories({ relations = [], errorCallback, successCallback }) {
    return async function (dispatch) {
        dispatch(_fetchCategories());

        try {
            const response = await getCategoriesApi(relations);
            dispatch(_fetchCategoriesSuccess({ categories: response?.items || [] }));
            successCallback && successCallback(response?.items);
        } catch (error) {
            const { message } = error;
            dispatch(_fetchCategoriesFailed());
            errorCallback && errorCallback(message);
        }
    };
}

function _fetchCategories() {
    return {
        type: types.FETCH_CATEGORIES
    };
}

function _fetchCategoriesFailed() {
    return {
        type: types.FETCH_CATEGORIES_FAILED
    };
}

export function _fetchCategoriesSuccess({ categories }) {
    return {
        type: types.FETCH_CATEGORIES_SUCCESS,
        payload: {
            categories
        }
    };
}

export function createCategory({ body, errorCallback, successCallback }) {
    return async function (dispatch) {
        try {
            const category = await createCategoryApi({ body });

            dispatch(_cretaCategorySuccess({ category }));
            successCallback && successCallback(category);
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[createCategory] ${message}`,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

function _cretaCategorySuccess({ category }) {
    return {
        type: types.CREATE_CATEGORY_SUCCESS,
        payload: {
            category
        }
    };
}

export function updateCategory({ id, body, errorCallback, successCallback }) {
    return async function (dispatch) {
        try {
            const updatedCategory = await updateCategoryApi({ id, body });
            dispatch(_updateCategorySuccess({ id, category: updatedCategory }));
            successCallback && successCallback(updatedCategory);
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[updateCategory] ${message}`,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

function _updateCategorySuccess({ id, category }) {
    return {
        type: types.UPDATE_CATEGORY_SUCCESS,
        payload: {
            category,
            id
        }
    };
}

export function deleteCategory({ id, errorCallback, successCallback }) {
    return async function (dispatch) {
        // dispatch(_deleteCategory({ id }));
        try {
            const res = await deleteCategoryApi({ id });
            if (res?.isConflict) {
                successCallback && successCallback(res?.bookings);
                return;
            }

            dispatch(_deleteCategorySuccess({ id }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[deleteCategory] ${message}`,
                    type: 'info'
                })
            );
            // dispatch(_deleteCategoryFailed({ id }));
            errorCallback && errorCallback(message);
        }
    };
}

// function _deleteCategory({ id }) {
//     return {
//         type: types.DELETE_CATEGORY,
//         payload: {
//             id
//         }
//     };
// }

function _deleteCategorySuccess({ id }) {
    return {
        type: types.DELETE_CATEGORY_SUCCESS,
        payload: {
            id
        }
    };
}

// function _deleteCategoryFailed({ id }) {
//     return {
//         type: types.DELETE_CATEGORY_FAILED,
//         payload: {
//             id
//         }
//     };
// }

export function reOrderCategory({
    afterCategoryId,
    dragId,
    beforeCategoryId,
    dropIndex,
    errorCallback,
    successCallback
}) {
    return async function (dispatch, getState) {
        const { service } = getState();
        const categoryList = service?.categoryList || [];
        const categories = orderBy(categoryList, ['orderIndex'], ['desc']);

        let body = {
            categoryId: +dragId
        };

        if (afterCategoryId) {
            body = {
                ...body,
                afterCategoryId: +afterCategoryId
            };
        } else if (beforeCategoryId) {
            body = {
                ...body,
                beforeCategoryId: +beforeCategoryId
            };
        }

        const categoryDrag = categoryList?.find((ct) => +ct?.id === +dragId);
        const oldIndex = categoryList?.findIndex((ct) => +ct?.id === +dragId);

        if (!categoryDrag) {
            console.log('categoryDrag is empty');
            return;
        }

        const isUp = oldIndex > dropIndex;

        const splitIndex = isUp ? dropIndex : dropIndex + 1;

        const firstPart = categoryList?.slice(0, splitIndex)?.filter((i) => +i?.id !== +dragId);
        const secondPart = categoryList?.slice(splitIndex)?.filter((i) => +i?.id !== +dragId);

        const newListCategoriesWithReorderIndex = [...firstPart, categoryDrag, ...secondPart]?.map((ct, index) => ({
            ...ct,
            orderIndex: categories?.length - index
        }));

        dispatch(reOrderCategoriesSuccess({ categories: newListCategoriesWithReorderIndex }));

        try {
            await reOrderCategoryApi(body);
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[reOrderCategory] ${message}`,
                    type: 'info'
                })
            );
            dispatch(reOrderCategoriesSuccess({ categories }));
            errorCallback && errorCallback(message);
        }
    };
}

export function reOrderCategoriesSuccess({ categories }) {
    return {
        type: types.REORDER_CATEGORY,
        payload: {
            categories
        }
    };
}

export function addServiceToCategory({ ctId, service }) {
    return {
        type: types.ADD_SERVICE_TO_CATEGORY,
        payload: {
            ctId,
            service
        }
    };
}

export function updateServiceToCategory({ ctId, sId, service }) {
    return {
        type: types.UPDATE_SERVICE_TO_CATEGORY,
        payload: {
            ctId,

            sId,
            service
        }
    };
}

export function deleteServiceToCategory({ ctId, sId }) {
    return {
        type: types.DELETE_SERVICE_TO_CATEGORY,
        payload: {
            ctId,
            sId
        }
    };
}

export function updateAvatarServiceToCategory({ ctId, sId, avatar }) {
    return {
        type: types.UPDATE_AVATAR_SERVICE_TO_CATEGORY,
        payload: {
            ctId,
            sId,
            avatar
        }
    };
}

export function reOrderServiceInCategory({
    services,
    afterServiceId,
    dragId,
    beforeServiceId,
    dropIndex,
    error,
    success,
    ctId
}) {
    return async function (dispatch, getState) {
        console.log('services', services);
        let body = {
            serviceId: +dragId,
            categoryId: +ctId
        };

        if (afterServiceId) {
            body = {
                ...body,
                afterServiceId: +afterServiceId
            };
        } else if (beforeServiceId) {
            body = {
                ...body,
                beforeServiceId: +beforeServiceId
            };
        }

        const serviceDrag = services?.find((sv) => +sv?.id === +dragId);
        const oldIndex = services?.findIndex((sv) => +sv?.id === +dragId);

        if (!serviceDrag) {
            console.log('serviceDrag is empty');
            return;
        }

        const isUp = oldIndex > dropIndex;

        const splitIndex = isUp ? dropIndex : dropIndex + 1;

        const firstPart = services?.slice(0, splitIndex)?.filter((i) => +i?.id !== +dragId);
        const secondPart = services?.slice(splitIndex)?.filter((i) => +i?.id !== +dragId);

        const newServiceList = [...firstPart, serviceDrag, ...secondPart];
        const newListServicesWithReorderIndex = newServiceList?.map((sv, index) => ({
            ...sv,
            orderIndex: newServiceList?.length - index
        }));

        console.log('newListServicesWithReorderIndex', newListServicesWithReorderIndex);

        dispatch(
            _reorderServiceInCategorySuccess({
                ctId,
                services: newListServicesWithReorderIndex
            })
        );

        try {
            await reOrderServiceApi(body);

            success && success();
        } catch ({ message }) {
            error && error(message);
        }
    };
}

function _reorderServiceInCategorySuccess({ ctId, services }) {
    return {
        type: types.RE_ORDER_SERVICE_TO_CATEGORY,
        payload: {
            ctId,
            services
        }
    };
}
