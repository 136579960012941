import React from 'react';

function InfoIconSVG({ color = '#FFFFFF', ...other }) {
    return (
        <svg
            {...other}
            width="20px"
            height="21px"
            viewBox="0 0 20 21"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g id="Styleguide" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="GridUI-Style-Guide" transform="translate(-169.000000, -5022.000000)" fill={color}>
                    <g id="Info" transform="translate(169.000000, 5022.257812)">
                        <path
                            d="M9.97289973,0 C15.501355,0 20,4.49864499 20,9.97289973 C20,15.501355 15.501355,20 9.97289973,20 C4.49864499,20 0,15.501355 0,9.97289973 C0,4.49864499 4.49864499,0 9.97289973,0 Z M10.9067192,8.40163891 L9.56800153,8.37614464 C9.03874106,8.37104579 8.51985826,8.3812435 8.29154982,8.44242975 C7.9179542,8.52401142 7.9179542,8.89112893 8.20852858,9.01350143 C8.66514544,9.17666477 9.16327294,8.93191976 9.03874106,9.70694562 L8.87474876,10.6606887 C8.7048728,11.6684306 8.52467735,12.8005379 8.40014548,13.7183317 C8.15108173,15.5539193 11.8040167,15.4315468 12.1776123,14.0854492 C12.2191229,13.881495 12.0530804,13.7591225 11.8870379,13.8407042 C11.5964636,13.9630767 11.4304211,14.1262401 11.0568254,14.1262401 C10.6832298,14.1670309 10.6417192,13.7183317 10.6417192,13.4735867 C10.8077617,12.3314433 11.1142723,10.6859257 11.3218254,9.54378229 C11.363336,9.29903728 11.4048467,9.0950831 11.4048467,8.85033809 C11.363336,8.56480225 11.1972935,8.40163891 10.9067192,8.40163891 Z M10.325,5 C9.685,5 9.125,5.52 9.125,6.2 C9.125,6.84 9.645,7.4 10.325,7.4 C10.965,7.4 11.485,6.88 11.525,6.2 C11.525,5.56 11.005,5 10.325,5 Z"
                            id="Shape"
                        ></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default React.memo(InfoIconSVG);
