export const PERCENT_OPTIONS = [
    {
        label: '0%',
        value: 0
    },
    {
        label: '5%',
        value: 5
    },
    {
        label: '10%',
        value: 10
    },
    {
        label: '15%',
        value: 15
    },
    {
        label: '20%',
        value: 20
    },
    {
        label: '25%',
        value: 25
    },
    {
        label: '30%',
        value: 30
    },
    {
        label: '35%',
        value: 35
    },
    {
        label: '40%',
        value: 40
    },
    {
        label: '45%',
        value: 45
    },
    {
        label: '50%',
        value: 50
    },
    {
        label: '55%',
        value: 55
    },
    {
        label: '60%',
        value: 60
    },
    {
        label: '65%',
        value: 65
    },
    {
        label: '70%',
        value: 70
    },
    {
        label: '80%',
        value: 80
    },
    {
        label: '85%',
        value: 85
    },
    {
        label: '90%',
        value: 90
    },
    {
        label: '95%',
        value: 95
    },
    {
        label: '100%',
        value: 100
    }
];

export const EXPIRATION_PERIOD_OPTIONS = [
    {
        label: 'Never',
        value: 0
    },
    {
        label: '3 months',
        value: 1
    },
    {
        label: '6 months',
        value: 2
    },
    {
        label: '9 months',
        value: 3
    },
    {
        label: '1 year',
        value: 4
    }
];

export const NUMBER_OF_SALE_OPTIONS = [
    {
        label: 'Unlimited',
        value: 2000000000
    },

    {
        label: '200',
        value: 200
    },
    {
        label: '400',
        value: 400
    },
    {
        label: '500',
        value: 500
    },
    {
        label: '1000',
        value: 1000
    }
];
