import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, useMediaQuery, Dialog } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import LayoutTabContent from 'views/layout/LayoutTabContent';
import AddVoucher from 'views/voucher/addVoucher';
import { useVouchersList, useIsFetching } from 'hooks/voucher';
import RedeemVoucher from './RedeemVoucher';
import AddIcon from '@material-ui/icons/AddCircle';
import ContentLayout from '../layout/ContentLayout';
import VoucherItem from './VoucherItem';
import Button from 'components/button/Base';
import RedeemIcon from '@material-ui/icons/Redeem';

const useStyles = makeStyles((theme) => ({
    root: {
        // maxWidth: 1024,
        overflowY: 'hidden',
        // margin: 'auto',
        height: '100%',
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            maxHeight: `-webkit-fill-available`,
            marginTop: 0
        }
    },
    voucherList: {
        flex: 1,
        height: '100%',
        overflowY: 'scroll',
        overflowX: 'hidden',
        padding: 20
    }
}));

function VoucherList() {
    const classes = useStyles();
    const theme = useTheme();

    const { t } = useTranslation();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const vouchers = useVouchersList();
    const isFetching = useIsFetching();

    const [isOpenRedeemDialog, setIsOpenRedeemDialog] = React.useState(false);
    const [isOpenCreateGiftDialog, setIsOpenCreateGiftDialog] = React.useState(false);

    const handleOpenRedeemDialog = () => setIsOpenRedeemDialog(true);
    const handleCloseRedeemDialog = () => setIsOpenRedeemDialog(false);

    const handleOpenCreateGiftDialog = () => setIsOpenCreateGiftDialog(true);
    const handleCloseCreateGiftDialog = () => setIsOpenCreateGiftDialog(false);

    return (
        <ContentLayout
            title={t(`gifts`)}
            action={
                <Grid container spacing={2}>
                    <Grid item>
                        <Button
                            startIcon={<RedeemIcon />}
                            variant="contained"
                            onClick={handleOpenRedeemDialog}
                            color="primary"
                        >
                            Redeem
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            startIcon={<AddIcon />}
                            variant="contained"
                            onClick={handleOpenCreateGiftDialog}
                            color="primary"
                        >
                            New gift
                        </Button>
                    </Grid>
                </Grid>
            }
        >
            <LayoutTabContent isLoading={isFetching && !vouchers}>
                <Grid container className={classes.root} direction="column" wrap="nowrap">
                    <Grid item className={classes.voucherList}>
                        <Grid
                            container
                            direction="row"
                            style={{ marginX: 'auto', paddingX: theme.spacing(4), maxWidth: 1440, margin: '0 auto' }}
                            spacing={6}
                        >
                            {vouchers?.map((voucher) => {
                                return (
                                    <Grid key={voucher?.id} item>
                                        <VoucherItem {...voucher} />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>

                    <Dialog
                        fullScreen={true}
                        padding={0}
                        open={isOpenCreateGiftDialog}
                        onClose={handleCloseCreateGiftDialog}
                    >
                        <AddVoucher onClose={handleCloseCreateGiftDialog} />
                    </Dialog>

                    <Dialog
                        fullScreen={fullScreen}
                        maxWidth={'lg'}
                        padding={0}
                        open={isOpenRedeemDialog}
                        onClose={handleCloseRedeemDialog}
                    >
                        <RedeemVoucher />
                    </Dialog>
                </Grid>
            </LayoutTabContent>
        </ContentLayout>
    );
}

export default VoucherList;
