import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import { INPUT_HEIGHT } from 'const/style';
import CheckedSVG from 'assets/images/svg/CheckedSVG';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        position: 'relative',
        flexWrap: 'wrap',
        paddingBottom: 5
    },
    errorText: {
        color: theme.palette.secondary.main,
        fontSize: 12,
        position: 'absolute',
        bottom: -5,
        left: 0
    },
    labelError: {
        color: theme.palette.secondary.main
    },
    success: {
        borderColor: `${theme.colors.green} !important`
    },
    label: {
        marginBottom: theme.spacing(1),
        fontWeight: 600
    }
}));

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing()
        },

        borderBottom: (props) => `1px solid ${props.error ? theme.palette.secondary.main : theme.colors.border}`,
        backgroundColor: theme.palette.common.white,

        paddingLeft: 5,
        paddingRight: 5,
        // borderRadius: BORDER_RADIUS,
        height: INPUT_HEIGHT,
        fontSize: 14,
        fontWeight: 'normal',
        '&:focus-within': {
            borderColor: theme.palette.primary.main
        },
        '&.MuiInputBase-multiline': {
            padding: `5px 0px`,
            height: '100%',
            marginTop: 8,
            '& > textarea': {
                marginBottom: 5
            }
        },
        marginBottom: 10,
        '& input': {
            color: 'black'
        }
    },
    input: {
        position: 'relative',
        fontSize: 14,
        fontWeight: 'normal',
        width: '100%',
        height: '100%',
        transition: theme.transitions.create(['border-color', 'box-shadow'])
        // '&[aria-invalid = "true"]': {
        //     border: `1px solid ${theme.colors.burntSlena}`
        // }
    }
}))(InputBase);

function InputForm({ label, placeholder, fullWidth, isShowChecked = true, className, errorText, ...rest }) {
    const classes = useStyles();
    const isValid = rest.value && !rest.error && isShowChecked;
    return (
        <FormControl fullWidth={fullWidth} className={`${classes.root} ${className} `}>
            {label && (
                <Typography className={`${classes.label} ${rest.error ? classes.labelError : ''}`} variant="body2">
                    {label}
                </Typography>
            )}
            <BootstrapInput
                rowsMax={5}
                rowsMin={2}
                className={`${rest.error ? classes.error : ''} ${isValid ? classes.success : ''}`}
                fullWidth={fullWidth}
                placeholder={placeholder}
                endAdornment={
                    isValid ? (
                        <InputAdornment position="end">
                            <CheckedSVG />
                        </InputAdornment>
                    ) : null
                }
                autoComplete={'false'}
                {...rest}
            />
            {rest.error && <div className={classes.errorText}>{errorText || 'error'}</div>}
        </FormControl>
    );
}

export default React.memo(InputForm);
