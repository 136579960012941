import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import {
    getMondayByCurrentDate,
    getCurrentMondayByDate,
    generateNextDate,
    getOffsetDays,
    getDateAfter,
    getNativeDate,
    isSameToday
} from 'utils/timing';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import { INPUT_HEIGHT, BORDER_RADIUS } from 'const/style';
import useMediaQueries from 'hooks/useMediaQueries';
import { ArrowRight, ArrowLeft } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: BORDER_RADIUS,
        background: theme.palette.common.white,
        '& *': {
            pointerEvents: (props) => (props.disabled ? 'none' : '')
        }
    },
    today: {
        width: 86,
        background: theme.palette.common.white,
        height: INPUT_HEIGHT,
        display: 'flex',
        alignItems: 'center',
        fontSize: 14,
        color: theme.colors.primaryText,
        fontWeight: 500,
        justifyContent: 'center',
        cursor: 'pointer',
        borderRight: `1px solid ${theme.colors.border}`,
        borderLeft: `1px solid ${theme.colors.border}`
    },
    todaySelected: {
        background: theme.palette.primary.main,
        color: theme.palette.common.white
    },
    arrow: {
        width: INPUT_HEIGHT,
        height: INPUT_HEIGHT,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        '&:hover': {
            background: theme.palette.primary.main,
            '& svg': {
                color: theme.palette.common.white
            }
        }
    },
    datePicker: {
        borderRight: `1px solid ${theme.colors.border}`,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        minWidth: 230,
        cursor: 'pointer',
        '& .react-datepicker-wrapper, & > div:first-child': {
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            '& .react-datepicker__input-container': {
                width: '100%',
                height: '100%'
            }
        },
        '& input': {
            border: 'none',
            width: '100%',
            height: '100%',
            outline: 'none',
            opacity: 0,
            cursor: 'pointer',
            fontSize: 14
        }
    }
}));

function DateRange({ onRangeChanged, disabled }) {
    const classes = useStyles({ disabled });
    const { isMobile } = useMediaQueries();

    const { t } = useTranslation();
    const [selectedMonday, setSelectedMonday] = React.useState(getCurrentMondayByDate(new Date()));
    const [range, setRange] = React.useState([]);
    const [selectedDatePicker, setSelectedDatePicker] = React.useState(new Date());

    React.useEffect(() => {
        if (range.length) {
            onRangeChanged && onRangeChanged(range);
        }
    }, [range, onRangeChanged]);

    React.useEffect(() => {
        const newRange = generateNextDate({ startDate: selectedMonday, nextDaysNumber: 7 });
        setRange(newRange);
    }, [selectedMonday]);

    const offsetDayFromPickerAndMonDay = React.useMemo(() => {
        return getOffsetDays({ day1: selectedDatePicker, day2: selectedMonday });
    }, [selectedDatePicker, selectedMonday]);

    const handleChange = (date) => {
        setSelectedDatePicker(date);
        const mondayAfterPickerSelect = getCurrentMondayByDate(date);
        setSelectedMonday(mondayAfterPickerSelect);
    };

    const handleTodayClick = () => {
        setSelectedDatePicker(new Date());
        const currentMonday = getCurrentMondayByDate(new Date());
        setSelectedMonday(currentMonday);
    };

    const handleNextClick = () => {
        const selectedNextMonday = getMondayByCurrentDate({ currentDate: selectedMonday, numOfWeek: 1 });

        const newSelectedDatePicker = getDateAfter({
            date: selectedNextMonday,
            numOfDay: offsetDayFromPickerAndMonDay
        });

        const newSelectedDatePickerFormatted = getNativeDate(newSelectedDatePicker);
        setSelectedDatePicker(newSelectedDatePickerFormatted);
        setSelectedMonday(selectedNextMonday);
    };

    const handlePrevClick = () => {
        const selectedNextMonday = getMondayByCurrentDate({ currentDate: selectedMonday, numOfWeek: -1 });

        const newSelectedDatePicker = getDateAfter({
            date: selectedNextMonday,
            numOfDay: offsetDayFromPickerAndMonDay
        });

        const newSelectedDatePickerFormatted = getNativeDate(newSelectedDatePicker);
        setSelectedDatePicker(newSelectedDatePickerFormatted);

        setSelectedMonday(selectedNextMonday);
    };

    const DateRangeText = React.useMemo(() => {
        const firstDate = range?.[0];
        const lastDate = range?.[range.length - 1];

        return `${firstDate?.dateMonth} - ${lastDate?.dateMonth}, ${lastDate?.year}`;
    }, [range]);

    return (
        <Grid container className={classes.root} direction="row" wrap="nowrap">
            <Grid item className={`${classes.center} ${classes.arrow}`} onClick={handlePrevClick}>
                <ArrowLeft />
            </Grid>
            <Grid
                item
                className={`${classes.today} ${isSameToday(selectedDatePicker) ? classes.todaySelected : ''}`}
                onClick={handleTodayClick}
            >
                {t(`today`)}
            </Grid>
            <Grid item className={`${classes.datePicker}`}>
                <DatePicker
                    withPortal={isMobile}
                    className={classes.datepickerRoot}
                    selected={selectedDatePicker}
                    onChange={handleChange}
                />
                <div className={classes.present}>{DateRangeText}</div>
            </Grid>
            <Grid item className={`${classes.arrow}`} onClick={handleNextClick}>
                <ArrowRight />
            </Grid>
        </Grid>
    );
}

export default React.memo(DateRange);
