const { QueryClient } = require('@tanstack/react-query');

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            refetchOnWindowFocus: false
        },
        mutations: {
            onError: (error) => {
                if (error.status === 401 && error.response.status !== 401) {
                    return;
                }
            }
        }
    }
});
