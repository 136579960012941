import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import PopperMenu from 'components/popper';
import moment from 'moment-timezone';
import { INPUT_HEIGHT, PADDING_LIST_ITEM_LEFT_RIGHT } from 'const/style';
import CloseSVG from 'assets/images/svg/CloseSVG';
import ArrowDownSVG from 'assets/images/svg/ArrowDownSVG';
import DatePicker from 'react-datepicker';

const useStyles = makeStyles((theme) => ({
    popper: {
        transform: `translate3d(0px, 0px, 0px) !important`
    },
    present: {
        borderBottom: `1px solid ${theme.colors.border}`,
        height: INPUT_HEIGHT,
        // paddingLeft: PADDING_LIST_ITEM_LEFT_RIGHT,
        paddingRight: PADDING_LIST_ITEM_LEFT_RIGHT,
        // borderRadius: BORDER_RADIUS,
        cursor: 'pointer',
        position: 'relative',
        background: theme.palette.common.white
    },
    flex: {
        display: 'flex'
    },
    label: {
        fontSize: 14,
        lineHeight: `1.75`,
        marginBottom: 4,
        fontWeight: 600
    }
}));

function PortalDatePicker({
    defaultValue,
    isUsedDatePresent = false,
    defaultDatePresent,
    label,
    onChange,
    onClose,
    format = `dddd, DD MMMM YYYY`,
    className,
    withPortal,
    ...rest
}) {
    const classes = useStyles();
    const [date, setDate] = React.useState(new Date());
    const [anchorEl, setAnchorEl] = React.useState(null);

    React.useEffect(() => {
        setDate(defaultValue);
    }, [defaultValue]);

    const datePresent = React.useMemo(() => {
        return moment(date).format(format);
    }, [date, format]);

    const handleOpenDatePicker = (e) => {
        setAnchorEl(anchorEl ? null : e.currentTarget);
    };

    const handleClickAway = () => {
        setAnchorEl(null);
    };

    const handleDateChange = (date) => {
        setDate(date);
        handleClickAway();
        onChange && onChange(date);
    };

    return (
        <Grid container className={`${classes.root} ${className}`} direction="column">
            {label && <div className={classes.label}>{label}</div>}

            <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                className={classes.present}
                onClick={handleOpenDatePicker}
                wrap="nowrap"
            >
                <Grid item className={classes.text}>
                    {isUsedDatePresent ? defaultDatePresent : datePresent}
                </Grid>
                {!onClose && (
                    <Grid item className={classes.flex}>
                        <ArrowDownSVG />
                    </Grid>
                )}
                {onClose && (
                    <Grid item className={classes.flex}>
                        <CloseSVG size={20} onClick={onClose} />
                    </Grid>
                )}
            </Grid>
            {anchorEl &&
                (withPortal ? (
                    <DatePicker selected={date} onChange={handleDateChange} inline {...rest} withPortal={true} />
                ) : (
                    <PopperMenu
                        className={rest?.withPortal ? classes.popper : ''}
                        noPadding={true}
                        anchorEl={anchorEl}
                        handleClickAway={handleClickAway}
                    >
                        <DatePicker selected={date} onChange={handleDateChange} inline {...rest} />
                    </PopperMenu>
                ))}
        </Grid>
    );
}

export default React.memo(PortalDatePicker);
