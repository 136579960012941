import * as types from './types';
import { getItem, setItem } from 'utils/localStorage';
import { THEMES, THEME_NAME, FONT_SIZE, SECURE_PHONE_NAME } from 'const';

const defaultSecurePhone = getItem(SECURE_PHONE_NAME);

const INITIAL_STATE = {
    isOpenSidebarLeft: false,
    socketId: null,
    openLogoutPopup: false,
    securePhone: typeof defaultSecurePhone === 'boolean' ? defaultSecurePhone : true,
    primaryColor: `#32297C`,
    fontSize: getItem(FONT_SIZE) || 1,
    themeName: getItem(THEME_NAME) || THEMES.BOOK_SALON
};

export default function Service(state = INITIAL_STATE, { type, payload }) {
    switch (type) {
        case types.TOGGLE_DRAWLER_SIDEBAR_LEFT: {
            const { isOpenSidebarLeft } = state;
            return {
                ...state,
                isOpenSidebarLeft: !isOpenSidebarLeft
            };
        }

        case types.TOGGLE_SECURE_PHONE: {
            const { securePhone } = state;
            return {
                ...state,
                securePhone: !securePhone
            };
        }

        case types.REGISTER_SOCKET_ID: {
            const { socketId } = payload;

            return {
                ...state,
                socketId
            };
        }

        case types.SET_OPEN_LOGOUT_POPUP: {
            const { openLogoutPopup } = payload;
            return {
                ...state,
                openLogoutPopup
            };
        }

        case types.SET_FONT_SIZE: {
            const { fontSize } = payload;
            setItem(FONT_SIZE, fontSize);
            return {
                ...state,
                fontSize
            };
        }

        case types.SET_THEME_NAME: {
            const { name } = payload;
            setItem(THEME_NAME, name);
            return {
                ...state,
                themeName: name
            };
        }

        default:
            return state;
    }
}
