import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import InputBase from 'components/input/Form';
import { findBookingLogsApi } from 'services/bookings';
import Spinner from 'components/spinner';
import BookingLogs from 'views/calender/calendar/components/BookingLogs';
import * as staffActions from 'views/staff/action';
import { useDispatch } from 'react-redux';
import * as serviceActions from 'views/services/action/service';

const useStyles = makeStyles((theme) => ({
    root: {},
    content: {
        flex: 1,
        overflowY: 'auto'
    },
    mt: {
        marginTop: theme.spacing(3)
    }
}));

function BookingLog() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isFetching, setIsFetching] = React.useState(false);
    const [bkId, setBkId] = React.useState(null);
    const [booking, setBooking] = React.useState(null);
    const timerRef = React.useRef();

    const _fetchBooking = React.useCallback(async () => {
        setIsFetching(true);
        try {
            const bk = await findBookingLogsApi(bkId);
            setIsFetching(false);
            setBooking(bk);
        } catch ({ message }) {
            console.log('message', message);
            setBooking(null);
            setIsFetching(false);
        }
    }, [bkId]);

    React.useEffect(() => {
        if (timerRef.current) clearTimeout(timerRef.current);

        timerRef.current = setTimeout(async () => {
            _fetchBooking();
        }, [500]);

        return () => {
            if (timerRef.current) clearTimeout(timerRef.current);
        };
    }, [bkId, _fetchBooking]);

    React.useEffect(() => {
        dispatch(
            staffActions.getStaff({
                errorCallback: () => {
                    console.log('failed to fetch staff');
                },
                successCallback: () => {
                    console.log('fetch staff successfully');
                }
            })
        );
    }, [dispatch]);

    React.useEffect(() => {
        dispatch(
            serviceActions.getServices({
                errorCallback: () => {
                    console.log('failed to get staff');
                }
            })
        );
    }, [dispatch]);

    return (
        <Grid container wrap="nowrap" style={{ width: '100%', height: '100%' }} direction="column">
            <Grid item>
                <InputBase
                    label={`Input bookingId`}
                    placeholder={'input bookingId'}
                    name="bkId"
                    value={bkId}
                    onChange={(e) => setBkId(e?.target?.value)}
                />
            </Grid>
            <Grid item className={classes.content}>
                {isFetching ? (
                    <Grid
                        container
                        className={classes.mt}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item>
                            <Spinner size={20} thick={4} />
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container direction="column">
                        {booking && <BookingLogs logs={booking?.logs} createdAt={booking?.createdAt} showDetail />}
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
}

export default BookingLog;
