import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useStaffMapping } from 'hooks/staff';
import { useServicesMapping } from 'hooks/service';
import ButtonBase from 'components/button/Base';
import DialogActions from 'components/dialog/Action';
import DialogTitle from 'components/dialog/Title';
import DialogContent from 'components/dialog/Content';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { getFriendlyShortTime } from 'utils/timing';
import { getFullName } from 'utils/naming';
import { getImageUrl } from 'utils/image';
import Avatar from 'components/avatar';
import useMediaQueries from 'hooks/useMediaQueries';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useMerchantInfo } from 'hooks/merchant';

const useStyles = makeStyles((theme) => ({
    root: {},
    whiteColor: {
        color: theme.palette.common.white
    },
    bookingService: {
        '&:not(:last-child)': {
            borderBottom: `1px solid ${theme.colors.border}`
        },
        [theme.breakpoints.down('sm')]: {
            background: theme.colors.ghostwhite
        },
        margin: theme.spacing(1),
        boxSizing: 'border-box',
        padding: theme.spacing(1),
        borderRadius: 6
    }
}));

function BookingDetailPreview({ booking, onClose }) {
    const { t } = useTranslation();
    const theme = useTheme();
    const classes = useStyles();
    const staffMapping = useStaffMapping();
    const serviceMapping = useServicesMapping();
    const { isMobile } = useMediaQueries();
    const merchant = useMerchantInfo();

    const TABLE_COLUMNS = [
        {
            id: 'staff',
            label: 'Staff',
            width: 250,
            format: ({ value }) => (
                <Grid container alignItems="center" direction="row" spacing={1}>
                    <Grid item>
                        <Avatar alt={getFullName(value)} src={getImageUrl(value?.avatar)} />
                    </Grid>
                    <Grid item>
                        <Typography variant="body2">{getFullName(value)}</Typography>
                    </Grid>
                </Grid>
            )
        },
        {
            id: 'service',
            label: 'Service',
            width: 250,
            format: ({ value }) => (
                <Grid container alignItems="center" direction="row" spacing={1}>
                    <Grid item>
                        <Avatar alt={getFullName(value)} src={getImageUrl(value?.avatar)} />
                    </Grid>
                    <Grid item>
                        <Typography variant="body2">{value?.title}</Typography>
                    </Grid>
                </Grid>
            )
        },
        { id: 'price', label: 'Price', width: 100 },
        { id: 'duration', label: 'Duration', width: 100 }
    ];

    const bookingServices = React.useMemo(() => {
        return booking?.bookingServices?.map((bkSv) => {
            const staff = staffMapping?.[bkSv?.staffId];
            const service = serviceMapping?.[bkSv?.serviceId];

            return {
                price: `${merchant.currency}${bkSv?.price}`,
                duration: `${bkSv?.duration}`,
                staff,
                service
            };
        });
    }, [booking, staffMapping, serviceMapping, merchant]);

    return (
        <>
            <DialogTitle>
                <Typography className={classes.whiteColor} variant="h5">
                    Booking Detail
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} direction="column" wrap="nowrap">
                    <Grid item>
                        <Grid
                            container
                            direction={isMobile ? 'column' : 'row'}
                            justifyContent={isMobile ? 'flex-start' : 'flex-end'}
                            spacing={isMobile ? 1 : 3}
                        >
                            <Grid item>
                                <Grid container direction="row" alignItems="center" spacing={1}>
                                    <Grid item>
                                        <Typography variant="body1">Booking Number: </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2">#{booking?.id}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction="row" alignItems="center" spacing={1}>
                                    <Grid item>
                                        <Typography variant="body1">Begin At:</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2">
                                            {getFriendlyShortTime(booking?.startDate)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Typography variant="h5">Detail:</Typography>
                    </Grid>
                    <Grid item>
                        <Paper
                            elevation={0}
                            style={{
                                pt: 2,
                                width: '100%',
                                overflow: 'hidden',
                                background: theme.palette.common.white,
                                color: `rgb(33, 43, 54)`,
                                backgroundImage: 'none',
                                position: 'relative',
                                zIndex: 0,
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            <Grid item>
                                <TableContainer>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                {TABLE_COLUMNS?.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{
                                                            minWidth: column.minWidth,
                                                            border: 'none',
                                                            lineHeight: `1.5rem`,
                                                            fontSize: `0.875rem`,
                                                            fontFamily: `"Public Sans", sans-serif`,
                                                            fontWeight: 600,
                                                            display: `table-cell`,
                                                            verticalAlign: 'inherit',
                                                            textAlign: 'left',
                                                            padding: '16px',
                                                            borderBottom: 'none',
                                                            color: `rgb(99, 115, 129)`,
                                                            backgroundColor: theme.colors.hover,
                                                            '&:first-of-type': {
                                                                paddingLeft: '24px',
                                                                borderTopLeftRadius: '8px',
                                                                borderBottomLeftRadius: '8px',
                                                                boxShadow: `rgb(255 255 255) 8px 0px 0px inset`
                                                            },
                                                            '&:last-of-type': {
                                                                paddingLeft: '24px',
                                                                borderTopRightRadius: '8px',
                                                                borderBottomRightRadius: '8px',
                                                                boxShadow: `rgb(255 255 255) -8px 0px 0px inset`
                                                            }
                                                        }}
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {bookingServices?.length === 0 && (
                                                <TableRow sx={{ textAlign: 'center' }}>
                                                    <TableCell style={{ border: 'none' }}>No histories</TableCell>
                                                </TableRow>
                                            )}
                                            {bookingServices?.map((row) => {
                                                return (
                                                    <TableRow
                                                        style={{
                                                            '&:hover': {
                                                                cursor: 'pointer',
                                                                background: `${theme.colors.hover} !important`
                                                            }
                                                        }}
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        key={row?.id}
                                                    >
                                                        {TABLE_COLUMNS.map((column) => {
                                                            const value = row?.[column.id];

                                                            return (
                                                                <TableCell
                                                                    style={{ border: 'none' }}
                                                                    key={column.id}
                                                                    align={column.align}
                                                                >
                                                                    {column.format
                                                                        ? column.format({
                                                                              rowData: row,
                                                                              value
                                                                          })
                                                                        : value}
                                                                </TableCell>
                                                            );
                                                        })}
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <ButtonBase onClick={onClose} variant="outlined">
                    {t('close')}
                </ButtonBase>
            </DialogActions>
        </>
    );
}

export default React.memo(BookingDetailPreview);
