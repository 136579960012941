import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, Chip, Box, IconButton, Dialog } from '@material-ui/core';
import Search from 'components/search';
import sortBy from 'lodash/sortBy';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import { isEmpty } from 'lodash';
import { BOOKING_SERVICE_STATES, CHECKOUT_TYPES } from 'const';
import { getFullName } from 'utils/naming';
import CheckoutBill from './CheckoutBill';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        width: '100%',
        background: theme.palette.common.white,
        borderLeft: `1px solid ${theme.colors.border}`
    },
    checkoutBooking: {
        background: theme.colors.ghostwhite,
        borderRadius: 6,
        padding: theme.spacing(2),
        '&:not(:last-child)': {
            marginBottom: theme.spacing(2)
        },
        cursor: 'pointer'
    },
    item: {
        '&:not(:last-child)': {
            marginBottom: theme.spacing(2)
        }
    },
    expand: {
        flex: 1
    },
    search: {
        background: theme.colors.ghostwhite,
        minWidth: 'auto'
    },
    fullWidth: {
        width: '100%'
    },
    header: {
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        borderBottom: `1px solid ${theme.colors.border}`
    },
    px: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    }
}));

function Checkout({ doneBookings, clientsById, serviceList, staffList, onClose, giftCodes }) {
    const classes = useStyles();
    const theme = useTheme();
    const [filter, setFilter] = React.useState('');
    const [openCheckout, setIsOpenCheckout] = React.useState(false);
    const [checkoutType, setCheckoutType] = React.useState(null);
    const [selectedBooking, setSelectedBooking] = React.useState(null);

    const staffMapping = React.useMemo(() => {
        return staffList?.reduce((obj, s) => {
            obj[s?.id] = s;
            return obj;
        }, {});
    }, [staffList]);

    const serviceMapping = React.useMemo(() => {
        return serviceList?.reduce((obj, s) => {
            obj[s?.id] = s;
            return obj;
        }, {});
    }, [serviceList]);

    const clientIdsBooking = React.useMemo(() => {
        const allClientIds = isEmpty(doneBookings) ? [] : Object.keys(doneBookings);
        const clients = allClientIds?.map((clientId) => {
            const booking = doneBookings?.[clientId]?.[0];
            return {
                id: clientId,
                title: booking?.title
            };
        });

        const clientsSorted = sortBy(clients, ['title']);
        const matchedClients = clientsSorted?.filter((client) =>
            client?.title?.toLowerCase()?.includes(filter?.toLowerCase())
        );
        return matchedClients?.map((c) => c?.id);
    }, [doneBookings, filter]);

    const todayCodesMapClient = React.useMemo(() => {
        return giftCodes?.reduce((obj, code) => {
            const clientId = code?.clientId || `_fake_${code?.id}`;
            if (!obj?.[clientId]) {
                obj[clientId] = {
                    title: code?.client?.title || `Walk in ${code?.id}`,
                    codes: []
                };
            }

            obj[clientId].codes = [
                ...obj?.[clientId].codes,
                {
                    ...code,
                    clientId,
                    client: {
                        ...code?.client,
                        title: code?.client?.title || `Walk in ${code?.id}`
                    }
                }
            ];

            return obj;
        }, {});
    }, [giftCodes]);

    const clientsGiftFiltered = React.useMemo(() => {
        const clientIds = Object.keys(todayCodesMapClient);
        return clientIds?.filter((clientId) => {
            const clientDetail = todayCodesMapClient?.[clientId];
            return clientDetail?.title?.toLowerCase()?.includes(filter?.toLowerCase());
        });
    }, [filter, todayCodesMapClient]);

    const handleClose = React.useCallback(() => {
        setIsOpenCheckout(false);
        setSelectedBooking(null);
        setCheckoutType(null);
    }, []);

    const openBooking = React.useCallback(({ type, booking }) => {
        setIsOpenCheckout(true);
        setSelectedBooking(booking);
        setCheckoutType(type);
    }, []);

    return (
        <Grid container direction="column" className={classes.root}>
            <Grid item className={classes.header}>
                <Grid container direction="row" alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <Typography variant="body1">Checkouts</Typography>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item>
                <Grid container direction="column">
                    <Grid item className={`${classes.fullWidth} ${classes.px}`}>
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                            <Grid item className={classes.expand}>
                                <Search
                                    placeholder={'Search client name'}
                                    value={filter}
                                    onChange={(e) => setFilter(e?.target?.value)}
                                    autoFocus
                                    className={classes.search}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid
                        item
                        className={classes.px}
                        style={{ marginBottom: theme.spacing(2), marginTop: theme.spacing(2) }}
                    >
                        <Box style={{ padding: `2px 14px`, borderRadius: 6, background: theme.colors.red }}>
                            <Typography variant="body1" sx={{ color: theme.palette.common.white }}>
                                Don't skip checkout booking!
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item className={`${classes.fullWidth}`}>
                        <Grid
                            item
                            container
                            direction="column"
                            style={{ overflowY: 'auto', height: 'calc(100vh - 24px - 40px - 48px - 21px - 48px)' }}
                            wrap="nowrap"
                            className={classes.px}
                        >
                            {clientIdsBooking?.map((clientId) => {
                                const bookingServices = doneBookings?.[clientId];
                                const bookings = groupBy(bookingServices, 'bookingId');

                                const validBookings = Object.keys(bookings)?.filter((bookingId) => {
                                    const bkSvs = bookings?.[bookingId];
                                    return !bkSvs?.some((bkSv) => bkSv?.status !== BOOKING_SERVICE_STATES.DONE);
                                });

                                if (!validBookings?.length) return null;

                                const client = clientsById?.[clientId];

                                return (
                                    <Grid className={classes.item} item key={clientId}>
                                        <Grid container direction="column" wrap="nowrap">
                                            <Grid item style={{ marginBottom: theme.spacing(2) }}>
                                                <Typography variant="body1">{getFullName(client)}</Typography>
                                            </Grid>
                                            <Grid item container direction="column">
                                                {validBookings?.map((bookingId) => {
                                                    const bkSvs = bookings?.[bookingId];
                                                    const booking = bkSvs?.[0]?.booking;
                                                    const bookingServices = orderBy(booking?.bookingServices, [
                                                        'startTime',
                                                        'asc'
                                                    ]);
                                                    const totalPrice = bookingServices?.reduce((total, bkSv) => {
                                                        return total + bkSv?.price;
                                                    }, 0);

                                                    return (
                                                        <Grid
                                                            onClick={() =>
                                                                openBooking({ type: CHECKOUT_TYPES.BOOKING, booking })
                                                            }
                                                            item
                                                            className={classes.checkoutBooking}
                                                            key={bookingId}
                                                        >
                                                            <Grid container spacing={4} direction="column">
                                                                <Grid item container direction="column">
                                                                    {bookingServices?.map((bkSv) => {
                                                                        const service =
                                                                            serviceMapping?.[bkSv?.serviceId];
                                                                        const staff = staffMapping?.[bkSv?.staffId];
                                                                        return (
                                                                            <Grid item key={bkSv?.id}>
                                                                                <Typography variant="body2">
                                                                                    - {service?.title} with{' '}
                                                                                    <span
                                                                                        style={{
                                                                                            color: theme.colors.red
                                                                                        }}
                                                                                    >
                                                                                        {getFullName(staff)}
                                                                                    </span>
                                                                                </Typography>
                                                                            </Grid>
                                                                        );
                                                                    })}
                                                                </Grid>

                                                                <Grid item style={{ width: '100%' }}>
                                                                    <Grid
                                                                        container
                                                                        direction="row"
                                                                        justifyContent="space-between"
                                                                    >
                                                                        <Grid item>
                                                                            <Chip
                                                                                label={'Booking'}
                                                                                color="primary"
                                                                                size="small"
                                                                            />
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Typography variant="body2">
                                                                                Price:{' '}
                                                                                <strong
                                                                                    style={{
                                                                                        color: theme.palette.primary
                                                                                            .main
                                                                                    }}
                                                                                >
                                                                                    ${totalPrice}
                                                                                </strong>
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                })}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                );
                            })}

                            {clientsGiftFiltered?.map((clientId) => {
                                const clientBuyCode = todayCodesMapClient?.[clientId];
                                const codes = clientBuyCode?.codes;

                                return (
                                    <Grid className={classes.item} item key={clientId}>
                                        <Grid container spacing={1} direction="column" wrap="nowrap">
                                            <Grid item>
                                                <Typography variant="body1">{clientBuyCode?.title}</Typography>
                                            </Grid>
                                            <Grid item container direction="column">
                                                {codes?.map((code) => {
                                                    return (
                                                        <Grid
                                                            onClick={() =>
                                                                openBooking({
                                                                    type: CHECKOUT_TYPES.GIFT,
                                                                    booking: code
                                                                })
                                                            }
                                                            item
                                                            className={classes.checkoutBooking}
                                                            key={code?.bookingId}
                                                        >
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                justifyContent="space-between"
                                                            >
                                                                <Grid item>
                                                                    <Chip
                                                                        label={'Gift'}
                                                                        style={{
                                                                            color: theme.palette.common.white,
                                                                            background: theme.colors.pink
                                                                        }}
                                                                        color="primary"
                                                                        size="small"
                                                                    />
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography variant="body2">
                                                                        Price:{' '}
                                                                        <strong
                                                                            style={{
                                                                                color: theme.palette.primary.main
                                                                            }}
                                                                        >
                                                                            ${code?.retailPrice}
                                                                        </strong>
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                })}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Dialog maxWidth={false} open={openCheckout} onClose={handleClose}>
                <CheckoutBill
                    type={checkoutType}
                    booking={selectedBooking}
                    onClose={handleClose}
                    serviceList={serviceList}
                />
            </Dialog>
        </Grid>
    );
}

export default Checkout;
