import React from 'react';

function VoucherSVG({ color = '#41415C', ...rest }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21.083" viewBox="0 0 22 21.083" {...rest}>
            <path
                opacity={0.5}
                id="gift-solid"
                d="M11.333,5a2.765,2.765,0,0,0-2.75,2.75,2.7,2.7,0,0,0,.172.917H4v5.5h.917V26.083H25.083V14.167H26v-5.5H21.245a2.7,2.7,0,0,0,.172-.917A2.765,2.765,0,0,0,18.667,5a4.387,4.387,0,0,0-3.409,2.234c-.1.136-.172.269-.258.4-.086-.132-.161-.265-.258-.4A4.387,4.387,0,0,0,11.333,5Zm0,1.833a2.94,2.94,0,0,1,1.891,1.432,3.638,3.638,0,0,1,.229.4h-2.12a.917.917,0,0,1,0-1.833Zm7.333,0a.917.917,0,0,1,0,1.833h-2.12a3.638,3.638,0,0,1,.229-.4A2.94,2.94,0,0,1,18.667,6.833ZM5.833,10.5H24.167v1.833h-8.25v-.917H14.083v.917H5.833Zm.917,3.667h16.5V24.25H15.917V15.083H14.083V24.25H6.75Z"
                transform="translate(-4 -5)"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(VoucherSVG);
