import React from 'react';
import { Grid, Box, Typography, useTheme } from '@material-ui/core';
import Service from './Service';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import * as categoryActions from 'views/services/action/category';
import { useSnackbar } from 'notistack';

function Services({ onEdit, services, selectedCategoryId }) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const handleDragEnd = React.useCallback(
        ({ destination, source, draggableId }) => {
            if (!destination) {
                return;
            }
            if (destination.droppableId === source.droppableId && destination.index === source.index) {
                return;
            }

            let beforeServiceId = null;
            let afterServiceId = null;
            const oldIndex = services?.findIndex((service) => +service?.id === +draggableId);

            const newIndex = destination?.index;

            if (newIndex === 0) {
                beforeServiceId = services?.[0]?.id;
            } else {
                let index = newIndex > oldIndex ? newIndex : Math.max(0, newIndex - 1);
                afterServiceId = services?.[index]?.id;
            }

            dispatch(
                categoryActions.reOrderServiceInCategory({
                    services,
                    afterServiceId,
                    beforeServiceId,
                    dragId: +draggableId,
                    dropIndex: destination?.index,
                    ctId: +selectedCategoryId,
                    success: () => {
                        console.log('reorder service success');
                    },
                    error: (message) => {
                        console.log('reorder service failed');
                        enqueueSnackbar(message, {
                            variant: 'error'
                        });
                    }
                })
            );
        },
        [services, enqueueSnackbar, dispatch, selectedCategoryId]
    );

    return (
        <Box style={{ padding: theme.spacing(2) }}>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="all-columns" direction="vertical" type="category">
                    {(provided) => (
                        <Grid
                            container
                            direction="column"
                            wrap="nowrap"
                            spacing={3}
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {!services?.length && (
                                <Grid item>
                                    <Typography variant="body2">
                                        Click <strong>`Add New Service`</strong> button to add more service
                                    </Typography>
                                </Grid>
                            )}
                            {services?.length > 0 &&
                                services?.map((sv, index) => {
                                    return (
                                        <Grid item key={sv?.id}>
                                            <Service onEdit={() => onEdit(sv)} {...sv} service={sv} index={index} />
                                        </Grid>
                                    );
                                })}
                        </Grid>
                    )}
                </Droppable>
            </DragDropContext>
        </Box>
    );
}

export default Services;
