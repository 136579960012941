import { MerchantState } from 'const';

export function getMerchantStateLabel(state) {
    switch (state) {
        case MerchantState.PendingPayment:
            return 'Payment Pending';
        case MerchantState.Deactivate:
            return 'Deactivate';
        default:
            return 'Active';
    }
}

export function getMerchantStateColor({ state, theme }) {
    switch (state) {
        case MerchantState.PendingPayment:
            return theme.colors.sun;
        case MerchantState.Deactivate:
            return theme.colors.red;
        default:
            return theme.colors.green;
    }
}
