import React from 'react';
import Dashboard from './Dashboard';
import LayoutTabsContent from '../layout/LayoutTabsContent';
import GiftCodeSVG from 'assets/images/svg/GiftCodeSVG';
import CalendarSVG from 'assets/images/svg/CalendarSVG';
import GiftStatistic from './GiftStatistic';
import { useTranslation } from 'react-i18next';
import { useIsHavingGiftPermission } from 'hooks/auth';

function AnalyzeTabs() {
    const { t } = useTranslation();
    const isHavingGiftPermission = useIsHavingGiftPermission();

    const tabs = isHavingGiftPermission
        ? [
              { component: <Dashboard />, index: 0, icon: <CalendarSVG />, title: t(`booking`), path: 'bookings' },
              {
                  component: <GiftStatistic />,
                  index: 1,
                  icon: <GiftCodeSVG />,
                  title: t(`gift`),
                  path: 'gifts'
              }
          ]
        : [{ component: <Dashboard />, index: 0, icon: <CalendarSVG />, title: t(`booking`), path: 'bookings' }];

    console.log('tabs', tabs);

    return <LayoutTabsContent tabs={tabs} />;
}

export default React.memo(AnalyzeTabs);
