import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogActions from './Action';
import DialogContent from './Content';
import DialogTitle from './Title';
import Button from 'components/button/Base';
import { Typography } from '@material-ui/core';
import { CONFIRM_TYPES } from 'const';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'relative',
        background: theme.palette.common.white,
        boxShadow: theme.shadows[1],
        overflowX: 'hidden',
        minWidth: 400,
        [theme.breakpoints.down('sm')]: {
            minWidth: 200
        }
    },

    content: {
        fontWeight: 400
    },
    error: {
        background: theme.colors.red,
        color: theme.palette.common.white,
        '&:hover': {
            background: theme.colors.red
        }
    },
    btnSubmit: {
        position: 'relative'
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -6,
        marginLeft: -6
    },
    whiteColor: {
        color: theme.palette.common.white
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function ConfirmBox({
    title,
    text,
    onAgree,
    onDisagree,
    agreeLabel = 'Agree',
    disagreeLabel = 'Close',
    type = CONFIRM_TYPES.INFO,
    isLoading,
    content,
    ...rest
}) {
    const classes = useStyles();

    return (
        <Dialog
            classes={{ paper: `${classes.paper} ${rest?.className}` }}
            onClose={onDisagree}
            TransitionComponent={Transition}
            {...rest}
        >
            {title && (
                <DialogTitle variant="body2">
                    <Typography className={classes.whiteColor} variant="h5">
                        {title}
                    </Typography>
                </DialogTitle>
            )}
            <DialogContent>
                {content ? (
                    content
                ) : (
                    <Typography variant="body2" dangerouslySetInnerHTML={{ __html: text }}></Typography>
                )}
            </DialogContent>
            <DialogActions>
                {onDisagree && (
                    <Button size="small" startIcon={<CloseIcon />} onClick={onDisagree} variant="outlined">
                        {disagreeLabel}
                    </Button>
                )}
                {onAgree && (
                    <Grid item className={classes.btnSubmit}>
                        <Button
                            size="small"
                            startIcon={<CheckIcon />}
                            onClick={onAgree}
                            variant="contained"
                            color={type === CONFIRM_TYPES.ERROR ? 'secondary' : 'primary'}
                            autoFocus
                            disabled={isLoading}
                        >
                            {agreeLabel}
                        </Button>
                        {isLoading && <CircularProgress size={12} className={classes.buttonProgress} />}
                    </Grid>
                )}
            </DialogActions>
        </Dialog>
    );
}

export default React.memo(ConfirmBox);
