import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Radio, FormControlLabel, Badge, Avatar } from '@material-ui/core';
import DialogTitle from 'components/dialog/Title';
import DialogContent from 'components/dialog/Content';
import DialogActions from 'components/dialog/Action';
import ButtonBase from 'components/button/Base';
import { Save } from '@material-ui/icons';
import * as settingActions from 'views/settings/actions';
import { useDispatch } from 'react-redux';
import { MerchantState } from 'const';
import { getImageUrl } from 'utils/image';

const useStyles = makeStyles((theme) => ({
    root: {},
    content: {
        minWidth: 400
    }
}));

const StyledBadge = withStyles((theme, ...rest) => {
    return {
        badge: {
            backgroundColor: (props) => (props?.active ? theme.colors.darkGreen : theme.colors.red),
            color: (props) => (props?.active ? theme.colors.darkGreen : theme.colors.red),
            boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
            '&::after': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                animation: '$ripple 1.2s infinite ease-in-out',
                border: '1px solid currentColor',
                content: '""'
            }
        },
        '@keyframes ripple': {
            '0%': {
                transform: 'scale(.8)',
                opacity: 1
            },
            '100%': {
                transform: 'scale(2.4)',
                opacity: 0
            }
        }
    };
})(Badge);

function AddPermission({ id, title, onClose, defaultGroupMerchants = [], merchantOptions = [] }) {
    const classes = useStyles();
    const [merchantIds, setMerchantIds] = React.useState(
        defaultGroupMerchants?.map((gMerchant) => +gMerchant?.merchantId) || []
    );

    const dispatch = useDispatch();

    const handleSave = React.useCallback(() => {
        dispatch(
            settingActions.addGroupMerchantToGroup({
                groupMerchant: {
                    merchantIds,
                    groupId: +id
                },
                error: () => {},
                success: () => {
                    onClose && onClose();
                }
            })
        );
    }, [dispatch, merchantIds, id, onClose]);

    console.log('merchantIds', merchantIds);

    const handleMerchantChange = React.useCallback(
        (merchantId) => {
            setMerchantIds(
                merchantIds?.includes(merchantId)
                    ? merchantIds?.filter((mId) => +mId !== +merchantId)
                    : [...(merchantIds || []), +merchantId]
            );
        },
        [merchantIds]
    );

    return (
        <>
            <DialogTitle>
                <Typography className={classes.whiteColor} variant="h5" component="div">
                    Add merchant to {title}
                </Typography>
            </DialogTitle>
            <DialogContent className={classes.content}>
                <Grid container direction="column" alignItems="flex-start" justifyContent="flex-start">
                    {merchantOptions?.map((merchant) => {
                        return (
                            <Grid item key={merchant?.value}>
                                <FormControlLabel
                                    value={merchant?.value}
                                    merchantsIds
                                    checked={merchantIds?.includes(+merchant?.value)}
                                    control={<Radio onClick={() => handleMerchantChange(+merchant?.value)} />}
                                    label={
                                        <Grid container direction="row" alignItems="center" spacing={2}>
                                            <Grid item>
                                                <StyledBadge
                                                    className={classes.badged}
                                                    active={MerchantState.Active === merchant?.state}
                                                    overlap="circular"
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'right'
                                                    }}
                                                    variant="dot"
                                                >
                                                    <Avatar alt={merchant?.title} src={getImageUrl(merchant?.logo)} />
                                                </StyledBadge>
                                            </Grid>

                                            <Grid item>
                                                <Typography variant="body2">{merchant?.label}</Typography>
                                            </Grid>
                                        </Grid>
                                    }
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container wrap="nowrap" spacing={2} alignItems="center" justifyContent="flex-end">
                    <Grid item>
                        <ButtonBase onClick={onClose} variant="outlined">
                            Close
                        </ButtonBase>
                    </Grid>
                    <Grid item>
                        <ButtonBase color="primary" startIcon={<Save />} onClick={handleSave} variant="contained">
                            Save
                        </ButtonBase>
                    </Grid>
                </Grid>
            </DialogActions>
        </>
    );
}

export default AddPermission;
