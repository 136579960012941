import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        width: (props) => props.width,
        height: (props) => props.height
    }
}));

const SIZES = {
    small: {
        height: 16,
        width: 16
    },
    medium: {
        width: 24,
        height: 24
    },
    large: {
        width: 32,
        height: 32
    }
};

function getSize({ size }) {
    if (typeof size === 'string') return SIZES[size] || size['small'];
    return { width: size, height: size };
}

function CloseSVG({ color = '#C760A7', size = 'large', className, ...rest }) {
    const { width, height } = getSize({ size });
    const classes = useStyles({ width, height });

    return (
        <svg
            className={`${classes.root} ${className}`}
            {...rest}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.8333 5.34166L14.6583 4.16666L9.99999 8.82499L5.34166 4.16666L4.16666 5.34166L8.82499 9.99999L4.16666 14.6583L5.34166 15.8333L9.99999 11.175L14.6583 15.8333L15.8333 14.6583L11.175 9.99999L15.8333 5.34166Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(CloseSVG);
