import { useSelector } from 'react-redux';
import orderBy from 'lodash/orderBy';

export function useStaffsInTurn() {
    return useSelector((state) => state?.turn?.inTurnStaffs);
}

export function useStaffsOutTurn() {
    return useSelector((state) => state?.turn?.outTurnStaffs);
}

export function useInTurnStaffs() {
    const inTurnStaffs = useStaffsInTurn();

    return orderBy(
        inTurnStaffs?.filter((staff) => staff?.isInTurn),
        ['turnOrder'],
        ['asc']
    );
}

export function useOutTurnStaffs() {
    return useSelector((state) => state?.turn?.outTurnStaffs?.filter((staff) => !staff?.isInTurn));
}
