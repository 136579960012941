import request from './request';
import { objectToQueryParams } from 'utils/queryParams';
import isEmpty from 'lodash/isEmpty';

const calendarApis = {
    bookings: `/bookings`,
    booking: (id) => `/bookings/${id}`,
    staffBookings: `/bookings/staffs`,
    notification: `booking-services/send-notification`,
    checkInBooking: (id) => `/bookings/check-in/${id}`,
    checkIn: `/bookings/check-in`,
    bookingLogs: `/booking-logs`
};

export async function createBookingApi({ body }) {
    const response = await request.post(calendarApis.bookings, body);
    return response.data;
}

export async function getBookingsApi(query) {
    let extraUrl = calendarApis.staffBookings;
    if (!isEmpty(query)) {
        extraUrl += `?${objectToQueryParams(query)}`;
    }
    const response = await request.get(extraUrl);
    return response.data;
}

export async function updateBookingApi({ id, body }) {
    const response = await request.put(calendarApis.booking(id), body);
    return response.data;
}

export async function refundBookingApi(id) {
    const response = await request.put(`${calendarApis.booking(id)}/refund`);
    return response.data;
}

export async function updateCheckInBookingApi({ id, body }) {
    const response = await request.put(calendarApis.checkInBooking(id), body);
    return response.data;
}

export async function deleteBookingApi({ id }) {
    const response = await request.delete(calendarApis.booking(id));
    return response.data;
}

export async function deleteBookingServiceApi({ id }) {
    const response = await request.delete(calendarApis.booking(id));
    return response.data;
}

export async function sendNotificationApi({ body }) {
    const response = await request.post(calendarApis.notification, body);
    return response.data;
}

export async function checkInBookingApi({ body }) {
    const response = await request.post(calendarApis.checkIn, body);
    return response.data;
}

export async function getBookingLogsApi(bookingId) {
    const response = await request.get(`${calendarApis.bookingLogs}/${bookingId}`);
    return response.data;
}
