import React from 'react';
import { Typography, Grid, InputBase } from '@material-ui/core';
import ButtonBase from 'components/button/Base';
import { useDispatch } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import Table from 'components/table/NewTable';
import { PADDING_LIST_ITEM_LEFT_RIGHT } from 'const/style';
import Checkbox from '@material-ui/core/Checkbox';
import { PAGINATION_OPTIONS_PER_PAGE, PAGINATION_LIMIT_DEFAULT } from 'const';
import { useSmsRemaining } from 'hooks/merchant';
import * as actionsSmsSchedule from '../action';
import { useSmsClients, useIsFetchingClients } from 'hooks/sms';
import Spinner from 'components/spinner';
import { OPERATOR } from 'const/condition';
import { columnTypes } from 'const/columnTypes';
import PhoneFormat from 'components/phoneFormat';
import { removePlus } from 'utils/phone';
import { searchClientApi } from 'services/client';
import { formatQueriesConditions } from 'utils/queryParams';
import { getAppendMerchantTimezoneToDate, getDatetimeFormat } from 'utils/timing';
import PortalDatePicker from 'components/datepicker/PortalReactDatePickerForm';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import useMediaQueries from 'hooks/useMediaQueries';
import NextIcon from '@material-ui/icons/ArrowForwardIos';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        overflowY: 'hidden',
        padding: theme.spacing(3)
    },

    wrapperContent: {
        width: '100%',
        marginBottom: 10
    },

    tableContent: {
        height: `calc(100% - 64px)`,
        overflowY: 'hidden',
        width: '100%'
    },

    wrapInput: {
        position: 'relative'
    },
    customInput: {
        'label + &': {
            color: theme.colors.secondaryText
        },
        backgroundColor: theme.palette.common.white,
        paddingLeft: PADDING_LIST_ITEM_LEFT_RIGHT,
        paddingRight: 22,
        borderRadius: 3,
        height: 35,
        fontSize: 14,
        fontWeight: 'normal',
        color: theme.colors.primaryText,
        width: '100%'
    },

    clearIcon: {
        position: 'absolute',
        cursor: 'pointer',
        right: 5,
        top: 7,
        zIndex: 9000
    },

    customCheckbox: {
        padding: 0
    },
    value: {
        color: theme.colors.primaryText,
        fontWeight: 600,
        marginLeft: theme.spacing(1)
    },
    spinner: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    content: {
        position: 'relative',
        minHeight: 370,
        padding: 20,
        width: '100%',
        height: '100%',
        flex: 1
    },
    dateItem: {
        minWidth: 250,
        [theme.breakpoints.down('sm')]: {
            minWidth: 'auto',
            width: '100%'
        }
    }
}));

const initialValueFilter = {
    firstName: {
        operator: OPERATOR.contains,
        value: '',
        type: columnTypes.TEXT
    },
    lastName: {
        operator: OPERATOR.contains,
        value: '',
        type: columnTypes.TEXT
    },
    phone: {
        operator: OPERATOR.contains,
        value: '',
        type: columnTypes.TEXT
    },
    address: {
        operator: OPERATOR.contains,
        value: '',
        type: columnTypes.TEXT
    },
    noShowCount: {
        operator: OPERATOR.equal,
        value: '',
        type: columnTypes.NUMBER
    }
};

export default function SelectClient({
    phones,
    handleNext,
    onChangePhones,
    onClearPhones,
    isChooseByDateRanges,
    onToggleChooseDateRange
}) {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const smsRemaining = useSmsRemaining();
    const clients = useSmsClients();
    const isFetchingClients = useIsFetchingClients();
    const { isMobile } = useMediaQueries();

    const timerRef = React.useRef();
    const [filter, setFilter] = React.useState(initialValueFilter);

    const [totalCount, setTotalCount] = React.useState(0);
    const [totalPages, setTotalPages] = React.useState(0);
    const [limit, setLimit] = React.useState(PAGINATION_LIMIT_DEFAULT);
    const [page, setPage] = React.useState(1);

    const [startDate, setStartDate] = React.useState(new Date());
    const [endDate, setEndDate] = React.useState(new Date());
    const [startDatePresent, setStartDatePresent] = React.useState('');
    const [endDatePresent, setEndDatePresent] = React.useState('');

    const handelClear = React.useCallback(() => {
        onChangePhones && onChangePhones([]);
    }, [onChangePhones]);

    const handleFilterChange = React.useCallback(
        (id, value) => {
            if (isChooseByDateRanges) {
                setPage(1);
            }

            setFilter({
                ...filter,
                [id]: {
                    ...filter?.[id],
                    value
                }
            });
        },
        [filter, isChooseByDateRanges]
    );

    const handleClearInput = React.useCallback(
        (id) => {
            const newFilter = {
                ...filter,
                [id]: {
                    ...filter?.[id],
                    value: ''
                }
            };
            setFilter(newFilter);
        },
        [filter]
    );

    React.useEffect(() => {
        if (startDate && endDate && isChooseByDateRanges) {
            const start = getAppendMerchantTimezoneToDate(getDatetimeFormat(startDate));
            const end = getAppendMerchantTimezoneToDate(getDatetimeFormat(endDate));
            dispatch(
                actionsSmsSchedule.fetchClientsByRanges({
                    startDate: start,
                    endDate: end,
                    errorCallback: () => {},
                    successCallback: ({ total }) => {
                        setPage(1);
                        setTotalPages(Math.floor(total / limit) + 1);
                        setTotalCount(total);
                        setFilter(initialValueFilter);
                    }
                })
            );
        }
    }, [dispatch, startDate, endDate, isChooseByDateRanges, limit]);

    const filterHeaderRenderer = React.useCallback(
        ({ column }) => {
            const filterValue = filter?.[column?.id]?.value;

            return (
                <div className={classes.wrapInput}>
                    <InputBase
                        className={classes.customInput}
                        placeholder={'Type to filter'}
                        value={filterValue}
                        onChange={(e) => {
                            if (column?.id === 'phone') e.target.value = e.target.value.replace(/\D/, '');
                            handleFilterChange(column?.id, e.target.value);
                        }}
                    />
                    {filterValue && (
                        <div className={classes.clearIcon} onClick={() => handleClearInput(column?.id)}>
                            <CloseIcon size="small" />
                        </div>
                    )}
                </div>
            );
        },
        [classes.clearIcon, classes.customInput, classes.wrapInput, filter, handleClearInput, handleFilterChange]
    );

    const possibleFilterFields = React.useMemo(() => {
        return Object.keys(filter)?.filter((key) => !!filter?.[key]?.value);
    }, [filter]);

    const clientsFilteredByRangesWithoutPagination = React.useMemo(() => {
        if (!possibleFilterFields?.length) return clients;

        return clients?.filter((client) => {
            let isValid = true;
            for (const key of possibleFilterFields) {
                if (
                    key === 'phone' &&
                    !client?.user?.phone?.toLowerCase()?.includes(filter?.phone?.value?.toLowerCase())
                ) {
                    isValid = false;
                    break;
                }
                if (key !== 'phone' && !client?.[key]?.toLowerCase()?.includes(filter?.[key]?.value?.toLowerCase())) {
                    isValid = false;
                    break;
                }
            }
            return isValid;
        });
    }, [clients, filter, possibleFilterFields]);

    const clientsFilteredByRangesWithPagination = React.useMemo(() => {
        return clientsFilteredByRangesWithoutPagination?.slice(Math.max(page - 1, 0) * limit, page * limit);
    }, [clientsFilteredByRangesWithoutPagination, limit, page]);

    const clientsFiltered = React.useMemo(() => {
        if (!isChooseByDateRanges) return clients;
        return clientsFilteredByRangesWithPagination;
    }, [isChooseByDateRanges, clientsFilteredByRangesWithPagination, clients]);

    const totalPagesFiltered = React.useMemo(() => {
        if (!isChooseByDateRanges || !possibleFilterFields?.length) return totalPages;
        return Math.floor(clientsFilteredByRangesWithPagination?.length / limit) + 1;
    }, [isChooseByDateRanges, totalPages, limit, clientsFilteredByRangesWithPagination, possibleFilterFields]);

    const totalCountFiltered = React.useMemo(() => {
        if (!isChooseByDateRanges || !possibleFilterFields?.length) return totalCount;

        return clientsFilteredByRangesWithPagination?.length;
    }, [isChooseByDateRanges, totalCount, clientsFilteredByRangesWithPagination, possibleFilterFields]);

    const handelAddTotalSearchToGroup = React.useCallback(async () => {
        if (isChooseByDateRanges) {
            const resPhones = clientsFilteredByRangesWithoutPagination?.map((client) =>
                removePlus(client?.user?.phone)
            );
            onChangePhones([...new Set([...phones, ...resPhones])]);
            return;
        }

        const query = formatQueriesConditions(filter);
        const response = await searchClientApi({ query, page: 1, limit: 10000000, isPhoneNotNull: true });

        const resPhones = response.items?.map((item) => removePlus(item?.user?.phone));
        onChangePhones([...new Set([...phones, ...resPhones])]);
    }, [filter, onChangePhones, phones, isChooseByDateRanges, clientsFilteredByRangesWithoutPagination]);

    const headerRenderer = React.useCallback(({ column }) => {
        return <Typography variant="body1">{column.label}</Typography>;
    }, []);

    const showTotalSearchRenderer = React.useCallback(() => {
        return (
            <Grid container alignItems="center" spacing={3}>
                <Grid item>
                    <Typography variant="body2">
                        Total search: <strong>{totalCountFiltered}</strong>
                    </Typography>
                </Grid>
                <Grid item>
                    <ButtonBase size="small" variant="contained" color="primary" onClick={handelAddTotalSearchToGroup}>
                        Add total selected
                    </ButtonBase>
                </Grid>
            </Grid>
        );
    }, [handelAddTotalSearchToGroup, totalCountFiltered]);

    const Selection = React.useCallback(
        ({ rowData }) => {
            return (
                <Checkbox
                    className={classes.customCheckbox}
                    checked={phones.includes(removePlus(rowData?.user?.phone))}
                    color="primary"
                    name={removePlus(rowData?.user?.phone)}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />
            );
        },
        [classes.customCheckbox, phones]
    );

    const onRowClicked = React.useCallback(
        ({ rowData }) => {
            const phone = removePlus(rowData.user?.phone);
            const isExist = phones.includes(phone);
            if (isExist) {
                onChangePhones(phones.filter((item) => item !== phone));
            } else {
                onChangePhones([...new Set([...phones, phone])]);
            }
        },
        [onChangePhones, phones]
    );

    const columns = [
        {
            id: 'selection',
            label: '',
            width: 120,
            searchable: false,
            format: (props) => <Selection {...props} />
        },
        {
            id: 'firstName',
            label: 'First name',
            width: 150,
            searchable: true
        },
        {
            id: 'lastName',
            label: 'Last Name',
            width: 150,
            searchable: true
        },
        {
            id: 'phone',
            label: 'Phone',
            width: 150,
            searchable: true,
            format: (props) => <PhoneFormat number={props?.rowData?.user?.phone} />
        },
        {
            id: 'address',
            label: 'Address',
            width: 150,
            searchable: true
        }
    ];

    const onChangePageOptions = React.useCallback((e) => {
        setPage(1);
        setLimit(e.target.value);
    }, []);

    const onChangePage = (event, page) => {
        setPage(page);
    };

    const handleStartDateChange = React.useCallback((date) => {
        setStartDate(date);
        setStartDatePresent(moment(date).format(`dddd, DD MMMM YYYY`));
    }, []);

    const handleEndDateChange = React.useCallback((date) => {
        setEndDate(date);
        setEndDatePresent(moment(date).format(`dddd, DD MMMM YYYY`));
    }, []);

    React.useEffect(() => {
        if (isChooseByDateRanges) return;
        if (timerRef.current) clearTimeout(timerRef.current);
        timerRef.current = setTimeout(async () => {
            dispatch(
                actionsSmsSchedule.fetchClients({
                    searchCondition: filter,
                    page,
                    limit,
                    errorCallback: () => {
                        console.log('Search clients errors');
                    },
                    successCallback: ({ totalPages, total }) => {
                        setTotalPages(totalPages);
                        setTotalCount(total);
                    }
                })
            );
        }, 500);
    }, [dispatch, filter, limit, page, isChooseByDateRanges]);

    return (
        <Grid container className={classes.root}>
            <Grid item className={classes.wrapperContent}>
                <Grid container justifyContent="space-between" alignContent="center" alignItems="center">
                    <Grid item>
                        <Grid container spacing={3} alignItems="center">
                            <Grid item>
                                <Typography variant="body2">
                                    SMS Remaining: <strong>{smsRemaining - phones.length}</strong>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="body2">
                                    Selected Client: <strong>{phones.length}</strong>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <ButtonBase
                                    size="small"
                                    variant="outlined"
                                    onClick={handelClear}
                                    style={{ marginRight: 10 }}
                                >
                                    Clear
                                </ButtonBase>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" alignItems="center" spacing={2}>
                            {isChooseByDateRanges && (
                                <Grid item>
                                    <Grid container direction="row" alignItems="center" spacing={2}>
                                        <Grid item className={classes.dateItem}>
                                            <PortalDatePicker
                                                maxDate={endDatePresent ? endDate : ''}
                                                onChange={handleStartDateChange}
                                                defaultValue={startDate}
                                                label={t(`start_date`)}
                                                placeholderText={t(`closed_date_start_date_placeholder`)}
                                                withPortal={isMobile}
                                                defaultDatePresent={
                                                    startDatePresent ? startDatePresent : 'Selected date'
                                                }
                                                isUsedDatePresent={true}
                                            />
                                        </Grid>
                                        {!isMobile && (
                                            <Grid item style={{ display: 'flex', position: 'relative', top: 12 }}>
                                                <NextIcon color={theme.colors.black} />
                                            </Grid>
                                        )}
                                        <Grid item className={classes.dateItem}>
                                            <PortalDatePicker
                                                minDate={startDatePresent ? startDate : ''}
                                                onChange={handleEndDateChange}
                                                defaultValue={endDate}
                                                defaultDatePresent={endDatePresent ? endDatePresent : 'Selected date'}
                                                label={t(`end_date`)}
                                                withPortal={isMobile}
                                                isUsedDatePresent={true}
                                                placeholderText={t(`closed_date_end_date_placeholder`)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                            <Grid item>
                                <ButtonBase size="small" variant="contained" onClick={onToggleChooseDateRange}>
                                    {isChooseByDateRanges ? 'Choose Client List' : 'Choose By Date Ranges'}
                                </ButtonBase>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {
                <Grid item className={classes.tableContent}>
                    {isFetchingClients && (
                        <div className={classes.spinner}>
                            <Spinner size={40} thick={4} />
                        </div>
                    )}

                    <Table
                        isBorder={true}
                        columns={columns}
                        rows={clientsFiltered || []}
                        page={page}
                        limit={limit}
                        totalPages={totalPagesFiltered}
                        rowPerPageOptions={PAGINATION_OPTIONS_PER_PAGE}
                        onChangePageOptions={onChangePageOptions}
                        onChangePage={onChangePage}
                        onRowClicked={onRowClicked}
                        headerRenderer={headerRenderer}
                        filterHeaderRenderer={filterHeaderRenderer}
                        showTotalSearchRenderer={showTotalSearchRenderer}
                    />
                </Grid>
            }
        </Grid>
    );
}
