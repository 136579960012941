import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';

function ShowCase({ title, amount, color = 'rgb(0, 123, 85)' }) {
    return (
        <Box
            style={{
                transition: `box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
                backgroundImage: 'none',
                overflow: 'hidden',
                position: 'relative',
                borderRadius: '16px',
                zIndex: 0,
                width: '100%',
                boxShadow: 'none',
                textAlign: 'center',
                padding: `10px 0px`,
                color: color,
                backgroundColor: alpha(color, 0.1)
            }}
        >
            <Grid container direction="column" alignItems="center" justifyContent="space-between">
                <Typography variant="h3">{amount}</Typography>
                <Typography variant="body1" style={{ fontSize: 12 }}>
                    {title}
                </Typography>
            </Grid>
        </Box>
    );
}

export default ShowCase;
