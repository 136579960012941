import React from 'react';
import { Grid } from '@material-ui/core';
import { useDoneBookingsWithoutPaid, useGiftCodes, useIsOpenCheckout } from 'hooks/calendar';
import Checkout from './index';
import { useServiceCategoryList } from 'hooks/service';
import { useStaffOptions } from 'hooks/staff';
import { useDispatch } from 'react-redux';
import * as calendarActions from 'views/calender/actions';

function CheckoutWrapper() {
    const dispatch = useDispatch();
    const isOpenCheckout = useIsOpenCheckout();
    const { clientsDone, clientDoneDetail } = useDoneBookingsWithoutPaid();
    const { serviceList, categoryList } = useServiceCategoryList();
    const staffListOptions = useStaffOptions();
    const giftCodes = useGiftCodes();

    const handleClose = React.useCallback(() => {
        dispatch(calendarActions.setIsOpenCheckout(false));
    }, [dispatch]);

    if (!isOpenCheckout) return null;

    return (
        <Grid item style={{ width: 300 }}>
            <Checkout
                doneBookings={clientsDone}
                clientsById={clientDoneDetail}
                categoryList={categoryList}
                serviceList={serviceList}
                staffList={staffListOptions}
                onClose={handleClose}
                giftCodes={giftCodes}
            />
        </Grid>
    );
}

export default CheckoutWrapper;
