import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button, CircularProgress } from '@material-ui/core';
import 'react-dates/lib/css/_datepicker.css';
import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { IMPORT_FILE_EXTENSIONS } from 'const';
import { enqueueSnackbar } from 'notifier/actions';
import { CloudUpload } from '@material-ui/icons';
import { importService } from 'services/service';
import { importClient } from 'services/client';
import { importBooking } from 'services/bookings';
import { importStaff } from 'services/staff';
import CheckedSVG from 'assets/images/svg/CheckedSVG';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 700,
        boxSizing: 'border-box',
        padding: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2)
        }
    },
    uploadSection: {
        background: theme.palette.common.white,
        borderRadius: 4,
        flex: 1,
        padding: theme.spacing(2),
        marginRight: theme.spacing(2)
    },
    mr2: {
        marginRight: theme.spacing(2)
    },
    mb2: {
        marginBottom: theme.spacing(2)
    },
    mb3: {
        marginBottom: theme.spacing(3)
    },
    dpFlex: {
        display: 'flex'
    },
    hasFile: {
        border: `1px solid ${theme.palette.primary.main}`
    },
    btnSubmit: {
        position: 'relative'
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    highlight: {
        color: theme.colors.red
    },
    highlightBlue: {
        color: theme.palette.primary.main
    }
}));

function Analyze({ merchant }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [serviceFile, setServiceFile] = React.useState(null);
    const [isUploadService, setIsUploadService] = React.useState(false);
    const [clientFile, setClientFile] = React.useState(null);
    const [isUploadClient, setIsUploadClient] = React.useState(false);

    const [staffFile, setStaffFile] = React.useState(null);
    const [isUploadStaff, setIsUploadStaff] = React.useState(false);

    const [bookingFile, setBookingFile] = React.useState(null);
    const [isUploadBooking, setIsUploadBooking] = React.useState(false);

    const [status, setStatus] = React.useState({
        service: false,
        client: false,
        staff: false,
        booking: false
    });

    const onServiceFileAccepted = React.useCallback((files) => {
        const file = files?.[0];
        setServiceFile(file);
    }, []);

    const onClientFileAccepted = React.useCallback((files) => {
        const file = files?.[0];
        setClientFile(file);
    }, []);

    const onStaffFileAccepted = React.useCallback((files) => {
        const file = files?.[0];
        setStaffFile(file);
    }, []);

    const onBookingFileAccepted = React.useCallback((files) => {
        const file = files?.[0];
        setBookingFile(file);
    }, []);

    const uploadService = React.useCallback(async () => {
        if (!serviceFile) {
            dispatch(enqueueSnackbar({ message: 'Please upload file service', type: 'info' }));
            return;
        }

        setIsUploadService(true);
        try {
            const response = await importService({ file: serviceFile, merchantId: +merchant?.id });
            if (response?.success) {
                setStatus({ ...status, service: true });
            } else {
                setStatus({ ...status, service: false });
            }
            setIsUploadService(false);
        } catch (error) {
            setIsUploadService(false);
            setStatus({ ...status, service: false });
            dispatch(enqueueSnackbar({ message: error?.message, type: 'info' }));
        }
    }, [serviceFile, dispatch, merchant, status]);

    const uploadStaff = React.useCallback(async () => {
        if (!staffFile) {
            dispatch(enqueueSnackbar({ message: 'Please upload file staff', type: 'info' }));
            return;
        }

        setIsUploadStaff(true);
        try {
            const response = await importStaff({ file: staffFile, merchantId: +merchant?.id });
            if (response?.success) {
                setStatus({ ...status, staff: true });
            } else {
                setStatus({ ...status, staff: false });
            }
            setIsUploadStaff(false);
        } catch (error) {
            setIsUploadStaff(false);
            setStatus({ ...status, staff: false });
            dispatch(enqueueSnackbar({ message: error?.message, type: 'info' }));
        }
    }, [staffFile, dispatch, merchant, status]);

    const uploadClient = React.useCallback(async () => {
        if (!clientFile) {
            dispatch(enqueueSnackbar({ message: 'Please upload file client', type: 'info' }));
            return;
        }

        setIsUploadClient(true);
        try {
            const response = await importClient({ file: clientFile, merchantId: +merchant?.id });
            if (response?.success) {
                setStatus({ ...status, client: true });
            } else {
                setStatus({ ...status, client: false });
            }
            setIsUploadClient(false);
        } catch (error) {
            setIsUploadClient(false);
            setStatus({ ...status, client: false });
            dispatch(enqueueSnackbar({ message: error?.message, type: 'info' }));
        }
    }, [clientFile, dispatch, merchant, status]);

    const uploadBooking = React.useCallback(async () => {
        if (!bookingFile) {
            dispatch(enqueueSnackbar({ message: 'Please upload file booking', type: 'info' }));
            return;
        }

        setIsUploadBooking(true);
        try {
            const response = await importBooking({ file: bookingFile, merchantId: +merchant?.id });
            if (response?.success) {
                setStatus({ ...status, booking: true });
            } else {
                setStatus({ ...status, booking: false });
            }
            setIsUploadBooking(false);
        } catch (error) {
            setIsUploadBooking(false);
            setStatus({ ...status, booking: false });
            dispatch(enqueueSnackbar({ message: error?.message, type: 'info' }));
        }
    }, [bookingFile, dispatch, merchant, status]);

    const onReject = React.useCallback(
        (files) => {
            dispatch(enqueueSnackbar({ message: 'Rejected file', type: 'info' }));
        },
        [dispatch]
    );

    const { getRootProps: serviceRootProps, getInputProps: serviceInputProps } = useDropzone({
        onDropAccepted: onServiceFileAccepted,
        onDropRejected: onReject,
        accept: IMPORT_FILE_EXTENSIONS
    });

    const { getRootProps: clientRootProps, getInputProps: clientInputProps } = useDropzone({
        onDropAccepted: onClientFileAccepted,
        onDropRejected: onReject,
        accept: IMPORT_FILE_EXTENSIONS
    });

    const { getRootProps: staffRootProps, getInputProps: staffInputProps } = useDropzone({
        onDropAccepted: onStaffFileAccepted,
        onDropRejected: onReject,
        accept: IMPORT_FILE_EXTENSIONS
    });

    const { getRootProps: bookingRootProps, getInputProps: bookingInputProps } = useDropzone({
        onDropAccepted: onBookingFileAccepted,
        onDropRejected: onReject,
        accept: IMPORT_FILE_EXTENSIONS
    });

    return (
        <Grid container wrap="nowrap" className={classes.root} direction="column">
            <Grid item container direction="column" className={classes.mb3}>
                <Grid item className={classes.mb2}>
                    <Typography variant="body1" display="inline">
                        Step 1:
                    </Typography>{' '}
                    <Typography variant="body2" display="inline">
                        Upload Service
                    </Typography>
                </Grid>
                <Grid item>
                    <Grid container direction="row" alignItems="center" justifyContent="space-between">
                        <Grid item className={`${classes.uploadSection} ${serviceFile ? classes.hasFile : ``}`}>
                            <div {...serviceRootProps()}>
                                <input {...serviceInputProps()} />
                                {!serviceFile ? (
                                    <Grid container direction="row" alignItems="center">
                                        <Grid item className={`${classes.mr2} ${classes.dpFlex}`}>
                                            <CloudUpload />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body2">Click or drag a "SERVICE" file here</Typography>
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <Grid container direction="row" alignItems="center">
                                        <Grid item className={`${classes.mr2} ${classes.dpFlex}`}>
                                            <CloudUpload />
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.highlightBlue} variant="body2">
                                                {serviceFile?.name}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}
                            </div>
                        </Grid>
                        <Grid item>
                            <Grid container direction="row" alignItems="center" spacing={2}>
                                <Grid item>
                                    <div className={classes.btnSubmit}>
                                        <Button
                                            fullWidth
                                            type="submit"
                                            color="primary"
                                            variant="contained"
                                            disabled={isUploadService || !serviceFile}
                                            className={classes.input}
                                            onClick={uploadService}
                                        >
                                            Upload
                                        </Button>
                                        {isUploadService && (
                                            <CircularProgress size={24} className={classes.buttonProgress} />
                                        )}
                                    </div>
                                </Grid>
                                {status?.service && (
                                    <Grid item>
                                        <CheckedSVG width={40} height={40} />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container direction="column" className={classes.mb3}>
                <Grid item className={classes.mb2}>
                    <Typography variant="body1" display="inline">
                        Step 2:
                    </Typography>{' '}
                    <Typography variant="body2" display="inline">
                        Upload Client
                    </Typography>
                </Grid>
                <Grid item>
                    <Grid container direction="row" alignItems="center" justifyContent="space-between">
                        <Grid item className={`${classes.uploadSection} ${clientFile ? classes.hasFile : ``}`}>
                            <div {...clientRootProps()}>
                                <input {...clientInputProps()} />
                                {!clientFile ? (
                                    <Grid container direction="row" alignItems="center">
                                        <Grid item className={`${classes.mr2} ${classes.dpFlex}`}>
                                            <CloudUpload />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body2">Click or drag a "CLIENT" file here</Typography>
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <Grid container direction="row" alignItems="center">
                                        <Grid item className={`${classes.mr2} ${classes.dpFlex}`}>
                                            <CloudUpload />
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.highlightBlue} variant="body2">
                                                {clientFile?.name}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}
                            </div>
                        </Grid>
                        <Grid item>
                            <Grid container direction="row" alignItems="center" spacing={2}>
                                <Grid item>
                                    <div className={classes.btnSubmit}>
                                        <Button
                                            fullWidth
                                            type="submit"
                                            color="primary"
                                            variant="contained"
                                            disabled={isUploadClient || !clientFile}
                                            className={classes.input}
                                            onClick={uploadClient}
                                        >
                                            Upload
                                        </Button>
                                        {isUploadClient && (
                                            <CircularProgress size={24} className={classes.buttonProgress} />
                                        )}
                                    </div>
                                </Grid>
                                {status?.client && (
                                    <Grid item>
                                        <CheckedSVG width={40} height={40} />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item container direction="column" className={classes.mb3}>
                <Grid item className={classes.mb2}>
                    <Typography variant="body1" display="inline">
                        Step 3:
                    </Typography>{' '}
                    <Typography variant="body2" display="inline">
                        Upload Staff
                    </Typography>
                </Grid>
                <Grid item>
                    <Grid container direction="row" alignItems="center" justifyContent="space-between">
                        <Grid item className={`${classes.uploadSection} ${staffFile ? classes.hasFile : ``}`}>
                            <div {...staffRootProps()}>
                                <input {...staffInputProps()} />
                                {!staffFile ? (
                                    <Grid container direction="row" alignItems="center">
                                        <Grid item className={`${classes.mr2} ${classes.dpFlex}`}>
                                            <CloudUpload />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body2">Click or drag a "STAFF" file here</Typography>
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <Grid container direction="row" alignItems="center">
                                        <Grid item className={`${classes.mr2} ${classes.dpFlex}`}>
                                            <CloudUpload />
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.highlightBlue} variant="body2">
                                                {staffFile?.name}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}
                            </div>
                        </Grid>
                        <Grid item>
                            <Grid container direction="row" alignItems="center" spacing={2}>
                                <Grid item>
                                    <div className={classes.btnSubmit}>
                                        <Button
                                            fullWidth
                                            type="submit"
                                            color="primary"
                                            variant="contained"
                                            disabled={isUploadStaff || !staffFile}
                                            className={classes.input}
                                            onClick={uploadStaff}
                                        >
                                            Upload
                                        </Button>
                                        {isUploadStaff && (
                                            <CircularProgress size={24} className={classes.buttonProgress} />
                                        )}
                                    </div>
                                </Grid>
                                {status?.staff && (
                                    <Grid item>
                                        <CheckedSVG width={40} height={40} />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container direction="column" className={classes.mb3}>
                <Grid item className={classes.mb2}>
                    <Typography variant="body1" display="inline">
                        Step 4:
                    </Typography>{' '}
                    <Typography className={classes.highlight} variant="body1" display="inline">
                        Please login new merchant and assign staff to all service before Upload
                    </Typography>
                </Grid>
            </Grid>

            <Grid item container direction="column" className={classes.mb3}>
                <Grid item className={classes.mb2}>
                    <Typography variant="body1" display="inline">
                        Step 5:
                    </Typography>{' '}
                    <Typography variant="body2" display="inline">
                        Upload Booking (PLease make sure all above file has uploaded)
                    </Typography>
                </Grid>
                <Grid item>
                    <Grid container direction="row" alignItems="center" justifyContent="space-between">
                        <Grid item className={`${classes.uploadSection} ${bookingFile ? classes.hasFile : ``}`}>
                            <div {...bookingRootProps()}>
                                <input {...bookingInputProps()} />
                                {!bookingFile ? (
                                    <Grid container direction="row" alignItems="center">
                                        <Grid item className={`${classes.mr2} ${classes.dpFlex}`}>
                                            <CloudUpload />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body2">Click or drag a "BOOKING" file here</Typography>
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <Grid container direction="row" alignItems="center">
                                        <Grid item className={`${classes.mr2} ${classes.dpFlex}`}>
                                            <CloudUpload />
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.highlightBlue} variant="body2">
                                                {bookingFile?.name}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}
                            </div>
                        </Grid>
                        <Grid item>
                            <Grid container direction="row" alignItems="center" spacing={2}>
                                <Grid item>
                                    <div className={classes.btnSubmit}>
                                        <Button
                                            fullWidth
                                            type="submit"
                                            color="primary"
                                            variant="contained"
                                            disabled={isUploadBooking || !bookingFile}
                                            className={classes.input}
                                            onClick={uploadBooking}
                                        >
                                            Upload
                                        </Button>
                                        {isUploadBooking && (
                                            <CircularProgress size={24} className={classes.buttonProgress} />
                                        )}
                                    </div>
                                </Grid>
                                {status?.booking && (
                                    <Grid item>
                                        <CheckedSVG width={40} height={40} />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Analyze;
