import React from 'react';
import { makeStyles, useTheme, alpha } from '@material-ui/core/styles';
import { Grid, useMediaQuery, Typography, IconButton, Dialog } from '@material-ui/core';
import LayoutTabContent from 'views/layout/LayoutTabContent';
import NativeSelect from 'components/select/Native';
import Table from 'components/table';
import {
    getUTCbyMerchantDatetimeFormat,
    isAfter,
    isAfterOrEqual,
    mapTimeToTime12,
    getNumRangeTimeStart,
    getNumRangeTimeEnd
} from 'utils/timing';
import DateRange from 'components/dateRange';
import { AddOutlined } from '@material-ui/icons';
import WorkingHourForm from './WorkingHourForm';
import {
    useIsFetchingStaffWorkingHours,
    useStaffWorkingHoursUsers,
    useStaffWorkingHoursSchedules,
    useClosedDatesFormatDate
} from 'hooks/staff';
import { useDispatch } from 'react-redux';
import * as staffActions from './action';
import { useTranslation } from 'react-i18next';
import useMediaQueries from 'hooks/useMediaQueries';
import Spinner from 'components/spinner';
import { useMerchantTime12 } from 'hooks/merchant';
import Tooltip from 'components/tooltip';
import Avatar from 'components/avatar';
import { getImageUrl } from 'utils/image';
import CloseIcon from '@material-ui/icons/EventBusy';

const TABLE_HEADER_HEIGHT = 50;
const TABLE_ROW_HEIGHT = 50;

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%'
    },
    w200: {
        width: 200
    },
    table: {
        width: `100%`,
        marginTop: 24,
        height: `calc(100% - ${TABLE_HEADER_HEIGHT}px - 24px)`,
        overflowY: 'hidden',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
            height: `calc(100% - ${TABLE_HEADER_HEIGHT + 24}px)`,
            maxHeight: `-webkit-fill-available`
        }
    },
    cell: {
        borderRight: `1px solid ${theme.colors.border}`,
        borderBottom: `1px solid ${theme.colors.border}`,
        '&:hover': {
            cursor: 'pointer',
            background: theme.colors.solitude
        },
        boxSizing: 'border-box'
    },
    emptyCell: {
        justifyContent: 'center',
        '& .addIcon': {
            display: 'none'
        },
        '&:hover': {
            '& .addIcon': {
                display: 'block'
            }
        }
    },
    freezingCell: {
        display: 'flex',
        borderRight: `1px solid ${theme.colors.border}`,
        borderBottom: `1px solid ${theme.colors.border}`,
        borderLeft: `1px solid ${theme.colors.border}`,
        // background: theme.palette.primary.main,
        color: theme.palette.common.white
    },
    timeShiftWrapper: {
        paddingLeft: 5,
        paddingRight: 5
    },
    timeShift: {
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        padding: `6px 10px`,
        fontWeight: 700,
        minWidth: 120,
        borderRadius: theme.radius(2),
        width: '100%',
        margin: 3,
        fontSize: 12
    },
    timeShiftDisabled: {
        background: theme.colors.ghostwhite,
        cursor: 'not-allowed',
        color: alpha(theme.palette.common.black, 0.5)
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        borderRight: `1px solid ${theme.colors.border}`,
        borderBottom: `1px solid ${theme.colors.border}`,
        borderTop: `1px solid ${theme.colors.border}`
        // background: theme.palette.primary.main,
        // color: theme.palette.common.white,
    },
    hoverTime: {
        width: '100%',
        height: '100%',
        background: theme.colors.hoverItem,
        color: theme.colors.primaryText,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 8,
        border: `1px dashed ${theme.colors.border}`,
        cursor: 'pointer'
    },
    dropdown: {
        width: 300
    },
    cancel: {
        width: 26,
        height: 26,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.colors.ghostwhite
    },
    closedDate: {
        pointerEvents: 'none'
    }
}));

function CellRender({
    rowIndex,
    columnIndex,
    style,
    columns,
    staffFiltered,
    onRefreshCurrentDateSchedule,
    closedDatesFormatDate
}) {
    const classes = useStyles();
    const theme = useTheme();
    const [isOpenWorkingHourForm, setIsOpenWorkingHourForm] = React.useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const TIME_12 = useMerchantTime12();

    const handleCloseWorkingHourForm = () => setIsOpenWorkingHourForm(false);
    const handleOpenWorkingHourForm = () => setIsOpenWorkingHourForm(true);

    const columnData = columns[columnIndex - 1];
    const closedDate = closedDatesFormatDate?.find((clDate) => clDate.date === columnData?.date);
    const isClosedDate = Boolean(closedDate);
    const { date, dateOfWeek } = columnData;
    const rowData = staffFiltered?.[rowIndex];

    const scheduleRanges = useStaffWorkingHoursSchedules({ staffId: +rowData?.id }) || [];

    const rangesByDayOfWeek = scheduleRanges
        .filter((schedule) => !schedule.isDeleted)
        .filter((range) => range?.dateOfWeek?.toLowerCase() === dateOfWeek?.toLowerCase());

    let rangesByDayOfWeekFormatted = rangesByDayOfWeek.filter((range) => {
        const date_start = getUTCbyMerchantDatetimeFormat(range.dateStart);
        const dateEnd = range.dateEnd;

        if (isAfter(date, date_start)) {
            return false;
        } else if (dateEnd === null) {
            return true;
        } else {
            const date_end = getUTCbyMerchantDatetimeFormat(dateEnd);
            if (isAfterOrEqual(date, date_end)) {
                return true;
            } else if (date_end === date_start) {
                return true;
            }
            return false;
        }
    });

    if (rangesByDayOfWeekFormatted.length === 0) {
        return (
            <>
                <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    wrap="nowrap"
                    style={style}
                    className={`${isClosedDate ? classes.closedDate : ''} ${classes.cell} ${classes.emptyCell}`}
                    onClick={handleOpenWorkingHourForm}
                >
                    {!isClosedDate && <AddOutlined className={`addIcon`} />}
                </Grid>
                <Dialog fullScreen={fullScreen} open={isOpenWorkingHourForm} onClose={handleCloseWorkingHourForm}>
                    <WorkingHourForm
                        onRefreshCurrentDateSchedule={onRefreshCurrentDateSchedule}
                        date={columnData}
                        user={rowData}
                        handleClose={handleCloseWorkingHourForm}
                    />
                </Dialog>
            </>
        );
    }

    const schedule = rangesByDayOfWeekFormatted?.[0];
    const { scheduleRangeFirst, scheduleRangeSecond } = schedule;

    return (
        <>
            <Grid
                container
                alignItems="center"
                justifyContent="center"
                direction="column"
                wrap="nowrap"
                style={style}
                className={`${isClosedDate ? classes.closedDate : ''} ${classes.cell} ${classes.emptyCell}`}
                onClick={handleOpenWorkingHourForm}
            >
                {[scheduleRangeFirst, scheduleRangeSecond]?.filter(Boolean).map((shift, index) => {
                    return (
                        <Grid item key={index} className={classes.timeShiftWrapper}>
                            <Typography
                                variant="body2"
                                className={`${classes.timeShift} ${isClosedDate ? classes.timeShiftDisabled : ''}`}
                            >{`${
                                TIME_12 ? mapTimeToTime12(getNumRangeTimeStart(shift)) : getNumRangeTimeStart(shift)
                            } - ${
                                TIME_12 ? mapTimeToTime12(getNumRangeTimeEnd(shift)) : getNumRangeTimeEnd(shift)
                            }`}</Typography>
                        </Grid>
                    );
                })}
            </Grid>
            <Dialog
                fullScreen={fullScreen}
                maxWidth={'md'}
                DialogContentBase
                open={isOpenWorkingHourForm}
                onClose={handleCloseWorkingHourForm}
            >
                <WorkingHourForm
                    date={columnData}
                    user={rowData}
                    isEdit={true}
                    schedule={schedule}
                    handleClose={handleCloseWorkingHourForm}
                    onRefreshCurrentDateSchedule={onRefreshCurrentDateSchedule}
                />
            </Dialog>
        </>
    );
}

const CellRenderMemo = React.memo(CellRender);

function StaffWorkingHours() {
    const classes = useStyles();
    const { t } = useTranslation();
    const { isMobile } = useMediaQueries();
    const [columns, setColumns] = React.useState([]);
    const dispatch = useDispatch();
    const theme = useTheme();
    const isFetchingWorkingHours = useIsFetchingStaffWorkingHours();
    const staffWorkingHoursUser = useStaffWorkingHoursUsers();
    const [filterStaffvalue, setFilterStaffValue] = React.useState(0);
    const timer = React.useRef();
    const closedDatesFormatDate = useClosedDatesFormatDate();

    React.useEffect(() => {
        if (columns.length > 0) {
            const dateStart = columns?.[0]?.date;
            const dateEnd = columns?.[columns.length - 1]?.date;

            if (timer.current) clearTimeout(timer.current);
            timer.current = setTimeout(() => {
                dispatch(
                    staffActions.getStaffWorkingHours({
                        dateStart,
                        dateEnd,
                        errorCallback: () => {
                            console.log('failed to fetch staff working hours');
                        },
                        successCallback: () => {
                            console.log('fetch staff successfully');
                        }
                    })
                );
            }, 200);
        }
    }, [dispatch, columns]);

    React.useEffect(() => {
        dispatch(
            staffActions.fetchClosedDates({
                errorCallback: () => {
                    console.log('fetchClosedDates failed');
                },
                successCallback: () => {
                    console.log('fetchClosedDates success');
                }
            })
        );
    }, [dispatch]);

    const onRefreshCurrentDateSchedule = () => {
        console.log('onRefreshCurrentDateSchedule');
        const dateStart = columns?.[0]?.date;
        const dateEnd = columns?.[columns.length - 1]?.date;
        dispatch(
            staffActions.getStaffWorkingHours({
                dateStart,
                dateEnd,
                errorCallback: () => {
                    console.log('failed to fetch staff working hours');
                },
                successCallback: () => {
                    console.log('fetch staff successfully');
                }
            })
        );
    };

    const onRangeChanged = React.useCallback((range) => {
        setColumns(range);
    }, []);

    const staffOptions = React.useMemo(() => {
        const staffs = staffWorkingHoursUser || [];
        return [
            { label: t('all_staff'), value: 0 },
            ...staffs.map((member) => ({
                ...member,
                label: member.name,
                value: member.id
            }))
        ];

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [staffWorkingHoursUser]);

    const staffFiltered = React.useMemo(() => {
        const filterStaffvalueToNumber = Number(filterStaffvalue);
        const matched =
            filterStaffvalueToNumber === 0
                ? staffWorkingHoursUser
                : [staffWorkingHoursUser.find((member) => Number(member.id) === filterStaffvalueToNumber)];
        return matched;
    }, [filterStaffvalue, staffWorkingHoursUser]);

    const freezingRender = React.useCallback(
        ({ rowIndex, columnIndex, style }) => {
            const data = staffFiltered?.[rowIndex];
            return (
                <Grid
                    container
                    alignItems="center"
                    justifyContent="flex-start"
                    style={{ ...style, flex: 1, paddingLeft: 8, paddingRight: 8 }}
                    className={classes.freezingCell}
                    wrap="nowrap"
                >
                    <Grid item style={{ marginRight: 6 }}>
                        <Avatar size="medium" src={getImageUrl(data?.avatar)}>
                            {data?.name?.slice(0, 1)}
                        </Avatar>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1" align="left">
                            {data?.name}
                        </Typography>
                    </Grid>
                </Grid>
            );
        },
        [classes, staffFiltered]
    );

    const headerRender = React.useCallback(
        ({ columnIndex, style }) => {
            if (columnIndex === 0)
                return (
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="center"
                        style={{
                            ...style,
                            borderLeft: `1px solid ${theme.colors.border}`,
                            paddingLeft: 12
                        }}
                        className={classes.header}
                    >
                        <Typography variant="body1">{t(`staff`)}</Typography>
                    </Grid>
                );
            const column = columns[columnIndex - 1];
            const closedDate = closedDatesFormatDate?.find((clDate) => clDate.date === column?.date);
            const isClosedDate = Boolean(closedDate);

            return (
                <div
                    style={{
                        ...style,
                        justifyContent: 'center',
                        position: 'relative'
                    }}
                    className={classes.header}
                >
                    <Grid container spacing={1} wrap="nowrap" direction="column">
                        {column?.label}
                    </Grid>
                    {isClosedDate && (
                        <Tooltip title={closedDate?.description} placement="top">
                            <IconButton
                                color="secondary"
                                size="small"
                                style={{ position: 'absolute', top: 0, right: 0 }}
                                className={classes.cancel}
                            >
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    )}
                </div>
            );
        },
        [columns, t, theme, classes, closedDatesFormatDate]
    );

    const handleStaffChange = React.useCallback((e) => {
        setFilterStaffValue(e.target.value);
    }, []);

    return (
        <LayoutTabContent isLoading={false}>
            <Grid container className={classes.root} direction="column" wrap="nowrap">
                <Grid item>
                    <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                        <Grid item xs={12} md={3} lg={2}>
                            <NativeSelect
                                onChange={handleStaffChange}
                                selectedValue={filterStaffvalue}
                                options={staffOptions}
                                placeholder={t(`choose_an_option`)}
                            />
                        </Grid>
                        <Grid item style={{ width: isMobile ? '100%' : '' }}>
                            <DateRange disabled={isFetchingWorkingHours} onRangeChanged={onRangeChanged} />
                        </Grid>
                        <Grid item xs={2} md={3} lg={2}></Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.table}>
                    {isFetchingWorkingHours && (
                        <Grid
                            container
                            style={{
                                height: `calc(100vh - 96px - 70px - 24px)`,
                                paddingTop: 100,
                                background: theme.palette.common.white,
                                opacity: 0.6,
                                zIndex: 100,
                                position: 'absolute',
                                top: 0,
                                left: 0
                            }}
                            justifyContent="center"
                        >
                            <Spinner size={40} thick={4} />
                        </Grid>
                    )}
                    {staffFiltered?.length >= 0 && (
                        <Table
                            cellRender={(props) => (
                                <CellRenderMemo
                                    columns={columns}
                                    staffFiltered={staffFiltered}
                                    onRefreshCurrentDateSchedule={onRefreshCurrentDateSchedule}
                                    closedDatesFormatDate={closedDatesFormatDate}
                                    {...props}
                                />
                            )}
                            rowHeight={TABLE_ROW_HEIGHT}
                            rowCount={staffFiltered?.length}
                            columnCount={columns.length + 1}
                            freezingRender={freezingRender}
                            headerRender={headerRender}
                            INDEX_COLUMN_WIDTH={160}
                            HEADER_HEIGHT={TABLE_HEADER_HEIGHT}
                        />
                    )}
                </Grid>
            </Grid>
        </LayoutTabContent>
    );
}

export default React.memo(StaffWorkingHours);
