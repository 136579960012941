const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

export function removePlus(phone) {
    if (!phone) return '';
    if (phone?.startsWith('+')) {
        return phone.slice(1);
    }
    return phone;
}

export function addPlus(phone) {
    if (!phone) return '';

    if (phone?.startsWith('+')) {
        return phone;
    }
    return `+${phone}`;
}

export function isValidPhone(phone) {
    try {
        const pn = phoneUtil.parse(addPlus(phone));
        if (!pn.hasCountryCode() || !pn.hasNationalNumber()) {
            return false;
        }
        if (!phoneUtil.isValidNumber(pn)) {
            return false;
        }
        return true;
    } catch (error) {
        return false;
    }
}

export function trimPhone(phone) {
    return phone.replace(/[^\d]/g, '');
}
