import * as types from './types';
import {
    getAnalyzeCheckoutApi,
    getCheckoutsApi,
    deleteCheckoutApi,
    printCheckoutApi,
    printLongCheckoutApi
} from 'services/checkout';
import { enqueueSnackbar } from 'notifier/actions';

export function fetchCheckouts({ query, errorCallback, successCallback }) {
    return async function (dispatch) {
        dispatch(_fetchCheckoutsAction());
        try {
            const [checkouts, statistic] = await Promise.all([getCheckoutsApi(query), getAnalyzeCheckoutApi(query)]);
            dispatch(_fetchCheckoutsActionSuccess({ checkouts, statistic }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `${message}`,
                    type: 'info'
                })
            );
            dispatch(_fetchCheckoutsActionFailed(message));
            errorCallback && errorCallback(message);
        }
    };
}

function _fetchCheckoutsAction() {
    return {
        type: types.FETCH_CHECKOUTS
    };
}

function _fetchCheckoutsActionFailed(error) {
    return {
        type: types.FETCH_CHECKOUTS_FAILED,
        payload: {
            error
        }
    };
}

function _fetchCheckoutsActionSuccess({ checkouts, statistic }) {
    return {
        type: types.FETCH_CHECKOUTS_SUCCESS,
        payload: {
            checkouts,
            statistic
        }
    };
}

export function voidCheckout({ checkoutId, errorCallback, successCallback }) {
    return async function (dispatch) {
        dispatch(_fetchCheckoutsAction());
        try {
            await deleteCheckoutApi(checkoutId);
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `${message}`,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function printCheckout({ checkoutId, errorCallback, successCallback }) {
    return async function (dispatch) {
        try {
            await printCheckoutApi(checkoutId);
            successCallback && successCallback();
            dispatch(
                enqueueSnackbar({
                    message: `Add checkout to print queue success`,
                    type: 'info'
                })
            );
        } catch (error) {
            const { message } = error;
            errorCallback && errorCallback(message);
        }
    };
}

export function printLongBill({ query, errorCallback, successCallback }) {
    return async function (dispatch) {
        try {
            await printLongCheckoutApi(query);
            successCallback && successCallback();
            dispatch(
                enqueueSnackbar({
                    message: `Add long checkout to print queue success`,
                    type: 'info'
                })
            );
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}
