import * as types from './types';

const INITIAL_STATE = {
    checkouts: [],
    statistic: null,
    isFetchingCheckout: false
};

export default function checkoutPayments(state = INITIAL_STATE, { type, payload }) {
    switch (type) {
        case types.FETCH_CHECKOUTS: {
            return {
                ...state,
                isFetchingCheckout: true
            };
        }

        case types.FETCH_CHECKOUTS_FAILED: {
            const { error } = payload;
            return {
                ...state,
                isFetchingCheckout: false,
                error
            };
        }

        case types.FETCH_CHECKOUTS_SUCCESS: {
            const { checkouts, statistic } = payload;
            return {
                ...state,
                isFetchingBooking: false,
                checkouts: checkouts || [],
                statistic
            };
        }

        default:
            return state;
    }
}
