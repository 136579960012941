import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, InputBase, IconButton, Chip, Dialog } from '@material-ui/core';
import LayoutTabContent from 'views/layout/LayoutTabContent';
import AddClient from './addClient';
import { useClientList, useIsFetchingClient, useTotalClients } from 'hooks/client';
import { useDispatch } from 'react-redux';
import ConfirmDialog from 'components/dialog/Confirm';
import * as clientActions from './actions';
import * as servicesActions from 'views/services/action/service';
import * as staffActions from 'views/staff/action';
import { useTranslation } from 'react-i18next';
import Avatar from 'components/avatar';
import IconCameraSVG from 'assets/images/svg/IconCameraSVG';
import { useDropzone } from 'react-dropzone';
import { AVATAR_EXTENSIONS, CONFIRM_TYPES, MERCHANT_PERMISSIONS } from 'const';
import Spinner from 'components/spinner';
import ClientProfile from './clientProfile';
import ContentLayout from '../layout/ContentLayout';
import Table from 'components/table/NewTable';
import { PADDING_LIST_ITEM_LEFT_RIGHT } from 'const/style';
import { getFullName } from 'utils/naming';
import PhoneFormat from 'components/phoneFormat';
import { OPERATOR } from 'const/condition';
import { columnTypes } from 'const/columnTypes';
import { getImageUrl } from 'utils/image';
import { trimPhone } from 'utils/phone';
import Tooltip from 'components/tooltip';
import Button from 'components/button/Base';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/AddCircle';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import EditIcon from '@material-ui/icons/Edit';
import ProfileIcon from '@material-ui/icons/ListAlt';
import CloseIcon from '@material-ui/icons/Close';
import { useIsHavingPermission, useUserMerchantId } from 'hooks/auth';
import { exportClientsApi } from 'services/client';

const useStyles = makeStyles((theme) => ({
    root: {
        overflowY: 'hidden',
        height: '100%',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            maxHeight: `-webkit-fill-available`,
            marginTop: 0
        }
    },
    wrapContent: {
        height: '100%',
        overflowY: 'hidden',
        backgroundColor: theme.palette.common.white,
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            marginBottom: 0
        }
    },

    header: {
        padding: theme.spacing(2)
    },

    wrapInput: {
        position: 'relative'
    },

    customInput: {
        'label + &': {
            color: theme.colors.secondaryText
        },
        backgroundColor: theme.palette.common.white,
        paddingLeft: PADDING_LIST_ITEM_LEFT_RIGHT,
        paddingRight: 22,
        borderRadius: 3,
        height: 35,
        fontSize: 14,
        fontWeight: 'normal',
        color: theme.colors.primaryText,
        width: '100%'
    },
    clearIcon: {
        position: 'absolute',
        cursor: 'pointer',
        right: 5,
        top: 7,
        zIndex: 9000
    },
    phones: {
        maxHeight: 45,
        overflowY: 'auto'
    },
    avatar: {
        '& .alreadyHaveImage': {
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            visibility: 'hidden',
            left: 0,
            top: 0,
            '& svg': {
                width: 30,
                height: 30,
                '& path': {
                    fill: theme.palette.common.white
                }
            },
            '&  div': {
                outline: 'none'
            }
        },
        '&:hover': {
            '& .alreadyHaveImage': {
                visibility: 'visible'
            }
        }
    },
    upload: {
        width: '100%',
        height: '100%',
        borderRadius: '50%',

        background: theme.palette.common.white,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            opacity: 0.8
        },
        '& svg': {
            width: 30,
            height: 30
        },
        '&  div': {
            outline: 'none'
        }
    },
    imageUploading: {
        opacity: 0.5,
        background: theme.colors.gunPower
    },
    noThumbnail: {
        background: `${theme.palette.common.white} !important`,
        border: `1px dashed ${theme.colors.border}`,
        '&:hover': {
            background: `${theme.palette.primary.light} !important`
        }
    },
    status: {
        background: theme.colors.turquoise,
        width: 'fit-content'
    },
    blocked: {
        background: theme.colors.red
    },
    flexRow: {
        display: 'flex',
        alignItems: 'center'
    },
    isNew: {
        background: theme.colors.sun,
        color: theme.palette.common.white,
        border: 'none',
        marginLeft: theme.spacing(2)
    }
}));

function AvatarClient({ rowData }) {
    const classes = useStyles();
    const avatar = rowData?.avatar;
    // const bookingsCount = rowData?.bookingsCount;
    const dispatch = useDispatch();

    const [thumbnail, setThumbnail] = React.useState(avatar);
    const [isLoading, setIsLoading] = React.useState(false);

    // const isNew = React.useMemo(() => {
    //     return bookingsCount === 0;
    // }, [bookingsCount]);

    React.useEffect(() => {
        if (avatar) {
            setThumbnail(getImageUrl(avatar));
        } else {
            setThumbnail();
        }
    }, [avatar]);

    const stopPropagation = (e) => {
        e.stopPropagation();
        e.preventDefault();
    };

    const onRejectedHandler = React.useCallback(
        (rejectedFiles) => {
            dispatch(clientActions.uploadAvatarFailed());
        },
        [dispatch]
    );

    const onDropAcceptedHandler = React.useCallback(
        (acceptedFiles) => {
            setIsLoading(true);
            const file = acceptedFiles?.[0];
            if (file) {
                setThumbnail(URL.createObjectURL(file));
            }
            dispatch(
                clientActions.uploadAvatar({
                    clientId: rowData?.id,
                    file,
                    successCallback: () => {
                        setIsLoading(false);
                    },
                    errorCallback: () => {
                        setIsLoading(false);
                        setThumbnail(avatar);
                    }
                })
            );
        },
        [dispatch, rowData, avatar]
    );

    const { getRootProps, getInputProps } = useDropzone({
        onDropAccepted: onDropAcceptedHandler,
        onDropRejected: onRejectedHandler,
        accept: AVATAR_EXTENSIONS
    });

    React.useEffect(() => {
        return () => {
            if (thumbnail) {
                URL.revokeObjectURL(thumbnail);
            }
        };
    }, [thumbnail]);

    return (
        <div className={classes.flexRow}>
            <div className={classes.avatar} style={{ position: 'relative', width: 48, height: 48 }}>
                <Avatar
                    className={`${classes.upload} ${isLoading ? classes.imageUploading : ''} ${
                        !thumbnail ? classes.noThumbnail : ''
                    }`}
                    src={thumbnail}
                    size="large"
                >
                    <div onClick={stopPropagation}>
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <IconCameraSVG />
                        </div>
                    </div>
                </Avatar>
                {thumbnail && (
                    <div onClick={stopPropagation} className={'alreadyHaveImage'}>
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <IconCameraSVG />
                        </div>
                    </div>
                )}
                {isLoading && (
                    <div
                        style={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            zIndex: 2,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            left: 3,
                            top: 3
                        }}
                    >
                        <Spinner size={40} thick={4} />
                    </div>
                )}
            </div>
            {/* {isNew && <Chip className={classes.isNew} label="New" size="small" variant="contained" />} */}
        </div>
    );

    // return <Avatar size="large" />;
}

const AvatarClientMemo = React.memo(AvatarClient);

const Actions = ({ value, rowData, t }) => {
    const dispatch = useDispatch();

    const [openDeleteConfirm, setOpenDeleteConfirm] = React.useState(false);
    // const [openBlockConfirm, setOpenBlockConfirm] = React.useState(false);
    const [isOpenEdit, setIsOpenEdit] = React.useState(false);
    const [isOpenHistory, setIsOpenHistory] = React.useState(false);

    const handleOpenEdit = (e) => {
        stopPropagation(e);
        setIsOpenEdit(true);
    };
    const handleCloseEdit = (e) => {
        stopPropagation(e);
        setIsOpenEdit(false);
    };

    const handleOpenHistory = (e) => {
        stopPropagation(e);
        setIsOpenHistory(true);
    };
    const handleCloseHistory = (e) => {
        stopPropagation(e);
        setIsOpenHistory(false);
    };

    const handleClick = (e) => {
        stopPropagation(e);
        setOpenDeleteConfirm(true);
    };

    const handleAgree = (e) => {
        stopPropagation(e);
        dispatch(clientActions.deleteClient({ clientId: rowData.id }));
        setOpenDeleteConfirm(false);
    };

    const handleDisagree = (e) => {
        stopPropagation(e);
        setOpenDeleteConfirm(false);
    };

    const stopPropagation = (e) => {
        e && e.stopPropagation();
        e && e.preventDefault();
    };

    const isSystem = rowData?.isSystem;

    return (
        <Grid container spacing={2} wrap="nowrap" alignItems="center" direction="row">
            <Tooltip title="Client history">
                <Grid item>
                    <IconButton size="small" onClick={handleOpenHistory}>
                        <ProfileIcon fontSize="small" />
                    </IconButton>
                </Grid>
            </Tooltip>

            {!isSystem && (
                <>
                    <Grid item>
                        <IconButton size="small">
                            <EditIcon onClick={handleOpenEdit} fontSize="small" />
                        </IconButton>
                    </Grid>

                    <Grid item>
                        <IconButton size="small">
                            <DeleteIcon onClick={handleClick} fontSize="small" />
                        </IconButton>
                    </Grid>
                </>
            )}
            <ConfirmDialog
                open={openDeleteConfirm}
                title={`Delete client`}
                text={`${t(`are_you_sure_to_delete`)} <strong>${getFullName(rowData)}</strong>?`}
                onAgree={handleAgree}
                onDisagree={handleDisagree}
                type={CONFIRM_TYPES.ERROR}
            />

            <Dialog fullScreen={true} padding={0} open={isOpenEdit} onClose={handleCloseEdit}>
                <AddClient isEdit={true} editedClient={rowData} handleClose={handleCloseEdit} />
            </Dialog>
            <Dialog fullScreen={true} padding={0} open={isOpenHistory} onClose={handleCloseHistory}>
                <ClientProfile selectedClient={rowData} onClose={handleCloseHistory} />
            </Dialog>
        </Grid>
    );
};

const ActionsMemo = React.memo(Actions);

const initialValueFilter = {
    firstName: {
        operator: OPERATOR.contains,
        value: '',
        type: columnTypes.TEXT
    },
    lastName: {
        operator: OPERATOR.contains,
        value: '',
        type: columnTypes.TEXT
    },
    phone: {
        operator: OPERATOR.contains,
        value: '',
        type: columnTypes.TEXT
    },
    email: {
        operator: OPERATOR.contains,
        value: '',
        type: columnTypes.TEXT
    },
    noShowCount: {
        operator: OPERATOR.equal,
        value: '',
        type: columnTypes.NUMBER
    }
};

function ClientList() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [filter, setFilter] = React.useState(initialValueFilter);
    const isHasExportClients = useIsHavingPermission(MERCHANT_PERMISSIONS.EXPORT_CLIENTS);
    const [totalPages, setTotalPages] = React.useState(0);
    const [limit, setLimit] = React.useState(15);
    const [page, setPage] = React.useState(1);
    const merchantId = useUserMerchantId();

    const isFetching = useIsFetchingClient();
    const clients = useClientList();

    const total = useTotalClients();
    const [isOpenAddClient, setIsOpenAddClient] = React.useState(false);
    const timerRef = React.useRef();

    const handleCloseAddClient = (e) => {
        stopPropagation(e);
        setIsOpenAddClient(false);
    };
    const handleOpenAddClient = (e) => {
        stopPropagation(e);
        setIsOpenAddClient(true);
    };

    const stopPropagation = (e) => {
        e && e.preventDefault();
        e && e.stopPropagation();
    };

    const columns = [
        {
            id: 'avatar',
            label: 'Avatar',
            width: 80,
            format: (props) => <AvatarClientMemo t={t} {...props} />
        },
        {
            id: 'firstName',
            label: 'First name',
            width: 150,
            searchable: true
        },
        {
            id: 'lastName',
            label: 'Last Name',
            width: 150,
            searchable: true
        },
        {
            id: 'email',
            label: 'Email',
            width: 150,
            searchable: false
        },
        {
            id: 'block',
            label: 'Status',
            width: 80,
            searchable: false,
            format: (props) => (
                <Chip
                    className={`${classes.status} ${props?.rowData?.block ? classes.blocked : ``}`}
                    label={props?.rowData?.block ? 'Blocked' : 'Active'}
                />
            )
        },
        {
            id: 'phone',
            label: 'Phone',
            width: 150,
            searchable: true,
            format: (props) => <PhoneFormat number={props?.rowData?.user?.phone} />
        },
        {
            id: 'noShowCount',
            label: 'No Show Count',
            width: 100,
            searchable: true,
            format: (props) => (
                <Typography variant="body2">{props?.rowData?.noShowCount ? props?.rowData?.noShowCount : 0}</Typography>
            )
        },
        {
            id: 'actions',
            label: 'Action',
            width: 80,
            align: 'right',
            format: (props) => <ActionsMemo t={t} {...props} />
        }
    ];

    const headerRenderer = ({ column }) => {
        return <Typography variant="body1">{column.label}</Typography>;
    };

    const handleFilterChange = React.useCallback(
        (id, value) => {
            setFilter({
                ...filter,
                [id]: {
                    ...filter?.[id],
                    value
                }
            });
        },
        [filter]
    );

    const handleClearInput = React.useCallback(
        (id) => {
            const newFilter = {
                ...filter,
                [id]: {
                    ...filter?.[id],
                    value: ''
                }
            };
            setFilter(newFilter);
        },
        [filter]
    );

    const filterHeaderRenderer = React.useCallback(
        ({ column }) => {
            const filterValue = filter?.[column?.id]?.value;
            return (
                <div className={classes.wrapInput}>
                    <InputBase
                        className={classes.customInput}
                        placeholder={'Type to filter'}
                        value={filterValue}
                        onChange={(e) => {
                            if (column?.id === 'noShowCount') e.target.value = e.target.value.replace(/\D/, '');
                            handleFilterChange(column?.id, e.target.value);
                        }}
                    />
                    {filterValue && (
                        <div className={classes.clearIcon} onClick={() => handleClearInput(column?.id)}>
                            <CloseIcon size="small" />
                        </div>
                    )}
                </div>
            );
        },
        [classes, filter, handleClearInput, handleFilterChange]
    );

    const onRowClicked = ({ rowData }) => {
        // handleOpenClientProfile();
        // setSelectedClient(rowData);
    };

    const onChangePageOptions = (e) => {
        setPage(1);
        setLimit(e.target.value);
    };

    const onChangePage = (event, page) => {
        setPage(page);
    };

    const handleExportClients = () => {
        exportClientsApi(merchantId);
    };

    React.useEffect(() => {
        if (timerRef.current) clearTimeout(timerRef.current);
        timerRef.current = setTimeout(async () => {
            dispatch(
                clientActions.fetchClients({
                    searchCondition: {
                        ...filter,
                        phone: {
                            ...filter?.phone,
                            value: trimPhone(filter?.phone?.value)
                        }
                    },
                    page,
                    limit,
                    errorCallback: () => {
                        console.log('Search clients errors');
                    },
                    successCallback: (totalPages) => {
                        setTotalPages(totalPages);
                    }
                })
            );
        }, 500);
    }, [dispatch, filter, limit, page]);

    React.useEffect(() => {
        dispatch(
            servicesActions.getServices({
                errorCallback: () => {
                    console.log('fetch services failed');
                },
                successCallback: () => {
                    console.log('fetch services success');
                }
            })
        );
    }, [dispatch]);

    React.useEffect(() => {
        dispatch(
            staffActions.getStaff({
                errorCallback: () => {
                    console.log('fetch staff failed');
                },
                successCallback: () => {
                    console.log('fetch staff success');
                }
            })
        );
    }, [dispatch]);

    return (
        <ContentLayout
            title={`Clients: ${total}`}
            action={
                <>
                    {isHasExportClients && (
                        <Button
                            startIcon={<DownloadIcon />}
                            variant="contained"
                            color="primary"
                            onClick={handleExportClients}
                            style={{ marginRight: 8 }}
                        >
                            Export clients
                        </Button>
                    )}
                    <Button startIcon={<AddIcon />} variant="contained" color="primary" onClick={handleOpenAddClient}>
                        New client
                    </Button>
                </>
            }
        >
            <LayoutTabContent isLoading={isFetching && clients === undefined}>
                <Grid container className={classes.root} direction="column" wrap="nowrap">
                    <Grid item className={classes.wrapContent}>
                        <Table
                            headerRenderer={headerRenderer}
                            filterHeaderRenderer={filterHeaderRenderer}
                            rows={clients || []}
                            columns={columns}
                            onRowClicked={onRowClicked}
                            page={page}
                            limit={limit}
                            totalPages={totalPages}
                            rowPerPageOptions={[15, 30, 45]}
                            onChangePageOptions={onChangePageOptions}
                            onChangePage={onChangePage}
                            classNameBody={classes.tableBody}
                        />
                    </Grid>

                    <Dialog fullScreen={true} padding={0} open={isOpenAddClient} onClose={handleCloseAddClient}>
                        <AddClient handleClose={handleCloseAddClient} />
                    </Dialog>
                </Grid>
            </LayoutTabContent>
        </ContentLayout>
    );
}

export default React.memo(ClientList);
