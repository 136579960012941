import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useCalendarOptions, useStaffFilteredIds } from 'hooks/calendar';
import Cell from 'views/calender/calendar/components/cells';
import Header from 'views/calender/calendar/components/Header';
import Overlay from 'views/calender/calendar/Overlay';
import BookingWrapper from 'views/calender/calendar/components/bookings';
import { INDEX_COLUMN_WIDTH, ROW_HEIGHT } from 'const';
import { useMerchantTime12 } from 'hooks/merchant';
import { mapTimeToTime12 } from 'utils/timing';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    tableScroll: {
        position: 'relative',
        width: '100%',
        zIndex: 1,
        margin: 'auto',
        overflow: 'auto'
    },
    mainTable: {
        width: '100%',
        // minWidth: 1280,
        margin: 'auto',
        borderCollapse: 'separate',
        borderSpacing: 0,
        '& th:first-child': {
            background: theme.colors.background,
            position: 'sticky',
            left: 0,
            zIndex: 10
        },
        tableLayout: 'fixed'
    },
    cell: {
        padding: 0,
        height: ROW_HEIGHT
    },
    header: {
        color: theme.colors.primaryText,
        position: 'sticky',
        zIndex: 10,
        top: 0,
        '&:first-child': {
            zIndex: `15 !important`,
            background: `${theme.colors.background} !important`
        }
    },
    rowHeader: {
        background: theme.colors.background
    }
}));

function Table({
    columnWidth = 160,
    INDEX_COLUMN_WIDTH = 80,
    HEADER_HEIGHT,
    openConfirmMovingModel,
    onRefreshStaffBlockedTimes,
    onRefreshWorkingHours,
    onReBooking,
    onReSchedule,
    onOpenBooking,
    onCloseBooking
}) {
    const theme = useTheme();

    const classes = useStyles();

    return (
        <table className={classes.mainTable} id="fuck-table">
            <thead>
                <tr className={classes.rowHeader}>
                    <th
                        className={`${classes.cell} ${classes.header}`}
                        style={{
                            width: INDEX_COLUMN_WIDTH,
                            height: HEADER_HEIGHT || ROW_HEIGHT
                        }}
                    >
                        <div
                            style={{
                                width: INDEX_COLUMN_WIDTH,
                                background: theme.colors.background,
                                height: HEADER_HEIGHT || ROW_HEIGHT
                            }}
                        ></div>
                    </th>

                    <StaffHeaders
                        columnWidth={columnWidth}
                        HEADER_HEIGHT={HEADER_HEIGHT}
                        onRefreshStaffBlockedTimes={onRefreshStaffBlockedTimes}
                        onRefreshWorkingHours={onRefreshWorkingHours}
                    />
                </tr>
            </thead>
            <tbody id="main-table-scroll" style={{ overflowY: 'auto' }}>
                <th
                    style={{
                        width: INDEX_COLUMN_WIDTH
                    }}
                    colSpan={1}
                >
                    <IndexColumnCell />
                </th>

                <StaffBookings
                    columnWidth={columnWidth}
                    onReBooking={onReBooking}
                    onReSchedule={onReSchedule}
                    onOpenBooking={onOpenBooking}
                    onCloseBooking={onCloseBooking}
                    onRefreshStaffBlockedTimes={onRefreshStaffBlockedTimes}
                    openConfirmMovingModel={openConfirmMovingModel}
                />

                <Overlay />
            </tbody>
        </table>
    );
}

function StaffHeaders({ columnWidth, HEADER_HEIGHT, onRefreshStaffBlockedTimes, onRefreshWorkingHours }) {
    const classes = useStyles();
    const staffIds = useStaffFilteredIds();

    return staffIds?.map((staffId, columnIndex) => {
        return (
            <th
                className={`${classes.cell} ${classes.header}`}
                scope="col"
                key={columnIndex}
                style={{
                    width: columnWidth,
                    minWidth: columnWidth,
                    height: HEADER_HEIGHT || ROW_HEIGHT,
                    flex: 1
                }}
            >
                <Header
                    HEADER_HEIGHT={HEADER_HEIGHT}
                    onRefreshStaffBlockedTimes={onRefreshStaffBlockedTimes}
                    staffId={staffId}
                    onRefreshWorkingHours={onRefreshWorkingHours}
                />
            </th>
        );
    });
}

function StaffBookings({
    columnWidth,
    onReBooking,
    onReSchedule,
    onOpenBooking,
    onCloseBooking,
    onRefreshStaffBlockedTimes,
    openConfirmMovingModel
}) {
    const classes = useStyles();
    const staffIds = useStaffFilteredIds();
    const calenderOptions = useCalendarOptions();

    return staffIds?.map((staffId) => {
        return (
            <th
                scope="col"
                key={staffId}
                style={{
                    width: columnWidth,
                    minWidth: columnWidth,
                    position: 'relative',
                    flex: 1
                }}
            >
                {calenderOptions?.map((time) => {
                    return (
                        <div style={{ width: '100%' }} className={classes.cell} key={time}>
                            <Cell openConfirmMovingModel={openConfirmMovingModel} staffId={staffId} time={time} />
                        </div>
                    );
                })}
                <BookingWrapper
                    onRefreshStaffBlockedTimes={onRefreshStaffBlockedTimes}
                    onReBooking={onReBooking}
                    onReSchedule={onReSchedule}
                    onOpenBooking={onOpenBooking}
                    onCloseBooking={onCloseBooking}
                    staffId={staffId}
                />
            </th>
        );
    });
}

function IndexColumnCell() {
    const classes = useStyles();
    const calenderOptions = useCalendarOptions();
    const TIME_12 = useMerchantTime12();

    return calenderOptions?.map((time) => {
        const timeLabel = TIME_12 ? mapTimeToTime12(time) : time;
        const timeArr = timeLabel?.split(` `);

        return (
            <div
                style={{
                    width: INDEX_COLUMN_WIDTH,
                    position: 'relative',
                    height: ROW_HEIGHT,
                    maxWidth: INDEX_COLUMN_WIDTH
                }}
                className={classes.indexCell}
                id={time === '12:30' ? `scrollHere` : ``}
            >
                <Typography variant="body2">{timeArr?.[0]}</Typography>
                {timeArr?.[1] && (
                    <span
                        style={{
                            position: 'absolute',
                            bottom: 5,
                            fontFamily: `"Public Sans", sans-serif`,
                            right: 2,
                            fontSize: 11,
                            fontWeight: 300,
                            textTransform: 'uppercase'
                        }}
                    >
                        {timeArr?.[1]}
                    </span>
                )}
            </div>
        );
    });
}

export default React.memo(Table);
