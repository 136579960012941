import request from './request';
// import { objectToQueryParams } from 'utils/queryParams';

const categoryApis = {
    categories: '/categories',
    category: (id) => `/categories/${id}`,
    reorder: `/categories/reorder`,
    publicCategories: (slug) => `categories/public/${slug}`
};

export async function createCategoryApi({ body }) {
    const response = await request.post(categoryApis.categories, body);
    return response.data;
}

export async function getCategoriesApi(relations = []) {
    let url = `${categoryApis.categories}`;

    if (relations?.length) {
        url += `?relations=${relations}`;
    }

    const response = await request.get(url);
    return response.data;
}

export async function updateCategoryApi({ id, body }) {
    const response = await request.put(categoryApis.category(id), body);
    return response.data;
}

export async function deleteCategoryApi({ id }) {
    const response = await request.delete(categoryApis.category(id));
    return response.data;
}

export async function reOrderCategoryApi(body) {
    const response = await request.post(`${categoryApis.reorder}`, body);
    return response.data;
}

export async function getPublicCategoriesApi(slug) {
    const response = await request.get(`${categoryApis.publicCategories(slug)}`);
    return response.data;
}
