import { generateOptionsMinutes, getTotalMinutesByTime, getNumRangeTimeEnd, getNumRangeTimeStart } from 'utils/timing';
import uniq from 'lodash/uniq';
import { BOOKING_SERVICE_STATE } from 'const';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';

export function formatStaffsBookings(staffsBookingResponse = []) {
    const bookings = {};
    staffsBookingResponse.forEach((booking) => {
        const { services = [], id, startDate, title } = booking;
        services.forEach((service) => {
            const { staffId } = service;
            if (!bookings?.[staffId]) {
                bookings[staffId] = [];
            }
            bookings[staffId] = [...bookings[staffId], { bookingId: id, startDate, title, ...service, booking }];
        });
    });
    return bookings;
}

export function formatStaffWorkingHours(workingHours = []) {
    const staffWorkingHours = {};
    const originalStaffSchedule = {};

    workingHours.forEach((schedule) => {
        const staffId = schedule?.staffId;
        const scheduleRangeFirst = schedule?.scheduleRangeFirst;
        const scheduleRangeSecond = schedule?.scheduleRangeSecond;
        const shifts = [scheduleRangeFirst, scheduleRangeSecond]?.filter(Boolean);
        const scheduleShifts = shifts?.map((shift) => ({
            timeEnd: getNumRangeTimeEnd(shift),
            timeStart: getNumRangeTimeStart(shift)
        }));

        staffWorkingHours[staffId] = scheduleShifts;
        originalStaffSchedule[staffId] = schedule;
    });

    return {
        staffWorkingHours,
        originalStaffSchedule
    };
}

export function generateWorkingSlots({ scheduleShifts = [], minutes = 30 }) {
    let timeSlots = [];
    scheduleShifts.forEach((range) => {
        const timeStart = range?.timeStart;
        const timeEnd = range?.timeEnd;
        const min = getTotalMinutesByTime(timeStart);
        const max = getTotalMinutesByTime(timeEnd);
        const options = generateOptionsMinutes(minutes, min, max, true);
        timeSlots = uniq([...timeSlots, ...options]);
    });

    return timeSlots;
}

export function splitBookingIntoSingleService(booking) {
    const bookingServicesByStaffId = {};

    const bookingServices = booking?.bookingServices || [];
    const startDate = booking?.startDate;
    const title = booking?.title;

    for (let bookingService of bookingServices) {
        const staffId = bookingService.staffId;
        if (!bookingServicesByStaffId?.[staffId]) {
            bookingServicesByStaffId[staffId] = [];
        }
        bookingServicesByStaffId[staffId] = [
            ...bookingServicesByStaffId?.[staffId],
            { bookingId: booking?.id, startDate, title, ...bookingService, booking, client: booking?.client }
        ];
    }

    return bookingServicesByStaffId;
}

export function getBookingServiceStatus(status) {
    console.log('status', status);
    return BOOKING_SERVICE_STATE?.[status] || BOOKING_SERVICE_STATE[0];
}

export function convertServiceGroupOptions(options = [], isShowPrice = false) {
    let groupOpts = [];
    let grouped = groupBy(options, 'categoryIndex');

    const groupNames = Object.keys(grouped)
        ?.map((i) => +i)
        ?.sort((a, b) => b - a);

    for (const groupIndex of groupNames) {
        const childOptions = grouped?.[groupIndex] || [];
        if (childOptions?.length) {
            const serviceOptions = grouped?.[groupIndex];
            const firstServiceOption = serviceOptions?.[0];
            const isCategoryDeleted = firstServiceOption?.category?.deleted;

            if (!isCategoryDeleted) {
                let group = {
                    label: firstServiceOption?.categoryName,
                    color: firstServiceOption?.category?.color,
                    groupName: firstServiceOption?.categoryName,
                    options:
                        orderBy(serviceOptions, ['orderIndex'], ['desc'])?.map((sv) => ({
                            ...sv,
                            label: `${isShowPrice ? sv?.label : sv?.title}`,
                            value: sv?.id
                        })) || []
                };
                groupOpts.push(group);
            }
        }
    }

    return groupOpts;
}
