/**
 * SMS SCHEDULES
 */

export const FETCH_SMS_SCHEDULES = '[SMS_SCHEDULES]FETCH_SMS_SCHEDULES';
export const FETCH_SMS_SCHEDULES_SUCCESS = '[SMS_SCHEDULES]FETCH_SMS_SCHEDULES_SUCCESS';
export const FETCH_SMS_SCHEDULES_FAILED = '[SMS_SCHEDULES]FETCH_SMS_SCHEDULES_FAILED';

export const FETCH_SMS_CLIENTS = '[CLIENTS]FETCH_SMS_CLIENTS';
export const FETCH_SMS_CLIENTS_SUCCESS = '[CLIENTS]FETCH_SMS_CLIENTS_SUCCESS';
export const FETCH_SMS_CLIENTS_FAILED = '[CLIENTS]FETCH_SMS_CLIENTS_FAILED';

export const CREATE_SMS_SCHEDULE = '[SMS_SCHEDULES]CREATE_SMS_SCHEDULE';
export const CREATE_SMS_SCHEDULE_SUCCESS = '[SMS_SCHEDULES]CREATE_SMS_SCHEDULE_SUCCESS';
export const CREATE_SMS_SCHEDULE_FAILED = '[SMS_SCHEDULES]CREATE_SMS_SCHEDULE_FAILED';

export const UPDATE_SMS_SCHEDULE = '[SMS_SCHEDULES]UPDATE_SMS_SCHEDULE';
export const UPDATE_SMS_SCHEDULE_SUCCESS = '[SMS_SCHEDULES]UPDATE_SMS_SCHEDULE_SUCCESS';
export const UPDATE_SMS_SCHEDULE_FAILED = '[SMS_SCHEDULES]UPDATE_SMS_SCHEDULE_FAILED';

export const DELETE_SMS_SCHEDULE = '[SMS_SCHEDULES]DELETE_SMS_SCHEDULE';
export const DELETE_SMS_SCHEDULE_SUCCESS = '[SMS_SCHEDULES]DELETE_SMS_SCHEDULE_SUCCESS';
export const DELETE_SMS_SCHEDULE_FAILED = '[SMS_SCHEDULES]DELETE_SMS_SCHEDULE_FAILED';

export const FETCH_SMS_CLIENTS_BY_RANGES = `[SMS_SCHEDULES]FETCH_SMS_CLIENTS_BY_RANGES`;
export const FETCH_SMS_CLIENTS_BY_RANGES_SUCCESS = `[SMS_SCHEDULES]FETCH_SMS_CLIENTS_BY_RANGES_SUCCESS`;
export const FETCH_SMS_CLIENTS_BY_RANGES_FAILED = `[SMS_SCHEDULES]FETCH_SMS_CLIENTS_BY_RANGES_FAILED`;
