import React from 'react';
import { Fab, Grid, useTheme } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { useDoneBookingsWithoutPaid, useGiftCodes, useIsOpenCheckout, useIsSameToday } from 'hooks/calendar';
import { useIsHavingPermission } from 'hooks/auth';
import CheckOutlined from '@material-ui/icons/CheckOutlined';
import * as calendarActions from 'views/calender/actions';
import { useDispatch } from 'react-redux';
import { MERCHANT_PERMISSIONS } from 'const';

function Name() {
    const dispatch = useDispatch();
    const { clientsDone } = useDoneBookingsWithoutPaid();
    const giftCodes = useGiftCodes();
    const isOpenCheckout = useIsOpenCheckout();
    const isAdvancedInternal = useIsHavingPermission(MERCHANT_PERMISSIONS.INTERNAL);
    const isHavingCheckout = useIsHavingPermission(MERCHANT_PERMISSIONS.CHECK_OUT);
    const theme = useTheme();

    const isToday = useIsSameToday();

    const checkoutLength = React.useMemo(() => {
        const bookingCheckoutLength = isEmpty(clientsDone) ? 0 : Object.keys(clientsDone)?.length;
        return bookingCheckoutLength + giftCodes?.length;
    }, [clientsDone, giftCodes]);

    const handleOpenCheckOut = React.useCallback(() => {
        dispatch(calendarActions.setIsOpenCheckout(true));
    }, [dispatch]);

    if (!(!isOpenCheckout && isAdvancedInternal && isHavingCheckout && isToday)) return null;

    return (
        <Grid item>
            <Fab
                variant="extended"
                size="small"
                color={checkoutLength > 0 ? 'secondary' : ``}
                aria-label="add"
                onClick={handleOpenCheckOut}
            >
                <CheckOutlined />
                Checkout ({<strong style={{ color: theme.palette.primary.main }}>{checkoutLength}</strong>})
            </Fab>
        </Grid>
    );
}

export default Name;
