import * as types from './types';

const INITIAL_STATE = {
    list: null,
    total: 0,
    isFetchingBooking: false
};

export default function smsSchedules(state = INITIAL_STATE, { type, payload }) {
    switch (type) {
        case types.FETCH_BOOKINGS: {
            return {
                ...state,
                isFetchingBooking: true
            };
        }

        case types.FETCH_BOOKINGS_FAILED: {
            const { error } = payload;
            return {
                ...state,
                isFetchingBooking: false,
                error
            };
        }

        case types.FETCH_BOOKINGS_SUCCESS: {
            const { bookings, total } = payload;
            return {
                ...state,
                isFetchingBooking: false,
                list: bookings || [],
                total
            };
        }

        default:
            return state;
    }
}
