import { COLORS } from 'utils/color';
import { BOOKING_SERVICE_STATES, BOOKING_STATES, THEMES } from './index';

export function getThemeObject(theme) {
    switch (theme) {
        case THEMES.MANGO_MINT: {
            return {
                categories: [
                    `#5A567B`,
                    `#A6C9DA`,
                    `#F9E1CA`,
                    `#C7C0D0`,
                    `#9AD7DB`,
                    `#DAEFF0`,
                    `#F9C5DC`,
                    `#D7C6F4`,
                    `#B7AEA9`,
                    `#C2B3B8`
                ],
                bkSv: {
                    [BOOKING_SERVICE_STATES.ARRIVED]: `#9bd8dc`,
                    [BOOKING_SERVICE_STATES.CANCELED]: `#fff3eb`,
                    [BOOKING_SERVICE_STATES.COMPLETED]: `#d4d4d4`,
                    [BOOKING_SERVICE_STATES.CONFIRMED]: `#9bd8dc`,
                    [BOOKING_SERVICE_STATES.DECLINE]: `#d5d5d5`,
                    [BOOKING_SERVICE_STATES.DONE]: `#d4d4d4`,
                    [BOOKING_SERVICE_STATES.NO_SHOW]: `#d5d5d5`,
                    [BOOKING_SERVICE_STATES.PICKED]: `#d7c6f4`,
                    [BOOKING_SERVICE_STATES.SERVING]: `#fac5dc`,
                    [BOOKING_SERVICE_STATES.WAITING]: `#d7c6f4`,
                    [BOOKING_SERVICE_STATES.WAIT_FOR_CHECK_IN]: `#fae2ca`
                },
                booking: {
                    [BOOKING_STATES.CLIENT_ARRIVED]: `#9bd8dc`,
                    [BOOKING_STATES.CLIENT_CANCEL]: `#fff3eb`,
                    [BOOKING_STATES.CLIENT_CONFIRMED]: `#9bd8dc`,
                    [BOOKING_STATES.DECLINE]: `#d5d5d5`,
                    [BOOKING_STATES.END]: `#d4d4d4`,
                    [BOOKING_STATES.NEW]: `#fae2ca`,
                    [BOOKING_STATES.NO_SHOW]: `#d5d5d5`,
                    [BOOKING_STATES.PENDING_PAYMENT]: `#fae2ca`,
                    [BOOKING_STATES.SEND_OTP]: `#fae2ca`,
                    [BOOKING_STATES.SERVING]: `#fac5dc`,
                    [BOOKING_STATES.WAIT]: `#d7c6f4`
                }
            };
        }

        case THEMES.BOOK_SALON: {
            return {
                categories: [
                    `#68B8FC`,
                    `#399CFF`,
                    `#2C5DB5`,
                    `#7474DC`,
                    `#EEBC5B`,
                    `#FFCA41`,
                    `#35D6AB`,
                    `#48C567`,
                    `#59897C`,
                    `#F27A7A`,
                    `#BB509A`,
                    `#FB7BB8`
                ],
                bkSv: {
                    [BOOKING_SERVICE_STATES.ARRIVED]: COLORS.green,
                    [BOOKING_SERVICE_STATES.CANCELED]: COLORS.gunPower,
                    [BOOKING_SERVICE_STATES.COMPLETED]: COLORS.periwinkle,
                    [BOOKING_SERVICE_STATES.CONFIRMED]: COLORS.darkBlue,
                    [BOOKING_SERVICE_STATES.DECLINE]: COLORS.disabled,
                    [BOOKING_SERVICE_STATES.DONE]: COLORS.periwinkle,
                    [BOOKING_SERVICE_STATES.NO_SHOW]: COLORS.gullGray,
                    [BOOKING_SERVICE_STATES.PICKED]: COLORS.purple,
                    [BOOKING_SERVICE_STATES.SERVING]: COLORS.pink,
                    [BOOKING_SERVICE_STATES.WAITING]: COLORS.template,
                    [BOOKING_SERVICE_STATES.WAIT_FOR_CHECK_IN]: COLORS.brightSun
                },
                booking: {
                    [BOOKING_STATES.CLIENT_ARRIVED]: COLORS.green,
                    [BOOKING_STATES.CLIENT_CANCEL]: COLORS.gunPower,
                    [BOOKING_STATES.CLIENT_CONFIRMED]: COLORS.darkBlue,
                    [BOOKING_STATES.DECLINE]: COLORS.disabled,
                    [BOOKING_STATES.END]: COLORS.periwinkle,
                    [BOOKING_STATES.NEW]: COLORS.template,
                    [BOOKING_STATES.NO_SHOW]: COLORS.gullGray,
                    [BOOKING_STATES.PENDING_PAYMENT]: COLORS.sun,
                    [BOOKING_STATES.SEND_OTP]: COLORS.brightSun,
                    [BOOKING_STATES.SERVING]: COLORS.pink,
                    [BOOKING_STATES.WAIT]: COLORS.template
                }
            };
        }
        default:
            return {};
    }
}
