import { API_URL } from 'config';

export function getImageUrl(key) {
    if (key?.includes(`${API_URL}/files`)) return key;
    return `${API_URL}/files/${key}`;
}

export function getPdfUrl(key) {
    if (key?.includes(`${API_URL}/pdf`)) return key;
    return `${API_URL}/pdf/${key}`;
}
