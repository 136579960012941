import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';

const StyledDialogTitle = withStyles((theme) => ({
    root: {
        background: theme.palette.primary.main
    }
}))(DialogTitle);

export default StyledDialogTitle;
