import React from 'react';

function ClientSVG({ color = '#41415C', ...rest }) {
    return (
        <svg {...rest} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.5">
                <path
                    d="M15.5708 16.1522C15.4421 16.0419 15.2728 15.9904 15.1036 16.0161C14.8204 16.0566 14.6033 16.3104 14.6033 16.6047V19.4298C14.6033 19.8013 14.905 19.9999 15.1882 19.9999C15.4715 19.9999 15.7731 19.8013 15.7731 19.4298V16.5937C15.7731 16.4245 15.6996 16.2626 15.5708 16.1522Z"
                    fill={color}
                />
                <path
                    d="M14.3054 6.04372L14.3422 5.33377C14.3789 4.59071 14.1178 3.88444 13.6065 3.34738C13.0952 2.81032 12.4036 2.51236 11.6605 2.51236H9.2732C8.53014 2.51236 7.83859 2.81032 7.32728 3.34738C6.62101 4.09044 6.58054 4.98799 6.61733 5.85244C5.56528 6.8677 4.96201 8.28025 4.96201 9.74429V19.4298C4.96201 19.8013 5.26364 19.9999 5.54689 19.9999C5.83013 19.9999 6.13177 19.8013 6.13177 19.4298V9.74429C6.13177 8.99755 6.33408 8.26185 6.70561 7.61812L6.83436 10.1747C6.84172 12.1684 8.47129 13.7906 10.465 13.7906C12.4588 13.7906 14.0883 12.1684 14.0957 10.1747L14.2097 7.95286C14.4709 8.50831 14.607 9.11894 14.607 9.74429V13.5184C14.607 13.89 14.9087 14.0886 15.1919 14.0886C15.4751 14.0886 15.7768 13.89 15.7768 13.5184V9.74429C15.7731 8.36117 15.2508 7.04795 14.3054 6.04372ZM13.1724 5.27491L12.9259 10.1305C12.9259 10.1416 12.9259 10.1489 12.9259 10.16C12.9259 11.5173 11.8224 12.6209 10.465 12.6209C9.10767 12.6209 8.00412 11.5173 8.00412 10.16C8.00412 10.1489 8.00412 10.1416 8.00412 10.1305L7.75766 5.27491C7.73559 4.84085 7.89376 4.42886 8.20276 4.11986C8.48232 3.8403 8.88328 3.67844 9.29895 3.67844H11.6274C12.0431 3.67844 12.4441 3.8403 12.7236 4.11986C13.0363 4.42886 13.1945 4.84085 13.1724 5.27491Z"
                    fill={color}
                />
                <path
                    d="M15.1882 15.6262H15.1919C15.4347 15.6262 15.6407 15.4864 15.729 15.2621C15.7915 15.1076 15.7842 14.9383 15.7106 14.7875C15.6407 14.6404 15.5156 14.5337 15.3611 14.4859C15.2066 14.4381 15.0374 14.4565 14.8976 14.5337C14.6438 14.6772 14.5335 14.9972 14.6438 15.2657C14.7395 15.4864 14.9491 15.6262 15.1882 15.6262Z"
                    fill={color}
                />
                <path
                    d="M9.39826 16.5201H11.4913C11.7672 16.5201 12.0615 16.3141 12.0615 15.9352C12.0615 15.5563 11.7672 15.3503 11.4913 15.3503H9.39826C9.12237 15.3503 8.82809 15.5563 8.82809 15.9352C8.82809 16.3141 9.12237 16.5201 9.39826 16.5201Z"
                    fill={color}
                />
                <path
                    d="M12.0799 16.8843H8.85017C8.47864 16.8843 8.28 17.186 8.28 17.4692C8.28 17.7524 8.47864 18.0541 8.85017 18.0541H12.0799C12.4514 18.0541 12.6501 17.7524 12.6501 17.4692C12.6501 17.186 12.4514 16.8843 12.0799 16.8843Z"
                    fill={color}
                />
                <path
                    d="M17.3218 3.75577C17.0459 3.75577 16.8252 3.98016 16.8252 4.25237V4.64965C16.8252 4.92554 17.0459 5.14625 17.3218 5.14625C17.5977 5.14625 17.8184 4.92554 17.8184 4.64965V4.25237C17.8184 3.98016 17.5977 3.75577 17.3218 3.75577Z"
                    fill={color}
                />
                <path
                    d="M16.2624 5.21246H15.8651C15.5892 5.21246 15.3685 5.43317 15.3685 5.70906C15.3685 5.98495 15.5929 6.20566 15.8651 6.20566H16.2624C16.5383 6.20566 16.759 5.98127 16.759 5.70906C16.759 5.43685 16.5346 5.21246 16.2624 5.21246Z"
                    fill={color}
                />
                <path
                    d="M18.7785 5.21246H18.3812C18.1053 5.21246 17.8846 5.43317 17.8846 5.70906C17.8846 5.98495 18.109 6.20566 18.3812 6.20566H18.7785C19.0543 6.20566 19.2751 5.98127 19.2751 5.70906C19.2751 5.43685 19.0543 5.21246 18.7785 5.21246Z"
                    fill={color}
                />
                <path
                    d="M17.3218 6.27554C17.0459 6.27554 16.8252 6.49625 16.8252 6.77214V7.16942C16.8252 7.44531 17.0459 7.66602 17.3218 7.66602C17.5977 7.66602 17.8184 7.44531 17.8184 7.16942V6.77214C17.8184 6.49625 17.5977 6.27554 17.3218 6.27554Z"
                    fill={color}
                />
                <path
                    d="M5.45124 0C5.17536 0 4.95465 0.224388 4.95465 0.496597V0.893875C4.95465 1.16976 5.17536 1.39047 5.45124 1.39047C5.72713 1.39047 5.94784 1.16976 5.94784 0.893875V0.496597C5.94784 0.224388 5.72345 0 5.45124 0Z"
                    fill={color}
                />
                <path
                    d="M4.39186 1.4567H3.99458C3.7187 1.4567 3.49799 1.67741 3.49799 1.95329C3.49799 2.22918 3.72237 2.44989 3.99458 2.44989H4.39186C4.66775 2.44989 4.88846 2.2255 4.88846 1.95329C4.88846 1.68108 4.66407 1.4567 4.39186 1.4567Z"
                    fill={color}
                />
                <path
                    d="M6.90794 1.4567H6.51067C6.23478 1.4567 6.01407 1.67741 6.01407 1.95329C6.01407 2.22918 6.23846 2.44989 6.51067 2.44989H6.90794C7.18383 2.44989 7.40454 2.2255 7.40454 1.95329C7.40454 1.68108 7.18383 1.4567 6.90794 1.4567Z"
                    fill={color}
                />
                <path
                    d="M5.45124 2.51608C5.17536 2.51608 4.95465 2.73679 4.95465 3.01268V3.40996C4.95465 3.68585 5.17536 3.90656 5.45124 3.90656C5.72713 3.90656 5.94784 3.68585 5.94784 3.40996V3.01636C5.94784 2.74047 5.72345 2.51608 5.45124 2.51608Z"
                    fill={color}
                />
                <path
                    d="M2.45326 6.48157C2.17737 6.48157 1.95667 6.70596 1.95667 6.97816V7.37544C1.95667 7.65133 2.17737 7.87204 2.45326 7.87204C2.72915 7.87204 2.94986 7.65133 2.94986 7.37544V6.97816C2.95354 6.70596 2.72915 6.48157 2.45326 6.48157Z"
                    fill={color}
                />
                <path
                    d="M1.39388 7.94199H0.996597C0.72071 7.94199 0.5 8.1627 0.5 8.43858C0.5 8.71447 0.724388 8.93518 0.996597 8.93518H1.39388C1.66976 8.93518 1.89047 8.71079 1.89047 8.43858C1.89047 8.1627 1.66976 7.94199 1.39388 7.94199Z"
                    fill={color}
                />
                <path
                    d="M3.90997 7.94199H3.51637C3.24049 7.94199 3.01978 8.1627 3.01978 8.43858C3.01978 8.71447 3.24416 8.93518 3.51637 8.93518H3.91365C4.18954 8.93518 4.41025 8.71079 4.41025 8.43858C4.41025 8.1627 4.18586 7.94199 3.90997 7.94199Z"
                    fill={color}
                />
                <path
                    d="M2.45326 9.00134C2.17737 9.00134 1.95667 9.22205 1.95667 9.49794V9.89521C1.95667 10.1711 2.17737 10.3918 2.45326 10.3918C2.72915 10.3918 2.94986 10.1711 2.94986 9.89521V9.49794C2.95354 9.22205 2.72915 9.00134 2.45326 9.00134Z"
                    fill={color}
                />
            </g>
        </svg>
    );
}

export default React.memo(ClientSVG);
