import * as types from './types';
import {
    getMerchantInfoApi,
    uploadAvatarApi,
    updateNormalMerchantApi,
    getOtherMerchantsApi,
    uploadPolicyApi,
    changePasswordApi
} from 'services/merchant';
import { enqueueSnackbar } from 'notifier/actions';

export function fetchMerchantInfo({ successCallback, errorCallback }) {
    return async function (dispatch, getState) {
        const { auth } = getState();

        const merchantId = auth?.user?.merchantId;

        if (!merchantId) {
            console.log('merchantId cannot be null');
            return;
        }
        dispatch(_fetchMerchantInfoAction());

        try {
            const merchant = await getMerchantInfoApi(merchantId);
            dispatch(_fetchMerchantInfoActionSuccess({ merchant }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            // dispatch(
            //     enqueueSnackbar({
            //         message: `[fetchMerchantInfo] ${message}`,
            //         type: 'info'
            //     })
            // );
            dispatch(_fetchMerchantInfoActionFailed());
            errorCallback && errorCallback(message);
        }
    };
}

function _fetchMerchantInfoAction() {
    return {
        type: types.FETCH_MERCHANT_INFO
    };
}

function _fetchMerchantInfoActionFailed() {
    return {
        type: types.FETCH_MERCHANT_INFO_FAILED
    };
}

function _fetchMerchantInfoActionSuccess({ merchant }) {
    return {
        type: types.FETCH_MERCHANT_INFO_SUCCESS,
        payload: {
            merchant
        }
    };
}

export function uploadAvatar({ merchantId, file, successCallback, errorCallback }) {
    return async function (dispatch) {
        try {
            await uploadAvatarApi({ file, merchantId });
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `${message}`,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function uploadPolicy({ merchantId, file, successCallback, errorCallback }) {
    return async function (dispatch) {
        try {
            const merchant = await uploadPolicyApi({ file, merchantId });
            dispatch(_updateNormalMerchant(merchant));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `${message}`,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function updateNormalMerchant({ merchantId, body, successCallback, errorCallback }) {
    return async function (dispatch) {
        try {
            const merchant = await updateNormalMerchantApi({ merchantId, body });
            dispatch(_updateNormalMerchant(merchant));
            successCallback && successCallback();
            dispatch(
                enqueueSnackbar({
                    message: `Update merchant setting success`,
                    type: 'success'
                })
            );
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `${message}`,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

function _updateNormalMerchant(merchant) {
    return {
        type: types.UPDATE_MERCHANT,
        payload: {
            merchant
        }
    };
}

export function getOtherMerchants({ success, error }) {
    return async function (dispatch, getState) {
        const { auth } = getState();

        const merchantId = auth?.user?.merchantId;

        if (!merchantId) {
            console.log('merchantId cannot be null');
            return;
        }

        try {
            const merchants = await getOtherMerchantsApi();
            dispatch(_getOthersMerchants(merchants));
            success && success();
        } catch ({ message }) {
            error && error(message);
        }
    };
}

function _getOthersMerchants(merchants) {
    return {
        type: types.FETCH_OTHER_MERCHANTS,
        payload: {
            merchants
        }
    };
}

export function changePassword({ body, success, error }) {
    return async function (dispatch, getState) {
        try {
            await changePasswordApi(body);
            success && success();
        } catch ({ message }) {
            dispatch(
                enqueueSnackbar({
                    message: `[Update password] ${message}`,
                    type: 'info'
                })
            );
            error && error(message);
        }
    };
}
