import request from './request';
// import { objectToQueryParams } from 'utils/queryParams';

const groupApis = {
    groups: '/groups',
    groupMerchants: `/group-merchant`
};

export async function fetchGroupsApi(relations = ['groupMerchants', 'groupMerchants.merchant']) {
    const response = await request.get(`${groupApis.groups}?relations=${relations}`);
    return response.data;
}

export async function deleteGroupApi(groupId) {
    const response = await request.delete(`${groupApis.groups}/${groupId}`);
    return response.data;
}

export async function createGroupApi(newGroup) {
    const response = await request.post(`${groupApis.groups}`, newGroup);
    return response.data;
}

export async function updateGroupApi({ groupId, newGroup }) {
    const response = await request.put(`${groupApis.groups}/${groupId}`, newGroup);
    return response.data;
}

export async function addMerchantToGroupApi(groupMerchant) {
    const response = await request.post(`${groupApis.groupMerchants}`, groupMerchant);
    return response.data;
}

export async function removeMerchantToGroupApi(gMId) {
    const response = await request.delete(`${groupApis.groupMerchants}/${gMId}`);
    return response.data;
}
