import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Badge, IconButton } from '@material-ui/core';
import { useIsNewCheckIn, useIsSameToday, useWaitBookings } from 'hooks/calendar';
import AirlineSeatReclineNormalIcon from '@material-ui/icons/AirlineSeatReclineNormal';
import { useIsHavingPermissionTest } from 'hooks/auth';
import { MERCHANT_PERMISSIONS } from 'const';
import { useDispatch } from 'react-redux';
import * as calendarActions from '../actions';
import PopperMenu from 'components/popper';
import WaitBookings from './waitBookings';

const useStyles = makeStyles((theme) => ({
    root: {},
    badge: {
        '& .MuiBadge-badge': {
            color: theme.palette.common.white,
            fontWeight: 500
        }
    },
    '@keyframes spin': {
        '0%': {
            transform: 'scale(0.8)',
            transformOrigin: `-50% 100%`
        },
        '100%': {
            transform: 'scale(1.2)',
            transformOrigin: `-150% 200%`
        }
    },
    badgeSpinning: {
        '& .MuiBadge-badge': {
            animation: `$spin 500ms infinite linear`
        }
    }
}));

function CheckInList() {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const isHasCheckInPermission = useIsHavingPermissionTest(MERCHANT_PERMISSIONS.CHECK_IN);
    const [anchorWait, setAnchorWait] = React.useState(false);
    const waitBookings = useWaitBookings();
    const isNewCheckIn = useIsNewCheckIn();

    const isToday = useIsSameToday();

    const handleOpenCheckInList = React.useCallback(
        (e) => {
            setAnchorWait(anchorWait ? null : e.currentTarget);
            setTimeout(() => {
                dispatch(calendarActions.setAlertNewCheckIn(''));
            }, 0);
        },
        [anchorWait, dispatch]
    );

    const handleCloseCheckInList = React.useCallback(() => {
        setAnchorWait(null);
    }, []);

    if (!(isHasCheckInPermission && isToday)) return null;

    return (
        <Grid item>
            <IconButton size="small" onClick={handleOpenCheckInList}>
                <Badge
                    className={`${classes.badge} ${Boolean(isNewCheckIn) && classes.badgeSpinning}`}
                    badgeContent={waitBookings?.length}
                    color="secondary"
                >
                    <AirlineSeatReclineNormalIcon fontSize="small" />
                </Badge>
            </IconButton>
            {anchorWait && (
                <PopperMenu
                    placement={'bottom-end'}
                    noPadding={true}
                    anchorEl={anchorWait}
                    handleClickAway={handleCloseCheckInList}
                    style={{
                        zIndex: theme.zIndex.drawer - 1
                    }}
                >
                    <WaitBookings bookings={waitBookings} />
                </PopperMenu>
            )}
        </Grid>
    );
}

export default CheckInList;
