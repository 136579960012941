import React from 'react';
import Settings from './Tabs';
import ContentLayout from '../layout/ContentLayout';
import { useTranslation } from 'react-i18next';

function Analyze() {
    const { t } = useTranslation();

    return (
        <ContentLayout title={`${t('nav_analytics')}`} isHideTitleMobile={true}>
            <Settings />
        </ContentLayout>
    );
}

export default React.memo(Analyze);
