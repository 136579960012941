import React from 'react';
import { Grid, Typography, Hidden } from '@material-ui/core';
import Button from 'components/button/Base';
import InputBase from 'components/input';
import * as bookingActions from './actions';
import { useDispatch } from 'react-redux';
import PhoneInput from 'components/input/Phone';
import { removePlus } from 'utils/phone';
import Spinner from 'components/spinner';
import { getFullName } from 'utils/naming';
import { useFormik } from 'formik';
import { string, object } from 'yup';
import { useSelectedServices, useSelectedTime, useSelectedDate, useSelectedStaff } from 'hooks/checkIn';
import { useMerchantDetail, useMerchantTime12 } from 'hooks/merchant';
import { mapTimeToTime12, getDay, getMonthText } from 'utils/timing';
import { DEFAULT_CURRENCY } from 'const';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    cfRoot: {
        width: '100%',
        background: theme.palette.common.white,
        borderRadius: `8px 8px 0px 0px`,
        boxShadow: theme.shadows[1],
        padding: 32,
        position: 'sticky',
        top: 0,
        left: 0,
        zIndex: 100,
        [theme.breakpoints.down('sm')]: {
            marginTop: 0
        }
    },
    cfTotalDescription: {},
    cfTextCenter: {
        textAlign: 'center'
    },
    cfAvatar: {
        position: 'absolute',
        width: 80,
        height: 80,
        top: -40,
        left: 0,
        right: 0,
        margin: 'auto',
        boxShadow: `rgba(16, 25, 40, 0.08) 0px 8px 16px 0px`,
        border: `2px solid white`
    },

    cfServiceItem: {
        width: '100%',
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        minHeight: 65,
        paddingLeft: 0
    },
    cfDatetime: {
        padding: `20px 20px 20px 0`
    },
    cfTitleService: {
        fontWeight: 600
    },
    cfDateTimeConfirm: {
        backgroundColor: '#f5f5fa',
        marginRight: theme.spacing(2),
        outline: 'none',
        padding: '10px 5px',
        maxWidth: '64px',
        background: '#FFFFFF',
        textAlign: 'center',
        borderRadius: '8px'
    },
    cfSpacing: {
        paddingBottom: theme.spacing(2)
    },
    cfBtnSubmit: {
        position: 'relative'
    },
    cfButtonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    cfLoadingWrapper: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        background: theme.palette.common.white,
        top: 0,
        left: 0,
        opacity: 0.8,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 10
    },
    cfServices: {
        minHeight: 100,
        display: 'flex',
        alignItems: 'center',
        borderBottom: `1px solid rgb(231, 232, 233)`,
        borderTop: `1px solid rgb(231, 232, 233)`
    },
    cfTotal: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3)
    }
}));

const ConfirmationSchema = object({
    title: string().required('This filed is required'),
    phone: string().required('This field is required'),
    email: string().email('Invalid email'),
    note: string()
});

function VerifyOTP() {
    const theme = useTheme();
    const classes = useStyles();
    const dispatch = useDispatch();

    const services = useSelectedServices();
    const staff = useSelectedStaff();
    const date = useSelectedDate();
    const time = useSelectedTime();
    const merchant = useMerchantDetail();
    const TIME_12 = useMerchantTime12();

    const slug = React.useMemo(() => {
        return merchant?.siteName;
    }, [merchant]);

    const totalTime = React.useMemo(() => {
        return (
            services?.reduce((t, sv) => {
                const { prices } = sv;
                const price = prices?.[0];
                t += price?.duration || 0;
                return t;
            }, 0) || 0
        );
    }, [services]);

    const totalPrice = React.useMemo(() => {
        return (
            services?.reduce((t, sv) => {
                const { prices } = sv;
                const price = prices?.[0];
                t += price?.retailPrice || 0;
                return t;
            }, 0) || 0
        );
    }, [services]);

    const tz = React.useMemo(() => {
        return merchant?.timezone;
    }, [merchant]);

    const handlePhoneChange = (value) => {
        setFieldValue('phone', value);
    };

    const currency = React.useMemo(() => {
        return merchant?.currency || DEFAULT_CURRENCY;
    }, [merchant]);

    const formik = useFormik({
        initialValues: {
            title: '',
            note: '',
            phone: '',
            email: ''
        },
        validationSchema: ConfirmationSchema,
        onSubmit: (values, { setSubmitting, setStatus }) => {
            setStatus({ error: null });
            dispatch(
                bookingActions.verifyOTP({
                    slug,
                    ...values,
                    phone: `${removePlus(values?.phone)}`,
                    title: values.title,
                    successCallback: () => {
                        dispatch(bookingActions.goNextStep());
                        setSubmitting(false);
                    },
                    errorCallback: () => {
                        setSubmitting(false);
                    },
                    conflictCallback: (message) => {
                        setSubmitting(false);
                    }
                })
            );

            setStatus({ error: null });
        }
    });

    const {
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        isValid
    } = formik;

    const isNotValid = React.useMemo(() => {
        return !isValid;
    }, [isValid]);

    return (
        <form onSubmit={handleSubmit}>
            <Grid container className={classes.cfRoot} direction="column" wrap="nowrap">
                {isSubmitting && (
                    <div className={classes.cfLoadingWrapper}>
                        <Spinner size={40} thick={4} />
                    </div>
                )}
                <Grid item className={classes.cfSpacing}>
                    <Typography variant="h3">{`Add your phone number`}</Typography>
                </Grid>
                <Grid item className={classes.cfSpacing}>
                    <Typography variant="subtitle1">
                        Please provide your mobile phone number to confirm your appointment booking.
                    </Typography>
                </Grid>
                <Grid item className={classes.cfSpacing}>
                    <PhoneInput
                        label={
                            <>
                                <Typography variant="body1" display="inline">
                                    Phone Number
                                </Typography>{' '}
                                <Typography display="inline" variant="body2" style={{ color: theme.colors.red }}>
                                    (Required)
                                </Typography>
                            </>
                        }
                        placeholder={`(471) 213-1312`}
                        disabled={isSubmitting}
                        onChange={handlePhoneChange}
                        defaultValue={values.phone}
                        autoFocus={true}
                    />
                </Grid>

                <Grid item className={classes.cfSpacing}>
                    <InputBase
                        fullWidth
                        label={
                            <>
                                <Typography variant="body1" display="inline">
                                    Full name
                                </Typography>{' '}
                                <Typography display="inline" variant="body2" style={{ color: theme.colors.red }}>
                                    (Required)
                                </Typography>
                            </>
                        }
                        placeholder={'Your first name and last name'}
                        name="title"
                        value={values.title || ''}
                        disabled={isSubmitting}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.title && touched.title}
                        errorText={errors.title}
                    />
                </Grid>

                <Grid item className={classes.cfSpacing}>
                    <InputBase
                        fullWidth
                        label={'Email'}
                        placeholder={'example@gmail.com'}
                        name="email"
                        value={values.email || ''}
                        disabled={isSubmitting}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.email && touched.email}
                        errorText={errors.email}
                    />
                </Grid>

                <Grid item className={classes.cfSpacing}>
                    <InputBase
                        fullWidth
                        label={'Appointment Note'}
                        placeholder={'Add an appointment note'}
                        name="note"
                        value={values.note || ''}
                        disabled={isSubmitting}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.note && touched.note}
                        errorText={errors.note}
                        multiline
                        rows={3}
                    />
                </Grid>

                <Hidden smUp>
                    <Grid item>
                        <Grid container direction="column" wrap="owrap">
                            <Grid item className={classes.cfDatetime}>
                                <Grid container direction="row" wrap="nowrap">
                                    <Grid item md={3} className={classes.cfDateTimeConfirm}>
                                        <Typography variant="h4">{`${getDay(date?.value, tz)}`}</Typography>
                                        <Typography variant="subtitle1">{`${getMonthText(date?.value)}`}</Typography>
                                    </Grid>
                                    <Grid item md={8}>
                                        <Typography variant="body1" style={{ margin: '5px 0' }}>{`Starts at ${
                                            TIME_12 ? mapTimeToTime12(time) : time
                                        }`}</Typography>
                                        <Typography variant="subtitle1"> Total duration {totalTime}min</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.cfServices}>
                                <Grid container direction="column" wrap="nowrap" alignItems="center">
                                    {services.length === 0 && (
                                        <Typography variant="caption">There is no service yet</Typography>
                                    )}
                                    {services.length > 0 &&
                                        services.map((service, index) => {
                                            const { prices } = service;
                                            const price = prices?.[0];
                                            return (
                                                <Grid key={index} item className={classes.cfServiceItem}>
                                                    <Grid
                                                        container
                                                        alignItems="flex-start"
                                                        justifyContent="space-between"
                                                        wrap="nowrap"
                                                    >
                                                        <Grid item>
                                                            <Grid container direction="column" wrap="nowrap">
                                                                <Grid item>
                                                                    <Typography
                                                                        variant="body2"
                                                                        className={classes.cfTitleService}
                                                                    >
                                                                        {service.title}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography variant="subtitle1">
                                                                        {price?.duration || 0}mins{' '}
                                                                        {staff
                                                                            ? staff.isSystem
                                                                                ? ''
                                                                                : `with ${getFullName(staff)}`
                                                                            : ''}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="body2">
                                                                {currency}
                                                                {price?.retailPrice || 0}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            );
                                        })}
                                </Grid>
                            </Grid>
                            <Grid item className={classes.cfTotal}>
                                <Grid container direction="row" justifyContent="space-between" wrap="nowrap">
                                    <Grid item>
                                        <Typography variant="body1">Total</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body1">
                                            {currency}
                                            {totalPrice}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Hidden>

                <Grid item>
                    <div className={classes.cfBtnSubmit}>
                        <Button
                            type={'submit'}
                            disabled={isNotValid || isSubmitting}
                            fullWidth
                            variant="contained"
                            color="primary"
                        >
                            Continue
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </form>
    );
}

export default React.memo(VerifyOTP);
