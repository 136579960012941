import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Avatar,
    ClickAwayListener,
    Grid,
    Grow,
    IconButton,
    MenuItem,
    Paper,
    Popper,
    useTheme,
    MenuList,
    Typography
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import GroupIcon from '@material-ui/icons/Group';
import WorkIcon from '@material-ui/icons/Work';
import { getImageUrl } from 'utils/image';
import { useFilteredStaff } from 'hooks/calendar';
import { useDispatch } from 'react-redux';
import * as calendarActions from '../actions';
import { useStaffOptions } from 'hooks/staff';
import Tooltip from 'components/tooltip';
import { setItem } from 'utils/localStorage';
import { FILTER_STAFF } from 'const';

const useStyles = makeStyles((theme) => ({
    root: {},
    group: {
        height: 50,
        borderRadius: 40,
        background: theme.palette.common.white,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
        // boxShadow: theme.shadows[1]
    },
    groupItem: {
        paddingRight: theme.spacing(1)
    }
}));

function StaffFiltered() {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const staffListOptions = useStaffOptions();

    const filteredStaff = useFilteredStaff();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (e) => {
        setAnchorEl(!anchorEl ? e.currentTarget : null);
    };

    const handleFilteredStaffChanged = React.useCallback(
        (value) => {
            dispatch(calendarActions.setFilteredStaff(value));
        },
        [dispatch]
    );

    const matchedStaff = React.useMemo(() => {
        return staffListOptions?.find((staff) => staff?.id === filteredStaff);
    }, [filteredStaff, staffListOptions]);

    return (
        <Grid container alignItems="center" wrap="nowrap" direction="row" className={classes.group}>
            <Grid item className={classes.groupItem}>
                <Tooltip placement="right" title="All staff">
                    <IconButton
                        size="small"
                        onClick={() => {
                            handleFilteredStaffChanged(0);
                            setItem(FILTER_STAFF, 0);
                        }}
                    >
                        <GroupIcon
                            fontSize="small"
                            style={{
                                color: +filteredStaff === 0 && theme.palette.primary.main
                            }}
                        />
                    </IconButton>
                </Tooltip>
            </Grid>
            <Grid item className={classes.groupItem}>
                <Tooltip placement="right" title="All staff have working hours">
                    <IconButton
                        size="small"
                        onClick={() => {
                            handleFilteredStaffChanged(-2);
                            setItem(FILTER_STAFF, -2);
                        }}
                    >
                        <WorkIcon
                            fontSize="small"
                            style={{
                                color: +filteredStaff === -2 && theme.palette.primary.main
                            }}
                        />
                    </IconButton>
                </Tooltip>
            </Grid>
            <Grid item>
                <Grid
                    container
                    onClick={handleClick}
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    style={{ cursor: 'pointer' }}
                    wrap="nowrap"
                >
                    <Grid item>
                        <Grid container wrap="nowrap" direction="row" alignItems="center" spacing={1}>
                            <Grid item>
                                <Avatar
                                    src={getImageUrl(matchedStaff?.avatar)}
                                    style={{
                                        width: 20,
                                        height: 20,
                                        fontSize: 10,
                                        border: !!matchedStaff
                                            ? `1px solid ${theme.palette.primary.main}`
                                            : `transparent`,
                                        padding: 2
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="body2">{matchedStaff?.label || 'Choose staff'}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item style={{ display: 'flex' }}>
                        <ArrowDropDownIcon />
                    </Grid>
                </Grid>
            </Grid>
            <Popper
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                role={undefined}
                transition
                disablePortal
                style={{ zIndex: 100 }}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                                <MenuList style={{ maxHeight: `70vh`, overflowY: 'auto' }} id="split-button-menu">
                                    {staffListOptions?.map((staff) => (
                                        <MenuItem
                                            key={staff?.value}
                                            selected={+filteredStaff === staff?.value}
                                            onClick={() => {
                                                dispatch(calendarActions.setFilteredStaff(staff?.value));
                                                setAnchorEl(null);
                                            }}
                                        >
                                            <Grid container direction="row" alignItems="center" spacing={1}>
                                                <Grid item>
                                                    <Avatar
                                                        src={getImageUrl(staff?.avatar)}
                                                        style={{ width: 24, height: 24, fontSize: 12 }}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="inherit"> {staff?.label}</Typography>
                                                </Grid>
                                            </Grid>
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Grid>
    );
}

export default StaffFiltered;
