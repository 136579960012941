import { useSelector } from 'react-redux';

export function useBookings() {
    return useSelector((state) => state.statistic?.bookings);
}

export function useCreatedClients() {
    return useSelector((state) => state.statistic?.createdClients);
}
export function useTotalOnlineBookings() {
    return useSelector((state) => state.statistic?.totalOnlineBookings);
}

export function useTotalOfflineBookings() {
    return useSelector((state) => state.statistic?.totalOfflineBookings);
}
export function useOfflineGifts() {
    return useSelector((state) => state.statistic?.offlineGifts);
}

export function useOnlineGifts() {
    return useSelector((state) => state.statistic?.onlineGifts);
}

export function useOnlineGiftIncome() {
    return useSelector((state) => state.statistic?.onlineGiftIncome);
}

export function useOfflineGiftIncome() {
    return useSelector((state) => state.statistic?.offlineGiftIncome);
}

export function useRedeemedValue() {
    return useSelector((state) => state.statistic?.redeemedValue);
}

export function useRemainingValue() {
    return useSelector((state) => state.statistic?.remainingValue);
}

export function useCodes() {
    return useSelector((state) => state.statistic?.codes);
}

export function useRedeemHistories() {
    return useSelector((state) => state.statistic?.redeemHistories);
}

export function useTotalDepositAmount() {
    return useSelector((state) => state.statistic?.totalDepositAmount);
}
