import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, ClickAwayListener, Typography, Paper } from '@material-ui/core';
import PhoneInput from 'components/input/Phone';
import { searchClientApi } from 'services/client';
import { getFullName } from 'utils/naming';
import Avatar from 'components/avatar';
import AvatarIconSVG from 'assets/images/svg/AvatarIconSVG';
import { removePlus, addPlus } from 'utils/phone';
import Spinner from 'components/spinner';
import hexToRgba from 'hex-to-rgba';
import ListItem from 'components/list/Item';
import { OPERATOR } from 'const/condition';
import { getImageUrl } from 'utils/image';
import BlockRounded from '@material-ui/icons/BlockRounded';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative'
    },
    suggestion: {
        position: 'absolute',
        top: 79,
        zIndex: 1000,
        boxShadow: theme.shadows[1],
        background: theme.palette.common.white,
        width: '100%',
        maxHeight: 250,
        overflowY: 'scroll',
        borderRadius: theme.radius(5),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    clientList: {
        position: 'relative',
        height: '100%',
        width: '100%'
    },
    loadingWrapper: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        background: hexToRgba(theme.palette.common.white, 0.8),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));

function PhoneInputWithClientSuggestion({
    isSubmitting,
    isNoShow,
    onChange,
    defaultValue,
    autoFocus,
    onClientChange,
    label,
    suggestionClassName,
    disabled
}) {
    const classes = useStyles();
    const theme = useTheme();
    const [filterValue, setFilterValue] = React.useState(defaultValue);
    const [clients, setClients] = React.useState([]);
    const [isFiltering, setIsFiltering] = React.useState(false);
    const [isShowSuggestion, setIsShowSuggestion] = React.useState(false);
    const filterRef = React.useRef();

    const handleFocus = () => {
        setIsShowSuggestion(true);
    };

    React.useEffect(() => {
        if (filterRef.current) clearTimeout(filterRef.current);

        filterRef.current = setTimeout(async () => {
            setIsFiltering(true);
            try {
                const response = await searchClientApi({
                    query: { phone: { [OPERATOR.contains]: removePlus(filterValue) } }
                });
                const clientList = response?.items || [];
                const clientOptions = clientList?.map((client) => {
                    const fullName = getFullName(client);
                    return {
                        ...client,
                        label: (
                            <>
                                <Typography variant="body2">{`${fullName} (${client?.user?.phone}) `}</Typography>
                                {client?.note && <Typography variant="subtitle2">{client?.note}</Typography>}
                            </>
                        ),
                        value: client?.id,
                        phone: client?.user?.phone,
                        fullName,
                        icon: () => (
                            <div style={{ position: 'relative', opacity: client?.block ? 0.5 : 1 }}>
                                <Avatar src={getImageUrl(client?.avatar)} size="medium">
                                    <AvatarIconSVG style={{ width: '100%', height: '100%' }} />
                                </Avatar>
                                {client?.block && (
                                    <div style={{ position: 'absolute', bottom: -10, right: -5 }}>
                                        <BlockRounded color="error" style={{ width: 15, height: 15 }} />
                                    </div>
                                )}
                            </div>
                        )
                    };
                });
                setIsFiltering(false);
                setClients(clientOptions);
            } catch (err) {
                console.log('error', err);
                setIsFiltering(false);
            }

            return () => {
                if (filterRef?.current) {
                    clearTimeout(filterRef.current);
                }
            };
        }, 500);

        return () => {
            if (filterRef.current) clearTimeout(filterRef.current);
        };
    }, [filterValue, theme]);

    const handleChange = (value) => {
        setFilterValue(value);
        onChange && onChange(value);
    };

    const handleChooseClient = (e, client) => {
        stopPropagation(e);
        setFilterValue(addPlus(client?.phone));
        onClientChange && onClientChange(client);
        setIsShowSuggestion(false);
    };

    const stopPropagation = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleClickAway = () => setIsShowSuggestion(false);

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Grid container className={`${classes.root}`}>
                <PhoneInput
                    label={label}
                    placeholder={`(471) 213-1312`}
                    disabled={isSubmitting || isNoShow || disabled}
                    onChange={handleChange}
                    defaultValue={addPlus(filterValue)}
                    autoFocus={autoFocus}
                    onFocus={handleFocus}
                />
                {clients?.length > 0 && isShowSuggestion && (
                    <Paper className={`${classes.suggestion} ${suggestionClassName}`}>
                        <Grid container direction="column" className={classes.clientList}>
                            {isFiltering && (
                                <div className={classes.loadingWrapper}>
                                    <Spinner size={20} thick={3} />
                                </div>
                            )}
                            {clients?.map((client) => {
                                return (
                                    <Grid key={client?.id} item onClick={(e) => handleChooseClient(e, client)}>
                                        <ListItem icon={client?.icon} name={client.label} />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Paper>
                )}
            </Grid>
        </ClickAwayListener>
    );
}

export default React.memo(PhoneInputWithClientSuggestion);
