import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { INPUT_HEIGHT, LIST_ITEM_HEIGHT } from 'const/style';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'block',
        fontSize: 14,
        fontStyle: 'normal',
        cursor: 'pointer',
        lineHeight: 1.3,
        height: INPUT_HEIGHT,
        paddingRight: 20,
        paddingLeft: theme.spacing(1),
        width: '100%',
        maxWidth: `100%` /* useful when width is set to anything other than 100% */,
        boxSizing: `border-box`,
        margin: 0,
        boxShadow: theme.shadows[1],
        borderRadius: theme.radius(2),
        appearance: `none`,
        border: 'none',
        backgroundColor: (props) => (props.disabled ? 'transparent' : theme.palette.common.white),
        backgroundImage: `url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E')`,
        backgroundRepeat: `no-repeat, repeat`,
        backgroundPosition: `right .7em top 50%, 0 0`,
        backgroundSize: `.65em auto, 100%`,
        '&::-ms-expand': {
            display: `none`
        },
        '&:focus': {
            outline: 'none'
        },
        '& option': {
            fontWeight: `normal`,
            minHeight: LIST_ITEM_HEIGHT,
            fontSize: 14
        }
    },
    label: {
        marginBottom: 6,
        fontWeight: 600
    }
}));

function NativeSelect({ options, label, isBorder = false, selectedValue, onChange, disabled, className }) {
    const classes = useStyles({ disabled, isBorder });

    return (
        <>
            {label && (
                <Typography className={classes.label} variant="body2">
                    {label}
                </Typography>
            )}
            <select
                className={`${classes.root} ${className}`}
                onChange={onChange}
                value={selectedValue}
                disabled={disabled}
            >
                {options.map((option) => {
                    return (
                        <option disabled={option?.disabled} key={option?.value} value={option?.value}>
                            {option?.label}
                        </option>
                    );
                })}
            </select>
        </>
    );
}

export default React.memo(NativeSelect);
