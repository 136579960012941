import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Radio, FormControlLabel } from '@material-ui/core';
import DialogTitle from 'components/dialog/Title';
import DialogContent from 'components/dialog/Content';
import DialogActions from 'components/dialog/Action';
import ButtonBase from 'components/button/Base';
import { Save } from '@material-ui/icons';
import { useGroupPermissionsOptions } from 'hooks/merchant';
import * as settingActions from 'views/settings/actions';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    root: {},
    content: {
        minWidth: 400
    }
}));

function AddPermission({ id, title, onClose, defaultPermissions = [] }) {
    const classes = useStyles();
    const [permissions, setPermissions] = React.useState(defaultPermissions);
    const groupPermissionActions = useGroupPermissionsOptions();
    const dispatch = useDispatch();

    const handleSave = React.useCallback(() => {
        dispatch(
            settingActions.updateGroup({
                groupId: id,
                newGroup: {
                    permissions
                },
                error: (message) => {
                    console.log('message', message);
                },
                success: () => {
                    onClose && onClose();
                }
            })
        );
    }, [dispatch, permissions, id, onClose]);

    const handlePermissionChange = React.useCallback(
        (permission) => {
            setPermissions(
                permissions?.includes(permission)
                    ? permissions?.filter((p) => p !== permission)
                    : [...(permissions || []), permission]
            );
        },
        [permissions]
    );

    return (
        <>
            <DialogTitle>
                <Typography className={classes.whiteColor} variant="h5" component="div">
                    Permission {title}'s setting
                </Typography>
            </DialogTitle>
            <DialogContent className={classes.content}>
                <Grid container direction="column" alignItems="flex-start" justifyContent="flex-start">
                    {groupPermissionActions?.map((option) => {
                        return (
                            <Grid item key={option?.value}>
                                <FormControlLabel
                                    value={option?.value}
                                    checked={permissions?.includes(option?.value)}
                                    control={<Radio onClick={() => handlePermissionChange(option?.value)} />}
                                    label={option?.label}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container wrap="nowrap" spacing={2} alignItems="center" justifyContent="flex-end">
                    <Grid item>
                        <ButtonBase onClick={onClose} variant="outlined">
                            Close
                        </ButtonBase>
                    </Grid>
                    <Grid item>
                        <ButtonBase color="primary" startIcon={<Save />} onClick={handleSave} variant="contained">
                            Save
                        </ButtonBase>
                    </Grid>
                </Grid>
            </DialogActions>
        </>
    );
}

export default AddPermission;
