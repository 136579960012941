import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';
import NoPreferenceSVG from 'assets/images/svg/NoPreferenceSVG';
import { useBookingStaffsFilteredServices } from 'hooks/checkIn';
import { getFullName } from 'utils/naming';
import { useDispatch } from 'react-redux';
import * as bookingActions from './actions';
import { useMerchantDetail } from 'hooks/merchant';
import StaffNotFoundSVG from 'assets/images/svg/StaffNotFoundSVG';
import AvatarIconSVG from 'assets/images/svg/AvatarIconSVG';
import Avatar from 'components/avatar';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    stRoot: {
        alignSelf: 'baseline',
        width: '100%',
        boxShadow: theme.shadows[1]
    },
    stSystem: {
        background: theme.palette.common.white,
        borderRadius: `8px 8px 0px 0px`,
        // boxShadow: theme.shadows[1],
        padding: `20px 30px`,
        borderBottom: `1px solid rgb(231, 232, 233)`,
        cursor: 'pointer',
        '&:hover': {
            background: theme.colors.hoverItem
        },
        position: 'sticky',
        top: 0,
        left: 0,
        zIndex: 100,
        [theme.breakpoints.down('sm')]: {
            padding: 12,
            zIndex: 99,
            position: 'relative'
        }
    },
    stNoStaffFound: {
        background: theme.palette.common.white,
        borderRadius: 8,
        boxShadow: theme.shadows[1],
        padding: `24px 32px`,
        [theme.breakpoints.down('sm')]: {
            padding: 12
        },
        borderBottom: `1px solid rgb(231, 232, 233)`
    },
    stMemberList: {},
    stMember: {
        background: theme.palette.common.white,
        padding: '20px 30px',
        [theme.breakpoints.down('sm')]: {
            padding: 12
        },
        borderBottom: `1px solid rgb(231, 232, 233)`,
        cursor: 'pointer',
        '&:hover': {
            background: theme.colors.hoverItem
        }
    },
    stFullWidth: {
        width: '100%'
    },
    stAlignCenter: {
        textAlign: 'center'
    },
    stNoStaffFoundImage: {
        margin: '20px 0px'
    },
    stAvatar: {
        width: 52,
        height: 52,

        background: `rgb(229, 241, 255)`,
        color: `rgb(3, 122, 255)`,
        fontSize: 24,
        fontWeight: 700,
        marginRight: theme.spacing(4),
        borderRadius: theme.radius(3)
    },
    stSystemAvatar: {
        width: 52,
        height: 52,
        color: `rgb(3, 122, 255)`,
        background: `rgb(242, 242, 247)`,
        fontSize: 24,
        fontWeight: 700,
        marginRight: theme.spacing(4),
        borderRadius: theme.radius(3),
        '& > svg': {
            fill: theme.palette.common.white,
            width: 30,
            height: 30
        }
    },
    stArrow: {
        color: theme.colors.midGrey,
        height: 20
    },
    stMemberListHeader: {
        padding: '20px 30px',
        background: theme.palette.common.white,
        borderBottom: `1px solid rgb(231, 232, 233)`,
        borderTopLeftRadius: theme.radius(3),
        borderTopRightRadius: theme.radius(3)
    },
    stFullName: {
        fontWeight: 600
    },
    stExpand: {
        flex: 1
    },
    stContentBorder: {
        borderRadius: 4
    }
}));

function StaffChoosing() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const staffs = useBookingStaffsFilteredServices();
    const merchant = useMerchantDetail();

    const permissions = React.useMemo(() => {
        return merchant?.permissions || [];
    }, [merchant]);

    const isHaveNoPreference = React.useMemo(() => {
        return permissions?.includes('NO_PREFERENCE');
    }, [permissions]);

    const system = { isSystem: true, name: 'Any available staff', subtitle: 'Maximum availability', id: 'system' };

    const handleStaffChange = (staff) => {
        dispatch(bookingActions.addStaff({ staff }));
        dispatch(bookingActions.goNextStep());
    };

    React.useEffect(() => {
        const $booking = document.getElementById('booking_');
        if ($booking) {
            $booking.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }, []);

    return (
        <Grid container className={classes.stRoot} direction="column" wrap="nowrap">
            {isHaveNoPreference && (
                <Grid
                    item
                    className={classes.stSystem}
                    onClick={() => handleStaffChange(system)}
                    style={{
                        borderRadius: !staffs?.length ? 8 : `8px 8px 0px 0px`
                    }}
                >
                    <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap">
                        <Grid item className={classes.stExpand}>
                            <Grid container alignItems="center" direction="row" wrap="nowrap">
                                <Grid item>
                                    <Avatar className={classes.stSystemAvatar}>
                                        <NoPreferenceSVG size={32} />
                                    </Avatar>
                                </Grid>
                                <Grid item className={classes.stFullWidth}>
                                    <Grid container direction="column" wrap="nowrap">
                                        <Grid item>
                                            <Typography variant="body2" className={classes.stFullName}>
                                                {system.name}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1">{system.subtitle}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <ArrowForwardIos className={classes.stArrow} />
                        </Grid>
                    </Grid>
                </Grid>
            )}
            {staffs.length === 0 && !isHaveNoPreference && (
                <Grid item container justifyContent="center" className={classes.stNoStaffFound}>
                    <Grid item className={classes.stNoStaffFoundImage}>
                        <StaffNotFoundSVG />
                    </Grid>
                    <Grid item className={`${classes.stFullName} ${classes.stAlignCenter}`}>
                        <Typography variant="h3">None of our staff can fulfil your request right now.</Typography>
                        <Typography variant="subtitle1">Please choose another service.</Typography>
                    </Grid>
                </Grid>
            )}
            <Grid item className={classes.stMemberList}>
                <Grid
                    className={!isHaveNoPreference ? classes.stContentBorder : ``}
                    container
                    direction="column"
                    wrap="nowrap"
                >
                    {staffs?.map((member) => {
                        const fullName = getFullName(member) || 'Staff Name';
                        return (
                            <Grid
                                key={member.id}
                                item
                                className={`${classes.stMember} `}
                                onClick={() => handleStaffChange(member)}
                            >
                                <Grid
                                    container
                                    justifyContent="space-between"
                                    spacing={2}
                                    wrap="nowrap"
                                    alignItems="center"
                                >
                                    <Grid item className={`${classes.stExpand}`}>
                                        <Grid container alignItems="center" direction="row" wrap="nowrap">
                                            <Grid item>
                                                <Avatar className={classes.stAvatar} src={member?.avatar}>
                                                    <AvatarIconSVG style={{ width: '100%', height: '100%' }} />
                                                </Avatar>
                                            </Grid>
                                            <Grid item className={classes.stFullWidth}>
                                                <Grid container direction="column" wrap="nowrap" spacing={1}>
                                                    <Grid item>
                                                        <Typography variant="body2" className={classes.stFullName}>
                                                            {fullName}
                                                        </Typography>
                                                    </Grid>
                                                    {member?.note && (
                                                        <Grid item>
                                                            <Typography variant="subtitle1">{member?.note}</Typography>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <ArrowForwardIos className={classes.stArrow} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(StaffChoosing);
