import React from 'react';

function CalenderSVG({ color = '#FFF', ...rest }) {
    return (
        <svg {...rest} width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.1181 8.89764C15.5095 8.89764 15.8267 8.58036 15.8267 8.18898C15.8267 7.79759 15.5095 7.48032 15.1181 7.48032C14.7267 7.48032 14.4094 7.79759 14.4094 8.18898C14.4094 8.58036 14.7267 8.89764 15.1181 8.89764Z"
                fill="#9292A0"
            />
            s
            <path
                d="M3.07087 20C1.37795 20 0 18.622 0 16.9291V4.64567C0 2.95276 1.37795 1.5748 3.07087 1.5748H4.13386V0.708661C4.13386 0.318898 4.45276 0 4.84252 0C5.23228 0 5.55118 0.318898 5.55118 0.708661V1.5748H9.25197V0.708661C9.25197 0.318898 9.57087 0 9.96063 0C10.3504 0 10.6693 0.318898 10.6693 0.708661V1.5748H14.4094V0.708661C14.4094 0.318898 14.7283 0 15.1181 0C15.5079 0 15.8268 0.318898 15.8268 0.708661V1.5748H16.9291C18.622 1.5748 20 2.95276 20 4.64567V9.13386C20 9.52362 19.6811 9.84252 19.2913 9.84252C18.9016 9.84252 18.5827 9.52362 18.5827 9.13386V4.64567C18.5827 3.73228 17.8425 2.99213 16.9291 2.99213H15.8268V3.85827C15.8268 4.24803 15.5079 4.56693 15.1181 4.56693C14.7283 4.56693 14.4094 4.24803 14.4094 3.85827V2.99213H10.6693V3.85827C10.6693 4.24803 10.3504 4.56693 9.96063 4.56693C9.57087 4.56693 9.25197 4.24803 9.25197 3.85827V2.99213H5.55118V3.85827C5.55118 4.24803 5.23228 4.56693 4.84252 4.56693C4.45276 4.56693 4.13386 4.24803 4.13386 3.85827V2.99213H3.07087C2.15748 2.99213 1.41732 3.73228 1.41732 4.64567V16.9291C1.41732 17.8425 2.15748 18.5827 3.07087 18.5827H9.09449C9.48425 18.5827 9.80315 18.9016 9.80315 19.2913C9.80315 19.6811 9.48425 20 9.09449 20H3.07087Z"
                fill="#9292A0"
            />
            <path
                d="M15.315 20C12.7323 20 10.6299 17.8976 10.6299 15.315C10.6299 12.7323 12.7323 10.6299 15.315 10.6299C17.8977 10.6299 20 12.7323 20 15.315C20 17.8976 17.8977 20 15.315 20ZM15.315 12.0472C13.5118 12.0472 12.0473 13.5118 12.0473 15.315C12.0473 17.1181 13.5118 18.5827 15.315 18.5827C17.1181 18.5827 18.5827 17.1181 18.5827 15.315C18.5827 13.5118 17.1181 12.0472 15.315 12.0472Z"
                fill="#9292A0"
            />
            <path
                d="M15.315 16.0236C14.9252 16.0236 14.6063 15.7047 14.6063 15.315V13.7008C14.6063 13.311 14.9252 12.9921 15.315 12.9921C15.7047 12.9921 16.0236 13.311 16.0236 13.7008V14.6063H16.4567C16.8465 14.6063 17.1654 14.9252 17.1654 15.315C17.1654 15.7047 16.8465 16.0236 16.4567 16.0236H15.315Z"
                fill="#9292A0"
            />
            <path
                d="M11.6929 8.89764C12.0843 8.89764 12.4016 8.58036 12.4016 8.18898C12.4016 7.79759 12.0843 7.48032 11.6929 7.48032C11.3015 7.48032 10.9843 7.79759 10.9843 8.18898C10.9843 8.58036 11.3015 8.89764 11.6929 8.89764Z"
                fill="#9292A0"
            />
            <path
                d="M8.26774 12.3228C8.65913 12.3228 8.9764 12.0056 8.9764 11.6142C8.9764 11.2228 8.65913 10.9055 8.26774 10.9055C7.87636 10.9055 7.55908 11.2228 7.55908 11.6142C7.55908 12.0056 7.87636 12.3228 8.26774 12.3228Z"
                fill="#9292A0"
            />
            <path
                d="M4.84251 8.89764C5.23389 8.89764 5.55117 8.58036 5.55117 8.18898C5.55117 7.79759 5.23389 7.48032 4.84251 7.48032C4.45113 7.48032 4.13385 7.79759 4.13385 8.18898C4.13385 8.58036 4.45113 8.89764 4.84251 8.89764Z"
                fill="#9292A0"
            />
            <path
                d="M4.84251 12.3228C5.23389 12.3228 5.55117 12.0056 5.55117 11.6142C5.55117 11.2228 5.23389 10.9055 4.84251 10.9055C4.45113 10.9055 4.13385 11.2228 4.13385 11.6142C4.13385 12.0056 4.45113 12.3228 4.84251 12.3228Z"
                fill="#9292A0"
            />
            <path
                d="M4.84251 15.748C5.23389 15.748 5.55117 15.4308 5.55117 15.0394C5.55117 14.648 5.23389 14.3307 4.84251 14.3307C4.45113 14.3307 4.13385 14.648 4.13385 15.0394C4.13385 15.4308 4.45113 15.748 4.84251 15.748Z"
                fill="#9292A0"
            />
            <path
                d="M8.26774 15.748C8.65913 15.748 8.9764 15.4308 8.9764 15.0394C8.9764 14.648 8.65913 14.3307 8.26774 14.3307C7.87636 14.3307 7.55908 14.648 7.55908 15.0394C7.55908 15.4308 7.87636 15.748 8.26774 15.748Z"
                fill="#9292A0"
            />
            <path
                d="M8.26774 8.89764C8.65913 8.89764 8.9764 8.58036 8.9764 8.18898C8.9764 7.79759 8.65913 7.48032 8.26774 7.48032C7.87636 7.48032 7.55908 7.79759 7.55908 8.18898C7.55908 8.58036 7.87636 8.89764 8.26774 8.89764Z"
                fill="#9292A0"
            />
        </svg>
    );
}

export default React.memo(CalenderSVG);
