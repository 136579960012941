import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, IconButton, Dialog } from '@material-ui/core';
import LayoutTabContent from 'views/layout/LayoutTabContent';
import AddMember from './addMember';
import StickyHeadTable from 'components/table/StickyHeadTable';
import { useStaffList, useIsFetchingStaff } from 'hooks/staff';
import { useDispatch } from 'react-redux';
import { getFullName } from 'utils/naming';
import ConfirmDialog from 'components/dialog/Confirm';
import * as staffActions from './action';
import { useTranslation } from 'react-i18next';
import Avatar from 'components/avatar';
import IconCameraSVG from 'assets/images/svg/IconCameraSVG';
import { useDropzone } from 'react-dropzone';
import { AVATAR_EXTENSIONS, MERCHANT_PERMISSIONS, UserState, USER_TYPES } from 'const';
import Spinner from 'components/spinner';
import SimpleBar from 'simplebar-react';
import Search from 'components/search';
import useMediaQueries from 'hooks/useMediaQueries';
import PhoneFormat from 'components/phoneFormat';
import { getImageUrl } from 'utils/image';
import { isValidPhone } from 'utils/phone';
import Tooltip from 'components/tooltip';
import { useIsHavingInternalPermission, useIsHavingPermission } from 'hooks/auth';
import ChangeStaffCredential from './ChangeStaffCredential';
import StaffServiceCommission from './StaffServiceCommission';
import * as serviceActions from 'views/services/action/service';
import IOSSwitch from 'components/switch/IOS';
import RequirePasswordForm from './RequirePasswordForm';
import { checkPasswordApi } from 'services/merchant';
import IncomingBookings from 'views/services/IncomingBookings';
import Button from 'components/button/Base';
import AddIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import CommissionIcon from '@material-ui/icons/AttachMoney';
import PassIcon from '@material-ui/icons/LockOpen';
import CheckIcon from '@material-ui/icons/Check';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%'
    },
    table: {
        width: '100%',
        marginTop: 24,
        height: `calc(100% - 48px - 24px)`,
        overflowY: 'hidden',
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
            height: `calc(100% - 48px)`,
            maxHeight: `-webkit-fill-available`
        }
    },
    container: {
        flex: 1
    },
    avatar: {
        '& .alreadyHaveImage': {
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            visibility: 'hidden',
            left: 0,
            top: 0,
            '& svg': {
                width: 30,
                height: 30,
                '& path': {
                    fill: theme.palette.common.white
                }
            },
            '&  div': {
                outline: 'none'
            }
        },
        '&:hover': {
            '& .alreadyHaveImage': {
                visibility: 'visible'
            }
        }
    },
    upload: {
        width: '100%',
        height: '100%',
        borderRadius: '50%',

        background: theme.palette.common.white,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            opacity: 0.8
        },
        '& svg': {
            width: 30,
            height: 30
        },
        '&  div': {
            outline: 'none'
        }
    },
    imageUploading: {
        opacity: 0.5,
        background: theme.colors.gunPower
    },
    noThumbnail: {
        background: `${theme.palette.common.white} !important`,
        border: `1px dashed ${theme.colors.border}`,
        '&:hover': {
            background: `${theme.palette.primary.light} !important`
        }
    },
    spacing: {
        marginBottom: theme.spacing(2)
    },
    search: {
        maxWidth: 300,
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            flex: 1
        }
    }
}));

function AvatarStaff({ rowData }) {
    const classes = useStyles();
    const avatar = rowData?.avatar;
    const dispatch = useDispatch();

    const [thumbnail, setThumbnail] = React.useState(avatar);
    const [isLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        if (avatar) {
            setThumbnail(getImageUrl(avatar));
        } else {
            setThumbnail();
        }
    }, [avatar]);

    const stopPropagation = (e) => {
        e.stopPropagation();
        e.preventDefault();
    };

    const onRejectedHandler = React.useCallback(
        (rejectedFiles) => {
            dispatch(staffActions.uploadAvatarFailed());
        },
        [dispatch]
    );

    const onDropAcceptedHandler = React.useCallback(
        (acceptedFiles) => {
            setIsLoading(true);
            const file = acceptedFiles?.[0];
            if (file) {
                setThumbnail(URL.createObjectURL(file));
            }
            dispatch(
                staffActions.uploadAvatar({
                    staffId: rowData.id,
                    file,
                    successCallback: () => {
                        setIsLoading(false);
                    },
                    errorCallback: () => {
                        setIsLoading(false);
                        setThumbnail(avatar);
                    }
                })
            );
        },
        [dispatch, rowData.id, avatar]
    );

    const { getRootProps, getInputProps } = useDropzone({
        onDropAccepted: onDropAcceptedHandler,
        onDropRejected: onRejectedHandler,
        accept: AVATAR_EXTENSIONS
    });

    React.useEffect(() => {
        return () => {
            if (thumbnail) {
                URL.revokeObjectURL(thumbnail);
            }
        };
    }, [thumbnail]);

    return (
        <div className={classes.avatar} style={{ position: 'relative', width: 48, height: 48 }}>
            <Avatar
                className={`${classes.upload} ${isLoading ? classes.imageUploading : ''} ${
                    !thumbnail ? classes.noThumbnail : ''
                }`}
                src={thumbnail}
                size="large"
            >
                <div onClick={stopPropagation}>
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <IconCameraSVG />
                    </div>
                </div>
            </Avatar>
            {thumbnail && (
                <div onClick={stopPropagation} className={'alreadyHaveImage'}>
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <IconCameraSVG />
                    </div>
                </div>
            )}
            {isLoading && (
                <div
                    style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        zIndex: 2,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        left: 3,
                        top: 3
                    }}
                >
                    <Spinner size={40} thick={4} />
                </div>
            )}
        </div>
    );

    // return <Avatar size="large" />;
}

const Actions = ({ value, rowData, t, isHavingInternalPermission, isHasServiceCommission, isShowStaffCommission }) => {
    const dispatch = useDispatch();
    const [openDeactivateConfirm, setOpenDeactivateConfirm] = React.useState(false);
    const [openActivateConfirm, setOpenActivateConfirm] = React.useState(false);
    const [openChangePassword, setOpenChangePassword] = React.useState(false);
    const [openStaffServiceCommission, setOpenStaffServiceCommission] = React.useState(false);
    const [incomingBookings, setInComingBookings] = React.useState(null);
    const [isShowIncomingBookings, setIsShowIncomingBookings] = React.useState(false);

    const phone = rowData?.user?.phone;
    const isPhoneValid = isValidPhone(phone);

    const isBlockedStaff = React.useMemo(() => {
        return rowData?.staffState === STAFF_STATE.DISABLE;
    }, [rowData]);

    const stopPropagation = React.useCallback((e) => {
        e && e.stopPropagation();
        e && e.preventDefault();
    }, []);

    const handleDeactivateClick = React.useCallback(
        (e) => {
            stopPropagation(e);
            setOpenDeactivateConfirm(true);
        },
        [stopPropagation]
    );

    const handleActivateClick = React.useCallback(
        (e) => {
            stopPropagation(e);
            setOpenActivateConfirm(true);
        },
        [stopPropagation]
    );

    const handleAgree = React.useCallback(
        (e) => {
            stopPropagation(e);
            dispatch(
                staffActions.deleteStaff({
                    id: rowData.id,

                    successCallback: (bookings = []) => {
                        if (bookings?.length) {
                            setIsShowIncomingBookings(true);
                            setInComingBookings(bookings);
                        }
                    },
                    errorCallback: (message) => {}
                })
            );
            setOpenDeactivateConfirm(false);
        },
        [dispatch, rowData, stopPropagation]
    );

    const handleDisagree = React.useCallback(
        (e) => {
            stopPropagation(e);
            setOpenDeactivateConfirm(false);
        },
        [stopPropagation]
    );

    const handleAgreeActivate = React.useCallback(
        (e) => {
            stopPropagation(e);
            dispatch(staffActions.activateStaff({ id: rowData?.id }));
            setOpenActivateConfirm(false);
        },
        [dispatch, rowData, stopPropagation]
    );

    const handleDisagreeActivate = React.useCallback(
        (e) => {
            stopPropagation(e);
            setOpenActivateConfirm(false);
        },
        [stopPropagation]
    );

    const handleOpenCommission = React.useCallback(
        (e) => {
            stopPropagation(e);
            setOpenStaffServiceCommission(true);
        },
        [stopPropagation]
    );

    const handleCloseCommission = React.useCallback(
        (e) => {
            stopPropagation(e);
            setOpenStaffServiceCommission(false);
        },
        [stopPropagation]
    );

    const handleOpenUpdatePassword = React.useCallback(
        (e) => {
            stopPropagation(e);
            setOpenChangePassword(true);
        },
        [stopPropagation]
    );

    const handleCloseUpdatePassword = React.useCallback(
        (e) => {
            stopPropagation(e);
            setOpenChangePassword(false);
        },
        [stopPropagation]
    );

    const handleIncomingClosed = React.useCallback(
        (e) => {
            stopPropagation(e);
            setIsShowIncomingBookings(false);
            setInComingBookings(null);
        },
        [stopPropagation]
    );

    return (
        <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
            {isHasServiceCommission && isShowStaffCommission && (
                <Grid item>
                    <Tooltip placement="top" title="Show staff commission">
                        <IconButton size="small" onClick={handleOpenCommission}>
                            <CommissionIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            )}
            {isHavingInternalPermission && isPhoneValid && (
                <Grid item>
                    <Tooltip placement="top" title="Setup staff internal password">
                        <IconButton size="small" onClick={handleOpenUpdatePassword}>
                            <PassIcon color={rowData?.user?.isSecured ? 'primary' : 'default'} />
                        </IconButton>
                    </Tooltip>
                </Grid>
            )}
            {!isBlockedStaff && (
                <Grid item>
                    <Tooltip placement="top" title="Deactivate staff">
                        <IconButton size="small" onClick={handleDeactivateClick}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            )}
            {isBlockedStaff && (
                <Grid item>
                    <Tooltip placement="top" title="Activate staff">
                        <IconButton size="small" onClick={handleActivateClick}>
                            <CheckIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            )}

            <ConfirmDialog
                open={openDeactivateConfirm}
                title={`Deactivate staff`}
                text={`${t(`are_you_sure_to_deactivate`)} <strong>${rowData.name}</strong>?`}
                onAgree={handleAgree}
                onDisagree={handleDisagree}
            />
            <ConfirmDialog
                open={openActivateConfirm}
                title={`Activate staff`}
                text={`${t(`are_you_sure_to_activate`)} <strong>${rowData.name}</strong>?`}
                onAgree={handleAgreeActivate}
                onDisagree={handleDisagreeActivate}
            />
            <Dialog
                disableEnforceFocus
                maxWidth={false}
                open={openChangePassword}
                onClose={handleCloseUpdatePassword}
                fullWidth={false}
            >
                <ChangeStaffCredential staff={rowData} onClose={handleCloseUpdatePassword} />
            </Dialog>

            <Dialog onClick={stopPropagation} open={isShowIncomingBookings} onClose={handleIncomingClosed}>
                <IncomingBookings bookings={incomingBookings} onClose={handleIncomingClosed} />
            </Dialog>

            <Dialog
                disableEnforceFocus
                maxWidth={false}
                open={openStaffServiceCommission}
                onClose={handleCloseCommission}
                fullWidth={false}
            >
                <StaffServiceCommission staff={rowData} onClose={handleCloseCommission} />
            </Dialog>
        </Grid>
    );
};

const ActionsMemo = React.memo(Actions);

function StaffPhone({ rowData, isHavingInternalPermission }) {
    const theme = useTheme();
    const phone = rowData?.user?.phone;
    const isPhoneValid = isValidPhone(phone);

    if (!isHavingInternalPermission) return <PhoneFormat number={phone} />;

    return (
        <Grid container direction="row" alignItems="center" spacing={1}>
            <Grid item>
                <PhoneFormat number={phone} />
            </Grid>
            <Grid item>
                {!phone ? (
                    <Tooltip placement="top" title={`please add phone to use internal feature`}>
                        <div>
                            <InfoIcon
                                style={{
                                    color: theme.colors.sun
                                }}
                            />
                        </div>
                    </Tooltip>
                ) : !isPhoneValid ? (
                    <Tooltip placement="top" title={`${phone} is not valid, please update`}>
                        <div>
                            <InfoIcon
                                style={{
                                    color: theme.colors.sun
                                }}
                            />
                        </div>
                    </Tooltip>
                ) : null}
            </Grid>
        </Grid>
    );
}

const STAFF_STATE = {
    ACTIVE: UserState.Active,
    DISABLE: UserState.Disable
};

function StaffMembers() {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const { isMobile } = useMediaQueries();
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [isEdit, setIsEdit] = React.useState(false);
    const [editedStaff, setEditedStaff] = React.useState({});
    const isFetchingStaff = useIsFetchingStaff();
    const staffList = useStaffList();
    const isHavingInternalPermission = useIsHavingInternalPermission();
    const isHasServiceCommission = useIsHavingPermission(MERCHANT_PERMISSIONS.SERVICE_COMMISSION);
    const [currentState, setCurrentState] = React.useState(STAFF_STATE.ACTIVE);
    const [isShowStaffCommission, setIsShowStaffCommission] = React.useState(false);
    const [openConfirmPassword, setOpenConfirmPassword] = React.useState(false);
    const [search, setSearch] = React.useState('');

    const [password, setPassword] = React.useState('');
    const [error, setError] = React.useState('');
    const [isLoading, setIsLoading] = React.useState('');

    React.useEffect(() => {
        dispatch(
            staffActions.getStaff({
                errorCallback: () => {
                    console.log('failed to fetch staff');
                },
                successCallback: () => {
                    console.log('fetch staff successfully');
                }
            })
        );
    }, [dispatch]);

    React.useEffect(() => {
        dispatch(
            serviceActions.getServices({
                errorCallback: () => {
                    console.log('failed to get staff');
                }
            })
        );
    }, [dispatch]);

    const columns = React.useMemo(
        () => [
            { id: 'avatar', label: t(`avatar`), minWidth: 20, format: (props) => <AvatarStaff {...props} /> },
            {
                id: 'name',
                label: t(`name`),
                minWidth: 100,
                format: (props) => {
                    return (
                        <>
                            <Typography variant="body2" display="inline">
                                {props?.rowData?.name}
                            </Typography>{' '}
                            {props?.rowData?.userType === USER_TYPES.Receptionist && (
                                <Typography variant="subtitle2" display="inline" style={{ color: theme.colors.sun }}>
                                    (Receptionist)
                                </Typography>
                            )}
                            {props?.rowData?.staffState === STAFF_STATE.DISABLE && (
                                <Typography variant="body2" display="inline" style={{ color: theme.colors.red }}>
                                    (Blocked)
                                </Typography>
                            )}
                        </>
                    );
                }
            },
            {
                id: 'phone',
                label: t(`mobile_number`),
                minWidth: 100,
                format: (props) => <StaffPhone isHavingInternalPermission={isHavingInternalPermission} {...props} />
            },
            // {
            //     id: 'staffState',
            //     label: t(`status`),
            //     minWidth: 100,
            //     format: (props) => (
            //         <Typography variant="body2">
            //             {props?.rowData?.staffState === STAFF_STATE.ACTIVE ? 'Active' : 'Blocked'}
            //         </Typography>
            //     )
            // },
            {
                id: 'enableAppointment',
                label: t(`appointments`),
                minWidth: 170,
                format: (props) => (
                    <Typography variant="body2">
                        {props.rowData?.enableAppointment ? 'enable online booking' : ``}
                    </Typography>
                )
            },
            {
                id: 'action',
                label: 'Actions',
                minWidth: 20,
                format: (props) => (
                    <ActionsMemo
                        isShowStaffCommission={isShowStaffCommission}
                        isHasServiceCommission={isHasServiceCommission}
                        isHavingInternalPermission={isHavingInternalPermission}
                        {...props}
                        t={t}
                    />
                )
            }
        ],
        [isHasServiceCommission, isHavingInternalPermission, t, theme, isShowStaffCommission]
    );

    const memberFormatted = React.useMemo(() => {
        return staffList
            ?.filter((staff) => {
                const isMatchState = !staff.isDeleted && staff.staffState === currentState;
                const isMatchSearch =
                    getFullName(staff)?.toLowerCase()?.includes(search?.toLowerCase()) ||
                    staff?.user?.phone?.includes(search?.toLowerCase());

                return isMatchState && isMatchSearch;
            })
            ?.map((staff) => {
                return {
                    ...staff,
                    name: getFullName(staff)
                };
            });
    }, [staffList, currentState, search]);

    const handleOpenAddStaff = () => {
        setIsEdit(false);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const headerRenderer = ({ column }) => {
        return <Typography variant="body1">{column.label}</Typography>;
    };

    const onRowClicked = ({ rowData }) => {
        if (rowData.staffState === STAFF_STATE.DISABLE) return;
        setIsEdit(true);
        setOpen(true);
        setEditedStaff(rowData);
    };

    const handleDragEnd = React.useCallback(
        (result) => {
            const { destination, source, draggableId } = result;
            if (!destination) {
                return;
            }
            if (destination.droppableId === source.droppableId && destination.index === source.index) {
                return;
            }

            let beforeStaffId = null;
            let afterStaffId = null;
            const oldIndex = memberFormatted?.findIndex((stf) => +stf?.id === +draggableId);

            const newIndex = destination?.index;
            if (newIndex === 0) {
                beforeStaffId = memberFormatted?.[0]?.id;
            } else {
                let index = newIndex > oldIndex ? newIndex : Math.max(0, newIndex - 1);
                afterStaffId = memberFormatted?.[index]?.id;
            }

            dispatch(
                staffActions.reOrderStaff({
                    afterStaffId,
                    beforeStaffId,
                    dragId: +draggableId,
                    dropIndex: destination?.index,
                    successCallback: () => {
                        console.log('reorder success');
                    },
                    errorCallback: () => {
                        console.log('reorder failed');
                    }
                })
            );
        },
        [dispatch, memberFormatted]
    );

    const handleOnOffStaffCommission = React.useCallback((e) => {
        if (e?.target?.checked) {
            setOpenConfirmPassword(true);
        } else {
            setIsShowStaffCommission(false);
        }
    }, []);

    const handleCheckPassword = React.useCallback(async () => {
        setIsLoading(true);
        setError('');
        try {
            const isValid = await checkPasswordApi({
                body: {
                    password
                }
            });
            setIsLoading(false);
            if (!isValid) {
                setError('Password do not match');
                return;
            }
            setIsShowStaffCommission(true);
            setOpenConfirmPassword(false);
        } catch (error) {
            setError(error?.message?.[0]);
            setIsLoading(false);
        }
    }, [password]);

    const handleCloseConfirmShowMoney = React.useCallback(() => {
        setOpenConfirmPassword(false);
        setPassword('');
        setError('');
    }, []);

    return (
        <LayoutTabContent isLoading={staffList === null && isFetchingStaff}>
            <Grid container className={classes.root} direction="column" wrap="nowrap">
                <Grid item className={isMobile ? classes.spacing : ''}>
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        wrap="nowrap"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Grid item className={classes.search}>
                            <Search
                                placeholder={'Search staff'}
                                onChange={(e) => setSearch(e?.target?.value)}
                                value={search}
                            />
                        </Grid>

                        <Grid item>
                            <Grid container alignItems="center" wrap="nowrap" direction="row" spacing={3}>
                                {isHasServiceCommission && (
                                    <Grid item>
                                        <Grid container spacing={1} alignItems="center">
                                            <Grid item>
                                                <IOSSwitch
                                                    checked={isShowStaffCommission}
                                                    onChange={handleOnOffStaffCommission}
                                                    name="checkedB"
                                                    color="primary"
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body1">Allow Full Access</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}

                                <Grid item>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item>
                                            <IOSSwitch
                                                checked={currentState}
                                                onChange={() => {
                                                    if (currentState === STAFF_STATE.ACTIVE) {
                                                        setCurrentState(STAFF_STATE.DISABLE);
                                                    } else {
                                                        setCurrentState(STAFF_STATE.ACTIVE);
                                                    }
                                                }}
                                                name="checkedB"
                                                color="secondary"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body1">Show deleted staff</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Button
                                        onClick={handleOpenAddStaff}
                                        startIcon={<AddIcon />}
                                        variant="contained"
                                        color="primary"
                                    >
                                        New staff
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.table}>
                    <SimpleBar style={{ maxHeight: '100%' }}>
                        <StickyHeadTable
                            classNameContainer={classes.container}
                            headerRenderer={headerRenderer}
                            rows={memberFormatted}
                            columns={columns}
                            onRowClicked={onRowClicked}
                            isDragDisabled={false}
                            onDragEnd={handleDragEnd}
                        />
                    </SimpleBar>
                </Grid>
                <Dialog fullScreen open={open} onClose={handleClose}>
                    <AddMember isEdit={isEdit} editedStaff={editedStaff} handleClose={handleClose} />
                </Dialog>
                <Dialog maxWidth={false} open={openConfirmPassword} onClose={handleCloseConfirmShowMoney}>
                    <RequirePasswordForm
                        password={password}
                        onPasswordChange={(e) => setPassword(e?.target?.value)}
                        isLoading={isLoading}
                        onCheckPassword={handleCheckPassword}
                        error={error}
                        title="Show Staff Commission"
                        subtitle="Type password to enable staff commission"
                    />
                </Dialog>
            </Grid>
        </LayoutTabContent>
    );
}

export default React.memo(StaffMembers);
