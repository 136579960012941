import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, Popover, Dialog } from '@material-ui/core';
import { mapTimeToTime12WithoutAMPm, convertDurationAndStartTimeToDate, convertTimeToFloat } from 'utils/timing';
import AddBlockedTime from 'views/calender/calendar/AddBlockedTime';
import { useMerchantTime12 } from 'hooks/merchant';
import { useRatio } from 'hooks/calendar';

const useStyles = makeStyles((theme) => ({
    root: {},
    timeBookingBlockedTime: {
        fontSize: 11,
        color: 'white',
        [theme.breakpoints.down('xs')]: {
            fontSize: 9
        }
    },
    dialog: {
        padding: 0
    },
    fullScreen: {
        '& .MuiPopover-paper': {
            top: `0 !important`,
            left: `0 !important`,
            width: '100vw',
            height: '100vh',
            minWidth: '100%',
            minHeight: '100%',
            maxWidth: '100%',
            maxHeight: '100%'
        }
    },
    popoverPaper: {
        borderRadius: 0
    }
}));

function BlockedTime({ isMobile, order, height, onUpdateOrCreatingSuccess, isDisabledWhenMovingBooking }) {
    const classes = useStyles();
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const TIME_12 = useMerchantTime12();
    const RATIO = useRatio();

    const handleClick = React.useCallback((event) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleClose = React.useCallback(() => {
        setAnchorEl(null);
    }, []);

    const open = Boolean(anchorEl);
    const id = open ? `booking-popover` : undefined;

    if (!order) return null;

    return (
        <>
            <Grid
                style={{
                    width: '100%',
                    height,
                    overflowY: 'hidden',
                    paddingLeft: 4,
                    opacity: isDisabledWhenMovingBooking ? 0.8 : 1,
                    background: theme.colors.gunPower,
                    // marginLeft: 1,
                    boxSizing: 'border-box',
                    borderRadius: 4
                    // borderLeft: `5px solid ${theme.colors.gunPower}`
                }}
                container
                direction="column"
                wrap="nowrap"
                onClick={isDisabledWhenMovingBooking ? () => {} : handleClick}
            >
                <Grid item>
                    <Typography variant="body2" className={classes.timeBookingBlockedTime} align="left">
                        {`${TIME_12 ? mapTimeToTime12WithoutAMPm(order?.slot?.timeStart) : order?.slot?.timeStart} - ${
                            TIME_12
                                ? mapTimeToTime12WithoutAMPm(
                                      convertDurationAndStartTimeToDate(
                                          convertTimeToFloat(order?.slot?.timeStart),
                                          height * (1 / RATIO)
                                      )
                                  )
                                : convertDurationAndStartTimeToDate(
                                      convertTimeToFloat(order?.slot?.timeStart),
                                      height * (1 / RATIO)
                                  )
                        }`}
                        (blocked)
                    </Typography>
                </Grid>
                <Grid item>
                    <Grid container direction="column">
                        <Grid item>
                            <Grid container alignItems="center">
                                <Grid item>
                                    <Typography style={{ color: 'white' }} variant="body2" align="left">
                                        {order.description}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {/* </Tooltip> */}

            {isMobile ? (
                <Dialog maxWidth={false} className={classes.dialog} open={open} onClose={handleClose}>
                    <AddBlockedTime
                        onClose={handleClose}
                        isEdit={true}
                        blockedTime={order}
                        isDialog={true}
                        onUpdateOrCreatingSuccess={onUpdateOrCreatingSuccess}
                    />
                </Dialog>
            ) : (
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    className={isMobile ? classes.fullScreen : ''}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'right'
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'left'
                    }}
                    classes={{ paper: classes.popoverPaper }}
                >
                    <AddBlockedTime
                        onClose={handleClose}
                        isEdit={true}
                        blockedTime={order}
                        onUpdateOrCreatingSuccess={onUpdateOrCreatingSuccess}
                    />
                </Popover>
            )}
        </>
    );
}

export default BlockedTime;
