import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import LayoutTabContent from 'views/layout/LayoutTabContent';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {}
}));

function GenerateStaticPage() {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <LayoutTabContent isLoading={true}>
            <Grid container className={classes.root} direction="column" spacing={4}>
                <Grid item>
                    <Typography variant="body2">{t(`generate_static_page`)}</Typography>
                </Grid>
            </Grid>
        </LayoutTabContent>
    );
}

export default React.memo(GenerateStaticPage);
