export const FETCH_STAFF = '[STAFF]FETCH_STAFF';
export const FETCH_STAFF_SUCCESS = '[STAFF]FETCH_STAFF_SUCCESS';
export const FETCH_STAFF_FAILED = '[STAFF]FETCH_STAFF_FAILED';

export const CREATE_STAFF = '[STAFF]CREATE_STAFF';
export const CREATE_STAFF_SUCCESS = '[STAFF]CREATE_STAFF_SUCCESS';
export const CREATE_STAFF_FAILED = '[STAFF]CREATE_STAFF_FAILED';

export const UPDATE_STAFF = '[STAFF]UPDATE_STAFF';
export const UPDATE_STAFF_SUCCESS = '[STAFF]UPDATE_STAFF_SUCCESS';
export const UPDATE_STAFF_FAILED = '[STAFF]UPDATE_STAFF_FAILED';

export const DELETE_STAFF = '[STAFF]DELETE_STAFF';
export const DELETE_STAFF_SUCCESS = '[STAFF]DELETE_STAFF_SUCCESS';
export const DELETE_STAFF_FAILED = '[STAFF]DELETE_STAFF_FAILED';

export const FETCH_STAFF_WORKING_HOURS = '[STAFF]FETCH_STAFF_WORKING_HOURS';
export const FETCH_STAFF_WORKING_HOURS_SUCCESS = '[STAFF]FETCH_STAFF_WORKING_HOURS_SUCCESS';
export const FETCH_STAFF_WORKING_HOURS_FAILED = '[STAFF]FETCH_STAFF_WORKING_HOURS_FAILED';

export const CREATE_STAFF_WORKING_HOURS = '[STAFF]CREATE_STAFF_WORKING_HOURS';
export const CREATE_STAFF_WORKING_HOURS_SUCCESS = '[STAFF]CREATE_STAFF_WORKING_HOURS_SUCCESS';
export const CREATE_STAFF_WORKING_HOURS_FAILED = '[STAFF]CREATE_STAFF_WORKING_HOURS_FAILED';

export const DELETE_STAFF_WORKING_HOURS = '[STAFF]DELETE_STAFF_WORKING_HOURS';
export const DELETE_STAFF_WORKING_HOURS_SUCCCESS = '[STAFF]DELETE_STAFF_WORKING_HOURS_SUCCCESS';
export const DELETE_STAFF_WORKING_HOURS_FAILED = '[STAFF]DELETE_STAFF_WORKING_HOURS_FAILED';

export const UPDATE_STAFF_WORKING_HOURS = '[STAFF]UPDATE_STAFF_WORKING_HOURS';
export const UPDATE_STAFF_WORKING_HOURS_SUCCCESS = '[STAFF]UPDATE_STAFF_WORKING_HOURS_SUCCCESS';
export const UPDATE_STAFF_WORKING_HOURS_FAILED = '[STAFF]UPDATE_STAFF_WORKING_HOURS_FAILED';

export const UPDATE_STAFF_AVATAR = '[STAFF]UPDATE_STAFF_AVATAR';

export const RE_ORDER_STAFF = '[STAFF]RE_ORDER_STAFF';

/**
 * CLOSED DATES
 */
export const FETCH_CLOSE_DATE = '[CLOSE-DATE]FETCH_CLOSE_DATE';
export const FETCH_CLOSE_DATE_FAILED = '[CLOSE-DATE]FETCH_CLOSE_DATE_FAILED';
export const FETCH_CLOSE_DATE_SUCCESS = '[CLOSE-DATE]FETCH_CLOSE_DATE_SUCCESS';

export const ADD_CLOSE_DATE = '[CLOSE-DATE]ADD_CLOSE_DATE';
export const ADD_CLOSE_DATE_FAILED = '[CLOSE-DATE]ADD_CLOSE_DATE_FAILED';
export const ADD_CLOSE_DATE_SUCCESS = '[CLOSE-DATE]ADD_CLOSE_DATE_SUCCESS';

export const UPDATE_CLOSE_DATE = '[CLOSE-DATE]UPDATE_CLOSE_DATE';
export const UPDATE_CLOSE_DATE_FAILED = '[CLOSE-DATE]UPDATE_CLOSE_DATE_FAILED';
export const UPDATE_CLOSE_DATE_SUCCESS = '[CLOSE-DATE]UPDATE_CLOSE_DATE_SUCCESS';

export const DELETE_CLOSE_DATE = '[CLOSE-DATE]DELETE_CLOSE_DATE';
export const DELETE_CLOSE_DATE_FAILED = '[CLOSE-DATE]DELETE_CLOSE_DATE_FAILED';
export const DELETE_CLOSE_DATE_SUCCESS = '[CLOSE-DATE]DELETE_CLOSE_DATE_SUCCESS';

export const SET_STAFF_PASSWORD = '[STAFF]SET_STAFF_PASSWORD';
export const UPDATE_STAFF_PASSWORD = '[STAFF]UPDATE_STAFF_PASSWORD';

export const SET_STAFF_STATE = '[STAFF]SET_STAFF_STATE';

export const UPDATE_SERVICE_STAFF = '[STAFF]UPDATE_SERVICE_STAFF';
export const FETCH_STAFF_AND_CLOSED_DATES_SUCCESS = '[STAFF]FETCH_STAFF_AND_CLOSED_DATES_SUCCESS';
