import * as types from './types';

const INITIAL_STATE = {
    serviceList: null,
    categoryList: null,
    isFetchingServices: false,
    isFetchingCategories: false
};

export default function Service(state = INITIAL_STATE, { type, payload }) {
    switch (type) {
        case types.FETCH_SERVICES:
        case types.FETCH_SERVICES_FAILED: {
            return {
                ...state,
                isFetchingServices: true
            };
        }

        case types.FETCH_SERVICES_SUCCESS: {
            const { services } = payload;
            return {
                ...state,
                isFetchingServices: false,
                serviceList: services
            };
        }

        case types.CREATE_SERVICE_SUCCESS: {
            const { service } = payload;
            const { serviceList } = state;
            return {
                ...state,
                serviceList: [service, ...serviceList]
            };
        }

        case types.UPDATE_SERVICE_SUCCESS: {
            const { service, id } = payload;
            const { serviceList } = state;
            const newServiceList = serviceList.map((sv) => {
                if (sv.id === id) return service;
                return sv;
            });
            return {
                ...state,
                serviceList: newServiceList
            };
        }

        case types.DELETE_SERVICE: {
            const { id } = payload;
            const { serviceList } = state;
            const newServiceList = serviceList.map((sv) => {
                if (sv.id === id) {
                    sv.isDeleted = true;
                }
                return sv;
            });
            return {
                ...state,
                serviceList: newServiceList
            };
        }

        case types.DELETE_SERVICE_FAILED: {
            const { id } = payload;
            const { serviceList } = state;
            const newServiceList = serviceList.map((sv) => {
                if (sv.id === id) {
                    sv.isDeleted = false;
                }
                return sv;
            });
            return {
                ...state,
                serviceList: newServiceList
            };
        }

        case types.DELETE_SERVICE_SUCCESS: {
            const { id } = payload;
            const { serviceList } = state;
            const newServiceList = serviceList.filter((sv) => sv.id !== id);
            return {
                ...state,
                serviceList: newServiceList
            };
        }

        case types.FETCH_CATEGORIES: {
            return {
                ...state,
                isFetchingCategories: true
            };
        }

        case types.FETCH_CATEGORIES_FAILED: {
            return {
                ...state,
                isFetchingCategories: false
            };
        }

        case types.FETCH_CATEGORIES_SUCCESS: {
            const { categories } = payload;
            return {
                ...state,
                isFetchingCategories: false,
                categoryList: categories
            };
        }

        case types.CREATE_CATEGORY_SUCCESS: {
            const { category } = payload;
            const { categoryList } = state;
            return {
                ...state,
                categoryList: [category, ...categoryList]
            };
        }

        case types.UPDATE_CATEGORY_SUCCESS: {
            const { category, id } = payload;
            const { categoryList } = state;
            const newCategoryList = categoryList.map((ct) => {
                if (ct.id === id)
                    return {
                        ...ct,
                        ...category
                    };
                return ct;
            });
            return {
                ...state,
                categoryList: newCategoryList
            };
        }

        case types.DELETE_CATEGORY: {
            const { id } = payload;
            const { categoryList } = state;
            const newCategoryList = categoryList.map((ct) => {
                if (ct.id === id) {
                    ct.isDeleted = true;
                }
                return ct;
            });
            return {
                ...state,
                categoryList: newCategoryList
            };
        }

        case types.DELETE_CATEGORY_FAILED: {
            const { id } = payload;
            const { categoryList } = state;
            const newCategoryList = categoryList.map((ct) => {
                if (ct.id === id) {
                    ct.isDeleted = false;
                }
                return ct;
            });
            return {
                ...state,
                categoryList: newCategoryList
            };
        }

        case types.DELETE_CATEGORY_SUCCESS: {
            const { id } = payload;
            const { categoryList } = state;
            const newCategoryList = categoryList.filter((ct) => ct.id !== id);
            return {
                ...state,
                categoryList: newCategoryList
            };
        }

        case types.UPDATE_SERVICE_AVATAR: {
            const { avatar, serviceId } = payload;
            const { serviceList } = state;
            const newList = serviceList.map((service) => {
                if (+service.userId === +serviceId) {
                    service.avatar = avatar;
                }
                return service;
            });
            return {
                ...state,
                list: newList
            };
        }

        case types.REORDER_CATEGORY: {
            const { categories } = payload;

            return {
                ...state,
                categoryList: categories
            };
        }

        case types.REORDER_SERVICE: {
            const { services } = payload;

            return {
                ...state,
                serviceList: services
            };
        }

        case types.ADD_SERVICE_TO_CATEGORY: {
            const { ctId, service } = payload;

            const newCategories = state?.categoryList?.map((ct) => {
                if (+ct?.id === ctId) {
                    const oldServices = ct?.services || [];
                    ct.services = [service, ...oldServices];
                }
                return ct;
            });

            return {
                ...state,
                categoryList: newCategories
            };
        }

        case types.UPDATE_SERVICE_TO_CATEGORY: {
            const { ctId, sId, service } = payload;

            const newCategories = state?.categoryList?.map((ct) => {
                if (+ct?.id === ctId) {
                    ct.services = ct?.services?.map((sv) => {
                        if (+sv?.id === sId) return service;
                        return sv;
                    });
                }
                return ct;
            });

            return {
                ...state,
                categoryList: newCategories
            };
        }

        case types.UPDATE_AVATAR_SERVICE_TO_CATEGORY: {
            const { ctId, sId, avatar } = payload;

            const newCategories = state?.categoryList?.map((ct) => {
                if (+ct?.id === ctId) {
                    ct.services = ct?.services?.map((sv) => {
                        if (+sv?.id === sId) {
                            sv.avatar = avatar;
                        }
                        return sv;
                    });
                }
                return ct;
            });

            return {
                ...state,
                categoryList: newCategories
            };
        }

        case types.DELETE_SERVICE_TO_CATEGORY: {
            const { ctId, sId } = payload;

            const newCategories = state?.categoryList?.map((ct) => {
                if (+ct?.id === ctId) {
                    ct.services = ct?.services?.filter((service) => +service?.id !== sId);
                }
                return ct;
            });

            return {
                ...state,
                categoryList: newCategories
            };
        }

        case types.RE_ORDER_SERVICE_TO_CATEGORY: {
            const { ctId, services } = payload;

            const newCategories = state?.categoryList?.map((ct) => {
                if (+ct?.id === ctId) {
                    ct.services = services;
                }
                return ct;
            });

            return {
                ...state,
                categoryList: newCategories
            };
        }

        case types.FETCH_SERVICE_CATEGORIES_SUCCESS: {
            const { categories, services } = payload;

            return {
                ...state,
                categoryList: categories,
                serviceList: services
            };
        }

        default:
            return state;
    }
}
