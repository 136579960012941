export const FETCH_MERCHANT_ANALYZE_RANGE_BY_ID = '[ANALYZE]FETCH_MERCHANT_ANALYZE_RANGE_BY_ID';
export const FETCH_ACTIVE_MERCHANTS = `[SETTINGS]FETCH_ACTIVE_MERCHANTS`;
export const UPDATE_MERCHANT = `[SETTINGS]UPDATE_MERCHANT`;
export const CREATE_MERCHANT = `[SETTINGS]CREATE_MERCHANT`;
export const UPLOAD_MERCHANT_LOGO = `[SETTINGS]UPLOAD_MERCHANT_LOGO`;

export const FETCH_GROUPS = `[GROUP]FETCH_GROUPS`;
export const DELETE_MERCHANT_GROUP = `[GROUP]DELETE_MERCHANT_GROUP`;
export const CREATE_MERCHANT_GROUP = `[GROUP]CREATE_MERCHANT_GROUP`;
export const CREATE_GROUP = `[SETTINGS]CREATE_GROUP`;
export const UPDATE_GROUP = `[SETTINGS]UPDATE_GROUP`;
export const DELETE_GROUP = `[SETTINGS]DELETE_GROUP`;

export const FETCH_BOOKING = `[SETTINGS]FETCH_BOOKING`;
