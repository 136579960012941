import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useSelectedServices, useSelectedStaff, useIsMerchantHasPermission, useStaffDurationMap } from 'hooks/checkIn';
import { getFullName } from 'utils/naming';
import { convertDurationToHrsMins } from 'utils/timing';
import Avatar from 'components/avatar';
import AvatarIconSVG from 'assets/images/svg/AvatarIconSVG';
import { getImageUrl } from 'utils/image';
import { DEFAULT_CURRENCY, MERCHANT_PERMISSIONS } from 'const';
import { useMerchantDetail } from 'hooks/merchant';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    pRoot: {
        alignSelf: 'baseline',
        width: '100%',
        background: theme.palette.common.white,
        borderRadius: 8,
        boxShadow: theme.shadows[1],
        position: 'sticky',
        top: 82,
        left: 0,
        zIndex: 100
    },
    PTotalDescription: {
        position: 'relative',
        padding: `60px 32px 24px`
        // borderBottom: `1px solid rgb(231, 232, 233)`
    },
    pTextCenter: {
        textAlign: 'center'
    },
    pAvatar: {
        position: 'absolute',
        width: 60,
        height: 60,
        top: -30,
        left: 0,
        right: 0,
        margin: 'auto',
        padding: 2,
        boxShadow: `rgba(16, 25, 40, 0.08) 0px 0px 16px 0px`,
        border: `4px solid white`
    },
    pServices: {
        minHeight: 100,
        display: 'flex',
        alignItems: 'center',
        borderBottom: `1px solid rgb(231, 232, 233)`,
        borderTop: `1px solid rgb(231, 232, 233)`
    },
    pServiceItem: {
        width: '100%',
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        minHeight: 65,
        paddingLeft: 32,
        paddingRight: 32
    },
    pDatetime: {
        padding: 20
    },
    pTotal: {
        padding: `24px 32px`
    },
    pTitleService: {
        fontWeight: 600
    },
    pDateTimeConfirm: {
        backgroundColor: '#f5f5fa',
        marginRight: theme.spacing(2),
        outline: 'none',
        padding: '10px 5px',
        maxWidth: '64px',
        background: '#FFFFFF',
        textAlign: 'center',
        borderRadius: '8px'
    }
}));

function PricingTotal() {
    const classes = useStyles();
    const services = useSelectedServices();
    const staff = useSelectedStaff();
    const merchant = useMerchantDetail();
    const isMerchantHasStaffServiceDuration = useIsMerchantHasPermission(MERCHANT_PERMISSIONS.STAFF_SERVICE_DURATION);
    const staffServiceDurationMap = useStaffDurationMap(staff?.id);

    const isSystem = React.useMemo(() => {
        return staff?.isSystem;
    }, [staff]);

    const currency = React.useMemo(() => {
        return merchant?.currency || DEFAULT_CURRENCY;
    }, [merchant]);

    const totalPrice = React.useMemo(() => {
        return (
            services?.reduce((t, sv) => {
                const { prices } = sv;
                const price = prices?.[0];
                t += price?.retailPrice || 0;
                return t;
            }, 0) || 0
        );
    }, [services]);

    return (
        <Grid container className={classes.pRoot} direction="column">
            <Grid item className={classes.PTotalDescription}>
                {/* <Avatar className={classes.avatar} variant="rounded" src={merchant?.logo} /> */}
                <Avatar size="medium" className={classes.pAvatar} variant="rounded" src={getImageUrl(merchant?.logo)}>
                    <AvatarIconSVG style={{ width: '100%', height: '100%' }} />
                </Avatar>
                <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    spacing={2}
                    direction="column"
                    wrap="nowrap"
                >
                    <Grid item>
                        <Typography variant="h4">{merchant?.title}</Typography>
                    </Grid>
                    <Grid item className={classes.pTextCenter}>
                        <Typography variant="subtitle1">{merchant?.address}</Typography>
                        <Typography variant="caption" display="block">
                            {merchant?.phone}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item className={classes.pServices}>
                <Grid container direction="column" wrap="nowrap" alignItems="center">
                    {services.length === 0 && (
                        <Typography style={{ opacity: 0.7 }} variant="subtitle1">
                            There is no service yet
                        </Typography>
                    )}
                    {services.length > 0 &&
                        services.map((service, index) => {
                            const price = service?.prices?.[0];
                            const duration = isMerchantHasStaffServiceDuration
                                ? staffServiceDurationMap?.[service?.id]
                                : price?.duration;

                            return (
                                <Grid key={index} item className={classes.pServiceItem}>
                                    <Grid
                                        container
                                        alignItems="flex-start"
                                        justifyContent="space-between"
                                        wrap="nowrap"
                                    >
                                        <Grid item>
                                            <Grid container direction="column" wrap="nowrap">
                                                <Grid item>
                                                    <Typography variant="body2" className={classes.pTitleService}>
                                                        {service.title}
                                                    </Typography>
                                                </Grid>
                                                {!(isMerchantHasStaffServiceDuration && (isSystem || !staff)) && (
                                                    <Grid item>
                                                        <Typography variant="subtitle1" display="inline">
                                                            {convertDurationToHrsMins(duration || 0)}{' '}
                                                        </Typography>

                                                        <Typography variant="subtitle1" display="inline">
                                                            {staff
                                                                ? staff?.isSystem
                                                                    ? ''
                                                                    : `with ${getFullName(staff)}`
                                                                : ''}
                                                        </Typography>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body2">
                                                {currency}
                                                {price?.retailPrice || 0}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            );
                        })}
                </Grid>
            </Grid>
            <Grid item className={classes.pTotal}>
                <Grid container direction="row" justifyContent="space-between" wrap="nowrap">
                    <Grid item>
                        <Typography variant="body1">Total</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1">
                            {currency}
                            {totalPrice}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(PricingTotal);
