import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { genderOptions } from 'data/client';
import NativeSelect from 'components/select/NativeForm';
import InputBase from 'components/input/Form';
import { useTranslation } from 'react-i18next';
// import PortalDatePicker from 'components/datepicker/PortalReactDatePickerForm';
import DatePicker from 'react-datepicker';

const useStyles = makeStyles((theme) => ({
    root: {},
    label: {
        fontSize: 14,
        lineHeight: `1.75`,
        marginBottom: 4,
        fontWeight: 600
    }
}));

function PersonalInfo({
    values,
    errors,
    touched,
    gender,
    handleChange,
    handleBlur,
    isSubmitting,
    handleGenderChange,
    startBirthDatePresent,
    birthDay,
    handleDateOfBirthChange
}) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Grid container className={classes.root} direction="column" spacing={3}>
            <Grid item>
                <NativeSelect
                    onChange={(e) => handleGenderChange(e.target.value)}
                    selectedValue={gender}
                    options={genderOptions}
                    label={t(`gender`)}
                    placeholder={`Choose an option`}
                />
            </Grid>
            <Grid item>
                <Grid container direction="column">
                    <Grid item>
                        <div className={classes.label}>{'Date of birth'}</div>
                    </Grid>
                    <Grid item style={{ borderBottom: `1px solid rgb(238, 238, 238)` }}>
                        <DatePicker
                            showYearDropdown
                            yearDropdownItemNumber={50}
                            scrollableYearDropdown
                            selected={birthDay ?? null}
                            onChange={(date) => {
                                console.log('date', date);
                                handleDateOfBirthChange(date);
                            }}
                            placeholderText={`Input date`}
                        />
                    </Grid>
                </Grid>
                {/* <PortalDatePicker
                    className={classes.minWidth}
                    defaultValue={birthDay}
                    label={'Date of birth'}
                    placeholderText={t(`closed_date_start_date_placeholder`)}
                    defaultDatePresent={startBirthDatePresent ? startBirthDatePresent : 'Selected date'}
                    isUsedDatePresent={true}
                    showYearDropdown
                    yearDropdownItemNumber={50}
                    scrollableYearDropdown
                /> */}
            </Grid>

            <Grid item>
                <InputBase
                    multiline
                    rows={4}
                    label={t(`notes`)}
                    name="note"
                    value={values.note || ''}
                    disabled={isSubmitting}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.note && touched.note}
                    errorText={errors.note}
                />
            </Grid>
        </Grid>
    );
}

export default React.memo(PersonalInfo);
