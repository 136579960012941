import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { string, object, number, boolean } from 'yup';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';

import VoucherVerticalPreview from '../VoucherPV2';
import InputBase from 'components/input/Form';
import NativeSelect from 'components/select/NativeForm';
import IOSSwitch from 'components/switch/IOS';
import Button from 'components/button/Base';
import * as voucherActions from '../action';
import { EXPIRATION_PERIOD_OPTIONS, PERCENT_OPTIONS, NUMBER_OF_SALE_OPTIONS } from 'data/vouchers';
import DialogActions from 'components/dialog/Action';
import DialogTitle from 'components/dialog/Title';
import DialogContent from 'components/dialog/Content';
import { getImageUrl } from 'utils/image';
import AddIcon from '@material-ui/icons/AddCircle';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';

const useStyles = makeStyles((theme) => ({
    spacing: {
        marginBottom: 18
    },

    tabTitle: {
        // textTransform: 'uppercase',
        fontWeight: 500
    },
    content: {
        width: '90vw',
        margin: `0 auto`,
        marginTop: 50,
        [theme.breakpoints.down('sm')]: {
            margin: 0,
            width: '100%'
        }
    },

    color: {
        width: 40,
        height: 40,
        borderRadius: '50%'
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    btnSubmit: {
        position: 'relative',
        width: '100%'
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    duration: {
        display: 'flex',
        padding: '20px 0px',
        alignItems: 'center'
    },
    label: {
        marginBottom: 4
    },
    limitAmount: {
        marginLeft: 10
    },
    colorPalette: {
        padding: '20px 0px'
    },
    whiteColor: {
        color: theme.palette.common.white
    }
}));

const VoucherSchema = object({
    name: string().required('This field is required'),
    retailPrice: number().required('This field is required'),
    salePercent: number(),
    expirationPeriod: string(),
    saleLimitEnabled: boolean(),
    saleLimit: number(),
    messageTitle: string(),
    messageDescription: string().max(120, 'Max characters are 120'),
    noteEnabled: boolean(),
    note: string(),
    color: string()
});

function AddVoucher({ isEdit, voucherEdit, onClose }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [file, setFile] = React.useState();
    const [logo, setLogo] = React.useState(isEdit ? getImageUrl(voucherEdit?.logo) : '');

    const formik = useFormik({
        initialValues: {
            name: isEdit ? voucherEdit?.name || `` : '',
            retailPrice: isEdit ? voucherEdit?.retailPrice : 25,
            salePercent: isEdit ? voucherEdit.salePercent : PERCENT_OPTIONS[0]?.value,
            expirationPeriod: isEdit ? voucherEdit?.expirationPeriod : EXPIRATION_PERIOD_OPTIONS[0]?.value,
            saleLimitEnabled: isEdit ? voucherEdit?.saleLimitEnabled : false,
            enableOnline: isEdit ? voucherEdit?.enableOnline : true,
            saleLimit: isEdit ? voucherEdit?.saleLimit : NUMBER_OF_SALE_OPTIONS[0]?.value,
            messageTitle: isEdit ? voucherEdit?.messageTitle || `` : '',
            messageDescription: isEdit ? voucherEdit?.messageDescription : '',
            noteEnabled: isEdit ? voucherEdit?.noteEnabled : false,
            note: isEdit ? voucherEdit?.note || `` : '',
            color: isEdit ? voucherEdit?.color : 'purple'
        },
        validationSchema: VoucherSchema,
        onSubmit: (values, { setSubmitting, setStatus }) => {
            setStatus({ error: null });
            setSubmitting(true);

            if (!isEdit) {
                dispatch(
                    voucherActions.createVoucher({
                        body: values,
                        file,
                        successCallback: () => {
                            onClose();
                        },
                        errorCallback: (error) => {
                            setSubmitting(false);
                        }
                    })
                );
            } else {
                dispatch(
                    voucherActions.updateVoucherWithLogo({
                        file,
                        body: values,
                        voucherId: voucherEdit?.id,
                        successCallback: () => {
                            onClose();
                        },
                        errorCallback: (error) => {
                            setSubmitting(false);
                        }
                    })
                );
            }
            setStatus({ error: null });
        }
    });

    // const handleColorChange = ({ e, color }) => {
    //     setFieldValue('color', color);
    // };

    const handleSalePercentChange = (e) => {
        setFieldValue('salePercent', Number(e.target.value));
    };

    const handleExpirationPeriodChange = (e) => {
        setFieldValue('expirationPeriod', Number(e.target.value));
    };

    const handelSaleLimitEnabledChange = (e) => {
        setFieldValue('saleLimitEnabled', !values?.saleLimitEnabled);
    };

    const handelEnableOnlineChange = (e) => {
        setFieldValue('enableOnline', !values?.enableOnline);
    };

    const handleSaleLimitChange = (e) => {
        setFieldValue('saleLimit', Number(e.target.value));
    };

    const handleUploadFile = ({ file, thumbnail }) => {
        setFile(file);
        setLogo(thumbnail);
    };

    const isHaveNoFile = React.useMemo(() => {
        return !file && !logo;
    }, [file, logo]);

    const {
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        isValid
    } = formik;

    return (
        <form onClick={(e) => e.stopPropagation()} onSubmit={handleSubmit} className={classes.form}>
            <DialogTitle>
                <Typography variant="h5" className={classes.whiteColor}>
                    {isEdit ? 'Edit gift card' : 'Create gift card'}
                </Typography>
            </DialogTitle>
            <DialogContent className={classes.content}>
                <Grid container justifyContent="space-around" direction="row">
                    <Grid item xs={12} md={6} lg={6} className={classes.spacing}>
                        <Grid container direction="column" justifyContent="center">
                            <Grid item>
                                <InputBase
                                    className={`${classes.spacing} ${classes.input}`}
                                    label={t('gift_name')}
                                    placeholder={t('')}
                                    name="name"
                                    value={values.name || ''}
                                    disabled={isSubmitting}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.name && touched.name}
                                    errorText={errors.name}
                                    autoFocus={true}
                                />
                            </Grid>
                            <Grid item>
                                <InputBase
                                    multiline
                                    rowsMax={5}
                                    label={`${t(`description`)} (${values.messageDescription?.length}/120)`}
                                    placeholder={t(`description`)}
                                    name="messageDescription"
                                    value={values.messageDescription || ''}
                                    disabled={isSubmitting}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.messageDescription && touched.messageDescription}
                                    errorText={errors.messageDescription}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </Grid>

                            <Grid item>
                                <Grid container direction="row" spacing={2} wrap="nowrap">
                                    <Grid item xs={12} md={6}>
                                        <InputBase
                                            fullWidth
                                            className={`${classes.spacing} ${classes.input}`}
                                            label={t('value')}
                                            placeholder={t('value_placeholder')}
                                            name="retailPrice"
                                            value={values.retailPrice}
                                            disabled={isSubmitting}
                                            onChange={(e) => {
                                                e.target.value = e.target.value.replace(/\D/, '');
                                                handleChange(e);
                                            }}
                                            onBlur={handleBlur}
                                            error={errors.retailPrice && touched.retailPrice}
                                            errorText={errors.retailPrice}
                                            autoFocus={false}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <NativeSelect
                                            label={`Sale Percent`}
                                            isBorder={true}
                                            options={PERCENT_OPTIONS}
                                            placeholder={t(`choose_an_option`)}
                                            selectedValue={values.salePercent}
                                            onChange={handleSalePercentChange}
                                        />{' '}
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <NativeSelect
                                    label={t(`validation_period`)}
                                    isBorder={true}
                                    options={EXPIRATION_PERIOD_OPTIONS}
                                    placeholder={t(`choose_an_option`)}
                                    selectedValue={values.expirationPeriod}
                                    onChange={handleExpirationPeriodChange}
                                />
                            </Grid>

                            <Grid item className={classes.duration}>
                                <IOSSwitch
                                    color="primary"
                                    checked={values?.enableOnline}
                                    onChange={handelEnableOnlineChange}
                                />
                                <Typography variant="body2" className={`${classes.label} ${classes.limitAmount}`}>
                                    Enable Online
                                </Typography>
                            </Grid>

                            <Grid item className={classes.duration}>
                                <IOSSwitch
                                    color="primary"
                                    checked={values?.saleLimitEnabled}
                                    onChange={handelSaleLimitEnabledChange}
                                />
                                <Typography variant="body2" className={`${classes.label} ${classes.limitAmount}`}>
                                    Limit amount of sales
                                </Typography>
                            </Grid>

                            <Grid item>
                                <NativeSelect
                                    label={`Number of sales`}
                                    isBorder={true}
                                    options={NUMBER_OF_SALE_OPTIONS}
                                    placeholder={t(`choose_an_option`)}
                                    disabled={!values?.saleLimitEnabled}
                                    selectedValue={values?.saleLimit}
                                    onChange={handleSaleLimitChange}
                                />
                            </Grid>
                            {/* <Grid item style={{ paddingTop: 20 }}>
                                <InputBase
                                    label={t(`title`)}
                                    placeholder={t(`title`)}
                                    name="messageTitle"
                                    value={values.messageTitle || ''}
                                    disabled={isSubmitting}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.messageTitle && touched.messageTitle}
                                    errorText={errors.messageTitle}
                                />
                            </Grid> */}

                            {/* <Grid item className={classes.colorPalette}>
                                <ColorPalette
                                    width={30}
                                    height={30}
                                    svg={20}
                                    selectedColor={values?.color}
                                    onChange={handleColorChange}
                                />
                            </Grid> */}

                            {/* <Grid item className={classes.duration}>
                                <IOSSwitch checked={values?.noteEnabled} onChange={handelNoteEnabledChange} />
                                <Typography variant="body2" className={`${classes.label} ${classes.limitAmount}`}>
                                    Enable notes for clients
                                </Typography>
                            </Grid>
                            <Grid item>
                                <InputBase
                                    multiline
                                    rows={8}
                                    label={t(`notes`)}
                                    placeholder={t(`notes_staff_placeholder`)}
                                    name="note"
                                    value={values.note || ''}
                                    disabled={!values?.noteEnabled}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.note && touched.note}
                                    errorText={errors.note}
                                />
                            </Grid> */}
                        </Grid>
                    </Grid>
                    {/* <Hidden smDown> */}
                    <Grid item xs={12} md={6} lg={6} className={classes.preview} container justifyContent="center">
                        <VoucherVerticalPreview {...values} logo={logo} onUpload={handleUploadFile} />
                    </Grid>
                    {/* </Hidden> */}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                    <Grid item>
                        <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
                            {t('close')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={isSubmitting || !isValid || isHaveNoFile}
                            startIcon={isEdit ? <SaveIcon /> : <AddIcon />}
                        >
                            {isEdit ? `Save` : t(`Create`)}
                        </Button>
                    </Grid>
                </Grid>

                {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
            </DialogActions>
        </form>
    );
}
export default AddVoucher;
