import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Typography } from '@material-ui/core';
import NotFoundSVG from 'assets/images/svg/NotFoundSVG';
import Booking from './Booking';
import orderBy from 'lodash/orderBy';
import Search from 'components/search';

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 300,
        background: theme.palette.common.white,
        boxShadow: theme.shadows[1],
        borderRadius: theme.radius(3),
        padding: theme.spacing(3),
        maxHeight: '80vh',
        overflowY: 'auto'
    },
    item: {
        '&:not(:last-child)': {
            marginBottom: theme.spacing(2)
        }
    },
    search: {
        background: theme.colors.ghostwhite
    }
}));

function WaitBookings({ bookings = [] }) {
    const classes = useStyles();
    const [search, setSearch] = React.useState('');

    const sortedBookings = React.useMemo(() => {
        return orderBy(bookings, ['createdAt', 'asc'])?.filter((booking) => {
            return (
                booking?.title?.toLowerCase()?.includes(search?.toLowerCase()) ||
                booking?.client?.phone?.includes(search?.toLowerCase())
            );
        }, []);
    }, [bookings, search]);

    return (
        <Box className={classes.root}>
            {bookings?.length === 0 ? (
                <Grid container direction="column" alignItems="center" justifyContent="center">
                    <Grid item>
                        <NotFoundSVG />
                    </Grid>
                    <Grid item>
                        <Typography variant="caption">There is no booking</Typography>
                    </Grid>
                </Grid>
            ) : (
                <Grid container wrap="nowrap" direction="column" spacing={2}>
                    <Grid item>
                        <Typography variant="body1">Walk-in list</Typography>
                    </Grid>
                    <Grid item>
                        <Search
                            className={classes.search}
                            value={search}
                            placeholder="Search phone or name..."
                            autoFocus
                            onChange={(e) => setSearch(e?.target?.value)}
                        />
                    </Grid>
                    <Grid item wrap="nowrap" direction="column">
                        {sortedBookings?.map((booking, index) => (
                            <Grid className={classes.item} item key={booking?.id}>
                                <Booking number={index} booking={booking} />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            )}
        </Box>
    );
}

export default WaitBookings;
