import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { getDateBefore, getDateAfter, isSameToday, isSameDate } from 'utils/timing';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { INPUT_HEIGHT, BORDER_RADIUS } from 'const/style';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import useMediaQueries from 'hooks/useMediaQueries';
import { ArrowRight, ArrowLeft } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: BORDER_RADIUS,
        background: theme.palette.common.white,
        boxShadow: theme.shadows[1],
        '& *': {
            pointerEvents: (props) => (props.disabled ? 'none' : '')
        }
    },
    today: {
        width: 86,
        background: theme.palette.common.white,
        height: INPUT_HEIGHT,
        display: 'flex',
        alignItems: 'center',
        fontSize: 14,
        fontWeight: 500,
        justifyContent: 'center',
        cursor: 'pointer',
        borderRight: `1px solid ${theme.colors.border}`,
        borderLeft: `1px solid ${theme.colors.border}`
    },
    todaySelected: {
        background: theme.palette.primary.main
    },
    todayText: {
        color: theme.palette.common.white
    },
    arrow: {
        width: INPUT_HEIGHT,
        height: INPUT_HEIGHT,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        '&:hover': {
            background: theme.palette.primary.main,
            '& svg': {
                color: theme.palette.common.white
            }
        }
    },
    datePicker: {
        borderRight: `1px solid ${theme.colors.border}`,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        minWidth: 230,
        cursor: 'pointer'
    },
    picker: {
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 10,
        margin: 'auto',
        '& .MuiTextField-root': {
            opacity: 0,
            width: '100%',
            cursor: 'pointer !important'
        },
        '& input': {
            cursor: 'pointer'
        }
    }
}));

function DateRange({ onChange, disabled }) {
    const classes = useStyles({ disabled });
    const { isMobile } = useMediaQueries();
    const { t } = useTranslation();
    const [selectedDate, setSelectedDate] = React.useState(moment());

    React.useEffect(() => {
        onChange && onChange(selectedDate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate]);

    const handleChange = (date) => {
        if (isSameDate({ date1: date, date2: selectedDate })) {
            return;
        }
        setSelectedDate(date);
    };

    const handleTodayClick = () => {
        if (isSameToday(selectedDate)) {
            return;
        }
        setSelectedDate(moment());
    };

    const handleNextClick = () => {
        const nextDate = getDateAfter({ date: selectedDate, numOfDay: 1 });
        const date = moment(nextDate);

        setSelectedDate(date);
    };

    const handlePrevClick = () => {
        const prevDate = getDateBefore({ date: selectedDate, numOfDay: 1 });
        const date = moment(prevDate);
        setSelectedDate(date);
    };

    const DateRangeText = React.useMemo(() => {
        return moment(selectedDate).format(`dddd DD MMM, YYYY`);
    }, [selectedDate]);

    return (
        <Grid
            container
            style={{ pointerEvents: disabled ? 'none' : '' }}
            className={classes.root}
            direction="row"
            wrap="nowrap"
        >
            <Grid item className={`${classes.center} ${classes.arrow}`} onClick={handlePrevClick}>
                <ArrowLeft />
            </Grid>
            <Grid
                item
                className={`${classes.center} ${classes.today} ${
                    isSameToday(selectedDate) ? classes.todaySelected : ''
                }`}
                onClick={handleTodayClick}
            >
                <Typography className={isSameToday(selectedDate) ? classes.todayText : ''} variant="body1">
                    {t(`today`)}
                </Typography>
            </Grid>
            <Grid
                item
                style={{ pointerEvents: disabled ? 'none' : '' }}
                className={`${classes.center} ${classes.datePicker}`}
            >
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                    <div className={classes.picker}>
                        <DatePicker
                            variant={isMobile ? 'dialog' : 'inline'}
                            label="Basic example"
                            value={selectedDate}
                            onChange={handleChange}
                            disableToolbar
                            format="DD/MM/YYYY"
                            autoOk={true}
                        />
                    </div>
                </MuiPickersUtilsProvider>
                <Typography variant="body1" className={classes.present}>
                    {DateRangeText}
                </Typography>
            </Grid>
            <Grid item className={`${classes.center} ${classes.arrow}`} onClick={handleNextClick}>
                <ArrowRight />
            </Grid>
        </Grid>
    );
}

export default React.memo(DateRange);
