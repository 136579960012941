import * as types from './types';
import { getStatisticBookingApi } from 'services/analyze';
import { getGiftCodeStatisticApi } from 'services/voucher';

export function fetchAnalyzeRange({ startDate, endDate, successCallback, errorCallback }) {
    return async function (dispatch) {
        try {
            const response = await getStatisticBookingApi({ startDate, endDate });
            dispatch(
                _fetchAnalyzeRangeSuccess({
                    bookings: response?.bookings,
                    createdClients: response?.createdClients,
                    totalOnlineBookings: response?.totalOnlineBookings,
                    totalOfflineBookings: response?.totalOfflineBookings,
                    totalDepositAmount: response?.totalDepositAmount
                })
            );

            successCallback && successCallback();
        } catch (error) {
            errorCallback && errorCallback();
        }
    };
}

function _fetchAnalyzeRangeSuccess({
    bookings,
    createdClients,
    totalOnlineBookings,
    totalOfflineBookings,
    totalDepositAmount
}) {
    return {
        type: types.FETCH_STATISTIC_RANGE,
        payload: {
            bookings,
            createdClients,
            totalOnlineBookings,
            totalOfflineBookings,
            totalDepositAmount
        }
    };
}

export function fetchGiftStatistic({ query, successCallback, errorCallback }) {
    return async function (dispatch) {
        try {
            const response = await getGiftCodeStatisticApi(query);
            dispatch(
                _fetchGiftStatisticSuccess({
                    ...response
                })
            );
            successCallback && successCallback();
        } catch (error) {
            errorCallback && errorCallback();
        }
    };
}

export function _fetchGiftStatisticSuccess(response) {
    return {
        type: types.FETCH_GIFTS_STATISTIC,
        payload: {
            ...response
        }
    };
}
