import React from 'react';

function IconCameraSVG({ color = '#fff', ...other }) {
    return (
        <svg {...other} width="43" height="34" viewBox="0 0 43 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M41.5694 5.27281C40.711 4.37357 39.5257 3.84221 38.1768 3.84221H31.3916V3.76046C31.3916 2.73859 30.9829 1.7576 30.288 1.10361C29.5932 0.408745 28.653 0 27.6312 0H15.3688C14.3061 0 13.366 0.408745 12.6711 1.10361C11.9762 1.79848 11.5675 2.73859 11.5675 3.76046V3.84221H4.82319C3.47433 3.84221 2.28897 4.37357 1.43061 5.27281C0.572243 6.13118 0 7.35741 0 8.6654V29.0209C0 30.3698 0.531369 31.5551 1.43061 32.4135C2.28897 33.2719 3.51521 33.8441 4.82319 33.8441H38.1768C39.5257 33.8441 40.711 33.3127 41.5694 32.4135C42.4278 31.5551 43 30.3289 43 29.0209V8.6654C43 7.31654 42.4686 6.13118 41.5694 5.27281ZM40.8745 29.0209H40.8337C40.8337 29.7567 40.5475 30.4107 40.057 30.9011C39.5665 31.3916 38.9125 31.6778 38.1768 31.6778H4.82319C4.08745 31.6778 3.43346 31.3916 2.94297 30.9011C2.45247 30.4107 2.16635 29.7567 2.16635 29.0209V8.6654C2.16635 7.92966 2.45247 7.27566 2.94297 6.78517C3.43346 6.29468 4.08745 6.00856 4.82319 6.00856H12.712C13.3251 6.00856 13.8156 5.51806 13.8156 4.90494V3.71958C13.8156 3.26996 13.9791 2.86122 14.2652 2.5751C14.5513 2.28897 14.9601 2.12548 15.4097 2.12548H27.6312C28.0808 2.12548 28.4895 2.28897 28.7757 2.5751C29.0618 2.86122 29.2253 3.26996 29.2253 3.71958V4.90494C29.2253 5.51806 29.7158 6.00856 30.3289 6.00856H38.2177C38.9534 6.00856 39.6074 6.29468 40.0979 6.78517C40.5884 7.27566 40.8745 7.92966 40.8745 8.6654V29.0209Z"
                fill="#787895"
            />
            <path
                d="M21.5 8.78802C18.7205 8.78802 16.1863 9.93251 14.3878 11.731C12.5484 13.5703 11.4448 16.0637 11.4448 18.8432C11.4448 21.6226 12.5893 24.1569 14.3878 25.9553C16.2271 27.7947 18.7205 28.8983 21.5 28.8983C24.2794 28.8983 26.8136 27.7538 28.6121 25.9553C30.4515 24.116 31.5551 21.6226 31.5551 18.8432C31.5551 16.0637 30.4106 13.5295 28.6121 11.731C26.8136 9.93251 24.2794 8.78802 21.5 8.78802ZM27.0589 24.443C25.6283 25.8327 23.6663 26.732 21.5 26.732C19.3336 26.732 17.3716 25.8327 15.941 24.443C14.5104 23.0124 13.652 21.0504 13.652 18.884C13.652 16.7177 14.5513 14.7557 15.941 13.3251C17.3716 11.8945 19.3336 11.0361 21.5 11.0361C23.6663 11.0361 25.6283 11.9354 27.0589 13.3251C28.4895 14.7557 29.3479 16.7177 29.3479 18.884C29.3887 21.0504 28.4895 23.0124 27.0589 24.443Z"
                fill="#787895"
            />
            <path
                d="M36.0512 12.7528C37.1574 12.7528 38.0541 11.8561 38.0541 10.75C38.0541 9.64384 37.1574 8.74713 36.0512 8.74713C34.9451 8.74713 34.0483 9.64384 34.0483 10.75C34.0483 11.8561 34.9451 12.7528 36.0512 12.7528Z"
                fill="#787895"
            />
        </svg>
    );
}

export default React.memo(IconCameraSVG);
