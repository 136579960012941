import React from 'react';

function DebitSVG({ color = '#41415C', ...rest }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" {...rest}>
            <path
                fill="#e9a583"
                d="M53,27.92h7V21.67a4.14,4.14,0,0,0-4.16-4.16H53Zm0,11.51v2.9a4.16,4.16,0,0,1-4.16,4.16H11v4a4.16,4.16,0,0,0,4.16,4.16H55.84A4.15,4.15,0,0,0,60,50.48v-11Z"
            />
            <path
                fill="#93d8e4"
                d="M8.16,9.36A4.15,4.15,0,0,0,4,13.52v2.72H53V13.52a4.16,4.16,0,0,0-4.16-4.16ZM4,21.79V42.33a4.15,4.15,0,0,0,4.16,4.16H48.87A4.16,4.16,0,0,0,53,42.33V21.79Zm6.15,12.8h7.66A1.21,1.21,0,0,1,19,35.8v4.81a1.21,1.21,0,0,1-1.21,1.21H10.15A1.2,1.2,0,0,1,9,40.61V35.8A1.2,1.2,0,0,1,10.15,34.59Zm27.51.66h8.23a2.16,2.16,0,0,1,2.17,2.17V39a2.16,2.16,0,0,1-2.17,2.17H37.66A2.17,2.17,0,0,1,35.49,39V37.42A2.17,2.17,0,0,1,37.66,35.25Z"
            />
            <path fill="#efc89d" d="M4,16.24H53v5.55H4Z" />
            <path
                fill="#f1f4f6"
                d="M10.16 34.59h7.65A1.21 1.21 0 0 1 19 35.8v4.81a1.21 1.21 0 0 1-1.21 1.21H10.16A1.21 1.21 0 0 1 9 40.61V35.8A1.21 1.21 0 0 1 10.16 34.59zM53 27.92h7V39.43H53z"
            />
            <path
                fill="#7ad0df"
                d="M44.87,9.36A4.16,4.16,0,0,1,49,13.52v2.72h4V13.52a4.16,4.16,0,0,0-4.16-4.16ZM49,21.79V42.33a4.16,4.16,0,0,1-4.16,4.16h4A4.16,4.16,0,0,0,53,42.33V21.79Z"
            />
            <path fill="#ecb780" d="M49,16.24v5.55h4V16.24Z" />
            <path
                fill="#e4936b"
                d="M53,17.51l-.19.13a4.15,4.15,0,0,1,3.16,4v6.25h4V21.67a4.14,4.14,0,0,0-4.16-4.16Zm3,21.92v11a4.15,4.15,0,0,1-4.16,4.16h4A4.15,4.15,0,0,0,60,50.48v-11Z"
            />
            <path fill="#dfeaef" d="M56,27.92h4V39.43H56Z" />
            <path
                fill="#f1f4f6"
                d="M37.66,35.25H45.9a2.17,2.17,0,0,1,2.17,2.17V39a2.16,2.16,0,0,1-2.17,2.17H37.66A2.16,2.16,0,0,1,35.49,39V37.42A2.17,2.17,0,0,1,37.66,35.25Z"
            />
            <path
                fill="#323c58"
                d="M55.84,16.51H54v-3a5.17,5.17,0,0,0-5.16-5.16H8.16A5.16,5.16,0,0,0,3,13.52V42.33a5.16,5.16,0,0,0,5.16,5.16H10v3a5.17,5.17,0,0,0,5.16,5.16H55.84A5.16,5.16,0,0,0,61,50.48V21.67A5.16,5.16,0,0,0,55.84,16.51Zm0,2A3.17,3.17,0,0,1,59,21.67v5.25H54V18.51ZM59,38.43H54V28.92h5ZM5,17.24H52v3.55H5Zm3.16-6.88H48.87A3.17,3.17,0,0,1,52,13.52v1.72H5V13.52A3.17,3.17,0,0,1,8.16,10.36ZM5,42.33V22.79H52V42.33a3.17,3.17,0,0,1-3.16,3.16H8.16A3.17,3.17,0,0,1,5,42.33ZM55.84,53.64H15.13A3.17,3.17,0,0,1,12,50.48v-3h36.9A5.17,5.17,0,0,0,54,42.33v-1.9h5v10A3.17,3.17,0,0,1,55.84,53.64Z"
            />
            <path
                fill="#323c58"
                d="M17.81 33.59H10.16A2.21 2.21 0 0 0 8 35.8v4.81a2.21 2.21 0 0 0 2.21 2.21h7.65A2.21 2.21 0 0 0 20 40.61V35.8A2.21 2.21 0 0 0 17.81 33.59zM18 35.8v1.41H15V35.59h2.83A.21.21 0 0 1 18 35.8zM10 40.61V35.8a.21.21 0 0 1 .21-.21H13v5.23H10.16A.21.21 0 0 1 10 40.61zm7.86.21H15V39.21h3v1.4A.21.21 0 0 1 17.81 40.82zM10 29a1 1 0 0 0 1-1V26.84a1 1 0 0 0-2 0V28A1 1 0 0 0 10 29zM13.51 29a1 1 0 0 0 1-1V26.84a1 1 0 0 0-2 0V28A1 1 0 0 0 13.51 29zM17.06 29a1 1 0 0 0 1-1V26.84a1 1 0 0 0-2 0V28A1 1 0 0 0 17.06 29zM22.86 25.84a1 1 0 0 0-1 1V28a1 1 0 0 0 2 0V26.84A1 1 0 0 0 22.86 25.84zM26.42 25.84a1 1 0 0 0-1 1V28a1 1 0 0 0 2 0V26.84A1 1 0 0 0 26.42 25.84zM30 25.84a1 1 0 0 0-1 1V28a1 1 0 0 0 2 0V26.84A1 1 0 0 0 30 25.84zM43.52 29a1 1 0 0 0 1-1V26.84a1 1 0 1 0-2 0V28A1 1 0 0 0 43.52 29zM47.08 29a1 1 0 0 0 1-1V26.84a1 1 0 0 0-2 0V28A1 1 0 0 0 47.08 29zM34.7 26.84a1 1 0 0 0-2 0V28a1 1 0 0 0 2 0zM49.07 37.42a3.18 3.18 0 0 0-3.17-3.17H37.66a3.17 3.17 0 0 0-3.17 3.17V39a3.17 3.17 0 0 0 3.17 3.17H45.9A3.18 3.18 0 0 0 49.07 39zm-2 1.57a1.17 1.17 0 0 1-1.17 1.17H37.66A1.17 1.17 0 0 1 36.49 39V37.42a1.17 1.17 0 0 1 1.17-1.17H45.9a1.18 1.18 0 0 1 1.17 1.17z"
            />
        </svg>
    );
}

export default React.memo(DebitSVG);
