import * as types from './types';
import {
    getBookingsApi,
    createBookingApi,
    deleteBookingServiceApi,
    deleteBookingApi,
    updateBookingApi,
    sendNotificationApi,
    updateCheckInBookingApi,
    getBookingLogsApi,
    refundBookingApi
} from 'services/calendar';
import {
    createBlockedTimeApi,
    deleteBlockedTimeApi,
    updateBlockedTimeApi,
    getStaffBlockedTimeApi
} from 'services/staffBlockedTime';
import { getStaffCalendarWorkingHoursApi, getStaffApi, getClosedDatesApi } from 'services/staff';
import { enqueueSnackbar } from 'notifier/actions';
import { formatStaffWorkingHours, splitBookingIntoSingleService } from 'utils/app/booking';
import { formatBlockedTimeByStaff } from 'utils/app/staff/blockedTime';
import performance from 'performance-now';
import { createCheckoutApi } from 'services/checkout';
import { getGiftCodesRangeApi } from 'services/voucher';
import {
    CHECKOUT_TYPES,
    BOOKING_REALTIME_ACTIONS,
    UserState,
    BOOKING_SERVICE_STATES,
    TIME_LIFE_FIND_BOOKING,
    BOOKING_ACTIONS,
    SCHEDULE_TYPES,
    MERCHANT_PERMISSIONS,
    BOOKING_CHECKOUT_STATES
} from 'const';
import {
    getAppendMerchantTimezoneToDate,
    getDatetimeFormat,
    getDateAfter,
    getStartOfDay,
    isSameDate,
    convertTimeToFloat,
    convertFloatingToDateTime,
    convertDurationAndStartTimeToDate
} from 'utils/timing';
import { getServicesApi } from 'services/service';
import { getCategoriesApi } from 'services/category';
import { OPERATOR } from 'const/condition';
import * as serviceActions from 'views/services/action/service';
import * as staffActions from 'views/staff/action';
import soundMp3 from 'assets/alert.mp3';
import moment from 'moment-timezone';
import { sortBy } from 'lodash';
import { convertStaffsServiceDuration } from 'utils/app/staff';
import { convertServicesMapping } from 'utils/app/service';

export function initDataDefault({ success, error }) {
    return async function (dispatch) {
        try {
            // dispatch(_initializeAction());
            const [serviceRes, categoriesRes, staffRes, closedDateRes] = await Promise.all([
                getServicesApi(),
                getCategoriesApi(),
                getStaffApi({
                    query: {
                        staffState: {
                            [OPERATOR.equal]: UserState.Active
                        }
                    }
                }),
                getClosedDatesApi()
            ]);
            dispatch(
                serviceActions.fetchCategoriesAndServicesDone({
                    services: serviceRes?.items,
                    categories: categoriesRes?.items
                })
            );

            dispatch(
                staffActions.fetchStaffsAndClosedDatesSuccess({
                    staffs: staffRes?.items,
                    closedDates: closedDateRes?.items
                })
            );
            // dispatch(_initializeActionFailed());
            success && success();
        } catch ({ message }) {
            console.log('message', message);
            error && error();
            // dispatch(_initializeActionFailed());
        }
    };
}

// function _initializeAction() {
//     return {
//         type: types.INITIAL_CALENDAR
//     };
// }

// function _initializeActionFailed() {
//     return {
//         type: types.INITIAL_CALENDAR_FAILED
//     };
// }

export function fetchCombinedDate({ date, success, error }) {
    return async function (dispatch) {
        const nextDate = getDateAfter({ date, numOfDay: 1 });
        dispatch(_getStaffBookingsByDateAction());

        try {
            const apiStart = performance();

            const [bookings, workingHours, blocked] = await Promise.all([
                getBookingsApi({ startDate: getAppendMerchantTimezoneToDate(getDatetimeFormat(date)) }),
                getStaffCalendarWorkingHoursApi({ startDate: getDatetimeFormat(date) }),
                getStaffBlockedTimeApi({
                    start: getAppendMerchantTimezoneToDate(getDatetimeFormat(date)),
                    end: getAppendMerchantTimezoneToDate(getDatetimeFormat(nextDate))
                })
            ]);

            const apiEnd = performance();

            const { staffWorkingHours, originalStaffSchedule } = formatStaffWorkingHours(workingHours);
            const blockedTimes = formatBlockedTimeByStaff(blocked?.items);

            const apiEnd2 = performance();

            console.log('api time', (apiStart - apiEnd).toFixed(3));
            console.log('mapping time', (apiEnd2 - apiEnd).toFixed(3));

            success && success();

            dispatch(
                _setData1Time({
                    bookings,
                    blockedTimes,
                    staffWorkingHours,
                    originalStaffSchedule
                })
            );
        } catch ({ message }) {
            error && error();
            dispatch(_getStaffBookingsByDateActionFailed());
        }
    };
}

function _setData1Time({ bookings, blockedTimes, staffWorkingHours, originalStaffSchedule }) {
    return {
        type: types.SET_DATA_BOOKINGS_1_TIME,
        payload: {
            bookings,
            blockedTimes,
            staffWorkingHours,
            originalStaffSchedule
        }
    };
}

function _getStaffBookingsByDateAction() {
    return {
        type: types.FETCH_STAFF_BOOKINGS_BY_DATE
    };
}

function _getStaffBookingsByDateActionFailed() {
    return {
        type: types.FETCH_STAFF_BOOKINGS_BY_DATE_FAILED
    };
}

export function getStaffWorkingHoursByDate({ success, error }) {
    return async function (dispatch, getState) {
        try {
            const { calendar } = getState();

            const staffWorkingHoursDate = getDatetimeFormat(calendar?.selectedDate);

            dispatch(_getStaffWorkingHoursByDateAction());
            const apiStart = performance();

            const data = await getStaffCalendarWorkingHoursApi({ startDate: staffWorkingHoursDate });

            const apiEnd = performance();
            console.log('API TIMING STAFF WORKING', (apiStart - apiEnd).toFixed(3));
            const mappingStart = performance();

            const { staffWorkingHours, originalStaffSchedule } = formatStaffWorkingHours(data);
            const mappingEnd = performance();
            console.log('MAPPING TIMING STAFF WORKING', (mappingStart - mappingEnd).toFixed(3));

            dispatch(_getStaffWorkingHoursByDateActionSuccess({ staffWorkingHours, originalStaffSchedule }));
            success && success();
        } catch ({ message }) {
            console.log('message', message);
            dispatch(_getStaffWorkingHoursByDateActionFailed());
            error && error();
        }
    };
}

function _getStaffWorkingHoursByDateAction() {
    return {
        type: types.FETCH_STAFF_WORKING_HOURS_BY_DATE
    };
}

function _getStaffWorkingHoursByDateActionFailed() {
    return {
        type: types.FETCH_STAFF_WORKING_HOURS_BY_DATE_FAILED
    };
}

function _getStaffWorkingHoursByDateActionSuccess({ staffWorkingHours, originalStaffSchedule }) {
    return {
        type: types.FETCH_STAFF_WORKING_HOURS_BY_DATE_SUCCESS,
        payload: {
            staffWorkingHours,
            originalStaffSchedule
        }
    };
}

export function getStaffBlockedTimesByDate({ success, error }) {
    return async function (dispatch, getState) {
        try {
            const { calendar } = getState();

            const selectedDate = calendar?.selectedDate;

            const nextDate = getDateAfter({ date: selectedDate, numOfDay: 1 });

            const blockedTimeRange = {
                start: getAppendMerchantTimezoneToDate(getDatetimeFormat(selectedDate)),
                end: getAppendMerchantTimezoneToDate(getDatetimeFormat(nextDate))
            };

            dispatch(_getStaffBlockedTimesByDate());

            const apiStart = performance();

            const response = await getStaffBlockedTimeApi(blockedTimeRange);
            const apiEnd = performance();
            console.log('API TIMING BLOCK TIMES', (apiStart - apiEnd).toFixed(3));

            const mappingStart = performance();

            const blockedTimes = formatBlockedTimeByStaff(response?.items);
            const mappingEnd = performance();
            console.log('MAPPING TIMING BLOCK TIMES', (mappingStart - mappingEnd).toFixed(3));

            dispatch(_getStaffBlockedTimesByDateSuccess({ blockedTimes }));
            success && success();
        } catch ({ message }) {
            dispatch(_getStaffBlockedTimesByDateFailed());
            error && error();
        }
    };
}

function _getStaffBlockedTimesByDate() {
    return {
        type: types.FETCH_STAFF_BLOCKED_TIMES_BY_DATE
    };
}

function _getStaffBlockedTimesByDateFailed() {
    return {
        type: types.FETCH_STAFF_BLOCKED_TIMES_BY_DATE_FAILED
    };
}

function _getStaffBlockedTimesByDateSuccess({ blockedTimes }) {
    return {
        type: types.FETCH_STAFF_BLOCKED_TIMES_BY_DATE_SUCCESS,
        payload: {
            blockedTimes
        }
    };
}

export function updateBookingByResize({
    staffId,
    bkSvIds,
    oldDuration,
    newDuration,
    bookingId,
    orderServiceId,
    body,
    successCallback,
    errorCallback
}) {
    return async function (dispatch) {
        dispatch(_updateBookingByResizeAction({ staffId, duration: newDuration, orderServiceId, bkSvIds }));
        //multi staff + multi service
        try {
            await updateBookingApi({ body, id: bookingId });
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[updateBookingByResize] ${message}`,
                    type: 'info'
                })
            );
            dispatch(_updateBookingByResizeAction({ staffId, duration: oldDuration, orderServiceId, bkSvIds }));
            errorCallback && errorCallback(message);
        }
    };
}

function _updateBookingByResizeAction({ staffId, duration, orderServiceId, bkSvIds }) {
    return {
        type: types.UPDATE_BOOKING_BY_RESIZE,
        payload: {
            staffId,
            duration,
            orderServiceId,
            bkSvIds
        }
    };
}

export function refundBooking({ bookingId, successCallback, errorCallback }) {
    return async function (dispatch) {
        dispatch(_updateBookingAction());

        try {
            const updatedBooking = await refundBookingApi(bookingId);
            const bookingServices = splitBookingIntoSingleService(updatedBooking);
            dispatch(_updateBookingActionSuccess({ bookingServices }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[cancelBooking] ${message}`,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function cancelBooking({ bookingId, body, successCallback, errorCallback }) {
    return async function (dispatch) {
        dispatch(_updateBookingAction());

        try {
            const updatedBooking = await updateBookingApi({ body, id: bookingId });
            const bookingServices = splitBookingIntoSingleService(updatedBooking);
            dispatch(_cancelBookingActionSuccess({ bookingServices }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[cancelBooking] ${message}`,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

function _cancelBookingActionSuccess({ bookingServices }) {
    return {
        type: types.CANCEL_BOOKING_SUCCESS,
        payload: {
            bookingServices
        }
    };
}

function _deleteStaffsBookingServices({ staffBkSvs }) {
    return {
        type: types.DELETE_STAFFS_BOOKING_SERVICES,
        payload: {
            staffBkSvs
        }
    };
}

export function updateBooking({ bookingId, oldBooking, body, successCallback, errorCallback }) {
    return async function (dispatch) {
        dispatch(_updateBookingAction());

        //multi staff + multi service
        try {
            const updatedBooking = await updateBookingApi({ body, id: bookingId });
            const bookingServices = splitBookingIntoSingleService(updatedBooking);

            const deletedBkServices = oldBooking?.bookingServices || [];

            if (deletedBkServices?.length) {
                const staffBkSvs = {};
                for (const bkSv of deletedBkServices) {
                    if (!staffBkSvs?.[+bkSv?.staffId]) {
                        staffBkSvs[+bkSv?.staffId] = [];
                    }
                    staffBkSvs[+bkSv?.staffId] = [...staffBkSvs?.[+bkSv?.staffId], +bkSv?.id];
                }

                dispatch(_deleteStaffsBookingServices({ staffBkSvs }));
            }

            dispatch(_updateBookingActionSuccess({ bookingServices }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[updateBooking] ${message}`,
                    type: 'info'
                })
            );
            dispatch(_updateBookingActionFailed());
            errorCallback && errorCallback(message);
        }
    };
}

export function updateCheckInBooking({ bookingId, oldBooking, body, successCallback, errorCallback }) {
    return async function (dispatch) {
        dispatch(_updateBookingAction());

        //multi staff + multi service
        try {
            const updatedBooking = await updateCheckInBookingApi({ body, id: bookingId });
            const bookingServices = splitBookingIntoSingleService(updatedBooking);

            const deletedBkServices = oldBooking?.bookingServices || [];

            if (deletedBkServices?.length) {
                const staffBkSvs = {};
                for (const bkSv of deletedBkServices) {
                    if (!staffBkSvs?.[+bkSv?.staffId]) {
                        staffBkSvs[+bkSv?.staffId] = [];
                    }
                    staffBkSvs[+bkSv?.staffId] = [...staffBkSvs?.[+bkSv?.staffId], +bkSv?.id];
                }

                dispatch(_deleteStaffsBookingServices({ staffBkSvs }));
            }

            dispatch(_updateBookingActionSuccess({ bookingServices }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[updateBooking] ${message}`,
                    type: 'info'
                })
            );
            dispatch(_updateBookingActionFailed());
            errorCallback && errorCallback(message);
        }
    };
}

function _updateBookingActionSuccess({ bookingServices }) {
    return {
        type: types.UPDATE_BOOKING_SUCCESS,
        payload: {
            bookingServices
        }
    };
}

function _updateBookingAction() {
    return {
        type: types.UPDATE_BOOKING
    };
}

function _updateBookingActionFailed() {
    return {
        type: types.UPDATE_BOOKING_FAILED
    };
}

export function createBooking({ body, successCallback, errorCallback }) {
    return async function (dispatch) {
        dispatch(_createBookingAction());
        //multi staff + multi service
        try {
            const createdBooking = await createBookingApi({ body });
            const bookingServices = splitBookingIntoSingleService(createdBooking);
            console.log('createdBooking', createdBooking);

            dispatch(_createBookingActionSuccess({ bookingServices }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[createBooking] ${message}`,
                    type: 'info'
                })
            );
            dispatch(_createBookingActionFailed());
            errorCallback && errorCallback(message);
        }
    };
}

function _createBookingAction() {
    return {
        type: types.ADD_BOOKING
    };
}

function _createBookingActionFailed() {
    return {
        type: types.ADD_BOOKING_FAILED
    };
}

function _createBookingActionSuccess({ bookingServices }) {
    return {
        type: types.ADD_BOOKING_SUCCESS,
        payload: {
            bookingServices
        }
    };
}

export function deleteBookingService({ bookingId, serviceId, staffId, successCallback, errorCallback }) {
    return async function (dispatch) {
        dispatch(_deleteBookingServiceAction({ bookingId, serviceId, staffId }));

        try {
            await deleteBookingServiceApi({ bookingId, serviceId });
            dispatch(_deleteBookingServiceActionSuccess({ bookingId, serviceId, staffId }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[createBooking] ${message}`,
                    type: 'info'
                })
            );
            dispatch(_deleteBookingServiceActionFailed({ bookingId, serviceId, staffId }));
            errorCallback && errorCallback(message);
        }
    };
}

function _deleteBookingServiceAction({ bookingId, serviceId, staffId }) {
    return {
        type: types.DELETE_BOOKING_SERVICE,
        payload: {
            bookingId,
            serviceId,
            staffId
        }
    };
}

function _deleteBookingServiceActionFailed({ bookingId, serviceId, staffId }) {
    return {
        type: types.DELETE_BOOKING_SERVICE_FAILED,
        payload: {
            bookingId,
            serviceId,
            staffId
        }
    };
}

function _deleteBookingServiceActionSuccess({ bookingId, serviceId, staffId }) {
    return {
        type: types.DELETE_BOOKING_SERVICE_SUCCESS,
        payload: {
            bookingId,
            serviceId,
            staffId
        }
    };
}

export function deleteBooking({ bookingId, successCallback, errorCallback }) {
    return async function (dispatch) {
        dispatch(_deleteBookingAction({ bookingId }));

        try {
            await deleteBookingApi({ id: bookingId });
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[deleteBooking] ${message}`,
                    type: 'info'
                })
            );
            dispatch(_deleteBookingActionFailed({ bookingId }));
            errorCallback && errorCallback(message);
        }
    };
}

function _deleteBookingAction({ bookingId }) {
    return {
        type: types.DELETE_BOOKING
    };
}

function _deleteBookingActionFailed({ bookingId }) {
    return {
        type: types.DELETE_BOOKING_FAILED
    };
}

export function updateZoomSetting(zoom) {
    return {
        type: types.UPDATE_ZOOM_SETTING,
        payload: {
            zoom
        }
    };
}

export function updateBlockedTime({ id, body, successCallback, errorCallback }) {
    return async function (dispatch) {
        dispatch(_updateBlockedAction());

        //multi staff + multi service
        try {
            await updateBlockedTimeApi({ body, id });
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[updateBlockedTime] ${message}`,
                    type: 'info'
                })
            );
            dispatch(_updateBlockedActionFailed());
            errorCallback && errorCallback(message);
        }
    };
}

function _updateBlockedAction() {
    return {
        type: types.UPDATE_BLOCKED_TIME
    };
}

function _updateBlockedActionFailed() {
    return {
        type: types.UPDATE_BLOCKED_TIME_FAILED
    };
}

export function createBlockedTime({ body, successCallback, errorCallback }) {
    return async function (dispatch) {
        dispatch(_createBlockedTimeAction());
        try {
            await createBlockedTimeApi({ body });
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[createBlockedTime] ${message}`,
                    type: 'info'
                })
            );
            dispatch(_createBlockedTimeActionFailed());
            errorCallback && errorCallback(message);
        }
    };
}

function _createBlockedTimeAction() {
    return {
        type: types.ADD_BLOCKED_TIME
    };
}

function _createBlockedTimeActionFailed() {
    return {
        type: types.ADD_BLOCKED_TIME_FAILED
    };
}

export function deleteBlockedTime({ id, successCallback, errorCallback }) {
    return async function (dispatch) {
        dispatch(_deleteBlockedTimeAction({ id }));

        try {
            await deleteBlockedTimeApi(id);
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[deleteBlockedTime] ${message}`,
                    type: 'info'
                })
            );
            dispatch(_deleteBlockedTimeActionFailed({ id }));
            errorCallback && errorCallback(message);
        }
    };
}

function _deleteBlockedTimeAction({ id }) {
    return {
        type: types.DELETE_BLOCKED_TIME
    };
}

function _deleteBlockedTimeActionFailed({ id }) {
    return {
        type: types.DELETE_BLOCKED_TIME_FAILED
    };
}

export function updateBlockedTimeByResize({
    id,
    staffId,
    newTimeEnd,
    oldTimeEnd,
    body,
    successCallback,
    errorCallback
}) {
    return async function (dispatch) {
        dispatch(_updateBlockedTimeByResizeAction({ id, timeEnd: newTimeEnd, staffId }));
        //multi staff + multi service
        try {
            await updateBlockedTimeApi({ id, body });
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[updateBookingByResize] ${message}`,
                    type: 'info'
                })
            );
            dispatch(_updateBlockedTimeByResizeAction({ staffId, timeEnd: oldTimeEnd, id }));
            errorCallback && errorCallback(message);
        }
    };
}

function _updateBlockedTimeByResizeAction({ id, timeEnd, staffId }) {
    return {
        type: types.UPDATE_BLOCKED_TIME_BY_RESIZE,
        payload: {
            id,
            timeEnd,
            staffId
        }
    };
}

export function createBookingServiceWithExistedBooking({ staffId, bookingService }) {
    return {
        type: types.CREATE_NEW_BOOKING_SERVICE_WITH_EXISTED_BOOKING,
        payload: {
            staffId,
            bookingService
        }
    };
}

export function deleteBookingServiceSocket({ staffId, bkSvId }) {
    return {
        type: types.DELETE_BOOKING_SERVICE_SOCKET,
        payload: {
            staffId,
            bkSvId
        }
    };
}

export function updateBookingServiceSocket({ staffId, bookingService }) {
    return {
        type: types.UPDATE_BOOKING_SERVICE_SOCKET,
        payload: {
            staffId,
            bookingService
        }
    };
}

export function createBookingSocket({ bookingServices }) {
    return {
        type: types.CREATE_BOOKING_SOCKET,
        payload: {
            bookingServices
        }
    };
}

export function deleteBookingSocket({ bookingServices }) {
    return {
        type: types.DELETE_BOOKING_SOCKET,
        payload: {
            bookingServices
        }
    };
}

export function rescheduleBookingSocket({ bookingServices, isSameDate }) {
    return {
        type: types.RESCHEDULE_BOOKING_SOCKET,
        payload: {
            bookingServices,
            isSameDate
        }
    };
}

export function updateBookingSocket({ bookingServices, extra }) {
    return {
        type: types.UPDATE_BOOKING_SOCKET,
        payload: {
            bookingServices,
            extra
        }
    };
}

export function sendStaffNotification({ body, successCallback, errorCallback }) {
    return async function (dispatch) {
        try {
            await sendNotificationApi({ body });
            dispatch(
                enqueueSnackbar({
                    message: `Send notification success`,
                    type: 'info'
                })
            );
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[sendStaffNotification] ${message}`,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function toggleCloseDate() {
    return {
        type: types.TOGGLE_CLOSE_DATE
    };
}

export function setSelectedDate(date) {
    return {
        type: types.SET_SELECTED_DATE,
        payload: {
            date
        }
    };
}

export function fetchGiftCodes({ query, successCallback, errorCallback }) {
    return async function (dispatch) {
        try {
            const giftCodes = await getGiftCodesRangeApi(query);
            dispatch(_fetchGiftCodesSuccess(giftCodes));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[checkout] ${message}`,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function _fetchGiftCodesSuccess(giftCodes) {
    return {
        type: types.FETCH_GIFT_CODES,
        payload: {
            giftCodes
        }
    };
}

export function checkout({ type, giftCodeId, bkSvsByStaffId, body, successCallback, errorCallback }) {
    return async function (dispatch) {
        try {
            await createCheckoutApi(body);

            if (type === CHECKOUT_TYPES.BOOKING) {
                dispatch(_checkoutBookingSuccess(bkSvsByStaffId));
            } else {
                dispatch(_checkoutGiftSuccess(giftCodeId));
            }
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[checkout] ${message}`,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function _checkoutBookingSuccess(bkSvsByStaffId) {
    return {
        type: types.CHECKOUT,
        payload: {
            bkSvsByStaffId
        }
    };
}

export function _checkoutGiftSuccess(giftCodeId) {
    return {
        type: types.REMOVE_GIFT_CODE,
        payload: {
            giftCodeId
        }
    };
}

export function setAlertNewCheckIn(alert) {
    return {
        type: types.SET_ALERT_NEW_CHECK_IN,
        payload: {
            alert
        }
    };
}

export function bookingHandler(body) {
    return async function (dispatch, getState) {
        const { app, calendar, merchant: merchantStore } = getState();
        const socketId = app?.socketId;

        const startDate = body?.startDate;
        const xSocketId = body?.xSocketId;
        const calendarDate = calendar?.selectedDate;
        const merchant = merchantStore?.detail;

        const isBookingNotSameToday = !isSameDate({
            date1: getStartOfDay(calendarDate, merchant?.timezone),
            date2: startDate
        });

        if (body?.action === BOOKING_REALTIME_ACTIONS.CHECKOUT) {
            if (isBookingNotSameToday) {
                return;
            }
            return dispatch(updateBookingSocket({ bookingServices: body?.data, extra: body?.extra }));
        }

        if (socketId === xSocketId) {
            console.log('SAME USERS => IGNORE');
            return;
        }

        if (!startDate) {
            console.log('missing startDate');
            return;
        }

        switch (body.action) {
            case BOOKING_REALTIME_ACTIONS.CREATE: {
                if (isBookingNotSameToday) {
                    return;
                }

                const isNewCheckInBooking = body?.data?.[0]?.status === BOOKING_SERVICE_STATES.WAIT_FOR_CHECK_IN;

                if (isNewCheckInBooking) {
                    try {
                        const audio = new Audio(soundMp3);
                        audio.play();
                    } catch (error) {
                        console.log('error', error);
                    }

                    dispatch(setAlertNewCheckIn('open'));
                }
                //spining
                return dispatch(createBookingSocket({ bookingServices: body?.data }));
            }

            case BOOKING_REALTIME_ACTIONS.UPDATE:
            case BOOKING_REALTIME_ACTIONS.NO_SHOW:
            case BOOKING_REALTIME_ACTIONS.CLIENT_ARRIVED:
            case BOOKING_REALTIME_ACTIONS.WAIT:
            case BOOKING_REALTIME_ACTIONS.SERVING:
            case BOOKING_REALTIME_ACTIONS.END: {
                if (isBookingNotSameToday) return;
                return dispatch(updateBookingSocket({ bookingServices: body?.data, extra: body?.extra }));
            }

            case BOOKING_REALTIME_ACTIONS.CLIENT_CANCEL:
            case BOOKING_REALTIME_ACTIONS.DECLINE:
            case BOOKING_REALTIME_ACTIONS.DELETE: {
                if (isBookingNotSameToday) return;
                return dispatch(deleteBookingSocket({ bookingServices: body?.data }));
            }

            case BOOKING_REALTIME_ACTIONS.RESCHEDULE: {
                return dispatch(
                    rescheduleBookingSocket({
                        bookingServices: body?.data,
                        isSameDate: !isBookingNotSameToday
                    })
                );
            }

            default:
                return;
        }
    };
}

export function fetchBookingLogs({ bookingId, successCallback, errorCallback }) {
    return async function (dispatch) {
        // dispatch(_fetchBookingLogs(bookingId));

        try {
            const logs = await getBookingLogsApi(bookingId);

            dispatch(_fetchBookingLogsSuccess({ logs, bookingId }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[fetchBookingLogs] ${message}`,
                    type: 'info'
                })
            );

            // dispatch(_fetchBookingLogsFailed({ bookingId, error: message }));
            errorCallback && errorCallback(message);
        }
    };
}

// function _fetchBookingLogs(bookingId) {
//     return {
//         type: types.FETCH_BOOKING_LOGS,
//         payload: {
//             bookingId
//         }
//     };
// }

function _fetchBookingLogsSuccess({ bookingId, logs }) {
    return {
        type: types.FETCH_BOOKING_LOGS_SUCCESS,
        payload: {
            bookingId,
            logs
        }
    };
}

// function _fetchBookingLogsFailed({ bookingId, error }) {
//     return {
//         type: types.FETCH_BOOKING_LOGS_FAILED,
//         payload: {
//             bookingId,
//             error
//         }
//     };
// }

export function setIsOpenClientArrived(value) {
    return {
        type: types.SET_IS_OPEN_CLIENT_ARRIVED,
        payload: {
            value
        }
    };
}

export function setIsOpenCheckout(value) {
    return {
        type: types.SET_IS_OPEN_CHECK_OUT,
        payload: {
            value
        }
    };
}

export function setFilteredStaff(value) {
    return {
        type: types.SET_FILTERED_STAFF,
        payload: {
            value
        }
    };
}

export function setFindBookingId(bkId) {
    return async function (dispatch) {
        if (!!bkId) {
            setTimeout(() => {
                dispatch(_setFindBookingId(null));
            }, TIME_LIFE_FIND_BOOKING);
        }
        dispatch(_setFindBookingId(bkId));
    };
}

function _setFindBookingId(bkId) {
    return {
        type: types.SET_FIND_BOOKING_ID,
        payload: {
            bkId
        }
    };
}

export function agreeMove({ success, error }) {
    return async function (dispatch, getState) {
        const { calendar, auth, staff, service } = getState();
        const selectedDate = calendar?.selectedDate;
        const permissions = auth?.user?.permissions;
        const isHasStaffServiceDurationPermission = permissions?.includes(MERCHANT_PERMISSIONS.STAFF_SERVICE_DURATION);
        const staffs = staff?.list;
        const services = service?.serviceList;
        const staffsServiceDuration = convertStaffsServiceDuration({ services, staffs });
        const servicesMapping = convertServicesMapping(services);
        const scheduleType = calendar?.scheduleType;
        const cloneTime = calendar?.cloneTime;
        const cloneStaff = calendar?.cloneStaff;
        const cloneBooking = calendar?.cloneBooking;

        if (!cloneTime || !cloneStaff || !cloneBooking) {
            console.log('wtf');
            return;
        }

        try {
            const BookingStartTime = convertTimeToFloat(cloneTime);

            const bookingStartDate = moment(
                `${getDatetimeFormat(selectedDate)} ${convertFloatingToDateTime(BookingStartTime)}`
            )
                .utc()
                .format();

            let updatedBooking = {
                startDate: bookingStartDate,
                startTime: BookingStartTime,
                phone: cloneBooking?.client?.phone,
                note: !cloneBooking?.note ? '' : cloneBooking?.note,
                title: cloneBooking?.title,
                state: BOOKING_ACTIONS.CLIENT_CONFIRMED,
                isReschedule: SCHEDULE_TYPES.MOVING === scheduleType
            };

            const newStaffId = +cloneStaff?.id;

            let formatServices = sortBy(cloneBooking?.bookingServices, `startTime`)?.map((bkSv, index) => {
                const totalDuration =
                    index === 0
                        ? 0
                        : cloneBooking?.bookingServices?.slice(0, index)?.reduce((acc, bkSvIn) => {
                              const defaultServiceDuration =
                                  servicesMapping?.[+bkSvIn?.serviceId]?.prices?.[0]?.duration;

                              const isModified =
                                  staffsServiceDuration?.[+bkSvIn?.staffId]?.[+bkSvIn?.serviceId] !== bkSvIn?.duration;

                              const duration = isHasStaffServiceDurationPermission
                                  ? isModified
                                      ? bkSvIn?.duration
                                      : staffsServiceDuration?.[+newStaffId]?.[+bkSvIn?.serviceId] ||
                                        bkSvIn?.duration ||
                                        defaultServiceDuration
                                  : bkSvIn?.duration;
                              return acc + duration;
                          }, 0);

                const updateStartTime = convertTimeToFloat(
                    convertDurationAndStartTimeToDate(BookingStartTime, totalDuration)
                );
                const love = +bkSv?.staffId !== +cloneStaff?.id && bkSv?.love ? false : bkSv?.love;

                const defaultServiceDuration = servicesMapping?.[+bkSv?.serviceId]?.prices?.[0]?.duration;

                const isModified = staffsServiceDuration?.[+bkSv?.staffId]?.[+bkSv?.serviceId] !== bkSv?.duration;

                return {
                    ...bkSv,
                    serviceId: +bkSv?.serviceId,
                    staffId: newStaffId,
                    startTime: updateStartTime,
                    love,
                    duration: isHasStaffServiceDurationPermission
                        ? isModified
                            ? bkSv.duration
                            : staffsServiceDuration?.[newStaffId]?.[+bkSv?.serviceId] ||
                              bkSv?.duration ||
                              defaultServiceDuration
                        : bkSv?.duration
                };
            });

            updatedBooking = {
                ...updatedBooking,
                bookingServices: formatServices
            };

            if (SCHEDULE_TYPES.MOVING === scheduleType) {
                dispatch(
                    updateBooking({
                        oldBooking: cloneBooking,
                        bookingId: +cloneBooking?.id,
                        body: updatedBooking,
                        successCallback: success,
                        errorCallback: error
                    })
                );
            } else {
                const newBooking = {
                    ...updatedBooking,
                    state: BOOKING_ACTIONS.CLIENT_CONFIRMED,
                    paid: BOOKING_CHECKOUT_STATES.NOT_PAY,
                    bookingServices: formatServices?.map((bkSv, index) => {
                        delete bkSv?.id;
                        if (isHasStaffServiceDurationPermission) {
                            const serviceId = bkSv?.serviceId;

                            const totalDuration =
                                index === 0
                                    ? 0
                                    : cloneBooking?.bookingServices?.slice(0, index)?.reduce((acc, bkSvIn) => {
                                          const defaultServiceDuration =
                                              servicesMapping?.[+bkSvIn?.serviceId]?.prices?.[0]?.duration;
                                          const isModified =
                                              staffsServiceDuration?.[+bkSvIn?.staffId]?.[+bkSvIn?.serviceId] !==
                                              bkSvIn?.duration;

                                          const duration = isModified
                                              ? bkSvIn?.duration
                                              : staffsServiceDuration?.[+newStaffId]?.[+bkSvIn?.serviceId] ||
                                                bkSvIn?.duration ||
                                                defaultServiceDuration;
                                          return acc + duration;
                                      }, 0);

                            const updateStartTime = convertTimeToFloat(
                                convertDurationAndStartTimeToDate(BookingStartTime, totalDuration)
                            );

                            const defaultServiceDuration = servicesMapping?.[+serviceId]?.prices?.[0]?.duration;

                            const isModified =
                                staffsServiceDuration?.[+bkSv?.staffId]?.[+bkSv?.serviceId] !== bkSv?.duration;

                            bkSv.duration = isModified
                                ? bkSv?.duration
                                : staffsServiceDuration?.[+newStaffId]?.[serviceId] ||
                                  bkSv?.duration ||
                                  defaultServiceDuration;
                            bkSv.startTime = updateStartTime;
                        }

                        bkSv.paidPrice = 0;

                        return bkSv;
                    })
                };

                dispatch(
                    createBooking({
                        body: newBooking,
                        successCallback: success,
                        errorCallback: error
                    })
                );
            }
        } catch ({ message }) {
            error && error(message);
        }
    };
}

export function handleStopMove({ currentSelected, success, error }) {
    return async function (dispatch, getState) {
        try {
            const { calendar, auth, staff, service } = getState();
            const selectedDate = calendar?.selectedDate;
            const permissions = auth?.user?.permissions;
            const isHasStaffServiceDurationPermission = permissions?.includes(
                MERCHANT_PERMISSIONS.STAFF_SERVICE_DURATION
            );
            const staffs = staff?.list;
            const services = service?.serviceList;
            const staffsServiceDuration = convertStaffsServiceDuration({ services, staffs });
            const servicesMapping = convertServicesMapping(services);
            const dragBkSv = calendar?.dragBkSv;

            // eslint-disable-next-line no-unused-vars
            let [_, startTime, staffId] = currentSelected?.split('_');

            const cloneBooking = dragBkSv?.booking;

            const bookingServices = cloneBooking?.bookingServices?.map((bkSv, index) => {
                if (+dragBkSv?.id === +bkSv?.id) {
                    const defaultServiceDuration = servicesMapping?.[+bkSv?.serviceId]?.prices?.[0]?.duration;
                    const isModified = staffsServiceDuration?.[+bkSv?.staffId]?.[+bkSv?.serviceId] !== bkSv?.duration;

                    return {
                        ...bkSv,
                        staffId: +staffId,
                        serviceId: +bkSv?.serviceId,
                        startTime: convertTimeToFloat(startTime),
                        love: +bkSv?.staffId !== +staffId && bkSv?.love ? false : bkSv?.love,
                        duration: isHasStaffServiceDurationPermission
                            ? isModified
                                ? bkSv?.duration
                                : staffsServiceDuration?.[+staffId]?.[+bkSv?.serviceId] ||
                                  bkSv?.duration ||
                                  defaultServiceDuration
                            : bkSv?.duration
                    };
                }

                return {
                    ...bkSv,
                    staffId: +bkSv?.staffId,
                    serviceId: +bkSv?.serviceId,
                    love: bkSv?.love
                };
            });
            const newBookingStartTime = sortBy(bookingServices, 'startTime')?.[0]?.startTime;
            const bookingStartDate = moment(
                `${getDatetimeFormat(selectedDate)} ${convertFloatingToDateTime(newBookingStartTime)}`
            )
                .utc()
                .format();

            let updatedBooking = {
                startDate: bookingStartDate,
                startTime: newBookingStartTime,
                phone: cloneBooking?.client?.phone,
                note: !cloneBooking?.note ? '' : cloneBooking?.note,
                title: cloneBooking?.title,
                bookingServices
            };

            dispatch(
                updateBooking({
                    oldBooking: cloneBooking,
                    bookingId: +cloneBooking?.id,
                    body: updatedBooking,
                    successCallback: success,
                    errorCallback: error
                })
            );
        } catch ({ message }) {
            error && error(message);
        }
    };
}

export function setOpenBooking(value) {
    return {
        type: types.SET_OPEN_BOOKING,
        payload: {
            value
        }
    };
}

export function setIsDragging(value) {
    return {
        type: types.SET_IS_DRAGGING,
        payload: {
            value
        }
    };
}

export function setDragBkSv(bkSv) {
    return {
        type: types.SET_DRAG_BOOKING_SERVICE,
        payload: {
            bkSv
        }
    };
}

export function setScheduleType(value) {
    return {
        type: types.SET_SCHEDULE_TYPE,
        payload: {
            value
        }
    };
}

export function setCloneBooking(value) {
    return {
        type: types.SET_CLONE_BOOKING,
        payload: {
            value
        }
    };
}

export function setCloneStaff(value) {
    return {
        type: types.SET_CLONE_STAFF,
        payload: {
            value
        }
    };
}

export function setCloneTime(value) {
    return {
        type: types.SET_CLONE_TIME,
        payload: {
            value
        }
    };
}

export function resetCalendar() {
    return {
        type: types.RESET_CALENDAR
    };
}
