import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, useMediaQuery, Dialog } from '@material-ui/core';
import { SCHEDULE_TYPES } from 'const';
import Avatar from 'components/avatar';
import { getImageUrl } from 'utils/image';
import { getFullName } from 'utils/naming';
import AddBlockedTime from '../AddBlockedTime';
import WorkingHourForm from 'views/staff/WorkingHourForm';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import PopupMenu from 'components/popper';
import ListItem from 'components/list/Item';
import { DEFAULT_COLOR } from 'utils/color';
import { useCategoriesMapping, useServicesMapping } from 'hooks/service';
import { useDefaultMinutes, useScheduleType, useStaffOriginalStaffScheduleByStaffId } from 'hooks/calendar';
import useMediaQueries from 'hooks/useMediaQueries';
import { useStaffById } from 'hooks/staff';

const useStyles = makeStyles((theme) => ({
    root: {},
    headerCell: {
        display: 'flex',
        alignItems: 'center'
    },
    userOption: {
        position: 'absolute',
        bottom: -8,
        right: -8,
        zIndex: 100,
        cursor: 'pointer',
        '& svg': {
            width: 20,
            height: 20,
            color: theme.colors.gunPower
        }
    },
    staffName: {
        height: `100%`,
        display: `block`,
        // eslint-disable-next-line no-dupe-keys
        display: `-webkit-box`,
        maxWidth: `100%`,
        margin: `0 auto`,
        lineHeight: `20px`,
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': `vertical`,
        overflow: `hidden`,
        textOverflow: `ellipsis`
    }
}));

function Header({ staffId, onRefreshStaffBlockedTimes, HEADER_HEIGHT, onRefreshWorkingHours }) {
    const classes = useStyles();
    const theme = useTheme();
    const [isShowBlockTime, setIsShowBlockTime] = React.useState(false);
    const [isEdit, setIsEdit] = React.useState(false);
    const [isShowStaffWorkingHourForm, setIsShowStaffWorkingHourForm] = React.useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const MINUTES = useDefaultMinutes();
    const scheduleType = useScheduleType();
    const staff = useStaffById(staffId);

    const { isMobile } = useMediaQueries();

    const schedule = useStaffOriginalStaffScheduleByStaffId(staffId);

    const servicesMapping = useServicesMapping();
    const categoriesMapping = useCategoriesMapping();

    const canDoCategories = React.useMemo(() => {
        if (!staff) return [];
        const colors = staff?.staffServices
            ?.map((sSv) => servicesMapping?.[sSv?.serviceId]?.categoryId)
            ?.filter(Boolean)
            ?.map((cId) => categoriesMapping?.[cId]?.color || DEFAULT_COLOR);

        return [...new Set([...colors])];
    }, [staff, servicesMapping, categoriesMapping]);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClickAway = React.useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleOpenBlockTimeForm = React.useCallback(() => {
        setIsShowBlockTime(true);
        handleClickAway();
    }, [handleClickAway]);

    const handleOpenWorkingHours = React.useCallback(() => {
        if (schedule) {
            setIsEdit(true);
        } else {
            setIsEdit(false);
        }
        setIsShowStaffWorkingHourForm(true);
        handleClickAway();
    }, [handleClickAway, schedule]);

    const handleCloseBlockTimeForm = React.useCallback(() => {
        setIsShowBlockTime(false);
    }, []);

    const isMoving = React.useMemo(() => [SCHEDULE_TYPES.MOVING, SCHEDULE_TYPES.CLONE].includes(scheduleType), [
        scheduleType
    ]);

    const handleClose = React.useCallback(() => {
        setIsEdit(false);
        setIsShowStaffWorkingHourForm(false);
    }, []);

    return (
        <div
            style={{
                width: '100%',
                height: HEADER_HEIGHT,
                flex: 1,
                background: theme.colors.background,
                justifyContent: 'center',
                position: 'relative'
            }}
            className={classes.headerCell}
        >
            <Grid
                container
                style={{ width: '100%', height: '100%' }}
                wrap="nowrap"
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
            >
                <Grid
                    item
                    onClick={handleClick}
                    style={{ position: 'relative', cursor: 'pointer', pointerEvents: isMoving ? 'none' : '' }}
                >
                    <Avatar size="small" src={getImageUrl(staff?.avatar)}>
                        {staff?.label?.slice(0, 1)}
                    </Avatar>
                </Grid>
                <Grid item style={{ height: '100%', width: '95%' }}>
                    <Typography variant="body1" className={classes.staffName}>
                        {getFullName(staff)}
                    </Typography>
                </Grid>
                <Grid item style={{ position: 'relative' }}>
                    <Grid container direction="row">
                        {canDoCategories?.slice(0, 8).map((color) => {
                            return (
                                <Grid
                                    item
                                    key={color}
                                    style={{
                                        width: 10,
                                        height: 10,
                                        marginLeft: -3,
                                        border: `1px solid ${theme.palette.common.white}`,
                                        borderRadius: 5,
                                        background: color || DEFAULT_COLOR
                                    }}
                                ></Grid>
                            );
                        })}
                    </Grid>
                </Grid>
            </Grid>

            {anchorEl && (
                <PopupMenu placement={'bottom'} anchorEl={anchorEl} handleClickAway={handleClickAway}>
                    <Grid container direction="column">
                        <Grid item onClick={handleOpenBlockTimeForm}>
                            <ListItem icon={<AddCircleIcon />} name={'Block time'} />
                        </Grid>
                        <Grid item onClick={handleOpenWorkingHours}>
                            <ListItem icon={<CalendarTodayIcon />} name={'Working hour'} />
                        </Grid>
                    </Grid>
                </PopupMenu>
            )}

            <Dialog fullScreen={isMobile} open={isShowBlockTime} onClose={handleCloseBlockTimeForm}>
                <AddBlockedTime
                    onClose={handleCloseBlockTimeForm}
                    staff={staff}
                    time={'09:00'}
                    minutes={MINUTES}
                    onUpdateOrCreatingSuccess={onRefreshStaffBlockedTimes}
                    isDialog={isMobile ? true : false}
                    classContent={isMobile ? `` : classes.modalBlockTimeContent}
                />
            </Dialog>

            <Dialog
                fullScreen={fullScreen}
                maxWidth={false}
                DialogContentBase
                open={isShowStaffWorkingHourForm}
                onClose={handleClose}
            >
                <WorkingHourForm
                    user={staff}
                    isEdit={isEdit}
                    schedule={schedule}
                    handleClose={handleClose}
                    onRefreshCurrentDateSchedule={onRefreshWorkingHours}
                    onCreateDone={onRefreshWorkingHours}
                    isCalendar={true}
                />
            </Dialog>
        </div>
    );
}

export default React.memo(Header);
