import * as types from './types';
import { fetchMerchantsApi, createMerchantApi, updateMerchantApi } from 'services/merchant';
import { getMerchantAnalyzeApi } from 'services/analyze';
import { enqueueSnackbar } from 'notifier/actions';
import {
    fetchGroupsApi,
    deleteGroupApi,
    createGroupApi,
    addMerchantToGroupApi,
    removeMerchantToGroupApi,
    updateGroupApi
} from 'services/group';

export function fetchMerchants({ successCallback, errorCallback }) {
    return async function (dispatch) {
        try {
            const response = await fetchMerchantsApi();
            dispatch(_getMerchants({ merchants: response?.items }));

            successCallback && successCallback({ merchants: response?.items });
        } catch (error) {
            errorCallback && errorCallback();
        }
    };
}

function _getMerchants({ merchants }) {
    return {
        type: types.FETCH_ACTIVE_MERCHANTS,
        payload: {
            merchants
        }
    };
}

export function createMerchant({ body, successCallback, errorCallback }) {
    return async function (dispatch) {
        try {
            const merchant = await createMerchantApi(body);
            dispatch(
                enqueueSnackbar({
                    message: `Merchant ${merchant?.title} created successfully`,
                    type: 'success',
                    duration: 5000
                })
            );
            successCallback && successCallback(merchant);
        } catch (error) {
            const message = error?.message;

            dispatch(
                enqueueSnackbar({
                    message: `${message}`,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

// function _createMerchantSuccess({ merchant }) {
//     return {
//         type: types.CREATE_MERCHANT,
//         payload: {
//             merchant
//         }
//     };
// }

export function updateMerchant({ merchantId, body, successCallback, errorCallback }) {
    return async function (dispatch) {
        try {
            const merchant = await updateMerchantApi({ merchantId, body });
            dispatch(
                _updateMerchantSuccess({
                    merchantId,
                    merchant
                })
            );
            dispatch(
                enqueueSnackbar({
                    message: `Update merchant info success`,
                    type: 'success'
                })
            );
            successCallback && successCallback();
        } catch (error) {
            const message = error?.message;

            dispatch(
                enqueueSnackbar({
                    message: `${message}`,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

function _updateMerchantSuccess({ merchantId, merchant }) {
    return {
        type: types.UPDATE_MERCHANT,
        payload: {
            merchant,
            merchantId
        }
    };
}

export function fetchMerchantAnalyze({ merchantId, startDate, endDate, successCallback, errorCallback }) {
    return async function (dispatch) {
        try {
            const response = await getMerchantAnalyzeApi({ merchantId, startDate, endDate });
            dispatch(
                _fetchMerchantAnalyzeRangeSuccess({
                    merchantId,
                    analyze: {
                        ...response
                    }
                })
            );

            successCallback && successCallback();
        } catch (error) {
            errorCallback && errorCallback();
        }
    };
}

function _fetchMerchantAnalyzeRangeSuccess({ merchantId, analyze }) {
    return {
        type: types.FETCH_MERCHANT_ANALYZE_RANGE_BY_ID,
        payload: {
            analyze,
            merchantId
        }
    };
}

export function fetchGroups({ successCallback, errorCallback }) {
    return async function (dispatch) {
        try {
            const response = await fetchGroupsApi();
            dispatch(_getGroups({ groups: response?.items }));
            successCallback && successCallback();
        } catch (error) {
            errorCallback && errorCallback();
        }
    };
}

function _getGroups({ groups }) {
    return {
        type: types.FETCH_GROUPS,
        payload: {
            groups
        }
    };
}

export function createGroup({ newGroup, successCallback, errorCallback }) {
    return async function (dispatch) {
        try {
            const createdGroup = await createGroupApi(newGroup);
            dispatch(_createGroup(createdGroup));
            successCallback && successCallback();
        } catch (error) {
            errorCallback && errorCallback();
        }
    };
}

function _createGroup(newGroup) {
    return {
        type: types.CREATE_GROUP,
        payload: {
            newGroup
        }
    };
}

export function updateGroup({ groupId, newGroup, success, error }) {
    return async function (dispatch) {
        try {
            const updatedGroup = await updateGroupApi({ groupId, newGroup });
            dispatch(_updateGroup({ groupId, updatedGroup }));
            success && success();
        } catch ({ message }) {
            error && error(message);
        }
    };
}

function _updateGroup({ groupId, updatedGroup }) {
    return {
        type: types.UPDATE_GROUP,
        payload: {
            updatedGroup,
            groupId
        }
    };
}

export function deleteGroup({ id, successCallback, errorCallback }) {
    return async function (dispatch) {
        try {
            await deleteGroupApi(id);
            dispatch(_deleteGroup(id));
            successCallback && successCallback();
        } catch ({ message }) {
            errorCallback && errorCallback(message);
        }
    };
}

function _deleteGroup(id) {
    return {
        type: types.DELETE_GROUP,
        payload: {
            id
        }
    };
}

export function addGroupMerchantToGroup({ groupMerchant, success, error }) {
    return async function (dispatch) {
        try {
            const groupMerchants = await addMerchantToGroupApi(groupMerchant);
            dispatch(_addGroupMerchantToGroup({ gId: groupMerchant?.groupId, groupMerchants }));
            success && success();
        } catch ({ message }) {
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            error && error(message);
        }
    };
}

function _addGroupMerchantToGroup({ gId, groupMerchants }) {
    return {
        type: types.CREATE_MERCHANT_GROUP,
        payload: {
            gId,
            groupMerchants
        }
    };
}

export function removeGroupMerchantToGroup({ gId, gMId, success, error }) {
    return async function (dispatch) {
        try {
            await removeMerchantToGroupApi(gMId);
            dispatch(_removeGroupMerchantToGroup({ gId, gMId }));
            success && success();
        } catch ({ message }) {
            error && error(message);
        }
    };
}

function _removeGroupMerchantToGroup({ gId, gMId }) {
    return {
        type: types.DELETE_MERCHANT_GROUP,
        payload: {
            gId,
            gMId
        }
    };
}
