import * as types from './types';

const INITIAL_STATE = {
    list: null,
    total: 0,
    isFetchingClient: false,
    clientBooking: null
};

export default function Client(state = INITIAL_STATE, { type, payload }) {
    switch (type) {
        case types.FETCH_CLIENTS: {
            return {
                ...state,
                isFetchingClient: true
            };
        }

        case types.FETCH_CLIENTS_FAILED: {
            const { error } = payload;
            return {
                ...state,
                isFetchingClient: false,
                error
            };
        }

        case types.FETCH_CLIENTS_SUCCESS: {
            const { clients, total } = payload;
            return {
                ...state,
                isFetchingClient: false,
                list: clients || [],
                total
            };
        }

        case types.CREATE_CLIENT_SUCCESS: {
            const { client } = payload;

            const list = state?.list || [];
            const total = state.total;

            const newList = [client, ...list];
            return {
                ...state,
                list: newList,
                total: total + 1
            };
        }

        case types.UPDATE_CLIENT_SUCCESS: {
            const { client, clientId } = payload;
            const list = state?.list || [];

            const newList = list?.map((member) => {
                if (member.id === clientId) return client;
                return member;
            });
            return {
                ...state,
                list: newList
            };
        }

        case types.UPDATE_CLIENT_AVATAR: {
            const { avatar, clientId } = payload;
            const list = state?.list || [];
            const newList = list?.map((client) => {
                if (client.id === clientId) {
                    client.avatar = avatar;
                }
                return client;
            });
            return {
                ...state,
                list: newList
            };
        }

        case types.DELETE_CLIENT: {
            const { clientId } = payload;
            const list = state?.list || [];
            const newList = list?.map((member) => {
                if (member.id === clientId) {
                    member.isDeleted = true;
                }
                return member;
            });
            return {
                ...state,
                list: newList
            };
        }

        case types.DELETE_CLIENT_FAILED: {
            const { clientId } = payload;
            const list = state?.list || [];
            const newList = list?.map((member) => {
                if (member.id === clientId) {
                    member.isDeleted = false;
                }
                return member;
            });
            return {
                ...state,
                list: newList
            };
        }

        case types.DELETE_CLIENT_SUCCESS: {
            const { clientId } = payload;
            const list = state?.list || [];
            const total = state.total;
            const newList = list.filter((member) => member.id !== clientId);
            return {
                ...state,
                list: newList,
                total: total - 1
            };
        }

        case types.SEARCH_CLIENT: {
            return {
                ...state,
                isFetchingClient: true
            };
        }

        case types.SEARCH_CLIENT_FAILED: {
            return {
                ...state,
                isFetchingClient: false
            };
        }

        case types.SEARCH_CLIENT_SUCCESS: {
            const { clients } = payload;
            return {
                ...state,
                isFetchingClient: false,
                list: clients || []
            };
        }

        case types.FETCH_CLIENT_BOOKINGS: {
            const { clientId } = payload;

            const { clientBooking } = state;

            return {
                ...state,
                clientBooking: {
                    ...clientBooking,
                    [clientId]: {
                        ...clientBooking?.[clientId],
                        isFetching: true
                    }
                }
            };
        }

        case types.FETCH_CLIENT_BOOKINGS_FAILED: {
            const { clientId, error } = payload;

            const { clientBooking } = state;

            return {
                ...state,
                clientBooking: {
                    ...clientBooking,
                    [clientId]: {
                        ...clientBooking?.[clientId],
                        isFetching: false,
                        error
                    }
                }
            };
        }

        case types.FETCH_CLIENT_BOOKINGS_SUCCESS: {
            const { clientId, bookings, client } = payload;

            const { clientBooking } = state;

            return {
                ...state,
                clientBooking: {
                    ...clientBooking,
                    [clientId]: {
                        ...clientBooking?.[clientId],
                        isFetching: false,
                        bookings,
                        client
                    }
                }
            };
        }

        default:
            return state;
    }
}
