import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Popover, Dialog } from '@material-ui/core';
import { mapTimeToTime12, addMinutesToTime } from 'utils/timing';
import hexToRgba from 'hex-to-rgba';
import AddBooking from '../../addBooking/index';
import { useDefaultMinutes } from 'hooks/calendar';
import useMediaQueries from 'hooks/useMediaQueries';
import { useMerchantTime12 } from 'hooks/merchant';
import { ROW_HEIGHT } from 'const';
import store from 'store/configStore';
import { useStaffById } from 'hooks/staff';

const useStyles = makeStyles((theme) => ({
    root: {},
    indexCell: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-end'
    },
    cell: {
        display: 'flex',
        alignItems: 'center',
        // paddingLeft: 5,
        transition: 0.5,
        background: theme.palette.common.white,
        // borderRight: `1px solid ${hexToRgba(theme.colors.gullGray, 0.3)}`,
        borderBottom: `1px solid ${theme.colors.border}`,
        '& .cellTime': {
            opacity: 0
        },
        '&:hover': {
            // background: theme.palette.common.white,
            cursor: 'pointer'
        },
        '&:hover .cellTime': {
            opacity: 1
        }
    },
    disabledTime: {
        background: `repeating-linear-gradient(-60deg,transparent ,transparent 4px, #D4D4D9 5px)`
    },
    doing: {
        background: theme.palette.primary.main,
        color: theme.palette.common.white
    },
    stayThere: {
        opacity: `1 !important`,
        fontWeight: 700,
        color: theme.palette.common.white,
        background: theme.palette.primary.main
    },
    dialog: {
        padding: 0
    },
    fullScreen: {
        '& .MuiPopover-paper': {
            top: `0 !important`,
            left: `0 !important`,
            width: '100vw',
            height: '100vh',
            minWidth: '100%',
            minHeight: '100%',
            maxWidth: '100%',
            maxHeight: '100%'
        }
    },
    popoverPaper: {
        borderRadius: 0
    },
    hoverEnable: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        zIndex: 2,
        borderBottom: `1px solid ${theme.colors.border}`,
        borderRight: `1px solid ${hexToRgba(theme.colors.gullGray, 0.3)}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: (props) => (props.open ? theme.palette.primary.main : ``),
        pointerEvents: 'none',
        '&:hover': {
            pointerEvents: 'auto'
        }
    }
}));

function MainCell({ staffId, time, openConfirmMovingModel }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const MINUTES = useDefaultMinutes();
    const TIME_12 = useMerchantTime12();
    const { isMobile } = useMediaQueries();

    const open = Boolean(anchorEl);
    const id = open ? `booking-popover` : undefined;

    const classes = useStyles({ open });

    console.log('Re-render MainCell');

    const timeLabel = React.useMemo(() => {
        return TIME_12 ? mapTimeToTime12(time) : time;
    }, [TIME_12, time]);

    const handleClick = React.useCallback(
        (event) => {
            const isMoving = store?.getState()?.calendar?.scheduleType;

            if (!isMoving) {
                setAnchorEl(event.currentTarget);
                return;
            }

            openConfirmMovingModel && openConfirmMovingModel({ time, staffId });
        },
        [openConfirmMovingModel, time, staffId]
    );

    const handleClose = React.useCallback(() => {
        setAnchorEl(null);
    }, []);

    const numbers = React.useMemo(() => {
        return MINUTES / 5;
    }, [MINUTES]);

    const arr = React.useMemo(() => {
        return [...Array(numbers).keys()];
    }, [numbers]);

    return (
        <>
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    flex: 1,
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column'
                }}
                className={`${classes.cell} ${classes.disabledTime} ${open ? classes.doing : ''}`}
                onClick={handleClick}
            >
                {arr?.map((i) => {
                    const newTime = addMinutesToTime({ time, minutes: i * 5 });
                    return (
                        <div
                            className={`cell_${newTime}_${staffId}`}
                            key={newTime}
                            style={{ height: `${ROW_HEIGHT / numbers}px`, fontSize: 10, width: '100%' }}
                        />
                    );
                })}
                <div className={classes.hoverEnable}>
                    <Typography variant="body1" className={`cellTime ${open ? classes.stayThere : ''}`}>
                        {timeLabel}
                    </Typography>
                </div>
            </div>
            {isMobile ? (
                <Dialog fullScreen className={classes.dialog} open={open} onClose={handleClose}>
                    <AddBookingWrapper onClose={handleClose} time={time} staffId={staffId} isDialog={true} />
                </Dialog>
            ) : (
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    className={isMobile ? classes.fullScreen : ''}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'right'
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'left'
                    }}
                    classes={{ paper: classes.popoverPaper }}
                >
                    <AddBookingWrapper onClose={handleClose} time={time} staffId={staffId} />
                </Popover>
            )}
        </>
    );
}

function AddBookingWrapper({ onClose, isDialog = false, time, staffId }) {
    const staff = useStaffById(staffId);

    return <AddBooking onClose={onClose} staff={staff} time={time} isDialog={isDialog} />;
}

export default React.memo(MainCell);
