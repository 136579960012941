import request from './request';
import { objectToQueryParamsSearch, encodeURL } from 'utils/queryParams';
import { PAGINATION_PAGE_DEFAULT, PAGINATION_LIMIT_DEFAULT } from 'const';
import isEmpty from 'lodash/isEmpty';

const clientApis = {
    clients: '/clients',
    customClients: '/custom-client',
    client: (clientId) => `/clients/${clientId}`,
    upload: (clientId) => `clients/${clientId}/avatar/upload`,
    clientBookings: `/client-booking`,
    import: (merchantId) => `/clients/import/${merchantId}`,
    search: '/clients/search',
    export: (merchantId) => `/clients/export/${merchantId}`
};

export async function getClientBookingsApi(clientId) {
    const relations = 'bookings,bookings.bookingServices';
    const response = await request.get(`${clientApis.client(clientId)}?relations=${relations}`);
    return response.data;
}

export async function uploadAvatarApi({ file, clientId }) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    const response = await request.put(clientApis.upload(clientId), formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
}

export async function getClientsApi({
    page = PAGINATION_PAGE_DEFAULT,
    limit = PAGINATION_LIMIT_DEFAULT,
    isPhoneNotNull = false,
    search = ''
}) {
    let extraUrl = `${clientApis.search}?page=${page}&limit=${limit}`;
    if (isPhoneNotNull) {
        extraUrl += `&isPhoneNotNull=${isPhoneNotNull}`;
    }
    if (search) {
        extraUrl += `&search=${search}`;
    }
    const response = await request.get(extraUrl);
    return response?.data;
}

export async function createClientApi({ body }) {
    const response = await request.post(`${clientApis.clients}`, body);
    return response.data;
}

export async function updateClientApi({ clientId, body }) {
    const response = await request.put(`${clientApis.client(clientId)}`, body);
    return response.data;
}

export async function deleteClientApi({ clientId }) {
    const response = await request.delete(clientApis.client(clientId));
    return response.data;
}

export async function searchClientApi({
    query,
    page = PAGINATION_PAGE_DEFAULT,
    limit = PAGINATION_LIMIT_DEFAULT,
    isPhoneNotNull = false,
    isIgnoreBlockClient = false
}) {
    let extraUrl = `${clientApis.clients}?page=${page}&limit=${limit}&isPhoneNotNull=${isPhoneNotNull}&isIgnoreBlockClient=${isIgnoreBlockClient}`;

    if (!isEmpty(query)) {
        extraUrl += `&query=${encodeURL(query)}`;
    }
    const response = await request.get(extraUrl);
    return response?.data;
}

export async function customSearchClientApi({ searchCondition }) {
    const params = objectToQueryParamsSearch(searchCondition);
    const response = await request.get(`${clientApis.customClients}?${params}&phone[$ne]=null]`);

    return response.data;
}

export async function importClient({ file, merchantId }) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    const response = await request.post(clientApis.import(merchantId), formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
}

export const exportClientsApi = async (merchantId) => {
    const res = await request.get(clientApis.export(merchantId));
    const csv = res.data;
    const blob = new Blob([csv], { type: 'text/csv' });

    // Create a URL for the Blob
    const url = window.URL.createObjectURL(blob);

    // Create a download link
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = 'clients.csv'; // Specify the file name
    // Append the link to the body
    document.body.appendChild(a);

    // Programmatically click the link to trigger the download
    a.click();

    // Clean up and revoke the object URL
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
};
