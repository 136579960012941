/**
 * HIDE PRICE DATES
 */
export const FETCH_HIDE_PRICE_DATE = '[HIDE-PRICE-DATE]FETCH_HIDE_PRICE_DATE';
export const FETCH_HIDE_PRICE_DATE_FAILED = '[HIDE-PRICE-DATE]FETCH_HIDE_PRICE_DATE_FAILED';
export const FETCH_HIDE_PRICE_DATE_SUCCESS = '[HIDE-PRICE-DATE]FETCH_HIDE_PRICE_DATE_SUCCESS';

export const ADD_HIDE_PRICE_DATE = '[HIDE-PRICE-DATE]ADD_HIDE_PRICE_DATE';
export const ADD_HIDE_PRICE_DATE_FAILED = '[HIDE-PRICE-DATE]ADD_HIDE_PRICE_DATE_FAILED';
export const ADD_HIDE_PRICE_DATE_SUCCESS = '[HIDE-PRICE-DATE]ADD_HIDE_PRICE_DATE_SUCCESS';

export const UPDATE_HIDE_PRICE_DATE = '[HIDE-PRICE-DATE]UPDATE_HIDE_PRICE_DATE';
export const UPDATE_HIDE_PRICE_DATE_FAILED = '[HIDE-PRICE-DATE]UPDATE_HIDE_PRICE_DATE_FAILED';
export const UPDATE_HIDE_PRICE_DATE_SUCCESS = '[HIDE-PRICE-DATE]UPDATE_HIDE_PRICE_DATE_SUCCESS';

export const DELETE_HIDE_PRICE_DATE = '[HIDE-PRICE-DATE]DELETE_HIDE_PRICE_DATE';
export const DELETE_HIDE_PRICE_DATE_FAILED = '[HIDE-PRICE-DATE]DELETE_HIDE_PRICE_DATE_FAILED';
export const DELETE_HIDE_PRICE_DATE_SUCCESS = '[HIDE-PRICE-DATE]DELETE_HIDE_PRICE_DATE_SUCCESS';
