import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Fab, Grid } from '@material-ui/core';
import { useCalendarOptions, useClientArrivedBookings, useIsOpenClientArrived, useSelectedDate } from 'hooks/calendar';
import { useIsHavingInternalPermission, useIsHavingPermission } from 'hooks/auth';
import NavigationIcon from '@material-ui/icons/Navigation';
import isEmpty from 'lodash/isEmpty';
import useMediaQueries from 'hooks/useMediaQueries';
import { useServiceCategoryList } from 'hooks/service';
import { useStaffOptions } from 'hooks/staff';
import ClientArrived from './ClientArrived';
import { useDispatch } from 'react-redux';
import * as calendarActions from 'views/calender/actions';
import { MERCHANT_PERMISSIONS, ROW_HEIGHT } from 'const';

const useStyles = makeStyles((theme) => ({
    root: {}
}));

function Button() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { clientsArrived, clientDetail } = useClientArrivedBookings();
    const isOpenClientArrived = useIsOpenClientArrived();

    const isAdvancedInternal = useIsHavingPermission(MERCHANT_PERMISSIONS.INTERNAL);
    const isHavingInternalPermission = useIsHavingInternalPermission();
    const { isMobile } = useMediaQueries();
    const calenderOptions = useCalendarOptions();

    const selectedDate = useSelectedDate();
    const { serviceList, categoryList } = useServiceCategoryList();
    const staffListOptions = useStaffOptions();

    const handleClose = React.useCallback(() => {
        dispatch(calendarActions.setIsOpenClientArrived(false));
    }, [dispatch]);

    const handleOpen = React.useCallback(() => {
        dispatch(calendarActions.setIsOpenClientArrived(true));
    }, [dispatch]);

    if (!(!isOpenClientArrived && isAdvancedInternal)) return null;

    return (
        <Grid item>
            <Fab
                variant="extended"
                size="small"
                color="primary"
                aria-label="add"
                className={classes.margin}
                onClick={handleOpen}
            >
                <NavigationIcon className={classes.extendedIcon} />
                Serving ({isEmpty(clientsArrived) ? 0 : Object.keys(clientsArrived)?.length})
            </Fab>

            {isOpenClientArrived && isHavingInternalPermission && (
                <ClientArrived
                    onClose={handleClose}
                    isMobile={isMobile}
                    selectedDate={selectedDate}
                    calenderOptions={calenderOptions}
                    clientsArrived={clientsArrived}
                    clientDetail={clientDetail}
                    columnWidth={ROW_HEIGHT}
                    serviceList={serviceList}
                    staffList={staffListOptions}
                    categoryList={categoryList}
                />
            )}
        </Grid>
    );
}

export default Button;
