import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import NativeSelect from 'components/select/NativeForm';
import NativeOptionGroupForm from 'components/select/NativeOptionGroupForm';
import { timeOptions } from 'data/time';
import { mapTimeToTime12, convertDurationToHrsMins } from 'utils/timing';
import FavoriteOutlined from '@material-ui/icons/FavoriteOutlined';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import { convertServiceGroupOptions } from 'utils/app/booking';
import ReactSelect from 'react-select';
import { BOOKING_SERVICE_STATES } from 'const';
import { useMerchantTime12 } from 'hooks/merchant';

const useStyles = makeStyles((theme) => ({
    serviceItem: {
        position: 'relative'
    },

    svSpacing: {
        paddingBottom: 12
    },
    spacing: {
        paddingBottom: 12
    },

    item: {
        cursor: 'pointer',
        position: 'relative'
    },

    activeLove: {
        color: theme.colors.red
    },
    normalLove: {
        color: theme.colors.border
    },
    dlFlex: {
        display: 'flex'
    },
    select: {
        '& > div': {
            border: 'none',
            borderBottom: `1px solid ${theme.colors.border}`,
            boxShadow: 'none',
            borderRadius: 0,
            '& > div': {
                padding: 0
            },
            '&:focus': {
                border: 'none',
                borderBottom: `1px solid ${theme.colors.black}`,
                boxShadow: 'none'
            }
        }
    },
    disabled: {
        pointerEvents: 'none'
    },
    heartIcon: {
        display: 'flex',
        cursor: 'pointer'
    }
}));

function Service({
    id,
    handleServiceChange,
    serviceId,
    staffId,
    startTime,
    handleStaffChange,
    serviceOptions,
    staffOptions,
    handleStartTimeChange,
    isCanClear,
    isNoShow,
    love,
    onLoveChange,
    serviceIndex,
    isInternal,
    price,
    disabled,
    duration,
    isHasStaffServiceDurationPermission,
    status
}) {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const matchIPad = useMediaQuery('(max-width:1024px)');
    const TIME_12 = useMerchantTime12();

    const timeOptionsWithFormat = React.useMemo(() => {
        return TIME_12
            ? timeOptions.map((time) => {
                  return {
                      ...time,
                      label: mapTimeToTime12(time.value)
                  };
              })
            : timeOptions;
    }, [TIME_12]);

    const svOptions = React.useMemo(() => {
        return convertServiceGroupOptions(serviceOptions, true);
    }, [serviceOptions]);

    const stfOptions = React.useMemo(() => {
        return staffOptions?.map((option) => {
            const stfSvIds = option?.staffServices?.map((sv) => +sv?.serviceId);
            if (!stfSvIds?.includes(+serviceId)) {
                return {
                    ...option,
                    label: `⚠️ ${option?.label}`
                };
            }
            return option;
        });
    }, [serviceId, staffOptions]);

    return (
        <Grid key={id} item className={classes.svSpacing}>
            <Grid container spacing={2} direction="column" wrap="nowrap" className={classes.serviceItem}>
                <Grid item>
                    <Grid spacing={2} container direction="row" alignItems="center">
                        <Grid item style={{ flex: 1 }}>
                            {!matchIPad ? (
                                <Grid container direction="column" spacing={1}>
                                    <Grid item>
                                        <>
                                            <Typography variant="body1" display="inline">
                                                {t(`service`)}
                                            </Typography>{' '}
                                            {isInternal && price && status === BOOKING_SERVICE_STATES.DONE && (
                                                <Typography
                                                    style={{ color: theme.colors.blue }}
                                                    variant="body1"
                                                    display="inline"
                                                >
                                                    (Paid: ${price})
                                                </Typography>
                                            )}
                                            {isHasStaffServiceDurationPermission && (
                                                <Typography
                                                    style={{ color: theme.colors.blue }}
                                                    variant="body1"
                                                    display="inline"
                                                >
                                                    ({convertDurationToHrsMins(duration)})
                                                </Typography>
                                            )}
                                        </>
                                    </Grid>
                                    <Grid item>
                                        <ReactSelect
                                            className={classes.select}
                                            onChange={(option) => {
                                                handleServiceChange({
                                                    id,
                                                    serviceId: Number(option?.value)
                                                });
                                            }}
                                            value={serviceOptions?.find((option) => +option?.id === +serviceId)}
                                            options={svOptions}
                                            styles={{
                                                menuPortal: (base) => ({
                                                    ...base,
                                                    zIndex: 9999,
                                                    minWidth: 500,
                                                    borderRadius: theme.radius(3)
                                                }),
                                                groupHeading: (base) => ({
                                                    ...base,
                                                    lineHeight: `1.57143`,
                                                    fontSize: `0.875rem`,
                                                    fontFamily: `"Public Sans", sans-serif`,
                                                    fontWeight: 400,
                                                    verticalAlign: 'inherit',
                                                    borderBottom: 'none',
                                                    color: `rgb(99, 115, 129)`
                                                }),
                                                option: (base) => ({
                                                    ...base,
                                                    lineHeight: `1.57143`,
                                                    fontSize: `0.875rem`,
                                                    fontFamily: `"Public Sans", sans-serif`,
                                                    fontWeight: 600,
                                                    verticalAlign: 'inherit',
                                                    borderBottom: 'none',
                                                    color: `rgb(33, 43, 54)`,
                                                    textTransform: 'none'
                                                })
                                            }}
                                            placeholder={`Choose service`}
                                            isMulti={false}
                                            components={{
                                                DropdownIndicator: () => (
                                                    <ArrowDropDown style={{ color: theme.colors.dropdown }} />
                                                ),
                                                IndicatorSeparator: null
                                            }}
                                            menuPortalTarget={document.body}
                                            menuPosition={'absolute'}
                                            menuPlacement={'bottom'}
                                            isDisabled={disabled}
                                        />
                                    </Grid>
                                </Grid>
                            ) : (
                                <NativeOptionGroupForm
                                    onChange={(e) =>
                                        handleServiceChange({
                                            id,
                                            serviceId: Number(e.target.value)
                                        })
                                    }
                                    selectedValue={serviceId}
                                    options={svOptions}
                                    label={
                                        <>
                                            <Typography variant="body1" display="inline">
                                                {t(`service`)}
                                            </Typography>{' '}
                                            {isInternal && price && status === BOOKING_SERVICE_STATES.DONE && (
                                                <Typography
                                                    style={{ color: theme.colors.blue }}
                                                    variant="body1"
                                                    display="inline"
                                                >
                                                    (Paid: ${price})
                                                </Typography>
                                            )}
                                            {isHasStaffServiceDurationPermission && (
                                                <Typography
                                                    style={{ color: theme.colors.blue }}
                                                    variant="body1"
                                                    display="inline"
                                                >
                                                    ({convertDurationToHrsMins(duration)})
                                                </Typography>
                                            )}
                                        </>
                                    }
                                    placeholder={`Choose a service`}
                                    disabled={!staffId || isNoShow || disabled}
                                />
                            )}
                        </Grid>

                        <Grid item>
                            <NativeSelect
                                onChange={(e) => handleStartTimeChange({ id, startTime: e.target.value })}
                                selectedValue={startTime}
                                options={timeOptionsWithFormat}
                                label={t(`start_time`)}
                                placeholder={`Choose an option`}
                                disabled={isNoShow || disabled}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item>
                    <NativeSelect
                        onChange={(e) => handleStaffChange({ id, staffId: Number(e.target.value) })}
                        selectedValue={staffId}
                        options={stfOptions}
                        label={
                            <Grid alignItems="center" container wrap="nowrap" spacing={1}>
                                <Grid item>
                                    <Typography variant="body1">{t(`staff`)}</Typography>
                                </Grid>
                                <Grid
                                    item
                                    className={`${classes.heartIcon} ${disabled ? classes.disabled : ``}`}
                                    onClick={(e) => {
                                        onLoveChange({ id });
                                    }}
                                >
                                    <FavoriteOutlined className={love ? classes.activeLove : classes.normalLove} />
                                </Grid>
                            </Grid>
                        }
                        placeholder={`Choose a staff`}
                        disabled={isNoShow || disabled}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(Service);
