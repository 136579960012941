import React from 'react';
import { makeStyles, alpha } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import CheckIconSVG from 'assets/images/svg/CheckIconSVG';
import { useCategoriesColor } from 'hooks/app';

const useStyles = makeStyles((theme) => ({
    root: {},
    color: {
        width: (props) => props.width || 48,
        height: (props) => props.height || 48,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& svg': {
            width: (props) => props.svg || 25,
            height: (props) => props.svg || 25,
            '& path': {
                fill: theme.palette.common.white
            }
        }
    }
}));

function ColorPalette({ selectedColor, className, onChange, rows = 1, ...rest }) {
    const classes = useStyles(rest);
    const colorPaletteOptions = useCategoriesColor();
    const handleColorChange = (props) => {
        onChange && onChange(props);
    };
    return (
        <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="flex-start"
            className={`${classes.root} ${className}`}
            // spacing={2}
        >
            {colorPaletteOptions.map((color) => {
                const isSelected = color === selectedColor;
                return (
                    <Grid key={color} onClick={(e) => handleColorChange({ e, color })} item>
                        <div
                            style={{
                                border: `1px solid ${color}`,
                                background: alpha(color, 0.7)
                            }}
                            className={classes.color}
                        >
                            {isSelected ? <CheckIconSVG /> : ''}
                        </div>
                    </Grid>
                );
            })}
        </Grid>
    );
}

export default React.memo(ColorPalette);
