import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Dialog, Typography } from '@material-ui/core';
import LayoutTabContent from 'views/layout/LayoutTabContent';
import AddNewClosedDate from './AddNew';
import StickyHeadTable from 'components/table/StickyHeadTable';
import { useTranslation } from 'react-i18next';
import { useClosedDates, useIsFetchingClosedDates, useClosedDatesForTable } from 'hooks/staff';
import * as staffActions from '../action';
import { useDispatch } from 'react-redux';
import Search from 'components/search';
import SimpleBar from 'simplebar-react';
import useMediaQueries from 'hooks/useMediaQueries';
import AddIcon from '@material-ui/icons/AddCircle';
import Button from 'components/button/Base';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%'
    },
    table: {
        width: '100%',
        marginTop: 24,
        height: `calc(100% - 48px - 24px)`,
        overflowY: 'hidden',
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
            height: `calc(100% - 48px)`,
            maxHeight: `-webkit-fill-available`
        }
    },
    container: {
        flex: 1
    },
    spacing: {
        marginBottom: theme.spacing(2)
    },
    search: {
        maxWidth: 300,
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            flex: 1
        }
    }
}));

function ClosedDates() {
    const classes = useStyles();
    const { t } = useTranslation();
    const { isMobile } = useMediaQueries();
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const isFetchingClosedDates = useIsFetchingClosedDates();
    const closedDates = useClosedDates();
    const data = useClosedDatesForTable();
    const [editedClosedDate, setEditedClosedDate] = React.useState({});
    const [isEdit, setIsEdit] = React.useState(false);

    const columns = [
        { id: 'ranges', label: t(`date_ranges`), minWidth: 200 },
        { id: 'numOfDays', label: t(`no_of_days`), minWidth: 170 },
        // { id: 'location  ', label: t(`locations`), minWidth: 170 },
        { id: 'description', label: t(`description`), minWidth: 170 }
    ];

    const handleClickOpen = () => {
        setIsEdit(false);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    React.useEffect(() => {
        dispatch(
            staffActions.fetchClosedDates({
                successCallback: () => {
                    console.log('fetchClosedDates success');
                },
                errorCallback: () => {
                    console.log('fetchClosedDates failed');
                }
            })
        );
    }, [dispatch]);

    const headerRenderer = ({ column }) => {
        return <Typography variant="body1">{column.label}</Typography>;
    };

    const onRowClicked = ({ rowData }) => {
        setIsEdit(true);
        setOpen(true);
        setEditedClosedDate(rowData);
    };

    return (
        <LayoutTabContent isLoading={isFetchingClosedDates && closedDates === null}>
            <Grid container className={classes.root} direction="column" wrap="nowrap">
                <Grid item className={isMobile ? classes.spacing : ''}>
                    <Grid
                        container
                        direction="row"
                        wrap="nowrap"
                        spacing={2}
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Grid item className={classes.search}>
                            <Search />
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleClickOpen}
                                startIcon={<AddIcon />}
                            >
                                Close dates
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.table}>
                    <SimpleBar style={{ maxHeight: '100%' }}>
                        <StickyHeadTable
                            classNameContainer={classes.container}
                            headerRenderer={headerRenderer}
                            rows={data}
                            columns={columns}
                            onRowClicked={onRowClicked}
                        />
                    </SimpleBar>
                </Grid>
                <Dialog fullScreen={isMobile} maxWidth={false} open={open} onClose={handleClose}>
                    <AddNewClosedDate isEdit={isEdit} editedClosedDate={editedClosedDate} onClose={handleClose} />
                </Dialog>
            </Grid>
        </LayoutTabContent>
    );
}

export default React.memo(ClosedDates);
