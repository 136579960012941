import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import StorefrontIcon from '@material-ui/icons/Storefront';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import Typography from '@material-ui/core/Typography';
import {
    getFriendlyShortTime,
    mapTimeToTime12WithoutAMPm,
    convertFloatingToDateTime,
    convertDurationAndStartTimeToDate
} from 'utils/timing';
import { BOOKING_LOGS_STATE_NUMBER, BOOKING_STATE_LABEL } from 'const';
import { Grid, Tooltip } from '@material-ui/core';
import { getFullName } from 'utils/naming';
import moment from 'moment-timezone';
import { orderBy } from 'lodash';
import { useStaffMapping } from 'hooks/staff';
import { useServicesMapping } from 'hooks/service';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useMerchantTime12 } from 'hooks/merchant';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

const useStyles = makeStyles((theme) => ({
    item: {
        '&:before': {
            display: 'none'
        },
        '& .MuiTimelineConnector-root': {
            height: 25
        }
    },
    secondaryTail: {
        backgroundColor: theme.palette.secondary.main
    },
    content: {
        position: 'relative'
    },
    tag: {
        borderRadius: 4,
        padding: `2px 4px`,
        width: 'fit-content'
    }
}));

function getWhoDidIt(log) {
    return getFullName(log?.state === BOOKING_LOGS_STATE_NUMBER.CLIENT ? log?.client : log?.staff);
}

function isSameDatetime(note) {
    try {
        const json = JSON.parse(note);
        const oldBooking = json.oldBooking;
        const newBooking = json.newBooking;
        return oldBooking && newBooking && moment(oldBooking?.startDate).isSame(newBooking?.startDate);
    } catch (error) {
        return true;
    }
}

function parseJson(json) {
    try {
        return JSON.parse(json);
    } catch (err) {
        return null;
    }
}

function Content({ log }) {
    const classes = useStyles();
    const theme = useTheme();
    const changeNote = log?.changeNote;
    const isChangeDateTime = !changeNote ? false : !isSameDatetime(changeNote);
    const changeNoteJson = parseJson(changeNote);

    const isChangeNoteEqualRefund = changeNote === 'refund';

    if (log?.beforeState === log?.currentState) {
        return (
            <Grid container direction="column">
                <Grid item>
                    <Grid container direction="row" alignItems="center" spacing={2}>
                        <Grid item>
                            <Typography variant="body2">
                                updated{' '}
                                {log?.state !== BOOKING_LOGS_STATE_NUMBER?.MERCHANT ? `by ` + getWhoDidIt(log) : ``}
                            </Typography>{' '}
                        </Grid>
                        {log?.userAgent && (
                            <Grid item>
                                <Tooltip placement="top" title={log?.userAgent}>
                                    <AssignmentIndIcon />
                                </Tooltip>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                {isChangeDateTime && (
                    <Grid item>
                        <Typography variant="body2">
                            Time changed: <strong>{getFriendlyShortTime(changeNoteJson?.oldBooking?.startDate)}</strong>{' '}
                            → <strong>{getFriendlyShortTime(changeNoteJson?.newBooking?.startDate)}</strong>
                        </Typography>
                    </Grid>
                )}

                {isChangeNoteEqualRefund && (
                    <Typography className={classes.tag} style={{ background: theme.colors.sun }} variant="body2">
                        Refunded
                    </Typography>
                )}
            </Grid>
        );
    } else {
        return (
            <Grid container direction="column">
                <Grid item>
                    <Typography variant="body2">
                        updated {log?.state !== BOOKING_LOGS_STATE_NUMBER?.MERCHANT ? `by ` + getWhoDidIt(log) : ``}
                    </Typography>
                </Grid>
                {isChangeDateTime && (
                    <Grid item>
                        <Typography variant="body2">
                            Time changed: <strong>{getFriendlyShortTime(changeNoteJson?.oldBooking?.startDate)}</strong>{' '}
                            → <strong>{getFriendlyShortTime(changeNoteJson?.newBooking?.startDate)}</strong>
                        </Typography>
                    </Grid>
                )}
                {isChangeNoteEqualRefund && (
                    <Typography className={classes.tag} style={{ background: theme.colors.sun }} variant="body2">
                        Refunded
                    </Typography>
                )}

                <Grid item>
                    <Typography variant="body2">
                        From{' '}
                        <span
                            className={classes.tag}
                            style={{
                                background: theme.booking?.[log?.beforeState]
                            }}
                        >
                            {BOOKING_STATE_LABEL?.[log?.beforeState]}
                        </span>{' '}
                        →{' '}
                        <span
                            className={classes.tag}
                            style={{
                                background: theme.booking?.[log?.currentState]
                            }}
                        >
                            {BOOKING_STATE_LABEL?.[log?.currentState]}
                        </span>
                    </Typography>
                </Grid>
            </Grid>
        );
    }
}

function Icon({ log }) {
    switch (log?.state) {
        case BOOKING_LOGS_STATE_NUMBER.CLIENT:
            return <EmojiPeopleIcon />;
        case BOOKING_LOGS_STATE_NUMBER.STAFF:
            return <RecordVoiceOverIcon />;
        default:
            return <StorefrontIcon />;
    }
}

function BookingService({ bkSv }) {
    const staffMapping = useStaffMapping();
    const serviceMapping = useServicesMapping();
    const TIME_12 = useMerchantTime12();

    const service = React.useMemo(() => {
        return serviceMapping?.[bkSv?.serviceId];
    }, [serviceMapping, bkSv]);

    const staff = React.useMemo(() => {
        return staffMapping?.[bkSv?.staffId];
    }, [staffMapping, bkSv]);

    return (
        <Grid container direction="column">
            <Grid item>
                <Typography variant="body2">
                    {`${
                        TIME_12
                            ? mapTimeToTime12WithoutAMPm(convertFloatingToDateTime(bkSv.startTime))
                            : convertFloatingToDateTime(bkSv.startTime)
                    } - ${
                        TIME_12
                            ? mapTimeToTime12WithoutAMPm(
                                  convertDurationAndStartTimeToDate(bkSv.startTime, bkSv?.duration)
                              )
                            : convertDurationAndStartTimeToDate(bkSv.startTime, bkSv?.duration)
                    }`}
                </Typography>
            </Grid>
            <Grid item>
                <Typography align="left" variant="body2">
                    {service?.title ? service?.title : '❌ service'} with {getFullName(staff)}
                </Typography>
            </Grid>
        </Grid>
    );
}

function Detail({ log }) {
    const classes = useStyles();

    const changeNote = log?.changeNote;
    const isChangeNoteEqualRefund = changeNote === 'refund';

    const changeNoteJson = parseJson(changeNote);

    const TABLE_COLUMNS = [
        {
            width: 100,
            label: 'Bk Service',
            id: 'index'
        },
        {
            label: 'Before',
            id: 'before',
            format: ({ value }) => {
                if (!value) return null;

                return <BookingService bkSv={value} />;
            }
        },
        {
            label: `After`,
            id: 'after',
            format: ({ value }) => {
                if (!value) return null;
                return <BookingService bkSv={value} />;
            }
        }
    ];

    const bookingServices = React.useMemo(() => {
        const oldBookingServices = changeNoteJson?.oldBooking?.bookingServices || [];
        const newBookingServices = changeNoteJson?.newBooking?.bookingServices || [];
        const newBookingServiceIds = newBookingServices?.map((bkSv) => bkSv?.id);
        const oldBookingServicesIds = oldBookingServices?.map((bkSv) => bkSv?.id);

        const addedBkSvs = newBookingServices?.filter((bkSv) => !oldBookingServicesIds?.includes(bkSv?.id));

        const bkSvs = [];

        let index = 1;

        for (const bkSv of oldBookingServices) {
            const bkSvId = bkSv?.id;
            let obj = {
                id: bkSvId,
                index
            };

            if (newBookingServiceIds?.includes(bkSvId)) {
                obj[`before`] = bkSv;
                obj[`after`] = newBookingServices?.find((bkSv) => bkSv?.id === bkSvId);
            } else {
                obj[`before`] = bkSv;
                obj[`after`] = null;
            }
            index++;
            bkSvs.push(obj);
        }

        for (const bkSv of addedBkSvs) {
            const bkSvId = bkSv?.id;
            let obj = {
                id: bkSvId,
                index
            };

            obj[`before`] = null;
            obj[`after`] = bkSv;
            index++;
            bkSvs.push(obj);
        }

        return bkSvs;
    }, [changeNoteJson]);

    if (!changeNote || isChangeNoteEqualRefund || !bookingServices?.length) return null;

    return (
        <Paper>
            <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow sx={{}}>
                            {TABLE_COLUMNS?.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    className={classes.tableCell}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {bookingServices?.map((row) => {
                            return (
                                <TableRow role="checkbox" tabIndex={-1} key={row.id}>
                                    {TABLE_COLUMNS.map((column) => {
                                        const value = row?.[column.id];

                                        return (
                                            <TableCell style={{ border: 'none' }} key={column.id} align={column.align}>
                                                {column.format
                                                    ? column.format({
                                                          rowData: row,
                                                          value
                                                      })
                                                    : value}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}

function BookingLogs({ logs, createdAt, showDetail = false }) {
    const classes = useStyles();

    const reverseLogs = React.useMemo(() => {
        return orderBy(
            logs,
            [
                (obj) => {
                    return new Date(obj.createdAt);
                }
            ],
            ['desc']
        );
    }, [logs]);

    return (
        <Timeline style={{ paddingLeft: 0, paddingRight: 0 }}>
            {reverseLogs?.map((log, index) => {
                console.log('log?.userAgent', log?.userAgent);
                return (
                    <TimelineItem key={log?.id} className={classes.item}>
                        <TimelineSeparator>
                            <TimelineDot variant="outlined" color="primary">
                                <Icon log={log} />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent className={classes.content}>
                            <Typography variant="caption">{getFriendlyShortTime(log?.createdAt)}</Typography>
                            <Content log={log} />
                            {showDetail && <Detail log={log} />}
                        </TimelineContent>
                    </TimelineItem>
                );
            })}

            <TimelineItem className={classes.item}>
                <TimelineSeparator>
                    <TimelineDot variant="outlined" color="primary">
                        <StorefrontIcon />
                    </TimelineDot>
                </TimelineSeparator>
                <TimelineContent className={classes.content}>
                    <Typography variant="caption">{getFriendlyShortTime(createdAt)}</Typography>
                    <Typography variant="body2">Created</Typography>
                </TimelineContent>
            </TimelineItem>
        </Timeline>
    );
}

export default BookingLogs;
