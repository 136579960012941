import * as types from './types';
import { loginApi, switchMerchantApi } from 'services/auth';
import { setItem } from 'utils/localStorage';
import { TOKEN_USER, TOKEN_NAME } from 'const';
import { enqueueSnackbar } from 'notifier/actions';
import moment from 'moment-timezone';

export function login({ phone, password, errorCallback, successCallback }) {
    return async function (dispatch) {
        try {
            const data = await loginApi({ phone, password });
            console.log('data', data);
            setItem(TOKEN_USER, data.user);
            setItem(TOKEN_NAME, data.access_token);
            dispatch(_loginActionSuccess({ user: data.user }));
            successCallback && successCallback();
        } catch (error) {
            const message = error?.message;
            dispatch(
                enqueueSnackbar({
                    message: `[login] ${message}`,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

function _loginActionSuccess({ user }) {
    return {
        type: types.USER_LOGIN_SUCCESS,
        payload: {
            user
        }
    };
}

export function switchMerchant({ body, errorCallback, successCallback }) {
    return async function (dispatch) {
        try {
            const data = await switchMerchantApi(body);
            setItem(TOKEN_USER, data.user);
            setItem(TOKEN_NAME, data.access_token);
            dispatch(_loginActionSuccess({ user: data.user }));
            moment.tz.setDefault(data?.user?.timezone || `America/Toronto`);

            successCallback && successCallback();
        } catch (error) {
            const message = error?.message;
            dispatch(
                enqueueSnackbar({
                    message: `[login] ${message}`,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}
