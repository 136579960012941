export default {
    translations: {
        help: 'Help',
        //global
        add_new: 'Add New',
        today: 'Today',
        export: 'Export',
        staff: 'Staff',
        gifts: 'Gifts',
        add: 'Add',
        back: 'Back',
        hello: 'Hello',
        services: 'Services',
        service: 'Service',
        category: 'Category',
        calendar: 'Calendar',
        client_name: 'Client Name',
        clients: 'Clients',
        client: 'Client',
        print: 'Print',
        online_booking: 'Online Booking',
        confirm_arrived: 'Arrived',
        no_show: 'No Show',
        undo_no_show: 'Undo No Show',
        pdf: 'PDF',
        csv: 'CSV',
        excel: 'EXCEL',
        duration: 'Duration',
        create: 'Create',
        save: 'Save',
        done: 'Done',
        update: 'Update',
        date: 'Date',
        delete: 'Delete',
        edit: 'Edit',
        cancel: 'Cancel',
        close: 'Close',
        options: 'Options',
        download: 'Download',
        suburd: 'Suburd',
        booking: `Booking`,
        blocked_time: `Blocked Time`,
        choose_booking_time: `Choose booking time`,
        city: 'City',
        state: 'State',
        zip_code: 'Zip / Post Code',
        upload: 'Upload',
        import: 'Import',
        merge: 'Merge',
        locations: 'Locations',
        description: 'Description',
        start_date: 'Start Date',
        start_time: 'Start Time',
        end_time: 'End Time',
        end_date: 'End Date',
        choose_an_option: 'Choose an option',
        are_you_sure_to_delete: `Are you sure to delete`,
        are_you_sure_to_block: `Are you sure to block`,
        this_action_will_remove: `This action will remove`,
        this_action_will_block: `This action will block`,
        are_you_sure_to_duplicate: `Are you sure to duplicate`,
        this_action_will_duplicate: `This action will duplicate`,
        are_you_sure_to_deactivate: `Are you sure to deactivate`,
        this_action_will_deactivate: `This action will deactivate`,
        this_action_will_activate: `This action will activate`,
        are_you_sure_to_activate: `Are you sure to activate`,

        forever: `forever`,
        hours: `Hours`,
        minutes: `Minutes`,
        shift_start: 'Shift Start',
        shift_end: 'Shift End',
        repeats: `Repeats`,
        end_repeat: `End Repeat`,
        next: 'Next',
        password: 'Password',
        logout: 'Log out',
        large: 'Large',
        medium: 'Medium',
        small: 'Small',
        from: 'From',
        to: 'To',
        oldPassword: 'Old password',
        newPassword: 'New password',
        confirmPassword: 'Confirm password',

        //sidebar Right
        nav_overview: 'Overview',
        nav_calendar: 'Calendar',
        nav_services: 'Services',
        nav_clients: 'Clients',
        nav_staff: 'Staff',
        nav_plus: 'Advanced',
        nav_basic: 'Basic',
        nav_analytics: 'Analytics',
        nav_payment_histories: 'Payment histories',
        nav_hide_price_setting: 'Prices',
        nav_marketing: 'Marketing',
        nav_voucher: 'Voucher',
        name: 'Name',
        email: 'Email',
        username_or_email: 'Username or email',
        email_placeholder: 'mail@example.com',
        mobile_number: 'Mobile Number',
        appointments: 'Appointments',
        appointment: 'Appointment',
        details: 'Details',
        first_name: 'First Name',
        first_name_placeholder: 'e.g.Jane',
        last_name: 'Last Name',
        last_name_placeholder: 'e.g.Doe',
        phone_number: 'Phone Number',
        phone_number_placeholder: '+84 98 899 31 54',
        notes: 'Notes',
        employment: 'Employment',
        select_date: 'Select Date',
        dashboard: 'Dashboard',
        reports: 'Reports',
        anylatics: 'Anylatics',
        manager: 'Manager',
        avatar: `Avatar`,
        your_booking_site: `Your booking site`,
        //Calendar Detail

        filter_day: 'Day',
        filter_3day: '3 Day',
        filter_week: 'Week',
        new_appointment: 'New Appointment',
        new_blocked_time: 'New Blocked Time',
        booking_title: 'Booking Title',
        booking_note: 'Appointment Note',
        booking_note_placeholder: 'Appointment note (visible to staff only)',
        booking_title_placeholder: 'John Doe',
        no_staff_schedule: 'No Staff Scheduled',
        zoom_setting: 'Zoom Setting',
        turn_off_close_date: 'Turn off close date',
        add_another_service: 'Add service',
        reschedule: 'Reschedule',
        rebook: 'Rebook',

        //Services Detail
        new_service: 'New Service',
        new_category: 'New Category',
        choose_a_category: 'Choosing a category',
        select_staff_who_perform_this_service: 'Select staff who perform this service.',
        create_category_brefore_create_a_service: 'Please create category before create a service',
        service_name: 'Service Name',
        service_name_placeholder: 'e.g Hair Service',
        price: 'Price',
        price_type: 'Price Type',
        retail_price: 'Retail Price',
        retail_price_placeholder: '0.00',
        special_price: 'Special Price(Optional)',
        special_price_placeholder: '0.00',
        pricing_name: 'Pricing Name(Optional)',
        pricing_name_description: 'e.g Long Hair',
        add_another_pricing_option: 'Add another pricing option',
        allow_clients_to_book_this:
            'Allow clients to book this service online, switch off to hide this service from your online bookings menu.',
        enable_online_booking: 'Enable online bookings',
        service_description: 'Service description',
        service_description_placeholder: 'Description will be displayed on your Fresha profile',
        service_available_for: 'Service available for',
        //Category
        category_name: 'Category Name',
        category_name_placeholder: 'e.g Hair Service',
        category_description: 'Category Description',
        category_description_placeholder: 'e.g Hair Service',
        choose_color: 'Choose Color',
        enable_internal_only: 'Enable Internal Only',
        //Client Detail

        search_by_name_email_or_mobile_number: 'Search by name, e-mail or mobile number',
        new_client: 'New Client',

        //Staff
        staff_working_hours: 'Staff Working Hours',
        closed_dates: 'Closed Dates',
        closed_date: 'Closed Date',
        hide_price_dates: 'Hide Price Dates',
        staff_members: 'Staff Members',
        user_permission: 'User Permission',
        all_staff: 'All staff',
        all_online_booking_staff: 'All online booking staffs',
        all_staff_have_working_hours: `All staff have working hours`,
        add_another_shift: `Add another shift`,
        assign_service_this_staff: 'Assign services this staff member can perform.',
        delete_upcoming_shifts: 'Delete upcoming shifts',
        delete_this_shift_only: 'Delete this shift only',
        update_this_shift_only: 'Update this shift only',
        update_upcoming_shifts: 'Update upcoming shifts',
        create_upcoming_shifts: 'Create upcoming shifts',
        create_this_shift_only: 'Create this shift only',
        working_hours: `working hours`,

        //closed dates
        date_ranges: 'Date Range',
        no_of_days: 'No.Of Days',
        online_bookings_can_not_be_placed: 'Online bookings can not be placed during closed dates.',
        hide_price_date_warning: 'This only affect to income in staff app and merchant statistic',
        enable_appointment_bookings: 'Enable booking',
        staff_title: 'Staff Title',
        staff_title_placeholder: 'Add staff title (i.e. Hairdresser) viewable in online booking only (optional)',
        notes_staff_placeholder: 'Place to add notes about your staff. This will be shown on booking site.',
        closed_date_start_date_placeholder: 'Select start date',
        closed_date_end_date_placeholder: 'Select end date',
        closed_date_description_placeholder: `e.g. public holiday`,
        new_closed_date: 'New Closed Date',

        //Marketing
        generate_static_page: 'Generate Static Page',
        generate_embeded_link: 'Generate Embeded Link',
        //Profile
        profile: `Profile`,
        //Clients
        send_sms: 'Send Sms',
        send_email: 'Send Email',
        manage_clients: 'Manage Clients',
        gender: 'Gender',
        persional_info: 'Personal Info',
        address: 'Address',
        message: 'Message',
        message_placeholder: 'Input your sms message',
        add_new_client: `Add new client`,
        //blocked time
        blocked_time_placeholder: `e.g. lunch meeting`,

        // Voucher details
        gift_name: 'Gift name',
        voucher_name_placeholder: 'Gift name',
        value: 'Value',
        value_placeholder: 'eg. 100',
        three_months: '3 months',
        six_months: '6 months',
        nine_months: '9 months',
        one_year: '1 year',
        never: 'Never',
        multiple_usage: 'For multiple-use',
        single_usage: 'For single-use',
        redeem: 'Redeem',
        redeem_value: 'Redeem value',
        gift_value: 'Gift value',
        redeem_all_services: 'Redeem all services',
        available_in_all_location: 'Available in all locations',
        sell_gift: 'Sell gift',
        duplicate_gift: 'Duplicate gift',
        edit_gift: 'Edit gift',
        delete_gift: 'Delete gift',
        expires_in: 'Expires in',
        validation_period: 'Validation period',
        gift_history: 'Gift history',
        status: 'Status',
        bookings: 'Bookings'
    }
};
