import React from 'react';
import { Popper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: 5000,
        borderRadius: theme.radius(3),
        padding: (props) => (props.noPadding ? 0 : `8px 12px`),
        background: theme.palette.common.white,
        overflow: 'hidden',
        boxShadow: theme.shadows[1]
    },
    paper: {}
}));

function PopperMenu({ noPadding = false, children, anchorEl, id, className, handleClickAway, ...rest }) {
    const classes = useStyles({ noPadding });
    const open = Boolean(anchorEl);
    const popperId = open ? id || 'simple-popper' : undefined;

    const onClickAway = () => {
        handleClickAway && handleClickAway();
    };

    return (
        <ClickAwayListener onClickAway={onClickAway}>
            <Popper
                className={`${classes.root} ${className}`}
                id={popperId}
                open={open}
                anchorEl={anchorEl}
                transition
                {...rest}
            >
                {children}
            </Popper>
        </ClickAwayListener>
    );
}

export default React.memo(PopperMenu);
