import React from 'react';

function GiftSVG({ color = '#41415C', ...rest }) {
    return (
        <svg height="512pt" viewBox="0 0 512 512" width="512pt" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                d="m455.8125 511.660156h-399.632812c-10.46875 0-18.953126-8.484375-18.953126-18.953125v-305.589843h437.542969v305.589843c0 10.46875-8.488281 18.953125-18.957031 18.953125zm0 0"
                fill="#ffc73b"
            />
            <path d="m37.226562 187.117188h437.542969v63.589843h-437.542969zm0 0" fill="#efb025" />
            <path
                d="m512 120.824219v78.78125c0 8.414062-6.828125 15.230469-15.242188 15.230469h-481.527343c-8.414063 0-15.230469-6.816407-15.230469-15.230469v-78.78125c0-8.414063 6.816406-15.230469 15.230469-15.230469h481.527343c8.414063 0 15.242188 6.816406 15.242188 15.230469zm0 0"
                fill="#ffc73b"
            />
            <path
                d="m238.59375 141.8125c-3.324219 3.871094-6.585938 7.796875-9.769531 11.765625-11.863281 14.761719-22.722657 30.230469-32.539063 46.285156-3.019531 4.9375-5.933594 9.925781-8.761718 14.972657h-103.839844c6.066406-13.765626 12.71875-27.277344 19.925781-40.484376 13.070313-23.960937 27.984375-46.953124 44.648437-68.757812 3.980469-5.207031 8.054688-10.351562 12.230469-15.417969 2.5-3.054687 5.042969-6.066406 7.621094-9.066406 9.476563 8.164063 18.945313 16.320313 28.425781 24.484375 1.46875 1.257812 2.9375 2.53125 4.402344 3.792969 11.1875 9.632812 22.375 19.261719 33.558594 28.90625 1.371094 1.164062 2.730468 2.347656 4.097656 3.519531zm0 0"
                fill="#efb025"
            />
            <path
                d="m393.527344 214.835938h-103.847656c-2.816407-5.046876-5.742188-10.035157-8.761719-14.972657-9.816407-16.054687-20.6875-31.523437-32.539063-46.285156-3.195312-3.96875-6.457031-7.894531-9.785156-11.765625 1.371094-1.171875 2.730469-2.347656 4.101562-3.519531 11.195313-9.644531 22.382813-19.285157 33.566407-28.90625 1.46875-1.261719 2.9375-2.535157 4.40625-3.792969 9.476562-8.164062 18.957031-16.320312 28.425781-24.484375 2.578125 2.992187 5.121094 6.011719 7.621094 9.058594 4.183594 5.074219 8.261718 10.21875 12.230468 15.425781 16.664063 21.804688 31.578126 44.796875 44.65625 68.757812 7.207032 13.207032 13.859376 26.71875 19.925782 40.484376zm0 0"
                fill="#efb025"
            />
            <g fill="#ff4440">
                <path d="m255.992188 141.820312c-3.328126 3.867188-6.589844 7.792969-9.773438 11.761719-11.859375 14.761719-22.722656 30.230469-32.539062 46.289063-31.164063 50.941406-51.863282 107.847656-60.59375 167.410156-13.121094-17.402344-25.230469-36.230469-36.125-56.363281-19.164063 12.5-37.894532 26.796875-55.929688 42.882812 9.261719-63.179687 29.667969-123.90625 59.976562-179.445312 16.1875-29.679688 35.210938-57.878907 56.875-84.175781 2.5-3.054688 5.042969-6.0625 7.621094-9.066407 10.945313 9.425781 21.882813 18.851563 32.832032 28.277344 11.183593 9.632813 22.371093 19.261719 33.558593 28.90625 1.367188 1.164063 2.726563 2.347656 4.097657 3.523437zm0 0" />
                <path d="m450.960938 353.800781c-18.035157-16.085937-36.765626-30.382812-55.929688-42.882812-10.894531 20.132812-23.003906 38.960937-36.125 56.363281-8.730469-59.5625-29.425781-116.46875-60.59375-167.410156-9.816406-16.058594-20.6875-31.527344-32.539062-46.289063-3.195313-3.96875-6.457032-7.894531-9.78125-11.761719 1.367187-1.175781 2.726562-2.351562 4.097656-3.523437 11.195312-9.644531 22.382812-19.285156 33.570312-28.90625 10.945313-9.4375 21.894532-18.851563 32.828125-28.277344 2.578125 2.992188 5.121094 6.011719 7.621094 9.058594 21.667969 26.296875 40.691406 54.496094 56.886719 84.183594 30.300781 55.539062 50.703125 116.265625 59.964844 179.445312zm0 0" />
                <path d="m215.234375 141.820312h81.527344v73.015626h-81.527344zm0 0" />
            </g>
            <path
                d="m390.996094 174.355469-92.683594 25.515625c-9.816406-16.058594-20.6875-31.527344-32.539062-46.289063l-5.683594-15.285156-4.097656-11.023437 37.667968-17.882813 40.449219-19.21875c21.667969 26.296875 40.691406 54.496094 56.886719 84.183594zm0 0"
                fill="#ea2f2f"
            />
            <path
                d="m255.992188 127.273438-4.097657 11.023437-5.675781 15.285156c-11.859375 14.761719-22.722656 30.230469-32.539062 46.289063l-92.671876-25.515625c16.1875-29.679688 35.210938-57.878907 56.875-84.175781l40.453126 19.210937zm0 0"
                fill="#ea2f2f"
            />
            <path
                d="m403.914062 3.542969-147.917968 70.246093 28.414062 76.417969 157.882813-43.457031c20.648437-5.683594 31.992187-27.84375 24.527343-47.917969l-13.03125-35.039062c-7.460937-20.074219-30.527343-29.4375-49.875-20.25zm0 0"
                fill="#ff4440"
            />
            <path
                d="m108.078125 3.542969 147.917969 70.246093-28.414063 76.417969-157.878906-43.457031c-20.648437-5.683594-31.992187-27.84375-24.527344-47.917969l13.027344-35.039062c7.464844-20.074219 30.527344-29.4375 49.875-20.25zm0 0"
                fill="#ff4440"
            />
            <path
                d="m291.316406 104.5c-2.6875 0-5.324218-1.328125-6.875-3.761719-2.425781-3.792969-1.320312-8.832031 2.472656-11.257812 1.121094-.714844 27.761719-17.667969 58.347657-30.039063 44.09375-17.835937 73.539062-16.878906 87.507812 2.84375 2.605469 3.675782 1.734375 8.765625-1.941406 11.367188-3.675781 2.601562-8.761719 1.734375-11.363281-1.941406-18.9375-26.734376-97.675782 14.835937-123.765625 31.507812-1.359375.867188-2.878907 1.28125-4.382813 1.28125zm0 0"
                fill="#ea2f2f"
            />
            <path
                d="m219.871094 104.5c-1.503906 0-3.023438-.414062-4.382813-1.28125-26.089843-16.671875-104.828125-58.242188-123.765625-31.507812-2.605468 3.675781-7.691406 4.542968-11.367187 1.941406-3.671875-2.605469-4.542969-7.691406-1.9375-11.367188 13.96875-19.722656 43.410156-20.679687 87.507812-2.84375 30.585938 12.367188 57.226563 29.324219 58.347657 30.039063 3.792968 2.425781 4.898437 7.464843 2.476562 11.257812-1.558594 2.433594-4.191406 3.761719-6.878906 3.761719zm0 0"
                fill="#ea2f2f"
            />
            <path
                d="m279.191406 47.523438h-46.390625c-12.695312 0-22.988281 10.292968-22.988281 22.992187v67.394531c0 12.699219 10.292969 22.992188 22.988281 22.992188h46.390625c12.695313 0 22.988282-10.292969 22.988282-22.992188v-67.394531c0-12.699219-10.292969-22.992187-22.988282-22.992187zm0 0"
                fill="#ea2f2f"
            />
            <path d="m215.234375 214.835938h81.527344v296.824218h-81.527344zm0 0" fill="#ff4440" />
            <path d="m215.234375 214.835938h81.527344v35.871093h-81.527344zm0 0" fill="#ea2f2f" />
        </svg>
    );
}

export default React.memo(GiftSVG);
