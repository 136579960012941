const EXPIRATION_PERIOD = new Map([
    [0, 'never'],
    [1, 'three_months'],
    [2, 'six_months'],
    [3, 'nine_months'],
    [4, 'one_year']
]);

const REDEMPTION_TYPE = new Map([
    [0, 'multiple_usage'],
    [1, 'single_usage']
]);

export const getExpirationByKey = (key) => {
    return EXPIRATION_PERIOD.get(key);
};

export const getRedemptionByKey = (key = 0) => {
    return REDEMPTION_TYPE.get(key);
};

export const calculateValueVoucher = (retailPrice, percent = 0) => {
    const result = Number(retailPrice) * (1 + Number(percent) / 100);
    return result.toFixed(0);
};
