import React from 'react';
import Analyzes from './Tabs';
import ContentLayout from '../layout/ContentLayout';
import RequirePasswordForm from './RequirePasswordForm';
import { checkPasswordApi } from 'services/merchant';

function Analyze() {
    const [isShow, setIsShow] = React.useState(false);
    const [password, setPassword] = React.useState('');
    const [error, setError] = React.useState('');
    const [isLoading, setIsLoading] = React.useState('');

    const handleCheckPassword = React.useCallback(async () => {
        setIsLoading(true);
        setError('');
        try {
            const isValid = await checkPasswordApi({
                body: {
                    password
                }
            });
            setIsLoading(false);
            if (!isValid) {
                setError('Password do not match');
                return;
            }
            setIsShow(true);
        } catch (error) {
            setError(error?.message?.[0]);
            setIsLoading(false);
        }
    }, [password]);

    return (
        <ContentLayout title={`Statistics`} isHideTitleMobile={true}>
            {isShow ? (
                <Analyzes />
            ) : (
                <RequirePasswordForm
                    password={password}
                    onPasswordChange={(e) => setPassword(e?.target?.value)}
                    isLoading={isLoading}
                    onCheckPassword={handleCheckPassword}
                    error={error}
                />
            )}
        </ContentLayout>
    );
}

export default React.memo(Analyze);
