import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Paper } from '@material-ui/core';
import PopperMenu from 'components/popper';
import moment from 'moment-timezone';
import { INPUT_HEIGHT } from 'const/style';
import CloseSVG from 'assets/images/svg/CloseSVG';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown';

const useStyles = makeStyles((theme) => ({
    root: {},
    present: {
        borderBottom: `1px solid ${theme.colors.border}`,
        height: INPUT_HEIGHT,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        // borderRadius: BORDER_RADIUS,
        cursor: 'pointer',
        position: 'relative',
        background: theme.palette.common.white,
        '&:focus-within': {
            borderColor: theme.palette.primary.main
        }
    },
    flex: {
        display: 'flex'
    },
    label: {
        fontSize: 14,
        lineHeight: `1.75`,
        fontWeight: 600
    },
    disabled: {
        background: theme.colors.backgroundDisabled,
        opacity: 0.8,
        pointerEvents: 'none'
    }
}));

function PortalDatePicker({ defaultValue = new Date(), label, onChange, onClose, disabled, ...rest }) {
    const classes = useStyles();
    const theme = useTheme();
    const [date, setDate] = React.useState(new Date());
    const [anchorEl, setAnchorEl] = React.useState(null);

    React.useEffect(() => {
        setDate(defaultValue);
    }, [defaultValue]);

    const datePresent = React.useMemo(() => {
        return moment(date).format(`dddd, DD MMMM YYYY`);
    }, [date]);

    const handleOpenDatePicker = (e) => {
        setAnchorEl(anchorEl ? null : e.currentTarget);
    };

    const handleClickAway = () => {
        setAnchorEl(null);
    };

    const handleDateChange = (date) => {
        setDate(date);
        handleClickAway();
        onChange && onChange(date);
    };

    return (
        <Grid container className={classes.root} direction="column">
            {label && <div className={classes.label}>{label}</div>}

            <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                className={`${classes.present} ${disabled && classes.disabled}`}
                onClick={handleOpenDatePicker}
                style={{
                    borderColor: Boolean(anchorEl) ? theme.palette.primary.main : ``
                }}
            >
                <Grid item className={classes.text}>
                    {datePresent}
                </Grid>
                {!onClose && (
                    <Grid item className={classes.flex}>
                        <ArrowDownIcon style={{ color: theme.colors.dropdown }} />
                    </Grid>
                )}
                {onClose && (
                    <Grid item className={classes.flex}>
                        <CloseSVG color={theme.palette.primary.main} onClick={onClose} />
                    </Grid>
                )}
            </Grid>
            {anchorEl && (
                <PopperMenu noPadding={true} anchorEl={anchorEl} handleClickAway={handleClickAway}>
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                        <Paper elevation={1}>
                            <DatePicker
                                variant="static"
                                label="Basic example"
                                value={date}
                                onChange={handleDateChange}
                                disableToolbar
                                format="DD/MM/YYYY"
                                {...rest}
                            />
                        </Paper>
                    </MuiPickersUtilsProvider>
                </PopperMenu>
            )}
        </Grid>
    );
}

export default React.memo(PortalDatePicker);
