export const FETCH_CLIENTS = '[CLIENT]FETCH_CLIENTS';
export const FETCH_CLIENTS_SUCCESS = '[CLIENT]FETCH_CLIENTS_SUCCESS';
export const FETCH_CLIENTS_FAILED = '[CLIENT]FETCH_CLIENTS_FAILED';

export const CREATE_CLIENT = '[CLIENT]CREATE_CLIENT';
export const CREATE_CLIENT_SUCCESS = '[CLIENT]CREATE_CLIENT_SUCCESS';
export const CREATE_CLIENT_FAILED = '[CLIENT]CREATE_CLIENT_FAILED';

export const UPDATE_CLIENT = '[CLIENT]UPDATE_CLIENT';
export const UPDATE_CLIENT_SUCCESS = '[CLIENT]UPDATE_CLIENT_SUCCESS';
export const UPDATE_CLIENT_FAILED = '[CLIENT]UPDATE_CLIENT_FAILED';

export const DELETE_CLIENT = '[CLIENT]DELETE_CLIENT';
export const DELETE_CLIENT_SUCCESS = '[CLIENT]DELETE_CLIENT_SUCCESS';
export const DELETE_CLIENT_FAILED = '[CLIENT]DELETE_CLIENT_FAILED';

export const UPDATE_CLIENT_AVATAR = '[CLIENT]UPDATE_CLIENT_AVATAR';

export const SEARCH_CLIENT = '[CLIENT]SEARCH_CLIENT';
export const SEARCH_CLIENT_SUCCESS = '[CLIENT]SEARCH_CLIENT_SUCCESS';
export const SEARCH_CLIENT_FAILED = '[CLIENT]SEARCH_CLIENT_FAILED';

export const FETCH_CLIENT_BOOKINGS = '[CLIENT]FETCH_CLIENT_BOOKINGS';
export const FETCH_CLIENT_BOOKINGS_SUCCESS = '[CLIENT]FETCH_CLIENT_BOOKINGS_SUCCESS';
export const FETCH_CLIENT_BOOKINGS_FAILED = '[CLIENT]FETCH_CLIENT_BOOKINGS_FAILED';
