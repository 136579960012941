import * as React from 'react';
import { TextField, Typography, Grid, CircularProgress, Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getFullName } from 'utils/naming';
import PhoneText from 'components/phoneFormat/Text';
import { getImageUrl } from 'utils/image';
import { useSnackbar } from 'notistack';
import { getClientsApi } from 'services/client';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ClientProfile from 'views/clients/clientProfile';
import BlockRounded from '@material-ui/icons/BlockRounded';
import AvatarIconSVG from 'assets/images/svg/AvatarIconSVG';
import Avatar from 'components/avatar';
import useMediaQueries from 'hooks/useMediaQueries';
import { useHidePhone } from 'hooks/app';

const useStyles = makeStyles((theme) => ({
    input: {
        height: '100%',
        background: theme.palette.common.white,
        borderRadius: 6,
        border: 'none',
        '& input': {
            paddingTop: `6px !important`,
            paddingBottom: `6px !important`
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
        }
    }
}));

function ClientSearchIncomingBookings() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [selectedClient, setSelectedClient] = React.useState(null);
    const [openInComingBookings, setOpenIncomingBookings] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const timerRef = React.useRef();
    const pasteRef = React.useRef(false);
    const { isMobile } = useMediaQueries();
    const hidePhone = useHidePhone();

    const [options, setOptions] = React.useState([]);

    const [search, setSearch] = React.useState('');

    const _fetchClients = React.useCallback(async () => {
        setLoading(true);
        try {
            const res = await getClientsApi({ page: 1, limit: 15, search, isPhoneNotNull: true });
            setOptions(res.items);
            setLoading(false);
        } catch ({ message }) {
            setLoading(false);
            enqueueSnackbar(message, {
                variant: 'info'
            });
        }
    }, [enqueueSnackbar, setLoading, search]);

    const handleCloseIncomingBookings = React.useCallback(() => {
        setOpenIncomingBookings(false);
    }, []);

    React.useEffect(() => {
        if (timerRef.current) clearTimeout(timerRef.current);

        if (!open) return;

        timerRef.current = setTimeout(async () => {
            _fetchClients();
        }, [500]);

        return () => {
            if (timerRef.current) clearTimeout(timerRef.current);
        };
    }, [search, _fetchClients, open]);

    const handlePaste = React.useCallback((e) => {
        const text = e.clipboardData.getData('Text');
        if (!text) return;

        pasteRef.current = true;
        const newText = text?.replace(/[^a-zA-Z0-9]/gi, '');

        console.log('newText', newText);

        setSearch(newText);
    }, []);

    return (
        <>
            <Autocomplete
                id="asynchronous-demo"
                style={{ width: isMobile ? 200 : 300 }}
                open={open}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                inputValue={search}
                onPaste={handlePaste}
                onInputChange={(e) => {
                    if (!pasteRef.current) {
                        setSearch(e?.target?.value);
                    } else {
                        pasteRef.current = false;
                    }
                }}
                getOptionLabel={(option) => getFullName(option)}
                filterOptions={(options) =>
                    options?.filter((option) => {
                        const fullName = getFullName(option)?.split(' ')?.join('');
                        return fullName
                            ?.concat(option?.user?.phone)
                            ?.concat(option?.email)
                            ?.toLowerCase()
                            ?.includes(search?.split(' ')?.join('')?.toLowerCase());
                    })
                }
                renderOption={(option) => {
                    return (
                        <Grid container wrap="nowrap" direction="row" key={option?.id} spacing={1}>
                            <Grid item>
                                <div style={{ position: 'relative', opacity: option?.block ? 0.5 : 1 }}>
                                    <Avatar
                                        active={option?.block}
                                        sizes="small"
                                        alt={getImageUrl(option?.avatar)}
                                        src={getImageUrl(option?.avatar)}
                                    >
                                        <AvatarIconSVG style={{ width: '100%', height: '100%' }} />
                                    </Avatar>

                                    {option?.block && (
                                        <div style={{ position: 'absolute', bottom: -10, right: -5 }}>
                                            <BlockRounded color="error" style={{ width: 15, height: 15 }} />
                                        </div>
                                    )}
                                </div>
                            </Grid>
                            <Grid item>
                                <Grid container direction="column">
                                    <Grid item>
                                        <Typography variant="body1" display="inline">
                                            {getFullName(option)}
                                        </Typography>
                                    </Grid>
                                    {option?.email && (
                                        <Grid item>
                                            <Typography display="inline" variant="subtitle2">
                                                ({option?.email})
                                            </Typography>
                                        </Grid>
                                    )}
                                    {!hidePhone && (
                                        <Grid item>
                                            <PhoneText phone={option?.user?.phone} />
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    );
                }}
                options={options}
                loading={isLoading}
                onChange={(e, v) => {
                    setSelectedClient(v);
                    if (v) {
                        setOpenIncomingBookings(true);
                    }
                    setSearch('');
                }}
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            className={classes.input}
                            label="Search client ..."
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                )
                            }}
                            size="small"
                            variant="outlined"
                        />
                    );
                }}
            />
            <Dialog open={openInComingBookings} fullWidth={true} maxWidth={false} onClose={handleCloseIncomingBookings}>
                <ClientProfile isInGrid selectedClient={selectedClient} onClose={handleCloseIncomingBookings} />
            </Dialog>
        </>
    );
}

export default ClientSearchIncomingBookings;
