import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import hexToRgba from 'hex-to-rgba';

const useStyles = makeStyles((theme) => ({
    '@keyframes spin': {
        '0%': {
            transform: 'rotate(0deg)'
        },
        '100%': {
            transform: 'rotate(359deg)'
        }
    },
    root: {
        position: 'relative'
    },
    spinner: {
        display: 'inline-block',
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: (props) => props.size,
        height: (props) => props.size,
        margin: (props) => `-${props.size / 2}px 0 0 -${props.size / 2}px`,
        border: (props) => `${props.thick}px ${props.bgColorProp} solid`,
        borderTop: (props) => `${props.thick}px ${props.rotateColorProp} solid`,
        // borderLeft: props => `${props.thick}px ${props.rotateColorProp} solid`,
        borderRadius: '50%',
        animation: `$spin 500ms infinite linear`
    }
}));

function Spinner({ size = 50, rotateColor, bgColor, thick = 4 }) {
    const theme = useTheme();
    const bgColorProp = hexToRgba(bgColor || theme.palette.primary.main, 0.5);
    const rotateColorProp = rotateColor || theme.palette.primary.main;
    const classes = useStyles({ size, rotateColorProp, bgColorProp, thick });
    return (
        <div className={classes.root}>
            <div className={classes.spinner}></div>
        </div>
    );
}

export default Spinner;
