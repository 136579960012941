import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { SUB_HEADER_HEIGHT, FOOTER_HEIGHT } from 'const';
import useMediaQueries from 'hooks/useMediaQueries';
// import ButtonIconWrapper from 'components/button/ButtonIconWrapper';
// import MenuSVG from 'assets/images/svg/MenuSVG';
// import { useDispatch } from 'react-redux';
// import * as appActions from 'app/actions';
// import capitalize from 'lodash/capitalize';

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 8,
            paddingRight: 8
        },
        width: '100%',
        height: '100%',
        paddingLeft: (props) => (props?.zeroPadding ? 0 : 14),
        paddingRight: (props) => (props?.zeroPadding ? 0 : 14)
    },
    subHeader: {
        height: SUB_HEADER_HEIGHT,
        minHeight: SUB_HEADER_HEIGHT,
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center'
    },
    content: {
        marginTop: 0,
        height: `calc(100vh - ${SUB_HEADER_HEIGHT}px)`,
        maxHeight: `-webkit-fill-available`,
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            height: `calc(100vh - ${SUB_HEADER_HEIGHT + FOOTER_HEIGHT * 2}px)`,
            maxHeight: `-webkit-fill-available`
        }
    },
    pr6: {
        paddingRight: theme.spacing(2)
    }
}));

function ContentLayout({
    title,
    titleIcon,
    children,
    action,
    isHideTitleMobile = false,
    zeroPadding = false,
    headerClassName,
    className,
    mid,
    ...rest
}) {
    const classes = useStyles({ zeroPadding });
    // const dispatch = useDispatch();
    const { isMobile } = useMediaQueries();

    // const handleToggleDrawler = () => {
    //     dispatch(appActions.toggleDrawlerLeft());
    // };

    return (
        <Grid container className={`${classes.root} ${className}`} direction="column" wrap="nowrap" {...rest}>
            <Grid item className={`${classes.subHeader} ${headerClassName}`}>
                {isHideTitleMobile && isMobile ? (
                    <span></span>
                ) : (
                    <Grid container wrap="nowrap" alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <Grid container alignItems="center">
                                {/* <Grid item className={`${classes.pr6}`}>
                                    <ButtonIconWrapper onClick={handleToggleDrawler} icon={<MenuSVG />} />
                                </Grid> */}
                                <Grid item className={`${classes.pr6}`}>
                                    <Typography variant="h3" component={'div'}>
                                        {/* {capitalize(title)} */}
                                        {title}
                                    </Typography>
                                </Grid>
                                {titleIcon && (
                                    <Grid item style={{ display: 'flex' }}>
                                        {typeof titleIcon === 'function' ? titleIcon() : titleIcon}
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        {mid && <Grid item>{mid && typeof mid === 'function' ? mid() : mid}</Grid>}
                        {action && <Grid item>{action && typeof action === 'function' ? action() : action}</Grid>}
                    </Grid>
                )}
            </Grid>
            <Grid item className={classes.content}>
                {children}
            </Grid>
        </Grid>
    );
}

export default React.memo(ContentLayout);
