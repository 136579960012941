import pickBy from 'lodash/pickBy';

export function getKeyValueOnly(object) {
    const newObj = {};
    Object.keys(object).forEach((key) => {
        if (object?.[key]) {
            newObj[key] = object[key]?.toString();
        }
    });
    return newObj;
}

export function cleanEmptyFieldsInObj(obj) {
    return pickBy(obj, (v) => v !== undefined && v !== null && v !== '');
}

export function MaxNumberInArr(arr) {
    return Math.max.apply(Math, arr);
}

export function rounded2Decimal(number) {
    return Math.round(number * 100) / 100;
}

export function isNotContainAllCheckingArray({ mainArr, checkArr }) {
    return checkArr.some((i) => !mainArr.includes(i));
}
