import DialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';

const StyledDialogActions = withStyles((theme) => ({
    root: {
        background: theme.colors.ghostwhite,
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2)
    }
}))(DialogActions);

export default StyledDialogActions;
