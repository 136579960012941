import React from 'react';
import { useTheme, alpha } from '@material-ui/core/styles';
import { Grid, useMediaQuery, Box, Typography, Divider, Paper, Dialog } from '@material-ui/core';
import LayoutTabContent from 'views/layout/LayoutTabContent';
import {
    useIsFetchingService,
    useServices,
    useCategories,
    useIsFetchingCategories,
    useServicesByCategoryId
} from 'hooks/service';
import { useTranslation } from 'react-i18next';
import ContentLayout from '../layout/ContentLayout';
import Button from 'components/button/Base';
import AddIcon from '@material-ui/icons/AddCircle';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import Category from './Category';
import * as categoryActions from 'views/services/action/category';
import { useDispatch } from 'react-redux';
import orderBy from 'lodash/orderBy';
import Services from './services';
import AddCategory from './AddCategory';
import AddService from './addService';

const ADD_TYPES = {
    CATEGORY: 'CATEGORY',
    SERVICE: 'SERVICE'
};

function ServiceList() {
    const { t } = useTranslation();
    const theme = useTheme();
    const [type, setType] = React.useState(null);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isFetchingServices = useIsFetchingService();
    const serviceList = useServices();
    const isFetchingCategories = useIsFetchingCategories();
    const categories = useCategories();
    const [selectedCategoryId, setSelectedCategoryId] = React.useState(null);
    const dispatch = useDispatch();
    const [editCt, setEditCt] = React.useState(null);

    const servicesByCategoryId = useServicesByCategoryId(selectedCategoryId);

    const handleOpenNewCategory = React.useCallback(() => {
        setType(ADD_TYPES.CATEGORY);
        setEditCt(null);
    }, []);

    const handleOpenNewService = React.useCallback(() => {
        setType(ADD_TYPES.SERVICE);
    }, []);

    const handleClose = React.useCallback(() => {
        setType(null);
        setEditCt(null);
    }, []);

    React.useEffect(() => {
        dispatch(
            categoryActions.getCategories({
                relations: ['services', 'services.prices', 'services.serviceStaffs'],
                errorCallback: () => {
                    console.log('failed to fetch categories');
                },
                successCallback: (categories) => {
                    const possibleCategories = orderBy(
                        categories?.filter((ct) => !ct.isDeleted),
                        ['orderIndex'],
                        ['desc']
                    );

                    if (possibleCategories?.[0]?.id) {
                        setSelectedCategoryId(possibleCategories?.[0]?.id);
                    }

                    console.log('fetch categories successfully');
                }
            })
        );
    }, [dispatch]);

    const handleDragEnd = React.useCallback(
        async ({ destination, source, draggableId }) => {
            if (!destination) {
                return;
            }
            if (destination.droppableId === source.droppableId && destination.index === source.index) {
                return;
            }

            let beforeCategoryId = null;
            let afterCategoryId = null;
            const oldIndex = categories?.findIndex((categoryId) => +categoryId === +draggableId);

            const newIndex = destination?.index;

            if (newIndex === 0) {
                beforeCategoryId = categories?.[0]?.id;
            } else {
                let index = newIndex > oldIndex ? newIndex : Math.max(0, newIndex - 1);
                afterCategoryId = categories?.[index]?.id;
            }

            dispatch(
                categoryActions.reOrderCategory({
                    afterCategoryId,
                    beforeCategoryId,
                    dragId: +draggableId,
                    dropIndex: destination?.index,
                    successCallback: () => {
                        console.log('reorder category success');
                    },
                    errorCallback: () => {
                        console.log('reorder category success');
                    }
                })
            );
        },
        [categories, dispatch]
    );

    const handleDeleteSuccess = React.useCallback(
        (id) => {
            if (id === selectedCategoryId) {
                const otherCategories = categories?.filter((ct) => ct?.id !== id);
                if (otherCategories?.length) {
                    setSelectedCategoryId(otherCategories?.[0]?.id);
                } else {
                    setSelectedCategoryId(null);
                }
            }
        },
        [selectedCategoryId, categories]
    );

    return (
        <ContentLayout title={t(`services`)}>
            <LayoutTabContent
                isLoading={
                    (isFetchingCategories && categories === null) || (isFetchingServices && serviceList === null)
                }
            >
                <Paper
                    style={{
                        width: '100%',
                        overflow: 'hidden',
                        background: theme.palette.common.white,
                        color: `rgb(33, 43, 54)`,
                        transition: `box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
                        backgroundImage: 'none',
                        position: 'relative',
                        boxShadow: `rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px`,
                        borderRadius: `16px`,
                        zIndex: 0,
                        display: 'flex',
                        flexDirection: 'row'
                    }}
                >
                    <Box
                        style={{
                            flexBasis: '400px',
                            borderRight: `1px solid ${theme.colors.border}`,
                            boxShadow: `rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px`
                        }}
                    >
                        <Grid container direction="column">
                            <Grid
                                item
                                style={{ padding: theme.spacing(2) }}
                                container
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Grid item>
                                    <Typography variant="h4">Categories</Typography>
                                </Grid>
                                <Grid item>
                                    <Grid item>
                                        <Button
                                            onClick={handleOpenNewCategory}
                                            variant="contained"
                                            color={'primary'}
                                            size="small"
                                            startIcon={<AddIcon />}
                                        >
                                            New
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Divider />
                            </Grid>

                            <Grid item>
                                <Box
                                    style={{
                                        padding: theme.spacing(2),
                                        height: `calc(100vh - ${60 + 68}px)`,
                                        maxHeight: `calc(100vh - ${60 + 68}px)`,
                                        overflowY: 'auto'
                                    }}
                                >
                                    <DragDropContext onDragEnd={handleDragEnd}>
                                        <Droppable droppableId="all-columns" direction="vertical" type="category">
                                            {(provided) => (
                                                <Grid
                                                    container
                                                    direction="column"
                                                    spacing={1}
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                >
                                                    {categories?.map((ct, index) => {
                                                        const isSelected = selectedCategoryId === ct?.id;
                                                        return (
                                                            <Grid
                                                                item
                                                                onClick={() => setSelectedCategoryId(ct?.id)}
                                                                key={ct?.id}
                                                            >
                                                                <Category
                                                                    {...ct}
                                                                    isActive={isSelected}
                                                                    index={index}
                                                                    onDeleteSuccess={handleDeleteSuccess}
                                                                    onEdit={() => {
                                                                        setEditCt(ct);
                                                                        setType(ADD_TYPES.CATEGORY);
                                                                    }}
                                                                />
                                                            </Grid>
                                                        );
                                                    })}
                                                </Grid>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box style={{ flex: 1, background: alpha(theme.palette.common.white, 0.7) }}>
                        {selectedCategoryId && (
                            <Grid container direction="column" style={{ marginX: 'auto', paddingX: theme.spacing(4) }}>
                                <Grid
                                    item
                                    style={{ padding: theme.spacing(2) }}
                                    container
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Grid item>
                                        <Typography variant="h4">Services</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Grid item>
                                            <Button
                                                onClick={handleOpenNewService}
                                                variant="contained"
                                                color={'primary'}
                                                size="small"
                                                startIcon={<AddIcon />}
                                            >
                                                New
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid
                                    item
                                    style={{
                                        maxHeight: `calc(100vh - ${60 + 68}px)`,
                                        overflowY: 'auto',
                                        height: `calc(100vh - ${60 + 68}px)`
                                    }}
                                >
                                    <Services services={servicesByCategoryId} selectedCategoryId={selectedCategoryId} />
                                </Grid>
                            </Grid>
                        )}
                    </Box>
                    <Dialog
                        onClose={handleClose}
                        maxWidth={false}
                        open={type === ADD_TYPES.CATEGORY}
                        fullScreen={fullScreen}
                    >
                        <AddCategory
                            isEdit={!!editCt}
                            editedCategory={editCt}
                            onClose={handleClose}
                            onAfterSuccess={(ct) => !editCt && setSelectedCategoryId(ct?.id)}
                        />
                    </Dialog>

                    <Dialog onClose={handleClose} fullScreen={true} open={type === ADD_TYPES.SERVICE}>
                        <AddService selectedCategoryId={+selectedCategoryId} onClose={handleClose} />
                    </Dialog>
                </Paper>
            </LayoutTabContent>
        </ContentLayout>
    );
}

export default React.memo(ServiceList);
