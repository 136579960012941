import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, CircularProgress, Typography, Button } from '@material-ui/core';
import AuthLayout from '../common/AuthLayout';
import InputBase from 'components/input';
import PhoneInput from 'components/input/Phone';
import { useFormik } from 'formik';
import { string, object } from 'yup';
import * as authActions from 'views/auth/actions';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { removePlus, addPlus } from 'utils/phone';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles((theme) => ({
    loginForm: {
        width: 350,
        padding: theme.spacing(4),
        background: theme.palette.common.white,
        borderRadius: theme.radius(2),
        boxShadow: theme.shadows[1],
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            boxSizing: 'border-box'
        }
    },
    btnSubmit: {
        position: 'relative'
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    center: {
        textAlign: 'center'
    },
    title: {
        maxWidth: 500,
        color: theme.palette.common.white
    },
    fullWidth: {
        width: '100%'
    },
    input: {
        height: 40,
        fontSize: 15
    },
    sp3: {
        marginBottom: theme.spacing(4)
    }
}));

const LoginSchema = object({
    phone: string().required('This field is required'),
    password: string().min(4, 'Min is 4 characters').required('This field is required')
});

function Login() {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const [off, setOff] = useState(true);

    const formik = useFormik({
        initialValues: {
            phone: '',
            password: ''
        },
        validationSchema: LoginSchema,
        onSubmit: (values, { setSubmitting, setStatus }) => {
            setStatus({ error: null });
            setSubmitting(true);
            dispatch(
                authActions.login({
                    password: values.password,
                    phone: removePlus(values.phone),
                    successCallback: () => {
                        history.push('/');
                    },
                    errorCallback: (error) => {
                        console.log(error);
                        setSubmitting(false);
                    }
                })
            );
            setStatus({ error: null });
        }
    });
    const { values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, setFieldValue } =
        formik;

    const handlePhoneChange = (phone) => {
        setFieldValue('phone', phone);
    };

    return (
        <AuthLayout title={'B N S'}>
            <form onSubmit={handleSubmit} className={classes.loginForm}>
                <Grid container className={classes.fullWidth} direction="column">
                    <Grid item className={classes.sp3}>
                        <Typography variant="h3">Log in with phone</Typography>
                    </Grid>

                    <Grid item direction="column" spacing={2} container className={classes.sp3}>
                        <Grid item>
                            <PhoneInput
                                label={t('phone_number')}
                                placeholder={`(471) 213-1312`}
                                disabled={isSubmitting}
                                onChange={handlePhoneChange}
                                defaultValue={addPlus(values.phone)}
                                autoFocus={true}
                            />
                        </Grid>
                        <Grid item style={{ position: 'relative' }}>
                            <InputBase
                                type={off ? 'password' : 'text'}
                                label={t(`password`)}
                                name="password"
                                value={values.password}
                                disabled={isSubmitting}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.password && touched.password}
                                errorText={errors.password}
                                placeholder="Password"
                                endAdornment={
                                    <div style={{ cursor: 'pointer' }} onClick={() => setOff(!off)}>
                                        {off ? <VisibilityOff /> : <Visibility />}
                                    </div>
                                }
                            />
                        </Grid>
                    </Grid>

                    <Grid item>
                        <div className={classes.btnSubmit}>
                            <Button
                                fullWidth
                                type="submit"
                                color="primary"
                                variant="contained"
                                disabled={isSubmitting || !isValid}
                                className={classes.input}
                            >
                                {t(`next`)}
                            </Button>
                            {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                    </Grid>
                </Grid>
            </form>
        </AuthLayout>
    );
}

export default React.memo(Login);
