import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputBase from 'components/input/Form';
import Button from 'components/button/Base';
import PortalDatePicker from 'components/datepicker/PortalReactDatePickerForm';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import useMediaQueries from 'hooks/useMediaQueries';
import { BORDER_RADIUS } from 'const/style';
import { useTranslation } from 'react-i18next';
import { getDatetimeFormat, getAppendMerchantTimezoneToDate, FRIENDLY_DATE_TIME_FORMAT } from 'utils/timing';
import * as staffActions from '../action';
import { useDispatch } from 'react-redux';
import DialogActions from 'components/dialog/Action';
import DialogTitle from 'components/dialog/Title';
import DialogContent from 'components/dialog/Content';
import moment from 'moment-timezone';
import AddIcon from '@material-ui/icons/AddCircle';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    spacing: {
        marginBottom: 18
    },
    input: {
        // minWidth: 320
    },
    minWidth: {
        minWidth: 250
    },
    title: {
        paddingRight: 0,
        paddingLeft: 0
    },
    warning: {
        background: theme.colors.anakiwa,
        padding: `5px 2px`,
        borderRadius: BORDER_RADIUS,
        paddingLeft: 8,
        color: theme.colors.primaryText
    },
    mw200: {
        minWidth: 250
    },
    btnSubmit: {
        position: 'relative'
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    mb4: {
        marginBottom: `14px !important`
    }
}));

function AddNewClosedDate({ onClose, isEdit, editedClosedDate }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [startDate, setStartDate] = React.useState(
        isEdit ? new Date(getAppendMerchantTimezoneToDate(editedClosedDate?.startDate)) : new Date()
    );

    const [startDatePresent, setStartDatePresent] = React.useState(
        isEdit
            ? moment(new Date(getAppendMerchantTimezoneToDate(editedClosedDate?.startDate))).format(
                  FRIENDLY_DATE_TIME_FORMAT
              )
            : ''
    );
    const [endDatePresent, setEndDatePresent] = React.useState(
        isEdit
            ? moment(new Date(getAppendMerchantTimezoneToDate(editedClosedDate?.endDate))).format(
                  FRIENDLY_DATE_TIME_FORMAT
              )
            : ''
    );

    const [endDate, setEndDate] = React.useState(
        isEdit ? new Date(getAppendMerchantTimezoneToDate(editedClosedDate?.endDate)) : new Date()
    );

    const [description, setDescription] = React.useState(isEdit ? editedClosedDate.description : '');
    const { isMobile } = useMediaQueries();
    const [isSubmitting, setIsSubmitting] = React.useState(false);

    const handleCreateClosedDate = () => {
        setIsSubmitting(true);
        if (!isEdit) {
            const createBody = {
                startDate: getDatetimeFormat(startDate),
                endDate: getDatetimeFormat(endDate),
                description
            };

            dispatch(
                staffActions.createClosedDate({
                    body: createBody,
                    successCallback: () => {
                        console.log('create closed date success');
                        onClose();
                    },
                    errorCallback: () => {
                        console.log('create closed date failed');
                        setIsSubmitting(false);
                    }
                })
            );
        } else {
            const updateBody = {
                startDate: getDatetimeFormat(startDate),
                endDate: getDatetimeFormat(endDate),
                description
            };

            dispatch(
                staffActions.updateClosedDate({
                    body: updateBody,
                    id: editedClosedDate?.id,
                    successCallback: () => {
                        console.log('update closed date success');
                        onClose();
                    },
                    errorCallback: () => {
                        console.log('update closed date failed');
                        setIsSubmitting(false);
                    }
                })
            );
        }
    };

    const handleDeleteClosedDate = () => {
        dispatch(
            staffActions.deleteClosedDate({
                id: editedClosedDate?.id,
                successCallback: () => {
                    console.log('delete closed date success');
                },
                errorCallback: () => {
                    console.log('delete closed date failed');
                    setIsSubmitting(false);
                }
            })
        );
        onClose();
    };

    const handleStartDateChange = (date) => {
        setStartDate(date);
        setStartDatePresent(moment(date).format(`dddd, DD MMMM YYYY`));
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
        setEndDatePresent(moment(date).format(`dddd, DD MMMM YYYY`));
    };

    const isValid = React.useMemo(() => {
        return !!description && Boolean(startDatePresent) && Boolean(endDatePresent);
    }, [description, startDatePresent, endDatePresent]);

    return (
        <>
            <DialogTitle>
                <Typography variant="h5">
                    {isEdit ? `${t(`edit`)} ${t(`closed_date`)}` : `${t(`create`)} ${t(`closed_date`)}`}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container direction="column">
                    <Grid item className={classes.mb4}>
                        <Typography variant="body2" className={classes.warning}>
                            {t(`online_bookings_can_not_be_placed`)}
                        </Typography>
                    </Grid>
                    <Grid item className={classes.mb4}>
                        <Grid container direction="row" spacing={isMobile ? 3 : 1}>
                            <Grid item xs={12} md={6} className={!isMobile ? classes.mw200 : ''}>
                                <PortalDatePicker
                                    className={classes.minWidth}
                                    maxDate={endDatePresent ? endDate : ''}
                                    onChange={handleStartDateChange}
                                    defaultValue={startDate}
                                    label={t(`start_date`)}
                                    placeholderText={t(`closed_date_start_date_placeholder`)}
                                    withPortal={isMobile}
                                    defaultDatePresent={startDatePresent ? startDatePresent : 'Selected date'}
                                    isUsedDatePresent={true}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} className={!isMobile ? classes.mw200 : ''}>
                                <PortalDatePicker
                                    className={classes.minWidth}
                                    minDate={startDatePresent ? startDate : ''}
                                    onChange={handleEndDateChange}
                                    defaultValue={endDate}
                                    defaultDatePresent={endDatePresent ? endDatePresent : 'Selected date'}
                                    label={t(`end_date`)}
                                    withPortal={isMobile}
                                    isUsedDatePresent={true}
                                    placeholderText={t(`closed_date_end_date_placeholder`)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.mb4}>
                        <InputBase
                            fullWidth
                            multiline
                            value={description || ''}
                            className={classes.input}
                            rowsMax={5}
                            placeholder={t(`closed_date_description_placeholder`)}
                            label={t(`description`)}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid
                    container
                    spacing={2}
                    wrap="nowrap"
                    alignItems="center"
                    justifyContent={isEdit ? 'space-between' : 'flex-end'}
                >
                    {isEdit && (
                        <Grid item>
                            <Button
                                startIcon={<DeleteIcon />}
                                onClick={handleDeleteClosedDate}
                                fullWidth={isMobile}
                                color="secondary"
                                variant="contained"
                            >
                                {t(`delete`)}
                            </Button>
                        </Grid>
                    )}

                    <Grid item>
                        <Grid container spacing={2} wrap="nowrap">
                            <Grid item>
                                <Button
                                    startIcon={<CloseIcon />}
                                    onClick={onClose}
                                    fullWidth={isMobile}
                                    variant="outlined"
                                >
                                    {t(`close`)}
                                </Button>
                            </Grid>
                            <Grid item>
                                <div className={classes.btnSubmit} style={{ width: isMobile ? '100%' : '' }}>
                                    <Button
                                        onClick={handleCreateClosedDate}
                                        disabled={isSubmitting || !isValid}
                                        fullWidth={isMobile}
                                        variant="contained"
                                        color="primary"
                                        startIcon={isEdit ? <SaveIcon /> : <AddIcon />}
                                    >
                                        {isEdit ? t('save') : t(`create`)}
                                    </Button>
                                    {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogActions>
        </>
    );
}

export default React.memo(AddNewClosedDate);
