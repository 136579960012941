import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, useMediaQuery, Dialog } from '@material-ui/core';
import LayoutTabContent from 'views/layout/LayoutTabContent';
import { useTranslation } from 'react-i18next';
import AddSVG from 'assets/images/svg/AddSVG';
import ContentLayout from '../layout/ContentLayout';
import ButtonIconWrapper from 'components/button/ButtonIconWrapper';
import Search from 'components/search';
import SimpleBar from 'simplebar-react';
import { useHidePriceDates, useIsFetchingHidePriceDates, useHidePriceDatesForTable } from 'hooks/hidePriceDate';
import { useDispatch } from 'react-redux';
import * as hidePriceDateActions from './actions';
import useMediaQueries from 'hooks/useMediaQueries';
import StickyHeadTable from 'components/table/StickyHeadTable';
import AddHidePriceDate from './AddHidePriceDate';

const useStyles = makeStyles((theme) => ({
    content: {
        overflowY: 'auto'
    },
    root: {
        maxWidth: 968,
        margin: '0 auto',
        width: '100%',
        padding: theme.spacing(4),
        height: '100%',
        [theme.breakpoints.down('xs')]: {
            maxHeight: `-webkit-fill-available`,
            marginTop: 0,
            padding: theme.spacing(2)
        }
    },
    spacing: {
        marginBottom: theme.spacing(2)
    }
}));

function HidePriceDateList() {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const { isMobile } = useMediaQueries();
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isFetchingHidePriceDate = useIsFetchingHidePriceDates();
    const hidePriceDates = useHidePriceDates();
    const [editedHidePriceDate, setEditedHidePriceDate] = React.useState({});
    const [isEdit, setIsEdit] = React.useState(false);
    const data = useHidePriceDatesForTable();

    const columns = [
        { id: 'ranges', label: t(`date_ranges`), minWidth: 200 },
        { id: 'numOfDays', label: t(`no_of_days`), minWidth: 170 },
        // { id: 'location  ', label: t(`locations`), minWidth: 170 },
        { id: 'description', label: t(`description`), minWidth: 170 }
    ];

    const handleClickOpen = () => {
        setIsEdit(false);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    React.useEffect(() => {
        dispatch(
            hidePriceDateActions.fetchHidePriceDates({
                successCallback: () => {
                    console.log('fetchClosedDates success');
                },
                errorCallback: () => {
                    console.log('fetchClosedDates failed');
                }
            })
        );
    }, [dispatch]);

    const headerRenderer = ({ column }) => {
        return <Typography variant="body1">{column.label}</Typography>;
    };

    const onRowClicked = ({ rowData }) => {
        setIsEdit(true);
        setOpen(true);
        setEditedHidePriceDate(rowData);
    };

    return (
        <ContentLayout title={'Hide Price Dates'}>
            <LayoutTabContent
                isLoading={isFetchingHidePriceDate && hidePriceDates === null}
                className={classes.content}
            >
                <Grid container className={classes.root} direction="column" wrap="nowrap">
                    <Grid item className={classes.spacing}>
                        <Grid
                            container
                            direction="row"
                            wrap="nowrap"
                            spacing={2}
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Grid item className={classes.search}>
                                <Search />
                            </Grid>
                            <Grid item>
                                <ButtonIconWrapper
                                    size={isMobile ? 40 : 'large'}
                                    onClick={handleClickOpen}
                                    icon={<AddSVG />}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.table}>
                        <SimpleBar style={{ maxHeight: '100%' }}>
                            <StickyHeadTable
                                classNameContainer={classes.container}
                                headerRenderer={headerRenderer}
                                rows={data}
                                columns={columns}
                                onRowClicked={onRowClicked}
                            />
                        </SimpleBar>
                    </Grid>
                    <Dialog fullScreen={fullScreen} open={open} onClose={handleClose}>
                        <AddHidePriceDate
                            isEdit={isEdit}
                            editedHidePriceDate={editedHidePriceDate}
                            onClose={handleClose}
                        />
                    </Dialog>
                </Grid>
            </LayoutTabContent>
        </ContentLayout>
    );
}

export default React.memo(HidePriceDateList);
