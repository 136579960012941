import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import DialogTitle from 'components/dialog/Title';
import DialogActions from 'components/dialog/Action';
import DialogContent from 'components/dialog/Content';
import { useFormik } from 'formik';
import { string, object } from 'yup';
import ButtonBase from 'components/button/Base';
import InputBase from 'components/input/Form';
import * as settingActions from 'views/settings/actions';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    root: {},
    content: {
        minWidth: 400,
        padding: `${theme.spacing(3)}px !important`
    },
    mr2: {
        marginRight: theme.spacing(2)
    }
}));

const GroupSchema = object({
    title: string().required('This filed is required'),
    description: string()
});

function CreateGroup({ onClose, merchantOptions }) {
    const classes = useStyles();

    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            title: '',
            description: ''
        },
        validationSchema: GroupSchema,
        onSubmit: (values, { setSubmitting, setStatus }) => {
            const createBody = {
                ...values
            };

            setSubmitting(true);
            dispatch(
                settingActions.createGroup({
                    newGroup: createBody,
                    successCallback: () => {
                        setSubmitting(false);
                        onClose && onClose();
                    },
                    errorCallback: () => {
                        setSubmitting(false);
                    }
                })
            );
        }
    });

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid } = formik;

    return (
        <form onSubmit={handleSubmit}>
            <DialogTitle className={classes.title}>Create Group</DialogTitle>
            <DialogContent className={classes.content}>
                <Grid container direction="column">
                    <Grid item>
                        <InputBase
                            fullWidth
                            className={`${classes.input}`}
                            label={'Title'}
                            placeholder={'Enter group title'}
                            name="title"
                            value={values.title || ''}
                            disabled={isSubmitting}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.title && touched.title}
                            errorText={errors.title}
                        />
                    </Grid>
                    <Grid item>
                        <InputBase
                            fullWidth
                            className={`${classes.input}`}
                            label={'Description'}
                            placeholder={'Enter group title'}
                            name="description"
                            value={values.description || ''}
                            disabled={isSubmitting}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.description && touched.description}
                            errorText={errors.description}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container direction="row" justifyContent="flex-end">
                    <Grid item className={classes.mr2}>
                        <ButtonBase variant="outlined" onClick={onClose}>
                            Close
                        </ButtonBase>
                    </Grid>
                    <Grid item>
                        <ButtonBase
                            disabled={!isValid || isSubmitting}
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            Save
                        </ButtonBase>
                    </Grid>
                </Grid>
            </DialogActions>
        </form>
    );
}

export default CreateGroup;
