import * as types from './types';

const INITIAL_STATE = {
    top5Services: [
        { label: 'Service A', income: 5000 },
        { label: 'Service B', income: 7000 }
    ],
    top5Technicians: [
        { label: 'Duong Nguyen', income: 2000 },
        { label: 'Bao Chau', income: 3000 }
    ],
    incomeTicketsData: [
        {
            label: '01-01-2021',
            income: 3000,
            tickets: 200
        },
        {
            label: '01-01-2021',
            income: 5000,
            tickets: 250
        }
    ],
    staffAnalyze: [],
    tip: []
};

export default function smsSchedules(state = INITIAL_STATE, { type, payload }) {
    switch (type) {
        case types.FETCH_ANALYZE_RANGE: {
            const { top5Services, top5Technicians, incomeTicketsData } = payload;
            return {
                ...state,
                top5Services,
                top5Technicians,
                incomeTicketsData
            };
        }

        case types.FETCH_STAFF_ANALYZE_RANGE: {
            const { staffAnalyze } = payload;
            return {
                ...state,
                staffAnalyze
            };
        }

        case types.FETCH_STAFF_TIP_RANGE: {
            const { tip } = payload;
            return {
                ...state,
                tip
            };
        }

        default:
            return state;
    }
}
