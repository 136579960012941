import axios from 'axios';
import { API_URL } from 'config';
import { getItem, removeItem } from 'utils/localStorage';
import { TOKEN_NAME, TOKEN_USER } from 'const';
import store from '../store/configStore';

let instance = axios.create({
    baseURL: API_URL
});

const DEFAULT_HEADERS = {
    Accept: 'application/json;',
    'Content-Type': 'application/json;',
    'Access-Control-Expose-Headers': 'Content-Disposition'
};

instance.interceptors.request.use(
    (config) => {
        const { getState } = store;
        let state = getState();
        let socketId = state?.app?.socketId;

        let newHeader = { ...DEFAULT_HEADERS };
        if (socketId) {
            newHeader['X-Socket-Id'] = socketId;
        }

        const token = getItem(TOKEN_NAME);
        if (token) {
            newHeader = {
                ...newHeader,
                Authorization: `Bearer ${token}`
            };
        }
        config['headers']['common'] = newHeader;
        return config;
    },
    (error) => {
        throw error;
    }
);

instance.interceptors.response.use(
    (response) => {
        return parseBody(response);
    },
    (error) => {
        return parseError(error);
    }
);

function redirect(path) {
    const { origin, hash } = window.location;
    removeItem(TOKEN_USER);
    removeItem(TOKEN_NAME);
    const redirectUrl = origin + '/' + hash + path;
    return window.location.replace(redirectUrl);
}

window.redirect = redirect;

function parseBody(response) {
    //Unauthorized
    if (response.status === 403) {
        redirect('/login');
    }

    if (response.status === 204) {
        return 204;
    }

    return response;
}

function parseError(error) {
    const { response, config } = error;
    const isAuthRequest = config && config.url === '/auth';

    if (response && response.status === 401) {
        if (!isAuthRequest) {
            redirect('/login');
        }
        throw formatError(response);
    }
    if (response && response.data) {
        throw formatError(response);
    }
    throw error;
}

function formatError(response) {
    return {
        status: response.data.status,
        message: response.data.message || response.data.title
    };
}

export default instance;
