import { useMediaQuery } from '@material-ui/core';
import json2mq from 'json2mq';

function useMediaQueries() {
    const isMobile = useMediaQuery(
        json2mq({
            minWidth: 0,
            maxWidth: 600
        })
    );
    const isTablet = useMediaQuery(
        json2mq({
            minWidth: 600,
            maxWidth: 960
        })
    );

    return {
        isMobile,
        isTablet
    };
}

export default useMediaQueries;
