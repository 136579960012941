import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import QRCode from 'qrcode.react';

const QR_WIDTH = 50;

const styles = (theme) => ({
    root: {
        background: theme.palette.common.white
    },
    QrPlace: {
        width: '100%',
        display: 'flex',
        '& canvas': {
            width: QR_WIDTH,
            height: QR_WIDTH
        }
    }
});

class QRCodePrint extends Component {
    render() {
        const { classes, code } = this.props;
        return (
            <Grid container className={classes.root}>
                <Grid item className={classes.QrPlace}>
                    <QRCode size={QR_WIDTH} value={code} />
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(QRCodePrint);
