export const OPERATOR = {
    contains: 'contains',
    equal: '=',
    notEqual: '!=',
    greaterOrEqual: '>=',
    lessOrEqual: '<=',
    less: '<',
    great: '>',
    in: 'in',
    true: true,
    false: false,
    startsWith: 'startsWith',
    endsWith: 'endsWith',
    both: 'both',
    between: 'between',
    isEmpty: 'isEmpty'
};
