import React from 'react';
import AuthenticationCheck from './AuthenticationCheck';
import { Route, Redirect } from 'react-router-dom';

function AlreadyLoginRoute({ ...rest }) {
    return (
        <AuthenticationCheck
            onUnAuth={() => <Route {...rest} />}
            onAuth={() => {
                return <Redirect to="/" />;
            }}
        />
    );
}

export default AlreadyLoginRoute;
