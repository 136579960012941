import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';
import InputBase from 'components/input/Form';
import { SENT_DATE_OPTIONS, SMS_SEND_TYPE } from 'const';
import NativeSelect from 'components/select/NativeForm';
import PortalDatePicker from 'components/datepicker/PortalReactDatePickerForm';
import { timeOptions } from 'data/time';
import { getCurrentShortTime, isSameToday, isAfterTime } from 'utils/timing';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 1024,
        margin: '0 auto',
        justifyContent: 'center',
        paddingTop: 50,
        [theme.breakpoints.down('sm')]: {
            paddingTop: 10
        }
    },
    paddingRight: {
        paddingRight: 12
    },

    paddingBottom: {
        paddingBottom: 12
    },

    label: {
        fontWeight: 600
    },
    fullWidth: {
        width: '100%'
    }
}));

export default function ChooseDate({
    handleChange,
    handleBlur,
    isValid,
    isSubmitting,
    touched,
    errors,
    values,
    sendType,
    onChangeSendType,
    sendDate,
    onChangeSendDate,
    sendTime,
    onChangeSendTime
}) {
    const { t } = useTranslation();
    const classes = useStyles();

    const formatTimeOptions = React.useMemo(() => {
        if (isSameToday(sendDate)) {
            const currentTime = getCurrentShortTime();
            const newTimeOptions = timeOptions.map((time) => {
                if (isAfterTime(currentTime, time?.value)) {
                    return {
                        ...time,
                        disabled: false
                    };
                } else {
                    return {
                        ...time,
                        disabled: true
                    };
                }
            });

            const filter = newTimeOptions.filter((time) => !time?.disabled);
            onChangeSendTime(filter[0]?.value);
            return newTimeOptions;
        }

        return timeOptions;
    }, [onChangeSendTime, sendDate]);

    return (
        <Grid container className={classes.root} spacing={3}>
            <Grid item md={6} xs={12}>
                <InputBase
                    fullWidth
                    label={`Name`}
                    placeholder={'Please enter a name'}
                    name="name"
                    value={values.name}
                    disabled={isSubmitting}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.name && touched.name}
                    errorText={errors.name}
                    autoFocus={true}
                />
            </Grid>
            <Grid item md={6} xs={12}>
                <InputBase
                    multiline
                    rows={1}
                    label={`Message`}
                    placeholder={'Please enter a message'}
                    name="message"
                    value={values.message}
                    disabled={isSubmitting}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.message && touched.message}
                    errorText={errors.message}
                />
            </Grid>

            <Grid item md={6} xs={12}>
                <NativeSelect
                    label={'Type Sent'}
                    options={SENT_DATE_OPTIONS}
                    placeholder={t(`choose_an_option`)}
                    selectedValue={sendType}
                    onChange={onChangeSendType}
                />
            </Grid>

            <Grid item md={6} xs={12}>
                {Number(sendType) === SMS_SEND_TYPE.INTEND && (
                    <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                            <PortalDatePicker
                                minDate={new Date()}
                                onChange={onChangeSendDate}
                                defaultValue={sendDate}
                                label={`Choose Date`}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <NativeSelect
                                onChange={(e) => onChangeSendTime(e.target.value)}
                                selectedValue={sendTime}
                                defaultValue={sendTime}
                                options={formatTimeOptions}
                                label={`Choose Time`}
                                placeholder={`Choose an option`}
                            />
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
}
