import React from 'react';

function AnalyzeSVG({ color = '#41415C', ...rest }) {
    return (
        <svg width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <g opacity="0.5">
                <path
                    d="M19.2962 17.6317H18.5428V6.6238C18.5428 5.82434 17.8898 5.17478 17.0862 5.17478H14.8374C14.0338 5.17478 13.3808 5.82434 13.3808 6.6238V17.6317H12.5887V3.44903C12.5887 2.64958 11.9357 2.00002 11.1321 2.00002H8.88336C8.07197 1.99617 7.41899 2.64573 7.41899 3.44519V17.6279H6.64237V9.70632C6.64237 8.90686 5.9894 8.2573 5.18573 8.2573H2.93701C2.13335 8.2573 1.48037 8.90686 1.48037 9.70632V17.6279H0.707614C0.332827 17.6279 0.0159976 17.9161 0.000542483 18.2889C-0.0149126 18.6771 0.301917 19 0.688295 19H19.3117C19.7019 19 20.0149 18.6771 19.9995 18.2889C19.9879 17.92 19.6672 17.6317 19.2962 17.6317ZM17.1596 6.6238V17.6317H14.7563V6.6238C14.7563 6.58152 14.7911 6.54693 14.8336 6.54693H17.0823C17.1248 6.54308 17.1596 6.57767 17.1596 6.6238ZM11.2055 3.44519V17.6279H8.80223V3.44519C8.80223 3.40291 8.837 3.36832 8.8795 3.36832H11.1282C11.1707 3.36832 11.2055 3.40291 11.2055 3.44519ZM5.26301 9.70632V17.6279H2.85974V9.70632C2.85974 9.66404 2.89451 9.62945 2.93701 9.62945H5.18573C5.22823 9.62945 5.26301 9.66404 5.26301 9.70632Z"
                    fill={color}
                />
            </g>
        </svg>
    );
}

export default React.memo(AnalyzeSVG);
