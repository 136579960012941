import * as types from './types';

const INITIAL_STATE = {
    list: null,
    isFetching: false,
    clients: null,
    isFetchingClients: false
};

export default function smsSchedules(state = INITIAL_STATE, { type, payload }) {
    switch (type) {
        case types.FETCH_SMS_SCHEDULES: {
            return {
                ...state,
                isFetching: true
            };
        }

        case types.FETCH_SMS_SCHEDULES_FAILED: {
            return {
                ...state,
                isFetching: false
            };
        }

        case types.FETCH_SMS_SCHEDULES_SUCCESS: {
            const { smsSchedules } = payload;
            return {
                ...state,
                isFetching: false,
                list: smsSchedules || []
            };
        }

        case types.CREATE_SMS_SCHEDULE_SUCCESS: {
            const { smsSchedule } = payload;
            const { list } = state;
            return {
                ...state,
                list: [smsSchedule, ...list]
            };
        }

        case types.UPDATE_SMS_SCHEDULE_SUCCESS: {
            const { smsSchedule, id } = payload;
            const { list } = state;
            const newList = list.map((item) => {
                if (item.id === id) {
                    item = smsSchedule;
                }
                return item;
            });
            return {
                ...state,
                list: newList
            };
        }

        case types.DELETE_SMS_SCHEDULE: {
            const { id } = payload;
            const { list } = state;

            const newList = list.map((item) => {
                if (item.id === id) {
                    item.deleted = true;
                }
                return item;
            });

            return {
                ...state,
                list: newList
            };
        }

        case types.DELETE_SMS_SCHEDULE_FAILED: {
            const { id } = payload;
            const { list } = state;
            const newList = list.map((member) => {
                if (member.id === id) {
                    member.deleted = false;
                }
                return member;
            });
            return {
                ...state,
                list: newList
            };
        }

        case types.DELETE_SMS_SCHEDULE_SUCCESS: {
            const { id } = payload;
            const { list } = state;
            const newList = list.filter((member) => member.id !== id);
            return {
                ...state,
                list: newList
            };
        }

        case types.FETCH_SMS_CLIENTS: {
            return {
                ...state,
                isFetchingClients: true
            };
        }

        case types.FETCH_SMS_CLIENTS_FAILED: {
            return {
                ...state,
                isFetchingClients: false
            };
        }

        case types.FETCH_SMS_CLIENTS_SUCCESS: {
            const { clients } = payload;
            return {
                ...state,
                isFetchingClients: false,
                clients: clients || []
            };
        }

        default:
            return state;
    }
}
