import request from './request';

const authApis = {
    login: '/auth/login',
    switchMerchant: `/auth/merchant/switch`
};

export async function loginApi(data) {
    const response = await request.post(authApis.login, {
        ...data,
        rememberMe: true,
        strategy: 'local',
        type: 'merchant'
    });
    return response.data;
}

export async function switchMerchantApi(body) {
    const response = await request.post(authApis.switchMerchant, body);
    return response.data;
}
