import React from 'react';
import { useDispatch } from 'react-redux';

import VoucherTab from 'views/voucher/Tab';
import * as actionsVouchers from './action';

function Voucher() {
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(
            actionsVouchers.getVouchers({
                errorCallback: () => {
                    console.log('failed to fetch gift');
                },
                successCallback: () => {
                    console.log('fetch gift successfully');
                }
            })
        );
    }, [dispatch]);

    return <VoucherTab />;
}

export default React.memo(Voucher);
