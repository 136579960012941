import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import Button from 'components/button/Base';
import { useDispatch } from 'react-redux';
import * as bookingActions from './actions';
import { useIsConflicted } from 'hooks/checkIn';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Countdown from 'react-countdown';

const useStyles = makeStyles((theme) => ({
    //success
    dRoot: {
        width: '100%',
        background: theme.palette.common.white,
        borderRadius: `8px 8px 0px 0px`,
        boxShadow: theme.shadows[1],
        padding: 32,
        position: 'sticky',
        top: 0,
        left: 0,
        zIndex: 100,
        [theme.breakpoints.down('sm')]: {
            top: 110
        }
    },
    dTextCenter: {
        textAlign: 'center'
    },
    dAvatar: {
        position: 'absolute',
        width: 80,
        height: 80,
        top: -40,
        left: 0,
        right: 0,
        margin: 'auto',
        boxShadow: `rgba(16, 25, 40, 0.08) 0px 8px 16px 0px`,
        border: `2px solid white`
    },
    dServices: {
        minHeight: 100,
        display: 'flex',
        alignItems: 'center',
        borderBottom: `1px solid rgb(231, 232, 233)`
    },
    dServiceItem: {
        width: '100%',
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        minHeight: 65
    },
    dDatetime: {
        padding: `24px 0px`,
        borderBottom: `1px solid rgb(231, 232, 233)`
    },
    dTotal: {
        padding: `24px 0px`
    },
    dSpacing: {
        paddingBottom: theme.spacing(3)
    },
    dBtnSubmit: {
        position: 'relative'
    },
    dButtonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    }
}));

const REDIRECT_SECONDS = 15;

function BookingSuccess() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();

    const isConflicted = useIsConflicted();

    const clearBookingAndGoBackHome = () => {
        dispatch(bookingActions.resetOrder());
        history.push(`/check-in`);
    };

    return (
        <Grid container className={classes.dRoot} direction="column" wrap="nowrap">
            <Grid item className={classes.dSpacing}>
                <Typography variant="h3">Your booking has been confirmed.</Typography>
            </Grid>
            <Grid item className={classes.dSpacing}>
                <Typography variant="subtitle1">
                    Thank you for using our service.{' '}
                    {isConflicted
                        ? 'Please take a seat and wait for receptionist.'
                        : 'Please go inside and enjoy your services.'}
                </Typography>
            </Grid>

            <Grid item>
                <Button
                    style={{ height: 50, fontSize: 16 }}
                    onClick={clearBookingAndGoBackHome}
                    fullWidth
                    variant="contained"
                    color="secondary"
                >
                    Redirecting in{' '}
                    <strong style={{ marginLeft: 4, marginRight: 4 }}>
                        <Countdown
                            onComplete={clearBookingAndGoBackHome}
                            date={Date.now() + REDIRECT_SECONDS * 1000}
                            renderer={({ hours, minutes, seconds, completed }) => <span>{seconds}</span>}
                        ></Countdown>{' '}
                    </strong>
                    seconds...
                </Button>
            </Grid>
        </Grid>
    );
}

export default React.memo(BookingSuccess);
