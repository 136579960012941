import React from 'react';
import { Grid, IconButton } from '@material-ui/core';
import SlideZoom from './SlideZoom';
import PopperMenu from 'components/popper';
import { Settings } from '@material-ui/icons';
import { useClosedDatesFormatDate } from 'hooks/staff';
import { useSelectedDate, useToggleCloseDate } from 'hooks/calendar';
import { getDatetimeFormat } from 'utils/timing';

function ZoomSetting() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const closedDatesFormatDate = useClosedDatesFormatDate();
    const selectedDate = useSelectedDate();
    const toggleCloseDate = useToggleCloseDate();

    const handleOpenSlideZoom = React.useCallback(
        (e) => {
            setAnchorEl(anchorEl ? null : e.currentTarget);
        },
        [anchorEl]
    );

    const isClosedDate = React.useMemo(() => {
        return Boolean(closedDatesFormatDate.find((clDate) => clDate.date === getDatetimeFormat(selectedDate)));
    }, [closedDatesFormatDate, selectedDate]);

    const handleCloseSlideZoom = React.useCallback(() => {
        setAnchorEl(null);
    }, []);

    return (
        <Grid item>
            <IconButton size="small" onClick={handleOpenSlideZoom}>
                <Settings fontSize="small" />
            </IconButton>
            {anchorEl && (
                <PopperMenu noPadding={true} anchorEl={anchorEl} handleClickAway={handleCloseSlideZoom}>
                    <SlideZoom isClosedDate={isClosedDate} toggleCloseDate={toggleCloseDate} />
                </PopperMenu>
            )}
        </Grid>
    );
}

export default ZoomSetting;
