import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, useMediaQuery, Dialog, Badge, withStyles, Button } from '@material-ui/core';
import LayoutTabContent from 'views/layout/LayoutTabContent';
import { useDispatch } from 'react-redux';
import useMediaQueries from 'hooks/useMediaQueries';
import { getAppendMerchantTimezoneToDate, getDatetimeFormat } from 'utils/timing';
import moment from 'moment-timezone';
// import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import Table from 'components/table/NewTable';
import { useStaffAnalyze, useTotalStaffsActualIncome } from 'hooks/analyze';
import Avatar from 'components/avatar';
import { getImageUrl } from 'utils/image';
import * as analyzeActions from './actions';
// import SingleDate from 'components/dateRange/SingleDate';
// import DateRange from 'components/dateRange';
import Spinner from 'components/spinner';
import StaffStatisticDetail from './StaffStatisticDetail';
import PortalDatePicker from 'components/datepicker/PortalReactDatePickerForm';
import { useTranslation } from 'react-i18next';
import ArrowNextSVG from 'assets/images/svg/ArrowNextSVG';
import { useMerchantInfo } from 'hooks/merchant';
import csvDownload from 'json-to-csv-export';
import { useMemo } from 'react';

const useStyles = makeStyles((theme) => ({
    root: {},
    chartWrapper: {
        boxSizing: 'border-box',
        background: theme.palette.common.white,
        padding: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2)
        }
    },
    chart: {
        height: 300,
        width: '100%',
        paddingTop: theme.spacing(3)
    },
    reportDetail: {
        height: `calc(100vh - 48px - 24px - 76px - 68px)`,
        maxWidth: 980,
        minWidth: 500,
        margin: 'auto',
        overflowY: 'auto',
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
            height: `calc(100vh - 48px - 24px - 82px - 82px - 46px)`,
            maxHeight: `-webkit-fill-available`,
            maxWidth: '100%',
            minWidth: '100%'
        },
        position: 'relative'
    },
    tableBody: {
        width: '100%'
    },
    centerText: {
        textAlign: 'center'
    },
    spinnerWrapper: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex'
    },
    fullWidth: {
        width: '100%'
    },
    dateItem: {
        minWidth: 250,
        [theme.breakpoints.down('sm')]: {
            minWidth: 'auto',
            width: '100%'
        }
    }
}));

const StyledBadge = withStyles((theme, ...rest) => {
    return {
        badge: {
            backgroundColor: (props) => (props?.active ? theme.colors.darkGreen : theme.colors.red),
            color: (props) => (props?.active ? theme.colors.darkGreen : theme.colors.red),
            boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
            '&::after': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                animation: '$ripple 1.2s infinite ease-in-out',
                border: '1px solid currentColor',
                content: '""'
            }
        },
        '@keyframes ripple': {
            '0%': {
                transform: 'scale(.8)',
                opacity: 1
            },
            '100%': {
                transform: 'scale(2.4)',
                opacity: 0
            }
        }
    };
})(Badge);

function StaffDetail({ value, rowData }) {
    const classes = useStyles();
    return (
        <Grid container alignItems="center" justifyContent="flex-start" className={classes.avatar} wrap="nowrap">
            <Grid item style={{ marginRight: 6 }}>
                <StyledBadge
                    className={classes.badged}
                    active={rowData?.isActive}
                    overlap="circular"
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                    variant="dot"
                >
                    <Avatar size="medium" src={getImageUrl(rowData?.avatar)}>
                        {rowData?.fullName?.slice(0, 1)}
                    </Avatar>
                </StyledBadge>
            </Grid>
            <Grid item>
                <Typography variant="body1" align="left">
                    {rowData?.fullName}
                </Typography>
            </Grid>
        </Grid>
    );
}

const StaffDetailMemo = React.memo(StaffDetail);

function Reports() {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { isMobile } = useMediaQueries();
    const [startDate, setStartDate] = React.useState(new Date());
    const [endDate, setEndDate] = React.useState(new Date());
    const merchant = useMerchantInfo();

    const [startDatePresent, setStartDatePresent] = React.useState('');

    const [endDatePresent, setEndDatePresent] = React.useState('');

    // const [focusedInput, setFocusedInput] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    // const expectedIncome = useTotalStaffsExpectedIncome();
    const actualIncome = useTotalStaffsActualIncome();
    // const [mode, setMode] = React.useState(STATISTIC_MODE.DAY);
    const data = useStaffAnalyze();

    const [open, setOpen] = React.useState(false);
    const [selectedStaff, setSelectedStaff] = React.useState(null);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const exportData = useMemo(() => {
        return data?.map((r) => ({
            name: r.fullName,
            incomeDoneService: r.incomeDoneService,
            salary: r.salary,
            salaryMin: r.salaryMin
        }));
    }, [data]);

    const dataToConvert = useMemo(() => {
        return {
            data: exportData,
            filename: 'income',
            delimiter: ',',
            headers: ['Full Name', 'Sale amount', 'Commission', 'Min salary']
        };
    }, [exportData]);

    // const onDatesChange = React.useCallback(({ startDate, endDate }) => {
    //     setStartDate(startDate);
    //     setEndDate(endDate);
    // }, []);

    React.useEffect(() => {
        if (startDate && endDate) {
            const start = getAppendMerchantTimezoneToDate(getDatetimeFormat(startDate));
            const end = getAppendMerchantTimezoneToDate(getDatetimeFormat(endDate));
            setIsLoading(true);
            dispatch(
                analyzeActions.fetchStaffAnalyzeRange({
                    startDate: start,
                    endDate: end,
                    successCallback: () => {
                        console.log('fetch staff analyze success');
                        setIsLoading(false);
                    },
                    errorCallback: () => {
                        console.log('failed to fetch staff analyze');
                        setIsLoading(false);
                    }
                })
            );

            dispatch(
                analyzeActions.fetchStaffTipRange({
                    startDate: start,
                    endDate: end,
                    successCallback: () => {
                        console.log('fetch tip success');
                        setIsLoading(false);
                    },
                    errorCallback: () => {
                        console.log('failed to fetch tip');
                        setIsLoading(false);
                    }
                })
            );
        }
    }, [dispatch, startDate, endDate]);

    // const orientation = React.useMemo(() => {
    //     return isMobile ? 'vertical' : 'horizontal';
    // }, [isMobile]);

    // const onSingleDateChange = React.useCallback((date) => {
    //     setStartDate(date.format());
    //     setEndDate(date.format());
    // }, []);

    // const onWeekDateChange = React.useCallback((range) => {
    //     console.log('range', range);
    //     if (range?.length > 0) {
    //         const dateStart = range?.[0]?.date;
    //         const dateEnd = range?.[range?.length - 1]?.date;
    //         setStartDate(dateStart);
    //         setEndDate(dateEnd);
    //     }
    // }, []);

    const columns = React.useMemo(
        () => [
            {
                id: 'id',
                label: 'Staff Info',
                width: 200,
                format: (props) => <StaffDetailMemo {...props} />
            },
            {
                id: 'incomeDoneService',
                label: 'Sale amount',
                width: 150,
                format: ({ value, rowData }) => (
                    <Typography variant={'body2'} display="inline">
                        ${value?.toLocaleString()}
                    </Typography>
                )
            },
            {
                id: 'salary',
                label: 'Commission',
                width: 100,
                format: ({ value, rowData }) => (
                    <Typography variant={'body2'} display="inline">
                        ${value}
                    </Typography>
                )
            },
            {
                id: 'salaryMin',
                label: 'Salary min',
                width: 100,
                format: ({ value, rowData }) => (
                    <Typography variant={'body2'} display="inline">
                        ${value}
                    </Typography>
                )
            },
            {
                id: 'tip',
                label: 'Tip',
                width: 100,
                format: ({ value }) => {
                    return (
                        <Typography variant={'body2'} display="inline">
                            ${value ?? 0}
                        </Typography>
                    );
                }
            }
        ],
        []
    );

    const headerRenderer = ({ column }) => {
        return <Typography variant="body1">{column.label}</Typography>;
    };

    const handleRowClick = React.useCallback(({ rowData }) => {
        setOpen(true);
        setSelectedStaff(rowData);
    }, []);

    const handleClose = React.useCallback(() => {
        setSelectedStaff(null);
        setOpen(false);
    }, []);

    const handleStartDateChange = React.useCallback((date) => {
        setStartDate(date);
        setStartDatePresent(moment(date).format(`dddd, DD MMMM YYYY`));
    }, []);

    const handleEndDateChange = React.useCallback((date) => {
        setEndDate(date);
        setEndDatePresent(moment(date).format(`dddd, DD MMMM YYYY`));
    }, []);

    return (
        <LayoutTabContent isLoading={false}>
            <Grid container wrap="nowrap" className={classes.root} direction="column" spacing={4}>
                <Grid item>
                    <Grid
                        container
                        direction={isMobile ? 'column' : 'row'}
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        {/* <Grid item className={isMobile ? classes.fullWidth : ``}>
                            <ButtonGroup
                                color="primary"
                                fullWidth={isMobile}
                                aria-label="outlined primary button group"
                            >
                                <Button
                                    variant={mode === STATISTIC_MODE.DAY ? 'contained' : 'outlined'}
                                    onClick={() => setMode(STATISTIC_MODE.DAY)}
                                >
                                    Day
                                </Button>
                                <Button
                                    variant={mode === STATISTIC_MODE.WEEK ? 'contained' : 'outlined'}
                                    onClick={() => setMode(STATISTIC_MODE.WEEK)}
                                >
                                    Week
                                </Button>
                            </ButtonGroup>
                        </Grid> */}
                        <Grid item className={isMobile ? classes.fullWidth : ``}>
                            <Grid container direction="row" alignItems="center" spacing={2}>
                                <Grid item className={classes.dateItem}>
                                    <PortalDatePicker
                                        maxDate={endDatePresent ? endDate : ''}
                                        onChange={handleStartDateChange}
                                        defaultValue={startDate}
                                        label={t(`start_date`)}
                                        placeholderText={t(`closed_date_start_date_placeholder`)}
                                        withPortal={isMobile}
                                        defaultDatePresent={startDatePresent ? startDatePresent : 'Selected date'}
                                        isUsedDatePresent={true}
                                    />
                                </Grid>
                                {!isMobile && (
                                    <Grid item style={{ display: 'flex', position: 'relative', top: 12 }}>
                                        <ArrowNextSVG color={theme.colors.black} />
                                    </Grid>
                                )}
                                <Grid item className={classes.dateItem}>
                                    <PortalDatePicker
                                        minDate={startDatePresent ? startDate : ''}
                                        onChange={handleEndDateChange}
                                        defaultValue={endDate}
                                        defaultDatePresent={endDatePresent ? endDatePresent : 'Selected date'}
                                        label={t(`end_date`)}
                                        withPortal={isMobile}
                                        isUsedDatePresent={true}
                                        placeholderText={t(`closed_date_end_date_placeholder`)}
                                    />
                                </Grid>
                            </Grid>
                            {/* <Grid container direction="row" alignItems="center">
                                <Grid
                                    item
                                    style={{
                                        marginTop: isMobile ? theme.spacing(2) : ``,
                                        marginBottom: isMobile ? theme.spacing(2) : ``
                                    }}
                                    className={isMobile ? classes.fullWidth : ``}
                                >
                                    {mode === STATISTIC_MODE?.DAY ? (
                                        <SingleDate disabled={isLoading} onChange={onSingleDateChange} />
                                    ) : (
                                        <DateRange disabled={isLoading} onRangeChanged={onWeekDateChange} />
                                    )}
                                </Grid>
                            </Grid> */}
                        </Grid>
                        <Grid item>
                            <Grid container direction="row" alignItems="center" spacing={2} wrap="nowrap">
                                <Grid item container direction="row" alignItems="center" wrap="nowrap">
                                    <Grid item>
                                        <Typography noWrap variant="h4" style={{ fontWeight: 'normal' }}>
                                            Total:
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            style={{ color: theme.colors.blue }}
                                            variant={isMobile ? 'h3' : 'h2'}
                                            display="inline"
                                        >
                                            {merchant.currency}
                                            {actualIncome?.toLocaleString()}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => csvDownload(dataToConvert)}
                                    >
                                        Download CSV
                                    </Button>
                                </Grid>
                                {/* <Grid item container direction="row" alignItems="center" wrap="nowrap">
                                    <Grid item>
                                        <Typography variant="h4" nowrap style={{ fontWeight: 'normal' }}>
                                            Expected income:
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            style={{ color: theme.colors.blue }}
                                            variant={isMobile ? 'h3' : 'h2'}
                                        >
                                            ${expectedIncome?.toLocaleString()}
                                        </Typography>
                                    </Grid>
                                </Grid> */}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.reportDetail}>
                    {isLoading && (
                        <div className={classes.spinnerWrapper}>
                            <Spinner size={40} thick={4} />
                        </div>
                    )}
                    <Table
                        headerRenderer={headerRenderer}
                        filterHeaderRenderer={() => {}}
                        rows={data}
                        columns={columns}
                        page={0}
                        limit={100}
                        totalPages={1}
                        rowPerPageOptions={[]}
                        onChangePageOptions={[]}
                        onChangePage={() => {}}
                        selectableRenderer={false}
                        classNameBody={classes.tableBody}
                        isShowFooter={false}
                        isShowFilter={false}
                        onRowClicked={handleRowClick}
                    />
                </Grid>
            </Grid>
            <Dialog fullScreen={fullScreen} open={open} onClose={handleClose}>
                <StaffStatisticDetail onClose={handleClose} selectedStaff={selectedStaff} />
            </Dialog>
        </LayoutTabContent>
    );
}

export default React.memo(Reports);
