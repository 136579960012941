import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Grid, Divider, Tab, Tabs } from '@material-ui/core';
import ButtonBase from 'components/button/Base';
import AvatarBase from 'components/avatar';
import TabPanel from 'components/tabPanel';
import Appointments from './Appointments';
import DialogActions from 'components/dialog/Action';
import DialogTitle from 'components/dialog/Title';
import DialogContent from 'components/dialog/Content';
import { getFullName } from 'utils/naming';
import { mapGenderName } from 'data/client';
import {
    useClientBookingsByClientId,
    useClientBookingsIsFetching,
    useClientBookingsStatisticByClientId
} from 'hooks/client';
import * as clientActions from '../actions';
import { useDispatch } from 'react-redux';
import { BOOKING_SERVICE_STATUS_TO_LABEL, BOOKING_SERVICE_STATES, getBookingServiceStatusColor } from 'const';
import ShowCase from 'components/statistic/ShowCase';
import { getImageUrl } from 'utils/image';
import PhoneText from 'components/phoneFormat/Text';
import { useHidePhone } from 'hooks/app';

const useStyles = makeStyles((theme) => ({
    root: {},
    title: {
        paddingLeft: 0,
        paddingRight: 0
    },
    content: {
        paddingTop: theme.spacing(4),
        maxWidth: 1024,
        margin: 'auto'
    },
    block: {
        boxSizing: 'border-box',
        boxShadow: theme.shadows[1]
    },
    padding: {
        padding: 24
    },
    spacing: {
        margin: 12
    },
    personalInfo: {
        minWidth: 320
    },
    clientDetail: {
        flex: 1,
        paddingLeft: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0
        }
    },
    scores: {},
    bookingHistory: {
        marginTop: 24
    },
    expand: {
        flex: 1
    },
    fullWidth: {
        width: '100%'
    },
    scoreStatusPadding: {
        paddingTop: 24,
        paddingBottom: 24
    },
    scoreSection: {
        borderBottom: `1px solid ${theme.colors.border}`
    },
    whiteColor: {
        color: theme.palette.common.white
    }
}));

function ClientProfile({ onClose, selectedClient, isInGrid }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();
    const [tabIndex, setTabIndex] = React.useState(0);
    const bookings = useClientBookingsByClientId(selectedClient?.id);
    const isFetching = useClientBookingsIsFetching(selectedClient?.id);
    const statistic = useClientBookingsStatisticByClientId(selectedClient?.id);
    const hidePhone = useHidePhone();

    const options = React.useMemo(() => {
        return [
            {
                title: 'Bookings',
                amount: statistic?.allBookings,
                color: getBookingServiceStatusColor?.[BOOKING_SERVICE_STATUS_TO_LABEL?.[BOOKING_SERVICE_STATES.ARRIVED]]
            },
            {
                title: 'Completed',
                amount: statistic?.completed,
                color: getBookingServiceStatusColor?.[
                    BOOKING_SERVICE_STATUS_TO_LABEL?.[BOOKING_SERVICE_STATES.COMPLETED]
                ]
            },
            {
                title: 'Canceled',
                amount: statistic?.canceled,
                color: getBookingServiceStatusColor?.[
                    BOOKING_SERVICE_STATUS_TO_LABEL?.[BOOKING_SERVICE_STATES.CANCELED]
                ]
            },
            {
                title: 'No Shows',
                amount: statistic?.noShow,
                color: getBookingServiceStatusColor?.[BOOKING_SERVICE_STATUS_TO_LABEL?.[BOOKING_SERVICE_STATES.NO_SHOW]]
            }
        ];
    }, [statistic]);

    const tabs = [
        {
            component: (
                <Appointments onClose={onClose} isInGrid={isInGrid} isLoading={isFetching} bookings={bookings} />
            ),
            index: 0,
            title: `Appointments`
        }
        // {
        //     component: <Products />,
        //     index: 1,
        //     title: `Products`
        // },
        // {
        //     component: <Invoices />,
        //     index: 2,
        //     title: `Invoices`
        // }
    ];

    React.useEffect(() => {
        if (selectedClient) {
            dispatch(
                clientActions.fetchClientBookings({
                    clientId: selectedClient?.id,
                    successCallback: () => {
                        console.log('fetch client bookings success');
                    },
                    errorCallback: () => {
                        console.log('fetch client bookings failed');
                    }
                })
            );
        }
    }, [dispatch, selectedClient]);

    console.log('selectedClient', selectedClient);

    return (
        <>
            <DialogTitle>
                <Typography className={classes.whiteColor} variant="h5">
                    Profile of {getFullName(selectedClient)}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container direction="row" alignItems="flex-start" className={classes.content}>
                    <Grid className={`${classes.personalInfo} ${classes.block}`} item xs={12} md={4}>
                        <Grid container direction="column" alignItems="center">
                            <Grid
                                className={classes.padding}
                                item
                                container
                                direction="column"
                                alignItems="center"
                                spacing={2}
                            >
                                <Grid item>
                                    <AvatarBase src={getImageUrl(selectedClient?.avatar)} size={100}>
                                        {getFullName(selectedClient)?.slice(0, 1)}
                                    </AvatarBase>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h4">{getFullName(selectedClient)}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.fullWidth}>
                                <Divider style={{ background: theme.colors.border }} />
                            </Grid>
                            <Grid item className={classes.fullWidth}>
                                <Grid container className={classes.padding} spacing={3}>
                                    {!hidePhone && (
                                        <Grid item container direction="column">
                                            <Grid item>
                                                <Typography variant="caption">Phone</Typography>
                                            </Grid>
                                            <Grid item>
                                                <PhoneText phone={selectedClient?.user?.phone} />
                                            </Grid>
                                        </Grid>
                                    )}
                                    <Grid item container direction="column">
                                        <Grid item>
                                            <Typography variant="caption">Email</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body1">{selectedClient?.email || '--'}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item container direction="column">
                                        <Grid item>
                                            <Typography variant="caption">Gender</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body1">
                                                {mapGenderName?.[selectedClient?.gender]}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.clientDetail}>
                        <Grid direction="column" container wrap="nowrap">
                            <Grid item className={`${classes.scores}`}>
                                <Grid container direction="column" wrap="nowrap">
                                    <Grid item>
                                        <Grid container direction="row" spacing={3} wrap="nowrap">
                                            {options.map((option) => (
                                                <Grid xs={3} key={option?.title} item>
                                                    <ShowCase {...option} />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={`${classes.bookingHistory} ${classes.block}`}>
                                <Grid container direction="column">
                                    <Grid item>
                                        <Tabs
                                            value={tabIndex}
                                            onChange={(e, index) => setTabIndex(index)}
                                            indicatorColor="primary"
                                        >
                                            {tabs.map((tab) => (
                                                <Tab key={tab.index} textColor="inherit" label={tab?.title} />
                                            ))}
                                        </Tabs>
                                    </Grid>
                                    <Grid item>
                                        {tabs.map((tab) => {
                                            return (
                                                <TabPanel pt={1} key={tab?.index} value={tabIndex} index={tab?.index}>
                                                    {tab.component}
                                                </TabPanel>
                                            );
                                        })}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <ButtonBase onClick={onClose} variant="contained" color="primary" type="submit">
                    Close
                </ButtonBase>
            </DialogActions>
        </>
    );
}

export default React.memo(ClientProfile);
