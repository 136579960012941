import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
// import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { Typography } from '@material-ui/core';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        background: theme.palette.common.white,
        border: `none`
    },
    header: {
        '& th': {
            color: theme.colors.primaryText,
            fontSize: 14,
            fontWeight: 'bold',
            background: theme.palette.common.white
        }
    },
    bodyRow: {
        cursor: 'pointer',
        '&:hover': {
            background: `${theme.colors.hoverItem} !important`
        },
        '& th': {
            background: theme.palette.common.white
        }
    },
    tableCell: {
        borderBottom: `1px solid ${theme.colors.border}`,
        '&:last-child': {
            textAlign: 'end'
        }
    }
}));

function StickyHeadTable({
    rows = [],
    columns = [],
    headerRenderer,
    cellRenderer,
    classNameContainer,
    onRowClicked,
    isDragDisabled = true,
    onDragEnd,
    ...rest
}) {
    const classes = useStyles();

    const handleRowClicked = ({ e, rowData: row, rowIndex }) => {
        onRowClicked && onRowClicked({ e, rowIndex, rowData: row });
    };

    return (
        <TableContainer className={`${classes.root} ${classNameContainer}`}>
            <Table stickyHeader style={{ overflowY: 'scroll' }} aria-label="sticky table">
                <DragDropContext onDragEnd={onDragEnd}>
                    <React.Fragment>
                        <TableHead>
                            <TableRow className={classes.header}>
                                {columns.map((column, index) => (
                                    <TableCell
                                        className={classes.tableCell}
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {headerRenderer({ columnIndex: index, column })}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        <Droppable droppableId="table">
                            {(provided) => (
                                <TableBody {...provided.droppableProps} innerRef={provided.innerRef}>
                                    {rows?.map((row, rowIndex) => {
                                        return (
                                            <Draggable
                                                isDragDisabled={isDragDisabled}
                                                draggableId={row?.id?.toString()}
                                                index={rowIndex}
                                                key={row?.id}
                                            >
                                                {(provided, snapshot) => (
                                                    <TableRow
                                                        className={`${classes.bodyRow} ${
                                                            snapshot.isDragging ? classes.isDragging : ``
                                                        }`}
                                                        onClick={(e) => handleRowClicked({ e, rowData: row, rowIndex })}
                                                        hover
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        key={row?.id}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        innerRef={provided.innerRef}
                                                    >
                                                        {columns?.map((column, columnIndex) => {
                                                            const value = row?.[column?.id];
                                                            return (
                                                                <TableCell
                                                                    className={classes.tableCell}
                                                                    key={column.id}
                                                                    align={column.align}
                                                                >
                                                                    {column.format ? (
                                                                        column.format({ value, rowData: row })
                                                                    ) : (
                                                                        <Typography variant="body2">{value}</Typography>
                                                                    )}
                                                                </TableCell>
                                                            );
                                                        })}
                                                    </TableRow>
                                                )}
                                            </Draggable>
                                        );
                                    })}
                                    {provided.placeholder}
                                </TableBody>
                            )}
                        </Droppable>
                    </React.Fragment>
                </DragDropContext>
            </Table>
        </TableContainer>
    );
}

export default React.memo(StickyHeadTable);
