export function waitForElementToDisplay(selector, time, callback) {
    const cell = document.getElementById(selector);

    if (cell != null) {
        return callback && callback(cell);
    } else {
        setTimeout(function () {
            waitForElementToDisplay(selector, time, callback);
        }, time);
    }
}
