import React from 'react';
import InputBase from 'components/input';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useDispatch } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, Divider, Collapse } from '@material-ui/core';
import * as voucherActions from './action';
// import { useVoucherCode } from 'hooks/voucher';
import ButtonBase from 'components/button/Base';
import * as _ from 'lodash';
import GiftCodeSVG from 'assets/images/svg/GiftCodeSVG';
import NotFoundSVG from 'assets/images/svg/NotFoundSVG';
import { getVoucherCodeByCodeNumberApi } from 'services/voucher';
import SuccessSVG from 'assets/images/svg/SuccessSVG';
import Spinner from 'components/spinner';
// import useMediaQueries from 'hooks/useMediaQueries';
import RedeemHistoriesTable from './RedeemHistoriesTable';
import { getFriendlyShortTime } from 'utils/timing';
import { rounded2Decimal } from 'utils/object';
import { findCheckoutByGiftCodeIdApi } from 'services/checkout';
import { MERCHANT_PERMISSIONS, GIFT_CODE_REDEEM_TYPES } from 'const';
import { useIsHavingPermission } from 'hooks/auth';
import IOSSwitcher from 'components/switch/IOS';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        width: 450,
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    title: {
        alignSelf: 'center'
    },
    spinner: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    content: {
        position: 'relative',
        minHeight: 370,
        padding: 20,
        width: '100%',
        height: '100%',
        flex: 1
    },
    fullWidth: {
        width: '100%'
    },
    item: {
        '&:not(:last-child)': {
            marginBottom: theme.spacing(3)
        }
    },
    foundGift: {
        marginTop: 30
    },
    redeemGift: {
        backgroundColor: theme.colors.background,
        minHeight: 200,
        width: '100%',
        padding: 25,
        borderRadius: 5,
        position: 'relative'
    },
    // illustrator: {
    //     width: '100%',
    //     height: '100%',
    //     margin: '0 auto',
    //     '& svg': {
    //         position: 'relative',
    //         top: -50
    //     }
    // },
    message: {
        color: theme.colors.secondaryText
    },
    mt2: {
        marginTop: theme.spacing(2)
    },
    success: {
        width: 50,
        height: 50
    },
    blackColor: {
        color: theme.colors.primaryText
    }
}));

const STATES = {
    INITIAL: 'INITIAL',
    GIFT_NOT_FOUND: 'GIFT_NOT_FOUND',
    REDEEM_GIFT: 'REDEEM_GIFT',
    TOP_UP_SUCCESS: 'TOP_UP_SUCCESS'
};

export default function RedeemVoucher() {
    const classes = useStyles();
    const theme = useTheme();

    const dispatch = useDispatch();

    // const { isMobile, isTablet } = useMediaQueries();
    // const isTabletOrMobile = React.useMemo(() => {
    //     return isTablet || isMobile;
    // }, [isMobile, isTablet]);
    const [expandHistories, setExpandHistories] = React.useState(false);
    const [codeNumber, setCodeNumber] = React.useState('');
    const [redeemAmount, setRedeemAmount] = React.useState(0);
    const [currentState, setCurrentState] = React.useState(STATES.INITIAL);

    const [voucherCode, setVoucherCode] = React.useState(null);
    const [isFetchingVoucherCode, setIsFetchingVoucherCode] = React.useState(false);
    const [isUpdatingVoucherCode, setIsUpdatingVoucherCode] = React.useState(false);
    const [isCodeNoTax, setIsCodeNoTax] = React.useState(false);
    const [isTopUp, setIsTopUp] = React.useState(false);

    const isHasTopUpGift = useIsHavingPermission(MERCHANT_PERMISSIONS.TOP_UP_GIFT);
    const isHaveCheckOutNotLegit = useIsHavingPermission(MERCHANT_PERMISSIONS.CHECK_OUT);
    const isHaveCheckOutLegit = useIsHavingPermission(MERCHANT_PERMISSIONS.CHECKOUT_LEGIT);

    const isHaveCheckOut = React.useCallback(() => {
        return isHaveCheckOutNotLegit || isHaveCheckOutLegit;
    }, [isHaveCheckOutLegit, isHaveCheckOutNotLegit]);

    const timerRef = React.useRef();

    const handleRedeemAmount = React.useCallback((e) => {
        setRedeemAmount(e.target.value);
    }, []);

    const isValidRedeemInput = React.useMemo(() => {
        return voucherCode?.remainingValue >= redeemAmount;
    }, [redeemAmount, voucherCode]);

    const handleNumberCodeChange = (e) => {
        setCodeNumber(e.target.value);
    };

    React.useEffect(() => {
        const checkCode = async () => {
            try {
                const checkouts = await findCheckoutByGiftCodeIdApi(voucherCode?.id);
                const noTaxCheckouts = checkouts?.filter((co) => !co?.includedTax);
                if (noTaxCheckouts?.length) {
                    setIsCodeNoTax(true);
                } else {
                    setIsCodeNoTax(false);
                }
            } catch ({ message }) {
                console.log('message', message);
            }
        };

        if (voucherCode && isHaveCheckOut) {
            checkCode();
        }
    }, [voucherCode, isHaveCheckOut]);

    const handleSubmitRedeem = React.useCallback(() => {
        setIsUpdatingVoucherCode(true);
        const body = {
            redeemValue: rounded2Decimal(redeemAmount)
        };
        dispatch(
            voucherActions.redeemVoucherCode({
                body,
                voucherCodeId: voucherCode?.id,
                successCallback: ({ voucherCode }) => {
                    console.log('success');
                    setIsUpdatingVoucherCode(false);
                    setVoucherCode(voucherCode);
                    setCurrentState(STATES.REDEEM_SUCCESS);
                },
                errorCallback: () => {
                    setIsUpdatingVoucherCode(false);
                    console.log('errors');
                }
            })
        );
    }, [voucherCode, dispatch, redeemAmount]);

    const handleSubmitTopUp = React.useCallback(() => {
        setIsUpdatingVoucherCode(true);
        const body = {
            amount: rounded2Decimal(redeemAmount)
        };
        dispatch(
            voucherActions.topUpVoucherCode({
                body,
                voucherCodeId: voucherCode?.id,
                successCallback: ({ voucherCode }) => {
                    console.log('success');
                    setIsUpdatingVoucherCode(false);
                    setVoucherCode(voucherCode);
                    setCurrentState(STATES.TOP_UP_SUCCESS);
                },
                errorCallback: () => {
                    setIsUpdatingVoucherCode(false);
                    console.log('errors');
                }
            })
        );
    }, [voucherCode, dispatch, redeemAmount]);

    React.useEffect(() => {
        if (timerRef.current) clearTimeout(timerRef.current);
        timerRef.current = setTimeout(async () => {
            setIsFetchingVoucherCode(true);
            if (codeNumber) {
                const voucherCode = await getVoucherCodeByCodeNumberApi({ codeNumber });

                if (_.isEmpty(voucherCode)) {
                    setCurrentState(STATES.GIFT_NOT_FOUND);
                } else {
                    setCurrentState(STATES.REDEEM_GIFT);
                    setRedeemAmount(voucherCode?.remainingValue);
                }
                setVoucherCode(voucherCode);
            }
            setIsFetchingVoucherCode(false);
        }, 500);
    }, [dispatch, codeNumber]);

    const FoundGift = ({ title = `Check balance`, state }) => {
        return (
            <Grid container className={classes.foundGift} alignItems="center" justifyContent="center">
                <Grid item className={`${classes.item} ${classes.fullWidth}`}>
                    <Grid container justifyContent="center" className={classes.illustrator}>
                        <Grid item>
                            {state === 'INITIAL' && <GiftCodeSVG />}
                            {state === 'GIFT_NOT_FOUND' && <NotFoundSVG />}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.item}>
                    <Typography component="div" className={classes.message} variant="caption">
                        {title}
                    </Typography>
                </Grid>
            </Grid>
        );
    };

    const bodyContent = React.useCallback(() => {
        return (
            <Grid container direction="column">
                {![STATES.REDEEM_SUCCESS, STATES.TOP_UP_SUCCESS]?.includes(currentState) && (
                    <Grid item className={classes.item}>
                        <InputBase
                            placeholder={'Enter Gift Code'}
                            name="codeNumber"
                            label="Gift Code"
                            type="text"
                            value={codeNumber}
                            onChange={handleNumberCodeChange}
                            error={false}
                        />
                    </Grid>
                )}

                {currentState === STATES.REDEEM_GIFT && isHasTopUpGift && (
                    <Grid item className={classes.item}>
                        <Grid container direction="row" alignItems="center" spacing={2}>
                            <Grid item>
                                <IOSSwitcher onChange={(e) => setIsTopUp(e?.target?.checked)} checked={isTopUp} />
                            </Grid>
                            <Grid item>
                                <Typography id="zoom-setting" gutterBottom variant="body2">
                                    Top up
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                )}

                <Grid item className={classes.item}>
                    <Grid container direction="column" className={classes.fullWidth}>
                        {
                            {
                                [STATES.INITIAL]: <FoundGift title={`Check balance`} state={STATES.INITIAL} />,
                                [STATES.GIFT_NOT_FOUND]: (
                                    <FoundGift title={`No Gift Found`} state={STATES.GIFT_NOT_FOUND} />
                                ),
                                [STATES.REDEEM_GIFT]: (
                                    <Grid item className={classes.redeemGift}>
                                        {isCodeNoTax && (
                                            <Grid
                                                style={{
                                                    position: 'absolute',
                                                    top: -10,
                                                    right: 5,
                                                    width: 'fit-content',
                                                    borderRadius: 4
                                                }}
                                                container
                                                direction="row"
                                                spacing={1}
                                            >
                                                <Grid item>
                                                    <Typography
                                                        style={{
                                                            padding: `2px 5px`,
                                                            background: theme.colors.red,
                                                            borderRadius: 4
                                                        }}
                                                        variant="body2"
                                                    >
                                                        No Tax
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography
                                                        style={{
                                                            padding: `2px 5px`,
                                                            background: theme.colors.sun,
                                                            borderRadius: 4
                                                        }}
                                                        variant="body2"
                                                    >
                                                        Remember to collect tax
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        )}
                                        <Grid container justifyContent="center" spacing={3}>
                                            <Grid item className={classes.fullWidth} container justifyContent="center">
                                                <Typography variant="h6">
                                                    Outstanding ({voucherCode?.remainingValue} $CAD)
                                                </Typography>
                                            </Grid>
                                            <Grid item className={classes.fullWidth}>
                                                <InputBase
                                                    label={'Amount'}
                                                    placeholder={'Redeemable amount'}
                                                    name="redeem"
                                                    error={!isValidRedeemInput && !isTopUp}
                                                    errorText={'The value must be less than redeem amount'}
                                                    value={redeemAmount}
                                                    onChange={handleRedeemAmount}
                                                    type="number"
                                                    step="any"
                                                />
                                            </Grid>
                                            <Grid item className={classes.fullWidth}>
                                                <Grid item style={{ flex: 1 }}>
                                                    <ButtonBase
                                                        type="submit"
                                                        color="primary"
                                                        variant={'contained'}
                                                        onClick={isTopUp ? handleSubmitTopUp : handleSubmitRedeem}
                                                        fullWidth={true}
                                                        disabled={isTopUp ? false : !isValidRedeemInput}
                                                    >
                                                        {isTopUp ? 'Top up' : 'Redeem'} gift
                                                    </ButtonBase>
                                                </Grid>
                                            </Grid>
                                            {voucherCode?.histories?.length > 0 && (
                                                <Grid item className={classes.fullWidth}>
                                                    <Grid container direction="column" spacing={2}>
                                                        <Grid item>
                                                            <Grid
                                                                container
                                                                alignItems="center"
                                                                direction="row"
                                                                spacing={2}
                                                            >
                                                                <Grid item>
                                                                    <Typography variant="body1">
                                                                        Redeem histories
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography
                                                                        variant="body2"
                                                                        onClick={() =>
                                                                            setExpandHistories(!expandHistories)
                                                                        }
                                                                        style={{
                                                                            color: theme.colors.template,
                                                                            cursor: 'pointer',
                                                                            textDecoration: 'underline'
                                                                        }}
                                                                    >
                                                                        {expandHistories ? '(Hide)' : '(Show)'}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Collapse in={expandHistories}>
                                                            <Grid item>
                                                                <RedeemHistoriesTable
                                                                    height={250}
                                                                    histories={voucherCode?.histories?.map(
                                                                        (history) => ({
                                                                            ...history,
                                                                            method:
                                                                                GIFT_CODE_REDEEM_TYPES?.[history?.type],
                                                                            amount: `$${history.amount}`,
                                                                            redeemedAt: getFriendlyShortTime(
                                                                                history?.createdAt
                                                                            )
                                                                        })
                                                                    )}
                                                                />
                                                            </Grid>
                                                        </Collapse>
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                ),
                                [STATES.REDEEM_SUCCESS]: (
                                    <FoundGift
                                        title={
                                            <Grid
                                                className={classes.mt2}
                                                container
                                                direction="column"
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Grid item>
                                                    <SuccessSVG className={classes.success} />
                                                </Grid>
                                                <Grid item style={{ marginTop: 20 }}>
                                                    <Typography variant="h4">Redeem success</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body">
                                                        {`Remaining Value: ${voucherCode?.remainingValue}`}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        }
                                    />
                                ),
                                [STATES.TOP_UP_SUCCESS]: (
                                    <FoundGift
                                        title={
                                            <Grid
                                                className={classes.mt2}
                                                container
                                                direction="column"
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Grid item>
                                                    <SuccessSVG className={classes.success} />
                                                </Grid>
                                                <Grid item style={{ marginTop: 20 }}>
                                                    <Typography variant="h4">Top up success</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body">
                                                        {`Remaining Value: ${voucherCode?.remainingValue}`}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        }
                                    />
                                )
                            }[currentState]
                        }
                    </Grid>
                </Grid>
            </Grid>
        );
    }, [
        isHasTopUpGift,
        isTopUp,
        isCodeNoTax,
        classes,
        codeNumber,
        currentState,
        handleRedeemAmount,
        handleSubmitRedeem,
        isValidRedeemInput,
        redeemAmount,
        voucherCode,
        theme,
        expandHistories,
        handleSubmitTopUp
    ]);

    return (
        <Grid container className={classes.root} direction="column">
            <Grid item className={classes.title}>
                <DialogTitle>
                    <Typography className={classes.blackColor} variant="h5">
                        {isTopUp ? 'Top up' : 'Redeem'} Gift
                    </Typography>
                </DialogTitle>
            </Grid>

            <Grid item>
                <Divider light />
            </Grid>

            <Grid item className={classes.content}>
                {(isFetchingVoucherCode || isUpdatingVoucherCode) && (
                    <div className={classes.spinner}>
                        <Spinner size={40} thick={4} />
                    </div>
                )}
                {bodyContent()}
            </Grid>
        </Grid>
    );
}
