import React from 'react';
import { Grid, Typography, Button, CircularProgress } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { getFullName } from 'utils/naming';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { getCurrentDateFormat, getAppendMerchantTimezoneToDate, getDatetimeFormat } from 'utils/timing';
import { useDispatch } from 'react-redux';
import * as turnActions from './actions';
import { useInTurnStaffs } from 'hooks/turn';
import { io } from 'socket.io-client';
import { useUserMerchantId } from 'hooks/auth';
import { ROOMS, TOPICS, TURN_REALTIME_ACTIONS } from 'const';
import { API_URL } from '../../config';
import { getItem } from 'utils/localStorage';
import { TOKEN_NAME } from 'const';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 1024,
        margin: 'auto',
        height: '100vh',
        background: theme.colors.ghostwhite
    },
    header: {
        height: '80px',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        background: theme.palette.common.white,
        width: '100%'
    },
    table: {
        flex: 1,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        width: '100%',
        marginTop: 24,
        // height: `calc(100vh - 80px)`,
        overflowY: 'auto',
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
            height: `calc(100vh - 80px)`,
            maxHeight: `-webkit-fill-available`
        }
    },
    container: {
        overflowY: 'auto',
        height: `calc(100vh - 80px)`,
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
            height: `calc(100vh - 80px)`,
            maxHeight: `-webkit-fill-available`
        }
    },
    item: {
        '&:not(:first-child)': {
            marginTop: theme.spacing(5)
        }
    },
    dragItem: {
        width: 400,
        height: 400,
        borderRadius: '50%',
        background: theme.palette.common.white,
        [theme.breakpoints.down('sm')]: {
            width: 380,
            height: 380
        },
        [theme.breakpoints.down('xs')]: {
            width: 200,
            height: 200
        }
    },
    staffName: {
        [theme.breakpoints.down('sm')]: {
            fontSize: 15
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 15
        }
    },
    btnSubmit: {
        position: 'relative'
    },
    buttonProgress: {
        color: theme.palette.common.white,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -30,
        marginLeft: -30
    }
}));

function Item({ staff, turnIndex }) {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = React.useState(false);

    const markOut = () => {
        setIsLoading(true);
        dispatch(
            turnActions.kickStaffOutOfTurn({
                staffId: +staff?.id,
                staffName: getFullName(staff),
                successCallback: () => {
                    console.log('kick staff out of turn success');
                },
                errorCallback: () => {
                    setIsLoading(false);
                    console.log('kick staff out of turn error');
                }
            })
        );
    };

    return (
        <Grid className={classes.dragItem} container direction="column" alignItems="center" justifyContent="center">
            <Grid item>
                <Typography className={classes.staffName} variant="h2">
                    {getFullName(staff)}
                </Typography>
            </Grid>
            <Grid item className={classes.btnSubmit}>
                <Button
                    onClick={markOut}
                    style={{
                        padding: 20,
                        borderRadius: '50%',
                        width: 100,
                        height: 100,
                        boxShadow: theme.shadows[3],
                        fontSize: 20,
                        marginTop: 10
                    }}
                    variant="contained"
                    color="primary"
                >
                    {isLoading ? <CircularProgress size={60} className={classes.buttonProgress} /> : 'Next'}
                </Button>
            </Grid>
            <Grid item style={{ marginTop: 10 }}>
                <Typography variant="h3">Turn: {turnIndex + 1}</Typography>
            </Grid>
        </Grid>
    );
}

function InTurn() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const merchantId = useUserMerchantId();
    const inTurnStaffs = useInTurnStaffs();
    const token = getItem(TOKEN_NAME);

    const isHaveNoStaff = React.useMemo(() => {
        return !inTurnStaffs?.length;
    }, [inTurnStaffs]);

    React.useEffect(() => {
        dispatch(
            turnActions.getInTurnList({
                query: {
                    startDate: getAppendMerchantTimezoneToDate(getDatetimeFormat(new Date()))
                },
                successCallback: () => {
                    console.log('get list success');
                },
                errorCallback: () => {
                    console.log('get list failed');
                }
            })
        );
    }, [dispatch]);

    const _inDataHandler = React.useCallback(
        (inData) => {
            switch (inData?.type) {
                case TURN_REALTIME_ACTIONS.ADD:
                    return dispatch(turnActions.registerStaffToInTurnSocket({ staff: inData?.staff }));
                case TURN_REALTIME_ACTIONS.REMOVE:
                    return dispatch(turnActions._kickStaffOutOfTurn({ staffId: +inData?.staff?.id }));
                case TURN_REALTIME_ACTIONS.RESET:
                    return dispatch(turnActions._resetInTurnList());
                case TURN_REALTIME_ACTIONS.RE_ORDER:
                    return;
                default:
                    return;
            }
        },
        [dispatch]
    );

    React.useEffect(() => {
        const bookingSocket = io(`${API_URL}/app`, {
            transports: ['websocket'],
            autoConnect: true,
            reconnectionDelay: 2000,
            query: {
                token
            }
        });
        bookingSocket.on('connect', () => {
            bookingSocket.emit(ROOMS.JOIN_ROOM, TOPICS.turn(merchantId));
        });

        bookingSocket.on(ROOMS.JOINED_ROOM, (room) => {
            console.log('JOINED_ROOM', room);
        });

        bookingSocket.on(ROOMS.MODIFIED, (body) => {
            const inData = body?.in;
            _inDataHandler(inData);
        });

        bookingSocket.on(ROOMS.LEAVED_ROOM, (body) => {
            console.log('data LEAVED_ROOM', body);
        });

        return () => {
            bookingSocket.emit(ROOMS.LEAVE_ROOM, TOPICS.turn(merchantId));
        };
    }, [merchantId, token, _inDataHandler]);

    const handleDragEnd = React.useCallback(
        (result) => {
            const { destination, source, draggableId } = result;

            const destinationIndex = destination?.index;
            const sourceIndex = source?.index;

            if (!destination || (destination.droppableId === source.droppableId && destinationIndex === sourceIndex)) {
                return;
            }

            let beforeStaffId = null;
            let afterStaffId = null;
            const oldIndex = inTurnStaffs?.findIndex((stf) => +stf?.id === +draggableId);

            if (destinationIndex === 0) {
                beforeStaffId = +inTurnStaffs?.[0]?.id;
            } else {
                let index = destinationIndex > oldIndex ? destinationIndex : Math.max(0, destinationIndex - 1);
                afterStaffId = +inTurnStaffs?.[index]?.id;
            }

            dispatch(
                turnActions.reOrderStaffTurn({
                    afterStaffId,
                    beforeStaffId,
                    dragId: +draggableId,
                    dropIndex: destination?.index,
                    successCallback: () => {
                        console.log('reOrderStaffTurn success');
                    },
                    errorCallback: () => {
                        console.log('reOrderStaffTurn failed');
                    }
                })
            );
        },
        [inTurnStaffs, dispatch]
    );

    const resetTurn = React.useCallback(() => {
        dispatch(
            turnActions.resetInTurnList({
                successCallback: () => {
                    console.log('reset success');
                },
                errorCallback: () => {
                    console.log('reset failed');
                }
            })
        );
    }, [dispatch]);

    return (
        <Grid container direction="column" alignItems="center" justifyContent="center" className={classes.root}>
            <Grid item className={classes.header}>
                <Typography variant="h3">
                    {inTurnStaffs?.length} Staffs in turn ({getCurrentDateFormat()})
                </Typography>
                {!isHaveNoStaff && (
                    <Button
                        style={{ marginLeft: 8 }}
                        onClick={resetTurn}
                        size="small"
                        variant="contained"
                        color="primary"
                    >
                        Reset
                    </Button>
                )}
            </Grid>
            <Grid item className={classes.table}>
                {isHaveNoStaff ? (
                    <Grid
                        container
                        style={{ height: '100%' }}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item>
                            <Typography variant="h4">There is no staffs in turn list</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2">
                                Please register by adding more staff in out turn tab
                            </Typography>
                        </Grid>
                    </Grid>
                ) : (
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <React.Fragment>
                            <Droppable droppableId="in-turn">
                                {(provided) => (
                                    <Grid
                                        container
                                        direction="column"
                                        {...provided.droppableProps}
                                        innerRef={provided.innerRef}
                                        alignItems="center"
                                    >
                                        {inTurnStaffs?.map((staff, rowIndex) => {
                                            return (
                                                <Draggable
                                                    draggableId={staff?.id?.toString()}
                                                    index={rowIndex}
                                                    key={staff?.id}
                                                >
                                                    {(provided, snapshot) => (
                                                        <Grid
                                                            item
                                                            className={`${classes.item} ${
                                                                snapshot.isDragging ? classes.isDragging : ``
                                                            }`}
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            key={staff?.id}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            innerRef={provided.innerRef}
                                                        >
                                                            <Item staff={staff} turnIndex={rowIndex} />
                                                        </Grid>
                                                    )}
                                                </Draggable>
                                            );
                                        })}
                                        {provided.placeholder}
                                    </Grid>
                                )}
                            </Droppable>
                        </React.Fragment>
                    </DragDropContext>
                )}
            </Grid>
        </Grid>
    );
}

export default React.memo(InTurn);
