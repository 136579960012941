import React from 'react';

function HambergerMenuSVG({ color = '#78778B', ...other }) {
    return (
        <svg {...other} width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 1.2503C0 0.559824 0.5616 0 1.26427 0H18.9464C19.6445 0 20.2107 0.555073 20.2107 1.2503C20.2107 1.94078 19.6491 2.5006 18.9464 2.5006H1.26427C0.566133 2.5006 0 1.94553 0 1.2503ZM0 8.75183C0 8.06135 0.5696 7.50153 1.25653 7.50153H22.7435C23.4373 7.50153 24 8.0566 24 8.75183C24 9.44231 23.4304 10.0021 22.7435 10.0021H1.25653C0.562667 10.0021 0 9.44706 0 8.75183ZM1.27173 15.0031C0.568267 15.0031 0 15.5629 0 16.2534C0 16.9486 0.569333 17.5037 1.27173 17.5037H16.4123C17.1157 17.5037 17.684 16.9438 17.684 16.2534C17.684 15.5581 17.1147 15.0031 16.4123 15.0031H1.27173Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(HambergerMenuSVG);
