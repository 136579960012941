export const deviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return 'tablet';
    } else if (
        /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)
    ) {
        return 'mobile';
    }
    return 'desktop';
};

export function isTouchDevice() {
    return (
        'ontouchstart' in window ||
        (window.matchMedia && window.matchMedia('(hover: none) and (pointer: coarse)').matches)
    );
}

export const isTablet = deviceType() === 'tablet';

export const isNotDesktop = deviceType() !== 'desktop' || (deviceType() === 'desktop' && isTouchDevice());
