import request from './request';
import { SORTING } from 'const';
import isEmpty from 'lodash/isEmpty';
import { objectToQueryParams } from 'utils/queryParams';

const giftApis = {
    gifts: '/gifts',
    gift: (id) => `/gifts/${id}`,
    giftCodes: '/gift-codes',
    redeemGiftCode: (giftCodeId) => `/gift-codes/${giftCodeId}/redeem`,
    topUpGiftCode: (giftCodeId) => `/gift-codes/${giftCodeId}/topup`,
    codes: '/codes',
    code: (code) => `/codes/${code}`,
    findCode: (code) => `/gift-codes/find/${code}`,
    createGift: `/uploads?uploadType=giftLogo`,
    updateUploadGift: (giftId) => `/uploads?uploadType=giftLogo&entityId=${giftId}`,
    duplicate: (giftId) => `/gifts/${giftId}/duplicate`,
    sendMail: (codeId) => `/gift-codes/${codeId}/send`,
    analyze: `/gift-codes/analyze`,
    giftCodesRange: `/gift-codes/range`
};

export async function getVouchersApi({ sort = { createdAt: SORTING.DESCENDING } }) {
    const response = await request.get(`${giftApis.gifts}?relations=${['giftCodes']}`);
    return response?.data;
}

export async function getGiftHistoryApi({ giftId }) {
    const relations = 'giftCodes,giftCodes.client';
    const response = await request.get(`${giftApis.gift(giftId)}?relations=${relations}`);
    return response?.data;
}

export async function createVoucherApi({ formData }) {
    const response = await request.post(`${giftApis.gifts}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return response?.data;
}
export async function duplicateVoucherApi({ voucherId }) {
    const response = await request.post(`${giftApis.duplicate(voucherId)}`);
    return response?.data;
}

export async function sendVoucherCodeApi({ giftCodeId, body }) {
    const response = await request.post(`${giftApis.sendMail(giftCodeId)}`, body);
    return response?.data;
}

export async function updateVoucherWithLogoChangeApi({ formData, voucherId }) {
    const response = await request.put(`${giftApis.gift(voucherId)}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return response?.data;
}

export async function updateVoucherApi({ body, voucherId }) {
    const response = await request.put(`${giftApis.gift(voucherId)}`, body);
    return response?.data;
}

export async function deleteVoucherApi({ voucherId }) {
    const response = await request.delete(`${giftApis.gift(voucherId)}`);
    return response?.data;
}

export async function createVoucherCodeApi({ body }) {
    const response = await request.post(`${giftApis.giftCodes}`, body);
    return response?.data;
}

export async function getVoucherCodesApi({ searchObject }) {
    const response = await request.get(`${giftApis.giftCodes}`);
    return response?.data;
}

export async function getVoucherCodeByCodeNumberApi({ codeNumber }) {
    const response = await request.get(`${giftApis.findCode(codeNumber)}`);
    return response?.data;
}

export async function redeemVoucherCodeApi({ body, voucherCodeId }) {
    const response = await request.put(`${giftApis.redeemGiftCode(voucherCodeId)}`, body);
    return response?.data;
}

export async function topUpVoucherCodeApi({ body, voucherCodeId }) {
    const response = await request.put(`${giftApis.topUpGiftCode(voucherCodeId)}`, body);
    return response?.data;
}

export async function getGiftCodeStatisticApi(query) {
    let extraUrl = giftApis.analyze;
    if (!isEmpty(query)) {
        extraUrl += `?${objectToQueryParams(query)}`;
    }
    const response = await request.get(extraUrl);
    return response.data;
}

export async function getGiftCodesRangeApi(query) {
    let extraUrl = giftApis.giftCodesRange;
    if (!isEmpty(query)) {
        extraUrl += `?${objectToQueryParams(query)}`;
    }
    const response = await request.get(extraUrl);
    return response.data;
}
