import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import InputBase from 'components/input/Form';
import { useTranslation } from 'react-i18next';
const useStyles = makeStyles((theme) => ({
    root: {}
}));

function Address({ values, errors, touched, handleChange, handleBlur, isSubmitting }) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Grid container className={classes.root} direction="column" spacing={3}>
            <Grid item>
                <InputBase
                    label={t(`address`)}
                    name="address"
                    value={values.address || ''}
                    disabled={isSubmitting}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.address && touched.address}
                    errorText={errors.address}
                />
            </Grid>
            {/* <Grid item>
                <InputBase
                    label={t(`suburd`)}
                    name="suburd"
                    value={values.suburd || ''}
                    disabled={isSubmitting}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.suburd && touched.suburd}
                    errorText={errors.suburd}
                />
            </Grid> */}
            <Grid item>
                <Grid container direction="row" wrap="nowrap" spacing={3}>
                    <Grid item xs={6}>
                        <InputBase
                            label={t(`city`)}
                            name="city"
                            value={values.city || ''}
                            disabled={isSubmitting}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.city && touched.city}
                            errorText={errors.city}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputBase
                            label={t(`state`)}
                            name="cityState"
                            value={values.cityState || ''}
                            disabled={isSubmitting}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.cityState && touched.cityState}
                            errorText={errors.cityState}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <InputBase
                    label={t(`zip_code`)}
                    name="postCode"
                    value={values.postCode || ''}
                    disabled={isSubmitting}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.postCode && touched.postCode}
                    errorText={errors.postCode}
                />
            </Grid>
        </Grid>
    );
}

export default React.memo(Address);
