import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, Typography } from '@material-ui/core';
import DialogTitle from 'components/dialog/Title';
import DialogActions from 'components/dialog/Action';
import DialogContent from 'components/dialog/Content';
import ButtonBase from 'components/button/Base';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { convertFloatingToDateTime, getFriendlyDate } from 'utils/timing';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    root: {},
    content: {
        minWidth: 500
    }
}));

function IncomingBookings({ bookings, onClose }) {
    const classes = useStyles();

    return (
        <>
            <DialogTitle>
                <Grid container direction="column" spacing={1}>
                    <Grid item>
                        <Typography variant="h5">Related incoming bookings</Typography>
                        <Typography variant="subtitle2">
                            You should remove all future booking related to this service before delete this!{' '}
                        </Typography>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container direction="column">
                    <TableContainer className={classes.table} component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Client</TableCell>
                                    <TableCell>Datetime</TableCell>
                                    <TableCell>num of services</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {bookings?.map((booking) => {
                                    return (
                                        <TableRow key={booking?.id}>
                                            <TableCell>{booking?.title}</TableCell>
                                            <TableCell>
                                                {convertFloatingToDateTime(booking?.startTime)},{' '}
                                                {getFriendlyDate(booking?.startDate)}
                                            </TableCell>
                                            <TableCell>{booking?.bookingServices?.length}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container direction="row" alignItems="center" justifyContent="flex-end">
                    <Grid item>
                        <ButtonBase startIcon={<CloseIcon />} onClick={onClose} variant="contained">
                            Close
                        </ButtonBase>
                    </Grid>
                </Grid>
            </DialogActions>
        </>
    );
}

export default IncomingBookings;
