export const INITIAL_CALENDAR = ['[CALENDAR]INITIAL_CALENDAR'];
export const INITIAL_CALENDAR_SUCCESS = ['[CALENDAR]INITIAL_CALENDAR_SUCCESS'];
export const INITIAL_CALENDAR_FAILED = ['[CALENDAR]INITIAL_CALENDAR_FAILED'];

export const FETCH_STAFF_BOOKINGS = '[CALENDAR]FETCH_STAFF_BOOKINGS';
export const FETCH_STAFF_BOOKINGS_FAILED = '[CALENDAR]FETCH_STAFF_BOOKINGS_FAILED';
export const FETCH_STAFF_BOOKINGS_SUCCESS = '[CALENDAR]FETCH_STAFF_BOOKINGS_SUCCESS';

export const ADD_BOOKING = '[CALENDAR]ADD_BOOKING';
export const ADD_BOOKING_FAILED = '[CALENDAR]ADD_BOOKING_FAILED';
export const ADD_BOOKING_SUCCESS = '[CALENDAR]ADD_BOOKING_SUCCESS';

export const UPDATE_BOOKING = '[CALENDAR]UPDATE_BOOKING';
export const UPDATE_BOOKING_FAILED = '[CALENDAR]UPDATE_BOOKING_FAILED';
export const UPDATE_BOOKING_SUCCESS = '[CALENDAR]UPDATE_BOOKING_SUCCESS';

export const DELETE_BOOKING = '[CALENDAR]DELETE_BOOKING';
export const DELETE_BOOKING_FAILED = '[CALENDAR]DELETE_BOOKING_FAILED';
export const DELETE_BOOKING_SUCCESS = '[CALENDAR]DELETE_BOOKING_SUCCESS';

export const DELETE_BOOKING_SERVICE = '[CALENDAR]DELETE_BOOKING_SERVICE';
export const DELETE_BOOKING_SERVICE_FAILED = '[CALENDAR]DELETE_BOOKING_SERVICE_FAILED';
export const DELETE_BOOKING_SERVICE_SUCCESS = '[CALENDAR]DELETE_BOOKING_SERVICE_SUCCESS';

export const UPDATE_BOOKING_BY_RESIZE = '[CALENDAR]UPDATE_BOOKING_BY_RESIZE';
export const UPDATE_ZOOM_SETTING = '[CALENDAR]UPDATE_ZOOM_SETTING';

export const FETCH_STAFF_BLOCKED_TIME = '[BLOCKED_TIME]FETCH_STAFF_BLOCKED_TIME';
export const FETCH_STAFF_BLOCKED_TIME_SUCCESS = '[BLOCKED_TIME]FETCH_STAFF_BLOCKED_TIME_SUCCESS';
export const FETCH_STAFF_BLOCKED_TIME_FAILED = '[BLOCKED_TIME]FETCH_STAFF_BLOCKED_TIME_FAILED';

export const ADD_BLOCKED_TIME = '[BLOCKED_TIME]ADD_BLOCKED_TIME';
export const ADD_BLOCKED_TIME_SUCCESS = '[BLOCKED_TIME]ADD_BLOCKED_TIME_SUCCESS';
export const ADD_BLOCKED_TIME_FAILED = '[BLOCKED_TIME]ADD_BLOCKED_TIME_FAILED';

export const UPDATE_BLOCKED_TIME = '[BLOCKED_TIME]UPDATE_BLOCKED_TIME';
export const UPDATE_BLOCKED_TIME_SUCCESS = '[BLOCKED_TIME]UPDATE_BLOCKED_TIME_SUCCESS';
export const UPDATE_BLOCKED_TIME_FAILED = '[BLOCKED_TIME]UPDATE_BLOCKED_TIME_FAILED';

export const DELETE_BLOCKED_TIME = '[BLOCKED_TIME]DELETE_BLOCKED_TIME';
export const DELETE_BLOCKED_TIME_SUCCESS = '[BLOCKED_TIME]DELETE_BLOCKED_TIME_SUCCESS';
export const DELETE_BLOCKED_TIME_FAILED = '[BLOCKED_TIME]DELETE_BLOCKED_TIME_FAILED';

export const UPDATE_BLOCKED_TIME_BY_RESIZE = '[CALENDAR]UPDATE_BLOCKED_TIME_BY_RESIZE';

export const FETCH_STAFF_BOOKINGS_BY_DATE = '[CALENDAR]FETCH_STAFF_BOOKINGS_BY_DATE';
export const FETCH_STAFF_BOOKINGS_BY_DATE_FAILED = '[CALENDAR]FETCH_STAFF_BOOKINGS_BY_DATE_FAILED';
export const FETCH_STAFF_BOOKINGS_BY_DATE_SUCCESS = '[CALENDAR]FETCH_STAFF_BOOKINGS_BY_DATE_SUCCESS';

export const FETCH_STAFF_BLOCKED_TIMES_BY_DATE = '[CALENDAR]FETCH_STAFF_BLOCKED_TIMES_BY_DATE';
export const FETCH_STAFF_BLOCKED_TIMES_BY_DATE_FAILED = '[CALENDAR]FETCH_STAFF_BLOCKED_TIMES_BY_DATE_FAILED';
export const FETCH_STAFF_BLOCKED_TIMES_BY_DATE_SUCCESS = '[CALENDAR]FETCH_STAFF_BLOCKED_TIMES_BY_DATE_SUCCESS';

export const FETCH_STAFF_WORKING_HOURS_BY_DATE = '[CALENDAR]FETCH_STAFF_WORKING_HOURS_BY_DATE';
export const FETCH_STAFF_WORKING_HOURS_BY_DATE_FAILED = '[CALENDAR]FETCH_STAFF_WORKING_HOURS_BY_DATE_FAILED';
export const FETCH_STAFF_WORKING_HOURS_BY_DATE_SUCCESS = '[CALENDAR]FETCH_STAFF_WORKING_HOURS_BY_DATE_SUCCESS';

export const CANCEL_BOOKING_SUCCESS = '[CALENDAR]CANCEL_BOOKING_SUCCESS';

export const DELETE_STAFFS_BOOKING_SERVICES = '[CALENDAR]DELETE_STAFFS_BOOKING_SERVICES';

export const CREATE_BOOKING_SOCKET = '[CALENDAR]CREATE_BOOKING_SOCKET';
export const CREATE_NEW_BOOKING_SERVICE_WITH_EXISTED_BOOKING =
    '[CALENDAR]UPDATE_CREATE_NEW_BOOKING_SERVICE_WITH_EXISTED_BOOKING';
export const UPDATE_BOOKING_SERVICE_SOCKET = '[CALENDAR]UPDATE_BOOKING_SERVICE_SOCKET';
export const DELETE_BOOKING_SERVICE_SOCKET = '[CALENDAR]DELETE_BOOKING_SERVICE_SOCKET';

export const UPDATE_BOOKING_SOCKET = '[CALENDAR]UPDATE_BOOKING_SOCKET';
export const DELETE_BOOKING_SOCKET = '[CALENDAR]DELETE_BOOKING_SOCKET';
export const RESCHEDULE_BOOKING_SOCKET = '[CALENDAR]RESCHEDULE_BOOKING_SOCKET';

export const SEND_NOTIFICATION = '[CALENDAR]SEND_NOTIFICATION';

export const TOGGLE_CLOSE_DATE = `[CALENDAR]TOGGLE_CLOSE_DATE`;

export const SET_SELECTED_DATE = `[CALENDAR]SET_SELECTED_DATE`;

export const CHECKOUT = `[CALENDAR]CHECKOUT`;

export const FETCH_GIFT_CODES = `[CALENDAR]FETCH_GIFT_CODES`;

export const REMOVE_GIFT_CODE = `[CALENDAR]REMOVE_GIFT_CODE`;

export const SET_DATA_BOOKINGS_1_TIME = `[CALENDAR]SET_DATA_BOOKINGS_1_TIME`;
export const INITIAL_DATA_DEFAULT = `[CALENDAR]INITIAL_DATA_DEFAULT`;

export const SET_ALERT_NEW_CHECK_IN = `[CALENDAR]SET_ALERT_NEW_CHECK_IN`;

export const FETCH_BOOKING_LOGS = `[BOOKING]FETCH_BOOKING_LOGS`;
export const FETCH_BOOKING_LOGS_SUCCESS = `[BOOKING]FETCH_BOOKING_LOGS_SUCCESS`;
export const FETCH_BOOKING_LOGS_FAILED = `[BOOKING]FETCH_BOOKING_LOGS_FAILED`;

export const SET_IS_OPEN_CLIENT_ARRIVED = `[BOOKING]SET_IS_OPEN_CLIENT_ARRIVED`;
export const SET_IS_OPEN_CHECK_OUT = `[BOOKING]SET_IS_OPEN_CHECK_OUT`;
export const SET_FILTERED_STAFF = `[BOOKING]SET_FILTERED_STAFF`;

export const SET_FIND_BOOKING_ID = `[BOOKING]SET_FIND_BOOKING_ID`;

export const SET_OPEN_BOOKING = `[BOOKING]SET_OPEN_BOOKING`;
export const SET_IS_DRAGGING = `[BOOKING]SET_IS_DRAGGING`;
export const SET_DRAG_BOOKING_SERVICE = `[BOOKING]SET_DRAG_BOOKING_SERVICE`;
export const SET_SCHEDULE_TYPE = `[BOOKING]SET_SCHEDULE_TYPE`;
export const RESET_CALENDAR = `[BOOKING]RESET_CALENDAR`;

export const SET_CLONE_TIME = `[BOOKING]SET_CLONE_TIME`;
export const SET_CLONE_BOOKING = `[BOOKING]SET_CLONE_BOOKING`;
export const SET_CLONE_STAFF = `[BOOKING]SET_CLONE_STAFF`;
