import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SimpleBar from 'simplebar-react';
import useMediaQueries from 'hooks/useMediaQueries';

const useStyles = makeStyles((theme) => ({
    tableScroll: {
        position: 'relative',
        width: '100%',
        zIndex: 1,
        margin: 'auto',
        overflow: 'auto'
    },
    mainTable: {
        width: '100%',
        // minWidth: 1280,
        margin: 'auto',
        borderCollapse: 'separate',
        borderSpacing: 0,
        '& th:first-child': {
            background: theme.colors.background,
            position: 'sticky',
            left: 0,
            zIndex: 10
        },
        tableLayout: 'fixed'
    },
    cell: {
        padding: 0,
        height: (props) => props.rowHeight
    },
    header: {
        color: theme.colors.primaryText,
        position: 'sticky',
        zIndex: 10,
        top: 0,
        '&:first-child': {
            zIndex: `15 !important`,
            background: `${theme.colors.background} !important`
        }
    },
    rowHeader: {
        background: theme.colors.background
    }
}));

function Table({
    height,
    columnIds,
    rowIds,
    cellRender,
    headerRender,
    rowHeight = 120,
    columnWidth = 160,
    INDEX_COLUMN_WIDTH = 80,
    HEADER_HEIGHT,
    overlay,
    width,
    overlayBody
}) {
    const classes = useStyles({ rowHeight });
    const { isMobile } = useMediaQueries();
    const LIMIT_ROWS_WIDTH = React.useMemo(() => {
        return isMobile ? 4 : 18;
    }, [isMobile]);

    return (
        <div
            style={{
                height,
                width
            }}
            id="client-arrived-table-scroll"
            className={classes.tableScroll}
        >
            <SimpleBar autoHide={false} style={{ maxWidth: width, height: height, maxHeight: height }}>
                <table className={classes.mainTable} id="client-arrived-table">
                    <thead>
                        <tr className={classes.rowHeader}>
                            {columnIds?.map((columnId, columnIndex) => {
                                const width = columnIndex === 0 ? INDEX_COLUMN_WIDTH : columnWidth;
                                const height = HEADER_HEIGHT || rowHeight;

                                return (
                                    <th
                                        className={`${classes.cell} ${classes.header}`}
                                        scope="col"
                                        key={columnId}
                                        style={{
                                            width:
                                                columnIndex === 0
                                                    ? INDEX_COLUMN_WIDTH
                                                    : columnIds?.length === 2 || columnIds?.length < LIMIT_ROWS_WIDTH
                                                    ? '100%'
                                                    : width,
                                            minWidth: columnIndex === 0 ? INDEX_COLUMN_WIDTH : width,
                                            height,
                                            flex: 1
                                        }}
                                    >
                                        {headerRender({
                                            columnIndex,
                                            style: {
                                                width: '100%',
                                                height,
                                                flex: 1
                                            }
                                        })}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody id="client-arrived-main-table-scroll" style={{ overflowY: 'scroll' }}>
                        {rowIds?.map((rowId, rowIndex) => {
                            return (
                                <tr
                                    key={`${rowId}`}
                                    style={{
                                        width,
                                        height: rowHeight,
                                        position: 'relative',
                                        flex: 1
                                    }}
                                >
                                    {columnIds?.map((columnId, columnIndex) => {
                                        return (
                                            <th
                                                scope="col"
                                                style={{ width: columnIndex === 0 ? width : '100%' }}
                                                className={classes.cell}
                                                key={`${columnId}_${rowIndex}`}
                                            >
                                                {cellRender({
                                                    rowIndex,
                                                    columnIndex,
                                                    style: {
                                                        width: '100%',
                                                        height: '100%',
                                                        flex: 1
                                                    }
                                                })}
                                            </th>
                                        );
                                    })}
                                    {typeof overlay === 'function' ? overlay({ rowIndex }) : overlay}
                                </tr>
                            );
                        })}

                        {typeof overlayBody === 'function' ? overlayBody() : overlayBody}
                    </tbody>
                </table>
            </SimpleBar>
        </div>
    );
}
export default React.memo(Table);
