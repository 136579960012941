import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import useMediaQueries from 'hooks/useMediaQueries';
import SimpleBar from 'simplebar-react';

const useStyles = makeStyles((theme) => ({
    tableScroll: {
        position: 'relative',
        width: '100%',
        zIndex: 1,
        margin: 'auto',
        overflow: 'hidden'
    },
    mainTable: {
        width: '100%',
        background: theme.palette.common.white,
        margin: 'auto',
        borderCollapse: 'separate',
        borderSpacing: 0,
        '& th:first-child': {
            position: 'sticky',
            left: 0,
            zIndex: 10,
            background: theme.palette.common.white
        }
    },
    cell: {
        padding: 0,
        height: (props) => props.rowHeight
    },
    header: {
        background: theme.palette.common.white,
        color: theme.colors.primaryText,
        position: 'sticky',
        zIndex: 10,
        top: 0,

        '&:first-child': {
            zIndex: `15 !important`,
            background: theme.palette.common.white
        }
    }
}));

function Table({
    height,
    columnCount = 30,
    rowCount = 50,
    cellRender,
    freezingRender,
    headerRender,
    rowHeight = 50,
    columnWidth = 140,
    onCellClicked,
    INDEX_COLUMN_WIDTH = 80,
    HEADER_HEIGHT,
    overlay
}) {
    const classes = useStyles({ rowHeight });
    // const { isMobile } = useMediaQueries();

    return (
        <div
            style={{
                height: '100%',
                overflowY: 'hidden'
            }}
            id="table-scroll"
            className={classes.tableScroll}
        >
            <SimpleBar style={{ maxHeight: '100%' }}>
                <table id="main-table" className={classes.mainTable}>
                    <thead>
                        <tr>
                            {Array.from(Array(columnCount).keys()).map((column, columnIndex) => {
                                const width = columnIndex === 0 ? INDEX_COLUMN_WIDTH : columnWidth;
                                const height = HEADER_HEIGHT || rowHeight;
                                return (
                                    <th
                                        className={`${classes.cell} ${classes.header}`}
                                        scope="col"
                                        key={columnIndex}
                                        style={{ width, minWidth: width, height }}
                                    >
                                        {headerRender({
                                            columnIndex,
                                            style: {
                                                width: '100%',
                                                height: '100%'
                                            }
                                        })}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {Array.from(Array(rowCount).keys()).map((rowIndex) => {
                            return (
                                <tr key={rowIndex}>
                                    {Array.from(Array(columnCount).keys()).map((column, columnIndex) => {
                                        if (columnIndex === 0)
                                            return (
                                                <th
                                                    className={`${classes.cell} ${classes.w40}`}
                                                    scope="col"
                                                    key={`${rowIndex}_${columnIndex}`}
                                                    style={{
                                                        width: INDEX_COLUMN_WIDTH,
                                                        minWidth: INDEX_COLUMN_WIDTH,
                                                        height: rowHeight,
                                                        flex: 1
                                                    }}
                                                >
                                                    {freezingRender({
                                                        rowIndex,
                                                        columnIndex,
                                                        style: {
                                                            width: '100%',
                                                            height: '100%'
                                                        }
                                                    })}
                                                </th>
                                            );
                                        return (
                                            <td
                                                key={`${rowIndex}_${columnIndex}`}
                                                onClick={() =>
                                                    onCellClicked && onCellClicked({ rowIndex, columnIndex })
                                                }
                                                className={classes.cell}
                                                style={{ width: columnWidth, minWidth: columnWidth, height: rowHeight }}
                                            >
                                                {cellRender({
                                                    rowIndex,
                                                    columnIndex,
                                                    style: {
                                                        width: '100%',
                                                        height: '100%'
                                                    }
                                                })}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                        {typeof overlay === 'function' ? overlay() : overlay}
                    </tbody>
                </table>
            </SimpleBar>
        </div>
    );
}

export default React.memo(Table);
