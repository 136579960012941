import React from 'react';
import { useSelector } from 'react-redux';
import { getFullName } from 'utils/naming';
import Avatar from 'components/avatar';
// import { getImageUrl } from 'utils/image';
import AvatarIconSVG from 'assets/images/svg/AvatarIconSVG';
import { BOOKING_STATES } from 'const';

export function useClients() {
    return useSelector((state) => state.client?.list);
}

export function useClientList() {
    return useSelector((state) => state.client?.list?.filter((client) => !client.isDeleted));
}

export function useTotalClients() {
    return useSelector((state) => state.client?.total);
}

export function useIsFetchingClient() {
    return useSelector((state) => state.client?.isFetchingClient);
}

export function useClientOptions() {
    return useSelector(
        (state) =>
            state.client?.list?.map((sf) => ({
                ...sf,
                label: getFullName(sf),
                value: sf.userId
            })) || []
    );
}

export function useClientOptionWithPhone() {
    return useSelector((state) =>
        state.client?.list?.map((client) => {
            const fullName = getFullName(client);
            return {
                label: `${fullName} (${client?.phone}) `,
                value: client?.id,
                email: client?.email,
                icon: () => (
                    <Avatar size="medium">
                        <AvatarIconSVG style={{ width: '100%', height: '100%' }} />
                    </Avatar>
                )
            };
        })
    );
}

export function useClientBooking() {
    return useSelector((state) => state.client?.clientBooking);
}

export function useClientByClientId(clientId) {
    return useSelector((state) => state.client?.clientBooking?.[clientId]);
}

export function useClientBookingsByClientId(clientId) {
    return useSelector((state) => state.client?.clientBooking?.[clientId]?.bookings || []);
}

export function useClientBookingsIsFetching(clientId) {
    return useSelector((state) => state.client?.clientBooking?.[clientId]?.isFetching);
}

export function useClientBookingsStatisticByClientId(clientId) {
    return useSelector((state) => {
        const clientBookings = state.client?.clientBooking?.[clientId]?.bookings || [];

        let data = {
            allBookings: clientBookings?.length,
            completed: 0,
            canceled: 0,
            declined: 0,
            noShow: 0,
            upComing: 0
        };

        for (let clientBooking of clientBookings) {
            const bookingState = clientBooking?.state;
            const isFuture = clientBooking?.isFuture;

            if (isFuture && bookingState !== BOOKING_STATES.CLIENT_CANCEL) {
                data = {
                    ...data,
                    upComing: data?.upComing + 1
                };
            }

            if ([BOOKING_STATES.CLIENT_ARRIVED, BOOKING_STATES.END].includes(clientBooking?.state)) {
                data = {
                    ...data,
                    completed: data?.completed + 1
                };
            }

            if ([BOOKING_STATES.CLIENT_CANCEL].includes(clientBooking?.state)) {
                data = {
                    ...data,
                    canceled: data?.canceled + 1
                };
            }

            if ([BOOKING_STATES.DECLINE].includes(clientBooking?.state)) {
                data = {
                    ...data,
                    declined: data?.declined + 1
                };
            }

            if ([BOOKING_STATES.NO_SHOW].includes(clientBooking?.state)) {
                data = {
                    ...data,
                    noShow: data?.noShow + 1
                };
            }
        }

        return data;
    });
}
