import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Badge, Grid, Typography, Avatar, IconButton, useTheme } from '@material-ui/core';
import { MAX_ARRIVED_LIST_SHOW } from 'const';
import { useArrivedListSortedByUpdatedTime, useIsSameToday } from 'hooks/calendar';
import { useHistory } from 'react-router-dom';
import Tooltip from 'components/tooltip';
import PhoneFormat from 'components/phoneFormat';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { getFullName } from 'utils/naming';
import { getImageUrl } from 'utils/image';
import { DEFAULT_COLOR } from 'utils/color';

const useStyles = makeStyles((theme) => ({
    root: {},
    whiteColor: {
        color: theme.palette.common.white,
        '& input': {
            color: theme.palette.common.white
        }
    },
    order: {
        width: 16,
        height: 16,
        background: theme.colors.sun,
        borderRadius: 12,
        boxShadow: theme.shadows[1],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.common.white,
        fontWeight: 'bold',
        position: 'relative',
        left: 10,
        fontSize: 13,
        '&:hover': {
            background: theme.colors.sun
        }
    }
}));

function ClientArrivedList() {
    const theme = useTheme();
    const classes = useStyles();
    const history = useHistory();

    const arrivedList = useArrivedListSortedByUpdatedTime();

    const openClientArrivedList = React.useCallback(() => {
        history.push('/client-arrived');
    }, [history]);

    const isToday = useIsSameToday();

    return (
        isToday && (
            <Grid container alignItems="center" direction="row" spacing={1}>
                {arrivedList?.slice(0, MAX_ARRIVED_LIST_SHOW)?.map((client, index) => {
                    const canDoCategories = client?.canDoCategories;
                    return (
                        <Tooltip
                            key={client?.bookingId}
                            title={
                                <Grid container direction="column">
                                    <Grid item>
                                        <Typography className={classes.whiteColor} variant="body2">
                                            {getFullName(client)}
                                        </Typography>
                                    </Grid>

                                    {client?.phone && (
                                        <Grid item className={classes.whiteColor}>
                                            <PhoneFormat bold number={client?.phone} />
                                        </Grid>
                                    )}
                                </Grid>
                            }
                        >
                            <Grid item>
                                <Badge
                                    overlap="circular"
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center'
                                    }}
                                    badgeContent={
                                        <Grid
                                            container
                                            direction="row"
                                            wrap="nowrap"
                                            justifyContent="center"
                                            style={{ left: -2, top: 25, position: 'relative' }}
                                        >
                                            {canDoCategories?.slice(0, 8).map((color) => {
                                                return (
                                                    <Grid
                                                        item
                                                        key={color}
                                                        style={{
                                                            width: 10,
                                                            height: 10,
                                                            marginLeft: -3,
                                                            border: `1px solid ${theme.palette.common.white}`,
                                                            borderRadius: 5,
                                                            background: color || DEFAULT_COLOR
                                                        }}
                                                    ></Grid>
                                                );
                                            })}
                                        </Grid>
                                    }
                                >
                                    <Badge
                                        overlap="circular"
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right'
                                        }}
                                        badgeContent={<div className={classes.order}>{index + 1}</div>}
                                    >
                                        <Avatar
                                            sizes="small"
                                            style={{ width: 32, height: 32, fontSize: 16 }}
                                            alt={getFullName(client)}
                                            src={getImageUrl(client?.avatar)}
                                        />
                                    </Badge>
                                </Badge>
                            </Grid>
                        </Tooltip>
                    );
                })}
                {arrivedList?.length > MAX_ARRIVED_LIST_SHOW && (
                    <Grid item style={{ cursor: 'pointer' }}>
                        <Badge
                            overlap="circular"
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right'
                            }}
                            badgeContent={
                                <IconButton
                                    className={classes.order}
                                    style={{ position: 'relative', top: -5, right: -5 }}
                                >
                                    <OpenInNewIcon fontSize="small" />
                                </IconButton>
                            }
                            onClick={openClientArrivedList}
                        >
                            <Avatar>+{arrivedList?.length - MAX_ARRIVED_LIST_SHOW}</Avatar>
                        </Badge>
                    </Grid>
                )}
            </Grid>
        )
    );
}

export default ClientArrivedList;
