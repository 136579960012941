import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, CircularProgress, Avatar } from '@material-ui/core';
import {
    usePermissionsOptions,
    useMerchantSlotInterval,
    useTimeZoneOptions,
    useApplicationFeeOptions,
    useCancelBeforeBookingMinutesOptions,
    useBeforeBookingMinutesOptions,
    userPaymentCurrencyOptions,
    useBookingApplicationFeeOptions
} from 'hooks/merchant';
import NativeSelect from 'components/select/Native';
import ButtonBase from 'components/button/Base';
import ReactSelect from 'react-select';
import InputBase from 'components/input/Form';
import { useDispatch } from 'react-redux';
import * as settingActions from './actions';
import * as merchantActions from 'merchant/actions';
import { MERCHANT_DEFAULT_OPTIONS, AVATAR_EXTENSIONS, DEFAULT_CURRENCY } from 'const';
import PhoneInput from 'components/input/Phone';
import { addPlus, removePlus } from 'utils/phone';
import { useDropzone } from 'react-dropzone';
import IconCameraSVG from 'assets/images/svg/IconCameraSVG';
import SuccessIllustratorSVG from 'assets/images/svg/SuccessIllustratorSVG';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        overflowY: 'auto',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3)
    },
    upload: {
        flexBasis: 300,
        marginTop: theme.spacing(3)
    },
    options: {
        flex: 1
    },
    btnSubmit: {
        position: 'relative'
    },
    buttonProgress: {
        color: theme.palette.common.white,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    media: {
        border: `1px solid ${theme.colors.border}`,
        width: 211,
        height: 211,
        position: 'relative',
        borderRadius: theme.radius(3),
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            width: 180,
            height: 180
        },
        '& .alreadyHaveImage': {
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            visibility: 'hidden',
            left: 0,
            top: 0,
            '& svg': {
                '& path': {
                    fill: theme.palette.common.white
                }
            },
            '&  div': {
                outline: 'none'
            }
        },
        '&:hover': {
            '& .alreadyHaveImage': {
                visibility: 'visible',
                cursor: 'pointer'
            }
        }
    },
    fullWidthHeight: {
        width: 330,
        height: 350,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    image: {
        width: '100%',
        height: '100%',
        borderRadius: theme.radius(2),
        '&.MuiAvatar-colorDefault': {
            background: theme.colors.background
        },
        cursor: 'pointer',
        position: 'relative'
    },
    success: {
        width: '100%',
        height: '100%',
        marginTop: 100
    }
}));

const STEPS = {
    CREATE_MERCHANT: 'CREATE_MERCHANT',
    UPLOAD_MERCHANT_LOGO: 'UPLOAD_MERCHANT_LOGO',
    CREATE_MERCHANT_SUCCESS: `CREATE_MERCHANT_SUCCESS`
};

function Setting() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const permissionOptions = usePermissionsOptions();
    const paymentCurrencyOptions = userPaymentCurrencyOptions();

    const slotIntervalOptions = useMerchantSlotInterval();
    const timezoneOptions = useTimeZoneOptions();
    const applicationFeeOptions = useApplicationFeeOptions();
    const bookingApplicationFeeOptions = useBookingApplicationFeeOptions();
    const beforeBookingMinutesOptions = useBeforeBookingMinutesOptions();
    const cancelBeforeBookingMinutesOptions = useCancelBeforeBookingMinutesOptions();
    const [currentStep, setCurrentStep] = React.useState(STEPS.CREATE_MERCHANT);
    const [createdMerchant, setCreatedMerchant] = React.useState(null);

    const [selectedSlotInterval, setSelectedSlotInterval] = React.useState(MERCHANT_DEFAULT_OPTIONS.slotInterval);

    const [permissions, setPermissions] = React.useState(MERCHANT_DEFAULT_OPTIONS.permissions);
    const [timezone, setTimezone] = React.useState(MERCHANT_DEFAULT_OPTIONS.timezone);
    const [cancelBefore, setCancelBefore] = React.useState(MERCHANT_DEFAULT_OPTIONS.bookingCancelBeforeMinutes);
    const [bookingBefore, setBookingBefore] = React.useState(MERCHANT_DEFAULT_OPTIONS.bookingBeforeMinutes);
    const [address, setAddress] = React.useState('');
    const [caid, setCaid] = React.useState('');
    const [applicationFee, setApplicationFee] = React.useState(null);
    const [bookingApplicationFeeAmount, setBookingApplicationFeeAmount] = React.useState(null);
    const [contactEmail, setContactEmail] = React.useState('');
    const [ownerEmail, setOwnerEmail] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);
    const [title, setTitle] = React.useState('');
    const [slug, setSlug] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [thumbnail, setThumbnail] = React.useState(null);
    const [file, setFile] = React.useState(null);
    const [reviewUrl, setReviewUrl] = React.useState(null);
    const [taxRate, setTaxRate] = React.useState(0);
    const [taxNumber, setTaxNumber] = React.useState('');
    const [merchantMap, setMerchantMap] = React.useState('');
    const [incomePassword, setIncomePassword] = React.useState('');
    const [currency, setCurrency] = React.useState('$');
    const [displayPhone, setDisplayPhone] = React.useState('');
    const [lat, setLat] = React.useState('');
    const [lng, setLng] = React.useState('');
    const [paymentCurrency, setPaymentCurrency] = React.useState(paymentCurrencyOptions?.[0]);
    const [discountPercent, setDiscountPercent] = React.useState(0);

    const onRejectedHandler = React.useCallback((rejectedFiles) => {}, []);

    const onDropAcceptedHandler = React.useCallback((acceptedFiles) => {
        const file = acceptedFiles?.[0];
        if (file) {
            setThumbnail(URL.createObjectURL(file));
        }
        setFile(file);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDropAccepted: onDropAcceptedHandler,
        onDropRejected: onRejectedHandler,
        accept: AVATAR_EXTENSIONS
    });

    const handleSlotIntervalChange = React.useCallback((e) => {
        setSelectedSlotInterval(e.target.value);
    }, []);

    const handlePermissionChange = React.useCallback((options) => {
        setPermissions(options);
    }, []);

    const handlePaymentCurrencyChange = React.useCallback((option) => {
        setPaymentCurrency(option);
    }, []);

    const handleTimezoneChange = React.useCallback((e) => {
        setTimezone(e.target?.value);
    }, []);

    const handleBookingBeforeChange = React.useCallback((e) => {
        setBookingBefore(e.target?.value);
    }, []);

    const handleCancelBeforeChange = React.useCallback((e) => {
        setCancelBefore(e.target?.value);
    }, []);

    const handleGiftApplicationFeeChange = React.useCallback((e) => {
        setApplicationFee(e.target?.value);
    }, []);

    const handleBookingApplicationFeeChange = React.useCallback((e) => {
        setBookingApplicationFeeAmount(e.target?.value);
    }, []);

    const isCreateMerchant = React.useMemo(() => {
        return STEPS.CREATE_MERCHANT === currentStep;
    }, [currentStep]);

    const handleSave = React.useCallback(() => {
        if (isCreateMerchant) {
            const createBody = {
                permissions: permissions?.map((option) => option?.value),
                address,
                email: contactEmail,
                ownerEmail,
                applicationFeeAmount: +applicationFee,
                bookingApplicationFeeAmount: +bookingApplicationFeeAmount,
                caid,
                slotInterval: +selectedSlotInterval,
                timezone,
                bookingCancelBeforeMinutes: +cancelBefore,
                bookingBeforeMinutes: +bookingBefore,
                title,
                slug,
                password,
                phone: removePlus(phone),
                currency: currency || DEFAULT_CURRENCY,
                reviewUrl,
                taxNumber,
                taxRate,
                discountPercent,
                merchantMap,
                incomePassword,
                displayPhone,
                lng: lng || null,
                lat: lat || null,
                paymentCurrency: paymentCurrency?.value || 'cad'
            };

            console.log('createBody', createBody);
            setIsLoading(true);
            dispatch(
                settingActions?.createMerchant({
                    body: createBody,
                    successCallback: (merchant) => {
                        setIsLoading(false);
                        setCurrentStep(STEPS.UPLOAD_MERCHANT_LOGO);
                        setCreatedMerchant(merchant);
                    },
                    errorCallback: () => {
                        setIsLoading(false);
                        console.log('create merchant failed');
                    }
                })
            );
        } else {
            setIsLoading(true);
            dispatch(
                merchantActions.uploadAvatar({
                    merchantId: +createdMerchant?.id,
                    file,
                    successCallback: (merchant) => {
                        setIsLoading(false);
                        setCurrentStep(STEPS.CREATE_MERCHANT_SUCCESS);
                    },
                    errorCallback: () => {
                        setIsLoading(false);
                        console.log('upload merchant failed');
                    }
                })
            );
        }
    }, [
        ownerEmail,
        discountPercent,
        bookingApplicationFeeAmount,
        lat,
        lng,
        paymentCurrency,
        currency,
        incomePassword,
        reviewUrl,
        taxNumber,
        taxRate,
        merchantMap,
        file,
        createdMerchant,
        isCreateMerchant,
        phone,
        password,
        dispatch,
        address,
        permissions,
        contactEmail,
        applicationFee,
        caid,
        selectedSlotInterval,
        timezone,
        cancelBefore,
        bookingBefore,
        slug,
        title,
        displayPhone
    ]);

    const isValidCreateMerchant = React.useMemo(() => {
        return (
            !!address &&
            !!password &&
            password === confirmPassword &&
            !!confirmPassword &&
            !!slug &&
            !!address &&
            !!contactEmail
        );
    }, [address, password, confirmPassword, slug, contactEmail]);

    const isValidUploadMerchant = React.useMemo(() => {
        return !!createdMerchant && !!thumbnail;
    }, [createdMerchant, thumbnail]);

    const stopPropagation = (e) => {
        e.stopPropagation();
        e.preventDefault();
    };

    const handleCreateNewMerchant = React.useCallback(() => {
        setSelectedSlotInterval(MERCHANT_DEFAULT_OPTIONS.slotInterval);
        setPermissions(MERCHANT_DEFAULT_OPTIONS.permissions);
        setTimezone(MERCHANT_DEFAULT_OPTIONS.timezone);
        setCancelBefore(MERCHANT_DEFAULT_OPTIONS.bookingCancelBeforeMinutes);
        setBookingBefore(MERCHANT_DEFAULT_OPTIONS.bookingBeforeMinutes);
        setAddress('');
        setCaid('');
        setApplicationFee(null);
        setContactEmail('');
        setOwnerEmail('');
        setTitle('');
        setSlug('');
        setPhone('');
        setPassword('');
        setConfirmPassword('');

        setCurrentStep(STEPS.CREATE_MERCHANT);
    }, []);

    const content = React.useMemo(() => {
        switch (currentStep) {
            case STEPS.CREATE_MERCHANT:
                return (
                    <Grid container direction="row" spacing={3}>
                        <Grid item className={classes.options}>
                            <Grid container direction="column" spacing={2}>
                                <Grid item>
                                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                                        <Grid item xs={4}>
                                            <Typography variant="body2">Phone</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <PhoneInput
                                                placeholder={'Phone'}
                                                disabled={isLoading}
                                                onChange={(phone) => setPhone(phone)}
                                                defaultValue={addPlus(phone)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                                        <Grid item xs={4}>
                                            <Typography variant="body2">Display Phone</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputBase
                                                fullWidth
                                                className={`${classes.input}`}
                                                placeholder={'+1 (234) 456-7899'}
                                                name="displayPhone"
                                                value={displayPhone || ''}
                                                onChange={(e) => setDisplayPhone(e?.target?.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                                        <Grid item xs={4}>
                                            <Typography variant="body2">Contact Email:</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputBase
                                                fullWidth
                                                className={`${classes.input}`}
                                                placeholder={'Email'}
                                                name="contactEmail"
                                                value={contactEmail || ''}
                                                onChange={(e) => setContactEmail(e?.target?.value)}
                                                error={!contactEmail}
                                                errorText={'Contact email cannot be empty'}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                                        <Grid item xs={4}>
                                            <Typography variant="body2">Owner Email:</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputBase
                                                fullWidth
                                                className={`${classes.input}`}
                                                placeholder={'Email'}
                                                name="ownerEmail"
                                                value={ownerEmail || ''}
                                                onChange={(e) => setOwnerEmail(e?.target?.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                                        <Grid item xs={4}>
                                            <Typography variant="body2">Password</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputBase
                                                fullWidth
                                                className={`${classes.input}`}
                                                placeholder={'password'}
                                                name="password"
                                                value={password || ''}
                                                onChange={(e) => setPassword(e?.target?.value)}
                                                error={!password || confirmPassword !== password}
                                                errorText={'Password cannot be empty or not same confirm password'}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                                        <Grid item xs={4}>
                                            <Typography variant="body2">Confirm password</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputBase
                                                fullWidth
                                                className={`${classes.input}`}
                                                placeholder={'Confirm Password'}
                                                name="confirmPassword"
                                                value={confirmPassword || ''}
                                                onChange={(e) => setConfirmPassword(e?.target?.value)}
                                                error={!confirmPassword || confirmPassword !== password}
                                                errorText={'Confirm password cannot be empty or not same password'}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                                        <Grid item xs={4}>
                                            <Typography variant="body2">Merchant Title</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputBase
                                                fullWidth
                                                className={`${classes.input}`}
                                                placeholder={'Merchant title'}
                                                name="title"
                                                value={title || ''}
                                                onChange={(e) => setTitle(e?.target?.value)}
                                                error={!title}
                                                errorText={'Merchant title cannot be empty'}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                                        <Grid item xs={4}>
                                            <Typography variant="body2">Merchant slug</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputBase
                                                fullWidth
                                                className={`${classes.input}`}
                                                placeholder={'Merchant slug'}
                                                name="slug"
                                                value={slug || ''}
                                                onChange={(e) => setSlug(e?.target?.value)}
                                                error={!slug}
                                                errorText={'Merchant slug cannot be empty'}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                                        <Grid item xs={4}>
                                            <Typography variant="body2">Address</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputBase
                                                fullWidth
                                                className={`${classes.input}`}
                                                placeholder={'Merchant address'}
                                                name="address"
                                                value={address || ''}
                                                onChange={(e) => setAddress(e?.target?.value)}
                                                error={!address}
                                                errorText={'Address cannot be empty'}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                                        <Grid item xs={4}>
                                            <Typography variant="body2">Permissions: </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ReactSelect
                                                onChange={handlePermissionChange}
                                                value={permissions}
                                                options={permissionOptions}
                                                placeholder={`Choose permission option`}
                                                isMulti
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                                        <Grid item xs={4}>
                                            <Typography variant="body2">Slot Interval:</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <NativeSelect
                                                onChange={handleSlotIntervalChange}
                                                selectedValue={selectedSlotInterval}
                                                options={slotIntervalOptions}
                                                placeholder={`Choose slot interval option`}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                                        <Grid item xs={4}>
                                            <Typography variant="body2">Allow Cancel Before:</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <NativeSelect
                                                onChange={handleCancelBeforeChange}
                                                selectedValue={cancelBefore}
                                                options={cancelBeforeBookingMinutesOptions}
                                                placeholder={`Choose time`}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                                        <Grid item xs={4}>
                                            <Typography variant="body2">Allow Booking Before:</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <NativeSelect
                                                onChange={handleBookingBeforeChange}
                                                selectedValue={bookingBefore}
                                                options={beforeBookingMinutesOptions}
                                                placeholder={`Choose time`}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                                        <Grid item xs={4}>
                                            <Typography variant="body2">Time zone:</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <NativeSelect
                                                onChange={handleTimezoneChange}
                                                selectedValue={timezone}
                                                options={timezoneOptions}
                                                placeholder={`Choose timezone`}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                                        <Grid item xs={4}>
                                            <Typography variant="body2">Stripe CAID:</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputBase
                                                fullWidth
                                                className={`${classes.input}`}
                                                placeholder={'Stripe Caid'}
                                                name="caid"
                                                value={caid || ''}
                                                onChange={(e) => setCaid(e?.target?.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                                        <Grid item xs={4}>
                                            <Typography variant="body2">Stripe Gift Application Fee:</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <NativeSelect
                                                onChange={handleGiftApplicationFeeChange}
                                                selectedValue={applicationFee}
                                                options={applicationFeeOptions}
                                                placeholder={`Choose option`}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                                        <Grid item xs={4}>
                                            <Typography variant="body2">Stripe Booking Application Fee:</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <NativeSelect
                                                onChange={handleBookingApplicationFeeChange}
                                                selectedValue={bookingApplicationFeeAmount}
                                                options={bookingApplicationFeeOptions}
                                                placeholder={`Choose option`}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                                        <Grid item xs={4}>
                                            <Typography variant="body2">Payment Currency: </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ReactSelect
                                                onChange={handlePaymentCurrencyChange}
                                                value={paymentCurrency}
                                                options={paymentCurrencyOptions}
                                                placeholder={`Choose payment currency option`}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                                        <Grid item xs={4}>
                                            <Typography variant="body2">Review Url:</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputBase
                                                fullWidth
                                                className={`${classes.input}`}
                                                placeholder={'Review Url'}
                                                name="reviewUrl"
                                                value={reviewUrl || ''}
                                                onChange={(e) => setReviewUrl(e?.target?.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                                        <Grid item xs={4}>
                                            <Typography variant="body2">Tax Rate:</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputBase
                                                fullWidth
                                                className={`${classes.input}`}
                                                placeholder={'Tax Rate'}
                                                name="taxRate"
                                                value={taxRate || ''}
                                                onChange={(e) => setTaxRate(e?.target?.value)}
                                                type="number"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                                        <Grid item xs={4}>
                                            <Typography variant="body2">Discount Percent</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputBase
                                                fullWidth
                                                className={`${classes.input}`}
                                                placeholder={'Discount percent'}
                                                name="discountPercent"
                                                value={discountPercent || ''}
                                                onChange={(e) => setDiscountPercent(e?.target?.value)}
                                                type="number"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                                        <Grid item xs={4}>
                                            <Typography variant="body2">Currency:</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputBase
                                                fullWidth
                                                className={`${classes.input}`}
                                                placeholder={'Currency'}
                                                name="currency"
                                                value={currency}
                                                onChange={(e) => setCurrency(e?.target?.value)}
                                                type="text"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                                        <Grid item xs={4}>
                                            <Typography variant="body2">Tax Number:</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputBase
                                                fullWidth
                                                className={`${classes.input}`}
                                                placeholder={'Tax Number'}
                                                name="taxNumber"
                                                value={taxNumber || ''}
                                                onChange={(e) => setTaxNumber(e?.target?.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                                        <Grid item xs={4}>
                                            <Typography variant="body2">Location embed map:</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputBase
                                                fullWidth
                                                className={`${classes.input}`}
                                                placeholder={'Location embed map'}
                                                name="merchantMap"
                                                value={merchantMap || ''}
                                                onChange={(e) => setMerchantMap(e?.target?.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                                        <Grid item xs={4}>
                                            <Typography variant="body2">Income password:</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputBase
                                                fullWidth
                                                className={`${classes.input}`}
                                                placeholder={'Income password'}
                                                name="incomePassword"
                                                value={incomePassword || ''}
                                                onChange={(e) => setIncomePassword(e?.target?.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                                        <Grid item xs={4}>
                                            <Typography variant="body2">Lat position</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputBase
                                                fullWidth
                                                className={`${classes.input}`}
                                                placeholder={'lat position'}
                                                name="lat"
                                                value={lat || ''}
                                                onChange={(e) => setLat(e?.target?.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                                        <Grid item xs={4}>
                                            <Typography variant="body2">Lng position</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputBase
                                                fullWidth
                                                className={`${classes.input}`}
                                                placeholder={'lng position'}
                                                name="lng"
                                                value={lng || ''}
                                                onChange={(e) => setLng(e?.target?.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {currentStep === STEPS.UPLOAD_MERCHANT_LOGO && (
                            <Grid item className={classes.upload}>
                                <Grid container direction="column" alignItems="center" spacing={2}>
                                    <Grid item className={classes.media}>
                                        <Avatar src={thumbnail} className={classes.image}>
                                            <div onClick={stopPropagation}>
                                                <div className={classes.fullWidthHeight} {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    <IconCameraSVG />
                                                </div>
                                            </div>
                                        </Avatar>
                                        {thumbnail && (
                                            <div onClick={stopPropagation} className={'alreadyHaveImage'}>
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    <IconCameraSVG />
                                                </div>
                                            </div>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                );

            case STEPS.UPLOAD_MERCHANT_LOGO:
                return (
                    <Grid container direction="row" spacing={3}>
                        <Grid item className={classes.options}>
                            <Grid container direction="column" spacing={2}>
                                <Grid item>
                                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                                        <Grid item xs={4}>
                                            <Typography variant="body2">Phone</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body2">{createdMerchant?.phone}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                                        <Grid item xs={4}>
                                            <Typography variant="body2">Contact Email:</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body2">
                                                {createdMerchant?.contactEmail || 'Not fill yet'}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                                        <Grid item xs={4}>
                                            <Typography variant="body2">Owner Email:</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body2">
                                                {createdMerchant?.ownerEmail || 'Not fill yet'}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                                        <Grid item xs={4}>
                                            <Typography variant="body2">Merchant Title</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body2">
                                                {createdMerchant?.title || 'Not fill yet'}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                                        <Grid item xs={4}>
                                            <Typography variant="body2">Merchant slug</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body2">
                                                {createdMerchant?.siteName || 'Not fill yet'}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                                        <Grid item xs={4}>
                                            <Typography variant="body2">Address</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body2">
                                                {createdMerchant?.address || 'Not fill yet'}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                                        <Grid item xs={4}>
                                            <Typography variant="body2">Permissions: </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body2">
                                                {createdMerchant?.permissions?.length
                                                    ? createdMerchant?.permissions?.join(', ')
                                                    : 'Have no permission'}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                                        <Grid item xs={4}>
                                            <Typography variant="body2">Slot Interval:</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body2">
                                                {createdMerchant?.slotInterval} mins
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                                        <Grid item xs={4}>
                                            <Typography variant="body2">Allow Cancel Before:</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body2">
                                                {createdMerchant?.bookingCancelBeforeMinutes} mins
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                                        <Grid item xs={4}>
                                            <Typography variant="body2">Allow Booking Before:</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body2">
                                                {createdMerchant?.bookingBeforeMinutes} mins
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                                        <Grid item xs={4}>
                                            <Typography variant="body2">Time zone:</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body2">
                                                {createdMerchant?.timezone || MERCHANT_DEFAULT_OPTIONS.timezone}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                                        <Grid item xs={4}>
                                            <Typography variant="body2">Stripe CAID:</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid item xs={12}>
                                                <Typography variant="body2">
                                                    {createdMerchant?.caid || 'Not fill yet'}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                                        <Grid item xs={4}>
                                            <Typography variant="body2">Stripe Gift Application Fee:</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid item xs={12}>
                                                <Typography variant="body2">
                                                    $
                                                    {createdMerchant?.applicationFeeAmount < 100
                                                        ? 0
                                                        : createdMerchant?.applicationFeeAmount / 100}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                                        <Grid item xs={4}>
                                            <Typography variant="body2">Stripe Booking Application Fee:</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid item xs={12}>
                                                <Typography variant="body2">
                                                    $
                                                    {createdMerchant?.bookingApplicationFeeAmount < 100
                                                        ? 0
                                                        : createdMerchant?.bookingApplicationFeeAmount / 100}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                                        <Grid item xs={4}>
                                            <Typography variant="body2">Payment Currency</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid item xs={12}>
                                                <Typography variant="body2">
                                                    {createdMerchant?.paymentCurrency || 'Not fill yet'}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                                        <Grid item xs={4}>
                                            <Typography variant="body2">Tax Number:</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid item xs={12}>
                                                <Typography variant="body2">
                                                    {createdMerchant?.taxNumber || 'Not fill yet'}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                                        <Grid item xs={4}>
                                            <Typography variant="body2">Currency:</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid item xs={12}>
                                                <Typography variant="body2">
                                                    {createdMerchant?.currency || 'Not fill yet'}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                                        <Grid item xs={4}>
                                            <Typography variant="body2">Tax Rate:</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid item xs={12}>
                                                <Typography variant="body2">{createdMerchant?.taxRate || 0}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                                        <Grid item xs={4}>
                                            <Typography variant="body2">Discount Percent:</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid item xs={12}>
                                                <Typography variant="body2">
                                                    {createdMerchant?.discountPercent || 0}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                                        <Grid item xs={4}>
                                            <Typography variant="body2">Review URL:</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid item xs={12}>
                                                <Typography variant="body2">
                                                    {createdMerchant?.reviewUrl || 'Not fill yet'}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                                        <Grid item xs={4}>
                                            <Typography variant="body2">Location embed map:</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid item xs={12}>
                                                <Typography variant="body2">
                                                    {createdMerchant?.merchantMap || 'Not fill yet'}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                                        <Grid item xs={4}>
                                            <Typography variant="body2">Income Password:</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid item xs={12}>
                                                <Typography variant="body2">
                                                    {createdMerchant?.incomePassword || 'Not fill yet'}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                                        <Grid item xs={4}>
                                            <Typography variant="body2">Lat:</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid item xs={12}>
                                                <Typography variant="body2">
                                                    {createdMerchant?.lat || 'Not fill yet'}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                                        <Grid item xs={4}>
                                            <Typography variant="body2">Lng:</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid item xs={12}>
                                                <Typography variant="body2">
                                                    {createdMerchant?.lng || 'Not fill yet'}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {!isCreateMerchant && (
                            <Grid item className={classes.upload}>
                                <Grid container direction="column" alignItems="center" spacing={2}>
                                    <Grid item className={classes.media}>
                                        <Avatar src={thumbnail} className={classes.image}>
                                            <div onClick={stopPropagation}>
                                                <div className={classes.fullWidthHeight} {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    <IconCameraSVG />
                                                </div>
                                            </div>
                                        </Avatar>
                                        {thumbnail && (
                                            <div onClick={stopPropagation} className={'alreadyHaveImage'}>
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    <IconCameraSVG />
                                                </div>
                                            </div>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                );

            case STEPS.CREATE_MERCHANT_SUCCESS:
                return (
                    <Grid
                        container
                        alignItems="center"
                        direction="column"
                        spacing={2}
                        justifyContent="center"
                        className={classes.success}
                        wrap="nowrap"
                    >
                        <Grid item>
                            <SuccessIllustratorSVG />
                        </Grid>
                        <Grid item>
                            <Typography variant="body1">Merchant Created Success</Typography>
                        </Grid>
                        <Grid item>
                            <ButtonBase variant="contained" onClick={handleCreateNewMerchant}>
                                Create New Merchant
                            </ButtonBase>
                        </Grid>
                    </Grid>
                );
            default:
                return null;
        }
    }, [
        ownerEmail,
        discountPercent,
        lat,
        lng,
        displayPhone,
        paymentCurrency,
        handlePaymentCurrencyChange,
        paymentCurrencyOptions,
        merchantMap,
        taxRate,
        taxNumber,
        reviewUrl,
        handleCreateNewMerchant,
        isCreateMerchant,
        createdMerchant,
        address,
        applicationFee,
        applicationFeeOptions,
        bookingApplicationFeeOptions,
        caid,
        beforeBookingMinutesOptions,
        bookingBefore,
        cancelBefore,
        classes,
        confirmPassword,
        cancelBeforeBookingMinutesOptions,
        handleBookingApplicationFeeChange,
        currentStep,
        getRootProps,
        contactEmail,
        getInputProps,
        handleGiftApplicationFeeChange,
        bookingApplicationFeeAmount,
        handleBookingBeforeChange,
        handlePermissionChange,
        handleSlotIntervalChange,
        handleCancelBeforeChange,
        permissions,
        password,
        phone,
        handleTimezoneChange,
        isLoading,
        permissionOptions,
        selectedSlotInterval,
        slotIntervalOptions,
        slug,
        thumbnail,
        timezone,
        title,
        timezoneOptions,
        incomePassword,
        currency
    ]);

    return (
        <Grid container direction="column" wrap="nowrap" spacing={2} className={classes.root}>
            {currentStep !== STEPS.CREATE_MERCHANT_SUCCESS && (
                <Grid item>
                    <Grid container direction="row" justifyContent="flex-end">
                        <Grid item className={classes.btnSubmit}>
                            <ButtonBase
                                disabled={isCreateMerchant ? !isValidCreateMerchant : !isValidUploadMerchant}
                                onClick={handleSave}
                                variant="contained"
                                color="primary"
                            >
                                {isLoading ? (
                                    <CircularProgress size={20} className={classes.buttonProgress} />
                                ) : isCreateMerchant ? (
                                    'Create'
                                ) : (
                                    'Upload'
                                )}
                            </ButtonBase>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <Grid item>{content}</Grid>
        </Grid>
    );
}

export default Setting;
