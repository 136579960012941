import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const StyledButton = withStyles((theme) => ({
    root: {
        borderRadius: 15,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        textTransform: 'inherit'
    }
}))(Button);

export default StyledButton;
