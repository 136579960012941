export const objectToQueryParams = (dataObj) => {
    const params = [];

    for (let propItem in dataObj) {
        if (dataObj[propItem] !== undefined && dataObj[propItem] !== '') {
            params.push(encodeURIComponent(propItem) + '=' + encodeURIComponent(dataObj[propItem]));
        }
    }

    return params.join('&');
};

export const objectToQueryParamsSearch = (dataObj) => {
    const params = [];

    for (let propItem in dataObj) {
        if (dataObj[propItem] !== undefined && dataObj[propItem] !== '') {
            params.push(`${encodeURIComponent(propItem)}[$like]=%${encodeURIComponent(dataObj[propItem])}%`);
        }
    }

    return params.join('&');
};

export const objectToQueryPagination = (dataObj) => {
    const params = [];
    for (let propItem in dataObj) {
        if (dataObj[propItem] !== undefined && dataObj[propItem] !== '') {
            params.push(`$${encodeURIComponent(propItem)}=${encodeURIComponent(dataObj[propItem])}`);
        }
    }
    return params.join('&');
};

export const objectToQuerySort = (dataObj) => {
    const params = [];
    for (let propItem in dataObj) {
        if (dataObj[propItem] !== undefined && dataObj[propItem] !== '') {
            params.push(`$sort[${encodeURIComponent(propItem)}]=${encodeURIComponent(dataObj[propItem])}`);
        }
    }
    return params.join('&');
};

export const combineQueryParams = (array) => {
    const params = array.filter((item) => item !== undefined && item !== '');
    return params.join('&');
};

export function encodeURL(params) {
    let type = typeof params;
    if (type === 'string') {
        return encode(params);
    } else if (type === 'object') {
        return encode(JSON.stringify(params));
    } else {
        return null;
    }
}

function encode(string) {
    let query = new URLSearchParams(string).toString();
    /**
     * TODO: improve this to support case &&&
     */
    query = query?.replace(new RegExp(`=&`, 'g'), encodeURIComponent('&'));
    if (query?.endsWith('=')) {
        query = query.slice(0, query?.length - 1);
    }
    return query;
}

export function formatQueriesConditions(searchCondition) {
    let query = {};
    const properties = Object.keys(searchCondition);

    console.log('searchCondition', searchCondition);
    for (const property of properties) {
        const queryDetail = searchCondition[property];

        if (queryDetail?.value) {
            query[property] = {
                [queryDetail.operator]: queryDetail?.value
            };
        }
    }

    return query;
}
