import React from 'react';
import { useDispatch } from 'react-redux';
import { ROOMS, TOPICS, REALTIME_OBJECT_TYPES, TOKEN_NAME } from 'const';
import { API_URL } from '../../config';
import { io } from 'socket.io-client';
import { useUserMerchantId, useIsHavingInternalPermission } from 'hooks/auth';
import { getItem } from 'utils/localStorage';
import * as calendarActions from 'views/calender/actions';
import ClientArrived from './UI';

function Calender() {
    const dispatch = useDispatch();
    const merchantId = useUserMerchantId();
    const token = getItem(TOKEN_NAME);
    const isHavingInternalPermission = useIsHavingInternalPermission();

    const handleRealtime = React.useCallback(
        (body) => {
            const type = body?.type;

            if (type === REALTIME_OBJECT_TYPES.Booking) {
                return dispatch(calendarActions.bookingHandler(body));
            }
        },
        [dispatch]
    );

    React.useEffect(() => {
        if (!isHavingInternalPermission) return;
        const bookingSocket = io(`${API_URL}/app`, {
            transports: ['websocket'],
            autoConnect: true,
            reconnectionDelay: 2000,
            query: {
                token
            }
        });
        bookingSocket.on('connect', () => {
            bookingSocket.emit(ROOMS.JOIN_ROOM, TOPICS.booking(merchantId));
        });

        bookingSocket.on(ROOMS.JOINED_ROOM, (room) => {
            console.log('JOINED_ROOM', room);
        });

        bookingSocket.on(ROOMS.MODIFIED, (body) => {
            handleRealtime(body);
        });

        bookingSocket.on(ROOMS.LEAVED_ROOM, (body) => {
            console.log('data LEAVED_ROOM', body);
        });

        return () => {
            bookingSocket.emit(ROOMS.LEAVE_ROOM, TOPICS.booking(merchantId));
        };
    }, [merchantId, token, isHavingInternalPermission, handleRealtime]);

    return <ClientArrived />;
}

export default React.memo(Calender);
