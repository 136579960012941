import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ButtonBase from 'components/button/Base';
import { Grid, Typography } from '@material-ui/core';
import useMediaQueries from 'hooks/useMediaQueries';
import { getCurrentFriendlyTime, convertStartTimeToTime12 } from 'utils/timing';
import DialogActions from 'components/dialog/Action';
import DialogTitle from 'components/dialog/Title';
import DialogContent from 'components/dialog/Content';
import groupBy from 'lodash/groupBy';
import { orderBy, sortBy } from 'lodash';
import { useMerchantDetail } from 'hooks/merchant';

const useStyles = makeStyles((theme) => ({
    title: {
        paddingRight: 0,
        paddingLeft: 0
    },
    content: {
        minWidth: 500,
        [theme.breakpoints.down('sm')]: {
            minWidth: 'auto'
        }
    },
    bookingDetail: {
        padding: theme.spacing(2),
        width: '100%',
        borderBottom: `1px solid ${theme.colors.border}`
    },
    highlight: {
        color: theme.colors.template
    },
    whiteColor: {
        color: theme.palette.common.white
    }
}));

function StaffStatisticDetail({ onClose, selectedStaff }) {
    const classes = useStyles();
    const theme = useTheme();
    const { isMobile } = useMediaQueries();
    const merchant = useMerchantDetail();

    const tickets = React.useMemo(() => {
        return selectedStaff?.tickets || [];
    }, [selectedStaff]);

    const ticketByBookingId = React.useMemo(() => {
        return groupBy(tickets, 'bookingId');
    }, [tickets]);

    const hasNoTickets = React.useMemo(() => {
        return tickets?.length === 0;
    }, [tickets]);

    const ticketByIdSortedByStartDate = React.useMemo(() => {
        const formattedTickets = Object.keys(ticketByBookingId)?.map((bookingId) => {
            return {
                startDate: ticketByBookingId?.[bookingId]?.[0]?.startDate,
                tickets: ticketByBookingId?.[bookingId],
                bookingId
            };
        });

        return sortBy(formattedTickets, ['startDate'], ['asc']);
    }, [ticketByBookingId]);

    return (
        <>
            <DialogTitle>
                <Typography className={classes.whiteColor} variant="h5" component="div">
                    Tickets Detail
                </Typography>
            </DialogTitle>
            <DialogContent className={classes.content}>
                <Grid container direction="column" alignItems="center" justifyContent="center">
                    {hasNoTickets ? (
                        <Grid item style={{ marginTop: theme.spacing(3) }}>
                            <Typography variant="body2">No tickets found</Typography>
                        </Grid>
                    ) : (
                        ticketByIdSortedByStartDate?.map((booking) => {
                            const tickets = booking?.tickets;
                            const sortedTickets = orderBy(tickets, ['startTime'], ['asc']);

                            const clientName = sortedTickets?.[0]?.client;
                            const startDate = sortedTickets?.[0]?.startDate;

                            const total = sortedTickets?.reduce((t, ticket) => t + (ticket?.price || 0), 0);

                            return (
                                <Grid className={classes.bookingDetail} item key={booking?.bookingId}>
                                    <Grid container direction="column">
                                        <Grid item style={{ marginBottom: theme.spacing(3) }}>
                                            <Grid
                                                container
                                                direction="row"
                                                alignItems="center"
                                                justifyContent="space-between"
                                            >
                                                <Grid item>
                                                    <Typography
                                                        className={classes.highlight}
                                                        variant="body1"
                                                        display="inline"
                                                    >
                                                        {getCurrentFriendlyTime(startDate, merchant?.timezone)}{' '}
                                                    </Typography>
                                                    <Typography variant="body2" display="inline">
                                                        ({sortedTickets?.length} tickets)
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body2" display="inline">
                                                        Total:{' '}
                                                    </Typography>
                                                    <Typography
                                                        className={classes.highlight}
                                                        style={{
                                                            fontSize: 16
                                                        }}
                                                        variant="body1"
                                                        display="inline"
                                                    >
                                                        {merchant?.currency}
                                                        {total}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item container direction="column">
                                            {sortedTickets?.map((ticket) => {
                                                return (
                                                    <Grid
                                                        key={ticket?.id}
                                                        style={{ marginBottom: theme.spacing(2) }}
                                                        container
                                                        alignItems="flex-start"
                                                        justifyContent="space-between"
                                                    >
                                                        <Grid item>
                                                            <Grid container direction="column" spacing={1}>
                                                                <Grid item>
                                                                    <Typography variant="body1">
                                                                        {ticket?.service}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography variant="body2">
                                                                        Begin at:{' '}
                                                                        {convertStartTimeToTime12(ticket?.startTime)}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography variant="body2" display="inline">
                                                                        Client: <strong>{clientName}</strong>
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="body2" display="inline">
                                                                Price:{' '}
                                                                <strong>
                                                                    {merchant?.currency}
                                                                    {ticket?.price}
                                                                </strong>
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            );
                        })
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container wrap="nowrap" alignItems="center" justifyContent="flex-end">
                    <Grid item>
                        <ButtonBase onClick={onClose} fullWidth={isMobile} variant="outlined">
                            Close
                        </ButtonBase>
                    </Grid>
                </Grid>
            </DialogActions>
        </>
    );
}

export default React.memo(StaffStatisticDetail);
