import * as types from './types';
import produce from 'immer';
import { setItem, getItem } from 'utils/localStorage';
import { BOOKING_CHECKOUT_STATES, FILTER_STAFF, ZOOM_SETTING } from 'const';
import { convertTimeToFloat, getCurrentDate, getTotalMinutesByTime, isSameUtcDate } from 'utils/timing';

const zoomStorage = Number(getItem(ZOOM_SETTING));
const defaultFilteredStaff = getItem(FILTER_STAFF);

const INITIAL_STATE = {
    bookings: null,
    isFetchingBookings: false,
    isInitializing: false,
    staffWorkingHours: {},
    blockedTimes: {},
    zoom: zoomStorage ? (zoomStorage > 3 || zoomStorage < 0 ? 0 : zoomStorage) : 0,
    ROW_HEIGHT: 36,
    isFirstLoaded: false,
    toggleCloseDate: false,
    selectedDate: getCurrentDate(),
    originalStaffSchedule: {},
    giftCodes: [],
    isNewCheckIn: '',
    isOpenClientArrived: false,
    isOpenCheckout: false,
    bookingLogs: null,
    filteredStaff: defaultFilteredStaff || 0,
    findBookingId: null,
    isOpenBooking: false,
    isDragging: false,
    dragBkSv: null,
    scheduleType: null,
    cloneBooking: null,
    cloneStaff: null,
    cloneTime: null
};

export default function Service(state = INITIAL_STATE, { type, payload }) {
    switch (type) {
        case types.INITIAL_CALENDAR: {
            return {
                ...state,
                isInitializing: true
            };
        }

        case types.INITIAL_CALENDAR_FAILED: {
            return {
                ...state,
                isInitializing: false
            };
        }

        case types.INITIAL_CALENDAR_SUCCESS: {
            return {
                ...state,
                isInitializing: false,
                isFirstLoaded: true
            };
        }

        case types.FETCH_STAFF_BOOKINGS_BY_DATE: {
            return {
                ...state,
                isFetchingBookings: true
            };
        }

        case types.FETCH_STAFF_BOOKINGS_BY_DATE_FAILED: {
            return {
                ...state,
                isFetchingBookings: false
            };
        }

        case types.FETCH_STAFF_BOOKINGS_BY_DATE_SUCCESS: {
            const { bookings } = payload;
            return {
                ...state,
                bookings
            };
        }

        case types.FETCH_STAFF_BLOCKED_TIMES_BY_DATE_SUCCESS: {
            const { blockedTimes } = payload;
            return {
                ...state,
                blockedTimes
            };
        }

        case types.FETCH_STAFF_WORKING_HOURS_BY_DATE_SUCCESS: {
            const { staffWorkingHours, originalStaffSchedule } = payload;
            return {
                ...state,
                staffWorkingHours,
                originalStaffSchedule
            };
        }

        case types.ADD_BOOKING_SUCCESS: {
            const { bookingServices } = payload;
            const { bookings } = state;

            const newBookings = produce(bookings, (draft) => {
                const staffIds = Object.keys(bookingServices);

                for (let staffId of staffIds) {
                    if (!draft?.[staffId]) {
                        draft[staffId] = [];
                    }

                    const oldBookingServicesOfStaffId = draft?.[staffId] || [];
                    draft[staffId] = [...oldBookingServicesOfStaffId, ...bookingServices?.[staffId]];
                }

                return draft;
            });

            return {
                ...state,
                bookings: newBookings
            };
        }

        case types.UPDATE_BOOKING_SUCCESS: {
            const { bookingServices } = payload;
            const { bookings } = state;

            const newBookings = produce(bookings, (draft) => {
                const staffIds = Object.keys(bookingServices);

                for (let staffId of staffIds) {
                    if (!draft?.[staffId]) {
                        draft[staffId] = [];
                    }

                    const bookingServiceByStaffId = bookingServices?.[staffId] || [];

                    let oldBookingServicesOfStaffId = draft?.[staffId] || [];
                    const oldBkServiceIds = oldBookingServicesOfStaffId?.map((bkSv) => +bkSv?.id);

                    let newBookingServicesByStaffId = [...oldBookingServicesOfStaffId];

                    for (let bkSv of bookingServiceByStaffId) {
                        const serviceId = +bkSv?.id;

                        if (oldBkServiceIds?.includes(serviceId)) {
                            newBookingServicesByStaffId = newBookingServicesByStaffId?.map((oldBkSv) => {
                                if (+oldBkSv?.id === serviceId) {
                                    return bkSv;
                                }
                                return oldBkSv;
                            });
                        } else {
                            newBookingServicesByStaffId = [...newBookingServicesByStaffId, bkSv];
                        }
                    }

                    draft[staffId] = newBookingServicesByStaffId;
                }

                return draft;
            });

            return {
                ...state,
                bookings: newBookings
            };
        }

        case types.DELETE_STAFFS_BOOKING_SERVICES: {
            const { staffBkSvs } = payload;
            const { bookings } = state;

            const newBookings = produce(bookings, (draft) => {
                const staffIds = Object.keys(staffBkSvs);

                for (const staffId of staffIds) {
                    if (!draft?.[staffId]) {
                        draft[staffId] = [];
                    }

                    const deletedBkServiceIds = staffBkSvs?.[staffId];

                    const staffBookingServices = draft?.[staffId] || [];

                    draft[staffId] = staffBookingServices?.filter(
                        (bkService) => !deletedBkServiceIds?.includes(+bkService?.id)
                    );
                }

                return draft;
            });

            return {
                ...state,
                bookings: newBookings
            };
        }

        case types.CANCEL_BOOKING_SUCCESS: {
            const { bookingServices } = payload;
            const { bookings } = state;

            const newBookings = produce(bookings, (draft) => {
                const staffIds = Object.keys(bookingServices);

                for (let staffId of staffIds) {
                    if (!draft?.[staffId]) {
                        draft[staffId] = [];
                    }

                    const bookingServiceByStaffId = bookingServices?.[staffId] || [];

                    let oldBookingServicesOfStaffId = draft?.[staffId] || [];
                    let newBookingServicesByStaffId = [...oldBookingServicesOfStaffId];

                    for (let cancelBkSv of bookingServiceByStaffId) {
                        newBookingServicesByStaffId = newBookingServicesByStaffId?.filter(
                            (bkSv) => +bkSv?.id !== +cancelBkSv?.id
                        );
                    }

                    draft[staffId] = newBookingServicesByStaffId;
                }

                return draft;
            });

            return {
                ...state,
                bookings: newBookings
            };
        }

        case types.DELETE_BOOKING_SERVICE: {
            const { bookingId, serviceId, staffId } = payload;
            const { bookings } = state;

            const newBookings = produce(bookings, (draft) => {
                if (!draft?.[staffId]) {
                    draft[staffId] = [];
                }
                draft[staffId] = draft[staffId]?.map((booking) => {
                    if (booking.serviceId === serviceId && booking.bookingId === bookingId) {
                        booking.isDeleted = true;
                    }
                    return booking;
                });
            });

            return {
                ...state,
                bookings: newBookings
            };
        }

        case types.DELETE_BOOKING_SERVICE_FAILED: {
            const { staffId, bookingId, serviceId } = payload;
            const { bookings } = state;

            const newBookings = produce(bookings, (draft) => {
                if (!draft?.[staffId]) {
                    draft[staffId] = [];
                }
                draft[staffId] = draft[staffId]?.map((booking) => {
                    if (booking.serviceId === serviceId && booking.bookingId === bookingId) {
                        booking.isDeleted = false;
                    }
                    return booking;
                });
            });

            return {
                ...state,
                bookings: newBookings
            };
        }

        case types.DELETE_BOOKING_SERVICE_SUCCESS: {
            const { staffId, bookingId, serviceId } = payload;
            const { bookings } = state;

            const newBookings = produce(bookings, (draft) => {
                if (!draft?.[staffId]) {
                    draft[staffId] = [];
                }
                draft[staffId] = draft[staffId]?.filter(
                    (booking) => !(booking.serviceId === serviceId && booking.bookingId === bookingId)
                );
            });

            return {
                ...state,
                bookings: newBookings
            };
        }

        case types.UPDATE_BOOKING_BY_RESIZE: {
            const { staffId, duration, orderServiceId, bkSvIds } = payload;
            const { bookings } = state;

            const newBookings = produce(bookings, (draft) => {
                if (!draft?.[staffId]) {
                    draft[staffId] = [];
                }

                draft[staffId] = draft?.[staffId]?.map((order) => {
                    if (bkSvIds?.includes(+order?.id)) {
                        if (+order?.id === +orderServiceId) {
                            order.duration = duration;
                            order.booking = {
                                ...order.booking,
                                bookingServices: order?.booking?.bookingServices?.map((sv) => {
                                    if (+sv?.id === +orderServiceId) {
                                        sv.duration = duration;
                                    }
                                    return sv;
                                })
                            };
                        } else {
                            order.booking = {
                                ...order.booking,
                                bookingServices: order?.booking?.bookingServices?.map((sv) => {
                                    if (+sv?.id === +orderServiceId) {
                                        sv.duration = duration;
                                    }
                                    return sv;
                                })
                            };
                        }
                    }

                    return order;
                });

                return draft;
            });

            return {
                ...state,
                bookings: newBookings
            };
        }

        case types.UPDATE_ZOOM_SETTING: {
            const { zoom } = payload;
            setItem(ZOOM_SETTING, zoom);
            return {
                ...state,
                zoom
            };
        }

        case types.UPDATE_BLOCKED_TIME_BY_RESIZE: {
            const { staffId, id, timeEnd } = payload;

            const { blockedTimes } = state;

            const newBlockedTimes = produce(blockedTimes, (draft) => {
                if (!draft?.[staffId]) {
                    draft[staffId] = [];
                }

                draft[staffId] = draft[staffId]?.map((blockedTime) => {
                    if (blockedTime?.id === id) {
                        blockedTime.timeEnd = convertTimeToFloat(timeEnd);

                        let start = getTotalMinutesByTime(blockedTime?.slot?.timeStart);
                        let end = getTotalMinutesByTime(timeEnd);
                        blockedTime.duration = Math.abs(end - start);
                        blockedTime.slot = {
                            timeStart: blockedTime?.slot?.timeStart,
                            timeEnd
                        };
                    }
                    return blockedTime;
                });
                return draft;
            });

            return {
                ...state,
                blockedTimes: newBlockedTimes
            };
        }

        case types.CREATE_NEW_BOOKING_SERVICE_WITH_EXISTED_BOOKING: {
            const { staffId, bookingService } = payload;
            const { bookings } = state;

            const newBookings = produce(bookings, (draft) => {
                if (!draft?.[staffId]) {
                    draft[staffId] = [];
                }
                const oldBookingServicesOfStaffId = draft?.[staffId] || [];
                if (oldBookingServicesOfStaffId?.findIndex((bkSv) => +bkSv?.id === +bookingService?.id) === -1) {
                    draft[staffId] = [...oldBookingServicesOfStaffId, bookingService];
                }

                return draft;
            });

            return {
                ...state,
                bookings: newBookings
            };
        }

        case types.DELETE_BOOKING_SERVICE_SOCKET: {
            const { staffId, bkSvId } = payload;
            const { bookings } = state;

            console.log('staffId', staffId);
            console.log('bkSvId', bkSvId);

            const newBookings = produce(bookings, (draft) => {
                if (!draft?.[staffId]) {
                    draft[staffId] = [];
                }
                const oldBookingServicesOfStaffId = draft?.[staffId] || [];
                draft[staffId] = oldBookingServicesOfStaffId?.filter((bkSv) => +bkSv?.id !== +bkSvId);
                return draft;
            });

            console.log('newBookings', newBookings);

            return {
                ...state,
                bookings: newBookings
            };
        }

        case types.UPDATE_BOOKING_SERVICE_SOCKET: {
            const { staffId, bookingService } = payload;
            const { bookings } = state;

            const newBookings = produce(bookings, (draft) => {
                if (!draft?.[staffId]) {
                    draft[staffId] = [];
                }
                const oldBookingServicesOfStaffId = draft?.[staffId] || [];

                if (isSameUtcDate(bookingService?.booking?.startDate, new Date())) {
                    //loop other staffs to delete that bookingService

                    const staffIds = Object.keys(bookings);

                    for (let bkSvStaffId of staffIds) {
                        if (!draft?.[bkSvStaffId]) {
                            draft[bkSvStaffId] = [];
                        }

                        const oldBookingServicesOfStaffId = draft?.[bkSvStaffId] || [];

                        if (+bkSvStaffId === +staffId) {
                            if (
                                oldBookingServicesOfStaffId?.findIndex((bkSv) => +bkSv?.id === +bookingService?.id) ===
                                -1
                            ) {
                                draft[bkSvStaffId] = [...oldBookingServicesOfStaffId, bookingService];
                            } else {
                                draft[bkSvStaffId] = oldBookingServicesOfStaffId?.map((bkSv) => {
                                    if (+bkSv?.id === +bookingService?.id) {
                                        return bookingService;
                                    }
                                    return bkSv;
                                });
                            }
                        } else {
                            draft[bkSvStaffId] = [...oldBookingServicesOfStaffId].filter(
                                (bkSv) => bkSv?.id !== +bookingService?.id
                            );
                        }
                    }

                    return draft;
                    //check and update booking service.
                } else {
                    //check startDate  if not same current date => move => delete
                    draft[staffId] = oldBookingServicesOfStaffId?.filter((bkSv) => +bkSv?.id !== +bookingService?.id);
                    return draft;
                }
            });

            return {
                ...state,
                bookings: newBookings
            };
        }

        case types.CREATE_BOOKING_SOCKET: {
            const { bookingServices } = payload;
            const { bookings } = state;

            const newBookings = produce(bookings, (draft) => {
                for (const bookingService of bookingServices) {
                    const staffId = +bookingService?.staffId;
                    if (!draft?.[staffId]) {
                        draft[staffId] = [];
                    }
                    const oldBookingServicesOfStaffId = draft?.[staffId] || [];
                    if (oldBookingServicesOfStaffId?.findIndex((bkSv) => +bkSv?.id === +bookingService?.id) === -1) {
                        draft[staffId] = [...oldBookingServicesOfStaffId, bookingService];
                    }
                }

                return draft;
            });

            return {
                ...state,
                bookings: newBookings
            };
        }

        case types.RESCHEDULE_BOOKING_SOCKET: {
            const { bookingServices, isSameDate } = payload;
            const { bookings } = state;
            const staffIds = Object.keys(bookings);

            const newBookings = produce(bookings, (draft) => {
                for (const bookingService of bookingServices) {
                    for (const staffId of staffIds) {
                        const oldBookingServicesOfStaffId = draft?.[staffId] || [];
                        draft[staffId] = oldBookingServicesOfStaffId?.filter(
                            (bkSv) => +bkSv?.id !== +bookingService?.id
                        );

                        if (isSameDate && +staffId === +bookingService?.staffId) {
                            draft[staffId] = [...draft[staffId], bookingService];
                        }
                    }
                }

                return draft;
            });

            return {
                ...state,
                bookings: newBookings
            };
        }

        case types.DELETE_BOOKING_SOCKET: {
            const { bookingServices } = payload;
            const { bookings } = state;
            const staffIds = Object.keys(bookings);

            const newBookings = produce(bookings, (draft) => {
                for (const bookingService of bookingServices) {
                    for (const staffId of staffIds) {
                        const oldBookingServicesOfStaffId = draft?.[staffId] || [];
                        draft[staffId] = oldBookingServicesOfStaffId?.filter(
                            (bkSv) => +bkSv?.id !== +bookingService?.id
                        );
                    }
                }

                return draft;
            });

            return {
                ...state,
                bookings: newBookings
            };
        }

        case types.UPDATE_BOOKING_SOCKET: {
            const { bookingServices, extra } = payload;
            const { bookings } = state;

            const isHavingExtra = extra?.updatedBkSvs?.length > 0 || extra?.removedBkSvs?.length > 0;

            let newBookings = {};

            console.log('isHavingExtra', isHavingExtra);

            if (!isHavingExtra) {
                newBookings = produce(bookings, (draft) => {
                    for (const bookingService of bookingServices) {
                        const staffId = +bookingService?.staffId;
                        if (!draft?.[staffId]) {
                            draft[staffId] = [];
                        }
                        const oldBookingServicesOfStaffId = draft?.[staffId] || [];
                        const staffBkSvIds = oldBookingServicesOfStaffId?.map((bkSv) => +bkSv?.id);

                        if (staffBkSvIds?.includes(+bookingService?.id)) {
                            draft[staffId] = oldBookingServicesOfStaffId?.map((bkSv) => {
                                if (+bkSv?.id === +bookingService?.id) {
                                    return bookingService;
                                }
                                return bkSv;
                            });
                        } else {
                            draft[staffId] = [...oldBookingServicesOfStaffId, bookingService];
                        }
                    }
                    return draft;
                });
            } else {
                const updatedBkSvs = extra?.updatedBkSvs;
                const removedBkSvs = extra?.removedBkSvs;

                // get all new created booking service
                const newBookingSvs = bookingServices?.filter((bkSv) => {
                    const updateBkSvIds = updatedBkSvs?.map((upBkSv) => +upBkSv?.id);
                    return !updateBkSvIds?.includes(+bkSv?.id);
                });

                //then correct updatedBookingServices
                const updatedBookingServices = bookingServices?.filter((bkSv) => {
                    const newBookingSvIds = newBookingSvs?.map((newBkSv) => +newBkSv?.id);
                    return !newBookingSvIds?.includes(+bkSv?.id);
                });

                const staffIds = Object.keys(bookings);

                newBookings = produce(bookings, (draft) => {
                    //remove deleted booking services -> verified success
                    for (const rmBkSv of removedBkSvs) {
                        const staffId = +rmBkSv?.staffId;
                        if (!draft?.[staffId]) {
                            draft[staffId] = [];
                        }
                        const oldBookingServicesOfStaffId = draft?.[staffId] || [];
                        draft[staffId] = oldBookingServicesOfStaffId?.filter((bkSv) => +bkSv?.id !== +rmBkSv?.id);
                    }

                    //update existing booking services;
                    for (const updateBkSv of updatedBookingServices) {
                        const updatedStaffId = +updateBkSv?.staffId;

                        //clear all bookingService related to that bookingServices
                        for (const staffId of staffIds) {
                            const oldBookingServicesOfStaffId = draft?.[staffId] || [];
                            draft[staffId] = oldBookingServicesOfStaffId?.filter(
                                (bkSv) => +bkSv?.id !== +updateBkSv?.id
                            );

                            if (+staffId === +updatedStaffId) {
                                const currentStaffBkSvs = draft?.[staffId] || [];
                                draft[staffId] = [...currentStaffBkSvs, updateBkSv];
                            }
                        }
                    }

                    //added new booking services
                    for (const newBkSv of newBookingSvs) {
                        const staffId = +newBkSv?.staffId;
                        if (!draft?.[staffId]) {
                            draft[staffId] = [];
                        }
                        const oldBookingServicesOfStaffId = draft?.[staffId] || [];
                        if (oldBookingServicesOfStaffId?.findIndex((bkSv) => +bkSv?.id === +newBkSv?.id) === -1) {
                            draft[staffId] = [...oldBookingServicesOfStaffId, newBkSv];
                        }
                    }

                    return draft;
                });
            }

            return {
                ...state,
                bookings: newBookings
            };
        }

        case types.TOGGLE_CLOSE_DATE: {
            const { toggleCloseDate } = state;

            return {
                ...state,
                toggleCloseDate: !toggleCloseDate
            };
        }

        case types.SET_SELECTED_DATE: {
            const { date } = payload;

            return {
                ...state,
                selectedDate: date
            };
        }

        case types.CHECKOUT: {
            const { bkSvsByStaffId } = payload;
            const { bookings } = state;

            const newBookings = produce(bookings, (draft) => {
                const staffIds = Object.keys(bkSvsByStaffId);

                for (let staffId of staffIds) {
                    if (!draft?.[staffId]) {
                        draft[staffId] = [];
                    }

                    const oldBkServices = draft?.[staffId] || [];
                    const updateBkSvIds = bkSvsByStaffId?.[staffId] || [];

                    draft[staffId] = oldBkServices?.map((bkSv) => {
                        if (updateBkSvIds?.includes(+bkSv?.id)) {
                            bkSv = {
                                ...bkSv,
                                booking: {
                                    ...bkSv?.booking,
                                    paid: BOOKING_CHECKOUT_STATES.DONE
                                }
                            };
                        }

                        return bkSv;
                    });
                }

                return draft;
            });

            return {
                ...state,
                bookings: newBookings
            };
        }

        case types.FETCH_GIFT_CODES: {
            const { giftCodes } = payload;

            return {
                ...state,
                giftCodes
            };
        }

        case types.REMOVE_GIFT_CODE: {
            const { giftCodeId } = payload;
            const { giftCodes } = state;

            return {
                ...state,
                giftCodes: giftCodes?.filter((giftCode) => +giftCodeId !== +giftCode?.id)
            };
        }

        case types.SET_DATA_BOOKINGS_1_TIME: {
            const { bookings, blockedTimes, staffWorkingHours, originalStaffSchedule } = payload;

            return {
                ...state,
                blockedTimes,
                bookings,
                staffWorkingHours,
                originalStaffSchedule,
                isFetchingBookings: false
            };
        }

        case types.SET_ALERT_NEW_CHECK_IN: {
            const { alert } = payload;

            return {
                ...state,
                isNewCheckIn: alert
            };
        }

        case types.FETCH_BOOKING_LOGS: {
            const { bookingId } = payload;
            const { bookingLogs } = state;

            return {
                ...state,
                bookingLogs: {
                    ...bookingLogs,
                    [bookingId]: {
                        ...bookingLogs?.[bookingId],
                        isFetching: true
                    }
                }
            };
        }

        case types.FETCH_BOOKING_LOGS_FAILED: {
            const { bookingId, error } = payload;

            const { bookingLogs } = state;

            return {
                ...state,
                bookingLogs: {
                    ...bookingLogs,
                    [bookingId]: {
                        ...bookingLogs?.[bookingId],
                        isFetching: false,
                        error
                    }
                }
            };
        }

        case types.FETCH_BOOKING_LOGS_SUCCESS: {
            const { bookingId, logs } = payload;
            const { bookingLogs } = state;

            return {
                ...state,
                bookingLogs: {
                    ...bookingLogs,
                    [bookingId]: {
                        ...bookingLogs?.[bookingId],
                        isFetching: false,
                        logs
                    }
                }
            };
        }

        case types.SET_IS_OPEN_CLIENT_ARRIVED: {
            const { value } = payload;
            return {
                ...state,
                isOpenClientArrived: value
            };
        }

        case types.SET_IS_OPEN_CHECK_OUT: {
            const { value } = payload;
            return {
                ...state,
                isOpenCheckout: value
            };
        }

        case types.SET_FILTERED_STAFF: {
            const { value } = payload;
            return {
                ...state,
                filteredStaff: value
            };
        }

        case types.SET_FIND_BOOKING_ID: {
            const { bkId } = payload;
            return {
                ...state,
                findBookingId: bkId
            };
        }

        case types.SET_OPEN_BOOKING: {
            const { value } = payload;
            return {
                ...state,
                isOpenBooking: value
            };
        }

        case types.SET_IS_DRAGGING: {
            const { value } = payload;
            return {
                ...state,
                isDragging: value
            };
        }

        case types.SET_DRAG_BOOKING_SERVICE: {
            const { bkSv } = payload;
            return {
                ...state,
                dragBkSv: bkSv
            };
        }

        case types.SET_SCHEDULE_TYPE: {
            const { value } = payload;
            return {
                ...state,
                scheduleType: value
            };
        }

        case types.SET_CLONE_BOOKING: {
            const { value } = payload;
            return {
                ...state,
                cloneBooking: value
            };
        }

        case types.SET_CLONE_STAFF: {
            const { value } = payload;
            return {
                ...state,
                cloneStaff: value
            };
        }

        case types.SET_CLONE_TIME: {
            const { value } = payload;
            return {
                ...state,
                cloneTime: value
            };
        }

        case types.RESET_CALENDAR: {
            return INITIAL_STATE;
        }

        default:
            return state;
    }
}
