import React from 'react';
import { Grid, Typography, CircularProgress, IconButton } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { getFullName } from 'utils/naming';
import * as turnActions from './actions';
import { useDispatch } from 'react-redux';
import { useOutTurnStaffs } from 'hooks/turn';
import { ROOMS, TOPICS, TURN_REALTIME_ACTIONS } from 'const';
import { API_URL } from '../../config';
import { getItem } from 'utils/localStorage';
import { TOKEN_NAME } from 'const';
import { io } from 'socket.io-client';
import { useUserMerchantId } from 'hooks/auth';
import { getColorByUserId } from 'utils/color';
import { getAppendMerchantTimezoneToDate, getDatetimeFormat } from 'utils/timing';
import NoMatchGiftCodeSVG from 'assets/images/svg/NoMatchGiftCodeSVG';

const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.colors.ghostwhite,
        minHeight: '100vh'
    },
    staffItem: {
        width: 200,
        height: 200,
        borderRadius: '50%',
        border: `1px solid ${theme.colors.border}`,
        background: theme.palette.common.white
    },
    btnSubmit: {
        position: 'relative'
    },
    buttonProgress: {
        color: theme.palette.common.white,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -40,
        marginLeft: -40
    }
}));

function Item({ staff }) {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = React.useState(false);
    const color = getColorByUserId(+staff?.id);

    const markDone = React.useCallback(
        (staffId) => {
            setIsLoading(true);
            dispatch(
                turnActions.staffMarkDone({
                    staffId,
                    successCallback: () => {
                        console.log('mark done success');
                    },
                    errorCallback: () => {
                        console.log('mark done failed');
                        setIsLoading(false);
                    }
                })
            );
        },
        [dispatch]
    );

    return (
        <IconButton onClick={() => markDone(+staff?.id)}>
            <Grid
                container
                className={classes.staffItem}
                style={{ background: color }}
                alignItems="center"
                justifyContent="center"
            >
                {isLoading ? (
                    <CircularProgress size={80} className={classes.buttonProgress} />
                ) : (
                    <Typography variant="h3" style={{ color: theme.colors.primaryText }}>
                        {getFullName(staff)}
                    </Typography>
                )}
            </Grid>
        </IconButton>
    );
}

function OutTurn() {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const token = getItem(TOKEN_NAME);
    const outTurnStaffs = useOutTurnStaffs();
    const merchantId = useUserMerchantId();
    const intervalRef = React.useRef(null);

    React.useEffect(() => {
        dispatch(
            turnActions.getOutTurnList({
                query: {
                    startDate: getAppendMerchantTimezoneToDate(getDatetimeFormat(new Date()))
                },
                successCallback: () => {
                    console.log('get list out turns success');
                },
                errorCallback: () => {
                    console.log('get list out turns failed');
                }
            })
        );
    }, [dispatch]);

    React.useEffect(() => {
        intervalRef.current = setInterval(() => {
            dispatch(
                turnActions.getOutTurnList({
                    query: {
                        startDate: getAppendMerchantTimezoneToDate(getDatetimeFormat(new Date()))
                    },
                    successCallback: () => {
                        console.log('get list out turns success');
                    },
                    errorCallback: () => {
                        console.log('get list out turns failed');
                    }
                })
            );
        }, 10000);

        return () => {
            if (intervalRef?.current) {
                clearInterval(intervalRef?.current);
            }
        };
    }, [dispatch]);

    const _outDataHandler = React.useCallback(
        (inData) => {
            switch (inData?.type) {
                case TURN_REALTIME_ACTIONS.ADD:
                    return dispatch(turnActions.registerStaffToOutTurnSocket({ staff: inData?.staff }));
                case TURN_REALTIME_ACTIONS.REMOVE:
                    return dispatch(turnActions._staffMarkDone({ staffId: +inData?.staff?.id }));
                case TURN_REALTIME_ACTIONS.RESET:
                    return dispatch(
                        turnActions.getOutTurnList({
                            query: {
                                startDate: getAppendMerchantTimezoneToDate(getDatetimeFormat(new Date()))
                            },
                            successCallback: () => {
                                console.log('get out turn list success');
                            },
                            errorCallback: () => {
                                console.log('get out turn list failed');
                            }
                        })
                    );
                default:
                    return;
            }
        },
        [dispatch]
    );

    React.useEffect(() => {
        const bookingSocket = io(`${API_URL}/app`, {
            transports: ['websocket'],
            autoConnect: true,
            reconnectionDelay: 2000,
            query: {
                token
            }
        });
        bookingSocket.on('connect', () => {
            bookingSocket.emit(ROOMS.JOIN_ROOM, TOPICS.turn(merchantId));
        });

        bookingSocket.on(ROOMS.JOINED_ROOM, (room) => {
            console.log('JOINED_ROOM', room);
        });

        bookingSocket.on(ROOMS.MODIFIED, (body) => {
            // const inData = body?.in;
            const outData = body?.out;
            _outDataHandler(outData);
        });

        bookingSocket.on(ROOMS.LEAVED_ROOM, (body) => {
            console.log('data LEAVED_ROOM', body);
        });

        return () => {
            bookingSocket.emit(ROOMS.LEAVE_ROOM, TOPICS.turn(merchantId));
        };
    }, [merchantId, token, _outDataHandler]);

    if (outTurnStaffs?.length === 0)
        return (
            <Grid container alignItems="center" direction="column" justifyContent="center" className={classes.root}>
                <Grid item style={{ paddingBottom: theme.spacing(2) }}>
                    <NoMatchGiftCodeSVG style={{ width: '100%', height: '100%' }} />
                </Grid>
                <Grid item>
                    <Typography variant="h2">No out turn staff found!</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body2">Staffs are currently working on their tasks</Typography>
                </Grid>
            </Grid>
        );

    return (
        <Grid
            container
            style={{ paddingBottom: theme.spacing(2) }}
            direction="row"
            justifyContent="center"
            className={classes.root}
        >
            {outTurnStaffs?.map((staff) => {
                return (
                    <Grid key={staff?.id} item xs={6} sm={4} md={3} lg={2}>
                        <Grid container direction="row" justifyContent="center" alignItems="center">
                            <Item staff={staff} />
                        </Grid>
                    </Grid>
                );
            })}
        </Grid>
    );
}

export default React.memo(OutTurn);
