import React from 'react';
import StaffTabs from './Tabs';
import ContentLayout from '../layout/ContentLayout';
import { useTranslation } from 'react-i18next';

function Staff() {
    const { t } = useTranslation();

    return (
        <ContentLayout title={`${t('staffs')}`} isHideTitleMobile={true}>
            <StaffTabs />
        </ContentLayout>
    );
}

export default React.memo(Staff);
