import * as types from './types';

export function toggleDrawlerLeft() {
    return {
        type: types.TOGGLE_DRAWLER_SIDEBAR_LEFT
    };
}

export function registerSocketId({ socketId }) {
    return {
        type: types.REGISTER_SOCKET_ID,
        payload: {
            socketId
        }
    };
}

export function setOpenLogoutPopup(openLogoutPopup) {
    return {
        type: types.SET_OPEN_LOGOUT_POPUP,
        payload: {
            openLogoutPopup
        }
    };
}

export function setThemeName(name) {
    return {
        type: types.SET_THEME_NAME,
        payload: {
            name
        }
    };
}

export function setFontSize(fontSize) {
    return {
        type: types.SET_FONT_SIZE,
        payload: {
            fontSize
        }
    };
}

export function toggleSecurePhone() {
    return {
        type: types.TOGGLE_SECURE_PHONE
    };
}
