import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, InputBase, Dialog, IconButton } from '@material-ui/core';
import LayoutTabContent from 'views/layout/LayoutTabContent';
// import useMediaQueries from 'hooks/useMediaQueries';
import { useDispatch } from 'react-redux';
import { OPERATOR } from 'const/condition';
import PhoneFormat from 'components/phoneFormat';
import { columnTypes } from 'const/columnTypes';
import { List, Print } from '@material-ui/icons';
import ContentLayout from '../layout/ContentLayout';
import CloseSVG from 'assets/images/svg/CloseSVG';
import Table from 'components/table/NewTable';
import { useTranslation } from 'react-i18next';
import { PADDING_LIST_ITEM_LEFT_RIGHT } from 'const/style';
import * as bookingActions from './actions';
import { useBookings, useIsFetchingBooking } from 'hooks/booking';
import { useStaffList } from 'hooks/staff';
import { getFriendlyShortTime } from 'utils/timing';
import { getFullName } from 'utils/naming';
import { getImageUrl } from 'utils/image';
import Avatar from 'components/avatar';
import Tooltip from 'components/tooltip';
import BookingPreviewDetail from './BookingPreviewDetail';
import useMediaQueries from 'hooks/useMediaQueries';
import { trimPhone } from 'utils/phone';

const useStyles = makeStyles((theme) => ({
    root: {
        overflowY: 'hidden',
        height: '100%',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            maxHeight: `-webkit-fill-available`,
            marginTop: 0
        }
    },
    wrapContent: {
        height: '100%',
        overflowY: 'hidden',
        backgroundColor: theme.palette.common.white,
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            marginBottom: 0
        }
    },

    header: {
        padding: theme.spacing(2)
    },

    wrapInput: {
        position: 'relative'
    },

    customInput: {
        'label + &': {
            color: theme.colors.secondaryText
        },
        backgroundColor: theme.palette.common.white,
        paddingLeft: PADDING_LIST_ITEM_LEFT_RIGHT,
        paddingRight: 22,
        borderRadius: 3,
        height: 35,
        fontSize: 14,
        fontWeight: 'normal',
        color: theme.colors.primaryText,
        width: '100%'
    },
    clearIcon: {
        position: 'absolute',
        cursor: 'pointer',
        right: 5,
        top: 7,
        zIndex: 9000
    },
    btnSubmit: {
        position: 'relative'
    },
    buttonProgress: {
        color: theme.palette.common.white,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    phones: {
        maxHeight: 45,
        overflowY: 'auto'
    }
}));

const Actions = ({ value, rowData, t, isMobile }) => {
    const dispatch = useDispatch();
    const [openDetail, setOpenDetail] = React.useState(false);
    // const [isPrinting, setIsPrinting] = React.useState(false);

    const stopPropagation = React.useCallback((e) => {
        e && e.stopPropagation();
        e && e.preventDefault();
    }, []);

    const handlePrint = React.useCallback(
        (e) => {
            // setIsPrinting(true);
            stopPropagation(e);
            dispatch(
                bookingActions.printBooking({
                    bookingId: +rowData?.id,
                    successCallback: () => {
                        // setIsPrinting(false);
                    },
                    errorCallback: () => {
                        // setIsPrinting(false);
                    }
                })
            );
        },
        [stopPropagation, dispatch, rowData]
    );

    const handleOpenDetail = React.useCallback(
        (e) => {
            stopPropagation(e);
            setOpenDetail(true);
        },
        [stopPropagation]
    );

    const handleCloseDetail = React.useCallback(
        (e) => {
            stopPropagation(e);
            setOpenDetail(false);
        },
        [stopPropagation]
    );

    return (
        <Grid container spacing={2} wrap="nowrap" justifyContent="flex-end" alignItems="center" direction="row">
            <Grid item>
                <IconButton size="small" onClick={handleOpenDetail}>
                    <List />
                </IconButton>
            </Grid>
            <Grid item>
                <IconButton size="small" onClick={handlePrint}>
                    <Print />
                </IconButton>
            </Grid>

            <Dialog fullScreen={isMobile} maxWidth={false} open={openDetail} onClose={handleCloseDetail}>
                <BookingPreviewDetail booking={rowData} onClose={handleCloseDetail} />
            </Dialog>
        </Grid>
    );
};

const ActionsMemo = React.memo(Actions);

const initialValueFilter = {
    phone: {
        operator: OPERATOR.contains,
        value: '',
        type: columnTypes.TEXT
    }
};

function Dashboard() {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [filter, setFilter] = React.useState(initialValueFilter);
    const timerRef = React.useRef();

    const [totalPages, setTotalPages] = React.useState(0);
    const [limit, setLimit] = React.useState(15);
    const [page, setPage] = React.useState(1);

    const isFetching = useIsFetchingBooking();
    const bookings = useBookings();
    const staffList = useStaffList();
    const { isMobile } = useMediaQueries();

    const handleFilterChange = React.useCallback(
        (id, value) => {
            setFilter({
                ...filter,
                [id]: {
                    ...filter?.[id],
                    value
                }
            });
        },
        [filter]
    );

    const handleClearInput = React.useCallback(
        (id) => {
            const newFilter = {
                ...filter,
                [id]: {
                    ...filter?.[id],
                    value: ''
                }
            };
            setFilter(newFilter);
        },
        [filter]
    );

    const filterHeaderRenderer = React.useCallback(
        ({ column }) => {
            const filterValue = filter?.[column?.id]?.value;
            return (
                <div className={classes.wrapInput}>
                    <InputBase
                        className={classes.customInput}
                        placeholder={'Type to filter'}
                        value={filterValue}
                        onChange={(e) => {
                            if (column?.id === 'noShowCount') e.target.value = e.target.value.replace(/\D/, '');
                            handleFilterChange(column?.id, e.target.value);
                        }}
                    />
                    {filterValue && (
                        <div className={classes.clearIcon} onClick={() => handleClearInput(column?.id)}>
                            <CloseSVG color="#8181A5" size="small" />
                        </div>
                    )}
                </div>
            );
        },
        [classes, filter, handleClearInput, handleFilterChange]
    );

    const columns = [
        {
            id: 'id',
            label: 'Booking ID',
            width: 100
        },
        {
            id: 'staffs',
            label: 'Technicians',
            width: 150,
            format: (props) => (
                <Grid spacing={1} container direction="row">
                    {props?.rowData?.bookingServices?.map((bkSv) => {
                        const staff = staffList?.find((staff) => +staff?.id === +bkSv?.staffId);
                        const fullName = getFullName(staff);

                        return (
                            <Tooltip key={bkSv?.id} placement="top" title={fullName}>
                                <Grid item>
                                    <Avatar size="medium" src={getImageUrl(staff?.avatar)}>
                                        {fullName?.slice(0, 1)}
                                    </Avatar>
                                </Grid>
                            </Tooltip>
                        );
                    })}
                </Grid>
            )
        },
        {
            id: 'serviceLength',
            label: 'Total Services',
            width: 150,
            format: (props) => <Typography variant="body2">{props?.rowData?.bookingServices?.length || 0}</Typography>
        },
        {
            id: 'clientName',
            label: 'Client Name',
            width: 150,
            format: (props) => <Typography variant="body2">{getFullName(props?.rowData?.client)}</Typography>
        },
        {
            id: 'phone',
            label: 'Phone',
            width: 150,
            searchable: true,
            format: (props) => <PhoneFormat number={props?.rowData?.client?.phone} />
        },
        {
            id: 'startDate',
            label: 'Start Date',
            width: 150,
            format: (props) => {
                return <Typography variant="body2">{getFriendlyShortTime(props?.rowData?.startDate)}</Typography>;
            }
        },
        {
            id: 'noShowCount',
            label: 'No Show Count',
            width: 100,
            format: (props) => <Typography variant="body2">{props?.rowData?.client?.noShow || 0}</Typography>
        },
        {
            id: 'actions',
            label: 'Action',
            width: 80,
            format: (props) => <ActionsMemo isMobile={isMobile} t={t} {...props} />
        }
    ];

    const headerRenderer = ({ column }) => {
        return <Typography variant="body1">{column.label}</Typography>;
    };

    const onRowClicked = ({ rowData }) => {
        // handleOpenClientProfile();
        // setSelectedClient(rowData);
    };

    const onChangePageOptions = (e) => {
        setPage(1);
        setLimit(e.target.value);
    };

    const onChangePage = (event, page) => {
        setPage(page);
    };

    React.useEffect(() => {
        if (timerRef.current) clearTimeout(timerRef.current);
        timerRef.current = setTimeout(async () => {
            dispatch(
                bookingActions.fetchBookings({
                    searchCondition: {
                        ...filter,
                        phone: {
                            ...filter?.phone,
                            value: trimPhone(filter?.phone?.value)
                        }
                    },
                    page,
                    limit,
                    errorCallback: () => {
                        console.log('Search bookings errors');
                    },
                    successCallback: (totalPages) => {
                        setTotalPages(totalPages);
                    }
                })
            );
        }, 500);
    }, [dispatch, filter, limit, page]);

    return (
        <ContentLayout title={t('bookings')}>
            <LayoutTabContent isLoading={isFetching && bookings === undefined}>
                <Grid container className={classes.root} direction="column" wrap="nowrap">
                    <Grid item className={classes.wrapContent}>
                        <Table
                            headerRenderer={headerRenderer}
                            filterHeaderRenderer={filterHeaderRenderer}
                            rows={bookings || []}
                            columns={columns}
                            onRowClicked={onRowClicked}
                            page={page}
                            limit={limit}
                            totalPages={totalPages}
                            rowPerPageOptions={[15, 30, 45]}
                            onChangePageOptions={onChangePageOptions}
                            onChangePage={onChangePage}
                            classNameBody={classes.tableBody}
                        />
                    </Grid>
                </Grid>
            </LayoutTabContent>
        </ContentLayout>
    );
}

export default React.memo(Dashboard);
