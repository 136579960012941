import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import useMediaQueries from 'hooks/useMediaQueries';

function TabPanel(props) {
    const { children, value, index, pt, ...other } = props;

    const { isMobile } = useMediaQueries();

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            {...other}
        >
            {value === index && <Box pt={pt ? pt : isMobile ? 1 : 4}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

export default React.memo(TabPanel);
