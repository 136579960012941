import { BOOKING_SERVICE_STATES, BOOKING_STATES } from 'const';

export const COLORS = {
    blue: '#68B8FC',
    template: `#399CFF`,
    darkBlue: '#2C5DB5',
    purple: '#7474DC',
    sun: '#EEBC5B',
    brightSun: '#FFCA41',
    green: '#35D6AB',
    darkGreen: '#48C567',
    lycan: '#59897C',
    red: '#F27A7A',
    darkPink: '#BB509A',
    pink: '#FB7BB8',
    periwinkle: '#C7C7FF',
    gunPower: '#41415C',
    gullGray: `#98A5B5`,
    disabled: `#C9C9CE`
};

export const DEFAULT_COLOR = '#68B8FC';

export const colorPaletteOptions = Object.keys(COLORS).map((key) => COLORS[key]);

export function getRandomColor() {
    const max = colorPaletteOptions.length - 1;
    const index = randomInRange(0, max);
    return colorPaletteOptions[index];
}

function randomInRange(start, end) {
    return Math.floor(Math.random() * (end - start + 1) + start);
}

const REALTIME_COLORS = {
    burntSlena: '#EA6A46',
    crusta: '#F9772A',
    sun: '#F8AD13',
    atlantis: '#77B32B',
    shamRock: '#2DD2A3',
    lightCyan: `#5DCDE3`,
    funBlue: '#1D66AE',
    brightRed: `#EB4F5E`,
    shipCove: '#6A7AB9',
    fuchsiaBlue: '#6E5BBE',
    froly: '#F67B8E',
    blue: '#68B8FC',
    template: `#399CFF`,
    darkBlue: '#2C5DB5',
    purple: '#7474DC',
    brightSun: '#FFCA41',
    green: '#35D6AB',
    darkGreen: '#48C567',
    lycan: '#59897C',
    red: '#F27A7A',
    darkPink: '#BB509A',
    pink: '#FB7BB8',
    disabled: '#C9C9CE'
};

export function getColorByUserId(id) {
    const colorsArr = Object.keys(REALTIME_COLORS).map((key) => REALTIME_COLORS[key]);
    const index = +id % colorsArr.length;
    return colorsArr?.[index] || colorsArr?.[0];
}

export function getBookingServiceColor({ bookingServiceState, defaultColor, theme }) {
    switch (bookingServiceState) {
        case BOOKING_SERVICE_STATES.NO_SHOW:
            return theme.bkSv?.[BOOKING_SERVICE_STATES.NO_SHOW];
        case BOOKING_SERVICE_STATES.DONE:
            return theme.bkSv?.[BOOKING_SERVICE_STATES.DONE];
        default:
            return defaultColor;
    }
}

export function getBackgroundByBookingState({ state, defaultColor, theme }) {
    switch (state) {
        case BOOKING_STATES.NO_SHOW:
            return theme.colors.backgroundDisabled;

        case BOOKING_STATES.CLIENT_CONFIRMED:
            return theme.colors.darkGreen;

        case BOOKING_STATES.CLIENT_CANCEL:
        case BOOKING_STATES.DECLINE:
            return theme.colors.lycan;

        case BOOKING_STATES.PENDING_PAYMENT:
            return theme.colors.sun;

        case BOOKING_STATES.CLIENT_ARRIVED:
            return theme.colors.turquoise;

        case BOOKING_STATES.SERVING:
            return theme.colors.pink;

        case BOOKING_STATES.END:
            return theme.colors.periwinkle;
        default:
            return defaultColor;
    }
}
