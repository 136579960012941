import * as types from './types';
import { formatStaffWorkingHoursResponse } from 'utils/app/staff';
import produce from 'immer';
import { UserState } from 'const';

const INITIAL_STATE = {
    list: [],
    isFetchingStaff: false,
    staffWorkingHoursUsers: null,
    staffWorkingHoursSchedules: {
        //scheduleList
        userId: []
    },
    isFetchingWorkingHours: false,
    closedDates: [],
    isFetchingClosedDates: false
};

export default function StaffReducer(state = INITIAL_STATE, { type, payload }) {
    switch (type) {
        case types.FETCH_STAFF: {
            return {
                ...state,
                isFetchingStaff: true
            };
        }

        case types.FETCH_STAFF_FAILED: {
            return {
                ...state,
                isFetchingStaff: false
            };
        }

        case types.FETCH_STAFF_SUCCESS: {
            const { staffs } = payload;
            return {
                ...state,
                isFetchingStaff: false,
                list: staffs || []
            };
        }

        case types.CREATE_STAFF_SUCCESS: {
            const { staff } = payload;
            const { list } = state;
            return {
                ...state,
                list: [staff, ...list]
            };
        }

        case types.UPDATE_STAFF_SUCCESS: {
            const { staff, id } = payload;
            const { list } = state;
            const newList = list.map((member) => {
                if (member.id === id) return staff;
                return member;
            });
            return {
                ...state,
                list: newList
            };
        }

        case types.UPDATE_STAFF_AVATAR: {
            const { avatar, staffId } = payload;
            const { list } = state;
            const newList = list.map((member) => {
                if (member.userId === staffId) {
                    member.avatar = avatar;
                }
                return member;
            });
            return {
                ...state,
                list: newList
            };
        }

        case types.DELETE_STAFF: {
            const { id } = payload;
            const { list } = state;
            const newList = list.map((member) => {
                if (member.id === id) {
                    member.isDeleted = true;
                }
                return member;
            });
            return {
                ...state,
                list: newList
            };
        }

        case types.DELETE_STAFF_FAILED: {
            const { id } = payload;
            const { list } = state;
            const newList = list.map((member) => {
                if (member.id === id) {
                    member.isDeleted = false;
                }
                return member;
            });
            return {
                ...state,
                list: newList
            };
        }

        case types.DELETE_STAFF_SUCCESS: {
            const { id } = payload;
            const { list } = state;
            const newList = list.map((member) => {
                if (+member?.id === +id) {
                    return {
                        ...member,
                        staffState: UserState.Disable,
                        isDeleted: false
                    };
                }
                return member;
            });
            return {
                ...state,
                list: newList
            };
        }

        case types.SET_STAFF_STATE: {
            const { id, staffState } = payload;

            const { list } = state;
            const newList = list?.map((member) => {
                if (+member?.id === +id) {
                    return {
                        ...member,
                        staffState
                    };
                }
                return member;
            });
            return {
                ...state,
                list: newList
            };
        }

        case types.FETCH_STAFF_WORKING_HOURS: {
            return {
                ...state,
                isFetchingWorkingHours: true
            };
        }

        case types.FETCH_STAFF_WORKING_HOURS_FAILED: {
            const { staffWorkingHours } = state;
            staffWorkingHours.length = 0;
            return {
                ...state,
                isFetchingWorkingHours: false,
                staffWorkingHours
            };
        }

        case types.FETCH_STAFF_WORKING_HOURS_SUCCESS: {
            const { staffWorkingHours } = payload;

            const { staffWorkingHoursSchedules, staffWorkingHoursUsers } = formatStaffWorkingHoursResponse(
                staffWorkingHours
            );

            return {
                ...state,
                isFetchingWorkingHours: false,
                staffWorkingHoursSchedules,
                staffWorkingHoursUsers
            };
        }

        case types.CREATE_STAFF_WORKING_HOURS_SUCCESS: {
            const { schedule, staffId } = payload;

            let { staffWorkingHoursSchedules } = state;

            const newStaffWorkingHoursSchedules = produce(staffWorkingHoursSchedules, (draft) => {
                if (!draft?.[staffId]) {
                    draft[staffId] = [];
                }
                draft[staffId] = [...draft[staffId], schedule];
            });

            console.log('newStaffWorkingHoursSchedules', newStaffWorkingHoursSchedules);
            return {
                ...state,
                staffWorkingHoursSchedules: newStaffWorkingHoursSchedules
            };
        }

        case types.UPDATE_STAFF_WORKING_HOURS_SUCCCESS: {
            const { scheduleRanges, staffId, scheduleId } = payload;

            let { staffWorkingHoursSchedules } = state;

            const newStaffWorkingHoursSchedules = produce(staffWorkingHoursSchedules, (draft) => {
                if (!draft?.[staffId]) {
                    draft[staffId] = [];
                }

                draft[staffId] = draft[staffId].map((schedule) => {
                    if (schedule.id === scheduleId) {
                        return scheduleRanges;
                    }
                    return schedule;
                });
            });
            return {
                ...state,
                staffWorkingHoursSchedules: newStaffWorkingHoursSchedules
            };
        }

        case types.DELETE_STAFF_WORKING_HOURS: {
            const { staffId, scheduleId } = payload;

            let { staffWorkingHoursSchedules } = state;

            const newStaffWorkingHoursSchedules = produce(staffWorkingHoursSchedules, (draft) => {
                if (!draft?.[staffId]) {
                    draft[staffId] = [];
                }

                draft[staffId] = draft[staffId].map((schedule) => {
                    if (schedule.id === scheduleId) {
                        schedule.isDeleted = true;
                    }
                    return schedule;
                });
            });
            return {
                ...state,
                staffWorkingHoursSchedules: newStaffWorkingHoursSchedules
            };
        }

        case types.DELETE_STAFF_WORKING_HOURS_FAILED: {
            const { staffId, scheduleId } = payload;

            let { staffWorkingHoursSchedules } = state;

            const newStaffWorkingHoursSchedules = produce(staffWorkingHoursSchedules, (draft) => {
                if (!draft?.[staffId]) {
                    draft[staffId] = [];
                }

                draft[staffId] = draft[staffId].map((schedule) => {
                    if (schedule.id === scheduleId) {
                        schedule.isDeleted = false;
                    }
                    return schedule;
                });
            });
            return {
                ...state,
                staffWorkingHoursSchedules: newStaffWorkingHoursSchedules
            };
        }

        case types.DELETE_STAFF_WORKING_HOURS_SUCCCESS: {
            const { staffId, scheduleId } = payload;

            let { staffWorkingHoursSchedules } = state;

            const newStaffWorkingHoursSchedules = produce(staffWorkingHoursSchedules, (draft) => {
                if (!draft?.[staffId]) {
                    draft[staffId] = [];
                }

                draft[staffId] = draft[staffId].filter((schedule) => schedule.id !== scheduleId);
            });
            return {
                ...state,
                staffWorkingHoursSchedules: newStaffWorkingHoursSchedules
            };
        }

        case types.FETCH_CLOSE_DATE: {
            return {
                ...state,
                isFetchingClosedDates: true
            };
        }

        case types.FETCH_CLOSE_DATE_FAILED: {
            return {
                ...state,
                isFetchingClosedDates: false
            };
        }

        case types.FETCH_CLOSE_DATE_SUCCESS: {
            const { closedDates } = payload;
            return {
                ...state,
                isFetchingClosedDates: false,
                closedDates
            };
        }

        case types.ADD_CLOSE_DATE_SUCCESS: {
            const { closedDate } = payload;
            const { closedDates } = state;
            return {
                ...state,
                closedDates: [closedDate, ...closedDates]
            };
        }

        case types.DELETE_CLOSE_DATE: {
            const { id } = payload;
            const { closedDates } = state;
            const newClosedDates = closedDates?.map((date) => {
                if (Number(date.id) === Number(id)) {
                    date.isDeleted = true;
                }
                return date;
            });

            return {
                ...state,
                closedDates: newClosedDates
            };
        }

        case types.DELETE_CLOSE_DATE_FAILED: {
            const { id } = payload;
            const { closedDates } = state;
            const newClosedDates = closedDates?.map((date) => {
                if (Number(date.id) === Number(id)) {
                    date.isDeleted = false;
                }
                return date;
            });

            return {
                ...state,
                closedDates: newClosedDates
            };
        }

        case types.DELETE_CLOSE_DATE_SUCCESS: {
            const { id } = payload;
            const { closedDates } = state;
            const newClosedDates = closedDates?.filter((date) => date.id !== id);

            return {
                ...state,
                closedDates: newClosedDates
            };
        }

        case types.UPDATE_CLOSE_DATE_SUCCESS: {
            const { closedDate, id } = payload;
            const { closedDates } = state;
            const newClosedDates = closedDates?.map((date) => {
                if (Number(date.id) === Number(id)) {
                    return closedDate;
                }
                return date;
            });

            return {
                ...state,
                closedDates: newClosedDates
            };
        }

        case types.RE_ORDER_STAFF: {
            const { staffs } = payload;

            return {
                ...state,
                list: staffs
            };
        }

        case types.SET_STAFF_PASSWORD: {
            const { staffId } = payload;
            const listStaffs = state?.list || [];
            const newStaffs = listStaffs?.map((staff) => {
                if (+staff?.id === +staffId) {
                    staff.user = {
                        ...staff.user,
                        isSecured: true
                    };
                }

                return staff;
            });
            return {
                ...state,
                list: newStaffs
            };
        }

        case types.UPDATE_SERVICE_STAFF: {
            const { staffId, staffServiceId, body } = payload;
            const listStaffs = state?.list || [];

            const newStaffs = listStaffs?.map((staff) => {
                if (+staff?.id === +staffId) {
                    staff.staffServices = staff?.staffServices?.map((sSv) => {
                        if (+sSv?.id === +staffServiceId) {
                            return {
                                ...sSv,
                                ...body
                            };
                        }
                        return sSv;
                    });
                }

                return staff;
            });
            return {
                ...state,
                list: newStaffs
            };
        }

        case types.FETCH_STAFF_AND_CLOSED_DATES_SUCCESS: {
            const { staffs, closedDates } = payload;

            return {
                ...state,
                list: staffs,
                closedDates
            };
        }

        default:
            return state;
    }
}
