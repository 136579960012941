import React from 'react';
import { useTheme, Grid, Box, Typography, IconButton, CircularProgress, Dialog } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/Check';
import DragIndicator from '@material-ui/icons/DragIndicator';
import { DEFAULT_COLOR } from 'utils/color';
import Tooltip from 'components/tooltip';
import { Draggable } from 'react-beautiful-dnd';
import { makeStyles } from '@material-ui/core/styles';
import * as categoryActions from 'views/services/action/category';
import { useDispatch } from 'react-redux';
import IncomingBookings from 'views/services/IncomingBookings';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        background: (props) => (props.isActive ? theme.palette.primary.main : theme.palette.common.white),
        padding: theme.spacing(2),
        borderRadius: theme.radius(3),
        cursor: 'pointer',
        '&:hover': {
            background: (props) => (!props.isActive ? '#F7F8FA' : ``)
        }
    }
}));
function Category({ isActive, color, title, onEdit, id, index, onDeleteSuccess }) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const classes = useStyles({ isActive });
    const [isDelete, setIsDelete] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const [incomingBookings, setInComingBookings] = React.useState(null);
    const [isShowIncomingBookings, setIsShowIncomingBookings] = React.useState(false);

    const stopPropagation = React.useCallback((e) => {
        e.stopPropagation();
        e.preventDefault();
    }, []);

    const handleOpenDelete = React.useCallback(
        (e) => {
            stopPropagation(e);
            setIsDelete(true);
        },
        [stopPropagation]
    );

    const handleCancelDelete = React.useCallback(
        (e) => {
            stopPropagation(e);
            setIsDelete(false);
        },
        [stopPropagation]
    );

    const handleDelete = React.useCallback(
        async (e) => {
            setLoading(true);
            stopPropagation(e);
            dispatch(
                categoryActions.deleteCategory({
                    id,
                    errorCallback: () => {},
                    successCallback: (bookings) => {
                        if (bookings?.length) {
                            setIsShowIncomingBookings(true);
                            setInComingBookings(bookings);
                            return;
                        }
                        onDeleteSuccess && onDeleteSuccess(id);
                    }
                })
            );
        },
        [stopPropagation, dispatch, id, onDeleteSuccess]
    );

    const handleIncomingClosed = React.useCallback(() => {
        setIsShowIncomingBookings(false);
        setInComingBookings(null);
    }, []);

    return (
        <Draggable draggableId={id} index={index}>
            {(provided) => (
                <div>
                    <Grid
                        className={classes.wrapper}
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        <Grid item>
                            <Grid container direction="row" alignItems="center" spacing={1}>
                                <Grid item style={{ display: 'flex' }}>
                                    <IconButton size="small">
                                        <DragIndicator
                                            style={{ color: isActive ? theme.palette.common.white : `` }}
                                            fontSize="small"
                                        />
                                    </IconButton>
                                </Grid>
                                <Grid item>
                                    <Box
                                        style={{
                                            height: 15,
                                            width: 15,
                                            borderRadius: theme.radius(1),
                                            background: color || DEFAULT_COLOR
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography
                                        variant="body2"
                                        style={{
                                            color: isActive ? theme.palette.common.white : ``,
                                            fontWeight: isActive ? 600 : ``
                                        }}
                                    >
                                        {title}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container direction="row" alignItems="center">
                                {!isDelete && (
                                    <>
                                        <Grid item style={{ display: 'flex' }}>
                                            <IconButton
                                                onClick={(e) => {
                                                    stopPropagation(e);
                                                    onEdit && onEdit();
                                                }}
                                                size="small"
                                            >
                                                <Tooltip placement="top" title="Edit">
                                                    <EditIcon
                                                        style={{ color: isActive ? theme.palette.common.white : `` }}
                                                        fontSize="small"
                                                    />
                                                </Tooltip>
                                            </IconButton>
                                        </Grid>
                                        <Grid item style={{ display: 'flex' }}>
                                            <IconButton onClick={handleOpenDelete} size="small">
                                                <Tooltip placement="top" title="Delete">
                                                    <DeleteIcon
                                                        style={{ color: isActive ? theme.palette.common.white : `` }}
                                                        fontSize="small"
                                                    />
                                                </Tooltip>
                                            </IconButton>
                                        </Grid>
                                    </>
                                )}
                                {isDelete && (
                                    <>
                                        {!isLoading && (
                                            <Grid item style={{ display: 'flex' }}>
                                                <IconButton onClick={handleCancelDelete} size="small">
                                                    <Tooltip placement="top" title="Cancel">
                                                        <CancelIcon
                                                            style={{
                                                                color: isActive ? theme.palette.common.white : ``
                                                            }}
                                                            fontSize="small"
                                                        />
                                                    </Tooltip>
                                                </IconButton>
                                            </Grid>
                                        )}

                                        <Grid item style={{ display: 'flex' }}>
                                            <IconButton disabled={isLoading} onClick={handleDelete} size="small">
                                                {isLoading ? (
                                                    <CircularProgress color="primary" size="20px" />
                                                ) : (
                                                    <Tooltip placement="top" title="Agree">
                                                        <CheckIcon
                                                            style={{ color: theme.colors.red }}
                                                            fontSize="small"
                                                        />
                                                    </Tooltip>
                                                )}
                                            </IconButton>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Dialog open={isShowIncomingBookings} onClose={handleIncomingClosed}>
                        <IncomingBookings bookings={incomingBookings} onClose={handleIncomingClosed} />
                    </Dialog>
                </div>
            )}
        </Draggable>
    );
}

export default Category;
