import React from 'react';
import AuthenticationCheck from './AuthenticationCheck';
import { Route, Redirect } from 'react-router-dom';
import AuthorizationCheck from './AuthorizationCheck';

function PrivateRoute({ ...rest }) {
    return (
        <AuthenticationCheck
            onAuth={() => (
                <AuthorizationCheck
                    onAuthorization={() => <Route {...rest} />}
                    onUnAuthorization={() => {
                        return <Redirect to="/calendar" />;
                    }}
                    {...rest}
                />
            )}
            onUnAuth={() => {
                return <Redirect to="/login" />;
            }}
        />
    );
}

export default PrivateRoute;
