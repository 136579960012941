export const FETCH_VOUCHERS = '[VOUCHERS]FETCH_VOUCHERS';
export const FETCH_VOUCHERS_SUCCESS = '[VOUCHERS]FETCH_VOUCHERS_SUCCESS';
export const FETCH_VOUCHERS_FAILED = '[VOUCHERS]FETCH_VOUCHERS_FAILED';

export const CREATE_VOUCHER = '[VOUCHERS]CREATE_VOUCHER';
export const CREATE_VOUCHER_SUCCESS = '[VOUCHERS]CREATE_VOUCHER_SUCCESS';
export const CREATE_VOUCHER_FAILED = '[VOUCHERS]CREATE_VOUCHER_FAILED';

export const DELETE_VOUCHER = '[VOUCHERS]DELETE_VOUCHER';
export const DELETE_VOUCHER_SUCCESS = '[VOUCHERS]DELETE_VOUCHER_SUCCESS';
export const DELETE_VOUCHER_FAILED = '[VOUCHERS]DELETE_VOUCHER_FAILED';

export const UPDATE_VOUCHER = '[VOUCHERS]UPDATE_VOUCHER';
export const UPDATE_VOUCHER_SUCCESS = '[VOUCHERS]UPDATE_VOUCHER_SUCCESS';
export const UPDATE_VOUCHER_FAILED = '[VOUCHERS]UPDATE_VOUCHER_FAILED';

/**
 * VOUCHER CODE
 */

export const CREATE_VOUCHER_CODE = '[VOUCHERS_CODE]CREATE_VOUCHER_CODE';
export const CREATE_VOUCHER_CODE_SUCCESS = '[VOUCHERS_CODE]CREATE_VOUCHER_CODE_SUCCESS';
export const CREATE_VOUCHER_CODE_FAILED = '[VOUCHERS_CODE]CREATE_VOUCHER_CODE_FAILED';

export const FETCH_VOUCHERS_CODE = '[VOUCHERS_CODE]FETCH_VOUCHERS';
export const FETCH_VOUCHERS_CODE_SUCCESS = '[VOUCHERS_CODE]FETCH_VOUCHERS_CODE_SUCCESS';
export const FETCH_VOUCHERS_CODE_FAILED = '[VOUCHERS_CODE]FETCH_VOUCHERS_CODE_FAILED';

export const GET_VOUCHERS_CODE_BY_CODE_NUMBER = '[VOUCHERS_CODE]GET_VOUCHERS_CODE_BY_CODE_NUMBER';
export const GET_VOUCHERS_CODE_BY_CODE_NUMBER_SUCCESS = '[VOUCHERS_CODE]GET_VOUCHERS_CODE_BY_CODE_NUMBER_SUCCESS';
export const GET_VOUCHERS_CODE_BY_CODE_NUMBER_FAILED = '[VOUCHERS_CODE]GET_VOUCHERS_CODE_BY_CODE_NUMBER_FAILED';

export const UPDATE_VOUCHER_CODE = '[VOUCHERS_CODE]UPDATE_VOUCHER_CODE';
export const UPDATE_VOUCHER_CODE_SUCCESS = '[VOUCHERS_CODE]UPDATE_VOUCHER_CODE_SUCCESS';
export const UPDATE_VOUCHER_CODE_FAILED = '[VOUCHERS_CODE]UPDATE_VOUCHER_CODE_FAILED';

export const SEND_VOUCHER_CODE = '[VOUCHERS_CODE]SEND_VOUCHER_CODE';
export const SEND_VOUCHER_CODE_SUCCESS = '[VOUCHERS_CODE]SEND_VOUCHER_CODE_SUCCESS';
export const SEND_VOUCHER_CODE_FAILED = '[VOUCHERS_CODE]SEND_VOUCHER_CODE_FAILED';

export const FETCH_GIFT_HISTORY = '[GIFT]FETCH_GIFT_HISTORY';
export const FETCH_GIFT_HISTORY_SUCCESS = '[GIFT]FETCH_GIFT_HISTORY_SUCCESS';
export const FETCH_GIFT_HISTORY_FAILED = '[GIFT]FETCH_GIFT_HISTORY_FAILED';
