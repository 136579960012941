import React from 'react';

function CalendarSVG({ color = '#41415C', ...rest }) {
    return (
        <svg {...rest} width="36px" height="36px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
            <g data-name="07-date">
                <path
                    fill="#bec3d2"
                    d="M35 4V5a2 2 0 0 1-4 0V3a2 2 0 0 1 2-2 2 2 0 0 1 1.41.59A2 2 0 0 1 35 3zM27 4V5a2 2 0 0 1-4 0V3a2 2 0 0 1 2-2 2 2 0 0 1 1.41.59A2 2 0 0 1 27 3zM19 4V5a2 2 0 0 1-4 0V3a2 2 0 0 1 2-2 2 2 0 0 1 1.41.59A2 2 0 0 1 19 3zM11 4V5A2 2 0 0 1 7 5V3A2 2 0 0 1 9 1a2 2 0 0 1 1.41.59A2 2 0 0 1 11 3z"
                />
                <path
                    fill="#ff73a5"
                    d="M41,10H1V8A4,4,0,0,1,5,4H7V5a2,2,0,0,0,4,0V4h4V5a2,2,0,0,0,4,0V4h4V5a2,2,0,0,0,4,0V4h4V5a2,2,0,0,0,4,0V4h2a4,4,0,0,1,4,4Z"
                />
                <rect width="6" height="6" x="18" y="28" fill="#ff73a5" />
                <rect width="6" height="6" x="6" y="28" fill="#ff73a5" />
                <rect width="6" height="6" x="6" y="16" fill="#ff73a5" />
                <rect width="6" height="6" x="18" y="16" fill="#ff73a5" />
                <path
                    fill="#ff73a5"
                    d="M36.31,19.28,33,22l-3.31-2.72a1.69,1.69,0,0,1,0-2.72,2.72,2.72,0,0,1,3.31,0,2.72,2.72,0,0,1,3.31,0A1.69,1.69,0,0,1,36.31,19.28Z"
                />
                <path
                    fill="#ff468c"
                    d="M36.31,16.56a2.72,2.72,0,0,0-2.65-.37,2.37,2.37,0,0,1,.65.37,1.69,1.69,0,0,1,0,2.72L32,21.18,33,22l3.31-2.72A1.69,1.69,0,0,0,36.31,16.56Z"
                />
                <path
                    fill="#ffe1ed"
                    d="M43,34a2,2,0,0,1,2-2,2,2,0,0,1,2,2v9a4,4,0,0,1-4,4H35a4,4,0,0,1-4-4V27a2,2,0,0,1,2-2,2,2,0,0,1,2,2v5a2,2,0,0,1,2-2,2,2,0,0,1,2,2v1a2,2,0,0,1,2-2,2,2,0,0,1,2,2Z"
                />
                <path fill="#ffb9d5" d="M43,44H35a4,4,0,0,1-4-4v3a4,4,0,0,0,4,4h8a4,4,0,0,0,4-4V40A4,4,0,0,1,43,44Z" />
                <path
                    fill="#f2f6fc"
                    d="M35,32V27a2,2,0,0,0-2-2,2,2,0,0,0-2,2V40H5a4,4,0,0,1-4-4V10H41V31a2,2,0,0,0-2,2V32a2,2,0,0,0-2-2A2,2,0,0,0,35,32ZM33,22l3.31-2.72a1.69,1.69,0,0,0,0-2.72,2.72,2.72,0,0,0-3.31,0,2.72,2.72,0,0,0-3.31,0,1.69,1.69,0,0,0,0,2.72ZM24,34V28H18v6Zm0-12V16H18v6ZM12,34V28H6v6Zm0-12V16H6v6Z"
                />
                <path
                    fill="none"
                    stroke="#231f20"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M7 4H5A4 4 0 0 0 1 8V36a4 4 0 0 0 4 4H17M35 4h2a4 4 0 0 1 4 4V28"
                />
                <rect
                    width="4"
                    height="6"
                    x="7"
                    y="1"
                    fill="none"
                    stroke="#231f20"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    rx="2"
                    ry="2"
                />
                <rect
                    width="4"
                    height="6"
                    x="15"
                    y="1"
                    fill="none"
                    stroke="#231f20"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    rx="2"
                    ry="2"
                />
                <rect
                    width="4"
                    height="6"
                    x="23"
                    y="1"
                    fill="none"
                    stroke="#231f20"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    rx="2"
                    ry="2"
                />
                <rect
                    width="4"
                    height="6"
                    x="31"
                    y="1"
                    fill="none"
                    stroke="#231f20"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    rx="2"
                    ry="2"
                />
                <line
                    x1="11"
                    x2="15"
                    y1="4"
                    y2="4"
                    fill="none"
                    stroke="#231f20"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <line
                    x1="19"
                    x2="23"
                    y1="4"
                    y2="4"
                    fill="none"
                    stroke="#231f20"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <line
                    x1="27"
                    x2="31"
                    y1="4"
                    y2="4"
                    fill="none"
                    stroke="#231f20"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <line
                    x1="1"
                    x2="41"
                    y1="10"
                    y2="10"
                    fill="none"
                    stroke="#231f20"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <rect
                    width="6"
                    height="6"
                    x="6"
                    y="16"
                    fill="none"
                    stroke="#231f20"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <rect
                    width="6"
                    height="6"
                    x="18"
                    y="16"
                    fill="none"
                    stroke="#231f20"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <rect
                    width="6"
                    height="6"
                    x="6"
                    y="28"
                    fill="none"
                    stroke="#231f20"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <rect
                    width="6"
                    height="6"
                    x="18"
                    y="28"
                    fill="none"
                    stroke="#231f20"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <path
                    fill="none"
                    stroke="#231f20"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M35,36V27a2,2,0,0,0-2-2h0a2,2,0,0,0-2,2V43a4,4,0,0,0,4,4h8a4,4,0,0,0,4-4V34a2,2,0,0,0-2-2h0a2,2,0,0,0-2,2v2"
                />
                <path
                    fill="none"
                    stroke="#231f20"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M35 36V32a2 2 0 0 1 2-2h0a2 2 0 0 1 2 2v4M39 36V33a2 2 0 0 1 2-2h0a2 2 0 0 1 2 2v2M36.31 16.56a2.72 2.72 0 0 0-3.31 0 2.72 2.72 0 0 0-3.31 0 1.69 1.69 0 0 0 0 2.72L33 22l3.31-2.72A1.69 1.69 0 0 0 36.31 16.56z"
                />
                <line
                    x1="31"
                    x2="25"
                    y1="40"
                    y2="40"
                    fill="none"
                    stroke="#231f20"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <line
                    x1="23"
                    x2="19"
                    y1="40"
                    y2="40"
                    fill="none"
                    stroke="#231f20"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
            </g>
        </svg>
    );
}

export default React.memo(CalendarSVG);
