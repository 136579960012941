import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

import LayoutTabContent from 'views/layout/LayoutTabContent';
import { useDispatch } from 'react-redux';
import ContentLayout from '../layout/ContentLayout';
import {
    useMerchantDetail,
    useBeforeBookingMinutesOptions,
    useCancelBeforeBookingMinutesOptions,
    useMerchantSlotInterval
} from 'hooks/merchant';
import { MERCHANT_DEFAULT_OPTIONS } from 'const';
import NativeSelect from 'components/select/Native';
import * as merchantActions from 'merchant/actions';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 1024,
        overflowY: 'hidden',
        height: '100%',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            maxHeight: `-webkit-fill-available`,
            marginTop: 0
        }
    }
}));

function MerchantSetting() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const merchantDetail = useMerchantDetail();
    const beforeBookingMinutesOptions = useBeforeBookingMinutesOptions();
    const cancelBeforeBookingMinutesOptions = useCancelBeforeBookingMinutesOptions();
    const slotIntervalOptions = useMerchantSlotInterval();
    // const timezoneOptions = useTimeZoneOptions();

    const slotInterval = React.useMemo(() => {
        return merchantDetail?.slotInterval || MERCHANT_DEFAULT_OPTIONS.slotInterval;
    }, [merchantDetail]);

    const bookingCancelBeforeMinutes = React.useMemo(() => {
        return merchantDetail?.bookingCancelBeforeMinutes || MERCHANT_DEFAULT_OPTIONS.bookingCancelBeforeMinutes;
    }, [merchantDetail]);

    const bookingBeforeMinutes = React.useMemo(() => {
        return merchantDetail?.bookingBeforeMinutes || MERCHANT_DEFAULT_OPTIONS.bookingBeforeMinutes;
    }, [merchantDetail]);

    const handleSlotChange = React.useCallback(
        (e) => {
            dispatch(
                merchantActions.updateNormalMerchant({
                    merchantId: merchantDetail?.id,
                    body: { slotInterval: +e?.target?.value },
                    successCallback: () => {
                        console.log('update slot success');
                    },
                    errorCallback: () => {
                        console.log('update slot error');
                    }
                })
            );
        },
        [dispatch, merchantDetail]
    );

    const handleCancelMinutesChange = React.useCallback(
        (e) => {
            dispatch(
                merchantActions.updateNormalMerchant({
                    merchantId: merchantDetail?.id,
                    body: { bookingCancelBeforeMinutes: +e?.target?.value },
                    successCallback: () => {
                        console.log('update bookingCancelBeforeMinutes success');
                    },
                    errorCallback: () => {
                        console.log('update bookingCancelBeforeMinutes error');
                    }
                })
            );
        },
        [dispatch, merchantDetail]
    );

    const handleBookingMinutesChange = React.useCallback(
        (e) => {
            dispatch(
                merchantActions.updateNormalMerchant({
                    merchantId: merchantDetail?.id,
                    body: { bookingBeforeMinutes: +e?.target?.value },
                    successCallback: () => {
                        console.log('update bookingBeforeMinutes success');
                    },
                    errorCallback: () => {
                        console.log('update bookingBeforeMinutes error');
                    }
                })
            );
        },
        [dispatch, merchantDetail]
    );

    return (
        <ContentLayout title={`Merchant Settings`}>
            <LayoutTabContent isLoading={false}>
                <Grid container direction="column" className={classes.root} spacing={2}>
                    <Grid item>
                        <Grid container direction="row" wrap="nowrap" alignItems="center">
                            <Grid item xs={12}>
                                <Typography variant="body2">Slot Interval:</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <NativeSelect
                                    onChange={handleSlotChange}
                                    selectedValue={slotInterval}
                                    options={slotIntervalOptions}
                                    placeholder={`Choose slot interval option`}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" wrap="nowrap" alignItems="center">
                            <Grid item xs={12}>
                                <Typography variant="body2">Allow Cancel Before:</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <NativeSelect
                                    onChange={handleCancelMinutesChange}
                                    selectedValue={bookingCancelBeforeMinutes}
                                    options={cancelBeforeBookingMinutesOptions}
                                    placeholder={`Choose time`}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" wrap="nowrap" alignItems="center">
                            <Grid item xs={12}>
                                <Typography variant="body2">Allow Booking Before:</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <NativeSelect
                                    onChange={handleBookingMinutesChange}
                                    selectedValue={bookingBeforeMinutes}
                                    options={beforeBookingMinutesOptions}
                                    placeholder={`Choose time`}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </LayoutTabContent>
        </ContentLayout>
    );
}

export default React.memo(MerchantSetting);
