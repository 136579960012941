import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import RouteWithLayout from 'components/router/RouterWithLayout';
import MainLayout from 'layouts/Main';
import PrivateRoute from 'views/auth/PrivateRoute';
import AlreadyLoginRoute from 'views/auth/CheckAlreadyLoginRoute';
import {
    Staff as StaffView,
    Calender as CalenderView,
    Services as ServiceView,
    Marketing as MarketingView,
    Profile as ProfileView,
    Clients as ClientView,
    Analyze as AnalyzeView,
    BlastSms as BlastSmsView,
    // NotFound as NotFoundView,
    Login as LoginView,
    Voucher as VoucherView,
    AccessDenied as AccessDeniedView,
    Booking as BookingView,
    OutTurn as OutTurnView,
    InTurn as InTurnView,
    ClientArrived as ClientArrivedView,
    Setting as SettingView,
    MerchantSetting as MerchantSettingView,
    // HidePriceSetting as HidePriceSettingView,
    PaymentHistories as PaymentHistoriesView,
    Statistic as StatisticView,
    TurnCount as TurnCountView
    // CheckIn as CheckInView
} from './views';
import FullScreenLayout from 'layouts/FullScreen';
import { MERCHANT_PERMISSIONS } from 'const';

const pages = [
    {
        component: CalenderView,
        path: '/calendar',
        layout: MainLayout,
        permissions: []
    },
    {
        component: ServiceView,
        path: '/services',
        layout: MainLayout,
        permissions: []
    },
    {
        component: StaffView,
        path: '/staff',
        layout: MainLayout,
        permissions: []
    },
    {
        component: ClientView,
        path: '/clients',
        layout: MainLayout,
        permissions: []
    },
    {
        component: MarketingView,
        path: '/marketing',
        layout: MainLayout,
        permissions: []
    },
    {
        component: ProfileView,
        path: '/profile',
        layout: MainLayout,
        permissions: []
    },
    {
        component: BlastSmsView,
        path: '/sms',
        layout: MainLayout,
        permissions: []
    },
    {
        component: VoucherView,
        path: '/gifts',
        layout: MainLayout,
        permissions: [MERCHANT_PERMISSIONS.GIFT]
    },
    {
        component: AnalyzeView,
        path: '/analyzes',
        layout: MainLayout,
        permissions: [
            MERCHANT_PERMISSIONS.INTERNAL,
            MERCHANT_PERMISSIONS.INTERNAL_BASIC,
            MERCHANT_PERMISSIONS.INTERNAL_MEDIUM
        ]
    },
    // {
    //     component: HidePriceSettingView,
    //     path: '/prices',
    //     layout: MainLayout,
    //     permissions: [
    //         MERCHANT_PERMISSIONS.INTERNAL,
    //         MERCHANT_PERMISSIONS.INTERNAL_BASIC,
    //         MERCHANT_PERMISSIONS.INTERNAL_MEDIUM
    //     ]
    // },
    {
        component: SettingView,
        path: '/admin-settings',
        layout: MainLayout,
        permissions: []
    },
    {
        component: MerchantSettingView,
        path: '/setting',
        layout: MainLayout,
        permissions: []
    },
    {
        component: BookingView,
        path: '/bookings',
        layout: MainLayout,
        permissions: [
            MERCHANT_PERMISSIONS.INTERNAL,
            MERCHANT_PERMISSIONS.INTERNAL_BASIC,
            MERCHANT_PERMISSIONS.INTERNAL_MEDIUM
        ]
    },
    {
        component: OutTurnView,
        path: '/out-turn',
        layout: FullScreenLayout,
        permissions: [MERCHANT_PERMISSIONS.INTERNAL]
    },
    {
        component: TurnCountView,
        path: '/turn-count',
        layout: FullScreenLayout,
        permissions: [MERCHANT_PERMISSIONS.TURN_COUNT]
    },
    {
        component: ClientArrivedView,
        path: '/client-arrived',
        layout: FullScreenLayout,
        permissions: []
    },
    {
        component: InTurnView,
        path: '/in-turn',
        layout: FullScreenLayout,
        permissions: [MERCHANT_PERMISSIONS.INTERNAL]
    },
    {
        component: PaymentHistoriesView,
        path: '/payment-histories',
        layout: MainLayout,
        permissions: [MERCHANT_PERMISSIONS.CHECK_OUT]
    },
    {
        component: StatisticView,
        path: '/statistic',
        layout: MainLayout,
        permissions: []
    }
    // {
    //     component: CheckInView,
    //     path: '/check-in',
    //     layout: FullScreenLayout,
    //     permissions: [MERCHANT_PERMISSIONS.CHECK_IN]
    // }
];

const Routes = () => {
    return (
        <Switch>
            <Redirect exact from="/" to="/calendar" />
            {pages.map(({ path, component, layout, permissions }) => {
                return (
                    <PrivateRoute component={component} key={path} path={path} permissions={permissions}>
                        {(matchProps) => {
                            return <RouteWithLayout layout={layout} component={component} {...matchProps} />;
                        }}
                    </PrivateRoute>
                );
            })}
            <Route path="/access-denied" component={AccessDeniedView} />
            <AlreadyLoginRoute path="/login" component={LoginView} />
            <Redirect to="/login" />
        </Switch>
    );
};

export default Routes;
