import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, Tab, Tabs, AppBar } from '@material-ui/core';
import LayoutTabContent from 'views/layout/LayoutTabContent';
import './main.css';
import { useDispatch } from 'react-redux';
import * as settingActions from './actions';
import { useMerchants } from 'hooks/settings';
import AvatarBase from 'components/avatar';
import { getImageUrl } from 'utils/image';
import Analyze from './Analyze';
import Setting from './Setting';
import Import from './Import';
import { HEADER_TAB_HEIGHT } from 'const';
import Search from 'components/search';
import { getMerchantStateColor, getMerchantStateLabel } from 'utils/app/merchant';

const useStyles = makeStyles((theme) => ({
    root: {
        height: `calc(100vh - 48px - 60px - 60px)`
    },
    sidebar: {
        flexBasis: 300,
        height: '100%',
        overflowY: 'scroll',
        overflowX: 'hidden'
    },
    merchantItem: {
        position: 'relative',
        padding: theme.spacing(2),
        width: '100%',
        '&:hover': {
            background: theme.colors.ghostwhite,
            cursor: 'pointer'
        }
    },
    tag: {
        position: 'absolute',
        padding: `0px 4px`,
        color: theme.palette.common.white,
        top: 10,
        right: 10,
        borderRadius: 4,
        fontSize: 10
    },
    itemSelected: {
        background: theme.colors.anakiwa
    },
    sidebarContent: {
        flex: 1,
        height: '100%',
        overflowY: 'scroll',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3)
    },
    secondaryBar: {
        height: HEADER_TAB_HEIGHT,
        background: theme.colors.background
    },
    tabContent: {
        marginTop: theme.spacing(2)
    },
    search: {
        border: `1px solid ${theme.colors.border}`,
        marginBottom: theme.spacing(3),
        minWidth: 200
    }
}));

function Dashboard() {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const merchants = useMerchants();
    const [selectedMerchant, setSelectedMerchant] = React.useState(null);
    const [tabIndex, setTabIndex] = React.useState(0);
    const [searchValue, setSearchValue] = React.useState('');

    React.useEffect(() => {
        dispatch(
            settingActions.fetchMerchants({
                successCallback: ({ merchants }) => {
                    setSelectedMerchant(merchants?.[0]);
                },
                errorCallback: () => {
                    console.log('fetchMerchants failed');
                }
            })
        );
    }, [dispatch]);

    const merchantsFiltered = React.useMemo(() => {
        return merchants?.filter((merchant) => merchant?.title?.toLowerCase()?.includes(searchValue?.toLowerCase()));
    }, [merchants, searchValue]);

    const tabs = [
        { index: 0, component: <Setting merchant={selectedMerchant} />, title: 'Setting' },
        { index: 1, component: <Analyze merchant={selectedMerchant} />, title: 'Analyze' },
        { index: 2, component: <Import merchant={selectedMerchant} />, title: 'FreshA Import' }
    ];

    return (
        <LayoutTabContent isLoading={false}>
            <Grid container wrap="nowrap" direction="row" className={classes.root}>
                <Grid item className={classes.sidebar}>
                    <Grid style={{ width: '100%' }} wrap="nowrap" container direction="column">
                        <Grid item>
                            <Search
                                placeholder={'Search merchant name'}
                                value={searchValue}
                                onChange={(e) => setSearchValue(e?.target?.value)}
                                className={classes.search}
                            />
                        </Grid>
                        {merchantsFiltered?.map((merchant) => {
                            const isSelected = +merchant?.id === +selectedMerchant?.id;
                            const label = getMerchantStateLabel(merchant?.state);
                            const color = getMerchantStateColor({ state: merchant?.state, theme });

                            return (
                                <Grid
                                    className={`${classes.merchantItem} ${isSelected ? classes.itemSelected : ``}`}
                                    item
                                    key={merchant?.id}
                                    onClick={() => setSelectedMerchant(merchant)}
                                >
                                    <Grid container direction="row" wrap="nowrap" spacing={2} alignItems="center">
                                        <Grid item>
                                            <AvatarBase alt={merchant?.title} src={getImageUrl(merchant?.logo)} />
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction="column">
                                                <Grid item>
                                                    <Typography variant="body1">{merchant?.title}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body2">{merchant?.contactEmail}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <div
                                        className={classes.tag}
                                        variant="body2"
                                        style={{
                                            background: color
                                        }}
                                    >
                                        {label}
                                    </div>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>

                <Grid item className={classes.sidebarContent}>
                    <AppBar component="div" className={classes.secondaryBar} position="static" elevation={0}>
                        <Tabs
                            value={tabIndex}
                            onChange={(e, index) => setTabIndex(index)}
                            indicatorColor="primary"
                            textColor="inherit"
                        >
                            {tabs.map((tab) => (
                                <Tab
                                    key={tab.index}
                                    textColor="inherit"
                                    label={<Typography variant="body2">{tab?.title}</Typography>}
                                />
                            ))}
                        </Tabs>
                    </AppBar>

                    <Grid className={classes.tabContent}>
                        {tabs.map((tab) => {
                            const { index, component } = tab;
                            return index === tabIndex && component;
                        })}
                    </Grid>
                </Grid>
            </Grid>
        </LayoutTabContent>
    );
}

export default React.memo(Dashboard);
