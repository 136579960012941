import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import DialogActions from 'components/dialog/Action';
import DialogContent from 'components/dialog/Content';
import DialogTitle from 'components/dialog/Title';
import ButtonBase from 'components/button/Base';
import useMediaQueries from 'hooks/useMediaQueries';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {},
    title: {},
    btnSubmit: {
        position: 'relative'
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    fullWidth: {
        width: '100%',
        position: 'relative'
    },
    mw500: {
        minWidth: 500
    },
    mw300: {
        minWidth: 300
    },
    closeButton: {
        position: 'absolute',
        zIndex: 20,
        right: 0,
        cursor: 'pointer',
        '& svg': {
            color: theme.colors.froly
        }
    },
    closeEndDateButton: {
        position: 'absolute',
        zIndex: 20,
        top: 0,
        right: 0,
        cursor: 'pointer',
        '& svg': {
            color: theme.colors.froly
        }
    }
}));

function UpcommingConfirm({
    message,
    title = `Repeating Shift`,
    isSubmitting,
    onThisShiftOnly,
    onUpcoming,
    upcomingLabel,
    shiftOnlyLabel,
    onClose
}) {
    const classes = useStyles();
    const { isMobile } = useMediaQueries();
    const { t } = useTranslation();

    return (
        <>
            <DialogTitle>
                <Typography variant="h5">{title}</Typography>
            </DialogTitle>
            <DialogContent className={`${isMobile ? classes.mw300 : classes.mw500}`}>
                <Grid container wrap="nowrap" justifyContent="center" direction="column">
                    <Grid item>
                        <Grid container direction="column">
                            <Grid item>
                                <Typography variant="body2" dangerouslySetInnerHTML={{ __html: message }}></Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container wrap="nowrap" alignItems="center" justifyContent={`space-between`}>
                    {!isMobile && (
                        <Grid item>
                            <ButtonBase
                                onClick={onClose}
                                fullWidth={isMobile}
                                variant="outlined"
                                minWidth={isMobile ? 100 : 120}
                            >
                                {t(`close`)}
                            </ButtonBase>
                        </Grid>
                    )}

                    <Grid item>
                        <Grid container alignItems="center" spacing={2} wrap="nowrap">
                            <Grid item>
                                <ButtonBase
                                    onClick={onUpcoming}
                                    fullWidth={isMobile}
                                    variant="outlined"
                                    color="secondary"
                                >
                                    {upcomingLabel}
                                </ButtonBase>
                            </Grid>
                            <Grid item>
                                <div className={classes.btnSubmit} style={{ width: isMobile ? '100%' : '' }}>
                                    <ButtonBase
                                        onClick={onThisShiftOnly}
                                        disabled={isSubmitting}
                                        fullWidth={isMobile}
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                    >
                                        {shiftOnlyLabel}
                                    </ButtonBase>
                                    {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogActions>
        </>
    );
}

export default React.memo(UpcommingConfirm);
