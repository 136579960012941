import React from 'react';

function CheckSVG({ color = '#7869B9', ...other }) {
    return (
        <svg {...other} width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.4468 1.45395L5.38651 9.69555C5.31552 9.76815 5.20056 9.76815 5.12973 9.69555L0.553123 5.0158C0.482292 4.94354 0.482292 4.826 0.553123 4.75341L1.66518 3.61633C1.73617 3.54391 1.85113 3.54391 1.92196 3.61633L5.25829 7.02756L12.0781 0.0543181C12.1492 -0.0181061 12.2639 -0.0181061 12.3349 0.0543181L13.4468 1.19139C13.5177 1.26382 13.5177 1.38119 13.4468 1.45395Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(CheckSVG);
