import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
// import { Grid } from '@material-ui/core';
import MarketingTabs from './Tabs';

// const useStyles = makeStyles((theme) => ({
//     root: {}
// }));

function Marketing() {
    // const classes = useStyles();

    return <MarketingTabs />;
}

export default React.memo(Marketing);
