import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import LayoutTabContent from 'views/layout/LayoutTabContent';
import Avatar from 'components/avatar';
import { NavLink as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CalenderSVG from 'assets/images/svg/CalenderSVG';
import ServicesSVG from 'assets/images/svg/ServicesSVG';
// import ClientSVG from 'assets/images/svg/ClientSVG';
import StaffSVG from 'assets/images/svg/StaffSVG';
// import AnalyzeSVG from 'assets/images/svg/AnalyzeSVG';
// import MarketingSVG from 'assets/images/svg/MarketingSVG';
import NearMeOutlinedIcon from '@material-ui/icons/NearMeOutlined';
import { CLIENT_URL } from 'config';
import LogoutSVG from 'assets/images/svg/LogoutSVG';
import { useHistory } from 'react-router-dom';
import { clearToken } from 'utils/token';
import VoucherSVG from 'assets/images/svg/VoucherSVG';
import ContentLayout from '../layout/ContentLayout';
import { isAuthorized } from 'utils/app/auth';
import { useMerchantPermissions } from 'hooks/auth';
import { MERCHANT_PERMISSIONS } from 'const';
import { MERCHANT_DEFAULT_OPTIONS } from 'const';
import NativeSelect from 'components/select/Native';
import * as merchantActions from 'merchant/actions';
import {
    useMerchantDetail,
    useBeforeBookingMinutesOptions,
    useCancelBeforeBookingMinutesOptions,
    useMerchantSlotInterval,
    useMerchantInfo
} from 'hooks/merchant';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    root: {
        // maxWidth: 1024,
        overflowY: 'scroll',
        // margin: 'auto',
        height: '100%',
        [theme.breakpoints.down('xs')]: {
            maxHeight: `-webkit-fill-available`
        }
    },
    large: {
        width: 64,
        height: 64
    },
    categories: {},
    avatarName: {
        color: theme.palette.common.white
    },
    position: {
        color: theme.colors.midGrey
    },
    categoryText: {
        paddingLeft: 10
    },
    listItem: {
        height: 44,
        paddingLeft: 10,
        paddingRight: 10,
        cursor: 'pointer',
        '&:hover': {
            borderRadius: 4,
            background: theme.palette.primary.light
        }
    },
    icon: {
        display: 'flex',
        marginRight: 26
    },
    selected: {
        '& .listItem': {
            borderRadius: 4,
            '& *': {
                fontWeight: 'bold'
            }
        }
    },
    link: {
        textDecoration: 'none'
    },
    visitBooking: {
        cursor: 'pointer',
        '& a': {
            color: theme.colors.primaryText,
            textDecoration: 'none'
        },
        '&:hover *': {
            color: `${theme.palette.primary.main} !important`
        }
    },
    pb2: {
        paddingBottom: theme.spacing(2)
    },
    pb1: {
        paddingBottom: theme.spacing(1)
    },
    setting: {}
}));

function Profile() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const merchant = useMerchantInfo();
    const merchantPermissions = useMerchantPermissions();
    const history = useHistory();
    const merchantDetail = useMerchantDetail();
    const beforeBookingMinutesOptions = useBeforeBookingMinutesOptions();
    const cancelBeforeBookingMinutesOptions = useCancelBeforeBookingMinutesOptions();
    const slotIntervalOptions = useMerchantSlotInterval();
    // const timezoneOptions = useTimeZoneOptions();

    const slotInterval = React.useMemo(() => {
        return merchantDetail?.slotInterval || MERCHANT_DEFAULT_OPTIONS.slotInterval;
    }, [merchantDetail]);

    const bookingCancelBeforeMinutes = React.useMemo(() => {
        return merchantDetail?.bookingCancelBeforeMinutes || MERCHANT_DEFAULT_OPTIONS.bookingCancelBeforeMinutes;
    }, [merchantDetail]);

    const bookingBeforeMinutes = React.useMemo(() => {
        return merchantDetail?.bookingBeforeMinutes || MERCHANT_DEFAULT_OPTIONS.bookingBeforeMinutes;
    }, [merchantDetail]);

    const categories = [
        {
            id: t('nav_overview'),
            children: [
                { id: t('nav_calendar'), icon: <CalenderSVG />, path: '/calendar', permissions: [] },
                { id: t('nav_services'), icon: <ServicesSVG />, path: '/services', permissions: [] },
                // { id: t('nav_clients'), icon: <ClientSVG />, path: '/clients' },
                { id: t('nav_staff'), icon: <StaffSVG />, path: '/staff', permissions: [] },
                { id: t('nav_voucher'), icon: <VoucherSVG />, path: '/gifts', permissions: [MERCHANT_PERMISSIONS.GIFT] }
            ]
        }
        // {
        //     id: t('nav_plus'),
        //     children: [
        //         { id: t('nav_analytics'), icon: <AnalyzeSVG />, path: '/analytics' },
        //         { id: t('nav_marketing'), icon: <MarketingSVG />, path: '/marketing' }
        //     ]
        // }
    ];

    const handleLogout = () => {
        clearToken();
        history.push('/login');
    };

    const handleSlotChange = React.useCallback(
        (e) => {
            dispatch(
                merchantActions.updateNormalMerchant({
                    merchantId: merchantDetail?.id,
                    body: { slotInterval: +e?.target?.value },
                    successCallback: () => {
                        console.log('update slot success');
                    },
                    errorCallback: () => {
                        console.log('update slot error');
                    }
                })
            );
        },
        [dispatch, merchantDetail]
    );

    const handleCancelMinutesChange = React.useCallback(
        (e) => {
            dispatch(
                merchantActions.updateNormalMerchant({
                    merchantId: merchantDetail?.id,
                    body: { bookingCancelBeforeMinutes: +e?.target?.value },
                    successCallback: () => {
                        console.log('update bookingCancelBeforeMinutes success');
                    },
                    errorCallback: () => {
                        console.log('update bookingCancelBeforeMinutes error');
                    }
                })
            );
        },
        [dispatch, merchantDetail]
    );

    const handleBookingMinutesChange = React.useCallback(
        (e) => {
            dispatch(
                merchantActions.updateNormalMerchant({
                    merchantId: merchantDetail?.id,
                    body: { bookingBeforeMinutes: +e?.target?.value },
                    successCallback: () => {
                        console.log('update bookingBeforeMinutes success');
                    },
                    errorCallback: () => {
                        console.log('update bookingBeforeMinutes error');
                    }
                })
            );
        },
        [dispatch, merchantDetail]
    );

    return (
        <ContentLayout title={t(`profile`)}>
            <LayoutTabContent isLoading={false}>
                <Grid container className={classes.root} direction="column" wrap="nowrap">
                    <Grid item className={classes.pb2}>
                        <Grid container justifyContent="center" wrap="nowrap" alignItems="center" direction="column">
                            <Grid item className={classes.pb2}>
                                <Avatar alt="Logo" src={merchant?.logo} className={classes.large} />
                            </Grid>
                            <Grid item className={classes.pb2}>
                                <Grid container direction="column" wrap="nowrap" alignItems="center">
                                    <Grid item>
                                        <Typography variant="body1">{merchant?.title}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2">{merchant?.address}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container alignItems="center" wrap="nowrap" className={classes.visitBooking}>
                                    <Grid item className={classes.flex}>
                                        <NearMeOutlinedIcon />
                                    </Grid>
                                    <Grid item>
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={`${CLIENT_URL}/${merchant?.siteName}/booking`}
                                            style={{ whiteSpace: 'nowrap' }}
                                        >
                                            Visit your booking client
                                        </a>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container direction="column">
                        <Grid item className={classes.pb2}>
                            <Grid item container className={classes.setting} direction="column">
                                <Grid item className={classes.pb2}>
                                    <Typography variant="h4">Settings</Typography>
                                </Grid>
                                <Grid item container direction="column">
                                    <Grid item className={classes.pb1}>
                                        <Grid container direction="row" wrap="nowrap" alignItems="center">
                                            <Grid item xs={12}>
                                                <Typography variant="body2">Slot Interval:</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <NativeSelect
                                                    onChange={handleSlotChange}
                                                    selectedValue={slotInterval}
                                                    options={slotIntervalOptions}
                                                    placeholder={`Choose slot interval option`}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item className={classes.pb1}>
                                        <Grid container direction="row" wrap="nowrap" alignItems="center">
                                            <Grid item xs={12}>
                                                <Typography variant="body2">Allow Cancel Before:</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <NativeSelect
                                                    onChange={handleCancelMinutesChange}
                                                    selectedValue={bookingCancelBeforeMinutes}
                                                    options={cancelBeforeBookingMinutesOptions}
                                                    placeholder={`Choose time`}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="row" wrap="nowrap" alignItems="center">
                                            <Grid item xs={12}>
                                                <Typography variant="body2">Allow Booking Before:</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <NativeSelect
                                                    onChange={handleBookingMinutesChange}
                                                    selectedValue={bookingBeforeMinutes}
                                                    options={beforeBookingMinutesOptions}
                                                    placeholder={`Choose time`}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid className={classes.categories} container direction="column">
                                {categories.map(({ id, children }) => (
                                    <Grid item key={id}>
                                        <Grid container direction="column">
                                            <Grid item className={classes.pb2}>
                                                <Typography variant="h4">{id}</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Grid container direction="column">
                                                    {children?.map(
                                                        ({ id: childId, icon, path, permissions }) =>
                                                            isAuthorized({
                                                                userPermission: merchantPermissions,
                                                                permissions
                                                            }) && (
                                                                <Grid item key={childId} className={classes.pb1}>
                                                                    <RouterLink
                                                                        to={path}
                                                                        className={classes.link}
                                                                        activeClassName={classes.selected}
                                                                    >
                                                                        <Grid
                                                                            container
                                                                            className={`${classes.listItem} listItem`}
                                                                            alignItems="center"
                                                                        >
                                                                            <Grid item className={classes.icon}>
                                                                                {icon}
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <Typography variant="body2">
                                                                                    {childId}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </RouterLink>
                                                                </Grid>
                                                            )
                                                    )}
                                                    <Grid item>
                                                        <Grid
                                                            container
                                                            className={`${classes.listItem} listItem`}
                                                            alignItems="center"
                                                            onClick={handleLogout}
                                                        >
                                                            <Grid item className={classes.icon}>
                                                                <LogoutSVG />
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography variant="body2">Log out</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </LayoutTabContent>
            s
        </ContentLayout>
    );
}

export default React.memo(Profile);
