import { useSelector } from 'react-redux';
import { MERCHANT_PERMISSIONS } from 'const';

export function useAuth() {
    return useSelector((state) => state.auth);
}

export function useUser() {
    return useSelector((state) => state.auth?.user);
}

export function useUserMerchantId() {
    return useSelector((state) => state.auth?.user?.merchantId);
}

export function useMerchantPermissions() {
    return useSelector((state) => state.auth?.user?.permissions);
}

export function useIsHavingInternalPermission() {
    return useSelector((state) =>
        state.auth?.user?.permissions?.some((permission) =>
            [
                MERCHANT_PERMISSIONS.INTERNAL,
                MERCHANT_PERMISSIONS.INTERNAL_BASIC,
                MERCHANT_PERMISSIONS.INTERNAL_MEDIUM
            ]?.includes(permission)
        )
    );
}

export function useIsSuperUser() {
    return useSelector((state) => state.auth?.user?.roles?.includes('superUser'));
}

export function useIsHasReviewButNoInternal() {
    const permissions = useMerchantPermissions();

    return (
        !permissions?.some((permission) =>
            [
                MERCHANT_PERMISSIONS.INTERNAL_BASIC,
                MERCHANT_PERMISSIONS.INTERNAL_MEDIUM,
                MERCHANT_PERMISSIONS.INTERNAL
            ]?.includes(permission)
        ) && permissions?.includes(MERCHANT_PERMISSIONS.REVIEW)
    );
}

export function useIsHavingGiftPermission() {
    const permissions = useMerchantPermissions();
    return permissions.includes(MERCHANT_PERMISSIONS.GIFT);
}

export function useIsHavingPermission(permission) {
    return useSelector((state) => state.auth?.user?.permissions?.includes(permission));
}

export function useIsHavingPermissionTest(permission) {
    return useSelector((state) => state.auth?.user?.permissions?.includes(permission));
}
