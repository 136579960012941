import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';

import TableHead from '@material-ui/core/TableHead';
import NativeSelect from 'components/select/Native';
import Grid from '@material-ui/core/Grid';

import TableRow from '@material-ui/core/TableRow';
import { Hidden, Typography } from '@material-ui/core';
import hexToRgba from 'hex-to-rgba';
import Pagination from '@material-ui/lab/Pagination';
import useMediaQueries from 'hooks/useMediaQueries';
import { PAGINATION_OPTIONS_PER_PAGE } from 'const';

const TOTAL_HEIGHT_HEADER_TABLE = 110;
const HEIGHT_HEADER_TABLE = 48;
const HEIGHT_FOOTER_TABLE = 80;

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        background: theme.palette.common.white,
        border: (props) => (props.isBorder ? `1px solid ${theme.colors.border}` : 'none')
    },
    container: {
        height: (props) => (props.isShowFooter ? `calc(100% - ${HEIGHT_FOOTER_TABLE}px )` : '100%'),
        boxShadow: theme.shadows[1]
    },

    wrapperHeader: {
        height: TOTAL_HEIGHT_HEADER_TABLE
    },

    header: {
        height: HEIGHT_HEADER_TABLE,
        '& th': {
            fontSize: 18,
            paddingTop: 15,
            paddingBottom: 15,
            fontWeight: 'bold',
            background: theme.palette.common.white,
            '& p': {
                color: theme.colors.primaryText
            }
        }
    },
    tableBody: {},

    bodyRow: {
        cursor: 'pointer',
        margin: '10px !important',
        border: '1px solid #ccc',
        '&:hover': {
            background: `${theme.colors.hoverItem} !important`
        },
        '& th': {
            background: theme.palette.common.white
        }
    },

    tableCell: {
        borderBottom: `1px solid ${theme.colors.border}`,
        '&:last-child': {
            textAlign: 'end'
        }
    },

    filter: {
        '& th': {
            top: HEIGHT_HEADER_TABLE,
            fontSize: 18,
            paddingTop: 10,
            paddingBottom: 10,
            fontWeight: 'bold',
            background: theme.colors.ghostwhite,
            '& p': {
                color: theme.colors.secondaryText
            }
        }
    },

    listPageNumbersContent: {
        padding: 20
    },

    listPageNumbers: {
        '& button': {
            width: 40,
            height: 40,
            fontWeight: 'bold'
        },

        '& .MuiButton-textPrimary': {
            color: '#8181A5'
        }
    },
    itemPerPage: {
        padding: '0px 20px',
        backgroundColor: theme.colors.ghostwhite,
        maxWidth: 250,
        borderRadius: theme.radius(2),
        float: 'right'
    },

    pageOptions: {
        backgroundColor: 'transparent !important',
        fontWeight: 600
    },
    warning: {
        background: hexToRgba(theme.colors.gunPower, 0.2)
    }
}));

function NewTable({
    isBorder,
    rows = [],
    columns = [],
    headerRenderer,
    filterHeaderRenderer,
    cellRenderer,
    classNameContainer,
    onRowClicked,
    page,
    limit,
    onChangePage,
    totalPages,
    rowPerPageOptions = PAGINATION_OPTIONS_PER_PAGE,
    onChangePageOptions,
    selectableRenderer,
    showTotalSearchRenderer,
    classNameBody,
    isShowFooter = true,
    isShowFilter = true,
    ...rest
}) {
    const classes = useStyles({ isBorder, isShowFooter, isShowFilter });
    const { isMobile } = useMediaQueries();

    const handleRowClicked = ({ e, rowData: row, rowIndex }) => {
        onRowClicked && onRowClicked({ e, rowIndex, rowData: row });
    };

    const pageOptions = React.useMemo(() => {
        const options = rowPerPageOptions;
        return options?.map((option) => {
            return {
                ...option,
                label: `${option}`,
                value: option
            };
        });
    }, [rowPerPageOptions]);

    const renderPagination = React.useCallback(() => {
        return (
            <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                className={classes.listPageNumbersContent}
            >
                <Grid item md={6} xs={12}>
                    <Grid container direction={isMobile ? 'column' : 'row'} alignItems="center" spacing={2}>
                        {totalPages > 1 && (
                            <Grid item>
                                <Pagination count={totalPages} shape="rounded" onChange={onChangePage} page={page} />
                            </Grid>
                        )}
                        <Grid item>{showTotalSearchRenderer && showTotalSearchRenderer()}</Grid>
                    </Grid>
                </Grid>
                <Hidden smDown>
                    <Grid item md={6}>
                        <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                            className={classes.itemPerPage}
                        >
                            <Grid item>
                                <Typography variant="caption">ITEMS PER PAGE:</Typography>
                            </Grid>
                            <Grid item>
                                <NativeSelect
                                    className={classes.pageOptions}
                                    onChange={onChangePageOptions}
                                    selectedValue={limit}
                                    options={pageOptions}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Hidden>
            </Grid>
        );
    }, [
        classes,
        isMobile,
        totalPages,
        onChangePage,
        page,
        showTotalSearchRenderer,
        onChangePageOptions,
        limit,
        pageOptions
    ]);

    return (
        <div className={`${classes.root} ${classNameContainer}`}>
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead className={classes.wrapperHeader}>
                        <TableRow className={classes.header}>
                            {columns.map((column, index) => (
                                <TableCell
                                    className={classes.tableCell}
                                    key={column.id}
                                    align={column.align}
                                    style={{ width: column.width, minWidth: column.width }}
                                >
                                    {headerRenderer({ columnIndex: index, column })}
                                </TableCell>
                            ))}
                        </TableRow>
                        {isShowFilter && (
                            <TableRow className={classes.filter}>
                                {columns?.map((column, index) => (
                                    <TableCell
                                        className={classes.tableCell}
                                        key={column.id}
                                        align={column.align}
                                        style={{ width: column.width, minWidth: column.width }}
                                    >
                                        {column?.searchable && filterHeaderRenderer({ columnIndex: index, column })}
                                        {column?.selectable && selectableRenderer({ columnIndex: index, column })}
                                    </TableCell>
                                ))}
                            </TableRow>
                        )}
                    </TableHead>
                    <TableBody className={`${classes.tableBody} ${classNameBody}`}>
                        {rows.map((row, rowIndex) => {
                            return (
                                <TableRow
                                    className={`${classes.bodyRow} ${row?.void ? classes.warning : ``}`}
                                    onClick={(e) => handleRowClicked({ e, rowData: row, rowIndex })}
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={row?.id || rowIndex}
                                >
                                    {columns.map((column, columnIndex) => {
                                        const value = row[column.id];
                                        return (
                                            <TableCell
                                                className={classes.tableCell}
                                                key={column.id}
                                                align={column.align}
                                                style={{ width: column.width, minWidth: column.width }}
                                            >
                                                {column.format ? (
                                                    column.format({ value, rowData: row })
                                                ) : (
                                                    <Typography variant="body2">{value || '-'}</Typography>
                                                )}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {isShowFooter ? renderPagination() : null}
        </div>
    );
}

export default React.memo(NewTable);
