import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { INPUT_HEIGHT } from 'const/style';
import Avatar from '@material-ui/core/Avatar';
import { EXPIRATION_PERIOD_OPTIONS } from 'data/vouchers';
import capitalize from 'lodash/capitalize';
import QRCode from 'qrcode.react';
import IconCameraSVG from 'assets/images/svg/IconCameraSVG';
import { useDispatch } from 'react-redux';
import { AVATAR_EXTENSIONS } from 'const';
import { useDropzone } from 'react-dropzone';
// import { useTranslation } from 'react-i18next';
import DecoratorLeft from 'assets/images/decorator_left.png';
import DecoratorRight from 'assets/images/decorator_right.png';
import { DEFAULT_VOUCHER_MESSAGE } from 'const';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 470,
        minWidth: 470,
        borderRadius: 0,
        position: 'relative',
        padding: `20px 17px`,
        background: theme.palette.common.white,
        boxShadow: theme.shadows[1],
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            minWidth: '100%',
            padding: `20px 0px`
        }
    },
    header: {
        overflow: 'hidden',
        fontFamily: 'Great Vibes !important',
        fontWeight: 400,
        color: theme.colors.black,
        fontSize: 38,
        textAlign: 'center'
    },
    subHeader: {
        height: 30,
        overflow: 'hidden'
    },
    divider: {
        width: '100%',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    dividerColor: {
        background: theme.colors.border
    },
    title: {
        fontSize: 28,
        fontWeight: 700,
        color: theme.colors.black
    },
    subTitle: {
        fontSize: 22,
        color: `#707070`,
        [theme.breakpoints.down('sm')]: {
            fontSize: 18
        }
    },
    price: {
        color: theme.colors.black
    },
    content: {
        marginTop: theme.spacing(6),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },
    media: {
        width: 211,
        height: 211,
        position: 'relative',
        borderRadius: theme.radius(3),
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            width: 180,
            height: 180
        },
        '& .alreadyHaveImage': {
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            visibility: 'hidden',
            left: 0,
            top: 0,
            '& svg': {
                '& path': {
                    fill: theme.palette.common.white
                }
            },
            '&  div': {
                outline: 'none'
            }
        },
        '&:hover': {
            '& .alreadyHaveImage': {
                visibility: 'visible',
                cursor: 'pointer'
            }
        }
    },
    fullWidthHeight: {
        width: 330,
        height: 350,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    image: {
        width: '100%',
        height: '100%',
        borderRadius: theme.radius(2),
        '&.MuiAvatar-colorDefault': {
            background: theme.colors.background
        },
        cursor: 'pointer',
        position: 'relative'
    },
    footer: {
        minHeight: INPUT_HEIGHT,
        padding: `10px 14px`,
        background: theme.palette.common.white,
        borderRadius: theme.radius(3),
        position: 'absolute',
        width: '95%',
        left: 0,
        bottom: 5,
        right: 0,
        margin: 'auto'
    },
    left: {
        paddingRight: theme.spacing(3),
        flexBasis: 211,
        [theme.breakpoints.down('sm')]: {
            flexBasis: 180
        }
    },
    right: {
        boxSizing: 'border-box',
        flex: 1
    },
    expand: {
        flex: 1
    },
    QrPlace: {
        width: '100%',
        display: 'flex',
        position: 'relative',
        '& canvas': {
            width: `50px !important`,
            height: `50px !important`
        }
    },
    mb1: {
        marginBottom: theme.spacing(1)
    },
    mb2: {
        marginBottom: theme.spacing(2)
    },
    mb3: {
        marginBottom: theme.spacing(3)
    },
    value: {},
    fullHeight: {
        height: '100%'
    },
    fullWidth: {
        width: '100%'
    },

    key: {
        fontWeight: 'normal',
        color: `#393939`
    },
    message: {
        fontStyle: 'italic',
        fontWeight: 'normal',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(5),
        color: `#8788A7`,
        minHeight: 92,
        overflowY: 'hidden'
    },
    valid: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    decorator_left: {
        position: 'absolute',
        top: 20,
        left: 17
    },
    decorator_right: {
        position: 'absolute',
        top: 20,
        right: 17
    },
    spacing: {
        marginBottom: theme.spacing(1)
    },
    allServices: {
        width: '100%',
        background: theme.palette.primary.main,
        borderRadius: 6,
        padding: `5px 12px !important`,
        // minHeight: 34,
        textAlign: 'center'
    },
    redeemableText: {
        fontSize: 14,
        color: theme.palette.common.white,
        fontWeight: 700
    },
    h5: {
        [theme.breakpoints.down('sm')]: {
            fontSize: 14
        }
    }
}));

const DEFAULT_NAME = 'Happy Birthday';
const DEFAULT_MESSAGE = DEFAULT_VOUCHER_MESSAGE;
const DEFAULT_FROM = `Your Name`;
const DEFAULT_TO = `Recipient’s Name`;

function RecipeReviewCard2({
    name = DEFAULT_NAME,
    retailPrice = 0,
    salePercent,
    redeeptionType,
    expirationPeriod = 0,
    messageTitle = DEFAULT_MESSAGE,
    messageDescription = DEFAULT_MESSAGE,
    onUpload,
    logo,
    giftCode = '',
    from = DEFAULT_FROM,
    to = DEFAULT_TO
}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [thumbnail, setThumbnail] = React.useState(logo);
    // const [expanded, setExpanded] = React.useState(false);
    const expirationPeriodLabel = React.useMemo(() => {
        return EXPIRATION_PERIOD_OPTIONS?.find((opt) => opt?.value === Number(expirationPeriod))?.label;
    }, [expirationPeriod]);

    React.useEffect(() => {
        if (logo) {
            setThumbnail(logo);
        } else {
            setThumbnail();
        }
    }, [logo]);

    const stopPropagation = (e) => {
        e.stopPropagation();
        e.preventDefault();
    };

    const onRejectedHandler = React.useCallback(
        (rejectedFiles) => {
            dispatch();
        },
        [dispatch]
    );

    // const handleExpandClick = () => {
    //     setExpanded(!expanded);
    // };

    const onDropAcceptedHandler = React.useCallback(
        (acceptedFiles) => {
            const file = acceptedFiles?.[0];
            if (file) {
                const thumbnail = URL.createObjectURL(file);
                setThumbnail(thumbnail);
                onUpload({ file, thumbnail });
            }
        },
        [onUpload]
    );

    const { getRootProps, getInputProps } = useDropzone({
        onDropAccepted: onDropAcceptedHandler,
        onDropRejected: onRejectedHandler,
        accept: AVATAR_EXTENSIONS
    });

    return (
        <Grid container className={classes.root} direction="column">
            <Grid item className={classes.decorator_left}>
                <img src={DecoratorLeft} alt={'dc_left'} />
            </Grid>
            <Grid item className={classes.decorator_right}>
                <img src={DecoratorRight} alt={'dc_right'} />
            </Grid>
            <Grid item container direction="column" justifyContent="center" alignItems="center">
                <Grid item>
                    <Typography className={`${classes.header}`} variant="h1">
                        {!name ? DEFAULT_NAME : name}
                    </Typography>
                </Grid>
                {/* <Grid item className={classes.divider}>
                    <Divider className={classes.dividerColor} />
                </Grid> */}
                <Grid item>
                    <Typography className={`${classes.title} ${classes.spacing}`} variant="h2">
                        Just for you
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography className={classes.subTitle} variant="body2">
                        Your{' '}
                        <span style={{ textDecoration: 'line-through', display: salePercent === 0 ? 'none' : `` }}>
                            {`$${Math.round(retailPrice - (retailPrice * salePercent) / 100)}`}
                        </span>{' '}
                        <span className={classes.price}>{`$${retailPrice}`}</span> eGift Card
                    </Typography>
                </Grid>
            </Grid>
            <Grid item container direction="row" className={classes.content}>
                <Grid item container direction="column" className={classes.left}>
                    <Grid item className={classes.media}>
                        <Avatar src={thumbnail} className={classes.image}>
                            <div onClick={stopPropagation}>
                                <div className={classes.fullWidthHeight} {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <IconCameraSVG />
                                </div>
                            </div>
                        </Avatar>
                        {thumbnail && (
                            <div onClick={stopPropagation} className={'alreadyHaveImage'}>
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <IconCameraSVG />
                                </div>
                            </div>
                        )}
                    </Grid>
                    <Grid item className={`${classes.allServices}`}>
                        <Typography variant="body2" className={classes.redeemableText}>
                            Redeemable for all services
                        </Typography>
                    </Grid>
                </Grid>

                <Grid item container direction="column" className={classes.right}>
                    <Grid item container direction="row" className={classes.to}>
                        <Typography variant="h5" className={classes.h5}>
                            <span className={classes.key}>To:&nbsp;</span> {!to ? DEFAULT_TO : to}
                        </Typography>
                    </Grid>
                    <Grid item container direction="row">
                        <Typography variant="h5" className={`${classes.message} ${classes.h5}`}>
                            {!messageDescription ? DEFAULT_MESSAGE : messageDescription}
                        </Typography>
                    </Grid>
                    <Grid item container direction="row" className={classes.from}>
                        <Typography variant="h5" className={classes.h5}>
                            <span className={classes.key}>From:&nbsp;</span> {!from ? DEFAULT_FROM : from}
                        </Typography>
                    </Grid>
                    <Grid item container direction="row" className={classes.valid}>
                        <Typography variant="body1">
                            <span className={classes.key}>Expires in:&nbsp;</span> {capitalize(expirationPeriodLabel)}
                        </Typography>
                    </Grid>
                    <Grid item className={classes.QrPlace}>
                        <QRCode size={50} value={giftCode} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(RecipeReviewCard2);
