import * as types from './types';
import {
    createStaffApi,
    deleteStaffApi,
    updateStaffApi,
    getStaffApi,
    getStaffWorkingHoursApi,
    createStaffWorkingHoursApi,
    updateStaffWorkingHoursApi,
    deleteStaffWorkingHoursApi,
    uploadAvatarApi,
    getClosedDatesApi,
    updateClosedDateApi,
    createClosedDateApi,
    deleteClosedDateApi,
    reOrderStaffApi,
    changeStaffPasswordApi,
    setStaffPasswordApi,
    activateStaffApi,
    updateServiceStaffApi
} from 'services/staff';
import { enqueueSnackbar } from 'notifier/actions';
import { formatCreateUpdateSchedule } from 'utils/app/staff';
import { UserState } from 'const';
import { orderBy } from 'lodash';

export function uploadAvatar({ staffId, file, successCallback, errorCallback }) {
    return async function (dispatch) {
        try {
            const staff = await uploadAvatarApi({ file, staffId });
            dispatch(_uploadAvatarActionSuccess({ avatar: staff?.avatar, staffId }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[uploadAvatar] ${message}`,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

function _uploadAvatarActionSuccess({ avatar, staffId }) {
    return {
        type: types.UPDATE_STAFF_AVATAR,
        payload: {
            avatar,
            staffId
        }
    };
}

export function uploadAvatarFailed({ error = '' }) {
    return async function (dispatch) {
        dispatch(
            enqueueSnackbar({
                message: `[uploadAvatarFailed]: ${error}`,
                type: 'info'
            })
        );
    };
}

export function getStaff({ query, errorCallback, successCallback }) {
    return async function (dispatch) {
        dispatch(_fetchStaff());

        try {
            const response = await getStaffApi({ query });
            dispatch(_fetchStaffSuccess({ staffs: response?.items || [] }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[getStaff] ${message}`,
                    type: 'info'
                })
            );
            dispatch(_fetchStaffFailed());
            errorCallback && errorCallback(message);
        }
    };
}

function _fetchStaff() {
    return {
        type: types.FETCH_STAFF
    };
}

function _fetchStaffFailed() {
    return {
        type: types.FETCH_STAFF_FAILED
    };
}

export function _fetchStaffSuccess({ staffs }) {
    return {
        type: types.FETCH_STAFF_SUCCESS,
        payload: {
            staffs
        }
    };
}

export function createStaff({ body, errorCallback, successCallback }) {
    return async function (dispatch) {
        try {
            const staff = await createStaffApi({ body });

            dispatch(_createStaffSuccess({ staff }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[createStaff] ${message}`,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

function _createStaffSuccess({ staff }) {
    return {
        type: types.CREATE_STAFF_SUCCESS,
        payload: {
            staff
        }
    };
}

export function updateStaff({ id, body, errorCallback, successCallback }) {
    return async function (dispatch) {
        try {
            const updatedStaff = await updateStaffApi({ id, body });
            dispatch(_updateStaffSuccess({ id, staff: updatedStaff }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[updateStaff] ${message}`,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

function _updateStaffSuccess({ id, staff }) {
    return {
        type: types.UPDATE_STAFF_SUCCESS,
        payload: {
            staff,
            id
        }
    };
}

export function deleteStaff({ id, errorCallback, successCallback }) {
    return async function (dispatch) {
        // dispatch(_deleteStaff({ id }));
        try {
            const res = await deleteStaffApi({ id });

            if (res?.isConflict) {
                successCallback && successCallback(res?.bookings);
                return;
            }

            dispatch(_deleteStaffSuccess({ id }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[deleteStaff] ${message}`,
                    type: 'info'
                })
            );
            // dispatch(_deleteStaffFailed({ id }));
            errorCallback && errorCallback(message);
        }
    };
}

export function activateStaff({ id, errorCallback, successCallback }) {
    return async function (dispatch) {
        dispatch(_setStaffState({ id, staffState: UserState.Active }));
        try {
            await activateStaffApi({ id });
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[deleteStaff] ${message}`,
                    type: 'info'
                })
            );
            dispatch(_setStaffState({ id, staffState: UserState.Disable }));
            errorCallback && errorCallback(message);
        }
    };
}

function _setStaffState({ id, staffState }) {
    return {
        type: types.SET_STAFF_STATE,
        payload: {
            id,
            staffState
        }
    };
}

// function _deleteStaff({ id }) {
//     return {
//         type: types.DELETE_STAFF,
//         payload: {
//             id
//         }
//     };
// }

function _deleteStaffSuccess({ id }) {
    return {
        type: types.DELETE_STAFF_SUCCESS,
        payload: {
            id
        }
    };
}

// function _deleteStaffFailed({ id }) {
//     return {
//         type: types.DELETE_STAFF_FAILED,
//         payload: {
//             id
//         }
//     };
// }

export function getStaffWorkingHours({ dateStart, dateEnd, errorCallback, successCallback }) {
    return async function (dispatch) {
        dispatch(_fetchStaffWorkingHours());

        if (!dateStart || !dateEnd) {
            dispatch(
                enqueueSnackbar({
                    message: `[getStaffWorkingHours] dateEnd Or DateStart cannot be empty`,
                    type: 'info'
                })
            );
        }

        try {
            const staffWorkingHours = await getStaffWorkingHoursApi({ dateStart, dateEnd });
            dispatch(_fetchStaffWorkingHoursSuccess({ staffWorkingHours }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[getStaffWorkingHours] ${message}`,
                    type: 'info'
                })
            );
            dispatch(_fetchStaffWorkingHoursFailed());
            errorCallback && errorCallback(message);
        }
    };
}

function _fetchStaffWorkingHours() {
    return {
        type: types.FETCH_STAFF_WORKING_HOURS
    };
}

function _fetchStaffWorkingHoursFailed() {
    return {
        type: types.FETCH_STAFF_WORKING_HOURS_FAILED
    };
}

export function _fetchStaffWorkingHoursSuccess({ staffWorkingHours }) {
    return {
        type: types.FETCH_STAFF_WORKING_HOURS_SUCCESS,
        payload: {
            staffWorkingHours
        }
    };
}

export function createStaffWorkingHours({ updateUpComing, staffId, body, errorCallback, successCallback }) {
    return async function (dispatch) {
        try {
            const schedule = await createStaffWorkingHoursApi({ body, updateUpComing });
            dispatch(_createStaffWorkingHoursSuccess({ staffId, schedule: formatCreateUpdateSchedule(schedule) }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[createStaffWorkingHours] ${message}`,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function updateStaffWorkingHours({ staffId, scheduleId, body, updateUpComing, errorCallback, successCallback }) {
    return async function (dispatch) {
        if (!staffId || !scheduleId) {
            dispatch(
                enqueueSnackbar({
                    message: `[updateStaffWorkingHours] staffId or scheduleId cannot be empty`,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
            return;
        }
        try {
            await updateStaffWorkingHoursApi({ body, id: scheduleId, updateUpComing });
            // const schedule = await updateStaffWorkingHoursApi({ body, id: scheduleId, updateUpComing });
            // dispatch(_updateStaffWorkingHoursSuccess({ staffId, scheduleId, scheduleRanges: schedule.scheduleRanges }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[updateStaffWorkingHours] ${message}`,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

function _createStaffWorkingHoursSuccess({ staffId, schedule }) {
    return {
        type: types.CREATE_STAFF_WORKING_HOURS_SUCCESS,
        payload: {
            staffId,
            schedule
        }
    };
}

// function _updateStaffWorkingHoursSuccess({ staffId, scheduleRanges, scheduleId }) {
//     return {
//         type: types.UPDATE_STAFF_WORKING_HOURS_SUCCCESS,
//         payload: {
//             staffId,
//             scheduleRanges,
//             scheduleId
//         }
//     };
// }

export function deleteStaffWorkingHours({
    staffId,
    dateStart,
    scheduleId,
    updateUpComing,
    errorCallback,
    successCallback
}) {
    return async function (dispatch) {
        dispatch(_deleteStaffWorkingHoursAction({ scheduleId, staffId }));
        try {
            await deleteStaffWorkingHoursApi({ scheduleId, dateStart, updateUpComing });
            // dispatch(_deleteStaffWorkingHoursActionSuccess({ scheduleId, staffId }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[deleteStaffWorkingHours] ${message}`,
                    type: 'info'
                })
            );
            dispatch(_deleteStaffWorkingHoursActionFailed({ scheduleId, staffId }));
            errorCallback && errorCallback(message);
        }
    };
}

function _deleteStaffWorkingHoursAction({ staffId, scheduleId }) {
    return {
        type: types.DELETE_STAFF_WORKING_HOURS,
        payload: {
            staffId,
            scheduleId
        }
    };
}

function _deleteStaffWorkingHoursActionFailed({ staffId, scheduleId }) {
    return {
        type: types.DELETE_STAFF_WORKING_HOURS_FAILED,
        payload: {
            staffId,
            scheduleId
        }
    };
}

// function _deleteStaffWorkingHoursActionSuccess({ staffId, scheduleId }) {
//     return {
//         type: types.DELETE_STAFF_WORKING_HOURS_SUCCCESS,
//         payload: {
//             staffId,
//             scheduleId
//         }
//     };
// }

/**
 * Closed Dates
 */

export function fetchClosedDates({ errorCallback, successCallback }) {
    return async function (dispatch) {
        dispatch(_fetchClosedDatesAction());

        try {
            const response = await getClosedDatesApi();
            dispatch(_fetchClosedDatesActionSuccess({ closedDates: response?.items || [] }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[fetchClosedDates] ${message}`,
                    type: 'info'
                })
            );
            dispatch(_fetchClosedDatesActionFailed());
            errorCallback && errorCallback(message);
        }
    };
}

function _fetchClosedDatesAction() {
    return {
        type: types.FETCH_CLOSE_DATE
    };
}

export function _fetchClosedDatesActionSuccess({ closedDates }) {
    return {
        type: types.FETCH_CLOSE_DATE_SUCCESS,
        payload: {
            closedDates
        }
    };
}

function _fetchClosedDatesActionFailed() {
    return {
        type: types.FETCH_CLOSE_DATE_FAILED
    };
}

export function createClosedDate({ body, errorCallback, successCallback }) {
    return async function (dispatch) {
        dispatch(_createClosedDatesAction());
        try {
            const createdClosedDate = await createClosedDateApi({ body });
            console.log('createdClosedDate', createdClosedDate);
            dispatch(_createClosedDateActionSuccess({ closedDate: createdClosedDate }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[createClosedDate] ${message}`,
                    type: 'info'
                })
            );
            dispatch(_createClosedDateActionFailed());
            errorCallback && errorCallback(message);
        }
    };
}

function _createClosedDatesAction() {
    return {
        type: types.ADD_CLOSE_DATE
    };
}

function _createClosedDateActionSuccess({ closedDate }) {
    return {
        type: types.ADD_CLOSE_DATE_SUCCESS,
        payload: {
            closedDate
        }
    };
}

function _createClosedDateActionFailed() {
    return {
        type: types.ADD_CLOSE_DATE_FAILED
    };
}

export function deleteClosedDate({ id, errorCallback, successCallback }) {
    return async function (dispatch) {
        dispatch(_deleteClosedDatesAction({ id }));
        try {
            await deleteClosedDateApi({ id });
            dispatch(_deleteClosedDatesActionSuccess({ id }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[deleteClosedDate] ${message}`,
                    type: 'info'
                })
            );
            dispatch(_deleteClosedDatesActionFailed({ id }));
            errorCallback && errorCallback(message);
        }
    };
}

function _deleteClosedDatesAction({ id }) {
    return {
        type: types.DELETE_CLOSE_DATE,
        payload: {
            id
        }
    };
}

function _deleteClosedDatesActionSuccess({ id }) {
    return {
        type: types.DELETE_CLOSE_DATE_SUCCESS,
        payload: {
            id
        }
    };
}

function _deleteClosedDatesActionFailed({ id }) {
    return {
        type: types.DELETE_CLOSE_DATE_FAILED,
        payload: {
            id
        }
    };
}

export function updateClosedDate({ id, body, errorCallback, successCallback }) {
    return async function (dispatch) {
        try {
            const closedDate = await updateClosedDateApi({ id, body });
            dispatch(_updateClosedDatesActionSuccess({ id, closedDate }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[updateClosedDate] ${message}`,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

function _updateClosedDatesActionSuccess({ id, closedDate }) {
    return {
        type: types.UPDATE_CLOSE_DATE_SUCCESS,
        payload: {
            id,
            closedDate
        }
    };
}

export function reOrderStaff({ afterStaffId, dragId, beforeStaffId, dropIndex, errorCallback, successCallback }) {
    return async function (dispatch, getState) {
        const { staff } = getState();
        const staffList = staff?.list || [];
        const staffListOrdered = orderBy(staffList, ['orderIndex'], ['desc']);

        console.log('staffListOrdered', staffListOrdered);
        let body = {
            staffId: +dragId
        };

        let isAfter = !!afterStaffId;
        let splitIndex = 0;

        if (isAfter) {
            body = {
                ...body,
                afterStaffId: +afterStaffId
            };
            const afterServiceIdIndex = staffListOrdered?.findIndex((staff) => +staff?.id === +afterStaffId);
            splitIndex = afterServiceIdIndex + 1;
        } else {
            body = {
                ...body,
                beforeStaffId: +beforeStaffId
            };

            const beforeServiceIdIndex = staffListOrdered?.findIndex((staff) => +staff?.id === +beforeStaffId);
            splitIndex = beforeServiceIdIndex;
        }

        const staffDrag = staffListOrdered?.find((stf) => +stf?.id === +dragId);

        if (!staffDrag) {
            console.log('staffDrag is empty');
            return;
        }

        const firstPart = staffListOrdered?.slice(0, splitIndex)?.filter((i) => +i?.id !== +dragId);
        const secondPart = staffListOrdered?.slice(splitIndex)?.filter((i) => +i?.id !== +dragId);

        const newStaffList = [...firstPart, staffDrag, ...secondPart];
        const newListStaffWithReorderIndex = newStaffList?.reverse()?.map((stf, index) => ({
            ...stf,
            orderIndex: index
        }));

        dispatch(reOrderStaffsSuccess({ staffs: newListStaffWithReorderIndex }));

        try {
            await reOrderStaffApi(body);
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[reOrderStaff] ${message}`,
                    type: 'info'
                })
            );
            dispatch(reOrderStaffsSuccess({ staffs: staffListOrdered }));
            errorCallback && errorCallback(message);
        }
    };
}

export function reOrderStaffsSuccess({ staffs }) {
    return {
        type: types.RE_ORDER_STAFF,
        payload: {
            staffs
        }
    };
}

export function updateStaffPassword({ staffId, body, errorCallback, successCallback }) {
    return async function (dispatch) {
        try {
            await changeStaffPasswordApi({ staffId, body });
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[updateStaffPassword] ${message}`,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function setStaffPassword({ staffId, body, errorCallback, successCallback }) {
    return async function (dispatch) {
        try {
            await setStaffPasswordApi({ staffId, body });
            dispatch(_setPasswordStaffSuccess({ staffId }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[setStaffPassword] ${message}`,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

function _setPasswordStaffSuccess({ staffId }) {
    return {
        type: types.SET_STAFF_PASSWORD,
        payload: {
            staffId
        }
    };
}

export function updateServiceStaff({ id, body, errorCallback, successCallback }) {
    return async function (dispatch) {
        try {
            await updateServiceStaffApi({ id, body });
            const { staffId, ...rest } = body;
            delete rest?.serviceId;
            dispatch(_updateServiceStaffSuccess({ staffId, staffServiceId: id, body: rest }));
            successCallback && successCallback();
            dispatch(
                enqueueSnackbar({
                    message: `Updated staff commission!`,
                    type: 'success'
                })
            );
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[setStaffPassword] ${message}`,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

function _updateServiceStaffSuccess({ staffId, staffServiceId, body }) {
    return {
        type: types.UPDATE_SERVICE_STAFF,
        payload: {
            staffId,
            staffServiceId,
            body
        }
    };
}

export function fetchStaffsAndClosedDatesSuccess({ staffs, closedDates }) {
    return {
        type: types.FETCH_STAFF_AND_CLOSED_DATES_SUCCESS,
        payload: {
            staffs,
            closedDates
        }
    };
}
