import React from 'react';

const GridLayout = ({ children, columns }) => {
    return (
        <div
            style={{
                display: 'grid',
                gridTemplateColumns: `repeat(${columns}, 1fr)`,
                gridGap: 20,
                margin: '50px auto'
            }}
        >
            {children}
        </div>
    );
};

export default GridLayout;
