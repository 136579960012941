import * as types from './types';
import { enqueueSnackbar } from 'notifier/actions';
import {
    fetchSmsSchedulesApi,
    deleteSmsScheduleApi,
    updateSmsScheduleApi,
    createSmsScheduleApi
} from 'services/schedule';
import { searchClientApi } from 'services/client';
import { formatQueriesConditions } from 'utils/queryParams';
import { fetchClientByBookingRangeDatesApi } from 'services/bookings';

export function fetchSmsSchedule({ searchCondition, page, limit, errorCallback, successCallback }) {
    return async function (dispatch) {
        dispatch(_fetchSmsScheduleAction());

        try {
            const query = formatQueriesConditions(searchCondition);
            const response = await fetchSmsSchedulesApi({ query, page, limit });
            dispatch(
                _fetchSmsScheduleActionSuccess({ smsSchedules: response?.items, total: response?.meta?.totalItems })
            );
            successCallback && successCallback(response?.meta?.totalPages);
        } catch (error) {
            const { message } = error;
            // dispatch(
            //     enqueueSnackbar({
            //         message: `[Fetch sms schedules failed] ${message}`,
            //         type: 'info'
            //     })
            // );
            dispatch(_fetchSmsScheduleActionFailed());
            errorCallback && errorCallback(message);
        }
    };
}

function _fetchSmsScheduleAction() {
    return {
        type: types.FETCH_SMS_SCHEDULES
    };
}

function _fetchSmsScheduleActionFailed() {
    return {
        type: types.FETCH_SMS_SCHEDULES_FAILED
    };
}

function _fetchSmsScheduleActionSuccess({ smsSchedules }) {
    return {
        type: types.FETCH_SMS_SCHEDULES_SUCCESS,
        payload: {
            smsSchedules
        }
    };
}

export function deleteSmsSchedule({ id, errorCallback, successCallback }) {
    return async function (dispatch) {
        dispatch(_deleteSmsSchedule({ id }));
        try {
            await deleteSmsScheduleApi({ id });
            dispatch(_deleteSmsScheduleSuccess({ id }));
            successCallback && successCallback({ id });
        } catch (error) {
            console.log(error);
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[deleteSmsSchedule] ${message}`,
                    type: 'info'
                })
            );
            dispatch(_deleteSmsScheduleFailed({ id }));
            errorCallback && errorCallback(message);
        }
    };
}

function _deleteSmsSchedule({ id }) {
    return {
        type: types.DELETE_SMS_SCHEDULE,
        payload: {
            id
        }
    };
}

function _deleteSmsScheduleFailed({ id }) {
    return {
        type: types.DELETE_SMS_SCHEDULE_FAILED,
        payload: {
            id
        }
    };
}

function _deleteSmsScheduleSuccess({ id }) {
    return {
        type: types.DELETE_SMS_SCHEDULE_SUCCESS,
        payload: {
            id
        }
    };
}

export function updateSmsSchedule({ id, body, errorCallback, successCallback }) {
    return async function (dispatch) {
        try {
            const updateSmsSchedule = await updateSmsScheduleApi({ id, body });
            dispatch(_updateSmsScheduleSuccess({ id, smsSchedule: updateSmsSchedule }));
            successCallback && successCallback({ updateSmsSchedule });
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[updateStaff] ${message}`,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

function _updateSmsScheduleSuccess({ id, smsSchedule }) {
    return {
        type: types.UPDATE_SMS_SCHEDULE_SUCCESS,
        payload: {
            id,
            smsSchedule
        }
    };
}

export function createSmsSchedule({ body, errorCallback, successCallback }) {
    return async function (dispatch) {
        dispatch(_createSmsSchedule());
        try {
            const smsSchedule = await createSmsScheduleApi({ body });
            dispatch(_createSmsScheduleSuccess({ smsSchedule }));
            successCallback && successCallback({ smsSchedule });
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[createSmsSchedule] ${message}`,
                    type: 'info'
                })
            );
            dispatch(_createSmsScheduleFailed());
            errorCallback && errorCallback(message);
        }
    };
}

function _createSmsSchedule() {
    return {
        type: types.CREATE_SMS_SCHEDULE
    };
}

function _createSmsScheduleFailed() {
    return {
        type: types.CREATE_SMS_SCHEDULE_FAILED
    };
}

function _createSmsScheduleSuccess({ smsSchedule }) {
    return {
        type: types.CREATE_SMS_SCHEDULE_SUCCESS,
        payload: {
            smsSchedule
        }
    };
}

export function fetchClients({ searchCondition, page, limit, errorCallback, successCallback }) {
    return async function (dispatch) {
        dispatch(_fetchClientsAction());

        try {
            const query = formatQueriesConditions(searchCondition);
            const response = await searchClientApi({ query, page, limit, isPhoneNotNull: true });
            dispatch(_fetchClientsActionSuccess({ clients: response?.items, total: response?.meta?.totalItems }));
            successCallback &&
                successCallback({ totalPages: response?.meta?.totalPages, total: response?.meta?.totalItems });
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[Fetch clients failed] ${message}`,
                    type: 'info'
                })
            );

            dispatch(_fetchClientsActionFailed(message));
            errorCallback && errorCallback(message);
        }
    };
}

function _fetchClientsAction() {
    return {
        type: types.FETCH_SMS_CLIENTS
    };
}

function _fetchClientsActionFailed() {
    return {
        type: types.FETCH_SMS_CLIENTS_FAILED
    };
}

function _fetchClientsActionSuccess({ clients }) {
    return {
        type: types.FETCH_SMS_CLIENTS_SUCCESS,
        payload: {
            clients
        }
    };
}

export function fetchClientsByRanges({ startDate, endDate, errorCallback, successCallback }) {
    return async function (dispatch) {
        dispatch(_fetchClientsAction());
        try {
            const clients = await fetchClientByBookingRangeDatesApi({ startDate, endDate });
            dispatch(_fetchClientsActionSuccess({ clients, total: clients?.length }));
            successCallback && successCallback({ total: clients.length });
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[Fetch clients failed] ${message}`,
                    type: 'info'
                })
            );

            dispatch(_fetchClientsActionFailed(message));
            errorCallback && errorCallback(message);
        }
    };
}
