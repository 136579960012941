import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Grid,
    ClickAwayListener,
    Typography,
    IconButton,
    Radio,
    RadioGroup,
    FormLabel,
    FormControl,
    FormControlLabel,
    Dialog
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { clearToken } from 'utils/token';
import LogoutSVG from 'assets/images/svg/LogoutSVG';
import { useTranslation } from 'react-i18next';
import {
    FONT_SIZE,
    MERCHANT_DEFAULT_OPTIONS,
    MERCHANT_PERMISSIONS,
    SECURE_PHONE_NAME,
    THEMES,
    THEME_NAME
} from 'const';
import NativeSelect from 'components/select/Native';
import * as merchantActions from 'merchant/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
    useMerchantDetail,
    useBeforeBookingMinutesOptions,
    useCancelBeforeBookingMinutesOptions,
    useMerchantSlotInterval,
    useGroupMerchants,
    useCheckInDistanceOptions
} from 'hooks/merchant';
import * as authActions from 'views/auth/actions';
import { useIsHavingPermission } from 'hooks/auth';
import PolicyIcon from '@material-ui/icons/Policy';
import SecurityIcon from '@material-ui/icons/Security';
import InputViewCopyOnly from 'components/input/InputViewCopyOnly';
import { CLIENT_URL } from 'config';
import { useThemeFontSize, useThemeName } from 'hooks/app';
import { setItem } from 'utils/localStorage';
import IOSSwitch from 'components/switch/IOS';
import { checkPasswordApi } from 'services/merchant';
import * as appActions from 'app/actions';
import RequirePasswordForm from 'views/analyze/RequirePasswordForm';

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 400,
        background: theme.palette.common.white
    },
    setting: {
        background: theme.colors.background,
        padding: theme.spacing(2),
        borderRadius: 6
    }
}));

function UserMenuPopup({ onClose, openSettingPolicy, openChangePassword }) {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const merchantDetail = useMerchantDetail();
    const beforeBookingMinutesOptions = useBeforeBookingMinutesOptions();
    const cancelBeforeBookingMinutesOptions = useCancelBeforeBookingMinutesOptions();
    const slotIntervalOptions = useMerchantSlotInterval();
    const groupMerchants = useGroupMerchants();
    const distanceOptions = useCheckInDistanceOptions();
    const isHavingCheckInPermission = useIsHavingPermission(MERCHANT_PERMISSIONS.CHECK_IN);
    const isHavingSecurePhone = useIsHavingPermission(MERCHANT_PERMISSIONS.SECURE_PHONE_NUMBER);
    const isHavingGiftPermission = useIsHavingPermission(MERCHANT_PERMISSIONS.GIFT);
    const fontSize = useThemeFontSize();
    const themeName = useThemeName();
    const [collectOnlineGiftTax, setCollectOnlineGiftTax] = React.useState(
        merchantDetail?.collectOnlineGiftTax || false
    );

    const securePhone = useSelector((state) => state.app?.securePhone);
    const [password, setPassword] = React.useState('');
    const [error, setError] = React.useState('');
    const [isLoading, setIsLoading] = React.useState('');
    const [showModal, setShowModal] = React.useState(false);

    const handleCheckPasswordConfirmShowMoney = React.useCallback(async () => {
        setIsLoading(true);
        setError('');
        try {
            const isValid = await checkPasswordApi({
                body: {
                    password
                }
            });
            setIsLoading(false);
            if (!isValid) {
                setError('Password do not match');
                return;
            }
            setShowModal(false);
            dispatch(appActions.toggleSecurePhone());
            setItem(SECURE_PHONE_NAME, false);
        } catch (error) {
            setError(error?.message?.[0]);
            setIsLoading(false);
        }
    }, [dispatch, password]);

    const slotInterval = React.useMemo(() => {
        return merchantDetail?.slotInterval || MERCHANT_DEFAULT_OPTIONS.slotInterval;
    }, [merchantDetail]);

    const bookingCancelBeforeMinutes = React.useMemo(() => {
        return merchantDetail?.bookingCancelBeforeMinutes || MERCHANT_DEFAULT_OPTIONS.bookingCancelBeforeMinutes;
    }, [merchantDetail]);

    const bookingBeforeMinutes = React.useMemo(() => {
        return merchantDetail?.bookingBeforeMinutes || MERCHANT_DEFAULT_OPTIONS.bookingBeforeMinutes;
    }, [merchantDetail]);

    const checkInDistance = React.useMemo(() => {
        return merchantDetail?.checkInDistance || MERCHANT_DEFAULT_OPTIONS.checkInDistance;
    }, [merchantDetail]);

    const handleLogout = () => {
        clearToken();
        history.push('/login');
    };

    const isShowSwitchMerchant = React.useMemo(() => {
        return groupMerchants?.length > 1;
    }, [groupMerchants]);

    const groupMerchantsOptions = React.useMemo(() => {
        return groupMerchants?.map((gM) => ({
            label: gM?.merchant?.title,
            value: gM?.merchant?.id
        }));
    }, [groupMerchants]);

    const handleSlotChange = React.useCallback(
        (e) => {
            dispatch(
                merchantActions.updateNormalMerchant({
                    merchantId: merchantDetail?.id,
                    body: { slotInterval: +e?.target?.value },
                    successCallback: () => {
                        console.log('update slot success');
                    },
                    errorCallback: () => {
                        console.log('update slot error');
                    }
                })
            );
        },
        [dispatch, merchantDetail]
    );

    const handleCancelMinutesChange = React.useCallback(
        (e) => {
            dispatch(
                merchantActions.updateNormalMerchant({
                    merchantId: merchantDetail?.id,
                    body: { bookingCancelBeforeMinutes: +e?.target?.value },
                    successCallback: () => {
                        console.log('update bookingCancelBeforeMinutes success');
                    },
                    errorCallback: () => {
                        console.log('update bookingCancelBeforeMinutes error');
                    }
                })
            );
        },
        [dispatch, merchantDetail]
    );

    const handleBookingMinutesChange = React.useCallback(
        (e) => {
            dispatch(
                merchantActions.updateNormalMerchant({
                    merchantId: merchantDetail?.id,
                    body: { bookingBeforeMinutes: +e?.target?.value },
                    successCallback: () => {
                        console.log('update bookingBeforeMinutes success');
                    },
                    errorCallback: () => {
                        console.log('update bookingBeforeMinutes error');
                    }
                })
            );
        },
        [dispatch, merchantDetail]
    );

    const handleCheckInDistanceChange = React.useCallback(
        (e) => {
            dispatch(
                merchantActions.updateNormalMerchant({
                    merchantId: merchantDetail?.id,
                    body: { checkInDistance: +e?.target?.value },
                    successCallback: () => {
                        console.log('update handleCheckInDistanceChange success');
                    },
                    errorCallback: () => {
                        console.log('update handleCheckInDistanceChange error');
                    }
                })
            );
        },
        [dispatch, merchantDetail]
    );

    const handleMerchantSwitch = React.useCallback(
        (e) => {
            const newMerchantId = +e?.target?.value;

            if (newMerchantId !== +merchantDetail?.id) {
                dispatch(
                    authActions.switchMerchant({
                        body: {
                            merchantId: newMerchantId
                        },
                        errorCallback: (message) => {
                            console.log('err', message);
                        },
                        successCallback: () => {
                            console.log('done');
                            history.go(0);
                        }
                    })
                );
                onClose && onClose();
            }
        },
        [merchantDetail, history, dispatch, onClose]
    );

    const handleSizeChange = React.useCallback(
        (e) => {
            setItem(FONT_SIZE, +e?.target?.value);
            // dispatch(appActions.setFontSize(+e?.target?.value));
            history.go(0);
        },
        [history]
    );

    const handleThemeName = React.useCallback(
        (e) => {
            setItem(THEME_NAME, e?.target?.value);
            // dispatch(appActions.setThemeName(e?.target?.value));
            history.go(0);
        },
        [history]
    );

    const handleCollectGiftTaxChange = React.useCallback(
        (e) => {
            const checked = e?.target?.checked;
            setCollectOnlineGiftTax(checked);
            dispatch(
                merchantActions.updateNormalMerchant({
                    merchantId: merchantDetail?.id,
                    body: { collectOnlineGiftTax: checked },
                    successCallback: () => {
                        console.log('update handleCheckInDistanceChange success');
                    },
                    errorCallback: () => {
                        console.log('update handleCheckInDistanceChange error');
                    }
                })
            );
        },
        [merchantDetail, dispatch]
    );

    const handleShowModal = React.useCallback(
        (e) => {
            if (e?.target?.checked) {
                setShowModal(true);
            } else {
                dispatch(appActions.toggleSecurePhone());
                setItem(SECURE_PHONE_NAME, true);
            }
        },
        [dispatch]
    );

    return (
        <ClickAwayListener onClickAway={onClose}>
            <Grid container className={classes.root} direction="column" wrap="nowrap">
                <Grid item container className={classes.setting} direction="column" spacing={2}>
                    <Grid item>
                        <Typography variant="h4">Settings</Typography>
                    </Grid>
                    <Grid item container direction="column" spacing={1}>
                        <Grid item>
                            <Grid container direction="row" wrap="nowrap" alignItems="center">
                                <Grid item xs={12}>
                                    <Typography variant="body1">Slot Interval:</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <NativeSelect
                                        onChange={handleSlotChange}
                                        selectedValue={slotInterval}
                                        options={slotIntervalOptions}
                                        placeholder={`Choose slot interval option`}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container direction="row" wrap="nowrap" alignItems="center">
                                <Grid item xs={12}>
                                    <Typography variant="body1">Allow Cancel Before:</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <NativeSelect
                                        onChange={handleCancelMinutesChange}
                                        selectedValue={bookingCancelBeforeMinutes}
                                        options={cancelBeforeBookingMinutesOptions}
                                        placeholder={`Choose time`}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container direction="row" wrap="nowrap" alignItems="center">
                                <Grid item xs={12}>
                                    <Typography variant="body1">Allow Booking Before:</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <NativeSelect
                                        onChange={handleBookingMinutesChange}
                                        selectedValue={bookingBeforeMinutes}
                                        options={beforeBookingMinutesOptions}
                                        placeholder={`Choose time`}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        {isHavingCheckInPermission && (
                            <Grid item>
                                <Grid container direction="row" wrap="nowrap" alignItems="center">
                                    <Grid item xs={12}>
                                        <Typography variant="body1">Check In Distance:</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <NativeSelect
                                            onChange={handleCheckInDistanceChange}
                                            selectedValue={checkInDistance}
                                            options={distanceOptions}
                                            placeholder={`Choose distance`}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}

                        {isShowSwitchMerchant && (
                            <Grid item>
                                <Grid container direction="row" wrap="nowrap" alignItems="center">
                                    <Grid item xs={12}>
                                        <Typography variant="body1">Switch Merchant</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <NativeSelect
                                            onChange={handleMerchantSwitch}
                                            selectedValue={merchantDetail?.id}
                                            options={groupMerchantsOptions}
                                            placeholder={`Choose merchant`}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}

                        <Grid item>
                            <Grid container direction="row" alignItems="center" wrap="nowrap">
                                <Grid item xs={12}>
                                    <Typography variant="body1">Embed snippet</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <InputViewCopyOnly
                                        value={`<iframe class="gridly-embed" src="${`${CLIENT_URL}/${merchantDetail?.siteName}/booking`}" frameborder="0" onmousewheel="" width="100%" height="533" style="background: transparent; border: 1px solid #ccc;" allow="clipboard-read; clipboard-write"></iframe>`}
                                        secure={false}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Grid container direction="row" spacing={2} wrap="nowrap" alignItems="center">
                                <Grid item>
                                    <Typography variant="body1">Policy Settings</Typography>
                                </Grid>
                                <Grid item>
                                    <IconButton onClick={openSettingPolicy} size="small">
                                        <PolicyIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Grid container direction="row" spacing={2} wrap="nowrap" alignItems="center">
                                <Grid item>
                                    <Typography variant="body1">Change password</Typography>
                                </Grid>
                                <Grid item>
                                    <IconButton onClick={openChangePassword} size="small">
                                        <SecurityIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>

                        {isHavingGiftPermission && (
                            <Grid style={{ marginTop: 4, paddingLeft: 2 }} container spacing={2} alignItems="center">
                                <Grid item>
                                    <Typography variant="body1">Gift card</Typography>
                                </Grid>
                                <Grid item>
                                    <IOSSwitch
                                        checked={collectOnlineGiftTax}
                                        onChange={handleCollectGiftTaxChange}
                                        name="block"
                                        color="secondary"
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography variant="body1">
                                        Gift certificate ({merchantDetail?.taxRate}% tax)
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}

                        {isHavingSecurePhone && (
                            <Grid style={{ marginTop: 4, paddingLeft: 2 }} container spacing={2} alignItems="center">
                                <Grid item>
                                    <Typography variant="body1">Show Phone:</Typography>
                                </Grid>
                                <Grid item>
                                    <IOSSwitch
                                        checked={!securePhone}
                                        onChange={handleShowModal}
                                        name="block"
                                        color="secondary"
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>

                <Grid item container className={classes.setting} direction="column" spacing={2}>
                    <Grid item>
                        <Typography variant="h4">Theme</Typography>
                    </Grid>
                    <Grid item container direction="column" spacing={1}>
                        <Grid item>
                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label">Font size</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    onChange={handleSizeChange}
                                    value={fontSize}
                                >
                                    <FormControlLabel value={0.8} control={<Radio />} label="Smaller" />
                                    <FormControlLabel value={0.9} control={<Radio />} label="Small" />
                                    <FormControlLabel value={1} control={<Radio />} label="Medium" />
                                    <FormControlLabel value={1.1} control={<Radio />} label="Large" />
                                    <FormControlLabel value={1.2} control={<Radio />} label="Larger" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item container direction="column" spacing={1}>
                        <Grid item>
                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label-theme">Color</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label-theme"
                                    name="row-radio-buttons-group"
                                    onChange={handleThemeName}
                                    value={themeName}
                                >
                                    <FormControlLabel value={THEMES.BOOK_SALON} control={<Radio />} label="Default" />
                                    <FormControlLabel value={THEMES.MANGO_MINT} control={<Radio />} label="Bright" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid
                        style={{ padding: `12px 24px`, marginTop: 14, cursor: 'pointer' }}
                        container
                        onClick={handleLogout}
                        alignItems="center"
                        spacing={2}
                    >
                        <Grid item style={{ display: 'flex' }}>
                            <LogoutSVG />
                        </Grid>
                        <Grid item>
                            <Typography variant="body2">{t(`logout`)}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Dialog
                    style={{ zIndex: 100000 }}
                    maxWidth={false}
                    open={showModal}
                    onClose={() => setShowModal(false)}
                >
                    <RequirePasswordForm
                        password={password}
                        onPasswordChange={(e) => setPassword(e?.target?.value)}
                        isLoading={isLoading}
                        onCheckPassword={handleCheckPasswordConfirmShowMoney}
                        error={error}
                        title="Turn on/off phone number"
                        subtitle="Management phone number only"
                    />
                </Dialog>
            </Grid>
        </ClickAwayListener>
    );
}

export default React.memo(UserMenuPopup);
