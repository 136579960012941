import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Avatar } from '@material-ui/core';
import AvatarIconSVG from 'assets/images/svg/AvatarIconSVG';

const useStyles = makeStyles((theme) => ({
    root: {
        width: (props) => props.dimension || 40,
        height: (props) => props.dimension || 40,
        minWidth: (props) => props.dimension || 40,
        minHeight: (props) => props.dimension || 40,
        fontSize: (props) => props.fontSize || 14,
        textTransform: `uppercase`,
        fontWeight: `bold`
    },
    svgWithChildren: {
        '& > svg': {
            width: (props) => props.fontSize || 14,
            height: (props) => props.fontSize || 14
        }
    }
}));

const SIZES = {
    small: {
        dimension: 24,
        fontSize: 12
    },
    medium: {
        dimension: 32,
        fontSize: 16
    },
    large: {
        dimension: 48,
        fontSize: 18
    }
};

function getSize(size) {
    if (typeof size === 'number') return { dimension: size, fontSize: size * 0.5 };
    switch (size) {
        case 'small':
            return SIZES['small'];
        case 'medium':
            return SIZES['medium'];
        case 'large':
            return SIZES['large'];
        default:
            return SIZES['medium'];
    }
}

function AvatarBase({ size = 'medium', color, children, className, src, ...rest }) {
    const { dimension, fontSize } = getSize(size);
    const classes = useStyles({ dimension, fontSize });
    const theme = useTheme();
    const defaultBackgroundColor = React.useMemo(() => {
        return theme.palette.primary.main;
    }, [theme]);

    if (!children && !src) {
        return (
            <Avatar
                className={`${classes.root} ${className}`}
                style={{
                    background: color || defaultBackgroundColor,
                    color: theme.palette.common.white
                }}
                {...rest}
            >
                <AvatarIconSVG style={{ width: '100%', height: '100%' }} />
            </Avatar>
        );
    }
    return (
        <Avatar
            src={src}
            className={`${classes.root} ${classes.svgWithChildren} ${classes.bold} ${className}`}
            style={{
                background: color || defaultBackgroundColor,
                color: theme.palette.common.white
            }}
            {...rest}
        >
            {children}
        </Avatar>
    );
}

export default React.memo(AvatarBase);
