import React from 'react';
import Dashboard from './Dashboard';
import CreateNewMerchant from './CreateNewMerchant';
import LayoutTabsContent from '../layout/LayoutTabsContent';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ReportIcon from '@material-ui/icons/Report';
import BookIcon from '@material-ui/icons/Book';
import GroupMerchant from './group';
import BookingLog from './log';

function AnalyzeTabs() {
    const tabs = [
        { component: <Dashboard />, index: 0, icon: <DashboardIcon />, title: `Merchants`, path: 'merchants' },
        {
            component: <CreateNewMerchant />,
            index: 1,
            icon: <ReportIcon />,
            title: `Create New Merchant`,
            path: 'new-merchant'
        },
        {
            component: <GroupMerchant />,
            index: 2,
            icon: <ReportIcon />,
            title: `Group Merchant`,
            path: 'group-merchant'
        },
        {
            component: <BookingLog />,
            index: 3,
            icon: <BookIcon />,
            title: `Booking log`,
            path: 'find-booking-log'
        }
    ];

    return <LayoutTabsContent tabs={tabs} />;
}

export default React.memo(AnalyzeTabs);
