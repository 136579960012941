import * as types from './types';

const INITIAL_STATE = {
    createdClients: 0,
    totalOnlineBookings: 0,
    totalOfflineBookings: 0,
    bookings: [],
    staffAnalyze: [],
    offlineGifts: 0,
    onlineGifts: 0,
    onlineGiftIncome: 0,
    offlineGiftIncome: 0,
    remainingValue: 0,
    redeemedCardCount: 0,
    redeemedValue: 0,
    codes: [],
    totalDepositAmount: 0
};

export default function smsSchedules(state = INITIAL_STATE, { type, payload }) {
    switch (type) {
        case types.FETCH_STATISTIC_RANGE: {
            const { bookings, createdClients, totalOnlineBookings, totalOfflineBookings, totalDepositAmount } = payload;
            return {
                ...state,
                bookings,
                createdClients,
                totalOnlineBookings,
                totalOfflineBookings,
                totalDepositAmount
            };
        }

        case types.FETCH_GIFTS_STATISTIC: {
            return {
                ...state,
                ...payload
            };
        }

        default:
            return state;
    }
}
