import React from 'react';
import { makeStyles, useTheme, alpha } from '@material-ui/core/styles';
import { Grid, Typography, Popover, Dialog, Divider, Chip, IconButton, CircularProgress } from '@material-ui/core';
import AddBooking from 'views/calender/calendar/addBooking/index';
import Tooltip from 'components/tooltip';
import { mapTimeToTime12WithoutAMPm, convertFloatingToDateTime, convertDurationAndStartTimeToDate } from 'utils/timing';
import { BOOKING_TYPES, BOOKING_SERVICE_STATES, BOOKING_CHECKOUT_STATES, MERCHANT_PERMISSIONS } from 'const';
import {
    CloudOutlined,
    FavoriteOutlined,
    EventBusyOutlined,
    SpeakerNotes,
    CheckCircle,
    AccessTime,
    NotificationImportantOutlined
} from '@material-ui/icons';
import PhoneFormat from 'components/phoneFormat';
import { getFullName } from 'utils/naming';
import { red } from '@material-ui/core/colors';
import { DEFAULT_COLOR, getBookingServiceColor } from 'utils/color';
import hexToRgba from 'hex-to-rgba';
import { useIsHavingInternalPermission, useIsHavingPermission } from 'hooks/auth';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PaymentIcon from '@material-ui/icons/CreditCardTwoTone';
import { useFindBookingId, useRatio } from 'hooks/calendar';
import scrollIntoView from 'scroll-into-view-if-needed';
import { useMerchantTime12 } from 'hooks/merchant';
import { useServiceDetailById } from 'hooks/service';
import { useStaffById } from 'hooks/staff';
import { useDispatch } from 'react-redux';
import * as calendarActions from 'views/calender/actions';

const useStyles = makeStyles((theme) => ({
    root: {},
    noSelect: {
        userSelect: 'none'
    },
    timeBooking: {
        fontSize: 11,
        [theme.breakpoints.down('xs')]: {
            fontSize: 9
        }
    },
    bookingTitle: {
        [theme.breakpoints.down('xs')]: {
            fontSize: 13
        }
    },
    fullWidth: {
        width: '100%'
    },
    controls: {
        position: 'absolute',
        top: 2,
        right: 2,
        width: 'auto',
        '& svg': {
            width: 16,
            height: 16,
            [theme.breakpoints.down('sm')]: {
                width: 13,
                height: 13
            }
        }
    },
    dialog: {
        padding: 0
    },
    popoverPaper: {
        borderRadius: 0
    },
    fullScreen: {
        '& .MuiPopover-paper': {
            top: `0 !important`,
            left: `0 !important`,
            width: '100vw',
            height: '100vh',
            minWidth: '100%',
            minHeight: '100%',
            maxWidth: '100%',
            maxHeight: '100%'
        }
    },
    arrived: {
        width: 16,
        height: 16,
        color: theme.colors.darkGreen,
        '& path': {
            stroke: theme.palette.common.white
        }
    },

    buttonProgress: {
        color: theme.palette.common.white,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -4,
        marginLeft: -4
    }
}));

function Booking({
    isMobile,
    order,
    staffId,
    height,
    onReBooking,
    onReSchedule,
    isDragHighlight,
    isDisabledTooltip,
    onOpenBooking,
    onCloseBooking
}) {
    const classes = useStyles();
    const isHavingDepositPermission = useIsHavingPermission(MERCHANT_PERMISSIONS.DEPOSIT);
    const isHavingDepositAmountPermission = useIsHavingPermission(MERCHANT_PERMISSIONS.DEPOSIT_AMOUNT);
    const isHavingInternalPermission = useIsHavingInternalPermission();

    const findBookingId = useFindBookingId();
    const TIME_12 = useMerchantTime12();
    const serviceDetail = useServiceDetailById(order?.serviceId);
    const staff = useStaffById(staffId);
    const [isSending, setIsSending] = React.useState(false);
    const dispatch = useDispatch();

    const isHaveDepositPermission = React.useMemo(() => {
        return isHavingDepositPermission || isHavingDepositAmountPermission;
    }, [isHavingDepositPermission, isHavingDepositAmountPermission]);

    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const RATIO = useRatio();

    console.log('re-render Booking');

    const handleClick = React.useCallback(
        (event) => {
            onOpenBooking && onOpenBooking();
            setAnchorEl(event.currentTarget);
        },
        [onOpenBooking]
    );

    const handleClose = React.useCallback(
        (event) => {
            setAnchorEl(null);
            onCloseBooking && onCloseBooking();
        },
        [onCloseBooking]
    );

    const open = Boolean(anchorEl);
    const id = open ? `booking-popover` : undefined;

    const noShowCount = React.useMemo(() => {
        return order?.booking?.client?.noShowCount;
    }, [order]);

    const isHasNoShowCount = React.useMemo(() => {
        return noShowCount ? true : false;
    }, [noShowCount]);

    const handleReSchedule = React.useCallback(
        (e, booking) => {
            onReSchedule && onReSchedule(e, booking);
            const $bkSvs = document.querySelectorAll('[id^="drag_bkSv_"]');

            for (const $item of $bkSvs) {
                $item.style.pointerEvents = 'none';
                $item.style.opacity = `0.7`;
            }
            handleClose();
        },
        [handleClose, onReSchedule]
    );

    const handleReBooking = React.useCallback(
        (e, booking) => {
            onReBooking && onReBooking(e, booking);
            const $bkSvs = document.querySelectorAll('[id^="drag_bkSv_"]');

            for (const $item of $bkSvs) {
                $item.style.pointerEvents = 'none';
                $item.style.opacity = `0.7`;
            }
            handleClose();
        },
        [handleClose, onReBooking]
    );

    const isWarning = React.useMemo(() => {
        const stfSvIds = staff?.staffServices?.map((sv) => +sv?.serviceId);
        return !stfSvIds?.includes(+serviceDetail?.id);
    }, [serviceDetail, staff]);

    React.useEffect(() => {
        if (findBookingId && +order?.booking?.id === +findBookingId) {
            const $element = document.getElementById(`booking_service_scroll_${order?.id}`);
            scrollIntoView($element, {
                behavior: `instant`,
                block: 'center'
            });
        }
    }, [findBookingId, order]);

    const handleSendNotification = ({ bookingService }) => {
        setIsSending(true);

        dispatch(
            calendarActions.sendStaffNotification({
                body: {
                    staffId: +bookingService?.staffId,
                    bkSvId: +bookingService?.id
                },
                successCallback: () => {
                    setIsSending(false);
                    console.log('send notification success');
                },
                errorCallback: () => {
                    setIsSending(false);
                    console.log('send notification failed');
                }
            })
        );
    };

    return (
        <>
            {!isDisabledTooltip ? (
                <Tooltip
                    color={theme.colors.background}
                    enterDelay={1000}
                    enterNextDelay={1000}
                    title={
                        <Grid
                            style={{
                                width: '100%',
                                height: '100%',
                                padding: 8,
                                minWidth: 300
                            }}
                            container
                            direction="column"
                            wrap="nowrap"
                            spacing={1}
                        >
                            <Grid item>
                                <Grid container direction="row" alignItems="center">
                                    <Grid item>
                                        <Typography variant="body2" align="left">
                                            {`${
                                                TIME_12
                                                    ? mapTimeToTime12WithoutAMPm(
                                                          convertFloatingToDateTime(order.startTime)
                                                      )
                                                    : convertFloatingToDateTime(order.startTime)
                                            } - ${
                                                TIME_12
                                                    ? mapTimeToTime12WithoutAMPm(
                                                          convertDurationAndStartTimeToDate(
                                                              order.startTime,
                                                              height * (1 / RATIO)
                                                          )
                                                      )
                                                    : convertDurationAndStartTimeToDate(
                                                          order.startTime,
                                                          height * (1 / RATIO)
                                                      )
                                            }`}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction="column" spacing={1}>
                                    <Grid item>
                                        <Grid container alignItems="center">
                                            <Grid item>
                                                <Typography variant="body1" align="left">
                                                    {order.title} {order?.booking?.client?.block ? `🚫` : ``}
                                                </Typography>
                                            </Grid>
                                            {[BOOKING_TYPES.NO_REFERENCE, BOOKING_TYPES.ONLINE].includes(
                                                order?.booking?.bookingType
                                            ) && (
                                                <Grid item style={{ display: 'flex', marginLeft: 4 }}>
                                                    <CloudOutlined
                                                        style={{ width: 20, height: 20, color: theme.colors.black }}
                                                    />
                                                </Grid>
                                            )}
                                            {order?.love && (
                                                <Grid item style={{ display: 'flex', marginLeft: 4 }}>
                                                    <FavoriteOutlined
                                                        style={{ width: 20, height: 20, color: red[500] }}
                                                    />
                                                </Grid>
                                            )}
                                            {isHasNoShowCount && (
                                                <Grid item style={{ display: 'flex', marginLeft: 4 }}>
                                                    <EventBusyOutlined
                                                        style={{
                                                            width: 20,
                                                            height: 20,
                                                            color: theme.colors.red
                                                        }}
                                                    />
                                                    <Typography
                                                        variant="body2"
                                                        display="inline"
                                                        style={{ color: theme.colors.red, fontSize: 13 }}
                                                    >
                                                        x{noShowCount}
                                                    </Typography>
                                                </Grid>
                                            )}

                                            {isHaveDepositPermission && order?.paidPrice && (
                                                <Grid item style={{ display: 'flex' }}>
                                                    <Chip
                                                        icon={<PaymentIcon />}
                                                        style={{
                                                            background: theme.colors.template
                                                        }}
                                                        color="primary"
                                                        size="small"
                                                        label={`Deposit $${order?.paidPrice}`}
                                                    />
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                    {order?.booking?.client?.phone && (
                                        <Grid item>
                                            <PhoneFormat bold number={order?.booking?.client?.phone} />
                                        </Grid>
                                    )}
                                    {order?.booking?.client?.note && (
                                        <Grid item>
                                            <Typography
                                                align="left"
                                                style={{ color: 'rgb(33, 43, 54)' }}
                                                variant="caption"
                                            >
                                                Note: {order?.booking?.client?.note}
                                            </Typography>
                                        </Grid>
                                    )}
                                    <Grid item>
                                        <Typography align="left" variant="body2">
                                            {serviceDetail?.title ? serviceDetail?.title : '❌ service'} with{' '}
                                            {isWarning ? `⚠️` : ''} {getFullName(staff)}
                                        </Typography>
                                    </Grid>

                                    <Grid item>
                                        {[BOOKING_SERVICE_STATES.ARRIVED, BOOKING_SERVICE_STATES.PICKED].includes(
                                            order?.status
                                        ) && (
                                            <Grid item>
                                                <Chip
                                                    icon={<CheckCircle />}
                                                    style={{
                                                        background: theme.colors.darkGreen
                                                    }}
                                                    size="small"
                                                    color="primary"
                                                    label="Arrived"
                                                />
                                            </Grid>
                                        )}
                                        {[BOOKING_SERVICE_STATES.SERVING]?.includes(order?.status) && (
                                            <Grid item>
                                                <Chip
                                                    icon={
                                                        <AccessTime
                                                            size="small"
                                                            style={{
                                                                width: '14px',
                                                                height: 'auto',
                                                                color: theme.palette.common.white
                                                            }}
                                                        />
                                                    }
                                                    style={{
                                                        background: theme.colors.pink
                                                    }}
                                                    size="small"
                                                    color="primary"
                                                    label="Serving"
                                                />
                                            </Grid>
                                        )}

                                        {[BOOKING_CHECKOUT_STATES.DONE].includes(order?.booking.paid) && (
                                            <Grid item>
                                                <Chip
                                                    icon={<AttachMoneyIcon />}
                                                    style={{
                                                        background: theme.colors.template
                                                    }}
                                                    color="primary"
                                                    size="small"
                                                    label="Paid"
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                    {order?.booking?.note && (
                                        <>
                                            <Grid
                                                item
                                                style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }}
                                            >
                                                <Divider />
                                            </Grid>
                                            <Grid item>
                                                <Grid container wrap="nowrap" direction="row" spacing={1}>
                                                    <Grid item style={{ display: 'flex' }}>
                                                        <SpeakerNotes
                                                            style={{
                                                                width: '20px',
                                                                height: '20px',
                                                                color: theme.palette.common.black
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography
                                                            style={{ color: 'rgb(33, 43, 54)' }}
                                                            variant="caption"
                                                            align="left"
                                                        >
                                                            {order?.booking?.note}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    placement="right"
                >
                    <Grid
                        id={`booking_service_scroll_${order?.id}`}
                        style={{
                            width: '100%',
                            height,
                            overflow: 'hidden',
                            background: isDragHighlight
                                ? theme.colors.template
                                : findBookingId && +findBookingId === +order?.booking?.id
                                ? theme.colors.portage
                                : order?.status === BOOKING_SERVICE_STATES.NO_SHOW
                                ? `repeating-linear-gradient(45deg, ${alpha(theme.bkSv?.[order?.status], 0.5)} 2px, ${
                                      theme.bkSv?.[order?.status]
                                  } 10px)`
                                : getBookingServiceColor({
                                      bookingServiceState: order?.status,
                                      defaultColor: hexToRgba(serviceDetail?.category?.color || DEFAULT_COLOR, 0.7),
                                      theme
                                  }),

                            // marginLeft: 1,
                            // boxSizing: 'border-box',
                            borderRadius: theme.radius(1),
                            borderLeft: `2px solid ${
                                isDragHighlight
                                    ? theme.colors.template
                                    : findBookingId && +findBookingId === +order?.booking?.id
                                    ? theme.colors.portage
                                    : getBookingServiceColor({
                                          bookingServiceState: order?.status,
                                          defaultColor: serviceDetail?.category?.color || DEFAULT_COLOR,
                                          theme
                                      })
                            }`,
                            paddingLeft: 4,
                            // paddingTop: 4,
                            opacity: 1,
                            position: 'relative'
                            // border: open ? `1px solid ${theme.palette.primary.main}` : ``
                        }}
                        className={classes.noSelect}
                        container
                        direction="column"
                        wrap="nowrap"
                        onClick={handleClick}
                    >
                        <Grid item>
                            <Typography variant="body2" className={classes.timeBooking} align="left">
                                {`${
                                    TIME_12
                                        ? mapTimeToTime12WithoutAMPm(convertFloatingToDateTime(order.startTime))
                                        : convertFloatingToDateTime(order.startTime)
                                } - ${
                                    TIME_12
                                        ? mapTimeToTime12WithoutAMPm(
                                              convertDurationAndStartTimeToDate(order.startTime, height * (1 / RATIO))
                                          )
                                        : convertDurationAndStartTimeToDate(order.startTime, height * (1 / RATIO))
                                }`}
                            </Typography>
                            <Grid className={classes.controls} container alignItems="center" direction="row">
                                {[BOOKING_TYPES.NO_REFERENCE, BOOKING_TYPES.ONLINE].includes(
                                    order?.booking?.bookingType
                                ) && (
                                    <Grid item style={{ display: 'flex' }}>
                                        <CloudOutlined style={{ color: theme.colors.black }} />
                                    </Grid>
                                )}
                                {order?.love && (
                                    <Grid item style={{ display: 'flex', marginLeft: 1 }}>
                                        <FavoriteOutlined style={{ color: red[500] }} />
                                    </Grid>
                                )}
                                {isHasNoShowCount && (
                                    <Grid item style={{ display: 'flex', position: 'relative', marginLeft: 1 }}>
                                        <EventBusyOutlined style={{ color: theme.colors.red }} />
                                        <span
                                            style={{
                                                color: theme.colors.red,
                                                fontSize: 10,
                                                fontWeight: 700,
                                                position: 'absolute',
                                                bottom: -12,
                                                right: -2
                                            }}
                                        >
                                            {noShowCount}
                                        </span>
                                    </Grid>
                                )}
                                {order?.booking?.note && (
                                    <Grid item style={{ display: 'flex', marginLeft: 1 }}>
                                        <SpeakerNotes fontSize="small" style={{ color: theme.colors.black }} />
                                    </Grid>
                                )}

                                {isHaveDepositPermission && order?.paidPrice > 0 && (
                                    <Grid item style={{ display: 'flex', marginLeft: 1 }}>
                                        <PaymentIcon style={{ color: theme.colors.template }} />
                                    </Grid>
                                )}
                                {[BOOKING_SERVICE_STATES.ARRIVED, BOOKING_SERVICE_STATES.PICKED].includes(
                                    order?.status
                                ) && (
                                    <Grid
                                        item
                                        style={{
                                            display: 'flex',
                                            padding: `0px 2px`,
                                            borderRadius: 20,
                                            background: theme.colors.darkGreen,
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                fontSize: 8,
                                                color: theme.palette.common.white,
                                                fontWeight: 'bold'
                                            }}
                                            variant="body2"
                                        >
                                            Arrived
                                        </Typography>
                                    </Grid>
                                )}
                                {[BOOKING_SERVICE_STATES.SERVING]?.includes(order?.status) && (
                                    <Grid
                                        item
                                        style={{
                                            display: 'flex',
                                            padding: `0px 2px`,
                                            borderRadius: 20,
                                            background: theme.colors.pink,
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                fontSize: 8,
                                                color: theme.palette.common.white,
                                                fontWeight: 'bold'
                                            }}
                                        >
                                            Serving
                                        </Typography>
                                    </Grid>
                                )}

                                {[BOOKING_CHECKOUT_STATES.DONE].includes(order?.booking.paid) && (
                                    <Grid
                                        item
                                        style={{
                                            display: 'flex',
                                            width: '14px',
                                            height: '14px',
                                            borderRadius: 20,
                                            background: theme.colors.template,
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <AttachMoneyIcon
                                            size="small"
                                            style={{ width: '10px', height: 'auto', color: theme.palette.common.white }}
                                        />
                                    </Grid>
                                )}

                                {isHavingInternalPermission &&
                                    [BOOKING_SERVICE_STATES.ARRIVED, BOOKING_SERVICE_STATES.SERVING].includes(
                                        order?.status
                                    ) && (
                                        <IconButton
                                            style={{
                                                background: theme.colors.darkBlue,
                                                width: 14,
                                                height: 14,
                                                padding: 4
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                handleSendNotification({ bookingService: order });
                                            }}
                                        >
                                            {isSending ? (
                                                <CircularProgress size={4} className={classes.buttonProgress} />
                                            ) : (
                                                <NotificationImportantOutlined
                                                    style={{ color: theme.palette.common.white, width: 12, height: 12 }}
                                                />
                                            )}
                                        </IconButton>
                                    )}
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container direction="column">
                                <Grid item className={classes.fullWidth}>
                                    <Grid container alignItems="center">
                                        <Grid item>
                                            <Typography className={classes.bookingTitle} variant="body1" align="left">
                                                {order.title} {order?.booking?.client?.block ? `🚫` : ``}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item className={classes.fullWidth}>
                                    <Typography className={classes.bookingTitle} align="left" variant="body2">
                                        {serviceDetail?.title ? serviceDetail?.title : '❌ service'} with{' '}
                                        {isWarning ? `⚠️` : ''} {getFullName(staff)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <Grid
                            item
                            style={{
                                position: 'absolute',
                                bottom: 2,
                                right: 2
                            }}
                        >
                            
                        </Grid> */}
                    </Grid>
                </Tooltip>
            ) : (
                <Grid
                    style={{
                        width: '100%',
                        height: 'auto',
                        overflow: 'hidden',
                        background: theme.colors.template,
                        // marginLeft: 1,
                        // boxSizing: 'border-box',
                        borderRadius: 4,
                        borderLeft: `5px solid ${theme.colors.template}`,
                        paddingLeft: 4,
                        // paddingTop: 4,
                        opacity: 1,
                        position: 'relative'

                        // border: open ? `1px solid ${theme.palette.primary.main}` : ``
                    }}
                    className={classes.noSelect}
                    container
                    direction="column"
                    wrap="nowrap"
                    onClick={handleClick}
                >
                    <Grid item>
                        <Typography
                            id={`booking_${order?.id}_title`}
                            variant="body2"
                            className={classes.timeBooking}
                            align="left"
                        >
                            {`${
                                TIME_12
                                    ? mapTimeToTime12WithoutAMPm(convertFloatingToDateTime(order.startTime))
                                    : convertFloatingToDateTime(order.startTime)
                            } - ${
                                TIME_12
                                    ? mapTimeToTime12WithoutAMPm(
                                          convertDurationAndStartTimeToDate(order.startTime, height * (1 / RATIO))
                                      )
                                    : convertDurationAndStartTimeToDate(order.startTime, height * (1 / RATIO))
                            }`}
                        </Typography>
                        <Grid className={classes.controls} container alignItems="center" direction="row">
                            {[BOOKING_TYPES.NO_REFERENCE, BOOKING_TYPES.ONLINE].includes(
                                order?.booking?.bookingType
                            ) && (
                                <Grid item style={{ display: 'flex' }}>
                                    <CloudOutlined style={{ color: theme.colors.black }} />
                                </Grid>
                            )}
                            {order?.love && (
                                <Grid item style={{ display: 'flex' }}>
                                    <FavoriteOutlined style={{ color: red[500] }} />
                                </Grid>
                            )}
                            {isHasNoShowCount && (
                                <Grid item style={{ display: 'flex', position: 'relative' }}>
                                    <EventBusyOutlined style={{ color: theme.colors.red }} />
                                    <span
                                        style={{
                                            color: theme.colors.red,
                                            fontSize: 10,
                                            fontWeight: 700,
                                            position: 'absolute',
                                            bottom: -12,
                                            right: -2
                                        }}
                                    >
                                        {noShowCount}
                                    </span>
                                </Grid>
                            )}
                            {order?.booking?.note && (
                                <Grid item style={{ display: 'flex' }}>
                                    <SpeakerNotes fontSize="small" style={{ color: theme.colors.black }} />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction="column">
                            <Grid item className={classes.fullWidth}>
                                <Grid container alignItems="center">
                                    <Grid item>
                                        <Typography className={classes.bookingTitle} variant="body1" align="left">
                                            {order.title} {order?.booking?.client?.block ? `🚫` : ``}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item className={classes.fullWidth}>
                                <Typography
                                    id={`booking_${order?.id}_subtitle`}
                                    className={classes.bookingTitle}
                                    align="left"
                                    variant="body2"
                                >
                                    {serviceDetail?.title ? serviceDetail?.title : '❌ service'} with{' '}
                                    {isWarning ? `⚠️` : ''} {getFullName(staff)}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}

            {isMobile ? (
                <Dialog fullScreen className={classes.dialog} open={open} onClose={handleClose}>
                    <AddBooking
                        isEdit={true}
                        onClose={handleClose}
                        booking={order?.booking}
                        staff={staff}
                        isDialog={true}
                        onReSchedule={handleReSchedule}
                        onReBooking={handleReBooking}
                    />
                </Dialog>
            ) : (
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    className={isMobile ? classes.fullScreen : ''}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'right'
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'left'
                    }}
                    classes={{ paper: classes.popoverPaper }}
                >
                    <AddBooking
                        isEdit={true}
                        onClose={handleClose}
                        booking={order?.booking}
                        staff={staff}
                        onReSchedule={handleReSchedule}
                        onReBooking={handleReBooking}
                    />
                </Popover>
            )}
        </>
    );
}

export default React.memo(Booking);
