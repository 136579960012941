import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import AppBar from '@material-ui/core/AppBar';

const useStyles = makeStyles((theme) => ({
    root: {}
}));

function PerformanceTabs() {
    const classes = useStyles();
    const [tabIndex, setTabIndex] = React.useState(0);

    return (
        <AppBar component="div" className={classes.secondaryBar} position="static" elevation={0}>
            <Tabs value={tabIndex} onChange={(e, index) => setTabIndex(index)} textColor="inherit">
                <Tab textColor="inherit" label="Booking Report" />
                <Tab textColor="inherit" label="Stratergy Suggestion" />
            </Tabs>
        </AppBar>
    );
}

export default React.memo(PerformanceTabs);
