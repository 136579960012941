import { useSelector } from 'react-redux';
import { getFullName } from 'utils/naming';
import { getFriendlyShortTime } from 'utils/timing';
import { GIFT_CODE_PAYMENT_TYPES } from 'const';

export function useVouchers() {
    return useSelector((state) => state?.voucher?.list);
}

export function useVouchersList() {
    return useSelector((state) => state?.voucher?.list?.filter((item) => !item?.deleted));
}

export function useIsFetching() {
    return useSelector((state) => state?.voucher?.isFetching);
}

export function useVoucherCodes() {
    return useSelector((state) => state?.voucher?.voucherCodes);
}

export function useVoucherCodesList() {
    return useSelector((state) => state?.voucher?.voucherCodes?.filter((item) => !item?.deleted));
}

export function useVoucherCode() {
    return useSelector((state) => state?.voucher?.voucherCode);
}

export function useVoucherMapping() {
    return useSelector((state) => {
        const vouchers = state?.voucher?.list?.filter((item) => !item?.deleted);
        return vouchers?.reduce((obj, i) => {
            obj[i?.id] = i;
            return obj;
        }, {});
    });
}

export function useVoucherById(vId) {
    return useSelector((state) => {
        const vouchers = state?.voucher?.list?.filter((item) => !item?.deleted);
        return vouchers?.reduce((obj, i) => {
            obj[i?.id] = i;
            return obj;
        }, {})?.[vId];
    });
}

export function useGiftHistoriesByGiftId({ giftId }) {
    return useSelector((state) => {
        const giftHistory = state?.voucher?.giftHistory;
        return (
            giftHistory?.[giftId]?.histories?.map((giftCode) => {
                return {
                    ...giftCode,
                    method: GIFT_CODE_PAYMENT_TYPES?.[giftCode?.paymentType],
                    buyer: getFullName(giftCode?.client),
                    remainingValue: `$${giftCode?.remainingValue}`,
                    retailPrice: `$${giftCode?.retailPrice}`,
                    purchaseAt: getFriendlyShortTime(giftCode?.purchaseAt),
                    status: giftCode?.deleted ? 'Disabled' : 'Active'
                };
            }) || []
        );
    });
}
