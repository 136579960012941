import request from './request';

const merchantApis = {
    merchant: (id) => `/merchants/${id}`,
    updateMerchantNormal: (id) => `/merchants/${id}/normal`,
    merchants: `/merchants`,
    upload: (merchantId) => `/merchants/${merchantId}/avatar/upload`,
    uploadPolicy: (merchantId) => `/merchants/${merchantId}/policy/upload`,
    checkPassword: `/merchants/check-password`,
    otherMerchants: `/group-merchant/others`,
    timeSlots: (slug) => `/staff-schedules/time-slots/${slug}`,
    updatePassword: `/merchants/update-password`
};

export async function getMerchantInfoApi(merchantId) {
    const response = await request.get(merchantApis.merchant(merchantId));
    return response.data;
}

export async function fetchMerchantsApi() {
    const response = await request.get(merchantApis.merchants);
    return response.data;
}

export async function createMerchantApi(body) {
    const response = await request.post(merchantApis.merchants, body);
    return response.data;
}

export async function updateMerchantApi({ merchantId, body }) {
    const response = await request.put(merchantApis.merchant(merchantId), body);
    return response.data;
}

export async function uploadAvatarApi({ file, merchantId }) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    const response = await request.put(merchantApis.upload(merchantId), formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
}

export async function uploadPolicyApi({ file, merchantId }) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    const response = await request.put(merchantApis.uploadPolicy(merchantId), formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
}

export async function updateNormalMerchantApi({ merchantId, body }) {
    const response = await request.put(merchantApis.updateMerchantNormal(merchantId), body);
    return response.data;
}

export async function checkPasswordApi({ body }) {
    const response = await request.post(merchantApis.checkPassword, body);
    return response.data;
}

export async function getOtherMerchantsApi() {
    const response = await request.get(merchantApis.otherMerchants);
    return response.data;
}

export async function getMerchantTimeSlotsApi({ query, slug }) {
    let extraUrl = merchantApis.timeSlots(slug);
    const response = await request.post(extraUrl, query);
    return response.data;
}

export async function changePasswordApi(data) {
    const response = await request.put(merchantApis.updatePassword, data);
    return response.data;
}
