import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Box, Grid, Typography, IconButton, Dialog } from '@material-ui/core';
import { TIMEZONE } from 'utils/timing';
import moment from 'moment-timezone';
import { useMerchantDetail } from 'hooks/merchant';
import orderBy from 'lodash/orderBy';
import BookingService from './BookingService';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import UpdateCheckInBooking from './form';
import useMediaQueries from 'hooks/useMediaQueries';
import * as calendarActions from 'views/calender/actions';
import { useDispatch } from 'react-redux';
import { BOOKING_ACTIONS, CONFIRM_TYPES } from 'const';
import Tooltip from 'components/tooltip';
import ConfirmDialog from 'components/dialog/Confirm';
import PhoneFormat from 'components/phoneFormat';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        background: theme.colors.background,
        borderRadius: theme.radius(3),
        maxWidth: 300
    },
    wrapper: {
        position: 'relative'
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    }
}));

function Booking({ booking, number }) {
    const classes = useStyles();
    const theme = useTheme();
    const merchant = useMerchantDetail();
    const [openEdit, setIsOpenEdit] = React.useState(false);
    const { isMobile } = useMediaQueries();
    const [open, setOpen] = React.useState(false);
    const dispatch = useDispatch();
    const [isDeclining, setIsDeclining] = React.useState(false);

    const checkInTime = React.useMemo(() => {
        return moment(booking?.createdAt)
            .tz(merchant?.timezone || TIMEZONE)
            ?.format('h:mm A');
    }, [booking, merchant]);

    const bookingServices = React.useMemo(() => {
        return orderBy(booking?.bookingServices, ['startTime', 'asc']);
    }, [booking]);

    const handleClick = React.useCallback(() => {
        setIsOpenEdit(true);
    }, []);

    const handleClose = React.useCallback(() => {
        setIsOpenEdit(false);
    }, []);

    const handleAgreeDecline = React.useCallback(() => {
        setIsDeclining(true);
        dispatch(
            calendarActions.cancelBooking({
                bookingId: booking.id,
                body: {
                    state: BOOKING_ACTIONS.DECLINE
                },
                successCallback: () => {},
                errorCallback: () => {
                    setIsDeclining(false);
                    console.log('update booking failed');
                }
            })
        );
    }, [booking, dispatch]);

    return (
        <Box className={classes.root}>
            <Grid container direction="column" spacing={2}>
                <Grid item>
                    <Grid container direction="row" alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <Grid container direction="row" spacing={1} alignItems="center">
                                <Grid
                                    item
                                    style={{
                                        borderRadius: '50%',
                                        width: 24,
                                        height: 24,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        background: number === 0 ? theme.colors.brightSun : theme.colors.darkGreen,
                                        fontWeight: 'bold',
                                        color: theme.palette.common.white
                                    }}
                                >
                                    {number}
                                </Grid>
                                <Grid item>
                                    <Typography variant="body1">{booking?.title}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography variant="caption">
                                Check-in: <strong>{checkInTime}</strong>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item>
                    <Grid container direction="row" alignItems="center" spacing={1}>
                        <Grid item>
                            <Typography variant="body1">Phone: </Typography>
                        </Grid>
                        <Grid item>
                            <PhoneFormat number={booking?.client?.phone} />
                        </Grid>
                    </Grid>
                </Grid>
                {booking?.note && (
                    <Grid item>
                        <Typography display="inline" variant="body1">
                            Note:{' '}
                        </Typography>{' '}
                        <Typography display="inline" variant="body2">
                            {booking?.note}
                        </Typography>
                    </Grid>
                )}
                <Grid item>
                    <Grid container direction="column" spacing={1}>
                        {bookingServices?.map((bkSv) => {
                            return (
                                <Grid item key={bkSv?.id} className={classes.bkSvItem}>
                                    <BookingService {...bkSv} />
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>

                <Grid item>
                    <Grid container spacing={1} alignItems="center" direction="row" justifyContent="flex-end">
                        <Grid item>
                            <IconButton disabled={isDeclining} onClick={handleClick} size="small">
                                <EditIcon fontSize="small" />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <Tooltip placement="top" title="Decline">
                                <IconButton
                                    color="secondary"
                                    disabled={isDeclining}
                                    onClick={() => setOpen(true)}
                                    size="small"
                                >
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Dialog fullScreen={isMobile} maxWidth={false} open={openEdit} onClose={handleClose}>
                <UpdateCheckInBooking
                    isEdit={true}
                    onClose={handleClose}
                    booking={booking}
                    staffId={bookingServices?.[0]?.staffId}
                    isDialog={true}
                />
            </Dialog>

            <ConfirmDialog
                open={open}
                title={`Decline ${booking?.title} booking?`}
                text={`Do you want to decline <strong>${booking?.title}</strong>'s booking?`}
                onAgree={handleAgreeDecline}
                onDisagree={() => setOpen(false)}
                type={CONFIRM_TYPES.ERROR}
                isLoading={isDeclining}
            />
        </Box>
    );
}

export default Booking;
