import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, CircularProgress, IconButton } from '@material-ui/core';
import DialogTitle from 'components/dialog/Title';
import DialogActions from 'components/dialog/Action';
import DialogContent from 'components/dialog/Content';
import useMediaQueries from 'hooks/useMediaQueries';
import NativeSelect from 'components/select/NativeForm';
import { repeatOptions, endRepeatOptions } from 'data/time';
import {
    getSuggestionDateEnd,
    getDateOfWeek,
    isAfter,
    convertTimeToFloat,
    getDatetimeFormat,
    generateOptionsMinutes,
    mapTimeToTime12,
    getAppendMerchantTimezoneToDate,
    getFriendlyDate,
    getNativeDate,
    getNumRangeTimeStart,
    getNumRangeTimeEnd,
    addHours,
    generateDateFormatted
} from 'utils/timing';
import * as staffWorkingHourActions from './action';
import { useDispatch } from 'react-redux';
import PortalDatePicker from 'components/datepicker/PortalReactDatePickerForm';
import { useTranslation } from 'react-i18next';
import { STAFF_WORKING_HOURS_REPEAT_TYPES } from 'const';
import { checkCollisionsApi } from 'services/staff';
import UpcomingConfirm from './UpComingConfirm';
import { getFullName } from 'utils/naming';
import CloseIcon from '@material-ui/icons/Close';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import Button from 'components/button/Base';
import { useSelectedDate } from 'hooks/calendar';
import { useMerchantTime12 } from 'hooks/merchant';

const timeOptions = generateOptionsMinutes(15, 360).map((time, index) => ({
    label: time,
    value: time,
    id: index
}));

const useStyles = makeStyles((theme) => ({
    root: {},
    title: {},
    btnSubmit: {
        position: 'relative'
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    fullWidth: {
        width: '100%',
        position: 'relative'
    },
    closeButton: {
        position: 'absolute',
        zIndex: 20,
        right: -10,
        display: 'flex',
        top: -25,
        cursor: 'pointer'
    },

    spacing: {
        '&:not(:last-child)': {
            marginBottom: theme.spacing(2)
        }
    },

    content: {
        padding: `${theme.spacing(3)}px !important`,
        minWidth: 450,
        [theme.breakpoints.down('sm')]: {
            minWidth: 300,
            padding: `${theme.spacing(1)}px !important`
        }
    },
    mt3: {
        marginTop: theme.spacing(3)
    },
    expand: {
        flex: 1
    },
    whiteColor: {
        color: theme.palette.common.white
    }
}));

function isNoRepeat(schedule) {
    return schedule?.dateEnd === schedule?.dateStart;
}

function isOnGoing(schedule) {
    return schedule?.dateEnd === null;
}

function getFirstRange(schedule) {
    const scheduleRangeFirst = schedule?.scheduleRangeFirst;
    if (!scheduleRangeFirst) return;
    return {
        timeStart: getNumRangeTimeStart(scheduleRangeFirst),
        timeEnd: getNumRangeTimeEnd(scheduleRangeFirst)
    };
}

function getSecondRange(schedule) {
    const scheduleRangeSecond = schedule?.scheduleRangeSecond;
    if (!scheduleRangeSecond || scheduleRangeSecond === '[]') return;
    return {
        timeStart: getNumRangeTimeStart(scheduleRangeSecond),
        timeEnd: getNumRangeTimeEnd(scheduleRangeSecond)
    };
}

function getDefaultDateEnd({ schedule, date }) {
    const { dateEnd, dateStart } = schedule;
    if (dateEnd === dateStart || dateEnd === null) {
        return getSuggestionDateEnd({ dateOfWeek: date.dateOfWeek, date: getNativeDate(date?.date) });
    }

    return new Date(getAppendMerchantTimezoneToDate(schedule.dateEnd));
}

function WorkingHourForm({
    isEdit,
    schedule,
    user,
    date,
    handleClose,
    onRefreshCurrentDateSchedule,
    onCreateDone,
    isCalendar
}) {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const selectedDate = useSelectedDate();
    const TIME_12 = useMerchantTime12();

    date = React.useMemo(() => {
        return isCalendar ? generateDateFormatted(selectedDate) : date;
    }, [date, selectedDate, isCalendar]);

    const dispatch = useDispatch();
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [isOpenConfirmDeleteUpComing, setIsOpenConfirmDeleteUpComing] = React.useState(false);
    const [isOpenConfirmUpdateUpComing, setIsOpenConfirmUpdateUpComing] = React.useState(false);
    const [isOpenConfirmCreateUpComing, setIsOpenConfirmCreateUpComing] = React.useState(false);
    const { isMobile } = useMediaQueries();

    const timeOptionsWithFormat = React.useMemo(() => {
        return TIME_12
            ? timeOptions.map((time, index) => {
                  return {
                      ...time,
                      label: index === timeOptions?.length - 1 ? `00:00` : mapTimeToTime12(time.value)
                  };
              })
            : timeOptions;
    }, [TIME_12]);

    const [repeat, setRepeat] = React.useState(
        isEdit
            ? isNoRepeat(schedule)
                ? STAFF_WORKING_HOURS_REPEAT_TYPES.NO_REPEAT
                : STAFF_WORKING_HOURS_REPEAT_TYPES.WEEKLY
            : STAFF_WORKING_HOURS_REPEAT_TYPES.NO_REPEAT
    );
    const [repeatType, setRepeatType] = React.useState(
        isEdit
            ? isOnGoing(schedule)
                ? STAFF_WORKING_HOURS_REPEAT_TYPES.ON_GOING
                : STAFF_WORKING_HOURS_REPEAT_TYPES.SPECIFIC
            : STAFF_WORKING_HOURS_REPEAT_TYPES.ON_GOING
    );
    const [firstRange, setFirstRange] = React.useState(
        isEdit ? getFirstRange(schedule) : { timeStart: '09:00', timeEnd: '17:00' }
    );

    const [secondRange, setSecondRange] = React.useState(isEdit ? getSecondRange(schedule) : undefined);
    const [dateEnd, setDateEnd] = React.useState(
        isEdit
            ? getDefaultDateEnd({ schedule, date })
            : getSuggestionDateEnd({ dateOfWeek: date.dateOfWeek, date: getNativeDate(date?.date) })
    );

    const addTime = () => {
        const timeStart = addHours({ time: firstRange.timeEnd, numOfHours: 1 });

        const timeEnd = addHours({ time: timeStart, numOfHours: 4 });

        setSecondRange({ timeStart, timeEnd });
    };

    const handleRemoveSecondRange = () => {
        setSecondRange(false);
    };

    const handleTimeStart1stRangeChange = (e) => {
        setFirstRange({ ...firstRange, timeStart: e.target.value });
    };

    const handleTimeEnd1stRangeChange = (e) => {
        setFirstRange({ ...firstRange, timeEnd: e.target.value });
    };

    const handleTimeStart2ndRangeChange = (e) => {
        setSecondRange({ ...secondRange, timeStart: e.target.value });
    };

    const handleTimeEnd2ndRangeChange = (e) => {
        setSecondRange({ ...secondRange, timeEnd: e.target.value });
    };

    const handleRepeatChange = (e) => {
        setRepeat(e.target.value);
    };

    const handleRepeatTypeChange = (e) => {
        setRepeatType(e.target.value);
    };

    const isUpdateShift = React.useMemo(() => {
        if (!isEdit) return false;
        const rootFirstRange = getFirstRange(schedule);
        const rootSecondRange = getSecondRange(schedule);
        if (
            rootFirstRange?.timeStart !== firstRange?.timeStart ||
            rootFirstRange?.timeEnd !== firstRange?.timeEnd ||
            rootSecondRange?.timeStart !== secondRange?.timeStart ||
            rootSecondRange?.timeEnd !== secondRange?.timeEnd
        ) {
            return true;
        }
        return false;
    }, [firstRange, secondRange, schedule, isEdit]);

    const handleUpdateChecking = async () => {
        if (!isEdit) {
            if (repeat === STAFF_WORKING_HOURS_REPEAT_TYPES.NO_REPEAT) {
                handleCreate();
                return;
            } else {
                let collisionBody = {
                    staffId: Number(user?.id),
                    dateStart: date.date
                };
                if (repeatType === STAFF_WORKING_HOURS_REPEAT_TYPES.SPECIFIC) {
                    collisionBody = {
                        ...collisionBody,
                        dateEnd: getDatetimeFormat(dateEnd)
                    };
                }

                const res = await checkCollisionsApi(collisionBody);

                if (res?.schedules?.length === 0) {
                    handleCreate();
                    return;
                }

                setIsOpenConfirmCreateUpComing(true);
                return;
            }
        }

        if (repeat === STAFF_WORKING_HOURS_REPEAT_TYPES.NO_REPEAT) {
            handleUpdateStaffWorkingNoRepeat();
            return;
        } else {
            if (isNoRepeat(schedule)) {
                let collisionBody = {
                    staffId: +user?.id,
                    dateStart: date.date
                };
                if (repeatType === STAFF_WORKING_HOURS_REPEAT_TYPES.SPECIFIC) {
                    collisionBody = {
                        ...collisionBody,
                        dateEnd: getDatetimeFormat(dateEnd)
                    };
                }

                const res = await checkCollisionsApi(collisionBody);
                const collisionSchIds = res?.schedules?.map((sch) => sch?.id);

                if (
                    collisionSchIds.length === 0 ||
                    (collisionSchIds.length === 1 && collisionSchIds?.[0] === schedule?.id)
                ) {
                    if (repeatType === STAFF_WORKING_HOURS_REPEAT_TYPES.SPECIFIC) {
                        handleUpdateStaffWorkingSpecific();
                        return;
                    }
                    handleUpdateStaffWorkingOngoing();
                    return;
                }
                setIsOpenConfirmUpdateUpComing(true);
                return;
            }
        }
        setIsOpenConfirmUpdateUpComing(true);
        return;
    };

    const handleUpdateStaffWorkingSpecific = (updateUpComing) => {
        let updatedBody = {
            staffId: +user?.id,
            dateStart: date.date,
            dateEnd: getDatetimeFormat(dateEnd),
            scheduleRangeFirst: `[${convertTimeToFloat(firstRange?.timeStart)}, ${convertTimeToFloat(
                firstRange.timeEnd
            )}]`
        };

        if (secondRange) {
            updatedBody = {
                ...updatedBody,
                scheduleRangeSecond: `[${convertTimeToFloat(secondRange?.timeStart)}, ${convertTimeToFloat(
                    secondRange.timeEnd
                )}]`
            };
        } else {
            updatedBody = {
                ...updatedBody,
                scheduleRangeSecond: null
            };
        }
        setIsSubmitting(true);
        dispatch(
            staffWorkingHourActions.updateStaffWorkingHours({
                staffId: user?.id,
                updateUpComing,
                scheduleId: schedule?.id,
                body: updatedBody,
                successCallback: () => {
                    handleClose && handleClose();
                    onRefreshCurrentDateSchedule && onRefreshCurrentDateSchedule();
                },
                errorCallback: () => {
                    setIsSubmitting(false);
                }
            })
        );
    };

    const handleUpdateStaffWorkingOngoing = (updateUpComing) => {
        let updatedBody = {
            staffId: Number(user?.id),
            dateStart: date.date,
            dateEnd: null,
            scheduleRangeFirst: `[${convertTimeToFloat(firstRange?.timeStart)}, ${convertTimeToFloat(
                firstRange.timeEnd
            )}]`
        };

        if (secondRange) {
            updatedBody = {
                ...updatedBody,
                scheduleRangeSecond: `[${convertTimeToFloat(secondRange?.timeStart)}, ${convertTimeToFloat(
                    secondRange.timeEnd
                )}]`
            };
        } else {
            updatedBody = {
                ...updatedBody,
                scheduleRangeSecond: null
            };
        }
        setIsSubmitting(true);
        dispatch(
            staffWorkingHourActions.updateStaffWorkingHours({
                staffId: user?.id,
                updateUpComing,
                scheduleId: schedule?.id,
                body: updatedBody,
                successCallback: () => {
                    handleClose && handleClose();
                    onRefreshCurrentDateSchedule && onRefreshCurrentDateSchedule();
                },
                errorCallback: () => {
                    setIsSubmitting(false);
                }
            })
        );
    };

    const handleUpdateStaffWorkingNoRepeat = (updateUpComing) => {
        let updatedBody = {
            staffId: Number(user?.id),
            dateStart: date.date,
            dateEnd: date.date,
            scheduleRangeFirst: `[${convertTimeToFloat(firstRange?.timeStart)}, ${convertTimeToFloat(
                firstRange.timeEnd
            )}]`
        };

        if (secondRange) {
            updatedBody = {
                ...updatedBody,
                scheduleRangeSecond: `[${convertTimeToFloat(secondRange?.timeStart)}, ${convertTimeToFloat(
                    secondRange.timeEnd
                )}]`
            };
        } else {
            updatedBody = {
                ...updatedBody,
                scheduleRangeSecond: null
            };
        }
        setIsSubmitting(true);
        dispatch(
            staffWorkingHourActions.updateStaffWorkingHours({
                staffId: user?.id,
                updateUpComing,
                scheduleId: schedule?.id,
                body: updatedBody,
                successCallback: () => {
                    handleClose && handleClose();
                    onRefreshCurrentDateSchedule && onRefreshCurrentDateSchedule();
                },
                errorCallback: () => {
                    setIsSubmitting(false);
                }
            })
        );
    };

    const handleCreate = (params) => {
        const updateUpComing = params?.updateUpComing;

        let createScheduleBody = {
            staffId: Number(user?.id),
            dateStart: date.date,
            dateEnd:
                repeat === STAFF_WORKING_HOURS_REPEAT_TYPES.NO_REPEAT
                    ? date.date
                    : repeatType === STAFF_WORKING_HOURS_REPEAT_TYPES.ON_GOING
                    ? null
                    : getDatetimeFormat(dateEnd),
            scheduleRangeFirst: `[${convertTimeToFloat(firstRange?.timeStart)}, ${convertTimeToFloat(
                firstRange.timeEnd
            )}]`
        };

        if (secondRange) {
            createScheduleBody = {
                ...createScheduleBody,
                scheduleRangeSecond: `[${convertTimeToFloat(secondRange?.timeStart)}, ${convertTimeToFloat(
                    secondRange.timeEnd
                )}]`
            };
        }

        setIsSubmitting(true);

        let body = {
            staffId: user?.id,
            body: createScheduleBody
        };

        if (typeof updateUpComing === 'boolean') {
            body = {
                ...body,
                updateUpComing
            };
        }
        dispatch(
            staffWorkingHourActions.createStaffWorkingHours({
                ...body,
                successCallback: () => {
                    console.log('staffWorkingHourActions success');
                    onCreateDone && onCreateDone();
                    handleClose && handleClose();
                },
                errorCallback: () => {
                    setIsSubmitting(false);
                    console.log('staffWorkingHourActions error');
                }
            })
        );
    };

    const handleDelete = (params) => {
        const updateUpComing = params?.updateUpComing;
        setIsSubmitting(true);
        let body = {
            staffId: user?.id,
            dateStart: date?.date,
            updateUpComing,
            scheduleId: schedule.id
        };

        if (typeof updateUpComing === 'boolean') {
            body = {
                ...body,
                updateUpComing
            };
        }

        dispatch(
            staffWorkingHourActions.deleteStaffWorkingHours({
                ...body,
                successCallback: () => {
                    setIsSubmitting(false);
                    console.log('delete staff schedule success');
                    handleClose && handleClose();
                    onRefreshCurrentDateSchedule && onRefreshCurrentDateSchedule();
                },
                errorCallback: () => {
                    setIsSubmitting(false);
                    console.log('failed to delete');
                }
            })
        );
    };

    const handleDeleteChecking = async () => {
        if (isNoRepeat(schedule)) {
            handleDelete();
            return;
        } else {
            setIsOpenConfirmDeleteUpComing(true);
            return;
        }
    };

    const isWeekday = React.useCallback(
        (conditionDate) => {
            const dateOfWeek = getDateOfWeek(conditionDate);
            return (
                dateOfWeek?.toLowerCase() === date?.dateOfWeek?.toLowerCase() &&
                isAfter(date?.date || new Date(), conditionDate)
            );
        },
        [date]
    );

    const onEndDateChange = (date) => {
        setDateEnd(date);
    };

    const clearConfirmHandle = () => {
        setIsOpenConfirmDeleteUpComing(false);
        setIsOpenConfirmUpdateUpComing(false);
        setIsOpenConfirmCreateUpComing(false);
    };

    const handleDeleteUpComing = () => {
        handleDelete({ updateUpComing: true });
    };

    const handleDeleteThisShiftOnly = () => {
        handleDelete({ updateUpComing: false });
    };

    const handleCreateThisShiftOnly = () => {
        handleCreate({ updateUpComing: false });
    };

    const handleCreateUpComing = () => {
        handleCreate({ updateUpComing: true });
    };

    const handleUpdateUpcoming = () => {
        if (repeatType === STAFF_WORKING_HOURS_REPEAT_TYPES.ON_GOING) {
            handleUpdateStaffWorkingOngoing(true);
            return;
        } else if (repeatType === STAFF_WORKING_HOURS_REPEAT_TYPES.SPECIFIC) {
            handleUpdateStaffWorkingSpecific(true);
            return;
        }

        alert('some thing weird handleUpdateUpcoming');
    };

    const handleUpdateThisShiftOnly = () => {
        if (repeatType === STAFF_WORKING_HOURS_REPEAT_TYPES.ON_GOING) {
            handleUpdateStaffWorkingOngoing(false);
            return;
        } else if (repeatType === STAFF_WORKING_HOURS_REPEAT_TYPES.SPECIFIC) {
            handleUpdateStaffWorkingSpecific(false);
            return;
        }
        alert('some thing weird in handleUpdateThisShiftOnly');
    };

    const userName = React.useMemo(() => {
        return user?.name || getFullName(user);
    }, [user]);

    if (isOpenConfirmUpdateUpComing)
        return (
            <UpcomingConfirm
                message={
                    repeat === STAFF_WORKING_HOURS_REPEAT_TYPES.WEEKLY &&
                    repeatType === STAFF_WORKING_HOURS_REPEAT_TYPES.SPECIFIC
                        ? `You have edited a shift that repeats weekly. Updating upcoming shifts will <strong>overwrite ${userName}'s ${
                              date.dateOfWeek
                          } schedule up to ${getFriendlyDate(getDatetimeFormat(dateEnd))}.</strong>`
                        : `You have edited a shift that repeats weekly. Updating upcoming shifts will <strong>overwrite ${userName}'s ongoing ${date.dateOfWeek} </strong> schedule.`
                }
                title={`Repeating Shift`}
                isSubmitting={isSubmitting}
                onThisShiftOnly={handleUpdateThisShiftOnly}
                onUpcoming={handleUpdateUpcoming}
                upcomingLabel={t(`update_upcoming_shifts`)}
                shiftOnlyLabel={t(`update_this_shift_only`)}
                onClose={clearConfirmHandle}
            />
        );

    if (isOpenConfirmDeleteUpComing)
        return (
            <UpcomingConfirm
                message={
                    !(isNoRepeat(schedule) || isOnGoing(schedule))
                        ? `You are deleting a shift that repeats weekly. Deleting upcoming shifts will overwrite ${userName}'s ${
                              date.dateOfWeek
                          } schedule up to ${getFriendlyDate(getDefaultDateEnd({ schedule, date }))}`
                        : `You are deleting a shift that repeats weekly. Deleting upcoming shifts will <strong>overwrite ${userName}'s ongoing ${date.dateOfWeek} </strong> schedule.`
                }
                title={`Repeating Shift`}
                isSubmitting={isSubmitting}
                onThisShiftOnly={handleDeleteThisShiftOnly}
                onUpcoming={handleDeleteUpComing}
                upcomingLabel={t(`delete_upcoming_shifts`)}
                shiftOnlyLabel={t(`delete_this_shift_only`)}
                onClose={clearConfirmHandle}
            />
        );
    if (isOpenConfirmCreateUpComing)
        return (
            <UpcomingConfirm
                message={
                    repeat === STAFF_WORKING_HOURS_REPEAT_TYPES.WEEKLY &&
                    repeatType === STAFF_WORKING_HOURS_REPEAT_TYPES.SPECIFIC
                        ? `You have edited a shift that repeats weekly. Updating upcoming shifts will <strong>overwrite ${userName}'s ${
                              date.dateOfWeek
                          } schedule up to ${getFriendlyDate(getDatetimeFormat(dateEnd))}.</strong>`
                        : `You are creating a shift that repeats weekly. Creating upcoming shifts will <strong>overwrite ${userName}'s ongoing ${date.dateOfWeek} </strong> schedule.`
                }
                title={`Repeating Shift`}
                isSubmitting={isSubmitting}
                onThisShiftOnly={handleCreateThisShiftOnly}
                onUpcoming={handleCreateUpComing}
                upcomingLabel={t(`create_upcoming_shifts`)}
                shiftOnlyLabel={t(`create_this_shift_only`)}
                onClose={clearConfirmHandle}
            />
        );
    return (
        <>
            <DialogTitle>
                <Typography className={classes.whiteColor} variant="h5" align="center">
                    {isEdit ? t(`edit`) : t(`create`)} {userName}'s {t(`hours`)}
                </Typography>
                <Typography variant="subtitle2" align="center">
                    {date?.friendlyName}
                </Typography>
            </DialogTitle>
            <DialogContent className={classes.content}>
                <Grid container wrap="nowrap" justifyContent="center" direction="column">
                    <Grid item container wrap="nowrap" direction="column">
                        <Grid item>
                            <Grid container spacing={3} direction="row" alignItems="center">
                                <Grid item>
                                    <Typography variant="body1">Shift 1</Typography>
                                </Grid>
                                <Grid item className={classes.expand}>
                                    <Grid
                                        direction="row"
                                        container
                                        wrap="wrap"
                                        justifyContent="space-between"
                                        spacing={2}
                                    >
                                        <Grid item xs>
                                            <NativeSelect
                                                selectedValue={firstRange.timeStart}
                                                options={timeOptionsWithFormat}
                                                onChange={handleTimeStart1stRangeChange}
                                                label={t(`shift_start`)}
                                                isBorder={true}
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <NativeSelect
                                                selectedValue={firstRange.timeEnd}
                                                onChange={handleTimeEnd1stRangeChange}
                                                options={timeOptionsWithFormat}
                                                label={t(`shift_end`)}
                                                isBorder={true}
                                            />
                                        </Grid>

                                        {secondRange && (
                                            <Grid item>
                                                <IconButton style={{ opacity: 0 }}>
                                                    <DeleteIcon
                                                        style={{
                                                            color: theme.palette.secondary.main,
                                                            width: 20,
                                                            height: 'auto'
                                                        }}
                                                    />
                                                </IconButton>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        {!secondRange && (
                            <Grid item style={{ marginTop: theme.spacing(2) }}>
                                <Button
                                    startIcon={<AddCircleIcon />}
                                    onClick={addTime}
                                    variant="outlined"
                                    color="primary"
                                >
                                    {t(`add_another_shift`)}
                                </Button>
                            </Grid>
                        )}

                        {secondRange && (
                            <Grid item style={{ marginTop: theme.spacing(2), position: 'relative' }}>
                                <Grid container spacing={3} direction="row" alignItems="center">
                                    <Grid item>
                                        <Typography variant="body1">Shift 2</Typography>
                                    </Grid>
                                    <Grid item className={classes.expand}>
                                        <Grid
                                            direction="row"
                                            spacing={2}
                                            container
                                            alignItems="center"
                                            wrap="wrap"
                                            justifyContent="space-between"
                                        >
                                            <Grid item xs>
                                                <NativeSelect
                                                    selectedValue={secondRange.timeStart}
                                                    options={timeOptionsWithFormat}
                                                    // label={t(`shift_start`)}
                                                    onChange={handleTimeStart2ndRangeChange}
                                                    isBorder={true}
                                                />
                                            </Grid>
                                            <Grid item xs>
                                                <NativeSelect
                                                    onChange={handleTimeEnd2ndRangeChange}
                                                    selectedValue={secondRange.timeEnd}
                                                    options={timeOptionsWithFormat}
                                                    // label={t(`shift_end`)}
                                                    isBorder={true}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <IconButton onClick={handleRemoveSecondRange}>
                                                    <DeleteIcon
                                                        style={{
                                                            color: theme.palette.secondary.main,
                                                            width: 20,
                                                            height: 'auto'
                                                        }}
                                                    />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>

                    <Grid item className={classes.mt3}>
                        <Grid
                            container
                            wrap={isMobile ? 'wrap' : 'nowrap'}
                            justifyContent="space-between"
                            direction="row"
                            spacing={2}
                        >
                            <Grid
                                className={isMobile ? classes.spacing : ''}
                                item
                                xs={repeat === 'weekly' ? (isMobile ? 12 : 6) : 12}
                            >
                                <NativeSelect
                                    options={repeatOptions}
                                    selectedValue={repeat}
                                    onChange={handleRepeatChange}
                                    label={t(`repeats`)}
                                    isBorder={true}
                                />
                            </Grid>
                            {repeat === 'weekly' && (
                                <Grid
                                    item
                                    className={isMobile ? classes.spacing : ''}
                                    xs={isMobile ? 12 : 6}
                                    style={{ position: 'relative' }}
                                >
                                    {repeatType === 'ongoing' ? (
                                        <NativeSelect
                                            options={endRepeatOptions}
                                            selectedValue={repeatType}
                                            onChange={handleRepeatTypeChange}
                                            label={t(`end_repeat`)}
                                            isBorder={true}
                                        />
                                    ) : (
                                        <PortalDatePicker
                                            filterDate={isWeekday}
                                            onChange={onEndDateChange}
                                            defaultValue={dateEnd}
                                            label={t(`end_repeat`)}
                                            onClose={
                                                repeatType !== 'ongoing' ? () => setRepeatType('ongoing') : undefined
                                            }
                                            withPortal={isMobile}
                                        />
                                    )}
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid
                    container
                    wrap="nowrap"
                    alignItems="center"
                    justifyContent={isEdit ? 'space-between' : 'flex-end'}
                >
                    <Grid item>
                        {isEdit && !isUpdateShift && (
                            <div className={classes.btnSubmit} style={{ width: isMobile ? '100%' : '' }}>
                                <Button
                                    onClick={handleDeleteChecking}
                                    disabled={isSubmitting}
                                    fullWidth={isMobile}
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<DeleteIcon style={{ color: theme.palette.common.white }} />}
                                    minWidth={isMobile ? 100 : 120}
                                >
                                    {t(`delete`)}
                                </Button>
                                {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </div>
                        )}
                    </Grid>

                    <Grid item>
                        <Grid container spacing={2} wrap="nowrap">
                            <Grid item>
                                <Button
                                    onClick={handleClose}
                                    fullWidth={isMobile}
                                    variant="outlined"
                                    startIcon={<CloseIcon />}
                                >
                                    {t(`close`)}
                                </Button>
                            </Grid>
                            <Grid item>
                                <div className={classes.btnSubmit} style={{ width: isMobile ? '100%' : '' }}>
                                    <Button
                                        onClick={handleUpdateChecking}
                                        disabled={isSubmitting}
                                        fullWidth={isMobile}
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        startIcon={isEdit ? <SaveIcon /> : <AddCircleIcon />}
                                    >
                                        {isEdit ? t('save') : t(`create`)}
                                    </Button>
                                    {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogActions>
        </>
    );
}

export default React.memo(WorkingHourForm);
