import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { useIsFetchingStaff, useActiveStaffList } from 'hooks/staff';
import * as staffActions from 'views/staff/action';
import { useDispatch } from 'react-redux';
import Spinner from 'components/spinner';
import { getFullName } from 'utils/naming';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {},
    staffList: {
        maxHeight: 500,
        overflowY: 'scrol'
    }
}));

function Staff({ serviceStaffs, onChange }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isFetchingStaff = useIsFetchingStaff();
    const staffList = useActiveStaffList();

    React.useEffect(() => {
        dispatch(
            staffActions.getStaff({
                errorCallback: () => {
                    console.log('failed to get staff');
                }
            })
        );
    }, [dispatch]);

    const handleCheckedChange = (props) => {
        onChange && onChange(props);
    };

    if (staffList === null && isFetchingStaff)
        return (
            <Grid
                container
                className={classes.root}
                style={{ paddingTop: 30 }}
                alignItems="center"
                justifyContent="center"
                spacing={0}
            >
                <Spinner size={40} thick={4} />
            </Grid>
        );

    return (
        <Grid container className={classes.root} direction="column" spacing={0}>
            <Grid item style={{ padding: `30px 0` }}>
                <Typography variant="body1">{t(`select_staff_who_perform_this_service`)}</Typography>
            </Grid>
            <Grid item>
                <Grid className={classes.staffList} container direction="column" wrap="wrap">
                    {staffList?.map((member) => {
                        const checked =
                            serviceStaffs.findIndex((serviceStaff) => +serviceStaff.staffId === +member.id) !== -1;
                        return (
                            <Grid item key={member.id}>
                                <Grid container alignItems="center" spacing={1} direction="row">
                                    <Grid item style={{ display: 'flex' }}>
                                        <Checkbox
                                            color="primary"
                                            checked={checked}
                                            onChange={(e) =>
                                                handleCheckedChange({
                                                    checked: e.target.checked,
                                                    staff: member
                                                })
                                            }
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2" display="inline">
                                            {getFullName(member)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(Staff);
