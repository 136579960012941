import * as types from './types';
import produce from 'immer';

const INITIAL_STATE = {
    list: null,
    isFetching: false,
    voucherCode: null,
    voucherCodes: [],
    giftHistory: {
        giftId: {
            isFetching: false,
            error: 'something',
            histories: []
        }
    }
};

export default function Voucher(state = INITIAL_STATE, { type, payload }) {
    switch (type) {
        /// VOUCHER
        case types.FETCH_VOUCHERS: {
            return {
                ...state,
                isFetching: true
            };
        }

        case types.FETCH_VOUCHERS_FAILED: {
            return {
                ...state,
                isFetching: false
            };
        }

        case types.FETCH_VOUCHERS_SUCCESS: {
            const { vouchers = [] } = payload;
            return {
                ...state,
                isFetching: false,
                list: vouchers
            };
        }

        case types.CREATE_VOUCHER_SUCCESS: {
            const { voucher } = payload;
            return {
                ...state,
                list: [voucher, ...state.list]
            };
        }

        case types.UPDATE_VOUCHER_SUCCESS: {
            const { voucher, voucherId } = payload;
            const { list } = state;
            const newList = produce(list, (draft) => {
                return draft?.map((item) => {
                    if (item?.id === voucherId) return voucher;
                    return item;
                });
            });
            return {
                ...state,
                list: newList
            };
        }

        case types.DELETE_VOUCHER: {
            const { voucherId } = payload;
            const { list } = state;
            const newList = produce(list, (draft) => {
                draft = draft?.map((item) => {
                    if (item?.id === voucherId) {
                        item.deleted = true;
                    }
                    return item;
                });
            });
            return {
                ...state,
                list: newList
            };
        }

        case types.DELETE_VOUCHER_FAILED: {
            const { voucherId } = payload;
            const { list } = state;
            const newList = produce(list, (draft) => {
                return draft?.map((item) => {
                    if (item?.id === voucherId) {
                        item.deleted = false;
                    }
                    return item;
                });
            });
            return {
                ...state,
                list: newList
            };
        }

        case types.DELETE_VOUCHER_SUCCESS: {
            const { voucherId } = payload;
            const { list } = state;
            const newList = produce(list, (draft) => {
                return draft?.filter((item) => item?.id !== voucherId);
            });
            return {
                ...state,
                list: newList
            };
        }

        /// VOUCHER CODE
        case types.CREATE_VOUCHER_CODE_SUCCESS: {
            const { code } = payload;
            return {
                ...state,
                list: state?.list?.map((voucher) => {
                    if (voucher?.id === code?.giftId?.toString()) {
                        const oldGiftCodes = voucher?.giftCodes || [];
                        voucher.giftCodesCount += 1;
                        voucher.giftCodes = [code, ...oldGiftCodes];
                    }
                    return voucher;
                })
            };
        }

        case types.FETCH_VOUCHERS_CODE: {
            return {
                ...state,
                isFetching: true
            };
        }

        case types.FETCH_VOUCHERS_CODE_FAILED: {
            return {
                ...state,
                isFetching: false
            };
        }

        case types.FETCH_VOUCHERS_CODE_SUCCESS: {
            const { voucherCodes = [] } = payload;
            return {
                ...state,
                isFetching: false,
                voucherCodes: voucherCodes
            };
        }

        case types.GET_VOUCHERS_CODE_BY_CODE_NUMBER: {
            return {
                ...state,
                isFetching: true
            };
        }

        case types.GET_VOUCHERS_CODE_BY_CODE_NUMBER_FAILED: {
            return {
                ...state,
                isFetching: false
            };
        }

        case types.GET_VOUCHERS_CODE_BY_CODE_NUMBER_SUCCESS: {
            const { voucherCode = {} } = payload;
            return {
                ...state,
                isFetching: false,
                voucherCode: voucherCode
            };
        }

        case types.UPDATE_VOUCHER_CODE_SUCCESS: {
            const { voucherCode } = payload;
            return {
                ...state,
                voucherCode: voucherCode
            };
        }

        case types.FETCH_GIFT_HISTORY: {
            const { giftId } = payload;
            const { giftHistory } = state;

            const newGiftHistory = produce(giftHistory, (draft) => {
                if (!draft?.[giftId]) {
                    draft[giftId] = {};
                }
                draft[giftId].isFetching = true;
            });
            return {
                ...state,
                giftHistory: newGiftHistory
            };
        }

        case types.FETCH_GIFT_HISTORY_FAILED: {
            const { giftId, error } = payload;
            const { giftHistory } = state;

            const newGiftHistory = produce(giftHistory, (draft) => {
                if (!draft?.[giftId]) {
                    draft[giftId] = {};
                }
                draft[giftId].isFetching = false;
                draft[giftId].error = error;
            });
            return {
                ...state,
                giftHistory: newGiftHistory
            };
        }

        case types.FETCH_GIFT_HISTORY_SUCCESS: {
            const { giftId, histories } = payload;
            const { giftHistory } = state;

            const newGiftHistory = produce(giftHistory, (draft) => {
                if (!draft?.[giftId]) {
                    draft[giftId] = {};
                }
                draft[giftId].isFetching = false;
                draft[giftId].histories = histories;
            });
            return {
                ...state,
                giftHistory: newGiftHistory
            };
        }

        default:
            return state;
    }
}
