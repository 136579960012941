import { orderBy } from 'lodash';
import { getFullName } from 'utils/naming';
import { getCurrentUTC, getDateOfWeek } from 'utils/timing';

export function formatStaffWorkingHoursResponse(staffWorkingHours) {
    let staffWorkingHoursSchedules = {};

    const staffWorkingHoursUsers = staffWorkingHours.map((staff) => {
        const staffSchedules = staff?.staffSchedules || [];
        staffWorkingHoursSchedules = {
            ...staffWorkingHoursSchedules,
            [staff.id]: staffSchedules
        };
        return {
            id: staff.id,
            name: staff.fullName,
            avatar: staff?.avatar
        };
    });

    return {
        staffWorkingHoursSchedules,
        staffWorkingHoursUsers
    };
}

export function formatCreateUpdateSchedule(schedule) {
    const dateStart = getCurrentUTC(schedule?.dateStart);
    return {
        ...schedule,
        dateEnd: getCurrentUTC(schedule?.dateEnd),
        dateStart,
        dateOfWeek: getDateOfWeek(dateStart)
    };
}

export const COMMISSION_RATE_OPTIONS = [
    { value: 40, label: '40%' },
    { value: 45, label: '45%' },
    { value: 50, label: '50%' },
    { value: 55, label: '55%' },
    { value: 60, label: '60%' },
    { value: 65, label: '65%' },
    { value: 70, label: '70%' },
    { value: 75, label: '75%' },
    { value: 80, label: '80%' },
    { value: 85, label: '85%' },
    { value: 90, label: '90%' }
];

export const DEPOSIT_RATE_OPTIONS = [
    { value: 0, label: '0%' },
    { value: 5, label: '5%' },
    { value: 10, label: '10%' },
    { value: 15, label: '15%' },
    { value: 20, label: '20%' },
    { value: 25, label: '25%' },
    { value: 30, label: '30%' },
    { value: 35, label: '35%' },
    { value: 40, label: '40%' },
    { value: 45, label: '45%' },
    { value: 50, label: '50%' },
    { value: 55, label: '55%' },
    { value: 60, label: '60%' },
    { value: 65, label: '65%' },
    { value: 70, label: '70%' },
    { value: 75, label: '75%' },
    { value: 80, label: '80%' },
    { value: 85, label: '85%' },
    { value: 90, label: '90%' }
];

export function convertStaffsServiceDuration({ staffs, services }) {
    const serviceList = services?.filter((sv) => !sv.isDeleted);

    const servicesMapping = serviceList?.reduce((obj, service) => {
        obj[service?.id] = service;
        return obj;
    }, {});

    let data = {};
    for (const staff of staffs) {
        if (!data?.[staff?.id]) {
            data[staff?.id] = {};
        }
        const staffServices = staff?.staffServices || [];
        const map = staffServices?.reduce((obj, i) => {
            obj[i?.serviceId] = i?.duration || servicesMapping?.[+i?.serviceId]?.prices?.[0]?.duration || 0;
            return obj;
        }, {});
        data[staff?.id] = map;
    }
    return data;
}

export function convertStaffFiltered({ staffs, filteredStaff, staffWorkingHours, bookingsByStaff }) {
    const staffList = orderBy(staffs, ['orderIndex'], ['desc']);

    const staffListOrdered = staffList
        ?.filter((staff) => !staff?.staffState)
        ?.map((sf) => ({
            ...sf,
            label: getFullName(sf),
            value: sf?.id
        }));

    switch (+filteredStaff) {
        case 0:
            return staffListOrdered;
        case -1:
            return staffListOrdered?.filter((staff) => staff?.enableAppointment);

        case -2:
            return staffListOrdered?.filter((staff) => {
                const bookingHours = staffWorkingHours?.[+staff?.id];
                const bookings = bookingsByStaff?.[+staff?.id];
                return bookingHours?.length > 0 || bookings?.length;
            });
        default:
            return [staffListOrdered?.find((staff) => +staff?.id === +filteredStaff)];
    }
}
