import React from 'react';
import GenerateStaticPage from './GenerateStaticPage';
import GenerateEmbededLink from './GenerateEmbededLink';
import LayoutTabsContent from '../layout/LayoutTabsContent';
import SettingsApplications from '@material-ui/icons/SettingsApplications';
import SettingsEthernet from '@material-ui/icons/SettingsEthernet';
import { useTranslation } from 'react-i18next';

function MarketingTabs() {
    const { t } = useTranslation();

    const tabs = [
        {
            component: <GenerateStaticPage />,
            index: 0,
            icon: <SettingsApplications />,
            title: t(`generate_static_page`),
            path: 'generate-static-page'
        },
        {
            component: <GenerateEmbededLink />,
            index: 1,
            icon: <SettingsEthernet />,
            title: t(`generate_embeded_link`),
            path: 'generate-embeded-link'
        }
    ];
    return <LayoutTabsContent tabs={tabs} />;
}

export default React.memo(MarketingTabs);
