import { useIsSameToday, useRatio } from 'hooks/calendar';
import React from 'react';
import { getTopByCurrentMinutes } from 'utils/timing';

function CurrentTimeline() {
    const [date, setDate] = React.useState(new Date());
    const [top, setTop] = React.useState(new Date());
    const isToday = useIsSameToday();

    const RATIO = useRatio();

    React.useEffect(() => {
        let id = setInterval(() => {
            setDate(new Date());
        }, 1000);

        return () => clearInterval(id);
    });

    React.useEffect(() => {
        setTop(getTopByCurrentMinutes({ date, minutePerPx: RATIO }));
    }, [date, RATIO]);

    if (!isToday) return null;

    return (
        <div
            style={{
                width: '100%',
                height: 2,
                background: '#C760A7',
                position: 'absolute',
                left: 0,
                top,
                zIndex: 4
            }}
        ></div>
    );
}

export default React.memo(CurrentTimeline);
