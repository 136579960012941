import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100vw',
        height: '100vh',
        overflow: 'hidden',
        background: theme.palette.primary.main,
        padding: theme.spacing(4)
    },
    logo: {
        fontWeight: 700,
        color: theme.palette.common.white
    },
    fullWidth: {
        width: '100%'
    }
}));

function AuthLayout({ title, children }) {
    const classes = useStyles();

    return (
        <Grid container alignItems="flex-start" justifyContent="center" className={classes.root}>
            <Grid className={classes.formWrapper} item container alignItems="center" spacing={3} direction="column">
                <Grid item>
                    <Typography className={classes.logo} variant="h2">
                        {title}
                    </Typography>
                </Grid>
                <Grid item className={classes.fullWidth}>
                    {children}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(AuthLayout);
