import React from 'react';

function StaffSVG({ color = '#41415C', ...rest }) {
    return (
        <svg {...rest} width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.5">
                <path
                    d="M16.1336 8.99844C17.3247 8.22125 18.1136 6.87776 18.1136 5.35071C18.1136 2.95274 16.1609 1 13.7629 1C12.1578 1 10.7518 1.87483 9.99805 3.17145C9.24429 1.87483 7.83831 1 6.23316 1C3.83519 1 1.88244 2.95274 1.88244 5.35071C1.88244 6.87776 2.67135 8.22125 3.86253 8.99844C1.59734 9.93185 0 12.1658 0 14.7629V16.9578C0 18.1451 0.968561 19.1136 2.15583 19.1136H17.8442C19.0314 19.1136 20 18.149 20 16.9578V14.7629C19.9961 12.1658 18.3988 9.93185 16.1336 8.99844ZM13.7629 2.17165C15.5165 2.17165 16.942 3.59715 16.942 5.35071C16.942 7.10428 15.5165 8.52978 13.7629 8.52978C12.0094 8.52978 10.5839 7.10428 10.5839 5.35071C10.5839 3.59715 12.0094 2.17165 13.7629 2.17165ZM9.99414 9.79516C9.56844 9.471 9.09979 9.20152 8.59988 8.99453C9.17399 8.61961 9.65046 8.1158 9.99414 7.52607C10.3378 8.1158 10.8182 8.61961 11.3884 8.99453C10.8963 9.20152 10.4277 9.471 9.99414 9.79516ZM3.05409 5.35071C3.05409 3.59715 4.47959 2.17165 6.23316 2.17165C7.98672 2.17165 9.41222 3.59715 9.41222 5.35071C9.41222 7.10428 7.98672 8.52978 6.23316 8.52978C4.47959 8.52978 3.05409 7.10428 3.05409 5.35071ZM9.68561 17.942H2.15583C1.61297 17.942 1.17165 17.5007 1.17165 16.9578V14.7629C1.17165 11.9705 3.44073 9.70143 6.23316 9.70143C9.02558 9.70143 11.2947 11.9705 11.2947 14.7629V16.9578C11.2947 17.5007 10.8533 17.942 10.3105 17.942H9.68561ZM18.8244 16.9578C18.8244 17.5007 18.3831 17.942 17.8403 17.942H12.2281C12.3804 17.6452 12.4663 17.3132 12.4663 16.9578V14.7629C12.4663 13.1656 11.8649 11.7088 10.8729 10.6075C11.7165 10.0217 12.7202 9.70143 13.7629 9.70143C16.5554 9.70143 18.8244 11.9705 18.8244 14.7629V16.9578Z"
                    fill={color}
                />
            </g>
        </svg>
    );
}

export default React.memo(StaffSVG);
