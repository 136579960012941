import React from 'react';
import NumberFormat from 'react-number-format';
import NativeSelect from 'components/select/NativeOption';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { phoneCountryCodes } from 'data/phoneNumber';
import { BORDER_RADIUS, INPUT_HEIGHT, PADDING_LIST_ITEM_LEFT_RIGHT } from 'const/style';
import { removePlus, addPlus } from 'utils/phone';

const useStyles = makeStyles((theme) => ({
    root: {
        border: `1px solid ${theme.colors.border}`,
        borderRadius: BORDER_RADIUS,
        width: '100%',
        height: INPUT_HEIGHT,
        '&:focus-within': {
            borderColor: theme.palette.primary.main
        }
    },
    countryCode: {
        borderRight: `1px solid ${theme.colors.border}`,
        minWidth: 50
    },
    inputWrapper: {
        flex: 1,
        width: '100%'
    },
    input: {
        background: 'transparent',
        width: 'calc(100%)',
        height: '100%',
        border: 'none',
        borderRadius: BORDER_RADIUS,
        padding: `0px ${PADDING_LIST_ITEM_LEFT_RIGHT}px`,
        fontSize: 14,
        '&:focus': {
            outline: 'none'
        }
    },
    label: {
        marginBottom: 8,
        fontWeight: 600
    },
    disabled: {
        background: theme.colors.backgroundDisabled,
        opacity: 0.8,
        pointerEvents: 'none'
    }
}));

const phoneOptions = phoneCountryCodes.map((phone) => ({
    ...phone,
    label: phone.dial_code,
    value: phone.dial_code
}));

export default function App({
    disabled,
    label,
    autoFocus,
    onChange,
    defaultValue = '',
    placeholder = 'Your phone number',
    onFocus
}) {
    const classes = useStyles({ disabled });
    const [value, setValue] = React.useState('');
    const [option, setOption] = React.useState(phoneOptions?.[0]);
    const [format, setFormat] = React.useState(phoneOptions?.[0]?.format);

    React.useEffect(() => {
        if (defaultValue) {
            const phoneNumber = parsePhoneNumberFromString(defaultValue);
            const countryCode = phoneNumber?.countryCallingCode;

            if (countryCode && phoneOptions?.findIndex((option) => option?.value === addPlus(countryCode)) !== -1) {
                const option = phoneOptions.find((opt) => opt.dial_code.includes(countryCode));
                if (option) {
                    setOption(option);
                    setFormat(option?.format);
                    setValue(phoneNumber?.nationalNumber);
                }
            } else {
                const noPlusNumber = removePlus(defaultValue);
                const maxNumberPhoneDefault = noPlusNumber?.slice(0, 10);
                setValue(maxNumberPhoneDefault);
                setOption(phoneOptions?.[0]);
                const formatNumber = parsePhoneNumberFromString(
                    removePlus(maxNumberPhoneDefault),
                    phoneOptions?.[0]?.code
                );
                onChange && onChange(formatNumber?.number || '');
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue]);

    React.useEffect(() => {
        setFormat(option.format);
    }, [option]);

    const handlePhoneChange = (e) => {
        const value = e.target.value;
        setValue(value);
        const formatNumber = parsePhoneNumberFromString(value, option.code);
        onChange && onChange(formatNumber?.number || '');
    };

    const handlePrefixChange = (option) => {
        setOption(option);
        setValue('');
    };

    return (
        <Grid container direction="column" wrap="nowrap">
            {label && (
                <Grid item>
                    <Typography className={classes.label} variant="body2">
                        {label}
                    </Typography>
                </Grid>
            )}
            <Grid item>
                <Grid
                    container
                    className={`${classes.root} ${disabled && classes.disabled}`}
                    direction="row"
                    wrap="nowrap"
                >
                    <Grid item className={classes.countryCode}>
                        <NativeSelect
                            selectedValue={option.value}
                            onChange={handlePrefixChange}
                            options={phoneOptions}
                            noBorder
                            fullHeight
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid item className={classes.inputWrapper}>
                        <NumberFormat
                            className={classes.input}
                            value={value}
                            format={format}
                            onChange={handlePhoneChange}
                            autoFocus={autoFocus}
                            placeholder={placeholder}
                            onFocus={onFocus}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
