import React from 'react';

function DeleteSVG({ color = '#41415C', ...other }) {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                d="M16.5625 2.5H13.125V1.875C13.125 0.841133 12.2839 0 11.25 0H8.75C7.71613 0 6.875 0.841133 6.875 1.875V2.5H3.4375C2.57594 2.5 1.875 3.20094 1.875 4.0625V6.25C1.875 6.59516 2.15484 6.875 2.5 6.875H2.84156L3.38152 18.2142C3.42922 19.2156 4.25187 20 5.25437 20H14.7456C15.7482 20 16.5708 19.2156 16.6185 18.2142L17.1584 6.875H17.5C17.8452 6.875 18.125 6.59516 18.125 6.25V4.0625C18.125 3.20094 17.4241 2.5 16.5625 2.5ZM8.125 1.875C8.125 1.53039 8.40539 1.25 8.75 1.25H11.25C11.5946 1.25 11.875 1.53039 11.875 1.875V2.5H8.125V1.875ZM3.125 4.0625C3.125 3.8902 3.2652 3.75 3.4375 3.75H16.5625C16.7348 3.75 16.875 3.8902 16.875 4.0625V5.625C16.6824 5.625 3.92316 5.625 3.125 5.625V4.0625ZM15.3699 18.1547C15.354 18.4885 15.0798 18.75 14.7456 18.75H5.25437C4.9202 18.75 4.64598 18.4885 4.63012 18.1547L4.09297 6.875H15.907L15.3699 18.1547Z"
                fill={color}
            />
            <path
                d="M10 17.5C10.3452 17.5 10.625 17.2202 10.625 16.875V8.75C10.625 8.40484 10.3452 8.125 10 8.125C9.65484 8.125 9.375 8.40484 9.375 8.75V16.875C9.375 17.2202 9.6548 17.5 10 17.5Z"
                fill={color}
            />
            <path
                d="M13.125 17.5C13.4702 17.5 13.75 17.2202 13.75 16.875V8.75C13.75 8.40484 13.4702 8.125 13.125 8.125C12.7798 8.125 12.5 8.40484 12.5 8.75V16.875C12.5 17.2202 12.7798 17.5 13.125 17.5Z"
                fill={color}
            />
            <path
                d="M6.875 17.5C7.22016 17.5 7.5 17.2202 7.5 16.875V8.75C7.5 8.40484 7.22016 8.125 6.875 8.125C6.52984 8.125 6.25 8.40484 6.25 8.75V16.875C6.25 17.2202 6.5298 17.5 6.875 17.5Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(DeleteSVG);
