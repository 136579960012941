import React from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import * as clientActions from 'views/clients/actions';
import * as calendarActions from 'views/calender/actions';

function FetchingData({ clientId, bookingId }) {
    const dispatch = useDispatch();

    const clientBookingFetchingRef = React.useRef();
    const bookingLogRef = React.useRef();

    React.useEffect(() => {
        if (clientId) {
            if (clientBookingFetchingRef?.current) {
                clearTimeout(clientBookingFetchingRef?.current);
            }
            clientBookingFetchingRef.current = setTimeout(() => {
                dispatch(
                    clientActions.fetchClientBookings({
                        clientId,
                        successCallback: () => {
                            console.log('fetch client bookings success');
                        },
                        errorCallback: () => {
                            console.log('fetch client bookings failed');
                        }
                    })
                );
            }, 300);
        }

        return () => {
            if (clientBookingFetchingRef?.current) clearTimeout(clientBookingFetchingRef?.current);
        };
    }, [clientId, dispatch]);

    React.useEffect(() => {
        if (bookingId) {
            if (bookingLogRef?.current) {
                clearTimeout(bookingLogRef?.current);
            }
            bookingLogRef.current = setTimeout(() => {
                dispatch(
                    calendarActions.fetchBookingLogs({
                        bookingId,
                        successCallback: () => {
                            console.log('fetch booking logs success');
                        },
                        errorCallback: () => {
                            console.log('fetch booking logs failed');
                        }
                    })
                );
            }, 300);
            return () => {
                if (bookingLogRef?.current) {
                    clearTimeout(bookingLogRef?.current);
                }
            };
        }
    }, [bookingId, dispatch]);

    return <Grid container></Grid>;
}

export default FetchingData;
