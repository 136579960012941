import React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';
import Routes from './routes';
import NotifierProvider from './notifier/NotifierProvider';
import Notifier from 'notifier/Notifier';
import * as merchantActions from './merchant/actions';
import * as appActions from 'app/actions';
import { useDispatch } from 'react-redux';
import { useUserMerchantId } from 'hooks/auth';
import { v1 as uuidv1 } from 'uuid';
import Networking from './networking';
import { createTheme } from '@material-ui/core/styles';
import { useThemeFontSize, useThemeName, useThemePrimaryColor } from 'hooks/app';
import { getThemeObject } from 'const/theme';
import { queryClient } from 'query-client';
import { QueryClientProvider } from '@tanstack/react-query';
// import { COLORS } from 'utils/color';

const browserHistory = createBrowserHistory();

function App() {
    const themeName = useThemeName();
    const primaryColor = useThemePrimaryColor();
    const fontSize = useThemeFontSize();

    const themeObjectByName = React.useMemo(() => {
        return getThemeObject(themeName);
    }, [themeName]);

    const theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    mode: 'light',
                    primary: {
                        main: primaryColor
                    },
                    secondary: { main: '#F27A7A' }
                },
                ...themeObjectByName,
                shadows: [
                    `rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px`,
                    `rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px`,
                    `rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px`,
                    `rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px`,
                    `rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px`,
                    `rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px`,
                    `rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px`,
                    `rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px`,
                    `rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px`,
                    `rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px`,
                    `rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px`,
                    `rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px`,
                    `rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px`,
                    `rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px`,
                    `rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px`,
                    `rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px`,
                    `rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px`,
                    `rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px`,
                    `rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px`,
                    `rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px`,
                    `rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px`,
                    `rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px`,
                    `rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px`,
                    `rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px`,
                    `rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px`,
                    `rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px`,
                    `rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px`,
                    `rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px`,
                    `rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px`,
                    `rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px`,
                    `rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px`,
                    `rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px`
                ],
                radius: (factor) => [0, 4, 6, 8, 10, 12, 18][factor],
                colors: {
                    lavender: '#4D3F8A',
                    darkLavender: '#392B76',
                    lightLavender: '#7869B9',
                    paleLavender: '#A69FC4',
                    solitude: '#EBEBF6',
                    paleNavy: '#F3F4FC',
                    codeHeader: `#787791`,
                    hover: `#F7F8FA`,
                    blue: '#68B8FC',
                    template: `#399CFF`,
                    darkBlue: '#2C5DB5',
                    purple: '#7474DC',
                    sun: '#EEBC5B',
                    brightSun: '#FFCA41',
                    green: '#35D6AB',
                    darkGreen: '#48C567',
                    lycan: '#59897C',
                    red: '#F27A7A',
                    darkPink: '#BB509A',
                    pink: '#FB7BB8',
                    border: `rgb(238, 238, 238)`,
                    backgroundDisabled: `hsl(0,0%,95%)`,
                    creamBrulee: '#FFE6A5',
                    anakiwa: '#A3D4FF',
                    sundown: '#FFB9B6',
                    waterLeaf: '#9EE9D5',
                    catskillWhite: '#F8FAFC',
                    gunPower: '#41415C',
                    capeHoney: '#FDE6A9',
                    periwinkle: '#C7C7FF',
                    portage: '#9C86F5',
                    iceCold: '#AFF3E1',
                    turquoise: '#43E0B7',
                    pinkLace: '#FFDBF7',
                    lightOrchid: '#E39DCF',
                    gullGray: '#98A5B5',
                    background: '#F7F4FA',
                    ghostwhite: '#F7F8FA',
                    tooltip: 'rgb(33, 43, 54)',
                    black: '#000',
                    dropdown: '#297CB2',
                    primaryText: `rgb(33, 43, 54)`,
                    checkInBackground: `#3E5277`,
                    dimGrey: '#2A293A',
                    steel: '#78778B',
                    disabledGrey: '#C9C9CE'
                },
                typography: {
                    body2: {
                        lineHeight: `1.57143`,
                        fontSize: `${0.875 * fontSize}rem`,
                        fontFamily: `"Public Sans", sans-serif`,
                        fontWeight: 400,
                        verticalAlign: 'inherit',
                        color: `rgb(33, 43, 54)`,
                        borderBottom: 'none'
                    },
                    body1: {
                        lineHeight: `1.57143`,
                        fontSize: `${0.875 * fontSize}rem`,
                        fontFamily: `"Public Sans", sans-serif`,
                        fontWeight: 600,
                        verticalAlign: 'inherit',
                        color: `rgb(33, 43, 54)`,
                        borderBottom: 'none'
                    },
                    h6: {
                        fontWeight: 600,
                        lineHeight: `1.57143`,
                        fontSize: `${0.875 * fontSize}rem`,
                        fontFamily: `"Public Sans", sans-serif`,
                        color: `rgb(33, 43, 54)`
                    },
                    h5: {
                        fontWeight: 700,
                        lineHeight: `1.57143`,
                        fontSize: `${fontSize * 1.125}rem`,
                        fontFamily: `"Public Sans", sans-serif`,
                        color: `white`
                    },
                    h2: {
                        fontWeight: 700,
                        lineHeight: `1.5`,
                        fontSize: `${2.5 * fontSize}rem`,
                        fontFamily: `"Public Sans", sans-serif`,
                        color: `rgb(33, 43, 54)`
                    },
                    h3: {
                        fontWeight: 700,
                        lineHeight: `1.5`,
                        fontSize: `${1.875 * fontSize}rem`,
                        fontFamily: `"Public Sans", sans-serif`,
                        color: `rgb(33, 43, 54)`
                    },
                    h4: {
                        ontWeight: 700,
                        lineHeight: `1.5`,
                        fontSize: `${1.5 * fontSize}rem`,
                        fontFamily: `"Public Sans", sans-serif`,
                        color: `rgb(33, 43, 54)`
                    },
                    subtitle2: {
                        lineHeight: `1.57143`,
                        fontSize: `${0.75 * fontSize}rem`,
                        fontFamily: `"Public Sans", sans-serif`,
                        fontWeight: 400,
                        verticalAlign: 'inherit',
                        color: `rgb(99, 115, 129)`,
                        borderBottom: 'none'
                    },
                    subtitle1: {
                        lineHeight: `1.5`,
                        fontSize: `${1 * fontSize}rem`,
                        fontFamily: `"Public Sans", sans-serif`,
                        fontWeight: 400,
                        color: `rgb(99, 115, 129)`
                    },
                    button: {
                        lineHeight: `1.5`,
                        fontSize: `${0.875 * fontSize}rem`,
                        fontFamily: `"Public Sans", sans-serif`
                    }
                }
            }),
        [primaryColor, themeObjectByName, fontSize]
    );

    const dispatch = useDispatch();
    const merchantId = useUserMerchantId();

    React.useEffect(() => {
        dispatch(
            merchantActions.fetchMerchantInfo({
                successCallback: () => {
                    console.log('fetch merchant info success');
                },
                errorCallback: () => {
                    console.log('fetch merchant info failed');
                }
            })
        );

        dispatch(
            merchantActions.getOtherMerchants({
                success: () => {
                    console.log('fetch other merchants info success');
                },
                error: () => {
                    console.log('fetch other merchants info failed');
                }
            })
        );

        dispatch(appActions.registerSocketId({ socketId: uuidv1() }));
    }, [dispatch, merchantId]);

    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
                <NotifierProvider>
                    <Router history={browserHistory}>
                        <Notifier />
                        <Routes />
                        <Networking />
                    </Router>
                </NotifierProvider>
            </ThemeProvider>
        </QueryClientProvider>
    );
}

export default App;
