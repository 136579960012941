import { MERCHANT_PERMISSIONS } from 'const';
import { getThemeObject } from 'const/theme';
import { useSelector } from 'react-redux';

export function useIsOpenSidebarLeft() {
    return useSelector((state) => state.app?.isOpenSidebarLeft);
}

export function useSocketId() {
    return useSelector((state) => state.app?.socketId);
}

export function useOpenLogoutPopup() {
    return useSelector((state) => state.app?.openLogoutPopup);
}

export function useThemeName() {
    return useSelector((state) => state.app?.themeName);
}

export function useThemePrimaryColor() {
    return useSelector((state) => state.app?.primaryColor);
}

export function useThemeFontSize() {
    return useSelector((state) => state.app?.fontSize);
}

export function useCategoriesColor() {
    return useSelector((state) => {
        return getThemeObject(state?.app?.themeName)?.categories;
    });
}

export function useHidePhone() {
    return useSelector((state) => {
        return (
            state.app?.securePhone && state.auth?.user?.permissions?.includes(MERCHANT_PERMISSIONS.SECURE_PHONE_NUMBER)
        );
    });
}
