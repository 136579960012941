import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Tab, Grid, Tabs, AppBar, Typography } from '@material-ui/core';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SettingsOutlined from '@material-ui/icons/SettingsOutlined';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import Hidden from '@material-ui/core/Hidden';
import { Link, useRouteMatch, Switch, Route, useLocation, Redirect, useHistory } from 'react-router-dom';
import { HEADER_TAB_HEIGHT } from 'const';
import useMediaQueries from 'hooks/useMediaQueries';

const useStyles = makeStyles((theme) => ({
    speedDial: {
        position: 'fixed',
        '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
            bottom: theme.spacing(7),
            right: theme.spacing(2)
        },
        '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
            top: theme.spacing(2),
            left: theme.spacing(2)
        }
    },
    fab: {
        width: 50,
        height: 50
    },
    secondaryBar: {
        height: HEADER_TAB_HEIGHT,
        background: theme.colors.background
    },
    titleBar: {
        height: HEADER_TAB_HEIGHT,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'row',
        padding: 20,
        position: 'fixed',
        zIndex: 100,
        top: 0,
        left: 0,
        width: '100%',
        background: theme.colors.background
    },
    tabContent: {
        height: `calc(100% - ${HEADER_TAB_HEIGHT + 10}px)`,
        marginTop: 20,
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
            height: (props) => `calc(100%)`,
            maxHeight: `-webkit-fill-available`
        }
    }
}));

function LayoutTabsContent({ tabs, ...rest }) {
    const { isMobile } = useMediaQueries();

    const classes = useStyles({ isMobile });
    const [open, setOpen] = React.useState(false);
    let { path, url } = useRouteMatch();
    const location = useLocation();
    const history = useHistory();

    const [tabIndex, setTabIndex] = React.useState(0);

    const selectedTab = React.useMemo(() => {
        return tabs.find((tab) => tab.index === tabIndex);
    }, [tabs, tabIndex]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleActionClick = (action) => {
        setTabIndex(action.index);
        if (action.path) {
            history.push(`${path}/${action.path}`);
        }
        handleClose();
    };

    React.useEffect(() => {
        let patials = location?.pathname?.split('/');
        const selectedTab = tabs.find((tab) => patials.includes(tab.path));
        setTabIndex(selectedTab?.index || 0);
    }, [location, tabs]);

    return (
        <>
            <Hidden xsDown implementation="js">
                <AppBar component="div" className={classes.secondaryBar} position="static" elevation={0}>
                    <Tabs
                        value={tabIndex}
                        onChange={(e, index) => setTabIndex(index)}
                        textColor="inherit"
                        indicatorColor="primary"
                    >
                        {tabs.map((tab) => (
                            <Tab
                                component={Link}
                                to={`${url}/${tab?.path}`}
                                key={tab.index}
                                textColor="inherit"
                                label={<Typography variant="body2">{tab?.title}</Typography>}
                            />
                        ))}
                    </Tabs>
                </AppBar>
            </Hidden>
            <Hidden smUp>
                <AppBar component="div" color="default" className={classes.titleBar} position="static" elevation={0}>
                    <Typography variant="h3">{selectedTab?.title}</Typography>
                </AppBar>
            </Hidden>
            {tabs.length > 1 && (
                <Hidden smUp implementation="js">
                    <SpeedDial
                        ariaLabel="SpeedDial example"
                        className={classes.speedDial}
                        icon={<SettingsOutlined />}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        open={open}
                        direction={'up'}
                        classes={{
                            fab: classes.fab
                        }}
                    >
                        {tabs.map((action) => (
                            <SpeedDialAction
                                key={action.title}
                                icon={action.icon}
                                tooltipTitle={action.title}
                                onClick={() => handleActionClick(action)}
                            />
                        ))}
                    </SpeedDial>
                </Hidden>
            )}
            <Grid className={classes.tabContent}>
                <Switch>
                    {tabs.map((tab) => {
                        return (
                            <Route key={tab.index} path={`${path}/${tab.path}`}>
                                {tab.component}
                            </Route>
                        );
                    })}
                    <Redirect to={`${path}/${tabs?.[0]?.path}`} />
                </Switch>
            </Grid>
        </>
    );
}

export default React.memo(LayoutTabsContent);
