import React, { useCallback } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, Slide, IconButton } from '@material-ui/core';
import Spinner from 'components/spinner';
import TableColumn from 'components/table/TableColumn';
import CloseIcon from '@material-ui/icons/Close';
import StaffInTurn from './staffInTurns';
import { SCHEDULE_TYPES, INDEX_COLUMN_WIDTH, TABLE_HEADER_HEIGHT, ROW_HEIGHT, MERCHANT_PERMISSIONS } from 'const';
import ConfirmDialog from 'components/dialog/Confirm';
import { isKbEscape } from 'utils/keyboard';
import * as calendarActions from '../actions';
import { useStaffById } from 'hooks/staff';
import { getFullName } from 'utils/naming';
import { useMerchantTime12 } from 'hooks/merchant';
import AutoSizer from 'react-virtualized-auto-sizer';
import {
    useDragBkSv,
    useIsFetchingBookings,
    useScheduleType,
    useSelectedDate,
    useCloneTime,
    useCloneStaff
} from 'hooks/calendar';
import LayoutTabContent from 'views/layout/LayoutTabContent';
import {
    getCalendarStyleByStartTime,
    convertDurationAndStartTimeToDate,
    mapTimeToTime12,
    mapTimeToTime12WithoutAMPm,
    getCurrentDateFormat,
    convertTimeToFloat
} from 'utils/timing';
import { useDispatch } from 'react-redux';
import store from 'store/configStore';
import slugify from 'slugify';
import { convertRatio } from 'utils/app/calendar';
import SimpleBar from 'simplebar-react';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        width: '100%',
        position: 'relative'
    },
    table: {
        width: `100%`,
        overflow: 'hidden',
        flex: 1
    },

    fullWidth: {
        width: '100%'
    },

    svgClosedDate: {
        '& svg': {
            maxWidth: '100%'
        }
    },
    warningWrapper: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        zIndex: 100,
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0
        }
    },
    warningTitle: {
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        background: theme.palette.primary.main
    },
    paddingLeftRight: {
        paddingLeft: 14,
        paddingRight: 14
    },

    suggestion: {
        top: 40
    },
    warningCheckout: {},
    tableScroll: {
        position: 'relative',
        width: '100%',
        zIndex: 1,
        margin: 'auto',
        overflow: 'auto'
    }
}));

function LoadingSpinner({ width, height }) {
    const isFetching = useIsFetchingBookings();
    if (!isFetching) return null;
    return (
        <Grid
            style={{
                width,
                height,
                position: 'absolute',
                zIndex: 100
            }}
            container
            justifyContent="center"
            alignItems="center"
        >
            <Spinner size={40} thick={4} />
        </Grid>
    );
}

function TableWrapper() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();

    const [isOpenReScheduleConfirm, setIsOpenReScheduleConfirm] = React.useState(false);
    const [isProcessingRescheduleLoveBkSv, setIsProcessingRescheduleLoveBkSv] = React.useState(false);

    const tableRef = React.useRef();
    const dragBookingServiceRef = React.useRef(null);

    // const [isMovingProcessing, setIsMovingProcessing] = React.useState(false);
    const [isOpenConfirmMovingModal, setIsOpenConfirmMovingModal] = React.useState(false);

    const [scrollLeftMore, setScrollLeftMore] = React.useState(false);
    const [scrollRightMore, setScrollRightMore] = React.useState(false);
    const [scrollBottomMore, setScrollBottomMore] = React.useState(false);
    const [scrollTopMore, setScrollTopMore] = React.useState(false);
    const isDraggingRef = React.useRef(false);

    const handleOpenConfirmMovingModal = React.useCallback(
        ({ time, staffId }) => {
            dispatch(calendarActions.setCloneTime(time));
            const state = store?.getState();
            const staffs = state?.staff?.list;
            const staffMapping = staffs?.reduce((obj, st) => {
                obj[st?.id] = st;
                return obj;
            }, {});
            const staff = staffMapping?.[staffId];
            dispatch(calendarActions.setCloneStaff(staff));
            setIsOpenConfirmMovingModal(true);
        },
        [dispatch]
    );

    console.log('RE-RENDER TABLE');

    const handleCellClick = React.useCallback(({ rowIndex, columnIndex }) => {
        console.log('cellClick', rowIndex, columnIndex);
    }, []);

    const resetMovingSchedule = React.useCallback(() => {
        dispatch(calendarActions.setScheduleType(null));
        dispatch(calendarActions.setCloneTime(null));
        dispatch(calendarActions.setCloneStaff(null));
        const $bkSvs = document.querySelectorAll('[id^="drag_bkSv_"]');

        for (const $item of $bkSvs) {
            $item.style.pointerEvents = 'block';
            $item.style.opacity = `1`;
        }
    }, [dispatch]);

    const handleCloseConfirmMovingModal = React.useCallback(() => {
        setIsOpenConfirmMovingModal(false);
        dispatch(calendarActions.setScheduleType(null));
        const $bkSvs = document.querySelectorAll('[id^="drag_bkSv_"]');

        for (const $item of $bkSvs) {
            $item.style.pointerEvents = 'block';
            $item.style.opacity = `1`;
        }
    }, [dispatch]);

    const handleCloseConfirmReschedule = React.useCallback(() => {
        setIsOpenReScheduleConfirm(false);
        dispatch(calendarActions.setDragBkSv(null));
    }, [dispatch]);

    const handleReschedule = React.useCallback(
        (e, booking) => {
            dispatch(calendarActions.setScheduleType(SCHEDULE_TYPES.MOVING));
            dispatch(calendarActions.setCloneBooking(booking));
        },
        [dispatch]
    );

    const handleReBooking = React.useCallback(
        (e, booking) => {
            dispatch(calendarActions.setScheduleType(SCHEDULE_TYPES.CLONE));
            dispatch(calendarActions.setCloneBooking(booking));
        },
        [dispatch]
    );

    const handleCancelDragging = useCallback(() => {
        const isDragging = store.getState()?.calendar?.isDragging;

        if (isDragging) {
            dispatch(calendarActions.setDragBkSv(null));
            dragBookingServiceRef.current = null;
            isDraggingRef.current = false;
            const $bkSvs = document.querySelectorAll('[id^="drag_bkSv_"]');

            for (const $item of $bkSvs) {
                $item.style.pointerEvents = 'auto';
            }
        }
    }, [dispatch]);

    const handleMouseUp = React.useCallback(
        (e) => {
            if (!isDraggingRef.current) {
                const $bkSvs = document.querySelectorAll('[id^="drag_bkSv_"]');

                for (const $item of $bkSvs) {
                    $item.style.pointerEvents = 'auto';
                }
                return;
            }

            const state = store?.getState();
            const isDragging = state?.calendar?.isDragging;

            if (isDragging) {
                const isHasResizeMoveBookingConfirmPermission = state.auth?.user?.permissions?.includes(
                    MERCHANT_PERMISSIONS.RESIZE_MOVE_CONFIRMED
                );

                if (isHasResizeMoveBookingConfirmPermission) {
                    let confirm = window.confirm('Are you sure you want to update this booking?');

                    if (!confirm) {
                        handleCancelDragging();
                        return;
                    }
                }

                const value = e.target && e.target.classList && e.target.classList.value;
                const matches = value.match(/cell_([^&]*)_([^&]*) ?/);
                const className = matches && matches[0].split(' ');

                if (className && className[0].includes('cell_')) {
                    const currentSelected = className[0];
                    if (currentSelected) {
                        dispatch(
                            calendarActions.handleStopMove({
                                currentSelected,
                                success: () => {
                                    console.log('moving booking success');
                                },
                                error: () => {
                                    console.log('moving booking failed');
                                }
                            })
                        );
                    }
                }

                dispatch(calendarActions.setIsDragging(false));
                dispatch(calendarActions.setDragBkSv(null));
                isDraggingRef.current = false;
            }

            const $bkSvs = document.querySelectorAll('[id^="drag_bkSv_"]');

            for (const $item of $bkSvs) {
                $item.style.pointerEvents = 'auto';
            }
        },
        [dispatch, handleCancelDragging]
    );

    const generateDurationBaseDistance = React.useCallback((distance) => {
        //1s = 400px
        const WIDTH_DEFAULT = 400;
        return Math.round((distance / WIDTH_DEFAULT) * 1000, 0);
    }, []);

    const scrollXTimerRefLeft = React.useRef();
    const scrollXTimerRefTop = React.useRef();

    const scrollToLeft = React.useCallback(({ element, to, duration, isDisabledScroll }) => {
        let start = element.scrollLeft,
            change = to - start,
            currentTime = 0,
            increment = 20;
        const changeABS = Math.abs(change);

        let animateScroll = function () {
            if (isDisabledScroll) {
                clearTimeout(scrollXTimerRefLeft && scrollXTimerRefLeft.current);
                return;
            }
            if (currentTime < changeABS) {
                currentTime += increment;
                let val = Math.linearTween(currentTime, start, change, changeABS);
                element.scrollLeft = val;
                scrollXTimerRefLeft.current = setTimeout(animateScroll, increment);
            }
        };
        animateScroll();
    }, []);

    const handleAgreeReschedule = React.useCallback(() => {
        const props = dragBookingServiceRef.current;
        if (!props) {
            console.log('whye whyw hyw');
            dispatch(calendarActions.setDragBkSv(null));
            setIsOpenReScheduleConfirm(false);
            return;
        }
        setIsProcessingRescheduleLoveBkSv(true);

        dispatch(
            calendarActions.updateBooking({
                ...props,
                successCallback: () => {
                    console.log('moving booking success');
                    dispatch(calendarActions.setDragBkSv(null));
                    setIsOpenReScheduleConfirm(false);
                    setIsProcessingRescheduleLoveBkSv(false);
                },
                errorCallback: () => {
                    console.log('moving booking failed');
                    setIsProcessingRescheduleLoveBkSv(false);
                }
            })
        );
    }, [dispatch]);

    const handleMouseMove = React.useCallback((e) => {
        const isDragging = store.getState()?.calendar?.isDragging;

        if (!isDragging) return;

        isDraggingRef.current = true;
        let clientX = e.clientX;
        let clientY = e.clientY;

        const value = e.target && e.target.classList && e.target.classList.value;
        const matches = value.match(/cell_([^&]*)_([^&]*) ?/);
        const className = matches && matches[0].split(' ');
        let selected = { staffId: null, startTime: null, staffName: null };

        const state = store?.getState();

        if (className && className[0].includes('cell_')) {
            const currentSelected = className[0];
            if (currentSelected) {
                // eslint-disable-next-line no-unused-vars
                const [_, startTime, staffId] = currentSelected?.split('_');

                const staffs = state?.staff?.list;
                const staffMapping = staffs?.reduce((obj, st) => {
                    obj[st?.id] = st;
                    return obj;
                }, {});

                const staff = staffMapping?.[staffId];
                selected.staffId = staff?.id;
                selected.startTime = startTime;
                selected.staffName = slugify(getFullName(staff) || ``);
            }
        }

        const dragBkSv = state?.calendar?.dragBkSv;
        const dragBkSvId = dragBkSv?.id;
        const $preview = document.getElementById(`booking_${dragBkSvId}`);
        const $mainTable = document.querySelector(`.simplebar-content-wrapper`);

        if (!$preview) return;

        const scrollLeft = $mainTable.scrollLeft;
        const scrollTop = $mainTable.scrollTop;
        const previewWidth = +$preview.style.width?.split('px')?.[0];
        const previewHeight = +$preview.style.height?.split('px')?.[0];

        const tableDimension = tableRef.current.getBoundingClientRect();

        if (clientX < tableDimension.left + INDEX_COLUMN_WIDTH && scrollLeft !== 0) {
            setScrollLeftMore(true);
        } else {
            setScrollLeftMore(false);
        }

        if (clientX + previewWidth > tableDimension.left + tableDimension.width) {
            setScrollRightMore(true);
        } else {
            setScrollRightMore(false);
        }

        if (clientY < TABLE_HEADER_HEIGHT + tableDimension.top && scrollTop !== 0) {
            setScrollTopMore(true);
        } else {
            setScrollTopMore(false);
        }

        if (clientY + previewHeight > tableDimension.top + tableDimension.height) {
            setScrollBottomMore(true);
        } else {
            setScrollBottomMore(false);
        }

        if ($preview) {
            const state = store?.getState();
            const TIME_12 = state?.auth?.user?.TIME_12 || true;

            $preview.style.top = `${clientY}px`;
            $preview.style.left = `${clientX}px`;
            const $title = document.getElementById(`booking_${dragBkSvId}_title`);
            const $subTitle = document.getElementById(`booking_${dragBkSvId}_subtitle`);

            if ($title && selected?.startTime) {
                const RATIO = convertRatio({ zoom: state?.calendar?.zoom, height: ROW_HEIGHT });
                let start = TIME_12 ? mapTimeToTime12WithoutAMPm(selected.startTime) : selected?.startTime;

                const style = getCalendarStyleByStartTime({
                    startTime: dragBkSv?.startTime,
                    duration: dragBkSv?.duration,
                    minutePerPx: RATIO
                });

                const end = TIME_12
                    ? mapTimeToTime12WithoutAMPm(
                          convertDurationAndStartTimeToDate(
                              convertTimeToFloat(selected?.startTime),
                              style.height * (1 / RATIO)
                          )
                      )
                    : convertDurationAndStartTimeToDate(
                          convertTimeToFloat(selected?.startTime),
                          style.height * (1 / RATIO)
                      );

                $title.textContent = `${start} - ${end}`;
                $subTitle.innerHTML = `Reordering with <span style="font-weight: bold; color: white;">${selected.staffName}</span>`;
            }
        }
    }, []);

    const scrollToTop = React.useCallback(({ element, to, duration, isDisabledScroll }) => {
        let start = element.scrollTop,
            change = to - start,
            currentTime = 0,
            increment = 20;

        const changeABS = Math.abs(change);

        let animateScroll = function () {
            if (isDisabledScroll) {
                clearTimeout(scrollXTimerRefTop && scrollXTimerRefTop.current);
                return;
            }

            if (currentTime < changeABS) {
                currentTime += increment;
                let val = Math.linearTween(currentTime, start, change, changeABS);
                element.scrollTop = val;
                scrollXTimerRefTop.current = setTimeout(animateScroll, increment);
            }
        };
        animateScroll();
    }, []);

    Math.linearTween = function (t, b, c, d) {
        return (c * t) / d + b;
    };

    //scroll left
    React.useEffect(() => {
        const $mainTable = document.querySelector(`.simplebar-content-wrapper`);

        if ($mainTable) {
            const scrollLeft = $mainTable.scrollLeft;
            if (scrollLeft !== 0) {
                scrollToLeft({
                    element: $mainTable,
                    to: 0,
                    duration: generateDurationBaseDistance(scrollLeft),
                    isDisabledScroll: !scrollLeftMore
                });
            }
        }
    }, [scrollLeftMore, scrollToLeft, generateDurationBaseDistance]);

    //scroll right
    React.useEffect(() => {
        const $mainTable = document.querySelector(`.simplebar-content-wrapper`);

        if ($mainTable) {
            const scrollLeft = $mainTable.scrollLeft;
            const scrollWidth = $mainTable.scrollWidth;
            if (scrollLeft !== scrollWidth) {
                scrollToLeft({
                    element: $mainTable,
                    to: scrollWidth,
                    duration: generateDurationBaseDistance(scrollWidth),
                    isDisabledScroll: !scrollRightMore
                });
            }
        }
    }, [scrollRightMore, scrollToLeft, generateDurationBaseDistance]);

    React.useEffect(() => {
        const $mainTable = document.querySelector(`.simplebar-content-wrapper`);

        if ($mainTable) {
            const scrollTop = $mainTable.scrollTop;
            const scrollHeight = $mainTable.scrollHeight;

            if (scrollTop !== scrollHeight) {
                scrollToTop({
                    element: $mainTable,
                    to: scrollHeight,
                    duration: generateDurationBaseDistance(scrollHeight),
                    isDisabledScroll: !scrollBottomMore
                });
            }
        }
    }, [scrollBottomMore, scrollToTop, generateDurationBaseDistance]);

    React.useEffect(() => {
        const $mainTable = document.querySelector(`.simplebar-content-wrapper`);
        if ($mainTable) {
            const scrollTop = $mainTable.scrollTop;

            if (scrollTop !== 0) {
                scrollToTop({
                    element: $mainTable,
                    to: 0,
                    duration: generateDurationBaseDistance(scrollTop),
                    isDisabledScroll: !scrollTopMore
                });
            }
        }
    }, [scrollTopMore, scrollToTop, generateDurationBaseDistance]);

    React.useEffect(() => {
        function downHandler(e) {
            var event = document.all ? window.event : e;
            if (!/^(?:input|textarea|select|button)$/i.test(e.target.tagName)) {
                if (isKbEscape(event)) {
                    handleCancelDragging();
                }
            }
        }

        window.addEventListener('keydown', downHandler, true);

        return () => {
            window.removeEventListener('keydown', downHandler, true);
        };
    }, [dispatch, handleCancelDragging]);

    const handleOpenBooking = () => {
        dispatch(calendarActions.setDragBkSv(null));
        dragBookingServiceRef.current = null;
        dispatch(calendarActions.setIsDragging(false));
        isDraggingRef.current = false;
        dispatch(calendarActions.setOpenBooking(true));
    };

    const handleBookingClose = () => {
        dispatch(calendarActions.setOpenBooking(false));
    };

    const handleRefreshStaffWorkingHours = React.useCallback(() => {
        dispatch(
            calendarActions.getStaffWorkingHoursByDate({
                success: () => {
                    console.log('fetch staff working hours success');
                },
                error: () => {
                    console.log('failed to fetch staff working hours');
                }
            })
        );
    }, [dispatch]);

    const onRefreshStaffBlockedTimes = React.useCallback(() => {
        dispatch(
            calendarActions.getStaffBlockedTimesByDate({
                successCallback: () => {
                    console.log('fetch staff blocked times success');
                },
                errorCallback: () => {
                    console.log('failed to fetch staff block times');
                }
            })
        );
    }, [dispatch]);

    return (
        <LayoutTabContent isLoading={false}>
            <Grid container className={classes.root} direction="column" wrap="nowrap">
                <Grid
                    item
                    className={classes.table}
                    ref={tableRef}
                    onMouseUp={handleMouseUp}
                    onMouseMove={handleMouseMove}
                    style={{ position: 'relative' }}
                >
                    <Grid container style={{ width: '100%', height: '100%', position: 'relative' }} direction="row">
                        <Grid item style={{ flex: 1 }}>
                            <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                                <AutoSizer>
                                    {({ height, width }) => {
                                        return (
                                            <>
                                                <LoadingSpinner width={width} height={height} />

                                                <div
                                                    style={{
                                                        height,
                                                        width
                                                    }}
                                                    id="table-scroll"
                                                    className={classes.tableScroll}
                                                >
                                                    <SimpleBar
                                                        autoHide={false}
                                                        style={{ maxWidth: width, maxHeight: height }}
                                                    >
                                                        <TableColumn
                                                            openConfirmMovingModel={handleOpenConfirmMovingModal}
                                                            onCellClicked={handleCellClick}
                                                            columnWidth={110}
                                                            onRefreshStaffBlockedTimes={onRefreshStaffBlockedTimes}
                                                            onRefreshWorkingHours={handleRefreshStaffWorkingHours}
                                                            INDEX_COLUMN_WIDTH={INDEX_COLUMN_WIDTH}
                                                            HEADER_HEIGHT={TABLE_HEADER_HEIGHT}
                                                            onReBooking={handleReBooking}
                                                            onReSchedule={handleReschedule}
                                                            onOpenBooking={handleOpenBooking}
                                                            onCloseBooking={handleBookingClose}
                                                        />
                                                    </SimpleBar>
                                                </div>

                                                <ConfirmMoving
                                                    open={isOpenConfirmMovingModal}
                                                    onDisagree={handleCloseConfirmMovingModal}
                                                    type={'INFO'}
                                                    onRest={resetMovingSchedule}
                                                />

                                                <ReScheduleBookingServiceConfirm
                                                    open={isOpenReScheduleConfirm}
                                                    onAgree={handleAgreeReschedule}
                                                    onDisagree={handleCloseConfirmReschedule}
                                                    isLoading={isProcessingRescheduleLoveBkSv}
                                                />
                                                <SlideUpWarning onClick={resetMovingSchedule} />
                                            </>
                                        );
                                    }}
                                </AutoSizer>
                            </div>
                        </Grid>
                        <Grid
                            item
                            style={{
                                borderLeft: `1px solid ${theme.colors.border}`,
                                height: '100%'
                            }}
                        >
                            <StaffInTurn />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </LayoutTabContent>
    );
}

export default TableWrapper;

function SlideUpWarning({ onClick }) {
    const classes = useStyles();
    const theme = useTheme();
    const scheduleType = useScheduleType();

    return (
        <Slide
            direction="up"
            in={[SCHEDULE_TYPES.CLONE, SCHEDULE_TYPES.MOVING]?.includes(scheduleType)}
            mountOnEnter
            unmountOnExit
        >
            <Grid container className={classes.warningWrapper}>
                <Grid item className={classes.fullWidth}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        className={classes.warningTitle}
                    >
                        <Grid item>
                            <Typography variant="h5">
                                Select date & time to {scheduleType === SCHEDULE_TYPES.CLONE ? 'rebook' : 'reschedule'}.
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={onClick}>
                                <CloseIcon
                                    style={{
                                        color: theme.palette.common.white
                                    }}
                                />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Slide>
    );
}

function ReScheduleBookingServiceConfirm({ onDisagree, open, isLoading, onAgree }) {
    const dragBkSv = useDragBkSv();
    const staff = useStaffById(dragBkSv?.id);

    return (
        <ConfirmDialog
            open={open}
            title={`Re-schedule booking service`}
            text={`This appointment currently requests <strong>${getFullName(
                staff
            )}</strong>. Do you want to reschedule?`}
            onAgree={onAgree}
            onDisagree={onDisagree}
            type={'INFO'}
            isLoading={isLoading}
        />
    );
}

function ConfirmMoving({ onDisagree, open, onReset }) {
    const selectedDate = useSelectedDate();
    const TIME_12 = useMerchantTime12();
    const scheduleType = useScheduleType();
    const cloneTime = useCloneTime();
    const cloneStaff = useCloneStaff();
    const dispatch = useDispatch();
    const [isMovingProcessing, setIsMovingProcessing] = React.useState(false);

    const handleAgreeMoving = React.useCallback(() => {
        if (![SCHEDULE_TYPES.MOVING, SCHEDULE_TYPES.CLONE].includes(scheduleType)) {
            const $bkSvs = document.querySelectorAll('[id^="drag_bkSv_"]');

            for (const $item of $bkSvs) {
                $item.style.pointerEvents = 'block';
                $item.style.opacity = `1`;
            }
            return;
        }

        setIsMovingProcessing(true);
        dispatch(
            calendarActions.agreeMove({
                success: () => {
                    onDisagree && onDisagree();
                    onReset && onReset();
                    setIsMovingProcessing(false);
                },
                error: () => {
                    const $bkSvs = document.querySelectorAll('[id^="drag_bkSv_"]');

                    for (const $item of $bkSvs) {
                        $item.style.pointerEvents = 'block';
                        $item.style.opacity = `1`;
                    }
                    setIsMovingProcessing(false);
                }
            })
        );
    }, [dispatch, onDisagree, onReset, scheduleType]);

    const friendlyTime = React.useMemo(() => {
        return TIME_12 ? mapTimeToTime12(cloneTime) : cloneTime;
    }, [cloneTime, TIME_12]);

    const startDateFriendly = React.useMemo(() => {
        return getCurrentDateFormat(selectedDate);
    }, [selectedDate]);

    return (
        <ConfirmDialog
            open={open}
            title={scheduleType === SCHEDULE_TYPES.CLONE ? 'Re-Booking' : 'Re-Schedule'}
            text={`Do you want to ${
                scheduleType === SCHEDULE_TYPES.CLONE ? 'rebook' : 'reschedule'
            } this booking to <strong>${startDateFriendly}, ${friendlyTime}</strong> with <strong>${getFullName(
                cloneStaff
            )}</strong>. This action will persist you data.`}
            onAgree={handleAgreeMoving}
            onDisagree={onDisagree}
            type={'INFO'}
            isLoading={isMovingProcessing}
        />
    );
}
