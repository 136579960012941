import { generateOptionsMinutes } from 'utils/timing';

const times = generateOptionsMinutes(5);

export const calenderOptions = generateOptionsMinutes(30).map((time, index) => ({
    label: time,
    value: time,
    id: index
}));

const durations = [
    { label: '5m', value: 5 },
    { label: '10m', value: 10 },
    { label: '15m', value: 15 },
    { label: '20m', value: 20 },
    { label: '25m', value: 25 },
    { label: '30m', value: 30 },
    { label: '35m', value: 35 },
    { label: '40m', value: 40 },
    { label: '45m', value: 45 },
    { label: '50m', value: 50 },
    { label: '55m', value: 55 },
    { label: '1h', value: 60 },
    { label: '1h 5m', value: 65 },
    { label: '1h 10m', value: 70 },
    { label: '1h 15m', value: 75 },
    { label: '1h 20m', value: 80 },
    { label: '1h 25m', value: 85 },
    { label: '1h 30m', value: 90 },
    { label: '1h 35m', value: 95 },
    { label: '1h 40m', value: 100 },
    { label: '1h 45m', value: 105 },
    { label: '1h 50m', value: 110 },
    { label: '1h 55m', value: 115 },
    { label: '2h', value: 120 },
    { label: '2h 15m', value: 135 },
    { label: '2h 30m', value: 150 },
    { label: '2h 45m', value: 165 },
    { label: '3h', value: 180 },
    { label: '3h 15m', value: 195 },
    { label: '3h 30m', value: 210 },
    { label: '3h 45m', value: 225 },
    { label: '4h', value: 240 },
    { label: '4h 30m', value: 270 },
    { label: '5h', value: 300 },
    { label: '5h 30m', value: 330 },
    { label: '6h', value: 360 },
    { label: '6h 30m', value: 390 },
    { label: '7h', value: 420 },
    { label: '8h', value: 480 },
    { label: '9h', value: 540 },
    { label: '10h', value: 600 },
    { label: '11h', value: 660 },
    { label: '12h', value: 720 }
];

export const durationOptions = durations.map((duration, index) => ({
    ...duration,
    id: index
}));

export const timeOptions = times.map((time, index) => ({
    label: time,
    value: time,
    id: index
}));

export const repeatOptions = [
    { value: 'weekly', label: 'Weekly' },
    { value: 'norepeat', label: "Don't repeat" }
];

export const endRepeatOptions = [
    { value: 'ongoing', label: 'On going' },
    { value: 'specific', label: 'Specific date' }
];
