import React from 'react';
import CalenderTabs from './Tabs';
import { useDispatch } from 'react-redux';
import { ROOMS, TOPICS, REALTIME_OBJECT_TYPES, TURN_REALTIME_ACTIONS, TOKEN_NAME } from 'const';
import { API_URL } from '../../config';
import { io } from 'socket.io-client';
import { useUserMerchantId, useIsHavingInternalPermission } from 'hooks/auth';
import { getItem } from 'utils/localStorage';
import * as turnActions from 'views/turn/actions';
import * as calendarActions from './actions';
import { getAppendMerchantTimezoneToDate, getDatetimeFormat } from 'utils/timing';

function Calender() {
    const dispatch = useDispatch();
    const merchantId = useUserMerchantId();
    const token = getItem(TOKEN_NAME);
    const isHavingInternalPermission = useIsHavingInternalPermission();

    const _turnRealtimeHandler = React.useCallback(
        (inData) => {
            switch (inData?.type) {
                case TURN_REALTIME_ACTIONS.ADD:
                    return dispatch(turnActions.registerStaffToInTurnSocket({ staff: inData?.staff }));
                case TURN_REALTIME_ACTIONS.REMOVE:
                    return dispatch(turnActions._kickStaffOutOfTurn({ staffId: +inData?.staff?.id }));
                case TURN_REALTIME_ACTIONS.RESET:
                    return dispatch(turnActions._resetInTurnList());
                case TURN_REALTIME_ACTIONS.RE_ORDER:
                    return;
                default:
                    return;
            }
        },
        [dispatch]
    );

    const handleRealtime = React.useCallback(
        (body) => {
            const type = body?.type;

            if (type === REALTIME_OBJECT_TYPES.Booking) {
                return dispatch(calendarActions.bookingHandler(body));
            }
            _turnRealtimeHandler(body?.in);
        },
        [_turnRealtimeHandler, dispatch]
    );

    React.useEffect(() => {
        if (!isHavingInternalPermission) return;
        const bookingSocket = io(`${API_URL}/app`, {
            transports: ['websocket'],
            autoConnect: true,
            reconnectionDelay: 2000,
            query: {
                token
            }
        });
        bookingSocket.on('connect', () => {
            bookingSocket.emit(ROOMS.JOIN_ROOM, TOPICS.booking(merchantId));
            bookingSocket.emit(ROOMS.JOIN_ROOM, TOPICS.turn(merchantId));
        });

        bookingSocket.on(ROOMS.JOINED_ROOM, (room) => {
            console.log('JOINED_ROOM', room);
        });

        bookingSocket.on(ROOMS.MODIFIED, (body) => {
            handleRealtime(body);
        });

        bookingSocket.on(ROOMS.LEAVED_ROOM, (body) => {
            console.log('data LEAVED_ROOM', body);
        });

        return () => {
            bookingSocket.emit(ROOMS.LEAVE_ROOM, TOPICS.booking(merchantId));
            bookingSocket.emit(ROOMS.LEAVE_ROOM, TOPICS.turn(merchantId));
        };
    }, [merchantId, token, isHavingInternalPermission, handleRealtime]);

    React.useEffect(() => {
        dispatch(
            calendarActions.initDataDefault({
                success: () => {
                    console.log('init success');
                },
                error: () => {
                    console.log('init success');
                }
            })
        );
    }, [dispatch]);

    React.useEffect(() => {
        dispatch(
            turnActions.getInTurnList({
                query: {
                    startDate: getAppendMerchantTimezoneToDate(getDatetimeFormat(new Date()))
                },
                errorCallback: () => {
                    console.log('failed');
                },
                successCallback: () => {
                    console.log('sucess');
                }
            })
        );
    }, [dispatch]);

    return <CalenderTabs />;
}

export default React.memo(Calender);
