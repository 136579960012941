import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import NativeSelect from 'components/select/NativeForm';
import { durationOptions } from 'data/time';
// import { priceOptions } from 'data/price';
import InputBase from 'components/input';
import { BORDER_RADIUS } from 'const/style';
import { CloseOutlined } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {},
    price: {
        background: theme.colors.catskillWhite,
        padding: `20px 20px 5px`,
        marginBottom: 20,
        borderRadius: BORDER_RADIUS,
        position: 'relative'
    },
    spacing: {
        padding: `20px 0`
    },
    closeIcon: {
        position: 'absolute',
        right: 10,
        top: 10,
        '& svg': {
            color: theme.colors.froly,
            cursor: 'pointer'
        }
    }
}));

function PricingList({
    prices,
    removePricing,
    onDurationChange,
    onPriceTypeChange,
    onRetailPriceChange,
    onSpecialPriceChange,
    onPriceNameChange
}) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Grid container direction={'column'} className={classes.root}>
            {prices.map((price, index) => {
                console.log('price', price);
                return (
                    <Grid key={price.id} direction="column" item container className={classes.price}>
                        {prices.length > 1 && (
                            <span className={classes.closeIcon} onClick={() => removePricing(price.id)}>
                                <CloseOutlined />
                            </span>
                        )}
                        <Grid item>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <NativeSelect
                                        isBorder={true}
                                        onChange={(e) => onDurationChange({ value: e.target.value, id: price.id })}
                                        label={t('duration')}
                                        options={durationOptions}
                                        selectedValue={price.duration}
                                    />
                                </Grid>
                                {/* <Grid item xs={6}>
                                    <NativeSelect
                                        onChange={(e) => onPriceTypeChange({ value: e.target.value, id: price.id })}
                                        label={t(`price_type`)}
                                        options={priceOptions}
                                        selectedValue={price.priceType}
                                    />
                                </Grid> */}
                                <Grid item xs={6}>
                                    <InputBase
                                        label={t(`retail_price`)}
                                        value={price.retailPrice}
                                        onChange={(e) =>
                                            onRetailPriceChange({
                                                value: e.target.value,
                                                id: price.id
                                            })
                                        }
                                        placeholder={t('retail_price_placeholder')}
                                        type="number"
                                        step="any"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.spacing}>
                            <Grid container spacing={3}>
                                {/* <Grid item xs={6}>
                                    <InputBase
                                        label={t(`retail_price`)}
                                        type="number"
                                        value={price.retailPrice || ''}
                                        onChange={(e) => onRetailPriceChange({ value: e.target.value, id: price.id })}
                                        placeholder={t('retail_price_placeholder')}
                                    />
                                </Grid> */}
                                {/* <Grid item xs={6}>
                                    <InputBase
                                        type="number"
                                        value={price.specialPrice || ''}
                                        label={t(`special_price`)}
                                        onChange={(e) => onSpecialPriceChange({ value: e.target.value, id: price.id })}
                                        placeholder={t(`special_price_placeholder`)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputBase
                                        value={price.priceName || ''}
                                        multiline
                                        onChange={(e) => onPriceNameChange({ value: e.target.value, id: price.id })}
                                        rows={2}
                                        label={t('pricing_name')}
                                        placeholder={t('pricing_name_description')}
                                    />
                                </Grid> */}
                            </Grid>
                        </Grid>
                    </Grid>
                );
            })}
        </Grid>
    );
}

export default React.memo(PricingList);
