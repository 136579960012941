import { isAuthorized } from 'utils/app/auth';
import { useMerchantPermissions } from 'hooks/auth';

const AuthorizationCheck = ({ onAuthorization, onUnAuthorization, ...rest }) => {
    const permissions = rest?.permissions;
    const merchantPermissions = useMerchantPermissions();

    if (isAuthorized({ userPermission: merchantPermissions, permissions })) {
        return onAuthorization();
    } else {
        return onUnAuthorization();
    }
};

export default AuthorizationCheck;
