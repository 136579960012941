import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import SingleDateNew from 'components/dateRange/SingleDateNew';
import scrollIntoView from 'scroll-into-view-if-needed';
import { waitForElementToDisplay } from 'utils/waitElement';

import IncomingBookings from './incomingBookings';
import CheckInList from './CheckInList';
import ZoomSetting from './ZoomSetting';
import ClientArrivedWrapper from './clientArrived/Wrapper';
import CheckoutWrapper from './checkout/Wrapper';
import ClientArrivedList from './ClientArrivedList';

import StaffFiltered from './StaffFiltered';
import Controls from './Controls';
import TableWrapper from './Table';
import HandleFetchingData from './HandleFetchingData';
import useMediaQueries from 'hooks/useMediaQueries';
import { useMerchantInfo } from 'hooks/merchant';
import { Alert } from '@material-ui/lab';

// const HEADER = 64;

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        [theme.breakpoints.down('md')]: {
            maxHeight: `-webkit-fill-available`
        }
    },
    content: {
        marginTop: 0,
        flex: 1,
        maxHeight: `-webkit-fill-available`,
        overflow: 'hidden',
        [theme.breakpoints.down('md')]: {
            maxHeight: `-webkit-fill-available`
        }
    },
    search: {
        height: 50,
        background: theme.palette.common.white,
        borderRadius: 40,
        // boxShadow: theme.shadows[1],
        overflow: 'hidden',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },
    header: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },
    fullWidth: {
        width: '100%'
    }
}));

function Calendar() {
    const classes = useStyles();
    const theme = useTheme();
    const { isMobile } = useMediaQueries();
    const merchant = useMerchantInfo();

    React.useEffect(() => {
        waitForElementToDisplay(`scrollHere`, 10, (node) => {
            scrollIntoView(node, {
                behavior: `instant`,
                block: 'center'
            });
        });
    }, []);

    return (
        <Grid container direction="row">
            <Grid item style={{ flex: 1, position: 'relative' }}>
                <Grid container className={classes.root} direction="column">
                    {merchant?.state >= 1 && (
                        <Grid item>
                            <Alert severity="warning">
                                You have a pending payment. Please proceed with the payment to continue using our
                                service.
                            </Alert>
                        </Grid>
                    )}
                    <Grid item>
                        <Grid
                            className={classes.header}
                            container
                            direction={isMobile ? 'column' : 'row'}
                            alignItems="center"
                            justifyContent="space-between"
                            wrap="nowrap"
                        >
                            <Grid item className={isMobile && classes.fullWidth}>
                                <StaffFiltered />
                            </Grid>

                            <Grid item className={isMobile && classes.fullWidth}>
                                <SingleDateNew />
                            </Grid>
                            {isMobile && (
                                <Grid
                                    item
                                    style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }}
                                    className={classes.fullWidth}
                                >
                                    <ClientArrivedList />
                                </Grid>
                            )}

                            <Grid item className={isMobile && classes.fullWidth}>
                                <Grid
                                    container
                                    spacing={1}
                                    className={classes.search}
                                    direction="row"
                                    alignItems="center"
                                    wrap="nowrap"
                                >
                                    {!isMobile && (
                                        <Grid item>
                                            <ClientArrivedList />
                                        </Grid>
                                    )}
                                    <Grid item>
                                        <IncomingBookings />
                                    </Grid>
                                    <CheckInList />
                                    <Grid item>
                                        <ZoomSetting />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.content}>
                        <TableWrapper />;
                    </Grid>
                </Grid>

                <ClientArrivedWrapper />

                <Controls />
            </Grid>
            <CheckoutWrapper />
            <HandleFetchingData />
        </Grid>
    );
}

export default React.memo(Calendar);
