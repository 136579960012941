//CATEGORIES
export const FETCH_CATEGORIES = '[CATEGORIES]FETCH_STAFF';
export const FETCH_CATEGORIES_SUCCESS = '[CATEGORIES]FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_FAILED = '[CATEGORIES]FETCH_CATEGORIES_FAILED';

export const CREATE_CATEGORY = '[CATEGORIES]CREATE_CATEGORY';
export const CREATE_CATEGORY_SUCCESS = '[CATEGORIES]CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_FAILED = '[CATEGORIES]CREATE_CATEGORY_FAILED';

export const UPDATE_CATEGORY = '[CATEGORIES]UPDATE_CATEGORY';
export const UPDATE_CATEGORY_SUCCESS = '[CATEGORIES]UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_FAILED = '[CATEGORIES]UPDATE_CATEGORY_FAILED';

export const DELETE_CATEGORY = '[CATEGORIES]DELETE_CATEGORY';
export const DELETE_CATEGORY_SUCCESS = '[CATEGORIES]DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_FAILED = '[CATEGORIES]DELETE_CATEGORY_FAILED';

//CATEGORIES
export const FETCH_SERVICES = '[CATEGORIES]FETCH_SERVICES';
export const FETCH_SERVICES_SUCCESS = '[CATEGORIES]FETCH_SERVICES_SUCCESS';
export const FETCH_SERVICES_FAILED = '[CATEGORIES]FETCH_SERVICES_FAILED';

export const CREATE_SERVICE = '[CATEGORIES]CREATE_SERVICE';
export const CREATE_SERVICE_SUCCESS = '[CATEGORIES]CREATE_SERVICE_SUCCESS';
export const CREATE_SERVICE_FAILED = '[CATEGORIES]CREATE_SERVICE_FAILED';

export const UPDATE_SERVICE = '[CATEGORIES]UPDATE_SERVICE';
export const UPDATE_SERVICE_SUCCESS = '[CATEGORIES]UPDATE_SERVICE_SUCCESS';
export const UPDATE_SERVICE_FAILED = '[CATEGORIES]UPDATE_SERVICE_FAILED';

export const DELETE_SERVICE = '[CATEGORIES]DELETE_SERVICE';
export const DELETE_SERVICE_SUCCESS = '[CATEGORIES]DELETE_SERVICE_SUCCESS';
export const DELETE_SERVICE_FAILED = '[CATEGORIES]DELETE_SERVICE_FAILED';

export const UPDATE_SERVICE_AVATAR = `[SERVICE]UPDATE_SERVICE_AVATAR`;

export const REORDER_CATEGORY = 'REORDER_CATEGORY';
export const REORDER_SERVICE = 'REORDER_SERVICE';

export const ADD_SERVICE_TO_CATEGORY = `ADD_SERVICE_TO_CATEGORY`;
export const UPDATE_SERVICE_TO_CATEGORY = `UPDATE_SERVICE_TO_CATEGORY`;
export const DELETE_SERVICE_TO_CATEGORY = `DELETE_SERVICE_TO_CATEGORY`;
export const UPDATE_AVATAR_SERVICE_TO_CATEGORY = `UPDATE_AVATAR_SERVICE_TO_CATEGORY`;
export const RE_ORDER_SERVICE_TO_CATEGORY = `RE_ORDER_SERVICE_TO_CATEGORY`;

export const FETCH_SERVICE_CATEGORIES_SUCCESS = `FETCH_SERVICE_CATEGORIES_SUCCESS`;
