import * as types from './types';
import produce from 'immer';

const INITIAL_STATE = {
    analyze: {
        id: {
            bookings: [
                { label: '06-26-2021', success: 200, noshow: 2, cancel: 12 },
                { label: '06-27-2021', success: 100, noshow: 1, cancel: 12 }
            ],
            newClients: [
                { label: '01-01-2021', clients: 2 },
                { label: '02-01-2021', clients: 1 }
            ],
            smsSchedules: [
                { label: '01-01-2021', schedules: 300 },
                { label: '02-01-2021', schedules: 120 }
            ],
            negativeReviews: [
                { label: '01-01-2021', reviews: 300 },
                { label: '02-01-2021', reviews: 120 }
            ],
            sms: [
                { label: '01-01-2021', sms: 300 },
                { label: '02-01-2021', sms: 120 }
            ],
            emails: [
                { label: '01-01-2021', emails: 300 },
                { label: '02-01-2021', emails: 120 }
            ],
            totalSms: 120,
            totalEmails: 2000,
            totalNewClients: 12,
            totalSmsSchedule: 120,
            totalNegativeReviews: 12
        }
    },
    merchants: [],
    groups: [],
    logs: {
        id: null
    }
};

export default function smsSchedules(state = INITIAL_STATE, { type, payload }) {
    switch (type) {
        case types.FETCH_MERCHANT_ANALYZE_RANGE_BY_ID: {
            const { merchantId, analyze } = payload;
            const { analyze: analyzeStore } = state;

            const newAnalyze = produce(analyzeStore, (draft) => {
                if (!draft?.[merchantId]) {
                    draft[merchantId] = {};
                }

                draft[merchantId] = analyze;
            });
            return {
                ...state,
                analyze: newAnalyze
            };
        }

        case types.FETCH_ACTIVE_MERCHANTS: {
            const { merchants } = payload;

            return {
                ...state,
                merchants
            };
        }

        case types.CREATE_MERCHANT: {
            const { merchant } = payload;
            const { merchants } = state;

            return {
                ...state,
                merchants: [...merchants, merchant]
            };
        }

        case types.UPDATE_MERCHANT: {
            const { merchantId, merchant: newMerchant } = payload;
            const { merchants } = state;
            const newMerchants = merchants?.map((merchant) => {
                if (+merchant?.id === +merchantId) {
                    return newMerchant;
                }

                return merchant;
            });

            return {
                ...state,
                merchants: newMerchants
            };
        }

        case types.FETCH_GROUPS: {
            const { groups } = payload;

            return {
                ...state,
                groups
            };
        }

        case types.CREATE_GROUP: {
            const { newGroup } = payload;

            return {
                ...state,
                groups: [...state?.groups, newGroup]
            };
        }

        case types.UPDATE_GROUP: {
            const { groupId, updatedGroup } = payload;

            return {
                ...state,
                groups: state?.groups?.map((group) => {
                    if (+group?.id === +groupId) {
                        return updatedGroup;
                    }
                    return group;
                })
            };
        }

        case types.DELETE_GROUP: {
            const { id } = payload;

            return {
                ...state,
                groups: state?.groups?.filter((g) => +g?.id !== +id)
            };
        }

        case types.CREATE_MERCHANT_GROUP: {
            const { gId, groupMerchants } = payload;

            return {
                ...state,
                groups: state?.groups?.map((g) => {
                    if (+g?.id === +gId) {
                        g.groupMerchants = groupMerchants;
                    }

                    return g;
                })
            };
        }

        case types.DELETE_MERCHANT_GROUP: {
            const { gId, gMId } = payload;

            return {
                ...state,
                groups: state?.groups?.map((g) => {
                    if (+g?.id === +gId) {
                        g.groupMerchants = g?.groupMerchants?.filter((gM) => +gM?.id !== +gMId);
                    }
                    return g;
                })
            };
        }

        case types.FETCH_BOOKING: {
            const { booking, logs } = payload;
            return {
                ...state,
                logs: {
                    [booking?.id]: {
                        ...booking,
                        logs
                    }
                }
            };
        }

        default:
            return state;
    }
}
