import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Chip, Avatar, IconButton, Dialog } from '@material-ui/core';
import * as settingActions from 'views/settings/actions';
import { useDispatch } from 'react-redux';
import { useGroups, useMerchants } from 'hooks/settings';
import AddSVG from 'assets/images/svg/AddSVG';
import DeleteSVG from 'assets/images/svg/DeleteSVG';
import ButtonIconWrapper from 'components/button/ButtonIconWrapper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { getImageUrl } from 'utils/image';
import { MerchantState, CONFIRM_TYPES } from 'const';
import Badge from '@material-ui/core/Badge';
import CreateGroup from './CreateGroup';
import ConfirmDialog from 'components/dialog/Confirm';
import AddPermission from './AddPermission';
import AddMerchants from './AddMerchants';

const StyledBadge = withStyles((theme, ...rest) => {
    return {
        badge: {
            backgroundColor: (props) => (props?.active ? theme.colors.darkGreen : theme.colors.red),
            color: (props) => (props?.active ? theme.colors.darkGreen : theme.colors.red),
            boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
            '&::after': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                animation: '$ripple 1.2s infinite ease-in-out',
                border: '1px solid currentColor',
                content: '""'
            }
        },
        '@keyframes ripple': {
            '0%': {
                transform: 'scale(.8)',
                opacity: 1
            },
            '100%': {
                transform: 'scale(2.4)',
                opacity: 0
            }
        }
    };
})(Badge);

const useStyles = makeStyles((theme) => ({
    root: {
        height: `calc(100vh - 48px - 60px - 60px)`,
        overflowY: 'auto'
    },
    table: {
        maxWidth: 1024,
        margin: '0 auto'
    },
    link: {
        textDecoration: 'underline',
        color: theme.palette.primary.main,
        cursor: 'pointer'
    },
    dropdown: {
        minWidth: 200,
        '& > div': {
            paddingLeft: 4,
            paddingRight: 4,
            border: 'none',
            borderBottom: `1px solid ${theme.colors.border}`,
            boxShadow: 'none',
            borderRadius: 0,
            '& > div': {
                padding: 0
            },
            '&:focus': {
                border: 'none',
                borderBottom: `1px solid ${theme.colors.black}`,
                boxShadow: 'none'
            }
        }
    }
}));

function GroupItem({ title, groupMerchants, id, merchantOptions, permissions }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [openPermission, setOpenPermission] = React.useState(false);
    const [openMerchant, setOpenMerchant] = React.useState(false);
    const [isOpenDelete, setIsOpenDelete] = React.useState(false);

    const handleOpen = (e) => {
        setOpenMerchant(true);
    };

    const handleClose = () => {
        setOpenMerchant(false);
    };

    const handleOpenPermission = (e) => {
        setOpenPermission(true);
    };

    const handleClosePermission = (e) => {
        setOpenPermission(false);
    };

    const handleDeleteGroup = React.useCallback(() => {
        dispatch(
            settingActions.deleteGroup({
                id,
                successCallback: () => {},
                error: (message) => {
                    console.log('message', message);
                }
            })
        );
    }, [dispatch, id]);

    const handleOpenDeleteConfirm = React.useCallback(() => {
        setIsOpenDelete(true);
    }, []);

    const handleCloseDeleteConfirm = React.useCallback(() => {
        setIsOpenDelete(false);
    }, []);

    const handleDeleteGroupMerchant = React.useCallback(
        (gmId) => {
            dispatch(
                settingActions.removeGroupMerchantToGroup({
                    gMId: +gmId,
                    gId: +id,
                    error: () => {},
                    success: () => {}
                })
            );
        },
        [dispatch, id]
    );

    const handleDeleteGroupPermission = React.useCallback(
        (p) => {
            dispatch(
                settingActions.updateGroup({
                    groupId: id,
                    newGroup: {
                        permissions: permissions?.filter((permission) => p !== permission)
                    },
                    error: (message) => {
                        console.log('message', message);
                    },
                    success: () => {
                        console.log('delete success');
                    }
                })
            );
        },
        [dispatch, id, permissions]
    );

    return (
        <TableRow>
            <TableCell component="th" scope="row">
                {title}
            </TableCell>
            <TableCell colSpan={6}>
                <Grid container direction="row" alignItems="center" spacing={1}>
                    {groupMerchants?.map((gM) => {
                        const merchant = gM?.merchant;
                        return (
                            <Grid key={gM?.id} item>
                                <StyledBadge
                                    className={classes.badged}
                                    active={MerchantState.Active === merchant?.state}
                                    overlap="circular"
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right'
                                    }}
                                    variant="dot"
                                >
                                    <Chip
                                        avatar={<Avatar alt={merchant?.title} src={getImageUrl(merchant?.logo)} />}
                                        label={merchant?.title}
                                        onDelete={() => handleDeleteGroupMerchant(gM?.id)}
                                    />
                                </StyledBadge>
                            </Grid>
                        );
                    })}
                    <Grid item>
                        <Typography onClick={handleOpen} className={classes.link} variant="body2">
                            Add more
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell colSpan={2} style={{ textAlign: 'right' }}>
                <Grid container direction="row" alignItems="center" spacing={1}>
                    {permissions?.map((p) => {
                        return (
                            <Grid key={p} item>
                                <Chip label={p} onDelete={() => handleDeleteGroupPermission(p)} />
                            </Grid>
                        );
                    })}
                    <Grid item>
                        <Typography onClick={handleOpenPermission} className={classes.link} variant="body2">
                            Add permission
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell style={{ textAlign: 'right' }}>
                <IconButton onClick={handleOpenDeleteConfirm}>
                    <DeleteSVG />
                </IconButton>
            </TableCell>

            <Dialog open={openMerchant} onClose={handleClose}>
                <AddMerchants
                    merchantOptions={merchantOptions}
                    id={id}
                    title={title}
                    defaultGroupMerchants={groupMerchants}
                    onClose={handleClose}
                />
            </Dialog>

            <Dialog open={openPermission} onClose={handleClosePermission}>
                <AddPermission id={id} title={title} defaultPermissions={permissions} onClose={handleClosePermission} />
            </Dialog>

            <ConfirmDialog
                open={isOpenDelete}
                title={`Delete?`}
                confirmText={'Delete group?'}
                onAgree={handleDeleteGroup}
                onDisagree={handleCloseDeleteConfirm}
                type={CONFIRM_TYPES.ERROR}
            />
        </TableRow>
    );
}

function GroupMerchants() {
    const classes = useStyles();
    const merchants = useMerchants();
    const groups = useGroups();

    const [isOpenCreateGroup, setIsOpenCreateGroup] = React.useState(false);

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(
            settingActions.fetchMerchants({
                successCallback: () => {},
                errorCallback: () => {
                    console.log('fetchMerchants failed');
                }
            })
        );
    }, [dispatch]);

    React.useEffect(() => {
        dispatch(
            settingActions.fetchGroups({
                successCallback: () => {},
                errorCallback: () => {
                    console.log('fetchGroups failed');
                }
            })
        );
    }, [dispatch]);

    const handleOpenCreateGroup = React.useCallback(() => {
        setIsOpenCreateGroup(true);
    }, []);

    const handleCloseCreateGroup = React.useCallback(() => {
        setIsOpenCreateGroup(false);
    }, []);

    const availableMerchants = React.useMemo(() => {
        return merchants?.map((m) => ({
            ...m,
            label: m?.title,
            value: m?.id,
            icon: () => <Avatar alt={m?.title} src={getImageUrl(m?.logo)} />
        }));
    }, [merchants]);

    return (
        <Grid container wrap="nowrap" direction="column" className={classes.root} spacing={2}>
            <Grid item>
                <Grid container direction="row" alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <Typography variant="h4">Group</Typography>
                    </Grid>
                    <Grid item>
                        <ButtonIconWrapper icon={<AddSVG />} size="large" onClick={handleOpenCreateGroup} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <TableContainer className={classes.table} component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Group Name</TableCell>
                                <TableCell colSpan={6}>Merchants</TableCell>
                                <TableCell colSpan={2}>Permissions</TableCell>
                                <TableCell style={{ textAlign: 'right' }}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {groups.map((g) => (
                                <GroupItem merchantOptions={availableMerchants} key={g?.id} {...g} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Dialog
                maxWidth={false}
                className={classes.dialog}
                open={isOpenCreateGroup}
                onClose={handleCloseCreateGroup}
            >
                <CreateGroup merchantOptions={availableMerchants} onClose={handleCloseCreateGroup} />
            </Dialog>
        </Grid>
    );
}

export default GroupMerchants;
