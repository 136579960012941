import * as types from './types';
import { getItem } from 'utils/localStorage';
import { TOKEN_USER } from 'const';
import moment from 'moment-timezone';
const DEFAULT_TIMEZONE = 'America/Toronto';

const user = getItem(TOKEN_USER);

const timezone = user?.timezone || DEFAULT_TIMEZONE;
moment.suppressDeprecationWarnings = true;
moment.tz.setDefault(timezone);

const INITIAL_STATE = {
    user: user
};

export default function Auth(state = INITIAL_STATE, { type, payload }) {
    switch (type) {
        case types.USER_LOGIN_SUCCESS: {
            const { user } = payload;
            return {
                ...state,
                user
            };
        }

        default:
            return state;
    }
}
