import { combineReducers } from 'redux';
import authReducer from '../views/auth/reducer';
import staffReducer from '../views/staff/reducer';
import clientReducer from '../views/clients/reducer';
import serviceReducer from '../views/services/reducer';
import notifierReducer from '../notifier/reducer';
import calendarReducer from '../views/calender/reducer';
import merchantReducer from '../merchant/reducer';
import voucherReducer from 'views/voucher/reducer';
import appReducer from 'app/reducer';
import smsReducer from '../views/sms/reducer';
import analyzeReducer from '../views/analyze/reducer';
import bookingReducer from '../views/booking/reducer';
import turnReducer from '../views/turn/reducer';
import settingReducer from '../views/settings/reducer';
import hidePriceDateReducer from '../views/prices/reducer';
import checkoutReducer from '../views/payment/reducer';
import statisticReducer from '../views/statistic/reducer';
import checkInReducer from '../views/checkin/reducer';

export default combineReducers({
    auth: authReducer,
    staff: staffReducer,
    client: clientReducer,
    notifier: notifierReducer,
    service: serviceReducer,
    calendar: calendarReducer,
    merchant: merchantReducer,
    voucher: voucherReducer,
    sms: smsReducer,
    app: appReducer,
    analyze: analyzeReducer,
    booking: bookingReducer,
    turn: turnReducer,
    setting: settingReducer,
    price: hidePriceDateReducer,
    checkout: checkoutReducer,
    statistic: statisticReducer,
    checkIn: checkInReducer
});
