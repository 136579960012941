import React from 'react';
import { Grid, CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from 'components/dialog/Title';
import DialogActions from 'components/dialog/Action';
import DialogContent from 'components/dialog/Content';
import { useFormik } from 'formik';
import { string, object, ref } from 'yup';
import InputBase from 'components/input';
import { useTranslation } from 'react-i18next';
import Button from 'components/button/Base';
import useMediaQueries from 'hooks/useMediaQueries';
import { useDispatch } from 'react-redux';
import * as staffActions from './action';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    root: {},
    content: {
        minWidth: 400
    },
    formContent: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(3)
    },
    fullWidth: {
        width: '100%'
    },
    active: {
        background: theme.colors.blue,
        color: theme.palette.common.white,
        fontWeight: 'bold'
    },
    btnSubmit: {
        position: 'relative'
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    }
}));

const STATES = {
    SET_PASSWORD: 'SET_PASSWORD',
    CHANGE_PASSWORD: 'CHANGE_PASSWORD'
};

const StaffCredentialSchema = object({
    // oldPassword: string(),
    newPassword: string().min(8, 'Min is 8 character').required('This filed is required'),
    confirmPassword: string()
        .min(8, 'Min is 8 character')
        .oneOf([ref('newPassword'), null], 'Password not matched')
        .required('This filed is required')
});

function ChangeStaffCredential({ onClose, staff }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { isMobile } = useMediaQueries();
    const { t } = useTranslation();

    const currentState = React.useMemo(() => {
        return staff?.user?.isSecured ? STATES.CHANGE_PASSWORD : STATES.SET_PASSWORD;
    }, [staff]);

    const stopPropagation = (e) => {
        e.stopPropagation();
        e.preventDefault();
    };

    const formik = useFormik({
        initialValues: {
            oldPassword: '',
            newPassword: '',
            confirmPassword: ''
        },
        validationSchema: StaffCredentialSchema,
        onSubmit: (values, { setSubmitting, setStatus }) => {
            setSubmitting(true);

            if (currentState === STATES.SET_PASSWORD) {
                const body = {
                    newPassword: values.newPassword
                };

                dispatch(
                    staffActions.setStaffPassword({
                        staffId: +staff?.id,
                        body,
                        successCallback: () => {
                            console.log('set password success');
                            onClose && onClose();
                        },
                        errorCallback: () => {
                            setSubmitting(false);
                        }
                    })
                );
            } else {
                const body = {
                    newPassword: values.newPassword
                };

                dispatch(
                    staffActions.updateStaffPassword({
                        staffId: +staff?.id,
                        body,
                        successCallback: () => {
                            console.log('update password success');
                            onClose && onClose();
                        },
                        errorCallback: () => {
                            setSubmitting(false);
                        }
                    })
                );
            }
        }
    });

    const { handleSubmit, isSubmitting, isValid, values, errors, touched, handleChange, handleBlur } = formik;

    const isValidFinal = React.useMemo(() => {
        return isValid;
    }, [isValid]);

    return (
        <form onClick={stopPropagation} className={classes.form}>
            <DialogTitle>
                <Typography variant="h5">
                    {currentState === STATES.SET_PASSWORD ? 'Set password' : 'Change password'}
                </Typography>
            </DialogTitle>
            <DialogContent className={classes.content}>
                <Grid container onClick={stopPropagation} className={classes.formContent} direction="column">
                    <Grid item>
                        <Grid container spacing={2} direction="column" alignItems="center">
                            <Grid item className={classes.fullWidth}>
                                <InputBase
                                    type="password"
                                    label={t(`newPassword`)}
                                    name="newPassword"
                                    value={values.newPassword}
                                    disabled={isSubmitting}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.newPassword && touched.newPassword}
                                    errorText={errors.newPassword}
                                    placeholder="new password"
                                    autoFocus={currentState === STATES?.SET_PASSWORD ? true : false}
                                />
                            </Grid>
                            <Grid item className={classes.fullWidth}>
                                <InputBase
                                    type="password"
                                    label={t(`confirmPassword`)}
                                    name="confirmPassword"
                                    value={values.confirmPassword}
                                    disabled={isSubmitting}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.confirmPassword && touched.confirmPassword}
                                    errorText={errors.confirmPassword}
                                    placeholder="confirm password"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button startIcon={<CloseIcon />} onClick={onClose} fullWidth={isMobile} variant="outlined">
                    {t(`close`)}
                </Button>
                <div className={classes.btnSubmit} style={{ width: isMobile ? '100%' : '' }}>
                    <Button
                        startIcon={<SaveIcon />}
                        disabled={isSubmitting || !isValidFinal}
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                    >
                        {t(`save`)}
                    </Button>
                    {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
            </DialogActions>
        </form>
    );
}

export default React.memo(ChangeStaffCredential);
