import { useBookingLogsByBookingId } from 'hooks/calendar';
import React from 'react';
import BookingLogs from 'views/calender/calendar/components/BookingLogs';

function Logs({ bookingId, createdAt }) {
    const bookingLogs = useBookingLogsByBookingId(bookingId);

    return <BookingLogs logs={bookingLogs} createdAt={createdAt} showDetail />;
}

export default Logs;
