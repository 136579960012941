import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import { INPUT_HEIGHT, BORDER_RADIUS } from 'const/style';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
    root: {},
    search: {
        position: 'relative',
        height: INPUT_HEIGHT,
        borderRadius: BORDER_RADIUS,
        backgroundColor: theme.palette.common.white,
        width: '100%',
        minWidth: 300,
        '&:focus-within': {
            border: `1px solid ${theme.palette.primary.main}`
        }
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
        height: '100%'
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: theme.spacing(6),
        transition: theme.transitions.create('width'),
        width: '100%',
        fontSize: 14,
        fontWeight: 'normal',
        color: theme.colors.primaryText
    }
}));

function Search({ placeholder = 'Default search value', className, ...rest }) {
    const classes = useStyles();

    return (
        <Grid className={`${classes.search} ${className}`}>
            <div className={classes.searchIcon}>
                <SearchIcon />
            </div>
            <InputBase
                fullWidth
                placeholder={placeholder}
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput
                }}
                inputProps={{ 'aria-label': 'search' }}
                {...rest}
            />
        </Grid>
    );
}

export default React.memo(Search);
