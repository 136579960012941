import { useSelector } from 'react-redux';
import { getFullName } from 'utils/naming';
import {
    formatDate,
    getOffsetDays,
    getAppendMerchantTimezoneToDate,
    getUTCbyMerchantDatetimeFormat,
    generateNextDate,
    FRIENDLY_DATE_TIME_FORMAT
} from 'utils/timing';
import orderBy from 'lodash/orderBy';
import { useServices } from 'hooks/service';
import { convertStaffsServiceDuration } from 'utils/app/staff';
import { isEqual } from 'lodash';

export function useStaffs() {
    return useSelector((state) => state?.staff?.list);
}

export function useStaffList() {
    const staffs = useStaffs();
    return orderBy(staffs, ['orderIndex'], ['desc']);
}

export function useActiveStaffList() {
    const staffs = useStaffs();

    const activeStaffs = staffs?.filter((staff) => staff?.staffState !== 1);
    return orderBy(activeStaffs, ['orderIndex'], ['desc']);
}

export function useIsFetchingStaff() {
    return useSelector((state) => state.staff?.isFetchingStaff);
}

export function useIsFetchingStaffWorkingHours() {
    return useSelector((state) => state.staff?.isFetchingWorkingHours);
}

export function useStaffWorkingHoursUsers() {
    return useSelector((state) => state.staff?.staffWorkingHoursUsers);
}

export function useStaffWorkingHoursSchedulesList() {
    return useSelector((state) => state.staff?.staffWorkingHoursSchedules);
}

export function useStaffWorkingHoursSchedules({ staffId }) {
    return useSelector((state) => state.staff?.staffWorkingHoursSchedules?.[staffId]);
}

export function useStaffOptions() {
    const staffs = useStaffs();
    const staffList = orderBy(staffs, ['orderIndex'], ['desc']);

    return staffList
        ?.filter((staff) => !staff?.staffState)
        ?.map((sf) => ({
            ...sf,
            label: getFullName(sf),
            value: sf?.id
        }));
}

export function useStaffsServiceDuration() {
    const staffs = useStaffs();
    const services = useServices();
    return convertStaffsServiceDuration({ staffs, services });
}

export function useClosedDateList() {
    return useSelector((state) => state.staff?.closedDates);
}

export function useClosedDates() {
    return useSelector((state) => state.staff?.closedDates?.filter((clDate) => !clDate?.isDeleted));
}

export function useIsFetchingClosedDates() {
    return useSelector((state) => state.staff?.isFetchingClosedDates);
}

export function useClosedDatesForTable() {
    return useSelector((state) =>
        state.staff?.closedDates
            ?.filter((clDate) => !clDate?.isDeleted)
            ?.map((clDate) => ({
                ...clDate,
                ranges: `${formatDate(
                    new Date(getAppendMerchantTimezoneToDate(clDate.startDate)),
                    FRIENDLY_DATE_TIME_FORMAT
                )} - ${formatDate(
                    new Date(getAppendMerchantTimezoneToDate(clDate.endDate)),
                    FRIENDLY_DATE_TIME_FORMAT
                )}`,
                numOfDays: getOffsetDays({ day1: clDate.endDate, day2: clDate.startDate }) + 1
            }))
    );
}

export function useClosedDatesFormatDate() {
    const list = useClosedDateList();
    const closedDates = list?.filter((clDate) => !clDate?.isDeleted);

    return closedDates?.reduce((list, clDate) => {
        const { startDate, endDate, description } = clDate;
        const offset = getOffsetDays({ day1: endDate, day2: startDate });
        const startDateFormatted = getUTCbyMerchantDatetimeFormat(startDate);
        const ranges = generateNextDate({
            startDate: startDateFormatted,
            nextDaysNumber: offset + 1
        })?.map((range) => ({ ...range, description }));
        list = list.concat(ranges);
        return list;
    }, []);
}

export function useStaffMapping() {
    const staffs = useStaffs();

    return staffs?.reduce((obj, staff) => {
        obj[staff?.id] = staff;
        return obj;
    }, {});
}

export function useStaffById(staffId) {
    return useSelector(
        (state) => {
            const list = state?.staff?.list;

            return list?.reduce((obj, staff) => {
                obj[staff?.id] = staff;
                return obj;
            }, {})?.[staffId];
        },
        (prev, current) => isEqual(prev, current)
    );
}
