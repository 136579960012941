import React from 'react';
import { makeStyles, alpha } from '@material-ui/core/styles';
import { Grid, Typography, Hidden, Slide } from '@material-ui/core';
import { ArrowBackTwoTone } from '@material-ui/icons';
import useMediaQueries from 'hooks/useMediaQueries';
import ServiceChoosing from './ServiceChoosing';
import BookingSuccess from './BookingSuccess';
import PricingTotal from './PricingTotal';
import StaffChoosing from './StaffChoosing';
import ButtonBase from 'components/button/Base';
import Confirmation from './Confirmation';
import { useDispatch } from 'react-redux';
import * as bookingActions from './actions';
import Spinner from 'components/spinner';
import { useSelectedServices, useCurrentStep, useBookingStaffs, useSelectedStaff } from 'hooks/checkIn';
import { DEFAULT_DATE } from 'utils/timing';
import { useHistory } from 'react-router-dom';
import { useMerchantDetail } from 'hooks/merchant';
import NextIcon from '@material-ui/icons/ArrowForward';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const BNS_FOOTER = 40;

const useStyles = makeStyles((theme) => ({
    root: {
        height: `calc(100vh - ${BNS_FOOTER}px)`,
        width: '100vw',
        position: 'relative',
        overflowY: 'auto',
        maxHeight: `-webkit-fill-available`
    },
    rHeader: {
        background: theme.palette.primary.main,
        width: '100%',
        top: 0,
        left: 0,
        position: 'sticky',
        zIndex: 100,
        height: 120,
        paddingLeft: 0,
        paddingRight: 0,
        [theme.breakpoints.down('md')]: {
            paddingLeft: 10,
            paddingRight: 10
        }
    },
    rTopRight: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1)
    },
    rContent: {
        height: `100vh`,
        maxWidth: 960,
        margin: '0px auto',
        width: '100%',
        position: 'relative',
        maxHeight: `-webkit-fill-available`,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 10,
            paddingRight: 10,
            margin: `10px auto`
        }
    },
    rSpinner: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 100
    },
    rHeaderContent: {
        width: '100%',
        transition: `0.6s`,
        maxWidth: 960,
        margin: `0 auto`,
        height: '100%'
    },

    rBooking: {
        top: -20,
        position: `absolute`,
        width: `100%`,
        left: 0
    },
    rFooter: {
        position: 'fixed',
        bottom: BNS_FOOTER,
        left: 0,
        width: '100vw',
        height: 80,
        background: theme.palette.common.white,
        zIndex: 100
    },
    rFooterContainer: {
        maxWidth: 960,
        margin: `0 auto`,
        position: 'relative',
        padding: 0,
        height: '100%',
        overflow: 'hidden'
    },

    rFooterBns: {
        background: '#f7f8fa',
        width: '100%',
        bottom: 0,
        left: 0,
        zIndex: 100,
        height: BNS_FOOTER,
        paddingLeft: 0,
        paddingRight: 0,
        position: 'fixed'
        // borderTop: `1px solid ${theme.colors.solitude}`
    },
    rFooterBnsContainer: {
        maxWidth: 960,
        margin: `0 auto`,
        position: 'relative',
        height: '100%',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    rBackArrow: {
        color: theme.palette.common.white,
        cursor: 'pointer'
    },
    rStepNumber: {
        color: alpha(theme.palette.common.white, 0.8),
        fontWeight: 400
    },
    rStepLabel: {
        color: theme.palette.common.white,
        fontWeight: 600,
        [theme.breakpoints.down('sm')]: {
            fontSize: 24
        }
    },
    rAvatar: {
        width: 32,
        height: 32,
        background: `rgb(229, 241, 255)`,
        color: `rgb(3, 122, 255)`,
        fontSize: 24,
        fontWeight: 700,
        borderRadius: theme.radius(3),
        cursor: 'pointer',
        border: `2px solid ${theme.palette.common.white} !important`
    },
    rmr3: {
        marginRight: theme.spacing(3)
    },

    link: {
        color: `rgb(3, 122, 255)`,
        marginLeft: theme.spacing(1),
        '&:hover': {
            textDecoration: 'underline',
            cursor: 'pointer'
        }
    }
}));

function StepContent({ step = 1, ...rest }) {
    switch (step) {
        case 1:
            return <ServiceChoosing {...rest} />;
        case 2:
            return <StaffChoosing {...rest} />;
        case 3:
            return <Confirmation {...rest} />;

        case 4:
            return <BookingSuccess {...rest} />;
        default:
            return <ServiceChoosing {...rest} />;
    }
}

const STEP_LABELS = {
    1: 'Select service',
    2: 'Select staff',
    3: 'Add Your Information',
    4: `Awesome! You're all set!`
};

function Booking() {
    const dispatch = useDispatch();
    const services = useSelectedServices();
    const staffs = useBookingStaffs();
    const step = useCurrentStep();
    const history = useHistory();
    const staff = useSelectedStaff();
    const [isFetching, setIsFetching] = React.useState(false);

    const merchant = useMerchantDetail();

    const isShowFooter = React.useMemo(() => {
        return step === 1 && services.length > 0;
    }, [step, services]);

    const classes = useStyles({ isShowFooter });

    const { isTablet, isMobile } = useMediaQueries();

    const isTabletOrMobile = React.useMemo(() => {
        return isTablet || isMobile;
    }, [isMobile, isTablet]);

    const siteName = React.useMemo(() => merchant?.siteName, [merchant]);

    React.useEffect(() => {
        if (siteName) {
            setIsFetching(true);
            dispatch(
                bookingActions.fetchCheckInData({
                    slug: siteName,
                    successCallback: () => {
                        console.log('inilizing success');
                        setIsFetching(false);
                    },
                    errorCallback: () => {
                        console.log('inilizing failed');
                        setIsFetching(false);
                    }
                })
            );
        }
    }, [siteName, dispatch]);

    const nextStep = () => {
        dispatch(bookingActions.goNextStep());
    };

    const prevStep = () => {
        if (step === 2) {
            dispatch(bookingActions.addStaff({ staff: null }));
        }
        if (step === 3) {
            dispatch(bookingActions.addDate({ date: DEFAULT_DATE, dateIndex: 0 }));
        }
        if (step === 4) {
            dispatch(bookingActions.addTime({ time: null }));
        }

        dispatch(bookingActions.goPrevStep());
    };

    const firstLoad = isFetching && staffs === null;

    return (
        <>
            <Grid id={'booking_'} container className={classes.root} direction="column" wrap="nowrap">
                <Grid item className={classes.rHeader}>
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        className={classes.rHeaderContent}
                        wrap="nowrap"
                    >
                        <Grid item>
                            <Grid container direction="column" wrap="nowrap">
                                <Grid item>
                                    <Grid container direction="row" alignItems="center" wrap="nowrap">
                                        {step !== 1 && step !== 4 && (
                                            <Grid
                                                item
                                                className={classes.rmr3}
                                                style={{ display: 'flex' }}
                                                onClick={prevStep}
                                            >
                                                <ArrowBackTwoTone className={classes.rBackArrow} />
                                            </Grid>
                                        )}
                                        <Grid item>
                                            <Typography noWrap variant="caption" className={classes.rStepNumber}>
                                                {step !== 4 ? `Step ${step} of 3` : ``}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h2" className={classes.rStepLabel}>
                                        {step === 3
                                            ? `${STEP_LABELS?.[step]} ${
                                                  !!staff && !staff?.isSystem ? `with ${staff?.fullName}` : ``
                                              }`
                                            : STEP_LABELS?.[step]}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.rContent}>
                    {firstLoad && (
                        <div className={classes.rSpinner}>
                            <Spinner size={isTabletOrMobile ? 40 : 70} thick={7} />
                        </div>
                    )}
                    {!firstLoad && (
                        <Grid item className={classes.rBooking}>
                            <Grid container direction="row" spacing={isMobile ? 0 : 5}>
                                <Grid item xs={12} sm={7}>
                                    <StepContent isShowFooter={isShowFooter} step={step} />
                                </Grid>
                                <Hidden xsDown>
                                    <Grid item sm={5}>
                                        <PricingTotal />
                                    </Grid>
                                </Hidden>
                            </Grid>
                        </Grid>
                    )}
                </Grid>

                <Slide direction="up" in={isShowFooter} mountOnEnter unmountOnExit>
                    <Grid item className={classes.rFooter}>
                        <Grid
                            container
                            className={classes.rFooterContainer}
                            alignItems="center"
                            justifyContent="flex-end"
                            wrap="nowrap"
                        >
                            <Grid item>
                                <ButtonBase
                                    onClick={nextStep}
                                    color="primary"
                                    variant="contained"
                                    endIcon={<NextIcon />}
                                >
                                    Book
                                </ButtonBase>
                            </Grid>
                        </Grid>
                    </Grid>
                </Slide>
            </Grid>

            <Grid item className={classes.rFooterBns}>
                <div className={`${classes.rFooterBnsContainer} p-2 p-sm-0`}>
                    {services?.length ? (
                        <Grid item>
                            <Typography display="inline" variant="body2">
                                {services?.length} services
                            </Typography>
                        </Grid>
                    ) : (
                        <div>
                            <Typography display="inline" variant="body2">
                                Powered by
                            </Typography>
                            <Typography
                                onClick={() => history.push('/')}
                                className={classes.link}
                                display="inline"
                                variant="body1"
                            >
                                Book Salon
                            </Typography>
                        </div>
                    )}
                    <div>
                        <Typography variant="body2">
                            <span className="d-none d-sm-inline-block">Hotline:</span>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="rgb(3, 122, 255)"
                                className="bi bi-telephone ms-3 me-1"
                                viewBox="0 0 16 16"
                            >
                                <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                            </svg>
                            {merchant?.phone}
                        </Typography>
                    </div>
                </div>
            </Grid>
        </>
    );
}

export default React.memo(Booking);
