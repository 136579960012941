export function getFullName(user) {
    if (!user) return '';
    const { fullName, firstName, lastName, email } = user;

    if (fullName) return fullName;
    if (firstName === lastName && firstName) return firstName;
    if (!firstName && !lastName) return email?.split('@')?.[0];

    return ((firstName ? firstName : '') + ' ' + (lastName ? lastName : '')).trim();
}

export function addZeroToSerialNumber(serialNumber) {
    if (serialNumber < 10) {
        return `00` + serialNumber;
    } else if (serialNumber < 100) {
        return `0` + serialNumber;
    } else {
        return serialNumber;
    }
}

export function titleCase(str) {
    var splitStr = str?.toLowerCase()?.split(' ');
    for (var i = 0; i < splitStr?.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr?.join(' ');
}
