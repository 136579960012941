import { useSelector } from 'react-redux';
import { MERCHANT_PERMISSIONS, GROUP_MERCHANT_PERMISSIONS } from 'const';
import momentTz from 'moment-timezone';
import { generateNumberOptions } from 'utils/timing';

export function useMerchantDetail() {
    return useSelector((state) => state.merchant?.detail);
}

export function useMerchantInfo() {
    return useSelector((state) => {
        const info = state.merchant?.detail;
        if (!!info) {
            info[`TIME_12`] = true;
        }
        return info;
    });
}

export function useMerchantTime12() {
    return true;
}

export function useMerchantCurrency() {
    return useSelector((state) => state.merchant?.detail?.currency);
}

export function useIsFetchingMerchantInfo() {
    return useSelector((state) => state.merchant?.isFetchingMerchantInfo);
}

export function useSmsRemaining() {
    return useSelector((state) => state.merchant?.detail?.smsRemaining || 0);
}

export function usePermissionsOptions() {
    return Object.keys(MERCHANT_PERMISSIONS)?.map((permission) => ({
        label: permission,
        value: permission
    }));
}

export function useGroupPermissionsOptions() {
    return Object.keys(GROUP_MERCHANT_PERMISSIONS)?.map((permission) => ({
        label: permission,
        value: permission
    }));
}

export function userPaymentCurrencyOptions() {
    return ['cad', 'usd']?.map((c) => ({
        label: c,
        value: c
    }));
}

export function useMerchantSlotInterval() {
    return [
        { label: '15 mins', value: 15 },
        { label: '30 mins', value: 30 },
        { label: '45 mins', value: 45 },
        { label: '1 hour', value: 60 }
    ];
}

export function useTimeZoneOptions() {
    const timezones = momentTz.tz.names();

    return timezones?.map((tz) => ({
        value: tz,
        label: tz
    }));
}

export function useApplicationFeeOptions() {
    return [
        { label: '$0', value: 1 },
        { label: '$1', value: 100 },
        { label: '$2', value: 200 },
        { label: '$3', value: 300 },
        { label: '$4', value: 400 },
        { label: '$5', value: 500 }
    ];
}

export function useBookingApplicationFeeOptions() {
    return [
        { label: '$0', value: 1 },
        { label: '10cent', value: 10 },
        { label: '20cent', value: 20 },
        { label: '30cent', value: 30 },
        { label: '40cent', value: 40 },
        { label: '50cent', value: 50 },
        { label: '60cent', value: 60 },
        { label: '70cent', value: 70 },
        { label: '80cent', value: 80 },
        { label: '90cent', value: 90 },
        { label: '$1', value: 100 },
        { label: '$2', value: 200 },
        { label: '$3', value: 300 }
    ];
}

export function useCancelBeforeBookingMinutesOptions() {
    const hours = generateNumberOptions(60, 60, 1440);

    return [
        { label: '0 mins', value: 1 },
        { label: '15 mins', value: 15 },
        { label: '30 mins', value: 30 },
        { label: '45 mins', value: 45 },
        ...hours?.map((h, index) => ({
            label: `${index + 1} hours`,
            value: h
        }))
    ];
}

export function useCheckInDistanceOptions() {
    return [
        { label: '500 meter', value: 0.5 },
        { label: '1 kilometer', value: 1 },
        { label: '1.5 kilometer', value: 1.5 },
        { label: '2 kilometer', value: 2 },
        { label: '2.5 kilometer', value: 2.5 },
        { label: '3 kilometer', value: 3 }
    ];
}

export function useBeforeBookingMinutesOptions() {
    const hours = generateNumberOptions(60, 60, 1440);

    return [
        { label: '15 mins', value: 15 },
        { label: '30 mins', value: 30 },
        { label: '45 mins', value: 45 },
        ...hours?.map((h, index) => ({
            label: `${index + 1} hours`,
            value: h
        }))
    ];
}

export function useGroupMerchants() {
    return useSelector((state) => state.merchant?.groupMerchants);
}
