import * as types from './types';
import { enqueueSnackbar } from 'notifier/actions';
import {
    getInTurnStaffsApi,
    getOutTurnStaffsApi,
    removeStaffOutOfTurnApi,
    addStaffToTurnApi,
    resetStaffTurnApi,
    reOrderStaffTurnApi
} from 'services/staff';
import orderBy from 'lodash/orderBy';
import { getAppendMerchantTimezoneToDate, getDatetimeFormat } from 'utils/timing';
import moment from 'moment-timezone';

/**
 *
 * @param {OUT TURN} param0
 */

export function getOutTurnList({ query, errorCallback, successCallback }) {
    return async function (dispatch) {
        try {
            const staffs = await getOutTurnStaffsApi(query);
            dispatch(_getOutTurnListSuccess({ staffs: staffs }));
        } catch (error) {
            errorCallback && errorCallback();
        }
    };
}

function _getOutTurnListSuccess({ staffs }) {
    return {
        type: types.GET_OUT_TURN_LIST,
        payload: {
            staffs
        }
    };
}

export function staffMarkDone({ staffId, errorCallback, successCallback }) {
    return async function (dispatch) {
        const startDate = getAppendMerchantTimezoneToDate(getDatetimeFormat(moment()));

        try {
            await addStaffToTurnApi(+staffId, { startDate });
            dispatch(_staffMarkDone({ staffId }));
            dispatch(
                enqueueSnackbar({
                    message: `Register next turn success!`,
                    type: 'success'
                })
            );
            successCallback && successCallback();
        } catch (error) {
            dispatch(
                enqueueSnackbar({
                    message: error?.message,
                    type: 'error'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function _staffMarkDone({ staffId }) {
    return {
        type: types.STAFF_MARK_DONE,
        payload: {
            staffId
        }
    };
}

/**
 *
 * @param {IN TURN} param0
 */

export function getInTurnList({ query, errorCallback, successCallback }) {
    return async function (dispatch) {
        try {
            const staffs = await getInTurnStaffsApi(query);
            dispatch(_getInTurnListSuccess({ staffs }));
            successCallback && successCallback();
        } catch (error) {
            errorCallback && errorCallback();
        }
    };
}

function _getInTurnListSuccess({ staffs }) {
    return {
        type: types.GET_IN_TURN_LIST,
        payload: {
            staffs
        }
    };
}

export function kickStaffOutOfTurn({ staffName, staffId, errorCallback, successCallback }) {
    return async function (dispatch) {
        dispatch(_kickStaffOutOfTurn({ staffId }));
        try {
            await removeStaffOutOfTurnApi(+staffId);
            dispatch(
                enqueueSnackbar({
                    message: `${staffName} has been assigned`,
                    type: 'success'
                })
            );
            dispatch(_kickStaffOutOfTurn({ staffId }));
            successCallback && successCallback();
        } catch (error) {
            errorCallback && errorCallback();
        }
    };
}

export function _kickStaffOutOfTurn({ staffId }) {
    return {
        type: types.OWNER_KICK_STAFF_OUT_OF_TURN_LIST,
        payload: {
            staffId
        }
    };
}

export function resetInTurnList({ successCallback, errorCallback }) {
    return async function (dispatch) {
        try {
            await resetStaffTurnApi();
            dispatch(
                enqueueSnackbar({
                    message: `Reset in turn list successfully!`,
                    type: 'success'
                })
            );
            dispatch(_resetInTurnList());
            successCallback && successCallback();
        } catch (error) {
            errorCallback && errorCallback();
        }
    };
}

export function _resetInTurnList() {
    return {
        type: types.RESET_IN_TURN_LIST
    };
}

export function registerStaffToInTurnSocket({ staff }) {
    return {
        type: types.REGISTER_STAFF_TO_IN_TURN_SOCKET,
        payload: {
            staff
        }
    };
}

export function registerStaffToOutTurnSocket({ staff }) {
    return {
        type: types.REGISTER_STAFF_TO_OUT_TURN_SOCKET,
        payload: {
            staff
        }
    };
}

export function reOrderStaffTurn({ afterStaffId, dragId, beforeStaffId, dropIndex, errorCallback, successCallback }) {
    return async function (dispatch, getState) {
        const { turn } = getState();
        const staffs = orderBy(
            turn?.inTurnStaffs?.filter((staff) => staff?.isInTurn),
            ['turnOrder'],
            ['asc']
        );

        console.log('afterStaffId, dragId, beforeStaffId, dropIndex', afterStaffId, dragId, beforeStaffId, dropIndex);

        let body = {
            staffId: +dragId
        };

        let itTurnOrder = 0;

        if (afterStaffId) {
            body = {
                ...body,
                afterStaffId: +afterStaffId
            };
            itTurnOrder = staffs?.find((staff) => +staff?.id === +afterStaffId)?.turnOrder + 1;
        } else if (beforeStaffId) {
            body = {
                ...body,
                beforeStaffId: +beforeStaffId
            };
            itTurnOrder = staffs?.find((staff) => +staff?.id === +beforeStaffId)?.turnOrder;
        }

        const staffDrag = staffs?.find((stf) => +stf?.id === +dragId);
        const oldIndex = staffs?.findIndex((stf) => +stf?.id === +dragId);

        if (!staffDrag) {
            console.log('staffDrag is empty');
            return;
        }

        const isUp = oldIndex > dropIndex;

        const splitIndex = isUp ? dropIndex : dropIndex + 1;

        const firstPart = staffs?.slice(0, splitIndex)?.filter((i) => +i?.id !== +dragId);

        const secondPart = staffs?.slice(splitIndex)?.filter((i) => +i?.id !== +dragId);

        const newListStaffWithReorderedTurns = [
            ...firstPart,
            { ...staffDrag, turnOrder: itTurnOrder },
            ...secondPart?.map((staff, index) => ({
                ...staff,
                turnOrder: itTurnOrder + index + 1
            }))
        ];

        try {
            dispatch(_reOrderStaffTurn({ inTurnStaffs: newListStaffWithReorderedTurns }));
            await reOrderStaffTurnApi(body);
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[reOrderStaffTurn] ${message}`,
                    type: 'info'
                })
            );
            dispatch(_reOrderStaffTurn({ inTurnStaffs: staffs }));
            errorCallback && errorCallback(message);
        }
    };
}

function _reOrderStaffTurn({ inTurnStaffs }) {
    return {
        type: types.REORDER_STAFF_TURN,
        payload: {
            inTurnStaffs
        }
    };
}
