import React from 'react';
import { useClientArrivedBookings, useIsOpenClientArrived } from 'hooks/calendar';
import { useIsHavingInternalPermission } from 'hooks/auth';
import useMediaQueries from 'hooks/useMediaQueries';
import { useServiceCategoryList } from 'hooks/service';
import { useStaffOptions } from 'hooks/staff';
import ClientArrived from './ClientArrived';
import { useDispatch } from 'react-redux';
import * as calendarActions from 'views/calender/actions';

function Button() {
    const dispatch = useDispatch();
    const { clientsArrived, clientDetail } = useClientArrivedBookings();
    const isOpenClientArrived = useIsOpenClientArrived();
    const isHavingInternalPermission = useIsHavingInternalPermission();
    const { isMobile } = useMediaQueries();

    const { serviceList, categoryList } = useServiceCategoryList();
    const staffListOptions = useStaffOptions();

    const handleClose = React.useCallback(() => {
        dispatch(calendarActions.setIsOpenClientArrived(false));
    }, [dispatch]);

    if (!(isOpenClientArrived && isHavingInternalPermission)) return null;

    return (
        <ClientArrived
            onClose={handleClose}
            isMobile={isMobile}
            clientsArrived={clientsArrived}
            clientDetail={clientDetail}
            serviceList={serviceList}
            staffList={staffListOptions}
            categoryList={categoryList}
        />
    );
}

export default Button;
