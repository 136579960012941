import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { STAFF_ORDER_TURNOUT, UserState } from 'const';
import { OPERATOR } from 'const/condition';
import { queryClient } from 'query-client';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useGetStaffQuery, useResetTurnCountMutation } from 'services/staff';
import { DndContext, closestCenter, MouseSensor, TouchSensor, DragOverlay, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import GridLayout from './Grid';
import SortableItem from './SortItem';
import Item from './Item';

const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.colors.ghostwhite,
        minHeight: '100vh',
        padding: theme.spacing(4)
    },
    app: {
        maxWidth: 1024,
        background: 'white',
        borderRadius: 20,
        boxShadow: theme.shadows[1],
        padding: theme.spacing(3)
    },
    staffList: {},
    staffItem: {
        minHeight: `40px`,
        width: '100%'
    },
    btnSubmit: {
        position: 'relative'
    },
    buttonProgress: {
        color: theme.palette.common.white,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -40,
        marginLeft: -40
    },
    button: {
        padding: `4px !important`,
        minWidth: `40px`
    }
}));

export default function TurnCountWrapper() {
    const classes = useStyles();
    const { data, isLoading } = useGetStaffQuery({
        query: {
            staffState: {
                [OPERATOR.equal]: UserState.Active
            }
        }
    });

    const staff = data?.items || [];

    if (isLoading) {
        return (
            <Grid direction="column" justifyContent="center" alignItems="center" container className={classes.app}>
                loading...
            </Grid>
        );
    }

    return <TurnCount staff={staff} />;
}

function TurnCount({ staff = [] }) {
    const classes = useStyles();

    const defaultItems = staff.map((staff) => staff.id);

    const combinedItemsWithStorage = useMemo(() => {
        let savedItems;
        try {
            savedItems = JSON.parse(localStorage.getItem(STAFF_ORDER_TURNOUT)) || [];
        } catch (error) {
            savedItems = [];
        }
        const restItems = defaultItems.filter((item) => !savedItems.includes(item));
        const filteredItems = savedItems.filter((item) => defaultItems.includes(item));
        return [...filteredItems, ...restItems];
    }, [defaultItems]);

    const [items, setItems] = useState(combinedItemsWithStorage);
    const [activeId, setActiveId] = useState(null);
    const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

    const staffMapping = useMemo(() => {
        return staff?.reduce((obj, st) => {
            obj.set(st.id, st);
            return obj;
        }, new Map());
    }, [staff]);

    const { mutate: resetMutate } = useResetTurnCountMutation({
        onSuccess: () => {
            queryClient.invalidateQueries(['staff']);
        }
    });

    useEffect(() => {
        localStorage.setItem(STAFF_ORDER_TURNOUT, JSON.stringify(items));
    }, [items]);

    const handleDragStart = useCallback(
        (event) => {
            setActiveId(event.active.id);
        },
        [setActiveId]
    );

    const handleDragEnd = useCallback(
        (event) => {
            const { active, over } = event;

            if (active.id !== over?.id) {
                setItems((items) => {
                    const oldIndex = items.indexOf(active.id);
                    const newIndex = items.indexOf(over.id);

                    return arrayMove(items, oldIndex, newIndex);
                });
            }

            setActiveId(null);
        },
        [setActiveId]
    );

    const handleDragCancel = useCallback(() => {
        setActiveId(null);
    }, [setActiveId]);

    const handleReset = () => {
        resetMutate();
    };

    return (
        <Grid className={classes.root} direction="column" container alignItems="center">
            <Grid direction="column" container className={classes.app}>
                <Grid item container direction="row" spacing={3} style={{ marginBottom: `12px` }}>
                    <Grid item>
                        <Typography variant="h4">Staff turn management</Typography>
                    </Grid>
                    <Grid item>
                        <Button onClick={handleReset} variant="contained" color="primary">
                            Reset
                        </Button>
                    </Grid>
                </Grid>

                <Grid container item direction="column" spacing={3} className={classes.staffList}>
                    <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragStart={handleDragStart}
                        onDragEnd={handleDragEnd}
                        onDragCancel={handleDragCancel}
                    >
                        <SortableContext items={items} strategy={rectSortingStrategy}>
                            <GridLayout columns={5}>
                                {items.map((id) => (
                                    <SortableItem key={staff.id} id={id} staff={staffMapping.get(id)} />
                                ))}
                            </GridLayout>
                        </SortableContext>
                        <DragOverlay adjustScale style={{ transformOrigin: '0 0 ' }}>
                            {activeId ? <Item id={activeId} staff={staffMapping.get(activeId)} isDragging /> : null}
                        </DragOverlay>
                    </DndContext>
                </Grid>
            </Grid>
        </Grid>
    );
}
