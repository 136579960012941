import React from 'react';
import { makeStyles, useTheme, alpha } from '@material-ui/core/styles';
import { Grid, Typography, Divider, Chip } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { getImageUrl } from 'utils/image';
import { getExpirationByKey } from 'utils/app/voucher';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 300,
        minWidth: 200,
        position: 'relative',
        alignContent: 'center',
        cursor: 'pointer'
    },
    media: {
        width: '100%',
        minWidth: 200,
        maxHeight: 210,
        minHeight: 210,
        [theme.breakpoints.down('xs')]: {
            maxHeight: 300,
            minHeight: 210
        },
        position: 'relative',
        background: theme.palette.common.white
    },
    contents: {
        width: '100%',
        padding: `20px`,
        background: theme.palette.common.white,
        boxShadow: theme.shadows[1],
        position: 'relative'
    },
    salePercent: {
        position: 'absolute',
        top: 20,
        left: -20
        // display: 'none'
    },
    salePercentContent: {
        padding: 10,
        backgroundColor: '#FFC25D',
        zIndex: 9999,
        borderRadius: 5,
        boxShadow: '0px 8px 15px 2px #FFC25D'
    },
    hotSales: {
        color: '#FFFFFF'
    },
    image: {
        width: '100%',
        height: '100%',
        borderRadius: theme.radius(3),
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        '&.MuiAvatar-colorDefault': {
            background: alpha(theme.palette.primary.main, 0.8)
        },
        cursor: 'pointer',
        position: 'relative'
    },
    textCenter: {
        textAlign: 'center',
        fontSize: 16
    },

    price: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: ' center',
        fontWeight: 700
    },
    itemFooter: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: ' center',
        flex: 2,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    key: {
        fontSize: 14,
        color: `#8788A7`,
        fontWeight: 'normal'
    },
    value: {
        fontWeight: 700,
        fontSize: 14,
        color: `#8788A7`
    },
    lineThrough: {
        textDecoration: 'line-through'
    },

    footer: {
        // padding: theme.spacing(2),
        background: theme.palette.common.white,
        borderBottomLeftRadius: theme.radius(2),
        borderBottomRightRadius: theme.radius(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: ' center',
        boxShadow: `0px 8px 30px -24px ${theme.palette.primary.main}`
    }
}));
export default function VoucherNewPV({ voucher }) {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const {
        name,
        retailPrice,
        salePercent,
        // redeeptionType,
        expirationPeriod = 0,
        // messageTitle,
        // messageDescription,
        // onUpload,
        logo,
        giftCodes
    } = voucher;

    const giftCodesCount = React.useMemo(() => {
        return giftCodes?.filter((code) => !code?.deleted)?.length;
    }, [giftCodes]);

    const [thumbnail, setThumbnail] = React.useState(logo);

    React.useEffect(() => {
        if (logo) {
            setThumbnail(logo);
        } else {
            setThumbnail();
        }
    }, [logo]);
    return (
        <Grid container className={classes.root}>
            <Grid item className={classes.media}>
                {(giftCodesCount > 0 || salePercent !== 0) && (
                    <Grid
                        container
                        style={{ position: 'absolute', top: 10, right: 10, zIndex: 10, width: 'fit-content' }}
                        direction="row"
                        spacing={1}
                    >
                        {giftCodesCount > 0 && (
                            <Grid item>
                                <Chip
                                    size="small"
                                    style={{ background: theme.colors.pink, color: theme.palette.common.white }}
                                    label={`${giftCodesCount} sold`}
                                ></Chip>
                            </Grid>
                        )}
                        {salePercent !== 0 && (
                            <Grid item>
                                <Chip
                                    style={{ background: theme.colors.sun }}
                                    size="small"
                                    color="primary"
                                    label={`${salePercent}% off`}
                                ></Chip>
                            </Grid>
                        )}
                    </Grid>
                )}
                <Avatar src={getImageUrl(thumbnail)} className={classes.image}></Avatar>
            </Grid>
            <Grid item className={classes.contents}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" className={classes.textCenter}>
                            {name}
                        </Typography>
                        <Typography variant="h4" className={classes.price}>
                            {`$${Math.round(retailPrice - (retailPrice * salePercent) / 100)} CAD`}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Divider light />
            </Grid>
            <Grid item xs={12} className={classes.footer}>
                <Grid container justifyContent="space-around">
                    <Grid item className={classes.itemFooter}>
                        <Typography variant="subtitle2" className={classes.key}>
                            {t(`expires_in`)}
                        </Typography>
                        <Typography variant="subtitle2" className={classes.value}>
                            {t(`${getExpirationByKey(expirationPeriod)}`)}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Divider light orientation="vertical" />
                    </Grid>
                    <Grid item className={classes.itemFooter}>
                        <Typography variant="subtitle2" className={classes.key}>
                            {t(`redeem_value`)}
                        </Typography>
                        <Typography variant="subtitle2" className={`${classes.value}`}>
                            {`$${retailPrice} CAD`}
                        </Typography>
                    </Grid>
                </Grid>
                {/* <Typography variant="subtitle2">{t(`expires_in`)}</Typography>
                <Typography variant="subtitle2" className={classes.value} style={{ color: '#1C1D21' }}>
                    {t(`${getExpirationByKey(expirationPeriod)}`)}
                </Typography> */}
            </Grid>
        </Grid>
    );
}
