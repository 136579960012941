import * as types from 'views/voucher/types';

import { enqueueSnackbar } from 'notifier/actions';
import {
    getVouchersApi,
    createVoucherApi,
    updateVoucherApi,
    deleteVoucherApi,
    getVoucherCodesApi,
    createVoucherCodeApi,
    getVoucherCodeByCodeNumberApi,
    redeemVoucherCodeApi,
    updateVoucherWithLogoChangeApi,
    duplicateVoucherApi,
    sendVoucherCodeApi,
    getGiftHistoryApi,
    topUpVoucherCodeApi
} from 'services/voucher';

export function getVouchers({ errorCallback, successCallback }) {
    return async function (dispatch) {
        dispatch(_fetchVouchersAction());

        try {
            const vouchers = await getVouchersApi({});
            dispatch(_fetchVouchersSuccessAction({ vouchers: vouchers?.items }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            // dispatch(
            //     enqueueSnackbar({
            //         message: `[getVouchers] ${message}`,
            //         type: 'info'
            //     })
            // );
            dispatch(_fetchVouchersFailedAction());
            errorCallback && errorCallback(message);
        }
    };
}

function _fetchVouchersAction() {
    return {
        type: types.FETCH_VOUCHERS
    };
}

function _fetchVouchersFailedAction() {
    return {
        type: types.FETCH_VOUCHERS_FAILED
    };
}

export function _fetchVouchersSuccessAction({ vouchers }) {
    return {
        type: types.FETCH_VOUCHERS_SUCCESS,
        payload: {
            vouchers
        }
    };
}

export function createVoucher({ body = {}, file, errorCallback, successCallback }) {
    return async function (dispatch) {
        dispatch(_createVoucherAction());
        const formData = new FormData();
        formData.append('file', file, file.name);
        Object.keys(body).forEach((key) => {
            formData.append(key, body?.[key]);
        });

        try {
            const voucher = await createVoucherApi({ formData });
            dispatch(_createVoucherSuccessAction({ voucher }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[createVoucher] ${message}`,
                    type: 'info'
                })
            );
            dispatch(_createVoucherFailedAction());
            errorCallback && errorCallback(message);
        }
    };
}

function _createVoucherAction() {
    return {
        type: types.CREATE_VOUCHER
    };
}

function _createVoucherFailedAction() {
    return {
        type: types.CREATE_VOUCHER_FAILED
    };
}

export function _createVoucherSuccessAction({ voucher }) {
    return {
        type: types.CREATE_VOUCHER_SUCCESS,
        payload: {
            voucher
        }
    };
}

export function duplicateVoucher({ voucherId = {}, errorCallback, successCallback }) {
    return async function (dispatch) {
        dispatch(_duplicateVoucherAction());

        try {
            const voucher = await duplicateVoucherApi({ voucherId });
            dispatch(_duplicateVoucherSuccessAction({ voucher }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[duplicateVoucher] ${message}`,
                    type: 'info'
                })
            );
            dispatch(_duplicateVoucherFailedAction());
            errorCallback && errorCallback(message);
        }
    };
}

function _duplicateVoucherAction() {
    return {
        type: types.CREATE_VOUCHER
    };
}

function _duplicateVoucherFailedAction() {
    return {
        type: types.CREATE_VOUCHER_FAILED
    };
}

export function _duplicateVoucherSuccessAction({ voucher }) {
    return {
        type: types.CREATE_VOUCHER_SUCCESS,
        payload: {
            voucher
        }
    };
}

// SEND EMAIL

export function sendVoucherCode({ giftCodeId, body = {}, errorCallback, successCallback }) {
    return async function (dispatch) {
        dispatch(_sendVoucherCodeAction());

        try {
            await sendVoucherCodeApi({ body, giftCodeId });
            dispatch(_sendVoucherCodeSuccessAction());
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[duplicateVoucher] ${message}`,
                    type: 'info'
                })
            );
            dispatch(_sendVoucherCodeFailedAction());
            errorCallback && errorCallback(message);
        }
    };
}

function _sendVoucherCodeAction() {
    return {
        type: types.SEND_VOUCHER_CODE
    };
}

function _sendVoucherCodeFailedAction() {
    return {
        type: types.SEND_VOUCHER_CODE_FAILED
    };
}

export function _sendVoucherCodeSuccessAction() {
    return {
        type: types.SEND_VOUCHER_CODE_SUCCESS
        // payload: {
        //     voucher
        // }
    };
}

export function updateVoucherWithLogo({ body, file, voucherId, errorCallback, successCallback }) {
    return async function (dispatch) {
        dispatch(_updateVoucherAction());

        const formData = new FormData();
        if (file) {
            formData.append('file', file, file.name);
        }
        Object.keys(body).forEach((key) => {
            formData.append(key, body?.[key]);
        });
        try {
            const voucher = await updateVoucherWithLogoChangeApi({ voucherId, formData });
            dispatch(_updateVoucherSuccessAction({ voucher, voucherId }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[updateVoucher] ${message}`,
                    type: 'info'
                })
            );
            dispatch(_updateVoucherFailedAction());
            errorCallback && errorCallback(message);
        }
    };
}

export function updateVoucher({ body, voucherId, errorCallback, successCallback }) {
    return async function (dispatch) {
        dispatch(_updateVoucherAction());

        try {
            const voucher = await updateVoucherApi({ body, voucherId });
            dispatch(_updateVoucherSuccessAction({ voucher, voucherId }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[updateVoucher] ${message}`,
                    type: 'info'
                })
            );
            dispatch(_updateVoucherFailedAction());
            errorCallback && errorCallback(message);
        }
    };
}

function _updateVoucherAction() {
    return {
        type: types.UPDATE_VOUCHER
    };
}

function _updateVoucherFailedAction() {
    return {
        type: types.UPDATE_VOUCHER_FAILED
    };
}

export function _updateVoucherSuccessAction({ voucher, voucherId }) {
    return {
        type: types.UPDATE_VOUCHER_SUCCESS,
        payload: {
            voucher,
            voucherId
        }
    };
}

export function deleteVoucher({ voucherId, errorCallback, successCallback }) {
    return async function (dispatch) {
        dispatch(_deleteVoucherAction({ voucherId }));

        try {
            await deleteVoucherApi({ voucherId });
            dispatch(_deleteVoucherSuccessAction({ voucherId }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[deleteVoucher] ${message}`,
                    type: 'info'
                })
            );
            dispatch(_deleteVoucherFailedAction({ voucherId }));
            errorCallback && errorCallback(message);
        }
    };
}

function _deleteVoucherAction({ voucherId }) {
    return {
        type: types.DELETE_VOUCHER,
        payload: {
            voucherId
        }
    };
}

function _deleteVoucherFailedAction({ voucherId }) {
    return {
        type: types.DELETE_VOUCHER_FAILED,
        payload: {
            voucherId
        }
    };
}

export function _deleteVoucherSuccessAction({ voucherId }) {
    return {
        type: types.DELETE_VOUCHER_SUCCESS,
        payload: {
            voucherId
        }
    };
}

export function getVoucherCodes({ searchObject, errorCallback, successCallback }) {
    return async function (dispatch) {
        dispatch(_fetchVoucherCodesAction());

        try {
            const voucherCodes = await getVoucherCodesApi({ searchObject });
            dispatch(_fetchVoucherCodesSuccessAction({ voucherCodes: voucherCodes?.data }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[getVoucherCodes] ${message}`,
                    type: 'info'
                })
            );
            dispatch(_fetchVoucherCodesFailedAction());
            errorCallback && errorCallback(message);
        }
    };
}

function _fetchVoucherCodesAction() {
    return {
        type: types.FETCH_VOUCHERS_CODE
    };
}

function _fetchVoucherCodesFailedAction() {
    return {
        type: types.FETCH_VOUCHERS_CODE_FAILED
    };
}

export function _fetchVoucherCodesSuccessAction({ voucherCodes }) {
    return {
        type: types.FETCH_VOUCHERS_CODE_SUCCESS,
        payload: {
            voucherCodes
        }
    };
}

export function getVoucherCodeByCodeNumber({ codeNumber, errorCallback, successCallback }) {
    return async function (dispatch) {
        dispatch(_fetchVoucherCodeByCodeNumberAction());

        try {
            const voucherCode = await getVoucherCodeByCodeNumberApi({ codeNumber });
            dispatch(_fetchVoucherCodeByCodeNumberSuccessAction({ voucherCode: voucherCode }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[getVoucherCodeByCodeNumber] ${message}`,
                    type: 'info'
                })
            );
            dispatch(_fetchVoucherCodeByCodeNumberFailedAction());
            errorCallback && errorCallback(message);
        }
    };
}

function _fetchVoucherCodeByCodeNumberAction() {
    return {
        type: types.GET_VOUCHERS_CODE_BY_CODE_NUMBER
    };
}

function _fetchVoucherCodeByCodeNumberFailedAction() {
    return {
        type: types.GET_VOUCHERS_CODE_BY_CODE_NUMBER_FAILED
    };
}

export function _fetchVoucherCodeByCodeNumberSuccessAction({ voucherCode }) {
    return {
        type: types.GET_VOUCHERS_CODE_BY_CODE_NUMBER_SUCCESS,
        payload: {
            voucherCode
        }
    };
}

export function createVoucherCode({ body, errorCallback, successCallback }) {
    return async function (dispatch) {
        dispatch(_createVoucherCodeAction());

        try {
            const createdCode = await createVoucherCodeApi({ body });
            dispatch(_createVoucherCodeSuccessAction({ code: createdCode }));
            successCallback && successCallback(createdCode);
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[createVoucherCode] ${message}`,
                    type: 'info'
                })
            );
            dispatch(_createVoucherCodeFailedAction());
            errorCallback && errorCallback(message);
        }
    };
}

function _createVoucherCodeAction() {
    return {
        type: types.CREATE_VOUCHER_CODE
    };
}

function _createVoucherCodeFailedAction() {
    return {
        type: types.CREATE_VOUCHER_CODE_FAILED
    };
}

function _createVoucherCodeSuccessAction({ gId, code }) {
    return {
        type: types.CREATE_VOUCHER_CODE_SUCCESS,
        payload: {
            gId,
            code
        }
    };
}

export function redeemVoucherCode({ body, voucherCodeId, errorCallback, successCallback }) {
    return async function (dispatch) {
        dispatch(_updateVoucherCodeAction());

        try {
            const voucherCode = await redeemVoucherCodeApi({ body, voucherCodeId });
            dispatch(_updateVoucherCodeSuccessAction({ voucherCode, voucherCodeId }));
            successCallback && successCallback({ voucherCode });
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[redeemVoucherCode] ${message}`,
                    type: 'info'
                })
            );
            dispatch(_updateVoucherCodeFailedAction());
            errorCallback && errorCallback(message);
        }
    };
}

export function topUpVoucherCode({ body, voucherCodeId, errorCallback, successCallback }) {
    return async function (dispatch) {
        dispatch(_updateVoucherCodeAction());

        try {
            const voucherCode = await topUpVoucherCodeApi({ body, voucherCodeId });
            dispatch(_updateVoucherCodeSuccessAction({ voucherCode, voucherCodeId }));
            successCallback && successCallback({ voucherCode });
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[redeemVoucherCode] ${message}`,
                    type: 'info'
                })
            );
            dispatch(_updateVoucherCodeFailedAction());
            errorCallback && errorCallback(message);
        }
    };
}

function _updateVoucherCodeAction() {
    return {
        type: types.UPDATE_VOUCHER
    };
}

function _updateVoucherCodeFailedAction() {
    return {
        type: types.UPDATE_VOUCHER_CODE_FAILED
    };
}

export function _updateVoucherCodeSuccessAction({ voucherCode, voucherCodeId }) {
    return {
        type: types.UPDATE_VOUCHER_CODE_SUCCESS,
        payload: {
            voucherCode,
            voucherCodeId
        }
    };
}

export function getGiftHistory({ giftId, errorCallback, successCallback }) {
    return async function (dispatch) {
        dispatch(_getGiftHistory({ giftId }));

        try {
            const response = await getGiftHistoryApi({ giftId });
            dispatch(_getGiftHistorySuccess({ giftId, histories: response?.giftCodes }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[getGiftHistory] ${message}`,
                    type: 'info'
                })
            );
            dispatch(_getGiftHistoryFailed({ giftId, error }));
            errorCallback && errorCallback(message);
        }
    };
}

export function _getGiftHistory({ giftId }) {
    return {
        type: types.FETCH_GIFT_HISTORY,
        payload: {
            giftId
        }
    };
}

export function _getGiftHistorySuccess({ giftId, histories = [] }) {
    return {
        type: types.FETCH_GIFT_HISTORY_SUCCESS,
        payload: {
            giftId,
            histories
        }
    };
}

export function _getGiftHistoryFailed({ giftId, error }) {
    return {
        type: types.FETCH_GIFT_HISTORY_FAILED,
        payload: {
            giftId,
            error
        }
    };
}
