import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, CircularProgress, Checkbox, Divider } from '@material-ui/core';
import ButtonBase from 'components/button/Base';
import useMediaQueries from 'hooks/useMediaQueries';
import { useFormik } from 'formik';
import { string, object, number } from 'yup';
import { useDispatch } from 'react-redux';
import * as staffActions from '../action';
import { getDatetimeFormat, getNativeDate } from 'utils/timing';
import { cleanEmptyFieldsInObj, getKeyValueOnly } from 'utils/object';
import { useTranslation } from 'react-i18next';
import DialogActions from 'components/dialog/Action';
import DialogTitle from 'components/dialog/Title';
import DialogContent from 'components/dialog/Content';
import { useIsHavingInternalPermission, useIsHavingPermission } from 'hooks/auth';
import { MERCHANT_PERMISSIONS, USER_TYPES, CONFIRM_TYPES } from 'const';
import InputBase from 'components/input/Form';
import IOSSwitch from 'components/switch/IOS';
import PhoneInput from 'components/input/Phone';
import { addPlus } from 'utils/phone';
import { COMMISSION_RATE_OPTIONS } from 'utils/app/staff';
import NativeSelect from 'components/select/NativeForm';
import AddIcon from '@material-ui/icons/AddCircle';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import { useServiceOptions, useServicesMapping } from 'hooks/service';
import { convertServiceGroupOptions } from 'utils/app/booking';
import { durationOptions } from 'data/time';
import CheckAllIcon from '@material-ui/icons/DoneAll';
import cloneDeep from 'lodash/cloneDeep';
import ConfirmDialog from 'components/dialog/Confirm';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 768,
        margin: '0 auto',
        paddingTop: 30,
        [theme.breakpoints.down('sm')]: {
            paddingTop: 10
        }
    },
    fullWidth: {
        width: '100%'
    },
    title: {
        color: theme.colors.primaryText
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    btnSubmit: {
        position: 'relative'
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    }
}));

const StaffSchema = object({
    firstName: string().required('This filed is required'),
    lastName: string(),
    phone: string(),
    email: string(),
    title: string(),
    note: string(),
    minIncomePerDay: number()
});

function AddMember({ isEdit, editedStaff, handleClose }) {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { isMobile } = useMediaQueries();
    const serviceList = useServiceOptions();

    const [isEnabledAppointment, setIsEnabledAppointment] = React.useState(
        isEdit ? (editedStaff?.enableAppointment ? true : false) : true
    );

    const [isReceptionist, setIsReceptionist] = React.useState(
        isEdit ? (editedStaff?.userType === USER_TYPES.Receptionist ? true : false) : false
    );

    const [services, setServices] = React.useState(isEdit ? cloneDeep(editedStaff?.staffServices || []) : []);
    const [startDate, setStartDate] = React.useState(
        isEdit ? (editedStaff.startDate ? getNativeDate(editedStaff?.startDate) : new Date()) : new Date()
    );

    const isInternal = useIsHavingInternalPermission();
    const servicesMapping = useServicesMapping();

    const endDateDefault = isEdit ? (editedStaff.endDate ? getNativeDate(editedStaff?.endDate) : undefined) : undefined;
    const [endDate, setEndDate] = React.useState(endDateDefault);
    const [commissionRate, setCommissionRate] = React.useState(isEdit ? +editedStaff?.commissionRate : 60);

    const isHasServiceCommission = useIsHavingPermission(MERCHANT_PERMISSIONS.SERVICE_COMMISSION);
    const isHasStaffServiceDuration = useIsHavingPermission(MERCHANT_PERMISSIONS.STAFF_SERVICE_DURATION);
    const [isOpenConfirmUpdateUpComingBookings, setIsOpenConfirmUpdateUpComingBookings] = React.useState(false);

    const onEndDateChange = (date) => setEndDate(date);
    const onStartDateChange = (date) => setStartDate(date);

    console.log('onEndDateChange', onEndDateChange);
    console.log('onStartDateChange', onStartDateChange);

    const serviceGroupOptions = React.useMemo(() => {
        return convertServiceGroupOptions(serviceList);
    }, [serviceList]);

    const formik = useFormik({
        initialValues: {
            firstName: isEdit ? editedStaff?.firstName || '' : '',
            lastName: isEdit ? editedStaff?.lastName || '' : '',
            phone: isEdit ? editedStaff?.user?.phone || '' : '',
            note: isEdit ? editedStaff?.note || '' : '',
            email: isEdit ? editedStaff?.email || '' : '',
            title: isEdit ? editedStaff?.title || '' : '',
            minIncomePerDay: isEdit ? editedStaff?.minIncomePerDay || 0 : 0
        },
        validationSchema: StaffSchema,
        onSubmit: (values, { setSubmitting, setStatus }) => {
            setStatus({ error: null });
            setSubmitting(true);

            const validObject = getKeyValueOnly(values);
            const extraObject = {};
            if (startDate) {
                extraObject['startDate'] = getDatetimeFormat(startDate);
            }
            if (endDate) {
                extraObject['endDate'] = getDatetimeFormat(endDate);
            }

            if (!isEdit) {
                const bodyCreate = {
                    ...validObject,
                    ...extraObject,
                    commissionRate,
                    enableAppointment: isEnabledAppointment,
                    isReceptionist,
                    staffServices: services.map((sv) => ({
                        serviceId: +sv?.id,
                        duration: +sv?.duration || +sv?.prices?.[0]?.duration
                    }))
                };
                dispatch(
                    staffActions.createStaff({
                        body: cleanEmptyFieldsInObj(bodyCreate),
                        successCallback: () => {
                            handleClose();
                        },
                        errorCallback: (error) => {
                            setSubmitting(false);
                        }
                    })
                );
            } else {
                const bodyUpdate = {
                    ...editedStaff,
                    ...values,
                    phone: values.phone?.toString(),
                    ...extraObject,
                    enableAppointment: isEnabledAppointment,
                    isReceptionist,
                    commissionRate,
                    staffServices: services.map((sv) => {
                        if (sv.serviceId) {
                            return {
                                commissionRate: sv?.commissionRate,
                                extraCommission: sv?.extraCommission,
                                serviceId: +sv?.serviceId,
                                id: +sv?.id,
                                duration: +sv?.duration
                            };
                        } else {
                            return {
                                serviceId: +sv?.id,
                                duration: +sv?.duration
                            };
                        }
                    })
                };

                if (isHasStaffServiceDuration) {
                    // const isSomeStaffServiceIsDeleted = Object.keys(oldStaffServiceDurationMap)?.some(sSvId => {
                    //     return !bodyUpdate?.staffServices?.find(sSv => +sSv?.id === sSvId)
                    // })

                    // if(isSomeStaffServiceIsDeleted){
                    //     setIsOpenConfirmUpdateUpComingBookings(true);
                    //     return;
                    // }

                    const newStaffServiceDurationMap = bodyUpdate?.staffServices?.reduce((obj, i) => {
                        if (i?.id) {
                            obj[i?.id] = i?.duration || servicesMapping?.[+i?.serviceId]?.prices?.[0]?.duration;
                        }

                        return obj;
                    }, {});

                    const oldStaffServiceDurationMap = editedStaff?.staffServices?.reduce((obj, sSv) => {
                        obj[+sSv?.id] = sSv?.duration || servicesMapping?.[+sSv?.serviceId]?.prices?.[0]?.duration;
                        return obj;
                    }, {});

                    const isShowConfirm = Object.keys(oldStaffServiceDurationMap)?.some((sSvId) => {
                        const newDuration = newStaffServiceDurationMap?.[sSvId];
                        return oldStaffServiceDurationMap?.[sSvId] !== newDuration;
                    });

                    if (isShowConfirm) {
                        setIsOpenConfirmUpdateUpComingBookings(true);
                        setSubmitting(false);
                        return;
                    }
                }

                delete bodyUpdate?.user;
                delete bodyUpdate?.userId;
                delete bodyUpdate?.merchantId;
                delete bodyUpdate?.avatar;
                delete bodyUpdate?.name;
                delete bodyUpdate?.orderIndex;
                delete bodyUpdate?.email;

                dispatch(
                    staffActions.updateStaff({
                        id: editedStaff?.id,
                        body: bodyUpdate,
                        successCallback: () => {
                            handleClose();
                        },
                        errorCallback: (error) => {
                            setSubmitting(false);
                        }
                    })
                );
            }

            setStatus({ error: null });
        }
    });

    const { handleSubmit, isSubmitting, isValid, setFieldValue, values, handleBlur, handleChange, errors, touched } =
        formik;

    const handleConfirmChangeStaffServiceDurationAccept = React.useCallback(() => {
        const extraObject = {};
        if (startDate) {
            extraObject['startDate'] = getDatetimeFormat(startDate);
        }
        if (endDate) {
            extraObject['endDate'] = getDatetimeFormat(endDate);
        }

        const bodyUpdate = {
            ...editedStaff,
            ...values,
            phone: values.phone?.toString(),
            ...extraObject,
            enableAppointment: isEnabledAppointment,
            isReceptionist,
            commissionRate,
            staffServices: services.map((sv) => {
                if (sv.serviceId) {
                    return {
                        commissionRate: sv?.commissionRate,
                        extraCommission: sv?.extraCommission,
                        serviceId: +sv?.serviceId,
                        id: +sv?.id,
                        duration: +sv?.duration
                    };
                } else {
                    return {
                        serviceId: +sv?.id,
                        duration: +sv?.duration
                    };
                }
            })
        };

        delete bodyUpdate?.user;
        delete bodyUpdate?.userId;
        delete bodyUpdate?.merchantId;
        delete bodyUpdate?.avatar;
        delete bodyUpdate?.name;
        delete bodyUpdate?.orderIndex;
        delete bodyUpdate?.email;

        dispatch(
            staffActions.updateStaff({
                id: editedStaff?.id,
                body: bodyUpdate,
                successCallback: () => {
                    handleClose();
                },
                errorCallback: (error) => {}
            })
        );
    }, [
        dispatch,
        commissionRate,
        handleClose,
        endDate,
        isReceptionist,
        editedStaff,
        services,
        values,
        startDate,
        isEnabledAppointment
    ]);

    const handleServiceChange = ({ checked, service }) => {
        let newServices = [...services];
        if (checked) {
            newServices = [...newServices, { ...service, duration: service?.prices?.[0]?.duration }];
        } else {
            newServices = newServices.filter((selectedService) => {
                if (selectedService.serviceId) {
                    return selectedService.serviceId !== service.id;
                } else {
                    return selectedService.id !== service.id;
                }
            });
        }
        setServices(newServices);
    };

    const onSelectAll = React.useCallback((allServices) => {
        setServices(allServices);
    }, []);

    const handleStaffServiceChange = React.useCallback(
        ({ sId, duration = 0 }) => {
            const newServices = services?.map((s) => {
                if (+s?.serviceId === +sId || +s?.id === +sId) {
                    s.duration = duration;
                }
                return s;
            });
            setServices(newServices);
        },
        [services]
    );

    const enabledAppointmentChange = (e) => {
        setIsEnabledAppointment(e.target.checked);
    };

    const onReceptionistChange = (e) => {
        setIsReceptionist(e.target.checked);
    };

    const handlePhoneChange = (phone) => {
        setFieldValue('phone', phone);
    };

    const handleCommissionChange = React.useCallback((e) => {
        setCommissionRate(+e?.target?.value);
    }, []);

    const allServices = React.useMemo(() => {
        let serviceAlls = [];
        for (let svGroup of serviceGroupOptions) {
            const svs = svGroup?.options || [];
            serviceAlls = serviceAlls.concat([...svs]);
        }

        return serviceAlls;
    }, [serviceGroupOptions]);

    const isSelectedAll = React.useMemo(() => {
        return services?.length === allServices?.length;
    }, [allServices, services]);

    const handleCheckAllServices = () => {
        if (isSelectedAll) {
            onSelectAll && onSelectAll([]);
        } else {
            onSelectAll && onSelectAll(allServices);
        }
    };

    return (
        <form onSubmit={handleSubmit} className={classes.form}>
            <DialogTitle>
                <Typography variant="h5">
                    {isEdit ? `Update` : `New`} {t(`staff`)}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Grid className={classes.root} container direction="column" spacing={3}>
                    <Grid item container direction="column" spacing={1}>
                        <Grid item>
                            <Typography variant="h4">Staff</Typography>
                        </Grid>
                        <Grid item container direction="column" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Grid container direction="row" spacing={2}>
                                    <Grid item xs={6}>
                                        <InputBase
                                            label={t(`first_name`)}
                                            placeholder={t(`first_name_placeholder`)}
                                            name="firstName"
                                            value={values.firstName || ''}
                                            disabled={isSubmitting}
                                            onChange={(e) => {
                                                e.target.value = e.target.value.replace(/[^A-Z_0-9]/gi, '');
                                                handleChange(e);
                                            }}
                                            onBlur={handleBlur}
                                            error={errors.firstName && touched.firstName}
                                            errorText={errors.firstName}
                                            autoFocus={true}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <InputBase
                                            label={t(`last_name`)}
                                            placeholder={t(`last_name_placeholder`)}
                                            name="lastName"
                                            value={values.lastName || ''}
                                            disabled={isSubmitting}
                                            onChange={(e) => {
                                                e.target.value = e.target.value.replace(/[^A-Z_0-9]/gi, '');
                                                handleChange(e);
                                            }}
                                            onBlur={handleBlur}
                                            error={errors.lastName && touched.lastName}
                                            errorText={errors.lastName}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <InputBase
                                    multiline
                                    label={t(`notes`)}
                                    placeholder={t(`notes_staff_placeholder`)}
                                    name="note"
                                    value={values.note || ''}
                                    disabled={isSubmitting}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.note && touched.note}
                                    errorText={errors.note}
                                />
                            </Grid>
                            <Grid item>
                                <PhoneInput
                                    label={t('phone_number')}
                                    placeholder={t(`phone_number_placeholder`)}
                                    disabled={isSubmitting}
                                    onChange={handlePhoneChange}
                                    defaultValue={addPlus(values?.phone)}
                                />
                            </Grid>

                            {isInternal && !isHasServiceCommission && (
                                <Grid item>
                                    <NativeSelect
                                        label={'Commission Rate'}
                                        onChange={handleCommissionChange}
                                        selectedValue={commissionRate}
                                        options={COMMISSION_RATE_OPTIONS}
                                        placeholder={t(`choose_an_option`)}
                                    />
                                </Grid>
                            )}

                            {isInternal && (
                                <Grid item>
                                    <InputBase
                                        type="number"
                                        label={'Min income per day'}
                                        placeholder={''}
                                        name="minIncomePerDay"
                                        value={values.minIncomePerDay || ''}
                                        disabled={isSubmitting}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={errors.minIncomePerDay && touched.minIncomePerDay}
                                        errorText={errors.minIncomePerDay}
                                    />
                                </Grid>
                            )}

                            <Grid item style={{ marginTop: theme.spacing(2) }}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item>
                                        <IOSSwitch
                                            checked={isEnabledAppointment}
                                            onChange={enabledAppointmentChange}
                                            name="checkedB"
                                            color="primary"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography className={classes.bold} variant="body2">
                                            {t(`enable_appointment_bookings`)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {isHasServiceCommission && (
                                <Grid item>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item>
                                            <IOSSwitch
                                                checked={isReceptionist}
                                                onChange={onReceptionistChange}
                                                name="checkedA"
                                                color="primary"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.bold} variant="body2">
                                                Enable receptionist
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Divider />
                    </Grid>
                    <Grid item container direction="column" spacing={1}>
                        <Grid item>
                            <Typography variant="h4">Categories</Typography>
                        </Grid>
                        <Grid item>
                            <Grid container className={classes.root} direction="column">
                                <Grid item className={classes.spacing} style={{ paddingTop: 12 }}>
                                    <Typography variant="body2" display="inline">
                                        {t(`assign_service_this_staff`)}
                                    </Typography>{' '}
                                    <Typography variant="body2" component="div" display="inline">
                                        <ButtonBase
                                            color="primary"
                                            size="small"
                                            variant="contained"
                                            onClick={handleCheckAllServices}
                                            startIcon={<CheckAllIcon />}
                                        >
                                            Select all services
                                        </ButtonBase>
                                    </Typography>
                                </Grid>
                                <Grid item className={classes.spacing}>
                                    <Grid container spacing={2}>
                                        {serviceGroupOptions?.map((group) => {
                                            const options = group?.options || [];
                                            const groupName = group?.groupName;
                                            return (
                                                <Grid item key={groupName} xs={12}>
                                                    <Grid container direction="column" wrap="nowrap">
                                                        <Grid item>
                                                            <Grid
                                                                style={{
                                                                    marginLeft: `6px`,
                                                                    marginBottom: theme.spacing(1),
                                                                    marginTop: theme.spacing(2)
                                                                }}
                                                                container
                                                                alignItems="center"
                                                                direction="row"
                                                                spacing={2}
                                                            >
                                                                <Grid item>
                                                                    <div
                                                                        style={{
                                                                            width: 20,
                                                                            height: 20,
                                                                            borderRadius: 4,
                                                                            background: group?.color,
                                                                            position: 'relative',
                                                                            left: -2
                                                                        }}
                                                                    ></div>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography variant="body1">{groupName}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        {options?.map((service) => {
                                                            const matchedService = services?.find((sv) => {
                                                                if (sv.serviceId) {
                                                                    return +sv.serviceId === +service.id;
                                                                }
                                                                return +sv.id === +service.id;
                                                            });

                                                            const checked = !!matchedService;
                                                            return (
                                                                <Grid key={service.id} item>
                                                                    <Grid
                                                                        container
                                                                        direction="row"
                                                                        spacing={2}
                                                                        alignItems="center"
                                                                        justifyContent="space-between"
                                                                        wrap="nowrap"
                                                                    >
                                                                        <Grid item>
                                                                            <Grid
                                                                                container
                                                                                alignItems="center"
                                                                                wrap="nowrap"
                                                                            >
                                                                                <Grid item>
                                                                                    <Checkbox
                                                                                        isBorder={true}
                                                                                        color="primary"
                                                                                        checked={checked}
                                                                                        onChange={(e) =>
                                                                                            handleServiceChange({
                                                                                                checked:
                                                                                                    e.target.checked,
                                                                                                service
                                                                                            })
                                                                                        }
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item>{service.title}</Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                        {checked && isHasStaffServiceDuration && (
                                                                            <Grid item>
                                                                                <NativeSelect
                                                                                    onChange={(e) =>
                                                                                        handleStaffServiceChange({
                                                                                            sId: service?.id,
                                                                                            duration: +e?.target?.value
                                                                                        })
                                                                                    }
                                                                                    selectedValue={
                                                                                        matchedService?.duration ||
                                                                                        service?.prices?.[0]?.duration
                                                                                    }
                                                                                    options={durationOptions}
                                                                                    placeholder={`Duration`}
                                                                                />
                                                                            </Grid>
                                                                        )}
                                                                    </Grid>
                                                                </Grid>
                                                            );
                                                        })}
                                                    </Grid>
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <ButtonBase startIcon={<CloseIcon />} onClick={handleClose} fullWidth={isMobile} variant="outlined">
                    {t(`close`)}
                </ButtonBase>
                <div className={classes.btnSubmit} style={{ width: isMobile ? '100%' : '' }}>
                    <ButtonBase
                        disabled={isSubmitting || !isValid}
                        fullWidth={isMobile}
                        variant="contained"
                        color="primary"
                        type="submit"
                        startIcon={isEdit ? <SaveIcon /> : <AddIcon />}
                    >
                        {isEdit ? t(`save`) : t(`create`)}
                    </ButtonBase>
                    {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
            </DialogActions>

            <ConfirmDialog
                open={isOpenConfirmUpdateUpComingBookings}
                title={`You have changed staff service's duration.`}
                text={`This action will update all upcoming appointments. Are you sure to do this?`}
                onAgree={handleConfirmChangeStaffServiceDurationAccept}
                agreeLabel={`Agree`}
                onDisagree={() => setIsOpenConfirmUpdateUpComingBookings(false)}
                type={CONFIRM_TYPES.ERROR}
            />
        </form>
    );
}

export default React.memo(AddMember);
