import React from 'react';
import { Grid, useTheme, IconButton, Avatar, Box, CircularProgress, Dialog, Chip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import DragIndicator from '@material-ui/icons/DragIndicator';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/Check';
import UploadIcon from '@material-ui/icons/CloudUpload';
import { getImageUrl } from 'utils/image';
import { AVATAR_EXTENSIONS, MERCHANT_PERMISSIONS } from 'const';
import { useDropzone } from 'react-dropzone';
import { useSnackbar } from 'notistack';
import Tooltip from 'components/tooltip';
import { Draggable } from 'react-beautiful-dnd';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import * as serviceActions from 'views/services/action/service';
import IncomingBookings from 'views/services/IncomingBookings';
import AddService from '../addService';
import { useIsHavingPermission } from 'hooks/auth';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        background: theme.palette.common.white,
        padding: theme.spacing(2),
        borderRadius: theme.radius(3),
        cursor: 'pointer'
    },
    avatar: {
        marginRight: theme.spacing(2),
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            '& .image': {
                display: 'block'
            }
        }
    }
}));

function Service({ title, onEdit, prices, avatar, id, index, categoryId, service, depositRate }) {
    const theme = useTheme();
    const classes = useStyles();
    const [isDelete, setIsDelete] = React.useState(false);
    const [thumbnail, setThumbnail] = React.useState();
    const [isLoading, setLoading] = React.useState(false);
    const [isUploading, setIsUploading] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const [incomingBookings, setInComingBookings] = React.useState(null);
    const [isShowIncomingBookings, setIsShowIncomingBookings] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const isHasDepositPermission = useIsHavingPermission(MERCHANT_PERMISSIONS.DEPOSIT);
    const isHasDepositAmountPermission = useIsHavingPermission(MERCHANT_PERMISSIONS.DEPOSIT_AMOUNT);

    const stopPropagation = React.useCallback((e) => {
        e.stopPropagation();
        e.preventDefault();
    }, []);

    React.useEffect(() => {
        if (avatar) {
            setThumbnail(getImageUrl(avatar));
        } else {
            setThumbnail();
        }
    }, [avatar]);

    const handleOpenDelete = React.useCallback(
        (e) => {
            stopPropagation(e);
            setIsDelete(true);
        },
        [stopPropagation]
    );

    const handleCancelDelete = React.useCallback(
        (e) => {
            stopPropagation(e);
            setIsDelete(false);
        },
        [stopPropagation]
    );

    const onDrop = React.useCallback(
        (acceptedFiles) => {
            const file = acceptedFiles?.[0];
            if (file) {
                setIsUploading(true);
                setThumbnail(URL.createObjectURL(file));

                dispatch(
                    serviceActions.uploadAvatar({
                        ctId: +categoryId,
                        sId: +id,
                        file,
                        successCallback: () => {
                            setIsUploading(false);
                        },
                        errorCallback: () => {
                            setIsUploading(false);
                        }
                    })
                );
            }
        },
        [dispatch, id, categoryId]
    );

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: AVATAR_EXTENSIONS,
        onDropRejected: (files) => {
            const message = files?.[0]?.errors?.[0]?.message;
            enqueueSnackbar(message, {
                variant: 'error'
            });
        }
    });

    const handleDelete = React.useCallback(
        async (e) => {
            setLoading(true);
            stopPropagation(e);
            dispatch(
                serviceActions.deleteService({
                    ctId: +categoryId,
                    sId: +id,
                    errorCallback: () => {},
                    successCallback: (bookings = []) => {
                        if (bookings?.length) {
                            setIsShowIncomingBookings(true);
                            setInComingBookings(bookings);
                        }
                    }
                })
            );
        },
        [stopPropagation, dispatch, id, categoryId]
    );

    const handleIncomingClosed = React.useCallback(() => {
        setIsShowIncomingBookings(false);
        setInComingBookings(null);
    }, []);

    const handleClose = React.useCallback(() => {
        setOpen(false);
    }, []);

    return (
        <Draggable draggableId={id} index={index}>
            {(provided) => (
                <Grid
                    className={classes.wrapper}
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <Grid item style={{ flex: 1 }}>
                        <Grid container direction="row" alignItems="center" spacing={1}>
                            <Grid item style={{ display: 'flex' }}>
                                <IconButton size="small">
                                    <DragIndicator fontSize="small" />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <Grid item className={classes.avatar}>
                                    <Avatar variant="rounded" alt={title} src={thumbnail} />

                                    <Box
                                        style={{
                                            position: 'absolute',
                                            width: '100%',
                                            height: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            zIndex: 10
                                        }}
                                        onClick={stopPropagation}
                                    >
                                        {isUploading ? (
                                            <CircularProgress />
                                        ) : (
                                            <div {...getRootProps()} style={{ width: '100%', height: '100%' }}>
                                                <input {...getInputProps()} />
                                                <UploadIcon
                                                    className="image"
                                                    style={{ color: theme.palette.primary.main, display: 'none' }}
                                                    fontSize="small"
                                                />
                                            </div>
                                        )}
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid item style={{ flex: 1 }}>
                                <Grid container alignItems="center" wrap="nowrap" direction="row">
                                    <Grid item xs={8}>
                                        {title}
                                    </Grid>
                                    {(isHasDepositPermission || isHasDepositAmountPermission) && depositRate > 0 && (
                                        <Grid item xs={2}>
                                            <Chip
                                                size="small"
                                                style={{
                                                    background: theme.colors.sun
                                                }}
                                                label={
                                                    isHasDepositAmountPermission
                                                        ? `Deposit amount $${depositRate}`
                                                        : `Deposit rate ${depositRate}%`
                                                }
                                            />
                                        </Grid>
                                    )}
                                    <Grid item xs={2}>
                                        ${prices?.[0]?.retailPrice}
                                    </Grid>
                                    <Grid item xs={2}>
                                        {prices?.[0]?.duration} mins
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" alignItems="center">
                            {!isDelete && (
                                <>
                                    <Grid item style={{ display: 'flex' }}>
                                        <IconButton
                                            onClick={(e) => {
                                                stopPropagation(e);
                                                setOpen(true);
                                            }}
                                            size="small"
                                        >
                                            <EditIcon fontSize="small" />
                                        </IconButton>
                                    </Grid>
                                    <Grid item style={{ display: 'flex' }}>
                                        <IconButton onClick={handleOpenDelete} size="small">
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                    </Grid>
                                </>
                            )}
                            {isDelete && (
                                <>
                                    {!isLoading && (
                                        <Grid item style={{ display: 'flex' }}>
                                            <IconButton onClick={handleCancelDelete} size="small">
                                                <Tooltip placement="top" title="Cancel">
                                                    <CancelIcon fontSize="small" />
                                                </Tooltip>
                                            </IconButton>
                                        </Grid>
                                    )}
                                    <Grid item style={{ display: 'flex' }}>
                                        <IconButton disabled={isLoading} onClick={handleDelete} size="small">
                                            {isLoading ? (
                                                <CircularProgress color="primary" size="20px" />
                                            ) : (
                                                <Tooltip placement="top" title="Agree">
                                                    <CheckIcon style={{ color: theme.colors.red }} fontSize="small" />
                                                </Tooltip>
                                            )}
                                        </IconButton>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Grid>

                    <Dialog open={isShowIncomingBookings} onClose={handleIncomingClosed}>
                        <IncomingBookings bookings={incomingBookings} onClose={handleIncomingClosed} />
                    </Dialog>
                    <Dialog fullScreen open={open} onClose={handleClose}>
                        <AddService
                            isEdit={true}
                            editedService={service}
                            onClose={handleClose}
                            selectedCategoryId={+categoryId}
                        />
                    </Dialog>
                </Grid>
            )}
        </Draggable>
    );
}

export default Service;
