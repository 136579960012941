export const getStatusCtrlOrShiftKey = (event) => {
    let metaData = {
        isShift: false,
        isCtrl: false
    };

    if (event.shiftKey) {
        metaData.isShift = true;
    }

    if (event.ctrlKey || event.metaKey) {
        metaData.isCtrl = true;
    }
    return metaData;
};

export function isKbUndo(e) {
    const { key, keyCode } = e;
    const { isCtrl, isShift } = getStatusCtrlOrShiftKey(e);
    if (keyCode === 90 && key === 'z' && isCtrl && !isShift) {
        return true;
    }
    return false;
}

export function isKbRedo(e) {
    const { key, keyCode } = e;
    const { isCtrl, isShift } = getStatusCtrlOrShiftKey(e);
    if ((keyCode === 90 && key === 'z' && isCtrl && isShift) || (keyCode === 89 && key === 'y' && isCtrl)) {
        return true;
    }
    return false;
}

export function isKbRefresh(e) {
    const { key, keyCode } = e;
    const { isCtrl } = getStatusCtrlOrShiftKey(e);
    if (keyCode === 82 && key === 'r' && isCtrl) {
        return true;
    }
    return false;
}

export function isKbEscape(e) {
    const { key, keyCode } = e;
    if (key === 'Escape' && keyCode === 27) {
        return true;
    }
    return false;
}

export function isKbEnter(e) {
    const { key, keyCode } = e;
    if (key === 'Enter' || keyCode === 13) {
        return true;
    }
    return false;
}

export function isKbCtrlEnter(e) {
    const { key, keyCode } = e;
    const { isCtrl } = getStatusCtrlOrShiftKey(e);
    if (isCtrl && (key === 'Enter' || keyCode === 13)) {
        return true;
    }
    return false;
}

export function isKbShiftEnter(e) {
    const { key, keyCode } = e;
    const { isShift } = getStatusCtrlOrShiftKey(e);
    if (isShift && (key === 'Enter' || keyCode === 13)) {
        return true;
    }
    return false;
}

export function isKbTab(e) {
    const { key, keyCode } = e;
    const { isShift } = getStatusCtrlOrShiftKey(e);
    if ((key === 'Tab' || keyCode === 9) && !isShift) {
        return true;
    }
    return false;
}

export function isKbShiftTab(e) {
    const { key, keyCode } = e;
    const { isShift } = getStatusCtrlOrShiftKey(e);
    if ((key === 'Tab' || keyCode === 9) && isShift) {
        return true;
    }
    return false;
}

export function isKbCopy(e) {
    const { key, keyCode } = e;
    const { isCtrl } = getStatusCtrlOrShiftKey(e);
    if (keyCode === 67 && key === 'c' && isCtrl) {
        return true;
    }
    return false;
}

export function isKbPaste(e) {
    const { key, keyCode } = e;
    const { isCtrl } = getStatusCtrlOrShiftKey(e);
    if (keyCode === 86 && key === 'v' && isCtrl) {
        return true;
    }
    return false;
}

export function isKbDelete(e) {
    const { key, keyCode } = e;
    if (keyCode === 8 || key === 'Backspace' || keyCode === 46 || key.toLowerCase() === 'delete') {
        return true;
    }
    return false;
}

export function isKbArrowRight(e) {
    const { key, keyCode } = e;
    const { isShift } = getStatusCtrlOrShiftKey(e);
    if (keyCode === 39 && key === 'ArrowRight' && !isShift) {
        return true;
    }
    return false;
}

export function isKbShiftArrowRight(e) {
    const { key, keyCode } = e;
    const { isShift } = getStatusCtrlOrShiftKey(e);
    if (keyCode === 39 && key === 'ArrowRight' && isShift) {
        return true;
    }
    return false;
}

export function isKbArrowUp(e) {
    const { key, keyCode } = e;
    const { isShift } = getStatusCtrlOrShiftKey(e);
    if (keyCode === 38 && key === 'ArrowUp' && !isShift) {
        return true;
    }
    return false;
}

export function isKbShiftArrowUp(e) {
    const { key, keyCode } = e;
    const { isShift } = getStatusCtrlOrShiftKey(e);
    if (keyCode === 38 && key === 'ArrowUp' && isShift) {
        return true;
    }
    return false;
}

export function isKbArrowDown(e) {
    const { key, keyCode } = e;
    const { isShift } = getStatusCtrlOrShiftKey(e);
    if (keyCode === 40 && key === 'ArrowDown' && !isShift) {
        return true;
    }
    return false;
}

export function isKbShiftArrowDown(e) {
    const { key, keyCode } = e;
    const { isShift } = getStatusCtrlOrShiftKey(e);
    if (keyCode === 40 && key === 'ArrowDown' && isShift) {
        return true;
    }
    return false;
}

export function isKbArrowLeft(e) {
    const { key, keyCode } = e;
    const { isShift } = getStatusCtrlOrShiftKey(e);
    if (keyCode === 37 && key === 'ArrowLeft' && !isShift) {
        return true;
    }
    return false;
}

export function isKbShiftArrowLeft(e) {
    const { key, keyCode } = e;
    const { isShift } = getStatusCtrlOrShiftKey(e);
    if (keyCode === 37 && key === 'ArrowLeft' && isShift) {
        return true;
    }
    return false;
}

export function isKbPageUp(e) {
    const { key, keyCode } = e;
    if (keyCode === 33 && key === 'PageUp') {
        return true;
    }
    return false;
}

export function isKbPageDown(e) {
    const { key, keyCode } = e;
    if (keyCode === 34 && key === 'PageDown') {
        return true;
    }
    return false;
}

export function isKbEnd(e) {
    const { key, keyCode } = e;
    if (keyCode === 35 && key === 'End') {
        return true;
    }
    return false;
}

export function isKbHome(e) {
    const { key, keyCode } = e;
    if (keyCode === 36 && key === 'Home') {
        return true;
    }
    return false;
}
