import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Chip, Dialog } from '@material-ui/core';
import DialogActions from 'components/dialog/Action';
import DialogTitle from 'components/dialog/Title';
import DialogContent from 'components/dialog/Content';
import Button from 'components/button/Base';
import { useDropzone } from 'react-dropzone';
import { POLICY_FILE_EXTENSIONS } from 'const';
import { enqueueSnackbar } from 'notifier/actions';
import { useDispatch } from 'react-redux';
import { CloudUpload, CheckBox, Save } from '@material-ui/icons';
import { useMerchantDetail } from 'hooks/merchant';
import * as merchantActions from 'merchant/actions';
import Spinner from 'components/spinner';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const useStyles = makeStyles((theme) => ({
    content: {
        minWidth: 600,
        boxSizing: 'border-box'
    },
    uploadSection: {
        flex: 1,
        padding: theme.spacing(2)
    },
    mr2: {
        marginRight: theme.spacing(2)
    },
    mb2: {
        marginBottom: theme.spacing(2)
    },
    mb3: {
        marginBottom: theme.spacing(3)
    },
    dpFlex: {
        display: 'flex'
    },
    hasFile: {
        border: `1px solid ${theme.palette.primary.main}`
    },
    btnSubmit: {
        position: 'relative'
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    highlight: {
        color: theme.colors.red
    },
    highlightBlue: {
        color: theme.palette.primary.main
    },
    editor: {
        '& .editor-wrapper': {
            border: `1px solid ${theme.colors.border}`,
            borderRadius: 10
        },
        '& .editor': {
            minHeight: 150,
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingBottom: theme.spacing(2)
        },
        '& .public-DraftStyleDefault-block': {
            margin: `2px 0px!important`
        }
    },
    preview: {
        minWidth: 400,
        maxWidth: 400
    },
    link: {
        color: theme.colors.blue,
        '&:hover': {
            textDecoration: 'underline',
            cursor: 'pointer'
        }
    },
    policy: {
        minWidth: 600,
        [theme.breakpoints.down('sm')]: {
            minWidth: '100%'
        }
    }
}));

function PolicySetting({ onClose }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const merchant = useMerchantDetail();
    const [isUploading, setIsUploading] = React.useState(false);
    const [openPolicy, setOpenPolicy] = React.useState(false);
    const [openTerms, setOpenTerms] = React.useState(false);

    const policyDefaultState = React.useMemo(() => {
        const blocksFromHtml = htmlToDraft(merchant?.policyNote || ``);
        const { contentBlocks, entityMap } = blocksFromHtml;
        return ContentState.createFromBlockArray(contentBlocks, entityMap);
    }, [merchant]);

    const informationDefaultState = React.useMemo(() => {
        const blocksFromHtml = htmlToDraft(merchant?.policyInformation || ``);
        const { contentBlocks, entityMap } = blocksFromHtml;
        return ContentState.createFromBlockArray(contentBlocks, entityMap);
    }, [merchant]);

    const [policyState, setPolicyState] = React.useState(
        merchant?.policyNote ? EditorState.createWithContent(policyDefaultState) : EditorState.createEmpty()
    );

    const [informationState, setInformationState] = React.useState(
        merchant?.policyInformation ? EditorState.createWithContent(informationDefaultState) : EditorState.createEmpty()
    );

    const onServiceFileAccepted = React.useCallback(
        (files) => {
            const file = files?.[0];

            setIsUploading(true);

            dispatch(
                merchantActions.uploadPolicy({
                    merchantId: +merchant?.id,
                    file,
                    successCallback: (merchant) => {
                        setIsUploading(false);
                    },
                    errorCallback: () => {
                        setIsUploading(false);
                        console.log('upload merchant failed');
                    }
                })
            );
        },
        [dispatch, merchant]
    );

    const onReject = React.useCallback(
        (files) => {
            dispatch(enqueueSnackbar({ message: 'Rejected file', type: 'info' }));
        },
        [dispatch]
    );

    const removePolicy = React.useCallback(() => {
        dispatch(
            merchantActions.updateNormalMerchant({
                merchantId: +merchant?.id,
                body: { policyUrl: null },
                successCallback: () => {},
                errorCallback: () => {}
            })
        );
    }, [merchant, dispatch]);

    const stopPropagation = React.useCallback((e) => {
        e.stopPropagation();
        e.preventDefault();
    }, []);

    const openMerchantTerms = React.useCallback(
        (e) => {
            stopPropagation(e);
            setOpenTerms(true);
        },
        [stopPropagation]
    );

    const openMerchantPolicy = React.useCallback(
        (e) => {
            stopPropagation(e);
            setOpenPolicy(true);
        },
        [stopPropagation]
    );

    const { getRootProps: serviceRootProps, getInputProps: serviceInputProps } = useDropzone({
        onDropAccepted: onServiceFileAccepted,
        onDropRejected: onReject,
        accept: POLICY_FILE_EXTENSIONS,
        disabled: isUploading
    });

    const policyNoteHtml = React.useMemo(() => {
        return draftToHtml(convertToRaw(policyState.getCurrentContent()));
    }, [policyState]);

    const isShowTerms = React.useMemo(() => {
        return policyNoteHtml !== '<p></p>\n';
    }, [policyNoteHtml]);

    const InformationHtml = React.useMemo(() => {
        return draftToHtml(convertToRaw(informationState.getCurrentContent()));
    }, [informationState]);

    const isShowExtra = React.useMemo(() => {
        return InformationHtml !== '<p></p>\n';
    }, [InformationHtml]);

    const handleSave = React.useCallback(() => {
        const html = draftToHtml(convertToRaw(policyState.getCurrentContent()));
        dispatch(
            merchantActions.updateNormalMerchant({
                merchantId: +merchant?.id,
                body: { policyNote: html, policyInformation: InformationHtml },
                successCallback: () => {
                    console.log('Save terms success');
                },
                errorCallback: () => {
                    console.log('Save terms failed');
                }
            })
        );
    }, [dispatch, policyState, merchant, InformationHtml]);

    return (
        <>
            <DialogTitle>
                <Typography className={classes.whiteColor} variant="h5" component="div">
                    Policy Setting
                </Typography>
            </DialogTitle>
            <DialogContent className={classes.content}>
                <Grid container direction="row" spacing={2}>
                    <Grid item>
                        <Grid container direction="column" alignItems="center" justifyContent="center">
                            <Grid container direction="column" spacing={2}>
                                <Grid item container direction="column" spacing={2}>
                                    <Grid item>
                                        <Typography variant="h4">Terms</Typography>
                                    </Grid>
                                    <Grid item className={classes.editor}>
                                        <Editor
                                            editorState={policyState}
                                            wrapperClassName="editor-wrapper"
                                            editorClassName="editor"
                                            onEditorStateChange={(editor) => setPolicyState(editor)}
                                            toolbar={{
                                                options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign'],
                                                inline: {
                                                    options: ['bold', 'italic', 'underline', 'strikethrough']
                                                },
                                                list: { options: ['unordered', 'ordered'] },
                                                link: { inDropdown: true },
                                                textAlign: { inDropdown: true }
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item container direction="column" spacing={2}>
                                    <Grid item>
                                        <Typography variant="h4">Extra information</Typography>
                                    </Grid>
                                    <Grid item className={classes.editor}>
                                        <Editor
                                            editorState={informationState}
                                            wrapperClassName="editor-wrapper"
                                            editorClassName="editor"
                                            onEditorStateChange={(editor) => setInformationState(editor)}
                                            toolbar={{
                                                options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign'],
                                                inline: {
                                                    options: ['bold', 'italic', 'underline', 'strikethrough']
                                                },
                                                list: { options: ['unordered', 'ordered'] },
                                                link: { inDropdown: true },
                                                textAlign: { inDropdown: true }
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.preview}>
                        <Grid item container direction="row" justifyContent="flex-end">
                            <div {...serviceRootProps()}>
                                <input {...serviceInputProps()} />
                                <Grid
                                    container
                                    style={{ cursor: !isUploading && 'pointer' }}
                                    direction="row"
                                    alignItems="center"
                                    spacing={1}
                                >
                                    <Grid item style={{ display: 'flex', marginRight: isUploading ? 8 : 0 }}>
                                        {isUploading ? <Spinner size={20} thick={4} /> : <CloudUpload />}
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2">Upload pdf file</Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>

                        <Grid item container direction="column" spacing={3}>
                            <Grid item>
                                <Grid container alignItems="center" spacing={1} direction="row">
                                    <Grid item>
                                        <Typography variant="h4">Preview</Typography>
                                    </Grid>
                                    {merchant?.policyUrl && (
                                        <Grid item>
                                            <Chip
                                                color="secondary"
                                                onDelete={removePolicy}
                                                label={'Delete policy file'}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction="column" spacing={2}>
                                    <Grid item>
                                        <Grid container wrap="nowrap" direction="row" spacing={1}>
                                            <Grid item>
                                                <CheckBox />
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2">
                                                    I agree to receive Booksalon ‘s newsletter, email and SMS containing
                                                    notification for booking, updates and promotions. You can withdraw
                                                    your consent at any time.
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        {(isShowTerms || merchant?.policyUrl) && (
                                            <Grid container wrap="nowrap" direction="row" spacing={1}>
                                                <Grid item>
                                                    <CheckBox />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body2">
                                                        I agree{' '}
                                                        {isShowTerms && (
                                                            <span onClick={openMerchantTerms} className={classes.link}>
                                                                {merchant?.title}'s Terms
                                                            </span>
                                                        )}{' '}
                                                        {isShowTerms && merchant?.policyUrl && 'and '}
                                                        {merchant?.policyUrl ? (
                                                            <span onClick={openMerchantPolicy} className={classes.link}>
                                                                Privacy Policy
                                                            </span>
                                                        ) : (
                                                            ``
                                                        )}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        )}

                                        {isShowExtra && (
                                            <Grid container wrap="nowrap" direction="row" spacing={1}>
                                                <Typography variant="body2">
                                                    <div dangerouslySetInnerHTML={{ __html: InformationHtml }}></div>
                                                </Typography>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container wrap="nowrap" spacing={2} alignItems="center" justifyContent="flex-end">
                    <Grid item>
                        <Button onClick={onClose} variant="outlined">
                            Close
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button color="primary" startIcon={<Save />} onClick={handleSave} variant="contained">
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
            <Dialog maxWidth={false} onClose={(e) => setOpenTerms(false)} open={openTerms}>
                <DialogTitle id="alert-dialog-title">
                    <Typography className={classes.whiteColor} variant="h5" component="div">
                        {merchant?.title}'s terms
                    </Typography>
                </DialogTitle>
                <DialogContent className={classes.policy}>
                    <div dangerouslySetInnerHTML={{ __html: policyNoteHtml }}></div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={(e) => setOpenTerms(false)} variant="outlined">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog onClose={(e) => setOpenPolicy(false)} open={openPolicy}>
                <Dialog maxWidth={false} onClose={(e) => setOpenPolicy(false)} open={openPolicy}>
                    <Typography className={classes.whiteColor} variant="h5" component="div">
                        {merchant?.title}'s privacy policy
                    </Typography>
                    <DialogContent className={classes.policy}>
                        <object
                            data={`${merchant?.policyUrl}?downloadFileOption=inline`}
                            type="application/pdf"
                            style={{
                                width: '100%',
                                minHeight: 600
                            }}
                            aria-labelledby="pdf"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button size="small" onClick={(e) => setOpenPolicy(false)} variant="outlined">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </Dialog>
        </>
    );
}

export default PolicySetting;
