import * as types from './types';

const INITIAL_STATE = {
    detail: null,
    isFetchingMerchantInfo: false,
    groupMerchants: []
};

export default function Merchant(state = INITIAL_STATE, { type, payload }) {
    switch (type) {
        case types.FETCH_MERCHANT_INFO: {
            return {
                ...state,
                isFetchingMerchantInfo: true
            };
        }

        case types.FETCH_MERCHANT_INFO_FAILED: {
            return {
                ...state,
                isFetchingMerchantInfo: false
            };
        }

        case types.FETCH_MERCHANT_INFO_SUCCESS: {
            const { merchant } = payload;
            return {
                ...state,
                detail: merchant,
                isFetchingMerchantInfo: false
            };
        }

        case types.UPDATE_MERCHANT: {
            const { merchant } = payload;
            return {
                ...state,
                detail: merchant
            };
        }

        case types.FETCH_OTHER_MERCHANTS: {
            const { merchants } = payload;
            return {
                ...state,
                groupMerchants: merchants
            };
        }

        default:
            return state;
    }
}
