import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import LayoutTabContent from 'views/layout/LayoutTabContent';
import InputBase from 'components/input';
import ButtonBase from 'components/button/Base';
import { isKbEnter } from 'utils/keyboard';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%'
    },
    form: {
        maxWidth: 400,
        minWidth: 300,
        margin: '0 auto',
        background: theme.palette.common.white,
        borderRadius: 6,
        boxShadow: theme.shadows?.[1],
        marginTop: theme.spacing(3),
        height: 'auto',
        padding: theme.spacing(3)
    },
    mt4: {
        marginTop: theme.spacing(4)
    },
    mb4: {
        marginBottom: theme.spacing(4)
    },
    error: {
        color: theme.colors.red
    },
    btnSubmit: {
        position: 'relative'
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    }
}));

function RequirePasswordForm({ password, onPasswordChange, isLoading, onCheckPassword, error }) {
    const classes = useStyles();

    const handleKeyDown = (e) => {
        if (isKbEnter(e) && password) {
            onCheckPassword && onCheckPassword();
        }
    };

    return (
        <LayoutTabContent>
            <Grid className={classes.root} container direction="row" alignItems="flex-start" justifyContent="center">
                <Grid container direction="column" item className={classes.form}>
                    <Grid item className={classes.mb4}>
                        <Typography align="center" variant="h3">
                            Management access only
                        </Typography>
                        {error && (
                            <Typography className={classes.error} align="center" variant="body2">
                                Password do not match
                            </Typography>
                        )}
                    </Grid>

                    <Grid item>
                        <InputBase
                            type="password"
                            label={'Enter your password'}
                            name="password"
                            value={password}
                            disabled={isLoading}
                            onChange={onPasswordChange}
                            placeholder="Password"
                            autoFocus
                            onKeyDown={handleKeyDown}
                        />
                    </Grid>
                    <Grid item className={classes.mt4}>
                        <div className={classes.btnSubmit}>
                            <ButtonBase
                                fullWidth
                                type="submit"
                                color="primary"
                                variant="contained"
                                disabled={isLoading || !password}
                                className={classes.input}
                                onClick={onCheckPassword}
                            >
                                Check
                            </ButtonBase>
                            {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </LayoutTabContent>
    );
}

export default RequirePasswordForm;
