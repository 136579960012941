import { DEFAULT_MINUTES, ROW_HEIGHT } from 'const';
import { generateNumberOptions } from 'utils/timing';

export function convertDefaultMinutes(zoom) {
    switch (zoom) {
        case 0:
            return DEFAULT_MINUTES;
        case 1:
            return DEFAULT_MINUTES * 2;
        case 2:
            return DEFAULT_MINUTES * 4;
        default:
            return DEFAULT_MINUTES;
    }
}

export function convertRatio({ zoom, height }) {
    const defaultMinutes = convertDefaultMinutes(zoom);
    return height / defaultMinutes;
}

export function convertSnapY(zoom) {
    switch (zoom) {
        case 0:
            return generateNumberOptions(ROW_HEIGHT / 3, ROW_HEIGHT / 3, 16 * ROW_HEIGHT);
        case 1:
            return generateNumberOptions(ROW_HEIGHT / 6, ROW_HEIGHT / 6, ROW_HEIGHT * 8);
        case 2:
            return generateNumberOptions(ROW_HEIGHT / 12, ROW_HEIGHT / 12, 4 * ROW_HEIGHT);
        default:
            return generateNumberOptions(ROW_HEIGHT, ROW_HEIGHT, 16 * DEFAULT_MINUTES);
    }
}
