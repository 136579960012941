import React from 'react';
import { useTheme, Grid, Box } from '@material-ui/core';
import { getImageUrl } from 'utils/image';
import { getFullName } from 'utils/naming';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import * as turnActions from 'views/turn/actions';
import ConfirmDialog from 'components/dialog/Confirm';
import Avatar from 'components/avatar';
import Tooltip from 'components/tooltip';

const useStyles = makeStyles((theme) => ({
    staff: {
        position: 'relative',
        height: '100%'
    },
    staffWrapper: {}
}));

function StaffInTurn({ staff, index }) {
    const theme = useTheme();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [openConfirm, setOpenConfirm] = React.useState(false);

    const handleClose = React.useCallback(() => {
        setOpenConfirm(false);
    }, []);

    const kickStaffOtOutOfInTurnHandle = React.useCallback(async () => {
        dispatch(
            turnActions.kickStaffOutOfTurn({
                staffId: +staff?.id,
                staffName: getFullName(staff),
                successCallback: () => {
                    console.log('success');
                    handleClose && handleClose();
                },
                errorCallback: () => {
                    console.log('failed');
                }
            })
        );
    }, [staff, dispatch, handleClose]);

    return (
        <>
            <Tooltip title={getFullName(staff)} placement="left">
                <Grid
                    onClick={() => setOpenConfirm(true)}
                    container
                    alignItems="center"
                    direction="row"
                    justifyContent="center"
                    wrap="nowrap"
                    className={classes.staff}
                >
                    <Box
                        style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            width: 20,
                            height: 20,
                            borderRadius: '50%',
                            background: index === 0 ? theme.colors.sun : theme.colors.pink,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: theme.palette.common.white,
                            zIndex: 10
                        }}
                    >
                        {index + 1}
                    </Box>
                    <Grid item>
                        <Avatar src={getImageUrl(staff?.avatar)} alt={getFullName(staff)} />
                    </Grid>
                </Grid>
            </Tooltip>

            <ConfirmDialog
                open={openConfirm}
                title={`Assign turn`}
                text={`Do you want to assign turn for <strong>${getFullName(staff)}</strong>?`}
                onAgree={kickStaffOtOutOfInTurnHandle}
                onDisagree={handleClose}
                type={'INFO'}
            />
        </>
    );
}

export default StaffInTurn;
