import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, Avatar } from '@material-ui/core';
import * as calendarActions from 'views/calender/actions';
import { useDispatch } from 'react-redux';
import { getCurrentDate } from 'utils/timing';
import { useArrivedListSortedByUpdatedTime } from 'hooks/calendar';
import { getFullName } from 'utils/naming';
import { getImageUrl } from 'utils/image';
import PhoneFormat from 'components/phoneFormat';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 600,
        margin: `0 auto`,
        padding: theme.spacing(3),
        overflowY: 'auto'
    },
    index: {
        width: 30,
        height: 30,
        borderRadius: 15,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 'bold'
    },
    client: {
        padding: theme.spacing(3),
        marginBottom: theme.spacing(2)
    }
}));

function ClientArrived() {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const internalRef = React.useRef();
    const arrivedList = useArrivedListSortedByUpdatedTime();

    const fetchBookingsStaffsByDate = React.useCallback(
        (date) => {
            dispatch(
                calendarActions.fetchCombinedDate({
                    date,
                    success: () => {
                        console.log('fetch success');
                    },
                    error: () => {
                        console.log('fetch failed');
                    }
                })
            );
        },
        [dispatch]
    );

    React.useEffect(() => {
        fetchBookingsStaffsByDate(getCurrentDate());
    }, [fetchBookingsStaffsByDate]);

    React.useEffect(() => {
        internalRef.current = setInterval(() => {
            fetchBookingsStaffsByDate(getCurrentDate());
        }, 10 * 60 * 1000);

        return () => {
            if (internalRef.current) clearInterval(internalRef?.current);
        };
    }, [fetchBookingsStaffsByDate]);

    return (
        <Grid container direction="column" spacing={4} className={classes.root}>
            <Grid item>
                <Typography variant="h3">Client Arrived ({arrivedList.length})</Typography>
            </Grid>
            <Grid item>
                <Grid container direction="column" spacing={2}>
                    {arrivedList?.map((client, index) => {
                        return (
                            <Grid item key={client?.bookingId} className={classes.client}>
                                <Grid
                                    container
                                    direction="row"
                                    alignItems="center"
                                    spacing={2}
                                    justifyContent="space-between"
                                >
                                    <Grid item>
                                        <Grid container direction="row" alignItems="center" spacing={2}>
                                            <Grid item>
                                                <Avatar
                                                    src={getImageUrl(client?.avatar)}
                                                    alt={getFullName(client)}
                                                ></Avatar>
                                            </Grid>
                                            <Grid item>
                                                <Grid container direction="column">
                                                    <Grid item>
                                                        <Typography style={{ fontSize: 20 }} variant="body1">
                                                            {getFullName(client)}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <PhoneFormat number={client?.phone} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        item
                                        className={classes.index}
                                        style={{
                                            background: index === 0 ? theme.colors.green : theme.colors.sun
                                        }}
                                    >
                                        {index + 1}
                                    </Grid>
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default ClientArrived;
