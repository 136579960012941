import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, IconButton, CircularProgress, Paper } from '@material-ui/core';
import TableRow from 'components/table/TableRow';
import { getFullName } from 'utils/naming';
import isEmpty from 'lodash/isEmpty';
import Avatar from 'components/avatar';
import {
    mapTimeToTime12,
    getClientArrivedBookingStyleByStartTime,
    mapTimeToTime12WithoutAMPm,
    convertFloatingToDateTime,
    convertDurationAndStartTimeToDate,
    getTopByCurrentMinutes,
    getEndTimeByStartTimeAndDuration
} from 'utils/timing';
import sortBy from 'lodash/sortBy';
import hexToRgba from 'hex-to-rgba';
import { CloudOutlined, FavoriteOutlined, CommentRounded, NotificationImportantOutlined } from '@material-ui/icons';
import { red } from '@material-ui/core/colors';
import { getColorByUserId, DEFAULT_COLOR } from 'utils/color';
import * as calendarActions from 'views/calender/actions';
import * as bookingActions from 'views/booking/actions';
import { useDispatch } from 'react-redux';
import { BOOKING_ACTIONS, BOOKING_TYPES, BOOKING_SERVICE_STATES } from 'const';
import scrollIntoView from 'scroll-into-view-if-needed';
import { waitForElementToDisplay } from 'utils/waitElement';
import orderBy from 'lodash/orderBy';
import CloseIcon from '@material-ui/icons/Close';
import AutoSizer from 'react-virtualized-auto-sizer';
import Search from 'components/search';
import Tooltip from 'components/tooltip';
import PopperMenu from 'components/popper';
import ListItem from 'components/list/Item';
import { getImageUrl } from 'utils/image';
import Spinner from 'components/spinner';
import PrintIcon from '@material-ui/icons/Print';
import DoneIcon from '@material-ui/icons/Check';
import Button from 'components/button/Base';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ClosedDateIllustratorSVG from 'assets/images/svg/ClosedDateIllustratorSVG';
import { useMerchantTime12 } from 'hooks/merchant';
import { useCalendarOptions, useDefaultMinutes } from 'hooks/calendar';

function getBookingServiceColor({ bookingServiceState, defaultColor, theme }) {
    switch (bookingServiceState) {
        case BOOKING_SERVICE_STATES.DONE:
            return theme.bkSv?.[BOOKING_SERVICE_STATES.DONE];

        case BOOKING_SERVICE_STATES.SERVING:
            return theme.bkSv?.[BOOKING_SERVICE_STATES.SERVING];
        default:
            return defaultColor;
    }
}

const COLUMN_WIDTH = 100;
const INDEX_COLUMN_WIDTH = 175;
const ROW_HEIGHT = 120;
const HEADER_HEIGHT = 36;

const useStyles = makeStyles((theme) => ({
    root: {},
    headerCell: {
        display: 'flex',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.common.white}`,
        borderRight: `1px solid ${theme.palette.common.white}`
    },
    cell: {
        display: 'flex',
        alignItems: 'center',
        // paddingLeft: 5,
        transition: 0.5,
        background: theme.palette.common.white,
        // borderRight: `1px solid ${hexToRgba(theme.colors.gullGray, 0.3)}`,
        borderBottom: `1px solid ${theme.palette.common.white}`,
        borderRight: `1px solid ${theme.palette.common.white}`,
        height: '100%'
    },
    btnSubmit: {
        position: 'relative'
    },
    buttonProgress: {
        color: theme.palette.common.white,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -10,
        marginLeft: -10
    },
    clientArrived: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        boxShadow: theme.shadows[3],
        borderRadius: 10,
        zIndex: 10,
        width: `60vw`,
        height: `70vh`,
        background: theme.palette.common.white,
        overflow: 'hidden'
    },
    header: {
        padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
        borderBottom: `1px solid ${theme.colors.border}`,
        background: theme.palette.primary.main
    },
    search: {
        border: `1px solid ${theme.colors.border}`,
        minWidth: 200
    },
    searchWrapper: {
        padding: `8px 14px`
    },
    staffList: {
        minWidth: 200,
        maxHeight: 300,
        overflow: 'auto'
    }
}));

function HeaderRender({ isMobile, MINUTES, columnIndex, style, time }) {
    const classes = useStyles();
    const theme = useTheme();
    const TIME_12 = useMerchantTime12();

    const timeLabel = React.useMemo(() => {
        return TIME_12 ? mapTimeToTime12(time) : time;
    }, [TIME_12, time]);

    if (columnIndex === 0)
        return (
            <div
                style={{
                    ...style,
                    background: theme.colors.background,
                    justifyContent: 'center',
                    position: 'relative'
                }}
                className={classes.headerCell}
            />
        );

    return (
        <div
            style={{
                ...style,
                background: theme.colors.background,
                justifyContent: 'center',
                position: 'relative'
            }}
            className={classes.headerCell}
        >
            <Typography variant="body2" style={{ fontSize: 12 }}>
                {timeLabel}
            </Typography>
        </div>
    );
}

const HeaderRenderMemo = React.memo(HeaderRender);

function CellRender({ client, style, rowIndex, columnIndex, bookingServices }) {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const fullName = getFullName(client);

    const [isMakingDone, setIsMakingDone] = React.useState(false);
    const [isPrinting, setIsPrinting] = React.useState(false);

    const handleMakingDone = React.useCallback(() => {
        const bookingServicesSorted = sortBy(bookingServices, ['startTime']);

        const booking = bookingServicesSorted?.[0]?.booking;

        if (!booking) return;
        setIsMakingDone(true);
        dispatch(
            calendarActions.updateBooking({
                bookingId: booking.id,
                body: {
                    state: BOOKING_ACTIONS.END
                },
                successCallback: () => {
                    setIsMakingDone(false);
                    console.log('success');
                },
                errorCallback: () => {
                    setIsMakingDone(false);
                    console.log('handleConfirmArrived failed');
                }
            })
        );
    }, [bookingServices, dispatch]);

    const handlePrint = React.useCallback(
        (e) => {
            const bookingServicesSorted = sortBy(bookingServices, ['startTime']);

            const bookingIds = [];

            for (const bkSv of bookingServicesSorted) {
                const bookingId = +bkSv?.bookingId;
                bookingIds.push(bookingId);
            }

            const uniqueBookingIds = [...new Set([...bookingIds])];

            setIsPrinting(true);

            dispatch(
                bookingActions.printBookings({
                    bookingIds: uniqueBookingIds,
                    successCallback: () => {
                        setIsPrinting(false);
                    },
                    errorCallback: () => {
                        setIsPrinting(false);
                    }
                })
            );
        },
        [dispatch, bookingServices]
    );

    if (columnIndex !== 0)
        return (
            <div
                style={{
                    ...style,
                    background: theme.colors.background,
                    alignItems: 'center',
                    position: 'relative',
                    flexDirection: 'column',
                    justifyContent: 'center'
                }}
                className={classes.cell}
            />
        );
    return (
        <Grid container justifyContent="center" alignItems="center" direction="column" className={classes.cell}>
            <Grid item style={{ marginBottom: theme.spacing(1) }}>
                <Avatar size="medium" src={getImageUrl(client?.avatar)}>
                    {fullName?.slice(0, 1)}
                </Avatar>
            </Grid>
            <Grid item style={{ marginBottom: theme.spacing(1) }}>
                <Typography variant="body1">{fullName}</Typography>
            </Grid>

            <Grid item>
                <Grid container direction="row" spacing={2} justifyContent="center" alignItems="center" wrap="nowrap">
                    <Grid item>
                        <Button
                            onClick={handlePrint}
                            startIcon={<PrintIcon />}
                            size="small"
                            variant="contained"
                            color="secondary"
                            disabled={isPrinting}
                        >
                            Print
                        </Button>
                        {isPrinting && <CircularProgress size={12} className={classes.buttonProgress} />}
                    </Grid>

                    <Grid item>
                        <Button
                            onClick={handleMakingDone}
                            startIcon={<DoneIcon />}
                            size="small"
                            variant="contained"
                            disabled={isMakingDone}
                        >
                            Done
                        </Button>
                        {isMakingDone && <CircularProgress size={12} className={classes.buttonProgress} />}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

const CellRenderMemo = React.memo(CellRender);

function BookingService({
    order,
    staffDetail,
    isWarning,
    RATIO,
    style,
    serviceDetail,
    isDuplicateBooking,
    offset,
    height,
    index,
    staffList
}) {
    const theme = useTheme();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isSending, setIsSending] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [searchValue, setSearchValue] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);
    const TIME_12 = useMerchantTime12();

    const handleClick = (e) => {
        setAnchorEl(anchorEl ? null : e.currentTarget);
    };

    const handleClickAway = () => {
        setAnchorEl(null);
    };

    const staffFiltered = React.useMemo(() => {
        return staffList?.filter((staff) => staff?.label?.toLowerCase()?.includes(searchValue?.toLowerCase()));
    }, [staffList, searchValue]);

    const handleSendNotification = ({ bookingService }) => {
        setIsSending(true);

        dispatch(
            calendarActions.sendStaffNotification({
                body: {
                    staffId: +bookingService?.staffId,
                    bkSvId: +bookingService?.id
                },
                successCallback: () => {
                    setIsSending(false);
                    console.log('send notification success');
                },
                errorCallback: () => {
                    setIsSending(false);
                    console.log('send notification failed');
                }
            })
        );
    };

    const handleChangeStaff = React.useCallback(
        (newStaffId) => {
            handleClickAway();
            if (newStaffId === +order?.staffId) return;

            setIsLoading(true);
            const booking = order?.booking;
            const client = order?.client;
            const body = {
                title: booking?.title,
                note: booking?.note,
                phone: client?.phone,
                startDate: booking?.startDate,
                state: BOOKING_ACTIONS.CLIENT_CONFIRMED,
                startTime: booking?.startTime,
                bookingServices: booking?.bookingServices?.map((bkSv) => {
                    if (+order?.id === +bkSv?.id) {
                        return {
                            serviceId: +bkSv?.serviceId,
                            staffId: newStaffId,
                            startTime: bkSv?.startTime,
                            duration: +bkSv?.duration,
                            love: bkSv?.love,
                            id: +bkSv?.id,
                            price: +bkSv?.price
                        };
                    }

                    return {
                        serviceId: +bkSv?.serviceId,
                        staffId: +bkSv?.staffId,
                        startTime: bkSv?.startTime,
                        duration: +bkSv?.duration,
                        love: bkSv?.love,
                        id: +bkSv?.id,
                        price: +bkSv?.price
                    };
                })
            };

            dispatch(
                calendarActions.updateBooking({
                    bookingId: booking.id,
                    body,
                    oldBooking: booking,
                    successCallback: () => {
                        console.log('update booking sucsces');
                        setIsLoading(false);
                    },
                    errorCallback: () => {
                        console.log('update booking failed');
                        setIsLoading(false);
                    }
                })
            );
        },
        [dispatch, order]
    );

    return (
        <Tooltip
            color={theme.colors.ghostwhite}
            placement="right"
            title={
                <Grid style={{ minWidth: 200 }} container direction="column" wrap="nowrap">
                    <div
                        container
                        style={{ position: 'absolute', top: 2, right: 2, display: 'flex', alignItems: 'center' }}
                    >
                        <Typography
                            variant="body2"
                            style={{
                                borderRadius: 4,
                                background: getColorByUserId(+staffDetail?.id),
                                padding: `0px 2px`,
                                marginRight: 4,
                                color: 'white',
                                fontSize: 12
                            }}
                        >
                            {isWarning ? `⚠️` : ' '} {getFullName(staffDetail)}
                        </Typography>
                    </div>
                    <Grid item>
                        <Typography variant="body2" className={classes.timeBooking} align="left">
                            {`${
                                TIME_12
                                    ? mapTimeToTime12WithoutAMPm(convertFloatingToDateTime(order.startTime))
                                    : convertFloatingToDateTime(order.startTime)
                            } - ${
                                TIME_12
                                    ? mapTimeToTime12WithoutAMPm(
                                          convertDurationAndStartTimeToDate(order.startTime, style.width * (1 / RATIO))
                                      )
                                    : convertDurationAndStartTimeToDate(order.startTime, style.width * (1 / RATIO))
                            }`}
                        </Typography>
                        <Grid className={classes.controls} container alignItems="center" direction="row">
                            {[BOOKING_TYPES.NO_REFERENCE, BOOKING_TYPES.ONLINE].includes(
                                order?.booking?.bookingType
                            ) && (
                                <Grid item style={{ display: 'flex' }}>
                                    <CloudOutlined style={{ color: theme.colors.black }} />
                                </Grid>
                            )}
                            {order?.love && (
                                <Grid item style={{ display: 'flex' }}>
                                    <FavoriteOutlined style={{ color: red[500] }} />
                                </Grid>
                            )}

                            {order?.booking?.note && (
                                <Grid item style={{ display: 'flex' }}>
                                    <CommentRounded style={{ color: theme.colors.black }} />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction="column">
                            <Grid item className={classes.fullWidth}>
                                <Grid container alignItems="center">
                                    <Grid item>
                                        <Typography className={classes.bookingTitle} variant="body1" align="left">
                                            {order.title}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item className={classes.fullWidth}>
                                <Typography className={classes.bookingTitle} align="left" variant="body2">
                                    {serviceDetail.title}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
        >
            <Grid
                id={`booking_scroll_${order?.id}`}
                style={{
                    ...style,
                    left: style?.left + INDEX_COLUMN_WIDTH - offset,
                    border: `1px solid ${theme.palette.common.white}`,
                    height: isDuplicateBooking ? height : ROW_HEIGHT,
                    marginTop: isDuplicateBooking ? `${index * height}px` : 0,
                    overflow: 'hidden',
                    position: 'absolute',
                    background: getBookingServiceColor({
                        bookingServiceState: order?.status,
                        defaultColor: hexToRgba(serviceDetail?.color || DEFAULT_COLOR, 0.7),
                        theme
                    }),
                    zIndex: 3,
                    boxSizing: 'border-box',
                    borderRadius: 4,
                    borderLeft: `5px solid ${getBookingServiceColor({
                        bookingServiceState: order?.status,
                        defaultColor: serviceDetail?.color || DEFAULT_COLOR,
                        theme
                    })}`,
                    paddingLeft: 4
                }}
                className={classes.noSelect}
                container
                direction="column"
                wrap="nowrap"
            >
                <div style={{ position: 'absolute', top: 2, right: 2, display: 'flex', alignItems: 'center' }}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            borderRadius: 4,
                            background: getColorByUserId(+staffDetail?.id),
                            padding: `0px 2px`,
                            cursor: 'pointer',
                            marginRight: 2
                        }}
                        onClick={handleClick}
                    >
                        <Typography
                            variant="body1"
                            style={{
                                color: theme.palette.common.white,
                                fontSize: 12
                            }}
                        >
                            {isWarning ? `⚠️` : ' '} {getFullName(staffDetail)}
                        </Typography>
                        {isLoading && <Spinner rotateColor={theme.palette.common.white} size={10} thick={1} />}
                        <ArrowDropDown style={{ color: theme.palette.common.white }} />
                    </div>
                    {anchorEl && (
                        <PopperMenu noPadding={true} anchorEl={anchorEl} handleClickAway={handleClickAway}>
                            <Paper>
                                <Grid container className={classes.picker} wrap="nowrap" direction="column">
                                    <Grid item className={classes.searchWrapper}>
                                        <Search
                                            placeholder={'Search staff name'}
                                            value={searchValue}
                                            onChange={(e) => setSearchValue(e?.target?.value)}
                                            className={classes.search}
                                            autoFocus
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="column" wrap="nowrap" className={classes.staffList}>
                                            {staffFiltered?.map((staff) => {
                                                return (
                                                    <Grid
                                                        item
                                                        className={classes.staffItem}
                                                        onClick={() => handleChangeStaff(+staff?.value)}
                                                    >
                                                        <ListItem
                                                            icon={() => (
                                                                <Avatar size="medium" src={getImageUrl(staff?.avatar)}>
                                                                    {staff?.label?.slice(0, 1)}
                                                                </Avatar>
                                                            )}
                                                            name={staff?.label}
                                                        />
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </PopperMenu>
                    )}

                    <IconButton
                        style={{ background: theme.colors.darkBlue, width: 24, height: 24 }}
                        onClick={() => handleSendNotification({ bookingService: order })}
                    >
                        {isSending ? (
                            <CircularProgress size={18} className={classes.buttonProgress} />
                        ) : (
                            <NotificationImportantOutlined
                                style={{ color: theme.palette.common.white, width: 18, height: 18 }}
                            />
                        )}
                    </IconButton>
                </div>
                <Grid item>
                    <Typography variant="body2" className={classes.timeBooking} align="left">
                        {`${
                            TIME_12
                                ? mapTimeToTime12WithoutAMPm(convertFloatingToDateTime(order.startTime))
                                : convertFloatingToDateTime(order.startTime)
                        } - ${
                            TIME_12
                                ? mapTimeToTime12WithoutAMPm(
                                      convertDurationAndStartTimeToDate(order.startTime, style.width * (1 / RATIO))
                                  )
                                : convertDurationAndStartTimeToDate(order.startTime, style.width * (1 / RATIO))
                        }`}
                    </Typography>
                    <Grid className={classes.controls} container alignItems="center" direction="row">
                        {[BOOKING_TYPES.NO_REFERENCE, BOOKING_TYPES.ONLINE].includes(order?.booking?.bookingType) && (
                            <Grid item style={{ display: 'flex' }}>
                                <CloudOutlined style={{ color: theme.colors.black }} />
                            </Grid>
                        )}
                        {order?.love && (
                            <Grid item style={{ display: 'flex' }}>
                                <FavoriteOutlined style={{ color: red[500] }} />
                            </Grid>
                        )}

                        {order?.booking?.note && (
                            <Grid item style={{ display: 'flex' }}>
                                <CommentRounded style={{ color: theme.colors.black }} />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container direction="column">
                        <Grid item className={classes.fullWidth}>
                            <Grid container alignItems="center">
                                <Grid item>
                                    <Typography className={classes.bookingTitle} variant="body1" align="left">
                                        {order.title}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item className={classes.fullWidth}>
                            <Typography className={classes.bookingTitle} align="left" variant="body2">
                                {serviceDetail.title}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Tooltip>
    );
}

const BookingServiceMemo = React.memo(BookingService);

function Booking({
    rowIndex,
    clientIds,
    clientDetail,
    clientsArrived,
    MINUTES,
    serviceList,
    categoryList,
    staffList,
    offset,
    RATIO
}) {
    const bookingServices = React.useMemo(() => {
        return clientsArrived?.[clientIds?.[rowIndex]];
    }, [clientIds, rowIndex, clientsArrived]);

    const bookingServicesSortedByStartTime = React.useMemo(() => {
        return sortBy(bookingServices, ['startTime']);
    }, [bookingServices]);

    const conflictObj = React.useMemo(() => {
        let objHold = {};
        const bkSvs = bookingServicesSortedByStartTime || [];

        for (const bkSv of bkSvs) {
            const startTime = bkSv?.startTime;
            const duration = bkSv?.duration;
            const endTime = getEndTimeByStartTimeAndDuration({ startTime, duration });

            const leftBkServices = bkSvs?.filter((sv) => +bkSv?.id !== +sv?.id);

            if (!objHold?.[+bkSv?.id]) {
                objHold[+bkSv?.id] = [+bkSv?.id];
            }

            for (const leftBkSv of leftBkServices) {
                const leftBkSvStartTime = leftBkSv?.startTime;
                const leftBkSvDuration = leftBkSv?.duration;
                const leftBkSvEndTime = getEndTimeByStartTimeAndDuration({
                    startTime: leftBkSvStartTime,
                    duration: leftBkSvDuration
                });

                if (
                    !(
                        (leftBkSvStartTime <= startTime && leftBkSvEndTime <= startTime) ||
                        (leftBkSvStartTime >= endTime && leftBkSvEndTime >= endTime)
                    )
                ) {
                    const childBkSvIds = objHold?.[+leftBkSv?.id] || [];
                    objHold[+bkSv?.id] = [...new Set([...objHold[+bkSv?.id], +leftBkSv?.id, ...childBkSvIds])];
                }
            }
        }

        return objHold;
    }, [bookingServicesSortedByStartTime]);

    const getConflictIdsWidthOrders = React.useCallback(
        ({ bkSvId }) => {
            const otherIds = conflictObj?.[bkSvId];
            let ids = [];
            for (let id of otherIds) {
                const anotherIds = conflictObj?.[id];
                ids = [...new Set([...ids, ...anotherIds])];
            }
            const BkIdIndex = ids?.map((id) => {
                const findIndex = bookingServicesSortedByStartTime?.findIndex((bkSv) => +bkSv?.id === +id);
                return {
                    id,
                    order: findIndex
                };
            });
            const bkSvOrdered = orderBy(BkIdIndex, ['order'], 'asc');

            return bkSvOrdered?.map((bkSv) => +bkSv?.id);
        },
        [conflictObj, bookingServicesSortedByStartTime]
    );

    const getServiceDetail = React.useCallback(
        (serviceId) => {
            const serviceDetail = serviceList?.find((sv) => +sv.id === +serviceId);
            const color = categoryList?.find((ct) => +ct.id === +serviceDetail?.categoryId)?.color || '#000000';
            return {
                ...serviceDetail,
                color
            };
        },
        [categoryList, serviceList]
    );

    const getStaffDetail = React.useCallback(
        (staffId) => {
            return staffList?.find((sv) => +sv.id === +staffId);
        },
        [staffList]
    );

    return bookingServicesSortedByStartTime?.map((order) => {
        const style = getClientArrivedBookingStyleByStartTime({
            startTime: order?.startTime,
            duration: order?.duration,
            minutePerPx: RATIO
        });
        const conflictIds = getConflictIdsWidthOrders({ conflictObj, bkSvId: +order?.id });
        const conflictLength = conflictIds?.length;
        const isDuplicateBooking = conflictLength > 1;
        const index = conflictIds?.findIndex((id) => +id === +order?.id);
        const height = ROW_HEIGHT / conflictLength;

        const serviceDetail = getServiceDetail(order?.serviceId);
        const staffDetail = getStaffDetail(order?.staffId);

        const isWarning = !staffDetail?.staffServices?.map((sv) => +sv?.serviceId)?.includes(+serviceDetail?.id);

        return (
            <div key={order?.id}>
                <BookingServiceMemo
                    order={order}
                    staffDetail={staffDetail}
                    isWarning={isWarning}
                    RATIO={RATIO}
                    style={style}
                    serviceDetail={serviceDetail}
                    isDuplicateBooking={isDuplicateBooking}
                    offset={offset}
                    height={height}
                    index={index}
                    staffList={staffList}
                />
            </div>
        );
    });
}

const BookingsMemo = React.memo(Booking);

function CurrentTimeLine({ RATIO, offset }) {
    const [date, setDate] = React.useState(new Date());
    const [left, setLeft] = React.useState(0);
    const ref = React.useRef();

    React.useEffect(() => {
        ref.current = setInterval(() => {
            setDate(new Date());
        }, 1000);

        return () => {
            if (ref?.current) {
                clearInterval(ref?.current);
            }
        };
    }, []);

    React.useEffect(() => {
        setLeft(getTopByCurrentMinutes({ date, minutePerPx: RATIO }));
    }, [date, RATIO]);

    return (
        <div
            style={{
                width: 2,
                height: '100%',
                background: '#C760A7',
                position: 'absolute',
                left: left - offset + INDEX_COLUMN_WIDTH + 2,
                top: 0,
                zIndex: 4
            }}
        ></div>
    );
}

const CurrentTimeLineMemo = React.memo(CurrentTimeLine);

function FakeCurrentTimeOffset({ RATIO, offset, width }) {
    const [date, setDate] = React.useState(new Date());
    const [left, setLeft] = React.useState(0);

    React.useEffect(() => {
        let id = setInterval(() => {
            setDate(new Date());
        }, 1000);

        return () => clearInterval(id);
    }, []);

    React.useEffect(() => {
        setLeft(getTopByCurrentMinutes({ date, minutePerPx: RATIO }));
    }, [date, RATIO]);

    return (
        <div
            id="scrollCurrentTime"
            style={{
                width: 2,
                height: '100%',
                position: 'absolute',
                left: left - offset + width,
                top: 0,
                zIndex: 4
            }}
        ></div>
    );
}

const FakeCurrentTimeOffsetMemo = React.memo(FakeCurrentTimeOffset);

function ClientArrived({ isMobile, clientDetail, clientsArrived, serviceList, staffList, categoryList, onClose }) {
    const classes = useStyles();
    const theme = useTheme();
    const [searchValue, setSearchValue] = React.useState('');
    const MINUTES = useDefaultMinutes();
    const calenderOptions = useCalendarOptions();

    const RATIO = React.useMemo(() => {
        return 100 / MINUTES;
    }, [MINUTES]);

    const [calendarOptionsRemoved, offset] = React.useMemo(() => {
        const index = calenderOptions?.findIndex((option) => option === '06:00');
        return [calenderOptions?.slice(index), index * COLUMN_WIDTH];
    }, [calenderOptions]);

    const clientIds = React.useMemo(() => {
        const allClientIds = isEmpty(clientsArrived) ? [] : Object.keys(clientsArrived);
        const clients = allClientIds?.map((clientId) => {
            const booking = clientsArrived?.[clientId]?.[0];
            return {
                id: clientId,
                title: booking?.title
            };
        });

        const clientsSorted = sortBy(clients, ['title']);
        const matchedClients = clientsSorted?.filter((client) =>
            client?.title?.toLowerCase()?.includes(searchValue?.toLowerCase())
        );
        return matchedClients?.map((c) => c?.id);
    }, [clientsArrived, searchValue]);

    React.useEffect(() => {
        setTimeout(() => {
            waitForElementToDisplay(`scrollCurrentTime`, 10, (node) => {
                scrollIntoView(node, {
                    behavior: `instant`,
                    block: 'center'
                });
            });
        }, 0);
    }, []);

    return (
        <Grid container direction="column" className={classes.clientArrived}>
            <Grid item>
                <Grid
                    container
                    className={classes.header}
                    alignItems="center"
                    justifyContent="space-between"
                    direction="row"
                    wrap="nowrap"
                >
                    <Grid item>
                        <Typography variant="h5">
                            Serving clients ({isEmpty(clientsArrived) ? 0 : Object.keys(clientsArrived)?.length})
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" alignItems="center" spacing={2}>
                            <Grid item>
                                <Search
                                    placeholder={'Search client name'}
                                    value={searchValue}
                                    onChange={(e) => setSearchValue(e?.target?.value)}
                                    className={classes.search}
                                />
                            </Grid>
                            <Grid item>
                                <IconButton onClick={onClose} size="small">
                                    <CloseIcon style={{ color: theme.palette.common.white }} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item style={{ flex: 1 }}>
                <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                    <AutoSizer>
                        {({ height, width }) => {
                            return !clientIds?.length ? (
                                <Grid
                                    style={{ width, height }}
                                    direction="column"
                                    spacing={2}
                                    container
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Grid item>
                                        <Typography variant="h4">We found no serving client!</Typography>
                                    </Grid>
                                    <Grid item>
                                        <ClosedDateIllustratorSVG />
                                    </Grid>
                                </Grid>
                            ) : (
                                <TableRow
                                    cellRender={({ rowIndex, columnIndex, style }) => {
                                        const clientId = clientIds?.[rowIndex];
                                        const client = clientDetail?.[clientId];
                                        const bookingServices = clientsArrived?.[clientId];

                                        return (
                                            <CellRenderMemo
                                                bookingServices={bookingServices}
                                                client={client}
                                                columnIndex={columnIndex}
                                                style={style}
                                            />
                                        );
                                    }}
                                    width={width}
                                    columnIds={calendarOptionsRemoved}
                                    rowIds={clientIds}
                                    INDEX_COLUMN_WIDTH={INDEX_COLUMN_WIDTH}
                                    HEADER_HEIGHT={HEADER_HEIGHT}
                                    columnWidth={COLUMN_WIDTH}
                                    rowHeight={ROW_HEIGHT}
                                    headerRender={({ columnIndex, style, rowIndex }) => {
                                        const time = calendarOptionsRemoved?.[columnIndex - 1];

                                        return (
                                            <HeaderRenderMemo
                                                isMobile={isMobile}
                                                MINUTES={MINUTES}
                                                columnIndex={columnIndex}
                                                style={style}
                                                time={time}
                                            />
                                        );
                                    }}
                                    overlay={(props) => (
                                        <>
                                            <BookingsMemo
                                                clientDetail={clientDetail}
                                                clientIds={clientIds}
                                                isMobile={isMobile}
                                                clientsArrived={clientsArrived}
                                                RATIO={RATIO}
                                                MINUTES={MINUTES}
                                                serviceList={serviceList}
                                                staffList={staffList}
                                                categoryList={categoryList}
                                                offset={offset}
                                                {...props}
                                            />
                                            <CurrentTimeLineMemo offset={offset} RATIO={RATIO} />
                                            <FakeCurrentTimeOffsetMemo width={width} offset={offset} RATIO={RATIO} />
                                        </>
                                    )}
                                    height={height}
                                />
                            );
                        }}
                    </AutoSizer>
                </div>
            </Grid>
        </Grid>
    );
}

export default React.memo(ClientArrived);
