import * as types from '../types';
import {
    getServicesApi,
    createServiceApi,
    deleteServiceApi,
    updateServiceApi,
    uploadAvatarApi,
    reOrderServiceApi
} from 'services/service';
import { enqueueSnackbar } from 'notifier/actions';
import * as categoryActions from './category';

export function uploadAvatar({ ctId, sId, file, successCallback, errorCallback }) {
    return async function (dispatch) {
        try {
            const staff = await uploadAvatarApi({ file, serviceId: sId });
            // dispatch(_uploadAvatarActionSuccess({ avatar: staff?.avatar, serviceId }));
            dispatch(categoryActions.updateAvatarServiceToCategory({ sId, ctId, avatar: staff?.avatar }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[uploadAvatar] ${message}`,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

// function _uploadAvatarActionSuccess({ avatar, serviceId }) {
//     return {
//         type: types.UPDATE_SERVICE_AVATAR,
//         payload: {
//             avatar,
//             serviceId
//         }
//     };
// }

export function getServices({ errorCallback, successCallback }) {
    return async function (dispatch) {
        dispatch(_fetchServices());

        try {
            const response = await getServicesApi();
            dispatch(_fetchServicesSuccess({ services: response?.items || [] }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            // dispatch(
            //     enqueueSnackbar({
            //         message: `[getServices] ${message}`,
            //         type: 'info'
            //     })
            // );
            dispatch(_fetchServicesFailed());
            errorCallback && errorCallback(message);
        }
    };
}

export function uploadAvatarFailed({ error = '' }) {
    return async function (dispatch) {
        dispatch(
            enqueueSnackbar({
                message: `[uploadAvatarFailed]: ${error}`,
                type: 'info'
            })
        );
    };
}

function _fetchServices() {
    return {
        type: types.FETCH_SERVICES
    };
}

function _fetchServicesFailed() {
    return {
        type: types.FETCH_SERVICES_FAILED
    };
}

export function _fetchServicesSuccess({ services }) {
    return {
        type: types.FETCH_SERVICES_SUCCESS,
        payload: {
            services
        }
    };
}

export function createService({ ctId, body, errorCallback, successCallback }) {
    return async function (dispatch) {
        try {
            const service = await createServiceApi({ body });
            dispatch(categoryActions.addServiceToCategory({ ctId, service }));
            if (body?.isPrimarySearch) {
                dispatch(
                    categoryActions.getCategories({
                        relations: ['services', 'services.prices', 'services.serviceStaffs']
                    })
                );
            }

            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[createService] ${message}`,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function updateService({ ctId, sId, body, errorCallback, successCallback }) {
    return async function (dispatch) {
        try {
            const updatedService = await updateServiceApi({ id: sId, body });
            // dispatch(_updateServiceSuccess({ id: sId, service: updatedService }));
            dispatch(categoryActions.updateServiceToCategory({ ctId, sId, service: updatedService }));
            if (body?.isPrimarySearch) {
                dispatch(
                    categoryActions.getCategories({
                        relations: ['services', 'services.prices', 'services.serviceStaffs']
                    })
                );
            }
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[updateService] ${message}`,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

// function _updateServiceSuccess({ id, service }) {
//     return {
//         type: types.UPDATE_SERVICE_SUCCESS,
//         payload: {
//             service,
//             id
//         }
//     };
// }

export function deleteService({ ctId, sId, errorCallback, successCallback }) {
    return async function (dispatch) {
        // dispatch(_deleteService({ id }));
        try {
            const res = await deleteServiceApi({ id: sId });

            if (res?.isConflict) {
                // dispatch(_deleteServiceFailed({ id }));
                successCallback && successCallback(res?.bookings);
                return;
            }

            // dispatch(_deleteServiceSuccess(id));

            dispatch(categoryActions.deleteServiceToCategory({ ctId, sId }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `${message}`,
                    type: 'info'
                })
            );
            // dispatch(_deleteServiceFailed({ id }));
            errorCallback && errorCallback(message);
        }
    };
}

// function _deleteService({ id }) {
//     return {
//         type: types.DELETE_SERVICE,
//         payload: {
//             id
//         }
//     };
// }

// function _deleteServiceSuccess(id) {
//     return {
//         type: types.DELETE_SERVICE_SUCCESS,
//         payload: {
//             id
//         }
//     };
// }

// function _deleteServiceFailed({ id }) {
//     return {
//         type: types.DELETE_SERVICE_FAILED,
//         payload: {
//             id
//         }
//     };
// }

export function reOrderService({
    afterServiceId,
    dragId,
    beforeServiceId,
    dropIndex,
    errorCallback,
    successCallback,
    categoryId
}) {
    return async function (dispatch, getState) {
        const { service } = getState();
        const serviceList = service?.serviceList || [];

        const serviceListWithCategoryId = serviceList.filter((sv) => +sv?.categoryId === +categoryId);
        const otherServices = serviceList?.filter((sv) => +sv?.categoryId !== +categoryId);

        let body = {
            serviceId: +dragId,
            categoryId
        };

        if (afterServiceId) {
            body = {
                ...body,
                afterServiceId: +afterServiceId
            };
        } else if (beforeServiceId) {
            body = {
                ...body,
                beforeServiceId: +beforeServiceId
            };
        }

        const staffDrag = serviceListWithCategoryId?.find((sv) => +sv?.id === +dragId);
        const oldIndex = serviceListWithCategoryId?.findIndex((sv) => +sv?.id === +dragId);

        if (!staffDrag) {
            console.log('staffDrag is empty');
            return;
        }

        const isUp = oldIndex > dropIndex;

        const splitIndex = isUp ? dropIndex : dropIndex + 1;

        const firstPart = serviceListWithCategoryId?.slice(0, splitIndex)?.filter((i) => +i?.id !== +dragId);
        const secondPart = serviceListWithCategoryId?.slice(splitIndex)?.filter((i) => +i?.id !== +dragId);

        const newServiceList = [...firstPart, staffDrag, ...secondPart];
        const newListServicesWithReorderIndex = newServiceList?.map((sv, index) => ({
            ...sv,
            orderIndex: newServiceList?.length - index
        }));

        dispatch(reOrderServicesSuccess({ services: [...newListServicesWithReorderIndex, ...otherServices] }));

        try {
            await reOrderServiceApi(body);
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[reOrderService] ${message}`,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function reOrderServicesSuccess({ services }) {
    return {
        type: types.REORDER_SERVICE,
        payload: {
            services
        }
    };
}

export function fetchCategoriesAndServicesDone({ services, categories }) {
    return {
        type: types.FETCH_SERVICE_CATEGORIES_SUCCESS,
        payload: {
            services,
            categories
        }
    };
}
