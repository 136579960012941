import React from 'react';
import NumberFormat from 'react-number-format';
import { makeStyles } from '@material-ui/core/styles';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { addPlus } from 'utils/phone';
import { supportLanguages } from 'utils/country';
import { useHidePhone } from 'hooks/app';

const useStyles = makeStyles(() => ({
    root: {},
    input: {
        width: '100%',
        height: '100%',
        border: 'none',
        padding: 0,
        fontSize: '0.875rem',
        fontFamily: `"Public Sans", sans-serif`,
        fontStyle: 'normal',
        background: 'transparent',
        display: `table-cell`,
        verticalAlign: 'inherit',
        color: `rgba(0, 0, 0, 0.87)`,
        lineHeight: `1.57143`,
        fontWeight: 400,
        '&:focus': {
            outline: 'none'
        }
    }
}));

const phoneOptions = supportLanguages.map((phone) => ({
    ...phone,
    label: `+ ${phone?.phone}`,
    value: `${phone.label}`
}));

function PhoneFormat({ phone }) {
    const classes = useStyles();
    const hidePhone = useHidePhone();

    const [value, format] = React.useMemo(() => {
        if (!phone) return [];

        const numberFormat = addPlus(phone);
        const phoneNumber = parsePhoneNumberFromString(numberFormat);
        const countryCode = phoneNumber?.countryCallingCode;

        if (!countryCode) return [];
        const option = phoneOptions.find((opt) => opt?.phone?.includes(countryCode));

        if (!option) return [];

        return [phoneNumber.nationalNumber, hidePhone ? option?.hideFormat : option.format];
    }, [phone, hidePhone]);

    return <NumberFormat className={classes.input} value={value} format={format} disabled />;
}

export default React.memo(PhoneFormat);
