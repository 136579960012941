import * as types from './types';
import { getAnalyzeBookingApi, getStaffAnalyzeBookingApi, getStaffTipApi } from 'services/analyze';

export function fetchAnalyzeRange({ startDate, endDate, successCallback, errorCallback }) {
    return async function (dispatch) {
        try {
            const response = await getAnalyzeBookingApi({ startDate, endDate });
            dispatch(
                _fetchAnalyzeRangeSuccess({
                    top5Services: response?.top5Services,
                    top5Technicians: response?.top5Technicians,
                    incomeTicketsData: response?.incomeTicketsData
                })
            );

            successCallback && successCallback();
        } catch (error) {
            errorCallback && errorCallback();
        }
    };
}

function _fetchAnalyzeRangeSuccess({ top5Services, top5Technicians, incomeTicketsData }) {
    return {
        type: types.FETCH_ANALYZE_RANGE,
        payload: {
            top5Services,
            top5Technicians,
            incomeTicketsData
        }
    };
}

export function fetchStaffAnalyzeRange({ startDate, endDate, successCallback, errorCallback }) {
    return async function (dispatch) {
        try {
            const staffAnalyze = await getStaffAnalyzeBookingApi({ startDate, endDate });
            dispatch(_fetchStaffAnalyzeRangeSuccess({ staffAnalyze }));

            successCallback && successCallback();
        } catch (error) {
            errorCallback && errorCallback();
        }
    };
}

export function fetchStaffTipRange({ startDate, endDate, successCallback, errorCallback }) {
    return async function (dispatch) {
        try {
            const tip = await getStaffTipApi({ startDate, endDate });
            dispatch(_fetchStaffTipRangeSuccess({ tip }));
            successCallback && successCallback();
        } catch (error) {
            errorCallback && errorCallback();
        }
    };
}

function _fetchStaffAnalyzeRangeSuccess({ staffAnalyze }) {
    return {
        type: types.FETCH_STAFF_ANALYZE_RANGE,
        payload: {
            staffAnalyze
        }
    };
}

function _fetchStaffTipRangeSuccess({ tip }) {
    return {
        type: types.FETCH_STAFF_TIP_RANGE,
        payload: {
            tip
        }
    };
}
