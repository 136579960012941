import React from 'react';
import { makeStyles, useTheme, alpha } from '@material-ui/core/styles';
import { Grid, Typography, CircularProgress, Hidden, Button, IconButton, Chip } from '@material-ui/core';
import { useFormik } from 'formik';
import { string, object } from 'yup';
import InputBase from 'components/input/Form';
import { useTranslation } from 'react-i18next';
import { generateTempId, isTempId } from 'utils/uuid';
import { useStaffOptions, useStaffList, useStaffsServiceDuration } from 'hooks/staff';
import { useServiceOptions, useServiceList, useServicesMapping } from 'hooks/service';
import {
    getDatetimeFormat,
    convertTimeToFloat,
    convertFloatingToDateTime,
    convertDurationAndStartTimeToDate,
    mapTimeToTime12,
    getFriendlyShortTime,
    getFriendlyWeekDate,
    convertMinsToHrsMins,
    convertDurationToHrsMins
} from 'utils/timing';
import moment from 'moment-timezone';
import * as calendarActions from 'views/calender/actions';
import { useDispatch } from 'react-redux';
import sortBy from 'lodash/sortBy';
import orderBy from 'lodash/orderBy';
import PortalDatePicker from 'components/datepicker/PortalMaterialDatePicker';
import { addPlus, removePlus } from 'utils/phone';
// import SimpleBar from 'simplebar-react';
import DialogContent from 'components/dialog/Content';
import DialogTitle from 'components/dialog/Title';
import DialogActions from 'components/dialog/Action';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/AddCircle';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import RebookIcon from '@material-ui/icons/FlipToFront';
import InfoIcon from '@material-ui/icons/Info';
import DeleteIcon from '@material-ui/icons/Delete';
import StyledButton from 'components/button/Base';
import ShowCase from 'components/statistic/ShowCase';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {
    BOOKING_ACTIONS,
    BOOKING_STATES,
    getBookingStatusColor,
    LIMIT_BOOKING_HISTORY,
    BOOKING_SERVICE_STATES,
    DEFAULT_DURATION,
    BOOKING_SERVICE_STATUS_TO_LABEL,
    getBookingServiceStatusColor,
    MERCHANT_PERMISSIONS
} from 'const';
import PhoneInputWithClientSuggestion from '../../PhoneInputWithClientSuggestion';
import NotFoundSVG from 'assets/images/svg/NotFoundSVG';
import * as clientActions from 'views/clients/actions';
import { useClientBookingsStatisticByClientId, useClientBookingsByClientId, useClientByClientId } from 'hooks/client';
import { getFullName } from 'utils/naming';
import Spinner from 'components/spinner';
import hexToRgba from 'hex-to-rgba';
import { getBookingServiceStatus } from 'utils/app/booking';
import { useIsHavingInternalPermission, useIsHavingPermission } from 'hooks/auth';
import BookingService from './BookingService';
import { useMerchantInfo, useMerchantTime12 } from 'hooks/merchant';
import Tooltip from 'components/tooltip';
import { timeOptions } from 'data/time';
import { useSelectedDate } from 'hooks/calendar';

const FOOTER_HEIGHT = 80;
const MAX_HEIGHT = 632;

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 400,
        position: 'relative',
        borderRadius: 0,
        boxShadow: theme.shadows[1],
        overflowX: 'hidden',
        overflowY: 'hidden',
        height: MAX_HEIGHT,
        maxHeight: MAX_HEIGHT
    },
    closeIcon: {
        position: 'absolute',
        top: 10,
        right: 10,
        // width: 30,
        // height: 30,
        cursor: 'pointer'
    },

    addMore: {
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    content: {
        height: `calc(${MAX_HEIGHT}px - ${FOOTER_HEIGHT}px - 40px)`,
        overflowY: 'scroll',
        overflowX: 'hidden',
        [theme.breakpoints.down('xs')]: {
            height: `calc(100vh - ${FOOTER_HEIGHT}px - 40px)`,
            maxHeight: `calc(100vh - ${FOOTER_HEIGHT}px - 40px)`
        }
    },

    footer: {
        height: FOOTER_HEIGHT,
        borderTop: `1px solid ${theme.colors.border}`
    },
    wrapper: {
        position: 'relative'
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -6,
        marginLeft: -6
    },

    spacing: {
        paddingBottom: 12
    },
    padding: {
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    title: {
        height: 40,
        paddingLeft: 20,
        paddingRight: 20,
        fontSize: 24,
        color: '#41415C',
        fontWeight: 400,
        fontStyle: 'normal',
        textTransform: 'capitalize'
    },
    fixedBottom: {
        position: 'fixed',
        bottom: 0,
        width: '100%',
        left: 0,
        background: theme.palette.common.white
    },
    contentPadding: {
        paddingBottom: FOOTER_HEIGHT
    },
    icon: {
        width: 30,
        height: 30
    },
    close: {
        '&:hover svg': {
            color: theme.palette.primary.main
        }
    },
    cancel: {
        '&:hover svg': {
            color: theme.colors.red
        }
    },
    confirm: {
        '&:hover svg': {
            color: theme.colors.turquoise
        }
    },
    reschedule: {
        '&:hover svg': {
            color: theme.colors.brightSun
        }
    },
    done: {
        '&:hover svg': {
            color: theme.colors.green
        }
    },
    save: {
        '&:hover svg': {
            color: `#8FCCFF`
        }
    },
    item: {
        cursor: 'pointer',
        position: 'relative'
    },
    disabled: {
        opacity: 0.4,
        pointerEvents: 'none'
    },

    dlFlex: {
        display: 'flex'
    },
    bookingPhone: {
        position: 'relative',
        top: -14
    },
    ellipsisWrapper: {
        maxWidth: '100%',
        overflow: 'hidden'
    },
    ellipsis: {
        display: `inline-block`,
        maxWidth: `100%`,
        whiteSpace: `nowrap`,
        textOverflow: `ellipsis`,
        overflow: `hidden`
    },
    services: {
        width: 400,
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    client: {
        width: 500
    },
    scoreStatusPadding: {
        paddingTop: 14,
        paddingBottom: 14
    },
    scoreSection: {
        borderBottom: `1px solid ${theme.colors.border}`
    },
    overlay: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        background: hexToRgba(theme.palette.common.white, 0.7),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    expand: {
        flex: 1
    },
    whiteColor: {
        color: theme.palette.common.white
    },

    bookingServiceItem: {
        background: theme.colors.hover,
        borderRadius: theme.radius(3),
        marginBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    tableCell: {
        border: 'none',
        lineHeight: `1.5rem`,
        fontSize: `0.875rem`,
        fontFamily: `"Public Sans", sans-serif`,
        fontWeight: 600,
        display: `table-cell`,
        verticalAlign: 'inherit',
        textAlign: 'left',
        padding: '16px',
        borderBottom: 'none',
        color: `rgb(99, 115, 129)`,
        backgroundColor: theme.colors.hover,
        '&:first-of-type': {
            paddingLeft: '24px',
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
            boxShadow: `rgb(255 255 255) 8px 0px 0px inset`
        },
        '&:last-of-type': {
            paddingLeft: '24px',
            borderTopRightRadius: '8px',
            borderBottomRightRadius: '8px',
            boxShadow: `rgb(255 255 255) -8px 0px 0px inset`
        }
    },
    newClientWarning: {
        borderRadius: theme.radius(3),
        background: alpha(theme.palette.primary.main, 0.1),
        color: theme.palette.common.white,
        padding: theme.spacing(1),
        width: '100%',
        border: `1px solid ${theme.palette.primary.main}`,
        marginBottom: theme.spacing(2)
    }
}));

const BookingSchema = object({
    title: string().required('This filed is required'),
    phone: string(),
    note: string()
});

function getSelectedStartDate(booking) {
    if (!booking.startDate) {
        return new Date();
    }
    return moment(booking?.startDate);
}

const TABLE_COLUMNS = [
    {
        width: 220,
        label: 'Start Time',
        id: 'startTime'
    },
    {
        width: 350,
        label: 'Service',
        id: 'serviceAndStaffAndDuration'
    },
    {
        width: 50,
        label: `Price`,
        id: 'price'
    }
];

function UpdateCheckInBooking({ staffId, time, onClose, isEdit, booking, isDialog }) {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const merchant = useMerchantInfo();
    const selectedDate = useSelectedDate();
    const TIME_12 = useMerchantTime12();

    const isNoShow = React.useMemo(() => {
        return booking?.state === BOOKING_STATES.NO_SHOW;
    }, [booking]);

    const servicesMapping = useServicesMapping();

    const [startDate, setStartDate] = React.useState(isEdit ? getSelectedStartDate(booking) : selectedDate);
    const [clientId, setClientId] = React.useState(isEdit ? +booking?.clientId : null);
    const statistic = useClientBookingsStatisticByClientId(clientId);
    const bookings = useClientBookingsByClientId(clientId);
    const client = useClientByClientId(clientId);
    const [isLoading, setIsLoading] = React.useState(false);

    const serviceOptions = useServiceOptions();
    const staffOptions = useStaffOptions();
    const staffsServiceDuration = useStaffsServiceDuration();
    const dispatch = useDispatch();
    const isInternal = useIsHavingInternalPermission();
    const [clientNote, setClientNote] = React.useState(isEdit ? booking?.client?.note : '');
    const [isEditNote, setIsEditNote] = React.useState(false);
    const isHasStaffServiceDurationPermission = useIsHavingPermission(MERCHANT_PERMISSIONS.STAFF_SERVICE_DURATION);

    const services = useServiceList();
    const staffList = useStaffList();

    const bookingServices = React.useMemo(() => {
        const result = [];

        for (let booking of bookings) {
            const isFuture = booking?.isFuture;
            const bkServices = booking?.bookingServices || [];
            const isCancel = booking?.state === BOOKING_STATES.CLIENT_CANCEL;

            bkServices.forEach((service) => {
                const serviceDetail = services?.find((sv) => +sv?.id === +service?.serviceId);
                const staffDetail = staffList?.find((stf) => +stf?.id === +service?.staffId);
                const bookingServiceStatus = service?.status;
                const bkStatus =
                    isFuture && bookingServiceStatus !== 2
                        ? 8
                        : isCancel
                        ? BOOKING_SERVICE_STATES.CANCELED
                        : bookingServiceStatus;

                const serviceBody = {
                    startTime: (
                        <Typography variant="body2" display="inline">
                            {mapTimeToTime12(convertFloatingToDateTime(service?.startTime))},{' '}
                            {getFriendlyWeekDate(booking?.startDate)}{' '}
                            <Chip
                                size="small"
                                style={{
                                    background: getBookingStatusColor?.[bkStatus],
                                    color: theme.palette.common.white
                                }}
                                label={getBookingServiceStatus(bkStatus)}
                            />
                        </Typography>
                    ),
                    price: `${merchant?.currency}${service?.price}`,
                    serviceAndStaffAndDuration: `${convertMinsToHrsMins(service?.duration, 'h')} ${
                        serviceDetail?.title
                    } ${service?.love ? `❤️` : `with`} ${getFullName(staffDetail)}`
                };

                result.push(serviceBody);
            });
        }

        return result?.slice(0, LIMIT_BOOKING_HISTORY);
    }, [services, bookings, staffList, theme, merchant]);

    const options = React.useMemo(() => {
        return [
            {
                title: 'Bookings',
                amount: statistic?.allBookings,
                color: getBookingServiceStatusColor?.[BOOKING_SERVICE_STATUS_TO_LABEL?.[BOOKING_SERVICE_STATES.ARRIVED]]
            },
            {
                title: 'completed',
                amount: statistic?.completed,
                color:
                    getBookingServiceStatusColor?.[BOOKING_SERVICE_STATUS_TO_LABEL?.[BOOKING_SERVICE_STATES.COMPLETED]]
            },
            {
                title: 'Canceled',
                amount: statistic?.canceled,
                color:
                    getBookingServiceStatusColor?.[BOOKING_SERVICE_STATUS_TO_LABEL?.[BOOKING_SERVICE_STATES.CANCELED]]
            },
            {
                title: 'No Shows',
                amount: statistic?.noShow,
                color: getBookingServiceStatusColor?.[BOOKING_SERVICE_STATUS_TO_LABEL?.[BOOKING_SERVICE_STATES.NO_SHOW]]
            }
        ];
    }, [statistic]);

    const DEFAULT_SERVICE = React.useMemo(() => {
        const newServiceOptions = serviceOptions?.filter((service) => {
            const staffs = service?.serviceStaffs || [];
            const staffIds = staffs?.map((sv) => +sv?.staffId);
            return staffIds.includes(+staffId) && !service?.category?.deleted;
        });

        const firstService = newServiceOptions?.[0];

        const service = !firstService ? serviceOptions?.[0] : firstService;
        const serviceId = +service?.id;
        const defaultDuration = service?.prices?.[0]?.duration;

        const duration = isHasStaffServiceDurationPermission
            ? staffsServiceDuration?.[+staffId]?.[serviceId] || defaultDuration
            : defaultDuration;

        if (isHasStaffServiceDurationPermission) {
            return {
                staffId: isEdit ? +booking?.bookingServices?.[0]?.staffId : staffId,
                id: generateTempId(),
                startTime: time,
                serviceId,
                duration
            };
        } else {
            return {
                staffId: isEdit ? +booking?.bookingServices?.[0]?.staffId : staffId,
                id: generateTempId(),
                startTime: time,
                serviceId
            };
        }
    }, [staffId, isEdit, time, booking, staffsServiceDuration, serviceOptions, isHasStaffServiceDurationPermission]);

    const [serviceList, setServiceList] = React.useState(
        isEdit
            ? orderBy(
                  booking?.bookingServices?.map((bkSv) => {
                      if (!serviceOptions?.map((option) => +option?.id)?.includes(+bkSv?.serviceId)) {
                          bkSv.serviceId = serviceOptions?.[0]?.id;
                      }
                      return {
                          ...bkSv,
                          startTime: convertFloatingToDateTime(bkSv.startTime)
                      };
                  }),
                  ['startTime', 'asc']
              )
            : [DEFAULT_SERVICE]
    );

    React.useEffect(() => {
        if (clientId) {
            setIsLoading(true);
            dispatch(
                clientActions.fetchClientBookings({
                    clientId,
                    successCallback: () => {
                        setIsLoading(false);
                        console.log('fetch client bookings success');
                    },
                    errorCallback: () => {
                        setIsLoading(false);
                        console.log('fetch client bookings failed');
                    }
                })
            );
        }
    }, [clientId, dispatch]);

    const formik = useFormik({
        initialValues: {
            title: isEdit ? booking.title : '' || '',
            note: isEdit ? (!booking.note ? '' : booking.note) : '' || '',
            phone: isEdit ? addPlus(booking?.client?.phone) : ''
        },
        validationSchema: BookingSchema,
        onSubmit: (values, { setSubmitting, setStatus }) => {
            setStatus({ error: null });

            const oldLovedBookingServices = booking?.bookingServices?.filter((bkSv) => bkSv?.love);

            const bookingServices = serviceList
                .filter((bkSv) => bkSv?.serviceId && bkSv?.staffId)
                .map((bkSv) => {
                    if (bkSv.duration) {
                        let svBody = {
                            serviceId: +bkSv.serviceId,
                            staffId: +bkSv.staffId,
                            startTime: convertTimeToFloat(bkSv.startTime),
                            duration: bkSv.duration,
                            love: bkSv.love
                        };
                        if (bkSv?.id && !isTempId(bkSv?.id)) {
                            svBody.id = +bkSv?.id;
                            const oldLovedBkSv = oldLovedBookingServices?.find(
                                (loveBkSv) => +loveBkSv?.id === +bkSv?.id
                            );
                            if (oldLovedBkSv && oldLovedBkSv?.staffId !== bkSv?.staffId) {
                                svBody.love = false;
                            }

                            if (bkSv?.price) {
                                svBody = {
                                    ...svBody,
                                    price: +bkSv?.price
                                };
                            }
                        }
                        return svBody;
                    }

                    const defaultDuration =
                        serviceOptions.find((svOpt) => +bkSv.serviceId === +svOpt.id)?.prices?.[0].duration ||
                        DEFAULT_DURATION;

                    let svBody = {
                        serviceId: +bkSv.serviceId,
                        staffId: +bkSv.staffId,
                        startTime: convertTimeToFloat(bkSv.startTime),
                        love: bkSv.love,
                        duration: isHasStaffServiceDurationPermission
                            ? staffsServiceDuration?.[bkSv?.staffId]?.[bkSv?.serviceId] || defaultDuration
                            : defaultDuration
                    };
                    if (bkSv?.id && !isTempId(bkSv?.id)) {
                        svBody.id = +bkSv?.id;
                        const oldLovedBkSv = oldLovedBookingServices?.find((loveBkSv) => +loveBkSv?.id === +bkSv?.id);
                        if (oldLovedBkSv && oldLovedBkSv?.staffId !== bkSv?.staffId) {
                            svBody.love = false;
                        }

                        if (bkSv?.price) {
                            svBody = {
                                ...svBody,
                                price: +bkSv?.price
                            };
                        }
                    }
                    return svBody;
                });
            const startTimes = bookingServices?.map((sv) => sv?.startTime);
            const startTime = Math.min.apply(Math, startTimes);

            const editBody = {
                ...values,
                startDate: moment(`${getDatetimeFormat(startDate)} ${convertFloatingToDateTime(startTime)}`)
                    .utc()
                    .format(),
                phone: removePlus(values.phone),
                state: BOOKING_ACTIONS.CLIENT_CONFIRMED,
                startTime,
                bookingServices
            };

            setSubmitting(true);

            dispatch(
                calendarActions.updateCheckInBooking({
                    bookingId: booking.id,
                    body: editBody,
                    oldBooking: booking,
                    successCallback: () => {
                        console.log('update booking sucsces');
                        onClose && onClose();
                    },
                    errorCallback: () => {
                        console.log('update booking failed');
                        setSubmitting(false);
                    }
                })
            );

            setStatus({ error: null });
        }
    });

    const handleAddMoreService = React.useCallback(() => {
        const serviceListSorted = sortBy(serviceList, 'startTime');
        const lastItem = serviceListSorted?.[serviceListSorted?.length - 1];
        const serviceId = lastItem?.serviceId;
        const startTime = lastItem?.startTime || '00:00';
        const lastItemDuration = lastItem?.duration;
        const serviceDetail = serviceOptions.find((sv) => +sv?.id === +serviceId);
        const duration = lastItemDuration || serviceDetail?.prices?.[0]?.duration || DEFAULT_DURATION;
        const startTimeFloat = convertTimeToFloat(startTime);
        const nextStartTime = convertDurationAndStartTimeToDate(startTimeFloat, duration);

        setServiceList([...serviceList, { ...DEFAULT_SERVICE, id: generateTempId(), startTime: nextStartTime }]);
    }, [DEFAULT_SERVICE, serviceList, serviceOptions]);

    const handleRemoveService = React.useCallback(
        (id) => {
            setServiceList(serviceList.filter((sv) => sv.id !== id));
        },
        [serviceList]
    );

    const timeOptionsWithFormat = React.useMemo(() => {
        return TIME_12
            ? timeOptions.map((time) => {
                  return {
                      ...time,
                      label: mapTimeToTime12(time.value)
                  };
              })
            : timeOptions;
    }, [TIME_12]);

    const handleServiceChange = React.useCallback(
        ({ id, serviceId }) => {
            const newServiceList = serviceList.map((serviceOption) => {
                const staffId = serviceOption?.staffId;

                if (id === serviceOption?.id) {
                    if (+serviceOption.serviceId !== +serviceId) {
                        const duration =
                            serviceOptions.find((svOpt) => +serviceId === +svOpt.id)?.prices?.[0].duration ||
                            DEFAULT_DURATION;

                        serviceOption.duration = isHasStaffServiceDurationPermission
                            ? staffsServiceDuration?.[staffId]?.[serviceId] || duration
                            : duration;

                        serviceOption.price =
                            serviceOptions.find((svOpt) => +serviceId === +svOpt.id)?.prices?.[0].retailPrice || 0;
                    }

                    serviceOption.serviceId = serviceId;
                }
                return serviceOption;
            });
            setServiceList(newServiceList);
        },
        [serviceList, serviceOptions, staffsServiceDuration, isHasStaffServiceDurationPermission]
    );

    const handleStaffChange = React.useCallback(
        ({ id, staffId }) => {
            const newServiceList = serviceList.map((serviceOption) => {
                if (id === serviceOption.id) {
                    if (+serviceOption?.staffId !== +staffId && isHasStaffServiceDurationPermission) {
                        const serviceId = serviceOption?.serviceId;
                        const duration =
                            serviceOptions.find((svOpt) => +serviceId === +svOpt.id)?.prices?.[0].duration ||
                            DEFAULT_DURATION;

                        serviceOption.duration = isHasStaffServiceDurationPermission
                            ? staffsServiceDuration?.[staffId]?.[serviceId] || duration
                            : duration;
                    }

                    serviceOption.staffId = staffId;
                }
                return serviceOption;
            });

            setServiceList(newServiceList);
        },
        [serviceList, isHasStaffServiceDurationPermission, serviceOptions, staffsServiceDuration]
    );

    const handleLoveChange = React.useCallback(
        ({ id }) => {
            const newServiceList = serviceList.map((serviceOption) => {
                if (id === serviceOption.id) {
                    serviceOption.love = !serviceOption.love;
                }
                return serviceOption;
            });

            setServiceList(newServiceList);
        },
        [serviceList]
    );

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue } = formik;

    const isValid = React.useMemo(() => {
        return values?.title && serviceList?.filter((sv) => sv.serviceId && sv.staffId)?.length > 0;
    }, [values, serviceList]);

    const onDateChange = React.useCallback((date) => {
        setStartDate(date);
    }, []);

    const handleStartTimeChange = React.useCallback(
        ({ id, startTime }) => {
            const newServiceList = serviceList.map((serviceOption) => {
                if (id === serviceOption.id) {
                    serviceOption.startTime = startTime;
                }
                return serviceOption;
            });
            setServiceList(newServiceList);
        },
        [serviceList]
    );

    const handlePhoneChange = React.useCallback(
        (value) => {
            setFieldValue('phone', value);
        },
        [setFieldValue]
    );

    const handleClientChange = React.useCallback(
        (client) => {
            console.log('client', client);
            setFieldValue('phone', addPlus(client?.phone));
            setFieldValue('title', client?.fullName);
            setClientId(+client?.value);
            setClientNote(client?.note);
        },
        [setFieldValue]
    );

    const handleClientNoteChange = React.useCallback((e) => {
        setClientNote(e.target.value);
    }, []);

    const handleEditNote = React.useCallback(() => {
        if (!clientNote) return;
        const bodyUpdate = {
            note: clientNote,
            firstName: client?.client?.firstName
        };

        dispatch(
            clientActions.updateClient({
                clientId,
                body: bodyUpdate,
                successCallback: () => {
                    setIsEditNote(false);
                },
                errorCallback: (error) => {
                    setIsEditNote(false);
                }
            })
        );
    }, [clientId, clientNote, dispatch, client]);

    const totalBookingPrice = React.useMemo(() => {
        return serviceList?.reduce((total, bkSv) => {
            return total + (+bkSv?.price || 0) || +servicesMapping?.[bkSv?.serviceId]?.prices?.[0]?.retailPrice || 0;
        }, 0);
    }, [serviceList, servicesMapping]);

    const totalBookingDuration = React.useMemo(() => {
        return serviceList?.reduce((total, bkSv) => {
            return total + (+bkSv?.duration || 0) || +servicesMapping?.[bkSv?.serviceId]?.prices?.[0]?.duration || 0;
        }, 0);
    }, [serviceList, servicesMapping]);

    const cloneBkSv = React.useCallback(
        (cloneBkSv) => {
            const serviceId = cloneBkSv?.serviceId;
            const startTime = cloneBkSv?.startTime || timeOptionsWithFormat?.[0]?.value;
            const lastBkSvDuration = cloneBkSv?.duration;
            const serviceDetail = servicesMapping?.[serviceId];
            const duration = lastBkSvDuration || serviceDetail?.prices?.[0]?.duration || DEFAULT_DURATION;
            const startTimeFloat = convertTimeToFloat(startTime);
            const nextStartTime = convertDurationAndStartTimeToDate(startTimeFloat, duration);

            setServiceList([...serviceList, { ...cloneBkSv, id: generateTempId(), startTime: nextStartTime }]);
        },
        [serviceList, servicesMapping, timeOptionsWithFormat]
    );

    return (
        <form onSubmit={handleSubmit}>
            {!isDialog && (
                <IconButton size="small" onClick={onClose} className={classes.closeIcon}>
                    <CloseIcon style={{ color: theme.palette.common.white }} />
                </IconButton>
            )}
            <DialogTitle>
                <Typography className={classes.whiteColor} variant="h5">
                    Modify check-in booking
                </Typography>
            </DialogTitle>
            <DialogContent className={`${classes.content} ${classes.padding}`}>
                <>
                    {client && client?.client?.isNewClient && (
                        <Grid container direction="row" alignItems="center" className={classes.newClientWarning}>
                            <Grid item style={{ display: 'flex', marginRight: theme.spacing(1) }}>
                                <InfoIcon color="primary" />
                            </Grid>
                            <Grid item>
                                <Typography variant="body1">This is new client. Please take good care!</Typography>
                            </Grid>
                        </Grid>
                    )}
                    <Grid
                        container
                        direction="row"
                        style={{
                            paddingLeft: isDialog ? 20 : '',
                            paddingRight: isDialog ? 20 : '',
                            height: '100%'
                        }}
                        spacing={2}
                        wrap="nowrap"
                    >
                        <Grid item className={classes.services}>
                            <Grid container direction="column" wrap="nowrap">
                                <Hidden smDown>
                                    <Grid item className={classes.spacing}>
                                        <InputBase
                                            fullWidth
                                            className={`${classes.input}`}
                                            label={t('client_name')}
                                            placeholder={t('booking_title_placeholder')}
                                            name="title"
                                            value={values.title || ''}
                                            disabled={true}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.title && touched.title}
                                            errorText={errors.title}
                                        />
                                    </Grid>
                                </Hidden>

                                <Hidden smUp>
                                    <Grid item className={classes.spacing}>
                                        <Grid container spacing={2} direction="row" alignItems="flex-end">
                                            <Grid item xs={5}>
                                                <InputBase
                                                    fullWidth
                                                    className={`${classes.input}`}
                                                    label={t('client_name')}
                                                    placeholder={t('booking_title_placeholder')}
                                                    name="title"
                                                    value={values.title || ''}
                                                    disabled={true}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={errors.title && touched.title}
                                                    errorText={errors.title}
                                                />
                                            </Grid>
                                            <Grid item xs={7} className={classes.bookingPhone}>
                                                <PhoneInputWithClientSuggestion
                                                    label={t('phone_number')}
                                                    placeholder={`(471) 213-1312`}
                                                    disabled={true}
                                                    onChange={handlePhoneChange}
                                                    defaultValue={values.phone}
                                                    onClientChange={handleClientChange}
                                                    autoFocus={!isEdit}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Hidden>

                                <Grid item className={classes.spacing}>
                                    <PortalDatePicker
                                        onChange={onDateChange}
                                        defaultValue={startDate}
                                        label={t(`start_date`)}
                                        disabled={true}
                                    />
                                </Grid>

                                <Grid item className={classes.spacing}>
                                    <Grid container direction="column" spacing={1}>
                                        <Grid item>
                                            <Typography variant="body1">Services</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Grid
                                                className={classes.bookingServices}
                                                container
                                                direction="column"
                                                wrap="nowrap"
                                            >
                                                {serviceList.map((serviceOption, index) => {
                                                    const {
                                                        serviceId,
                                                        staffId,
                                                        id,
                                                        startTime,
                                                        love,
                                                        price,
                                                        duration,
                                                        status
                                                    } = serviceOption;

                                                    return (
                                                        <Grid item key={id} className={classes.bookingServiceItem}>
                                                            <Grid container direction="column" spacing={1}>
                                                                <Grid item>
                                                                    <Grid
                                                                        container
                                                                        alignItems="center"
                                                                        direction="row"
                                                                        justifyContent="space-between"
                                                                    >
                                                                        <Grid
                                                                            item
                                                                            style={{
                                                                                width: 20,
                                                                                height: 20,
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                justifyContent: 'center',
                                                                                background: theme.palette.primary.main,
                                                                                color: theme.palette.common.white,
                                                                                borderRadius: '50%'
                                                                            }}
                                                                        >
                                                                            {index + 1}
                                                                        </Grid>

                                                                        <Grid item>
                                                                            <Grid container direction="row" spacing={1}>
                                                                                <Grid item>
                                                                                    <Tooltip
                                                                                        placement="top"
                                                                                        title="Clone booking service"
                                                                                    >
                                                                                        <IconButton
                                                                                            onClick={() =>
                                                                                                cloneBkSv(serviceOption)
                                                                                            }
                                                                                            size="small"
                                                                                            color="primary"
                                                                                        >
                                                                                            <RebookIcon fontSize="small" />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                </Grid>
                                                                                {serviceList?.length > 1 && (
                                                                                    <Grid item>
                                                                                        <IconButton
                                                                                            size="small"
                                                                                            onClick={() =>
                                                                                                handleRemoveService(id)
                                                                                            }
                                                                                        >
                                                                                            <DeleteIcon
                                                                                                fontSize="small"
                                                                                                color="secondary"
                                                                                            />
                                                                                        </IconButton>
                                                                                    </Grid>
                                                                                )}
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item>
                                                                    <BookingService
                                                                        price={price}
                                                                        duration={duration}
                                                                        serviceIndex={index}
                                                                        id={id}
                                                                        startTime={startTime}
                                                                        serviceId={serviceId}
                                                                        staffId={staffId}
                                                                        handleStaffChange={handleStaffChange}
                                                                        handleServiceChange={handleServiceChange}
                                                                        onLoveChange={handleLoveChange}
                                                                        serviceOptions={serviceOptions}
                                                                        staffOptions={staffOptions}
                                                                        handleRemoveService={handleRemoveService}
                                                                        handleStartTimeChange={handleStartTimeChange}
                                                                        isCanClear={serviceList.length > 1}
                                                                        isNoShow={isNoShow}
                                                                        love={love}
                                                                        isInternal={isInternal}
                                                                        isHasStaffServiceDurationPermission={
                                                                            isHasStaffServiceDurationPermission
                                                                        }
                                                                        status={status}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                })}
                                                {!isNoShow && (
                                                    <Grid item>
                                                        <Button
                                                            size="small"
                                                            onClick={handleAddMoreService}
                                                            style={{
                                                                textDecoration: 'underline',
                                                                textTransform: 'inherit'
                                                            }}
                                                            color="primary"
                                                            startIcon={<AddIcon />}
                                                        >
                                                            {t(`add_another_service`)}
                                                        </Button>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item className={classes.spacing}>
                                    <Grid container direction="row" spacing={2}>
                                        <Grid item>
                                            <Typography variant="body2">
                                                Total price:{' '}
                                                <strong style={{ color: theme.colors.blue }}>
                                                    {merchant.currency}
                                                    {totalBookingPrice}
                                                </strong>
                                            </Typography>
                                        </Grid>

                                        <Grid item>
                                            <Typography variant="body2">
                                                Duration:{' '}
                                                <strong style={{ color: theme.colors.blue }}>
                                                    {convertDurationToHrsMins(totalBookingDuration)}
                                                </strong>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <InputBase
                                        fullWidth
                                        className={`${classes.input}`}
                                        label={'Note'}
                                        placeholder={t('booking_note_placeholder')}
                                        name="note"
                                        value={values.note || ''}
                                        disabled={isSubmitting}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={errors.note && touched.note}
                                        errorText={errors.note}
                                        multiline
                                        maxRows={3}
                                    />
                                </Grid>
                                {isEdit && (
                                    <Grid item>
                                        <Typography
                                            variant="body2"
                                            style={{
                                                fontSize: 13,
                                                padding: '2px 6px',
                                                background: theme.colors.template,
                                                borderRadius: theme.radius(5),
                                                color: theme.palette.common.white
                                            }}
                                        >
                                            Appointment was made on:{' '}
                                            <strong color="primary">{getFriendlyShortTime(booking?.createdAt)}</strong>
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>

                        <Hidden smDown>
                            <Grid item className={classes.client}>
                                <Grid container direction="column">
                                    <Grid item>
                                        <PhoneInputWithClientSuggestion
                                            label={t('phone_number')}
                                            placeholder={`(471) 213-1312`}
                                            disabled={true}
                                            onChange={handlePhoneChange}
                                            defaultValue={values.phone}
                                            onClientChange={handleClientChange}
                                            autoFocus={!isEdit}
                                        />
                                    </Grid>
                                    {!client && (
                                        <Grid item style={{ marginTop: 100 }}>
                                            <Grid
                                                container
                                                alignItems="center"
                                                justifyContent="center"
                                                direction="column"
                                                spacing={2}
                                            >
                                                <Grid item>
                                                    <Typography variant="body2">No client found</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <NotFoundSVG width={80} height={80} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                    {client && (
                                        <Grid
                                            container
                                            style={{ position: 'relative', marginTop: theme.spacing(3) }}
                                            direction="column"
                                        >
                                            {!client?.client?.isSystem && (
                                                <Grid item style={{ marginBottom: theme.spacing(2) }}>
                                                    <Grid container direction="row" alignItems="flex-end" spacing={2}>
                                                        <Grid item className={classes.expand}>
                                                            <InputBase
                                                                multiline
                                                                maxRows={3}
                                                                label={
                                                                    <Grid
                                                                        container
                                                                        direction="row"
                                                                        alignItems="center"
                                                                        spacing={1}
                                                                    >
                                                                        <Grid item>
                                                                            <Typography variant="body1">
                                                                                Notes
                                                                            </Typography>
                                                                        </Grid>
                                                                        <StyledButton
                                                                            size="small"
                                                                            color="primary"
                                                                            onClick={
                                                                                !isEditNote
                                                                                    ? () => setIsEditNote(true)
                                                                                    : handleEditNote
                                                                            }
                                                                            variant="contained"
                                                                            startIcon={
                                                                                !isEditNote ? (
                                                                                    <EditIcon />
                                                                                ) : (
                                                                                    <SaveIcon />
                                                                                )
                                                                            }
                                                                        >
                                                                            {!isEditNote ? 'Edit' : 'Save'}
                                                                        </StyledButton>
                                                                    </Grid>
                                                                }
                                                                name="note"
                                                                value={clientNote || ''}
                                                                disabled={!isEditNote}
                                                                onChange={handleClientNoteChange}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )}

                                            <Grid item>
                                                <Grid container direction="column" spacing={1}>
                                                    <Grid item>
                                                        <Grid container direction="row" spacing={3} wrap="nowrap">
                                                            {options.map((option) => (
                                                                <Grid xs={3} key={option?.title} item>
                                                                    <ShowCase {...option} />
                                                                </Grid>
                                                            ))}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Paper
                                                    elevation={0}
                                                    style={{
                                                        pt: 2,
                                                        width: '100%',
                                                        overflow: 'hidden',
                                                        background: theme.palette.common.white,
                                                        color: `rgb(33, 43, 54)`,
                                                        backgroundImage: 'none',
                                                        position: 'relative',
                                                        zIndex: 0,
                                                        display: 'flex',
                                                        flexDirection: 'column'
                                                    }}
                                                >
                                                    <Grid item>
                                                        <TableContainer>
                                                            <Table stickyHeader aria-label="sticky table">
                                                                <TableHead>
                                                                    <TableRow sx={{}}>
                                                                        {TABLE_COLUMNS?.map((column) => (
                                                                            <TableCell
                                                                                key={column.id}
                                                                                align={column.align}
                                                                                className={classes.tableCell}
                                                                                style={{ minWidth: column.minWidth }}
                                                                            >
                                                                                {column.label}
                                                                            </TableCell>
                                                                        ))}
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {bookingServices?.length === 0 && (
                                                                        <TableRow sx={{ textAlign: 'center' }}>
                                                                            <TableCell style={{ border: 'none' }}>
                                                                                No histories
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )}
                                                                    {bookingServices?.map((row) => {
                                                                        return (
                                                                            <TableRow
                                                                                sx={{
                                                                                    '&:hover': {
                                                                                        cursor: 'pointer',
                                                                                        background: `${theme.colors.hover} !important`
                                                                                    }
                                                                                }}
                                                                                role="checkbox"
                                                                                tabIndex={-1}
                                                                                key={row.id}
                                                                            >
                                                                                {TABLE_COLUMNS.map((column) => {
                                                                                    const value = row?.[column.id];

                                                                                    return (
                                                                                        <TableCell
                                                                                            style={{ border: 'none' }}
                                                                                            key={column.id}
                                                                                            align={column.align}
                                                                                        >
                                                                                            {column.format
                                                                                                ? column.format({
                                                                                                      rowData: row,
                                                                                                      value
                                                                                                  })
                                                                                                : value}
                                                                                        </TableCell>
                                                                                    );
                                                                                })}
                                                                            </TableRow>
                                                                        );
                                                                    })}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    </Grid>
                                                </Paper>
                                            </Grid>
                                            {isLoading && (
                                                <Grid className={classes.overlay}>
                                                    <Spinner size={40} thick={4} />
                                                </Grid>
                                            )}
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Hidden>
                    </Grid>
                </>
            </DialogContent>

            <DialogActions>
                <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    justifyContent={'flex-end'}
                    direction={'row'}
                    wrap="nowrap"
                >
                    <Grid item className={classes.wrapper}>
                        <StyledButton
                            disabled={isSubmitting || !isValid}
                            type="submit"
                            variant="contained"
                            color="primary"
                            startIcon={<SaveIcon />}
                        >
                            {t(`save`)}
                        </StyledButton>

                        {isSubmitting && <CircularProgress size={12} className={classes.buttonProgress} />}
                    </Grid>
                </Grid>
            </DialogActions>
        </form>
    );
}

export default React.memo(UpdateCheckInBooking);
