import React from 'react';

function ServicesSVG({ color = '#41415C', ...rest }) {
    return (
        <svg {...rest} width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.5">
                <path
                    d="M9.81641 7.68357C9.57423 7.44138 9.17579 7.43747 8.92969 7.68357L6.875 9.74216L6.06641 8.93357C5.82422 8.69138 5.42578 8.69138 5.18359 8.93357C4.94141 9.17575 4.94141 9.57419 5.18359 9.81638L6.4336 11.0664C6.55469 11.1875 6.71485 11.25 6.875 11.25C7.03516 11.25 7.19532 11.1875 7.31641 11.0664L9.81641 8.56638C10.0625 8.32419 10.0625 7.92575 9.81641 7.68357Z"
                    fill={color}
                />
                <path
                    d="M9.81641 12.6836C9.57423 12.4414 9.17579 12.4375 8.92969 12.6836L6.875 14.7422L6.06641 13.9336C5.82422 13.6914 5.42578 13.6914 5.18359 13.9336C4.94141 14.1758 4.94141 14.5742 5.18359 14.8164L6.4336 16.0664C6.55469 16.1875 6.71485 16.25 6.875 16.25C7.03516 16.25 7.19532 16.1875 7.31641 16.0664L9.81641 13.5664C10.0625 13.3242 10.0625 12.9258 9.81641 12.6836Z"
                    fill={color}
                />
                <path
                    d="M14.375 8.75H11.875C11.5312 8.75 11.25 9.03125 11.25 9.375C11.25 9.71875 11.5312 10 11.875 10H14.375C14.7188 10 15 9.71875 15 9.375C15 9.03125 14.7188 8.75 14.375 8.75Z"
                    fill={color}
                />
                <path
                    d="M14.375 13.75H11.875C11.5312 13.75 11.25 14.0312 11.25 14.375C11.25 14.7188 11.5312 15 11.875 15H14.375C14.7188 15 15 14.7188 15 14.375C15 14.0312 14.7188 13.75 14.375 13.75Z"
                    fill={color}
                />
                <path
                    d="M16.25 2.5H13.75V1.875C13.75 1.53125 13.4688 1.25 13.125 1.25H11.7695C11.5117 0.523438 10.8164 0 10 0C9.18359 0 8.48828 0.523438 8.23047 1.25H6.875C6.53125 1.25 6.25 1.53125 6.25 1.875V2.5H3.75C3.0625 2.5 2.5 3.0625 2.5 3.75V18.75C2.5 19.4375 3.0625 20 3.75 20H16.25C16.9375 20 17.5 19.4375 17.5 18.75V3.75C17.5 3.0625 16.9375 2.5 16.25 2.5ZM7.5 2.5H8.75C9.09375 2.5 9.375 2.21875 9.375 1.875C9.375 1.53125 9.65625 1.25 10 1.25C10.3438 1.25 10.625 1.53125 10.625 1.875C10.625 2.21875 10.9062 2.5 11.25 2.5H12.5V3.75H7.5V2.5ZM16.25 18.75H3.75V3.75H6.25V4.375C6.25 4.71875 6.53125 5 6.875 5H13.125C13.4688 5 13.75 4.71875 13.75 4.375V3.75H16.25V18.75Z"
                    fill={color}
                />
            </g>
        </svg>
    );
}

export default React.memo(ServicesSVG);
