import { useSelector } from 'react-redux';

export function useMerchants() {
    return useSelector((state) => state.setting?.merchants);
}

export function useAnalyze() {
    return useSelector((state) => state?.setting?.analyze);
}

export function useMerchantAnalyze(merchantId) {
    return useSelector((state) => state?.setting?.analyze?.[merchantId]);
}

export function useGroups() {
    return useSelector((state) => state.setting?.groups);
}

export function useBooking(id) {
    return useSelector((state) => state.setting?.logs?.[id]);
}
