import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, InputBase, Dialog, Button, Chip, IconButton } from '@material-ui/core';
import LayoutTabContent from 'views/layout/LayoutTabContent';
import { useDispatch } from 'react-redux';
import { OPERATOR } from 'const/condition';
import { columnTypes } from 'const/columnTypes';
import { Print as PrintIcon, Delete as DeleteIcon } from '@material-ui/icons';
import ContentLayout from '../layout/ContentLayout';
import CloseSVG from 'assets/images/svg/CloseSVG';
import Table from 'components/table/NewTable';
import { useTranslation } from 'react-i18next';
import { PADDING_LIST_ITEM_LEFT_RIGHT } from 'const/style';
import * as checkoutActions from './actions';
import { useCheckoutStatistic, useCheckouts } from 'hooks/checkout';
import { getFriendlyShortTime, getCurrentDate, getAppendMerchantTimezoneToDate, getDatetimeFormat } from 'utils/timing';
import useMediaQueries from 'hooks/useMediaQueries';
import DateRange from 'components/dateRange/SingleDate';
import VisaSVG from 'assets/images/svg/VisaSVG';
import MasterCard from 'assets/images/svg/MasterCard';
import DebitSVG from 'assets/images/svg/DebitSVG';
import CashSVG from 'assets/images/svg/CashSVG';
import PaymentCancelSVG from 'assets/images/svg/PaymentCancelSVG';
import DiscountSVG from 'assets/images/svg/DiscountSVG';
import GiftPaymentSVG from 'assets/images/svg/GiftPaymentSVG';
import { CONFIRM_TYPES, PAYMENT_METHOD_TYPES, CHECKOUT_TYPES } from 'const';
import Spinner from 'components/spinner';
import hexToRgba from 'hex-to-rgba';
import { getFullName } from 'utils/naming';
import ConfirmDialog from 'components/dialog/Confirm';
import RequirePasswordForm from './RequirePasswordForm';
import { checkPasswordApi } from 'services/merchant';
import IOSSwitch from 'components/switch/IOS';

const useStyles = makeStyles((theme) => ({
    fullHeight: {
        height: '100%'
    },
    root: {
        overflowY: 'auto',
        height: '100%',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            maxHeight: `-webkit-fill-available`,
            marginTop: 0
        }
    },
    wrapContent: {
        height: '100%',
        overflowY: 'hidden',
        backgroundColor: theme.palette.common.white,
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            marginBottom: 0
        },
        position: 'relative'
    },

    header: {
        padding: theme.spacing(2)
    },

    wrapInput: {
        position: 'relative'
    },

    customInput: {
        'label + &': {
            color: theme.colors.secondaryText
        },
        backgroundColor: theme.palette.common.white,
        paddingLeft: PADDING_LIST_ITEM_LEFT_RIGHT,
        paddingRight: 22,
        borderRadius: 3,
        height: 35,
        fontSize: 14,
        fontWeight: 'normal',
        color: theme.colors.primaryText,
        width: '100%'
    },
    clearIcon: {
        position: 'absolute',
        cursor: 'pointer',
        right: 5,
        top: 7,
        zIndex: 9000
    },
    btnSubmit: {
        position: 'relative'
    },
    buttonProgress: {
        color: theme.palette.common.white,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    phones: {
        maxHeight: 45,
        overflowY: 'auto'
    },
    statistic: {
        boxShadow: theme.shadows[1],
        padding: theme.spacing(3),
        borderRadius: 4,
        background: theme.palette.common.white,
        boxSizing: 'border-box'
    },
    paymentMethod: {
        boxShadow: theme.shadows[1],
        padding: theme.spacing(1),
        borderRadius: 4,
        background: theme.colors.background,
        boxSizing: 'border-box',
        '&:not(:last-child)': {
            marginRight: theme.spacing(1)
        }
    },
    spinnerWrapper: {
        width: '100%',
        height: '100%',
        display: 'flex',
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        top: 0,
        left: 0,
        background: hexToRgba(theme.palette.common.white, 0.5),
        zIndex: 2
    },
    tag: {
        background: theme.colors.template
    },
    gift: {
        background: theme.colors.pink
    }
}));

const Actions = ({ value, rowData, t, isMobile, onDelete }) => {
    const dispatch = useDispatch();
    const [isPrinting, setIsPrinting] = React.useState(false);

    const isVoid = rowData?.void;
    const checkoutId = +rowData?.id;

    const stopPropagation = React.useCallback((e) => {
        e && e.stopPropagation();
        e && e.preventDefault();
    }, []);

    const handlePrint = React.useCallback(
        (e) => {
            setIsPrinting(true);
            stopPropagation(e);
            dispatch(
                checkoutActions.printCheckout({
                    checkoutId,
                    successCallback: () => {
                        setIsPrinting(false);
                    },
                    errorCallback: () => {
                        setIsPrinting(false);
                    }
                })
            );
        },
        [stopPropagation, dispatch, checkoutId]
    );

    if (isVoid) return null;

    return (
        <Grid container spacing={2} wrap="nowrap" justifyContent="flex-end" alignItems="center" direction="row">
            <Grid item>
                <IconButton color="secondary" onClick={() => onDelete(rowData)}>
                    <DeleteIcon />
                </IconButton>
            </Grid>
            <Grid item>
                <IconButton disabled={isPrinting} color="primary" onClick={handlePrint}>
                    <PrintIcon />
                </IconButton>
            </Grid>
        </Grid>
    );
};

const ActionsMemo = React.memo(Actions);

const initialValueFilter = {
    phone: {
        operator: OPERATOR.contains,
        value: '',
        type: columnTypes.TEXT
    }
};

const HIDDEN_CHARACTERS = '****';

function Dashboard() {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [filter, setFilter] = React.useState(initialValueFilter);
    const [statisticLoading, setStatisticLoading] = React.useState(false);
    const [isFetching, setIsFetching] = React.useState(false);

    const checkouts = useCheckouts();
    const statistic = useCheckoutStatistic();
    const { isMobile } = useMediaQueries();
    const [selectedDate, setSelectedDate] = React.useState(getCurrentDate());
    const [isOpenDeleteConfirm, setIsOpenDeleteConfirm] = React.useState(false);
    const [deleteCheckout, setDeleteCheckout] = React.useState(null);
    const [isVoiding, setIsVoiding] = React.useState(false);
    const [isOpenPrintLongBill, setIsOpenPrintLongBill] = React.useState(false);

    const [password, setPassword] = React.useState('');
    const [error, setError] = React.useState('');
    const [isLoading, setIsLoading] = React.useState('');
    const [isShowMoney, setIsShowMoney] = React.useState(false);
    const [isOpenShowMoney, setIsOpenShowMoney] = React.useState(false);

    const checkoutsFiltered = React.useMemo(() => {
        return checkouts?.filter((co) => {
            const clientName = getFullName(co?.client) || '';
            return clientName?.toLowerCase()?.includes((filter?.clientName?.value || '')?.toLowerCase());
        });
    }, [checkouts, filter]);

    const handleFilterChange = React.useCallback(
        (id, value) => {
            setFilter({
                ...filter,
                [id]: {
                    ...filter?.[id],
                    value
                }
            });
        },
        [filter]
    );

    const handleFetchData = React.useCallback(
        (date) => {
            setStatisticLoading(true);
            setIsFetching(true);
            dispatch(
                checkoutActions.fetchCheckouts({
                    query: {
                        startDate: getAppendMerchantTimezoneToDate(getDatetimeFormat(date))
                    },
                    errorCallback: () => {
                        console.log('fetch checkout failed');
                        setStatisticLoading(false);
                        setIsFetching(false);
                    },
                    successCallback: () => {
                        setStatisticLoading(false);
                        setIsFetching(false);
                        console.log('fetch checkout success');
                    }
                })
            );
        },
        [dispatch]
    );

    React.useEffect(() => {
        if (selectedDate) {
            handleFetchData(selectedDate);
        }
    }, [selectedDate, dispatch, handleFetchData]);

    const openDelete = React.useCallback((rowData) => {
        setDeleteCheckout(rowData);
        setIsOpenDeleteConfirm(true);
    }, []);

    const handleClose = React.useCallback(() => {
        setDeleteCheckout(null);
        setIsOpenDeleteConfirm(false);
    }, []);

    const handleVoidPayment = React.useCallback(() => {
        setIsVoiding(true);
        dispatch(
            checkoutActions.voidCheckout({
                checkoutId: +deleteCheckout?.id,
                successCallback: () => {
                    console.log('void success');
                    setIsVoiding(false);
                    handleFetchData(selectedDate);
                    handleClose();
                },
                errorCallback: () => {
                    console.log('void failed');
                    setIsVoiding(false);
                }
            })
        );
    }, [deleteCheckout, dispatch, handleClose, handleFetchData, selectedDate]);

    const handleClearInput = React.useCallback(
        (id) => {
            const newFilter = {
                ...filter,
                [id]: {
                    ...filter?.[id],
                    value: ''
                }
            };
            setFilter(newFilter);
        },
        [filter]
    );

    const filterHeaderRenderer = React.useCallback(
        ({ column }) => {
            const filterValue = filter?.[column?.id]?.value;
            return (
                <div className={classes.wrapInput}>
                    <InputBase
                        className={classes.customInput}
                        placeholder={'Type to filter'}
                        value={filterValue}
                        onChange={(e) => {
                            if (column?.id === 'noShowCount') e.target.value = e.target.value.replace(/\D/, '');
                            handleFilterChange(column?.id, e.target.value);
                        }}
                    />
                    {filterValue && (
                        <div className={classes.clearIcon} onClick={() => handleClearInput(column?.id)}>
                            <CloseSVG color="#8181A5" size="small" />
                        </div>
                    )}
                </div>
            );
        },
        [classes, filter, handleClearInput, handleFilterChange]
    );

    const getPaymentIconByType = React.useCallback((paymentType) => {
        switch (paymentType) {
            case PAYMENT_METHOD_TYPES.CASH:
                return <CashSVG width={20} height={20} />;
            case PAYMENT_METHOD_TYPES.MASTERCARD:
                return <MasterCard width={20} height={20} />;
            case PAYMENT_METHOD_TYPES.VISA:
                return <VisaSVG width={20} height={20} />;
            case PAYMENT_METHOD_TYPES.DEBIT:
                return <DebitSVG width={20} height={20} />;
            case PAYMENT_METHOD_TYPES.GIFT:
                return <GiftPaymentSVG width={20} height={20} />;
            case PAYMENT_METHOD_TYPES.GIFT_TAX:
                return <GiftPaymentSVG width={20} height={20} />;
            default:
                return null;
        }
    }, []);

    const handleShowMoneyChange = React.useCallback((e) => {
        if (e?.target?.checked) {
            setIsOpenShowMoney(true);
        } else {
            setIsShowMoney(false);
        }
    }, []);

    const columns = [
        // {
        //     id: 'serialNumber',
        //     label: 'Serial Number',
        //     width: 100,
        //     format: (props) => (
        //         <Typography variant="body2">{addZeroToSerialNumber(props?.rowData?.serialNumber)}</Typography>
        //     )
        // },
        {
            id: 'clientName',
            label: 'Client Name',
            width: 150,
            format: (props) => (
                <Typography variant="body2">{getFullName(props?.rowData?.client) || 'Walk in'}</Typography>
            ),
            searchable: true
        },
        {
            id: 'checkoutMethods',
            label: 'Checkout methods',
            width: 150,
            format: (props) => (
                <Grid container direction="row" alignItems="center">
                    {props?.rowData?.checkoutMethods?.map((checkoutMethod, index) => (
                        <Grid key={index} item className={classes.paymentMethod}>
                            <Grid container direction="column">
                                <Grid item>{getPaymentIconByType(checkoutMethod.paymentType)}</Grid>
                                <Grid item>
                                    <Typography variant="body2">
                                        {isShowMoney ? `$${checkoutMethod?.amount}` : HIDDEN_CHARACTERS}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                    {props.rowData?.checkoutStaff?.length > 0 && (
                        <Grid item className={classes.paymentMethod}>
                            <Grid container direction="column">
                                <Grid item>Tips</Grid>
                                <Grid item>
                                    <Typography variant="body2">
                                        {isShowMoney
                                            ? `$${props.rowData?.checkoutStaff?.reduce((t, s) => {
                                                  return t + (s?.amount ?? 0);
                                              }, 0)}`
                                            : HIDDEN_CHARACTERS}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            )
        },
        {
            id: 'createdAt',
            label: 'Created At',
            width: 150,
            format: (props) => {
                return <Typography variant="body2">{getFriendlyShortTime(props.rowData?.createdAt)}</Typography>;
            }
        },
        {
            id: 'grossSales',
            label: 'Total',
            width: 100,
            format: (props) => (
                <Typography variant="body2">
                    {isShowMoney ? `$${props?.rowData?.grossSales || 0}` : HIDDEN_CHARACTERS}
                </Typography>
            )
        },
        {
            id: 'discount',
            label: 'Discount',
            width: 100,
            format: (props) => (
                <Typography variant="body2">
                    {isShowMoney ? `$${props?.rowData?.discount || 0}` : HIDDEN_CHARACTERS}
                </Typography>
            )
        },
        {
            id: 'amount',
            label: 'Sub total',
            width: 100,
            format: (props) => (
                <Typography variant="body2">
                    {isShowMoney ? `$${props?.rowData?.amount || 0}` : HIDDEN_CHARACTERS}
                </Typography>
            )
        },

        {
            id: 'type',
            label: 'Type',
            width: 100,
            format: (props) => (
                <Chip
                    className={`${props?.rowData?.type === CHECKOUT_TYPES.GIFT ? classes.gift : classes.tag}`}
                    label={props?.rowData?.type === CHECKOUT_TYPES.BOOKING ? 'Booking' : 'Gift'}
                />
            )
        },
        {
            id: 'includedTax',
            label: 'Included Tax',
            width: 100,
            format: (props) =>
                props?.rowData?.includedTax ? null : (
                    <Chip
                        size="small"
                        style={{ background: theme.colors.red, color: theme.palette.common.white }}
                        variant="outlined"
                        label={'No'}
                    />
                )
        },
        {
            id: 'actions',
            label: 'Action',
            width: 50,
            format: (props) => <ActionsMemo isMobile={isMobile} t={t} {...props} onDelete={openDelete} />
        }
    ];

    const headerRenderer = ({ column }) => {
        return <Typography variant="body1">{column.label}</Typography>;
    };

    const onRowClicked = ({ rowData }) => {
        // handleOpenClientProfile();
        // setSelectedClient(rowData);
    };

    const onRangeChanged = React.useCallback((date) => {
        setSelectedDate(date.format());
    }, []);

    const handleCloseConfirmLongBill = React.useCallback(() => {
        setIsOpenPrintLongBill(false);
        setPassword('');
        setError('');
    }, []);

    const handleCloseConfirmShowMoney = React.useCallback(() => {
        setIsOpenShowMoney(false);
        setPassword('');
        setError('');
    }, []);

    const handlePrintLongBill = React.useCallback(() => {
        dispatch(
            checkoutActions.printLongBill({
                query: {
                    startDate: getAppendMerchantTimezoneToDate(getDatetimeFormat(selectedDate))
                },
                successCallback: () => {
                    console.log('printLongBill success');

                    setPassword('');
                    setError('');
                },
                errorCallback: () => {
                    console.log('printLongBill failed');
                }
            })
        );

        handleCloseConfirmLongBill();
    }, [dispatch, selectedDate, handleCloseConfirmLongBill]);

    const handleCheckPassword = React.useCallback(async () => {
        setIsLoading(true);
        setError('');
        try {
            const isValid = await checkPasswordApi({
                body: {
                    password
                }
            });
            setIsLoading(false);
            if (!isValid) {
                setError('Password do not match');
                return;
            }
            handlePrintLongBill();
        } catch (error) {
            setError(error?.message?.[0]);
            setIsLoading(false);
        }
    }, [password, handlePrintLongBill]);

    const handleCheckPasswordConfirmShowMoney = React.useCallback(async () => {
        setIsLoading(true);
        setError('');
        try {
            const isValid = await checkPasswordApi({
                body: {
                    password
                }
            });
            setIsLoading(false);
            if (!isValid) {
                setError('Password do not match');
                return;
            }
            setIsShowMoney(true);
            handleCloseConfirmShowMoney();
        } catch (error) {
            setError(error?.message?.[0]);
            setIsLoading(false);
        }
    }, [password, handleCloseConfirmShowMoney]);

    return (
        <ContentLayout
            title={
                <Grid item container spacing={2} direction="row" alignItems="center">
                    <Grid item>
                        <Typography variant="h3">{t(`nav_payment_histories`)}</Typography>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item>
                                <IOSSwitch
                                    checked={isShowMoney}
                                    onChange={handleShowMoneyChange}
                                    name="checkedB"
                                    color="primary"
                                />
                            </Grid>
                            <Grid item>
                                <Typography className={classes.bold} variant="body2">
                                    closed report
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
        >
            <LayoutTabContent isLoading={isFetching && checkouts === undefined}>
                <Grid container direction="row" className={classes.fullHeight}>
                    <Grid item style={{ flex: 1 }} container wrap="nowrap" direction="column" className={classes.root}>
                        <Grid item className={classes.wrapContent}>
                            {isFetching && (
                                <div className={classes.spinnerWrapper}>
                                    <Spinner size={40} thick={4} />
                                </div>
                            )}
                            <Table
                                headerRenderer={headerRenderer}
                                filterHeaderRenderer={filterHeaderRenderer}
                                rows={checkoutsFiltered || []}
                                columns={columns}
                                onRowClicked={onRowClicked}
                                classNameBody={classes.tableBody}
                                isShowFooter={false}
                                isShowFilter={true}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        style={{
                            width: 400,
                            height: '100%',
                            overflowY: 'auto',
                            marginLeft: theme.spacing(3),
                            marginTop: theme.spacing(2),
                            position: 'relative'
                        }}
                    >
                        {statisticLoading && (
                            <div className={classes.spinnerWrapper}>
                                <Spinner size={40} thick={4} />
                            </div>
                        )}
                        <Grid container direction="column" spacing={3}>
                            <Grid item>
                                <DateRange onChange={onRangeChanged} />
                            </Grid>
                            {isShowMoney && (
                                <Grid item style={{ marginTop: theme.spacing(3) }}>
                                    <Button
                                        onClick={() => setIsOpenPrintLongBill(true)}
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                    >
                                        EOD Reports
                                    </Button>
                                </Grid>
                            )}

                            <Grid item>
                                <Grid container spacing={3} direction="row">
                                    <Grid item xs={6}>
                                        <Grid
                                            container
                                            direction="column"
                                            justifyContent="center"
                                            alignItems="center"
                                            className={classes.statistic}
                                        >
                                            <Grid item>
                                                <CashSVG width={80} height={60} />
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="h3">
                                                    {isShowMoney ? `$${statistic?.cash}` : HIDDEN_CHARACTERS}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Grid
                                            container
                                            direction="column"
                                            justifyContent="center"
                                            alignItems="center"
                                            className={classes.statistic}
                                        >
                                            <Grid item>
                                                <MasterCard width={80} height={60} />
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="h3">
                                                    {isShowMoney ? `$${statistic?.masterCard}` : HIDDEN_CHARACTERS}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Grid
                                            container
                                            direction="column"
                                            justifyContent="center"
                                            alignItems="center"
                                            className={classes.statistic}
                                        >
                                            <Grid item>
                                                <VisaSVG width={80} height={60} />
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="h3">
                                                    {isShowMoney ? `$${statistic?.visa}` : HIDDEN_CHARACTERS}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Grid
                                            container
                                            direction="column"
                                            alignItems="center"
                                            justifyContent="center"
                                            className={classes.statistic}
                                        >
                                            <Grid item>
                                                <DebitSVG width={80} height={60} />
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="h3">
                                                    {isShowMoney ? `$${statistic?.debit}` : HIDDEN_CHARACTERS}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Grid
                                            container
                                            direction="column"
                                            alignItems="center"
                                            justifyContent="center"
                                            className={classes.statistic}
                                        >
                                            <Grid item>
                                                <GiftPaymentSVG width={80} height={60} />
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="h3">
                                                    {isShowMoney ? `$${statistic?.gift}` : HIDDEN_CHARACTERS}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Grid
                                            container
                                            direction="column"
                                            alignItems="center"
                                            justifyContent="center"
                                            className={classes.statistic}
                                        >
                                            <Grid item>
                                                <PaymentCancelSVG width={80} height={60} />
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="h3">
                                                    {isShowMoney ? `$${statistic?.voidAmount}` : HIDDEN_CHARACTERS}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Grid
                                            container
                                            direction="column"
                                            alignItems="center"
                                            justifyContent="center"
                                            className={classes.statistic}
                                        >
                                            <Grid item>
                                                <PaymentCancelSVG width={80} height={60} />
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="h3">
                                                    {isShowMoney ? `${statistic?.voidCount}` : HIDDEN_CHARACTERS}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Grid
                                            container
                                            direction="column"
                                            alignItems="center"
                                            justifyContent="center"
                                            className={classes.statistic}
                                        >
                                            <Grid item>
                                                <DiscountSVG width={80} height={60} />
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="h3">
                                                    {isShowMoney ? `${statistic?.discount}` : HIDDEN_CHARACTERS}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Grid
                                            container
                                            direction="column"
                                            alignItems="center"
                                            justifyContent="center"
                                            className={classes.statistic}
                                        >
                                            <Grid item>
                                                <h2>Tips</h2>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="h3">
                                                    {isShowMoney ? `${statistic?.tip}` : HIDDEN_CHARACTERS}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Grid
                                            container
                                            direction="column"
                                            alignItems="center"
                                            justifyContent="center"
                                            className={classes.statistic}
                                        >
                                            <Grid item>
                                                <h2>Void Tips</h2>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="h3">
                                                    {isShowMoney ? `${statistic?.voidTip}` : HIDDEN_CHARACTERS}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </LayoutTabContent>
            <ConfirmDialog
                open={isOpenDeleteConfirm}
                title={'Void Checkout'}
                text={`Are you sure to void checkout of <strong>${getFullName(
                    deleteCheckout?.client
                )}</strong> 's booking. This action will persist you data. click agree to void checkout.`}
                onAgree={handleVoidPayment}
                onDisagree={handleClose}
                type={CONFIRM_TYPES.ERROR}
                isLoading={isVoiding}
            />

            <Dialog maxWidth={false} open={isOpenPrintLongBill} onClose={handleCloseConfirmLongBill}>
                <RequirePasswordForm
                    password={password}
                    onPasswordChange={(e) => setPassword(e?.target?.value)}
                    isLoading={isLoading}
                    onCheckPassword={handleCheckPassword}
                    error={error}
                />
            </Dialog>

            <Dialog maxWidth={false} open={isOpenShowMoney} onClose={handleCloseConfirmShowMoney}>
                <RequirePasswordForm
                    password={password}
                    onPasswordChange={(e) => setPassword(e?.target?.value)}
                    isLoading={isLoading}
                    onCheckPassword={handleCheckPasswordConfirmShowMoney}
                    error={error}
                    title="Detailed reports"
                    subtitle="Management access only"
                />
            </Dialog>
        </ContentLayout>
    );
}

export default React.memo(Dashboard);
