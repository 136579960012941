import React from 'react';
import Dashboard from './Dashboard';
import StaffReports from './StaffReports';
import LayoutTabsContent from '../layout/LayoutTabsContent';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ReportIcon from '@material-ui/icons/Report';
import { useTranslation } from 'react-i18next';

function AnalyzeTabs() {
    const { t } = useTranslation();

    const tabs = [
        { component: <StaffReports />, index: 0, icon: <ReportIcon />, title: t(`staff`), path: 'staffs' },
        { component: <Dashboard />, index: 1, icon: <DashboardIcon />, title: t(`booking`), path: 'bookings' }
    ];

    return <LayoutTabsContent tabs={tabs} />;
}

export default React.memo(AnalyzeTabs);
