import * as types from './types';

const INITIAL_STATE = {
    hidePriceDates: null,
    isFetchingHidePriceDates: false
};

export default function Service(state = INITIAL_STATE, { type, payload }) {
    switch (type) {
        case types.FETCH_HIDE_PRICE_DATE: {
            return {
                ...state,
                isFetchingHidePriceDates: true
            };
        }

        case types.FETCH_HIDE_PRICE_DATE_FAILED: {
            return {
                ...state,
                isFetchingHidePriceDates: false
            };
        }

        case types.FETCH_HIDE_PRICE_DATE_SUCCESS: {
            const { hidePriceDates } = payload;
            return {
                ...state,
                isFetchingHidePriceDates: false,
                hidePriceDates
            };
        }

        case types.ADD_HIDE_PRICE_DATE_SUCCESS: {
            const { hidePriceDate } = payload;
            const { hidePriceDates } = state;
            return {
                ...state,
                hidePriceDates: [hidePriceDate, ...hidePriceDates]
            };
        }

        case types.DELETE_HIDE_PRICE_DATE: {
            const { id } = payload;
            const { hidePriceDates } = state;
            const newHidePriceDates = hidePriceDates?.map((date) => {
                if (+date?.id === +id) {
                    date.isDeleted = true;
                }
                return date;
            });

            return {
                ...state,
                hidePriceDates: newHidePriceDates
            };
        }

        case types.DELETE_HIDE_PRICE_DATE_FAILED: {
            const { id } = payload;
            const { hidePriceDates } = state;
            const newHidePriceDates = hidePriceDates?.map((date) => {
                if (+date?.id === +id) {
                    date.isDeleted = false;
                }
                return date;
            });

            return {
                ...state,
                hidePriceDates: newHidePriceDates
            };
        }

        case types.DELETE_HIDE_PRICE_DATE_SUCCESS: {
            const { id } = payload;
            const { hidePriceDates } = state;
            const newHidePriceDates = hidePriceDates?.filter((date) => +date?.id !== +id);

            return {
                ...state,
                hidePriceDates: newHidePriceDates
            };
        }

        case types.UPDATE_HIDE_PRICE_DATE_SUCCESS: {
            const { hidePriceDate, id } = payload;
            const { hidePriceDates } = state;
            const newHidePriceDates = hidePriceDates?.map((date) => {
                if (+date?.id === +id) {
                    return hidePriceDate;
                }
                return date;
            });

            return {
                ...state,
                hidePriceDates: newHidePriceDates
            };
        }

        default:
            return state;
    }
}
