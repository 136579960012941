export const genderOptions = [
    { value: 0, label: 'Unknown' },
    { value: 1, label: 'Male' },
    { value: 2, label: 'Female' }
];

export const mapGenderName = {
    0: 'Unknown',
    1: 'Male',
    2: 'Female'
};
