import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Spinner from 'components/spinner';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        width: '100%',
        flex: 1,
        height: '100%'
    },
    spinner: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));

function LayoutTabContent({ children, className, isLoading, ...rest }) {
    const classes = useStyles();

    return (
        <Grid container className={`${classes.root} ${className}`}>
            {isLoading && (
                <div className={classes.spinner}>
                    <Spinner size={40} thick={4} />
                </div>
            )}
            {children}
        </Grid>
    );
}

export default React.memo(LayoutTabContent);
