import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputBase from 'components/input/Form';
import ButtonBase from 'components/button/Base';
import DialogActions from 'components/dialog/Action';
import DialogTitle from 'components/dialog/Title';
import DialogContent from 'components/dialog/Content';
import PricingList from './PricingList';
import Staff from './Staff';
import { Tab, Tabs, CircularProgress, Hidden, Grid, Typography } from '@material-ui/core';
import TabPanel from 'components/tabPanel';
import { useFormik } from 'formik';
import { string, object } from 'yup';
import { useDispatch } from 'react-redux';
import { generateTempId, isTempId } from 'utils/uuid';
import * as serviceActions from '../action/service';
import { useTranslation } from 'react-i18next';
import { BORDER_RADIUS } from 'const/style';
import IOSSwitch from 'components/switch/IOS';
import { useMerchantPermissions, useIsHavingPermission } from 'hooks/auth';
import { MERCHANT_PERMISSIONS, CONFIRM_TYPES } from 'const';
import { isAuthorized } from 'utils/app/auth';
import { cloneDeep } from 'lodash';
import ConfirmDialog from 'components/dialog/Confirm';
import AddIcon from '@material-ui/icons/AddCircle';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import { DEPOSIT_RATE_OPTIONS } from 'utils/app/staff';
import NativeSelect from 'components/select/NativeForm';
import { durationOptions } from 'data/time';
import htmlToDraft from 'html-to-draftjs';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// import SimpleBar from 'simplebar-react';

const useStyles = makeStyles((theme) => ({
    spacing: {
        marginBottom: 18
    },
    addPricing: {
        cursor: 'pointer',
        textDecoration: 'underline',
        color: theme.colors.dodgerBlue
    },
    tabTitle: {
        textTransform: 'uppercase',
        fontWeight: 500
    },
    content: {
        maxWidth: 1024,
        margin: `0 auto`,
        width: '100%'
    },
    category: {
        width: '100%',
        minHeight: 200,
        padding: 10,
        borderRadius: BORDER_RADIUS,
        '&:hover': {
            opacity: 0.5,
            cursor: 'pointer'
        }
    },
    ctTitle: {
        color: theme.palette.common.white
    },
    selected: {
        border: `2px solid ${theme.colors.primaryText}`,
        boxShadow: theme.shadows[1]
    },
    color: {
        width: 40,
        height: 40,
        borderRadius: '50%'
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    btnSubmit: {
        position: 'relative',
        width: '100%'
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    categorySpacing: {
        paddingRight: 12,
        paddingBottom: 12,
        [theme.breakpoints.down('sm')]: {
            paddingRight: 0
        }
    },
    editor: {
        '& .editor-wrapper': {
            border: `1px solid ${theme.colors.border}`,
            borderRadius: 10
        },
        '& .editor': {
            minHeight: 150,
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingBottom: theme.spacing(2)
        },
        '& .public-DraftStyleDefault-block': {
            margin: `2px 0px!important`
        }
    }
}));
const DEFAULT_PRICING_OPTION = {
    duration: 60,
    id: generateTempId(),
    priceType: 0,
    retailPrice: 0,
    specialPrice: null,
    priceName: ''
};

const CategorySchema = object({
    title: string().required('This filed is required')
});

function AddService({ onClose, editedService, isEdit, selectedCategoryId }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const merchantPermissions = useMerchantPermissions();

    const [enableInternalOnly, setEnableInternalOnly] = React.useState(
        isEdit ? (editedService?.enableInternalOnly ? true : false) : false
    );

    const serviceDescriptionDefaultState = React.useMemo(() => {
        const blocksFromHtml = htmlToDraft(editedService?.description || ``);
        const { contentBlocks, entityMap } = blocksFromHtml;
        return ContentState.createFromBlockArray(contentBlocks, entityMap);
    }, [editedService]);

    const defaultDescription = React.useMemo(() => {
        return editedService?.description
            ? EditorState.createWithContent(serviceDescriptionDefaultState)
            : EditorState.createEmpty();
    }, [serviceDescriptionDefaultState, editedService]);

    const [description, setDescription] = React.useState(defaultDescription);

    const [enablePrimarySearch, setEnablePrimarySearch] = React.useState(
        isEdit ? (editedService?.isPrimarySearch ? true : false) : false
    );
    const { t } = useTranslation();
    const [prices, setPrices] = React.useState(
        isEdit
            ? editedService?.prices?.length
                ? cloneDeep(editedService?.prices)
                : [{ ...DEFAULT_PRICING_OPTION }]
            : [{ ...DEFAULT_PRICING_OPTION }]
    );
    const [tabIndex, setTabIndex] = React.useState(0);
    const [enableOnlineBooking, setEnableOnlineBooking] = React.useState(
        isEdit ? editedService.enableOnlineBooking : true
    );
    const isHasStaffServiceDuration = useIsHavingPermission(MERCHANT_PERMISSIONS.STAFF_SERVICE_DURATION);
    const isHasDepositPermission = useIsHavingPermission(MERCHANT_PERMISSIONS.DEPOSIT);
    const isHasDepositAmountPermission = useIsHavingPermission(MERCHANT_PERMISSIONS.DEPOSIT_AMOUNT);

    const [serviceStaffs, setServiceStaffs] = React.useState(isEdit ? editedService.serviceStaffs : []);
    const [serviceAvaibleType, setServiceAvaibleType] = React.useState(isEdit ? editedService.serviceAvaibleType : 0);
    const [openConfirmChangePriceOrService, setOpenConfirmChangePriceOrService] = React.useState(false);

    const [depositRate, setDepositRate] = React.useState(isEdit ? +editedService?.depositRate || 0 : 0);
    const [minimumTime, setMinimumTime] = React.useState(isEdit ? +editedService?.minimumTime || 0 : 0);

    const enabledInternalOnlyChange = (e) => {
        setEnableInternalOnly(e.target.checked);
    };

    const enablePrimarySearchChange = (e) => {
        setEnablePrimarySearch(e.target.checked);
    };

    console.log('setServiceAvaibleType', setServiceAvaibleType);
    console.log('setEnableOnlineBooking', setEnableOnlineBooking);

    const isUpdateDuration = React.useMemo(() => {
        const oldPrice = editedService?.prices?.[0];
        const newPrice = prices?.[0];

        return oldPrice?.duration !== newPrice?.duration;
    }, [prices, editedService]);

    const formik = useFormik({
        initialValues: {
            title: isEdit ? editedService?.title || '' : ''
        },
        validationSchema: CategorySchema,
        onSubmit: (values, { setSubmitting, setStatus }) => {
            setStatus({ error: null });
            setSubmitting(true);

            const newDescription = draftToHtml(convertToRaw(description.getCurrentContent()));

            if (!isEdit) {
                const createBody = {
                    ...values,
                    description: newDescription,
                    prices: prices.map((price) => {
                        if (price.id) {
                            delete price['id'];
                        }
                        return price;
                    }),
                    minimumTime,
                    depositRate,
                    enableOnlineBooking,
                    enableInternalOnly,
                    isPrimarySearch: enablePrimarySearch,
                    categoryId: selectedCategoryId,
                    serviceAvaibleType,
                    serviceStaffs: serviceStaffs
                        ?.filter((staff) => staff.staffId)
                        ?.map((staff) => {
                            return {
                                staffId: Number(staff.staffId)
                            };
                        })
                };

                dispatch(
                    serviceActions.createService({
                        ctId: selectedCategoryId,
                        body: createBody,
                        successCallback: () => {
                            console.log('create service success');
                            onClose();
                        },
                        errorCallback: () => {
                            setSubmitting(false);
                        }
                    })
                );
            } else {
                const oldPrice = editedService?.prices?.[0];
                const newPrice = prices?.[0];

                if (oldPrice?.duration !== newPrice?.duration || oldPrice?.retailPrice !== newPrice?.retailPrice) {
                    setOpenConfirmChangePriceOrService(true);
                    return;
                }

                const updatedBody = {
                    ...values,
                    description: newDescription,
                    prices: prices.map((price) => {
                        if (price.id && isTempId(price.id)) {
                            delete price['id'];
                        }
                        return price;
                    }),
                    depositRate,
                    minimumTime,
                    enableOnlineBooking,
                    categoryId: selectedCategoryId,
                    serviceAvaibleType,
                    enableInternalOnly,
                    isPrimarySearch: enablePrimarySearch,
                    serviceStaffs: serviceStaffs
                        ?.filter((serviceStaff) => serviceStaff?.staffId)
                        .map((serviceStaff) => {
                            if (!isTempId(serviceStaff?.id)) {
                                return {
                                    staffId: Number(serviceStaff.staffId),
                                    id: Number(serviceStaff.id),
                                    commissionRate: serviceStaff?.commissionRate,
                                    extraCommission: serviceStaff?.extraCommission
                                };
                            } else {
                                return {
                                    staffId: Number(serviceStaff.staffId)
                                };
                            }
                        })
                };

                dispatch(
                    serviceActions.updateService({
                        ctId: +selectedCategoryId,
                        sId: +editedService?.id,
                        body: updatedBody,
                        successCallback: () => {
                            console.log('update service success');
                            onClose();
                        },
                        errorCallback: () => {
                            setSubmitting(false);
                        }
                    })
                );
            }

            setStatus({ error: null });
        }
    });

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, setSubmitting } =
        formik;

    const handleConfirmChangePriceAccept = React.useCallback(() => {
        const newDescription = draftToHtml(convertToRaw(description.getCurrentContent()));

        const updatedBody = {
            ...values,
            prices: prices.map((price) => {
                if (price.id && isTempId(price.id)) {
                    delete price['id'];
                }
                return price;
            }),
            description: newDescription,
            enableOnlineBooking,
            categoryId: selectedCategoryId,
            serviceAvaibleType,
            enableInternalOnly,
            depositRate,
            minimumTime,
            isPrimarySearch: enablePrimarySearch,
            serviceStaffs: serviceStaffs
                ?.filter((serviceStaff) => serviceStaff?.staffId)
                .map((serviceStaff) => {
                    if (!isTempId(serviceStaff?.id)) {
                        return {
                            staffId: Number(serviceStaff.staffId),
                            id: Number(serviceStaff.id)
                        };
                    } else {
                        return {
                            staffId: Number(serviceStaff.staffId)
                        };
                    }
                })
        };

        dispatch(
            serviceActions.updateService({
                ctId: +selectedCategoryId,
                sId: +editedService?.id,
                body: updatedBody,
                successCallback: () => {
                    console.log('update service success');
                    onClose();
                },
                errorCallback: () => {
                    setSubmitting(false);
                }
            })
        );
    }, [
        description,
        values,
        prices,
        enableOnlineBooking,
        selectedCategoryId,
        serviceAvaibleType,
        enableInternalOnly,
        depositRate,
        minimumTime,
        enablePrimarySearch,
        serviceStaffs,
        dispatch,
        editedService,
        onClose,
        setSubmitting
    ]);

    // const addPricingOption = () => {
    //     setPrices([...prices, { ...DEFAULT_PRICING_OPTION, id: generateTempId() }]);
    // };

    const removePricing = React.useCallback(
        (id) => {
            const newPricing = prices.filter((price) => price.id !== id);
            setPrices(newPricing);
        },
        [prices]
    );

    const onDurationChange = React.useCallback(
        ({ id, value }) => {
            const newPricing = prices.map((price) => {
                if (price.id === id) {
                    price.duration = Number(value);
                }
                return price;
            });

            setPrices(newPricing);
        },
        [prices]
    );

    const onPriceNameChange = React.useCallback(
        ({ id, value }) => {
            const newPricing = prices.map((price) => {
                if (price.id === id) {
                    price.priceName = value;
                }
                return price;
            });

            setPrices(newPricing);
        },
        [prices]
    );

    const onPriceTypeChange = React.useCallback(
        ({ id, value }) => {
            const newPricing = prices.map((price) => {
                if (price.id === id) {
                    price.priceType = Number(value);
                }
                return price;
            });

            setPrices(newPricing);
        },
        [prices]
    );

    const onRetailPriceChange = React.useCallback(
        ({ id, value }) => {
            const newPricing = prices.map((price) => {
                if (price.id === id) {
                    price.retailPrice = Number(value);
                }
                return price;
            });

            setPrices(newPricing);
        },
        [prices]
    );

    const onSpecialPriceChange = React.useCallback(
        ({ id, value }) => {
            const newPricing = prices.map((price) => {
                if (price.id === id) {
                    price.specialPrice = Number(value);
                }
                return price;
            });

            setPrices(newPricing);
        },
        [prices]
    );

    const handleStaffChange = React.useCallback(
        ({ checked, staff }) => {
            let newServiceStaffs = [...serviceStaffs];
            if (checked) {
                newServiceStaffs = [...serviceStaffs, { id: generateTempId(), staffId: staff.id }];
            } else {
                newServiceStaffs = newServiceStaffs.filter((serviceStaff) => serviceStaff?.staffId !== staff.id);
            }
            setServiceStaffs(newServiceStaffs);
        },
        [serviceStaffs]
    );

    const handleDepositChange = React.useCallback((e) => {
        setDepositRate(+e?.target?.value);
    }, []);

    // const onOnlineBookingChanged = (checked) => {
    //     setEnableOnlineBooking(checked);
    // };

    // const onServiceAvaibleChanged = (value) => {
    //     setServiceAvaibleType(Number(value));
    // };

    const tabs = [
        { component: <Staff serviceStaffs={serviceStaffs} onChange={handleStaffChange} />, index: 0, title: t('staff') }
        // {
        //     component: (
        //         <OnlineBooking
        //             enableOnlineBooking={enableOnlineBooking}
        //             onOnlineBookingChanged={onOnlineBookingChanged}
        //             values={values}
        //             errors={errors}
        //             touched={touched}
        //             handleChange={handleChange}
        //             handleBlur={handleBlur}
        //             isSubmitting={isSubmitting}
        //             serviceAvaibleType={serviceAvaibleType}
        //             onServiceAvaibleChanged={onServiceAvaibleChanged}
        //         />
        //     ),
        //     index: 1,
        //     title: t('online_booking')
        // }
    ];

    return (
        <form onSubmit={handleSubmit} className={classes.form}>
            <DialogTitle>
                <Typography variant="h5">{isEdit ? `Update` : `New`} Service</Typography>
            </DialogTitle>
            <DialogContent>
                {/* <SimpleBar style={{ maxHeight: '100%' }}> */}
                <Grid container direction="row" className={classes.content} spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Grid container direction="column" wrap="nowrap">
                            <Grid item>
                                <InputBase
                                    fullWidth
                                    className={`${classes.spacing} ${classes.input}`}
                                    label={t('service_name')}
                                    placeholder={t('service_name_placeholder')}
                                    name="title"
                                    value={values.title || ''}
                                    disabled={isSubmitting}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.title && touched.title}
                                    errorText={errors.title}
                                    autoFocus={true}
                                />
                            </Grid>
                            <Grid item>
                                <PricingList
                                    prices={prices}
                                    removePricing={removePricing}
                                    onDurationChange={onDurationChange}
                                    onPriceTypeChange={onPriceTypeChange}
                                    onRetailPriceChange={onRetailPriceChange}
                                    onSpecialPriceChange={onSpecialPriceChange}
                                    onPriceNameChange={onPriceNameChange}
                                />
                            </Grid>

                            <Grid item>
                                <Grid container direction="column" spacing={1}>
                                    <Grid item>
                                        <Typography variant="body1">Description</Typography>
                                    </Grid>
                                    <Grid item className={classes.editor}>
                                        <Editor
                                            editorState={description}
                                            wrapperClassName="editor-wrapper"
                                            editorClassName="editor"
                                            onEditorStateChange={(editor) => setDescription(editor)}
                                            toolbar={{
                                                options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign'],
                                                inline: {
                                                    options: ['bold', 'italic', 'underline', 'strikethrough']
                                                },
                                                list: { options: ['unordered', 'ordered'] },
                                                link: { inDropdown: true },
                                                textAlign: { inDropdown: true }
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            {isHasDepositPermission && (
                                <Grid item className={`${classes.spacing}`}>
                                    <NativeSelect
                                        label={'Deposit Rate'}
                                        onChange={handleDepositChange}
                                        selectedValue={depositRate}
                                        options={DEPOSIT_RATE_OPTIONS}
                                        placeholder={t(`choose_an_option`)}
                                    />
                                </Grid>
                            )}

                            {isHasDepositAmountPermission && (
                                <Grid item className={`${classes.spacing}`}>
                                    <InputBase
                                        label={`Deposit Amount`}
                                        value={depositRate}
                                        onChange={(e) => setDepositRate(e?.target?.value)}
                                        placeholder={t('retail_price_placeholder')}
                                        type="number"
                                        step="any"
                                    />
                                </Grid>
                            )}

                            {isAuthorized({
                                userPermission: merchantPermissions,
                                permissions: [
                                    MERCHANT_PERMISSIONS.INTERNAL,
                                    MERCHANT_PERMISSIONS.INTERNAL_BASIC,
                                    MERCHANT_PERMISSIONS.INTERNAL_MEDIUM
                                ]
                            }) && (
                                <div className={classes.spacing}>
                                    <NativeSelect
                                        isBorder={true}
                                        onChange={(e) => setMinimumTime(e.target.value)}
                                        label={'Minimum time service'}
                                        options={[{ label: 'reset', value: 0 }, ...durationOptions]}
                                        selectedValue={minimumTime}
                                    />
                                </div>
                            )}

                            {isAuthorized({
                                userPermission: merchantPermissions,
                                permissions: [
                                    MERCHANT_PERMISSIONS.INTERNAL,
                                    MERCHANT_PERMISSIONS.INTERNAL_BASIC,
                                    MERCHANT_PERMISSIONS.INTERNAL_MEDIUM
                                ]
                            }) && (
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item>
                                        <IOSSwitch
                                            checked={enableInternalOnly}
                                            onChange={enabledInternalOnlyChange}
                                            name="checkedB"
                                            color="primary"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography className={classes.bold} variant="body2">
                                            {t(`enable_internal_only`)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                            <Grid style={{ marginTop: 18 }} container spacing={2} alignItems="center">
                                <Grid item>
                                    <IOSSwitch
                                        checked={enablePrimarySearch}
                                        onChange={enablePrimarySearchChange}
                                        name="checkedB"
                                        color="primary"
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.bold} variant="body2">
                                        Enable primary search for this service
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Tabs
                            value={tabIndex}
                            onChange={(e, index) => setTabIndex(index)}
                            indicatorColor="primary"
                            textColor="inherit"
                        >
                            {tabs.map((tab) => (
                                <Tab
                                    key={tab.index}
                                    textColor="inherit"
                                    label={
                                        <Typography className={classes.tabTitle} variant="body2">
                                            {tab?.title}
                                        </Typography>
                                    }
                                />
                            ))}
                        </Tabs>
                        {tabs.map((tab) => {
                            return (
                                <TabPanel key={tab?.index} value={tabIndex} index={tab?.index}>
                                    {tab.component}
                                </TabPanel>
                            );
                        })}
                    </Grid>
                </Grid>
                {/* </SimpleBar> */}
            </DialogContent>
            <DialogActions>
                <Grid container direction="row" spacing={2} alignItems="center" justifyContent="flex-end" wrap="nowrap">
                    <Hidden xsDown>
                        <Grid item>
                            <ButtonBase startIcon={<CloseIcon />} onClick={onClose} fullWidth variant="outlined">
                                {t('close')}
                            </ButtonBase>
                        </Grid>
                    </Hidden>

                    <Grid item>
                        <div className={classes.btnSubmit}>
                            <ButtonBase
                                disabled={isSubmitting || !isValid}
                                fullWidth
                                variant="contained"
                                color="primary"
                                type="submit"
                                startIcon={isEdit ? <SaveIcon /> : <AddIcon />}
                            >
                                {isEdit ? t('save') : t('create')}
                            </ButtonBase>
                            {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                    </Grid>
                </Grid>
            </DialogActions>
            <ConfirmDialog
                open={openConfirmChangePriceOrService}
                title={`You have changed service price or duration`}
                text={`This action will update all upcoming appointments${
                    isHasStaffServiceDuration && isUpdateDuration && ` and also update staff's service duration`
                }. Are you sure to do this?`}
                onAgree={handleConfirmChangePriceAccept}
                agreeLabel={`Agree`}
                onDisagree={() => setOpenConfirmChangePriceOrService(false)}
                type={CONFIRM_TYPES.ERROR}
            />
        </form>
    );
}

export default React.memo(AddService);
