import React, { useEffect } from 'react';
import { enqueueSnackbar } from 'notifier/actions';
import store from './store/configStore';

const { dispatch } = store;

function Networking() {
    useEffect(() => {
        const handlerOffline = function () {
            dispatch(
                enqueueSnackbar({
                    message: `No internet connection`,
                    type: 'error',
                    duration: 20000
                })
            );
        };
        window.addEventListener('offline', handlerOffline);

        const handlerOnline = function () {
            dispatch(
                enqueueSnackbar({
                    message: `Network connected`,
                    type: 'success'
                })
            );
        };
        window.addEventListener('online', handlerOnline);

        return () => {
            window.removeEventListener('offline', handlerOffline);
            window.removeEventListener('online', handlerOnline);
        };
    }, []);
    return null;
}

export default React.memo(Networking);
