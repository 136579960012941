import React from 'react';
import { Grid } from '@material-ui/core';
import CheckoutButton from './checkout/Button';
import ClientArrivedButton from './clientArrived/Button';
import { useIsHavingPermissionTest } from 'hooks/auth';
import { MERCHANT_PERMISSIONS } from 'const';

function Controls() {
    const isAdvancedInternal = useIsHavingPermissionTest(MERCHANT_PERMISSIONS.INTERNAL);

    const width = React.useMemo(() => {
        return isAdvancedInternal ? 64 : 0;
    }, [isAdvancedInternal]);

    return (
        <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            style={{ position: 'absolute', bottom: 30, right: 30 + width, zIndex: 9 }}
            spacing={3}
        >
            <CheckoutButton />
            <ClientArrivedButton />
        </Grid>
    );
}

export default Controls;
