import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

const width = 50;
const height = 25;

const IOSSwitch = withStyles((theme) => ({
    root: {
        width: width,
        height: height,
        padding: 0,
        display: 'flex'
    },
    switchBase: {
        padding: 2,
        color: theme.palette.grey[500],
        transform: `translateY(-1px)`,
        '&$checked': {
            transform: `translate(24px, -1px)`,
            color: theme.palette.common.white,
            '& + $track': {
                opacity: 1,
                backgroundColor: (props) => (props.light ? theme.colors.hover : theme.palette.primary.main),
                borderColor: theme.palette.primary.main
            }
        }
    },
    thumb: {
        width: height - 2,
        height: height - 2,
        boxShadow: 'none'
    },
    track: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: height / 2,
        opacity: 1,
        backgroundColor: theme.palette.common.white
    },
    checked: {}
}))(Switch);

export default React.memo(IOSSwitch);
