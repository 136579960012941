export default {
    translations: {
        add_new: ' Thêm Mới',
        today: ' Hôm nay',
        export: ' Xuất',
        staff: ' Thợ',
        vouchers: 'Giảm giá',
        add: ' Thêm',
        back: ' Quay lại',
        hello: ' Xin chào',
        services: ' Dịch vụ',
        service: ' Dịch vụ',
        category: ' Danh mục',
        calendar: ' Lịch',
        client_name: ' Tên khách hàng',
        clients: ' Khách hàng',
        client: ' Khách hàng',
        online_booking: 'Đặt trực tuyến',
        confirm_arrived: 'Đã đến',
        no_show: 'Không đến',
        undo_no_show: 'Khách đổi ý',
        pdf: ' PDF',
        csv: ' CSV',
        excel: ' EXCEL',
        duration: ' Thời gian',
        create: ' Tạo',
        save: ' Lưu',
        update: ' Cập nhật',
        date: ' Ngày',
        delete: ' Xoá',
        edit: ' Sửa',
        cancel: ' Huỷ',
        close: ' Đóng',
        options: ' Tuỳ chọn',
        download: ' Tải xuống',
        suburd: ' ',
        booking: ' Hẹn',
        city: ' Thành phố',
        state: ' Bang',
        zip_code: ' Mã Zip',
        upload: ' Tải lên',
        import: ' Nhập vào',
        merge: ' Ghép',
        locations: ' Vị trí',
        description: ' Mô tả',
        start_date: ' Ngày bắt đầu',
        start_time: ' Giờ bắt đầu',
        end_time: ' Giờ kết thúc',
        end_date: ' Ngày kết thúc',
        choose_an_option: ' Chọn',
        are_you_sure_to_delete: ' Bạn có chắc sẽ xoá?',
        this_action_will_remove: ' Hành động này sẽ xoá',
        forever: ' Mãi mãi',
        hours: ' Giờ',
        minutes: ' Phút',
        shift_start: ' Bắt đầu ca',
        shift_end: ' Kết thúc ca',
        repeats: ' Lặp lại',
        end_repeat: ' Huỷ lặp',
        next: ' Kế tiếp',
        password: ' Mật khẩu',
        logout: ' Thoát',
        large: ' Lớn',
        medium: ' Vừa',
        small: ' Nhỏ',
        nav_overview: ' Tổng quan',
        nav_calendar: ' Lịch',
        nav_services: ' Dịch vụ',
        nav_clients: ' Khách hàng',
        nav_staff: ' Thợ',
        nav_plus: ' CA Plus',
        nav_analytics: ' Phân tích',
        nav_marketing: ' Quảng cáo',
        nav_voucher: 'Giảm giá',
        name: ' Tên',
        email: ' Thư điện tử',
        username_or_email: ' Tên hoặc email',
        email_placeholder: ' mail@example.com',
        mobile_number: ' Điện thoại',
        appointments: ' Lịch hẹn',
        appointment: ' Lịch hẹn',
        details: ' Chi tiết',
        first_name: ' Tên',
        first_name_placeholder: ' ví dụ: Hoa',
        last_name: ' Họ',
        last_name_placeholder: ' ví dụ: Nguyễn',
        phone_number: ' Điện thoại',
        phone_number_placeholder: ' +84 98 899 31 54',
        notes: ' Ghi chú',
        employment: ' Tuyển dụng',
        select_date: ' Chọn ngày',
        dashboard: ' Bảng điều khiển',
        reports: ' Báo cáo',
        anylatics: ' Phân tích',
        manager: ' Quản lí',
        avatar: ' Hình đại diện',
        your_booking_site: ' Trang đặt hẹn',
        filter_day: ' Ngày',
        filter_3day: ' 3 ngày',
        filter_week: ' Tuần',
        new_appointment: ' Lịch hẹn mới',
        new_blocked_time: ' Lịch nghỉ mới',
        booking_title: ' Tên cuộc hẹn',
        booking_title_placeholder: ' Hoa Nguyễn',
        no_staff_schedule: ' Không có giờ làm của nhân viên ',
        zoom_setting: ' Chỉnh to/ nhỏ',
        add_another_service: ' Thêm dịch vụ',
        new_service: ' Dịch vụ mới',
        new_category: ' Danh mục mới',
        choose_a_category: ' Hãy chọn một danh mục',
        select_staff_who_perform_this_service: ' Chọn thợ có thể làm dịch vụ này',
        create_category_brefore_create_a_service: ' Vui lòng tạo danh mục trước khi tạo dịch vụ',
        service_name: ' Tên dịch vụ',
        service_name_placeholder: ' ví dụ: Làm móng giả',
        price: 'Giá',
        price_type: ' Loại giá',
        retail_price: ' Giá',
        retail_price_placeholder: ' 0',
        special_price: ' Giá đặc biệt (không bắt buộc)',
        special_price_placeholder: ' 0',
        pricing_name: ' Tên giá (không bắt buộc)',
        pricing_name_description: ' ví dụ: Giá tháng sáu',
        add_another_pricing_option: ' Thêm một loại giá khác',
        allow_clients_to_book_this:
            ' Cho phép khách được đặt hẹn cho dịch vụ này. Tắt mục này sẽ ẩn dịch vụ khỏi phần đặt hẹn online.',
        enable_online_booking: ' Mở hẹn online',
        service_description: ' Mô tả dịch vụ',
        service_description_placeholder: ' Mô tả sẽ hiển thị trên trang đặt hẹn online',
        service_available_for: ' Dịch vụ này dành cho',
        category_name: ' Tên danh mục',
        category_name_placeholder: ' ví dụ: Làm móng giả',
        category_description: ' Nội dung danh mục',
        category_description_placeholder: ' ví dụ: Làm móng giả',
        choose_color: ' Chọn màu',
        search_by_name_email_or_mobile_number: ' Tìm kiếm bằng email hoặc điện thoại',
        new_client: ' Khách hàng mới',
        staff_working_hours: ' Giờ làm',
        closed_dates: ' Ngày nghỉ',
        closed_date: ' Ngày nghỉ',
        staff_members: ' Nhân viên',
        user_permission: ' Quyền',
        all_staff: ' Tất cả nhân viên',
        add_another_shift: ' Thêm ca làm',
        assign_service_this_staff: ' Chọn dịch vụ nhân viên này có thể làm',
        date_ranges: ' Ngày',
        no_of_days: ' Số ngày',
        online_bookings_can_not_be_placed: ' Bạn không thể đặt trực tuyến vào ngày nghỉ',
        enable_appointment_bookings: ' Cho phép đặt hẹn',
        staff_title: ' Vị trí của nhân viên',
        staff_title_placeholder: ' Nhập vị trí của nhân viên (ví dụ: thợ cắt tóc) có thể xem lịch hẹn',
        notes_staff_placeholder: ' Thêm ghi chú cho nhân viên. Ghi chú sẽ được hiển thị trên trang hẹn online.',
        closed_date_start_date_placeholder: ' Chọn ngày bắt đầu',
        closed_date_end_date_placeholder: ' Chọn ngày kết thúc',
        new_closed_date: ' Ngày Đóng Cửa',
        closed_date_description_placeholder: ' ví dụ: lễ tết',
        generate_static_page: ' ',
        generate_embeded_link: ' ',
        profile: ' Trang cá nhân',
        send_sms: ' Gửi tin nhắn SMS',
        send_email: ' Gửi email',
        manage_clients: ' Quản lí khách',
        gender: ' Giới tính',
        persional_info: ' Thông tin cá nhân',
        address: ' Địa chỉ',
        message: ' Tin nhắn',
        message_placeholder: ' Nhập tin nhắn SMS',
        add_new_client: ' Thêm khách mới',

        // Voucher details
        gift_name: 'Tên giảm giá',
        gift_name_placeholder: 'Tên giảm giá',
        value: 'Value',
        value_placeholder: 'eg. 100',
        never: 'không bao giờ',
        three_months: '3 tháng',
        six_months: '6 tháng',
        nine_months: '9 tháng',
        one_year: '1 năm',
        multiple_usage: 'For multiple-use',
        single_usage: 'For single-use',
        redeem: 'Đổi thưởng',
        gift_value: 'Giá trị quà tặng',
        redeem_all_services: 'Đổi tất cả dịch vụ',
        available_in_all_location: 'Available in all locations',
        sell_gift: 'Sell gift',
        duplicate_gift: 'Duplicate gift',
        edit_gift: 'Edit gift',
        delete_gift: 'Delete gift',
        expires_in: 'Thời gian hết hạn',
        validation_period: 'Validation period ',
        from: 'Người Nhận',
        to: 'Người Gửi'
    }
};
