import request from './request';
import isEmpty from 'lodash/isEmpty';
import { objectToQueryParams } from 'utils/queryParams';

const analyzeApis = {
    booking: `/bookings/analyze`,
    staff: `/bookings/staffs/analyze`,
    merchant: `/bookings/merchants/analyze`,
    statistic: `/bookings/statistic`,
    tip: `/checkout-staff/tip`
};

export async function getAnalyzeBookingApi(query) {
    let extraUrl = analyzeApis.booking;
    if (!isEmpty(query)) {
        extraUrl += `?${objectToQueryParams(query)}`;
    }
    const response = await request.get(extraUrl);
    return response.data;
}

export async function getStaffAnalyzeBookingApi(query) {
    let extraUrl = analyzeApis.staff;
    if (!isEmpty(query)) {
        extraUrl += `?${objectToQueryParams(query)}`;
    }
    const response = await request.get(extraUrl);
    return response.data;
}

export async function getStaffTipApi(query) {
    let extraUrl = analyzeApis.tip;
    if (!isEmpty(query)) {
        extraUrl += `?${objectToQueryParams(query)}`;
    }
    const response = await request.get(extraUrl);
    return response.data;
}

export async function getMerchantAnalyzeApi(query) {
    let extraUrl = analyzeApis.merchant;
    if (!isEmpty(query)) {
        extraUrl += `?${objectToQueryParams(query)}`;
    }
    const response = await request.get(extraUrl);
    return response.data;
}

export async function getStatisticBookingApi(query) {
    let extraUrl = analyzeApis.statistic;
    if (!isEmpty(query)) {
        extraUrl += `?${objectToQueryParams(query)}`;
    }
    const response = await request.get(extraUrl);
    return response.data;
}
