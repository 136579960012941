import React from 'react';

function PaymentCancelSVG({ color = '#41415C', ...rest }) {
    return (
        <svg
            id="Layer_3"
            height="512"
            viewBox="0 0 64 64"
            width="512"
            xmlns="http://www.w3.org/2000/svg"
            data-name="Layer 3"
            {...rest}
        >
            <rect fill="#d6d6d6" height="34" rx="4" width="52" x="2" y="6" />
            <path d="m2 16h52v6h-52z" fill="#a1a1a1" />
            <rect fill="#d6d6d6" height="34" rx="4" width="52" x="8" y="12" />
            <path d="m12 12a4 4 0 0 0 -4 4v25h39a5 5 0 0 0 5-5v-24z" fill="#f2f2f2" />
            <path d="m36.877 36h8.246v2h-8.246z" fill="#a1a1a1" transform="matrix(.242 -.97 .97 .242 -4.828 67.818)" />
            <circle cx="50" cy="46" fill="#aa2b24" r="12" />
            <path
                d="m50 34a11.986 11.986 0 0 0 -10.382 18h13.382a4 4 0 0 0 4-4v-11.733a11.928 11.928 0 0 0 -7-2.267z"
                fill="#d24034"
            />
            <rect fill="#f3a845" height="12" rx="2" width="12" x="14" y="18" />
            <path d="m24 26v-8h-8a2 2 0 0 0 -2 2v8h8a2 2 0 0 0 2-2z" fill="#eecc61" />
            <path d="m23 21h-2v-3h-2v7h-2v2h2v3h2v-7h2z" fill="#fff" />
            <g fill="#a1a1a1">
                <path d="m13 39h23v2h-23z" />
                <path d="m31 17h24v2h-24z" />
                <path d="m31 21h24v2h-24z" />
                <path d="m31 25h24v2h-24z" />
                <path d="m31 29h20v2h-20z" />
                <path d="m53 29h2v2h-2z" />
            </g>
            <path d="m57 42-3-3-4 4-4-4-3 3 4 4-4 4 3 3 4-4 4 4 3-3-4-4z" fill="#f2f2f2" />
        </svg>
    );
}

export default React.memo(PaymentCancelSVG);
