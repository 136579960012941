import * as types from './types';
import {
    createClientApi,
    deleteClientApi,
    updateClientApi,
    uploadAvatarApi,
    searchClientApi,
    getClientBookingsApi
} from 'services/client';
import { enqueueSnackbar } from 'notifier/actions';
import { formatQueriesConditions } from 'utils/queryParams';

export function uploadAvatar({ clientId, file, successCallback, errorCallback }) {
    return async function (dispatch) {
        try {
            const client = await uploadAvatarApi({ file, clientId });

            dispatch(_uploadAvatarActionSuccess({ avatar: client?.avatar, clientId }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[uploadUserAvatar] ${message}`,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

function _uploadAvatarActionSuccess({ avatar, clientId }) {
    return {
        type: types.UPDATE_CLIENT_AVATAR,
        payload: {
            avatar,
            clientId
        }
    };
}

export function uploadAvatarFailed({ error = '' }) {
    return async function (dispatch) {
        dispatch(
            enqueueSnackbar({
                message: `[uploadAvatarFailed]: ${error}`,
                type: 'info'
            })
        );
    };
}

export function fetchClients({ searchCondition, page, limit, errorCallback, successCallback }) {
    return async function (dispatch) {
        dispatch(_fetchClientsAction());

        try {
            const query = formatQueriesConditions(searchCondition);
            const response = await searchClientApi({ query, page, limit, isIgnoreBlockClient: false });
            dispatch(_fetchClientsActionSuccess({ clients: response?.items, total: response?.meta?.totalItems }));
            successCallback && successCallback(response?.meta?.totalPages);
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[Fetch clients failed] ${message}`,
                    type: 'info'
                })
            );

            dispatch(_fetchClientsActionFailed(message));
            errorCallback && errorCallback(message);
        }
    };
}

function _fetchClientsAction() {
    return {
        type: types.FETCH_CLIENTS
    };
}

function _fetchClientsActionFailed(error) {
    return {
        type: types.FETCH_CLIENTS_FAILED,
        payload: {
            error
        }
    };
}

function _fetchClientsActionSuccess({ clients, total }) {
    return {
        type: types.FETCH_CLIENTS_SUCCESS,
        payload: {
            clients,
            total
        }
    };
}

export function createClient({ body, errorCallback, successCallback }) {
    return async function (dispatch) {
        try {
            const client = await createClientApi({ body });
            dispatch(_createClientSuccess({ client }));
            successCallback && successCallback({ client });
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[createClient] ${message}`,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

function _createClientSuccess({ client }) {
    return {
        type: types.CREATE_CLIENT_SUCCESS,
        payload: {
            client
        }
    };
}

export function updateClient({ clientId, body, errorCallback, successCallback }) {
    return async function (dispatch) {
        try {
            const updatedClient = await updateClientApi({ clientId, body });
            dispatch(_updateClientSuccess({ clientId, client: updatedClient }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[updateClient] ${message}`,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

function _updateClientSuccess({ clientId, client }) {
    return {
        type: types.UPDATE_CLIENT_SUCCESS,
        payload: {
            client,
            clientId
        }
    };
}

export function deleteClient({ clientId, errorCallback, successCallback }) {
    return async function (dispatch) {
        dispatch(_deleteClient({ clientId }));
        try {
            await deleteClientApi({ clientId });
            dispatch(_deleteClientSuccess({ clientId }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[deleteClient] ${message}`,
                    type: 'info'
                })
            );
            dispatch(_deleteClientFailed({ clientId }));
            errorCallback && errorCallback(message);
        }
    };
}

function _deleteClient({ clientId }) {
    return {
        type: types.DELETE_CLIENT,
        payload: {
            clientId
        }
    };
}

function _deleteClientSuccess({ clientId }) {
    return {
        type: types.DELETE_CLIENT_SUCCESS,
        payload: {
            clientId
        }
    };
}

function _deleteClientFailed({ clientId }) {
    return {
        type: types.DELETE_CLIENT_FAILED,
        payload: {
            clientId
        }
    };
}

export function fetchClientBookings({ clientId, successCallback, errorCallback }) {
    return async function (dispatch) {
        dispatch(_fetchClientBookings({ clientId }));

        try {
            const response = await getClientBookingsApi(clientId);
            dispatch(_fetchClientBookingsSuccess({ bookings: response?.bookings, client: response, clientId }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[fetchClientBookings] ${message}`,
                    type: 'info'
                })
            );

            dispatch(_fetchClientBookingsFailed({ clientId, error: message }));
            errorCallback && errorCallback(message);
        }
    };
}

function _fetchClientBookings({ clientId }) {
    return {
        type: types.FETCH_CLIENT_BOOKINGS,
        payload: {
            clientId
        }
    };
}

function _fetchClientBookingsSuccess({ clientId, bookings, client }) {
    return {
        type: types.FETCH_CLIENT_BOOKINGS_SUCCESS,
        payload: {
            clientId,
            bookings,
            client
        }
    };
}

function _fetchClientBookingsFailed({ clientId, error }) {
    return {
        type: types.FETCH_CLIENT_BOOKINGS_FAILED,
        payload: {
            clientId,
            error
        }
    };
}
