import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, CircularProgress, Tab, Tabs } from '@material-ui/core';
import TabPanel from 'components/tabPanel';
import Button from 'components/button/Base';
import useMediaQueries from 'hooks/useMediaQueries';
import { useFormik } from 'formik';
import { string, object } from 'yup';
import { useDispatch } from 'react-redux';
import * as clientActions from '../actions';
import { cleanEmptyFieldsInObj } from 'utils/object';
import { useTranslation } from 'react-i18next';
import InputBase from 'components/input/Form';
import PersonalInfo from './PersonalInfo';
import Address from './Address';
import PhoneInput from 'components/input/Phone';
import { addPlus, removePlus } from 'utils/phone';
import DialogActions from 'components/dialog/Action';
import DialogTitle from 'components/dialog/Title';
import DialogContent from 'components/dialog/Content';
import IOSSwitch from 'components/switch/IOS';
import AddIcon from '@material-ui/icons/AddCircle';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import { FRIENDLY_DATE_TIME_FORMAT, getAppendMerchantTimezoneToDate, getDatetimeFormat } from 'utils/timing';
import moment from 'moment-timezone';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 1024,
        margin: '0 auto',
        paddingTop: 30
    },
    fullWidth: {
        width: '100%',
        marginTop: 30
    },
    tabTitle: {
        color: theme.colors.primaryText
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    btnSubmit: {
        position: 'relative'
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -6,
        marginLeft: -6
    },
    whiteColor: {
        color: theme.palette.common.white
    }
}));

const ClientSchema = object({
    firstName: string().required('This filed is required'),
    phone: string().required('This filed is required'),
    lastName: string(),
    email: string().email('invalid email'),
    note: string(),
    address: string(),
    suburd: string(),
    city: string(),
    cityState: string(),
    zipCode: string()
});

function AddMember({ isEdit, editedClient, handleClose }) {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [tabIndex, setTabIndex] = React.useState(0);
    const { isMobile } = useMediaQueries();

    const [gender, setGender] = React.useState(0);
    const [birthDay, setBirthDay] = React.useState(
        isEdit
            ? editedClient?.dateOfBirth
                ? new Date(getAppendMerchantTimezoneToDate(editedClient?.dateOfBirth))
                : null
            : null
    );

    const [startBirthDatePresent, setStartBirthDatePresent] = React.useState(
        isEdit
            ? editedClient?.dateOfBirth
                ? moment(new Date(getAppendMerchantTimezoneToDate(editedClient?.dateOfBirth))).format(
                      FRIENDLY_DATE_TIME_FORMAT
                  )
                : ''
            : ''
    );

    const formik = useFormik({
        initialValues: {
            firstName: isEdit ? editedClient?.firstName || '' : '',
            lastName: isEdit ? editedClient?.lastName || '' : '',
            note: isEdit ? editedClient?.note || '' : '',
            email: isEdit ? editedClient?.email || '' : '',
            address: isEdit ? editedClient?.address || '' : '',
            phone: isEdit ? addPlus(editedClient?.user?.phone) : '',
            // suburd: isEdit ? editedClient?.suburd || '' : '',
            city: isEdit ? editedClient?.city || '' : '',
            cityState: isEdit ? editedClient?.cityState || '' : '',
            postCode: isEdit ? editedClient?.postCode || '' : '',
            block: isEdit ? editedClient?.block : false
        },
        validationSchema: ClientSchema,
        onSubmit: (values, { setSubmitting, setStatus }) => {
            setStatus({ error: null });
            setSubmitting(true);

            let extraObject = {
                phone: removePlus(values.phone),
                gender: +gender
            };

            if (startBirthDatePresent && birthDay) {
                extraObject = {
                    ...extraObject,
                    dateOfBirth: getDatetimeFormat(birthDay)
                };
            }

            if (!isEdit) {
                const createBodyCombined = {
                    ...values,
                    ...extraObject
                };

                dispatch(
                    clientActions.createClient({
                        body: cleanEmptyFieldsInObj(createBodyCombined),
                        successCallback: () => {
                            handleClose && handleClose();
                        },
                        errorCallback: (error) => {
                            setSubmitting(false);
                        }
                    })
                );
            } else {
                const bodyUpdate = {
                    ...editedClient,
                    ...values,
                    ...extraObject
                };
                delete bodyUpdate?.clientId;
                delete bodyUpdate?.user;
                delete bodyUpdate?.merchantId;

                if (!bodyUpdate.email) {
                    delete bodyUpdate.email;
                }

                dispatch(
                    clientActions.updateClient({
                        clientId: editedClient?.id,
                        body: bodyUpdate,
                        successCallback: () => {
                            handleClose && handleClose();
                        },
                        errorCallback: (error) => {
                            setSubmitting(false);
                        }
                    })
                );
            }

            setStatus({ error: null });
        }
    });

    const handleGenderChange = (gender) => {
        setGender(gender);
    };

    const handleDateOfBirthChange = (date) => {
        setBirthDay(date);
        setStartBirthDatePresent(moment(date).format(`dddd, DD MMMM YYYY`));
    };

    const { handleSubmit, isSubmitting, isValid, values, errors, touched, handleChange, handleBlur, setFieldValue } =
        formik;

    const tabs = [
        {
            component: (
                <PersonalInfo
                    birthDay={birthDay}
                    gender={gender}
                    startBirthDatePresent={startBirthDatePresent}
                    {...formik}
                    handleDateOfBirthChange={handleDateOfBirthChange}
                    handleGenderChange={handleGenderChange}
                />
            ),
            index: 0,
            title: t(`persional_info`)
        },
        {
            component: <Address {...formik} />,
            index: 1,
            title: t(`address`)
        }
    ];

    const handlePhoneChange = (value) => {
        setFieldValue('phone', value);
    };

    const handleBlockChange = (e) => {
        setFieldValue(e?.target?.name, e?.target?.checked);
    };

    return (
        <form onSubmit={handleSubmit} className={classes.form}>
            <DialogTitle>
                <Typography className={classes.whiteColor} variant="h5">
                    {isEdit ? `Update` : `New`} client
                </Typography>
            </DialogTitle>
            <DialogContent>
                {/* <SimpleBar style={{ maxHeight: '100%' }}> */}
                <Grid container wrap="nowrap" alignItems="center" direction="column" className={classes.root}>
                    <Grid item className={classes.fullWidth}>
                        <Grid container direction="row" spacing={5}>
                            <Grid item xs={12} md={6} container direction="column" spacing={4} wrap="nowrap">
                                <Grid item>
                                    <Grid container direction="row" spacing={3}>
                                        <Grid item xs={6}>
                                            <InputBase
                                                label={t(`first_name`)}
                                                placeholder={t(`first_name_placeholder`)}
                                                name="firstName"
                                                value={values.firstName || ''}
                                                disabled={isSubmitting}
                                                onChange={(e) => {
                                                    e.target.value = e.target.value.replace(/[^A-Z_0-9]/gi, '');
                                                    handleChange(e);
                                                }}
                                                onBlur={handleBlur}
                                                error={errors.firstName && touched.firstName}
                                                errorText={errors.firstName}
                                                autoFocus={true}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <InputBase
                                                label={t(`last_name`)}
                                                placeholder={t(`last_name_placeholder`)}
                                                name="lastName"
                                                value={values.lastName || ''}
                                                disabled={isSubmitting}
                                                onChange={(e) => {
                                                    e.target.value = e.target.value.replace(/[^A-Z_0-9]/gi, '');
                                                    handleChange(e);
                                                }}
                                                onBlur={handleBlur}
                                                error={errors.lastName && touched.lastName}
                                                errorText={errors.lastName}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <PhoneInput
                                        label={
                                            <>
                                                <Typography variant="body1" display="inline">
                                                    {t('phone_number')}
                                                </Typography>{' '}
                                                <Typography
                                                    display="inline"
                                                    variant="body2"
                                                    style={{ color: theme.colors.red }}
                                                >
                                                    (Required)
                                                </Typography>
                                            </>
                                        }
                                        placeholder={`(471) 213 - 1312`}
                                        disabled={isSubmitting}
                                        onChange={handlePhoneChange}
                                        defaultValue={values.phone}
                                    />
                                </Grid>
                                <Grid item>
                                    <InputBase
                                        label={t(`email`)}
                                        placeholder={t(`email_placeholder`)}
                                        name="email"
                                        value={values.email || ''}
                                        disabled={isSubmitting}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={errors.email && touched.email}
                                        errorText={errors.email}
                                    />
                                </Grid>
                                <Grid item>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item>
                                            <IOSSwitch
                                                checked={values.block}
                                                onChange={handleBlockChange}
                                                name="block"
                                                color="secondary"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.bold} variant="body2">
                                                BLOCK CLIENT
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6} container direction="column" spacing={4} wrap="nowrap">
                                <Grid item>
                                    <Tabs
                                        value={tabIndex}
                                        onChange={(e, index) => setTabIndex(index)}
                                        indicatorColor="primary"
                                    >
                                        {tabs.map((tab) => (
                                            <Tab key={tab.index} textColor="inherit" label={tab?.title} />
                                        ))}
                                    </Tabs>
                                </Grid>
                                <Grid item>
                                    {tabs.map((tab) => {
                                        return (
                                            <TabPanel key={tab?.index} value={tabIndex} index={tab?.index}>
                                                {tab.component}
                                            </TabPanel>
                                        );
                                    })}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {/* </SimpleBar> */}
            </DialogContent>
            <DialogActions>
                <Button startIcon={<CloseIcon />} onClick={handleClose} fullWidth={isMobile} variant="outlined">
                    {t(`close`)}
                </Button>
                <div className={classes.btnSubmit} style={{ width: isMobile ? '100%' : '' }}>
                    <Button
                        disabled={isSubmitting || !isValid}
                        fullWidth={isMobile}
                        variant="contained"
                        color="primary"
                        type="submit"
                        startIcon={isEdit ? <SaveIcon /> : <AddIcon />}
                    >
                        {isEdit ? t(`save`) : t(`create`)}
                    </Button>
                    {isSubmitting && <CircularProgress size={12} className={classes.buttonProgress} />}
                </div>
            </DialogActions>
        </form>
    );
}

export default React.memo(AddMember);
