export const FETCH_SERVICES = '[BOOKING]FETCH_SERVICES';
export const FETCH_SERVICES_FAILED = '[BOOKING]FETCH_SERVICES_FAILED';
export const FETCH_SERVICES_SUCCESS = '[BOOKING]FETCH_SERVICES_SUCCESS';

export const FETCH_STAFF = '[BOOKING]FETCH_STAFF';
export const FETCH_STAFF_FAILED = '[BOOKING]FETCH_STAFF_FAILED';
export const FETCH_STAFF_SUCCESS = '[BOOKING]FETCH_STAFF_SUCCESS';

export const INITIAL_BOOKING = '[BOOKING]INITIAL_BOOKING';
export const INITIAL_BOOKING_SUCCESS = '[BOOKING]INITIAL_BOOKING_SUCCESS';
export const INITIAL_BOOKING_FAILED = '[BOOKING]INITIAL_BOOKING_FAILED';

export const ADD_SERVICE = '[BOOKING]ADD_SERVICE';
export const ADD_STAFF = '[BOOKING]ADD_STAFF';
export const ADD_DATE = '[BOOKING]ADD_DATE';
export const ADD_TIME = '[BOOKING]ADD_TIME';
export const GO_NEXT_STEP = '[BOOKING]GO_NEXT_STEP';
export const GO_PREV_STEP = '[BOOKING]GO_PREV_STEP';
export const SET_CURRENT_DATE_INDEX = '[BOOKING]SET_CURRENT_DATE_INDEX';
export const SET_CURRENT_CATEGORY_INDEX = '[BOOKING]SET_CURRENT_CATEGORY_INDEX';

export const FETCH_TIME_SLOTS = '[BOOKING]FETCH_TIME_SLOTS';
export const FETCH_TIME_SLOTS_SUCCESS = '[BOOKING]FETCH_TIME_SLOTS_SUCCESS';
export const FETCH_TIME_SLOTS_FAILED = '[BOOKING]FETCH_TIME_SLOTS_FAILED';

export const BOOKING_ORDER = '[BOOKING]BOOKING_ORDER';
export const BOOKING_ORDER_FAILED = '[BOOKING]BOOKING_ORDER_FAILED';
export const BOOKING_ORDER_SUCCESS = '[BOOKING]BOOKING_ORDER_SUCCESS';

export const BOOKING_VERIFY_OTP = '[BOOKING]BOOKING_VERIFY_OTP';
export const BOOKING_VERIFY_OTP_FAILED = '[BOOKING]BOOKING_VERIFY_OTP_FAILED';
export const BOOKING_VERIFY_OTP_SUCCESS = '[BOOKING]BOOKING_VERIFY_OTP_SUCCESS';

export const RESET_ORDER = '[BOOKING]RESET_ORDER';
export const GO_BACK_TO_CHOOSING_STAFF = '[BOOKING]GO_BACK_TO_CHOOSING_STAFF';

export const FETCH_INCOMING_BOOKINGS = `[BOOKINGS]FETCH_INCOMING_BOOKINGS`;

export const FETCH_CHECKING_DATA_SUCCESS = `[BOOKING]FETCH_CHECKING_DATA_SUCCESS`;

export const SET_BOOKING_CONFLICTED = `[BOOKING]SET_BOOKING_CONFLICTED`;
