import request from './request';
import { encodeURL } from 'utils/queryParams';
import { PAGINATION_PAGE_DEFAULT, PAGINATION_LIMIT_DEFAULT } from 'const';
import isEmpty from 'lodash/isEmpty';

const scheduleApis = {
    schedules: `/sms-schedules`,
    schedule: (id) => `/sms-schedules/${id}`
};

export async function fetchSmsSchedulesApi({
    query,
    page = PAGINATION_PAGE_DEFAULT,
    limit = PAGINATION_LIMIT_DEFAULT
}) {
    let extraUrl = `${scheduleApis.schedules}?page=${page}&limit=${limit}`;

    if (!isEmpty(query)) {
        extraUrl += `&query=${encodeURL(query)}`;
    }

    const response = await request.get(extraUrl);
    return response.data;
}

export async function getSmsSchedule({ id }) {
    const response = await request.get(`${scheduleApis.schedule(id)}`);
    return response.data;
}

export async function createSmsScheduleApi({ body }) {
    const response = await request.post(`${scheduleApis.schedules}`, body);
    return response.data;
}

export async function updateSmsScheduleApi({ id, body }) {
    const response = await request.put(`${scheduleApis.schedule(id)}`, body);
    return response.data;
}

export async function deleteSmsScheduleApi({ id }) {
    const response = await request.delete(`${scheduleApis.schedule(id)}`);
    return response.data;
}
