import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Grid, TextField, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getFullName } from 'utils/naming';
import Avatar from 'components/avatar';
import { getImageUrl } from 'utils/image';
import AvatarIconSVG from 'assets/images/svg/AvatarIconSVG';
import BlockRounded from '@material-ui/icons/BlockRounded';
import PhoneText from 'components/phoneFormat/Text';
import { useSnackbar } from 'notistack';
import { getClientsApi } from 'services/client';
import { addPlus, isValidPhone, removePlus } from 'utils/phone';
import { INPUT_HEIGHT } from 'const/style';
import { phoneCountryCodes } from 'data/phoneNumber';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import NativeSelect from 'components/select/NativeOption';
import { useHidePhone } from 'hooks/app';
// import Visibility from '@material-ui/icons/Visibility';
// import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles((theme) => ({
    root: {},
    input: {
        marginTop: `8px`,
        border: 'none',
        backgroundColor: theme.palette.common.white,
        '& > div': {
            height: INPUT_HEIGHT,
            paddingRight: `30px !important`
        },
        '& input': {
            position: 'relative',
            fontSize: 14,
            fontWeight: 'normal',
            width: '100%',
            height: '100%',
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            color: 'black'
        },
        '& .MuiInput-underline::before': {
            borderColor: theme.colors.border
        },
        '& .MuiInput-underline::after': {
            borderBottom: (props) =>
                `1px solid ${props?.error ? theme.palette.secondary.main : theme.palette.primary.main}`
        }
    },
    expand: {
        flex: 1
    },
    select: {
        paddingBottom: 6,
        borderRadius: 0,
        borderBottom: `1px solid ${theme.colors.border}`
    }
}));

const phoneOptions = phoneCountryCodes.map((phone) => ({
    ...phone,
    label: phone.dial_code,
    value: phone.dial_code
}));

function _findPrefix(phone) {
    if (!isValidPhone(phone)) return phoneOptions?.[0]?.value;

    const phoneNumber = parsePhoneNumberFromString(addPlus(phone));
    const countryCode = phoneNumber?.countryCallingCode;

    if (countryCode && phoneOptions?.findIndex((option) => option?.value === addPlus(countryCode)) !== -1) {
        return addPlus(countryCode);
    } else {
        return phoneOptions?.[0]?.value;
    }
}

function _findExtraPhone(phone) {
    const _prefix = _findPrefix(phone);
    const _prefixRemovedPlus = removePlus(_prefix);

    return phone?.slice(_prefixRemovedPlus?.length);
}

function ClientInput({ isEdit, booking, onChange, onPhoneChange, ...rest }) {
    const [options, setOptions] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [search, setSearch] = React.useState('');
    const [isLoading, setLoading] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [selectedClient, setSelectedClient] = React.useState(null);
    const pasteRef = React.useRef(false);
    const timerRef = React.useRef();
    const hidePhone = useHidePhone();
    // const [showPass, setShowPass] = useState(!hidePhone);

    const defaultPrefix = React.useMemo(() => {
        if (!isEdit) return phoneOptions?.[0]?.value;
        const value = booking?.client?.phone;
        if (!isValidPhone(value)) return phoneOptions?.[0]?.value;

        const phoneNumber = parsePhoneNumberFromString(addPlus(value));
        const countryCode = phoneNumber?.countryCallingCode;

        if (countryCode && phoneOptions?.findIndex((option) => option?.value === addPlus(countryCode)) !== -1) {
            return addPlus(countryCode);
        } else {
            return phoneOptions?.[0]?.value;
        }
    }, [booking, isEdit]);

    const [prefix, setPrefix] = React.useState(defaultPrefix);

    const isValid = React.useMemo(() => {
        return !!selectedClient && isValidPhone(addPlus(`${prefix}${search}`));
    }, [selectedClient, search, prefix]);

    const classes = useStyles({ error: !isValid });

    const handlePaste = React.useCallback(
        (e) => {
            e.stopPropagation();
            const text = e.clipboardData.getData('Text');
            if (!text) return;
            pasteRef.current = true;
            const newText = text?.replace(/[^a-zA-Z0-9]/gi, '');
            setSearch(newText);
            onPhoneChange({
                prefix,
                extraPhone: newText
            });
        },
        [prefix, onPhoneChange]
    );

    const handlePrefixChange = React.useCallback(
        (option) => {
            setPrefix(option?.value);
            setSearch('');
            onPhoneChange({
                prefix: option?.value,
                extraPhone: ``
            });
        },
        [onPhoneChange]
    );

    const _fetchClients = React.useCallback(async () => {
        setLoading(true);
        try {
            const res = await getClientsApi({ page: 1, limit: 15, search, isPhoneNotNull: true });
            setOptions(res.items);
            setLoading(false);
        } catch ({ message }) {
            setLoading(false);
            enqueueSnackbar(message, {
                variant: 'info'
            });
        }
    }, [enqueueSnackbar, setLoading, search]);

    React.useEffect(() => {
        if (isEdit && booking?.client && booking?.client?.phone) {
            const phone = booking?.client?.phone;
            const extraPhone = _findExtraPhone(phone);
            setSearch(extraPhone);
            setSelectedClient({
                ...booking?.client,
                user: {
                    phone: booking?.client?.phone
                }
            });
        }
    }, [booking, isEdit]);

    React.useEffect(() => {
        if (timerRef.current) clearTimeout(timerRef.current);

        if (!open) return;

        timerRef.current = setTimeout(async () => {
            _fetchClients();
        }, [500]);

        return () => {
            if (timerRef.current) clearTimeout(timerRef.current);
        };
    }, [search, _fetchClients, open]);

    return (
        <Grid container direction="column" spacing={1}>
            <Grid item>
                <Typography variant="body1">Phone number</Typography>
            </Grid>
            <Grid item>
                <Grid container direction="row" alignItems="flex-end" spacing={1}>
                    <Grid item>
                        <NativeSelect
                            selectedValue={prefix}
                            onChange={handlePrefixChange}
                            options={phoneOptions}
                            noBorder
                            fullHeight
                            className={classes.select}
                        />
                    </Grid>
                    <Grid item className={classes.expand}>
                        <Autocomplete
                            id="asynchronous-demo"
                            open={open}
                            onOpen={() => {
                                setOpen(true);
                            }}
                            onClose={() => {
                                setOpen(false);
                            }}
                            inputValue={search}
                            onPaste={handlePaste}
                            onInputChange={(e) => {
                                if (!pasteRef.current) {
                                    setSearch(e?.target?.value);
                                } else {
                                    pasteRef.current = false;
                                    return;
                                }

                                onPhoneChange({
                                    prefix,
                                    extraPhone: e?.target?.value
                                });
                            }}
                            getOptionLabel={(option) => {
                                const phone = option?.user?.phone;
                                return _findExtraPhone(phone);
                            }}
                            filterOptions={(options) =>
                                options?.filter((option) => {
                                    const fullName = getFullName(option)?.split(' ')?.join('');
                                    return fullName
                                        ?.concat(option?.user?.phone)
                                        ?.concat(option?.email)
                                        ?.toLowerCase()
                                        ?.includes(search?.split(' ')?.join('')?.toLowerCase());
                                })
                            }
                            renderOption={(option) => {
                                return (
                                    <Grid container wrap="nowrap" direction="row" key={option?.id} spacing={1}>
                                        <Grid item>
                                            <div style={{ position: 'relative', opacity: option?.block ? 0.5 : 1 }}>
                                                <Avatar
                                                    active={option?.block}
                                                    sizes="small"
                                                    alt={getImageUrl(option?.avatar)}
                                                    src={getImageUrl(option?.avatar)}
                                                >
                                                    <AvatarIconSVG style={{ width: '100%', height: '100%' }} />
                                                </Avatar>

                                                {option?.block && (
                                                    <div style={{ position: 'absolute', bottom: -10, right: -5 }}>
                                                        <BlockRounded color="error" style={{ width: 15, height: 15 }} />
                                                    </div>
                                                )}
                                            </div>
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction="column">
                                                <Grid item>
                                                    <Typography variant="body1" display="inline">
                                                        {getFullName(option)}
                                                    </Typography>
                                                </Grid>
                                                {option?.email && (
                                                    <Grid item>
                                                        <Typography display="inline" variant="subtitle2">
                                                            ({option?.email})
                                                        </Typography>
                                                    </Grid>
                                                )}
                                                <Grid item>
                                                    <Grid
                                                        style={{ pointerEvents: 'none' }}
                                                        container
                                                        direction="row"
                                                        spacing={1}
                                                    >
                                                        <Grid item>
                                                            <PhoneText phone={option?.user?.phone} />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                );
                            }}
                            closeIcon={() => null}
                            options={options}
                            loading={isLoading}
                            onChange={(e, v) => {
                                const phone = v?.user?.phone;
                                const _prefix = _findPrefix(phone);
                                const extraPhone = _findExtraPhone(phone);
                                setSelectedClient(v);
                                setSearch(extraPhone);
                                onChange(v);
                                setPrefix(_prefix);

                                onPhoneChange({ prefix: _prefix, extraPhone });
                            }}
                            renderInput={(params) => {
                                return (
                                    <Grid container direction="column">
                                        <Grid item>
                                            <TextField
                                                {...params}
                                                className={classes.input}
                                                autoFocus={!isEdit}
                                                error={!isValid}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {isLoading ? (
                                                                <CircularProgress color="inherit" size={20} />
                                                            ) : null}
                                                            {/* {hidePhone && (
                                                                <div
                                                                    style={{ cursor: 'pointer' }}
                                                                    onClick={() => setShowPass(!showPass)}
                                                                >
                                                                    {showPass ? <VisibilityOff /> : <Visibility />}
                                                                </div>
                                                            )} */}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    )
                                                }}
                                                placeholder="Search client name or phone"
                                                size="small"
                                                type={hidePhone ? 'password' : 'text'}
                                                // type={'text'}
                                                variant="standard"
                                            />
                                        </Grid>
                                    </Grid>
                                );
                            }}
                            {...rest}
                        />
                    </Grid>
                </Grid>
            </Grid>
            {/* {selectedClient && (
                <Grid item style={{ marginTop: theme.spacing(1) }}>
                    <Grid container direction="row" alignItems="center" spacing={2}>
                        <Grid item>
                            <Typography variant="body1">Phone</Typography>
                        </Grid>
                        <Grid item>
                            <Grid container direction="row" alignItems="center">
                                <Grid item>
                                    <PhoneText phone={selectedClient?.user?.phone} />
                                </Grid>
                                <Grid item>
                                    <IconButton onClick={handleCopy} size="small">
                                        <FileCopy fontSize="small" />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )} */}
        </Grid>
    );
}

export default ClientInput;
