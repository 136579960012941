import { useSelector } from 'react-redux';
import orderBy from 'lodash/orderBy';
import { convertServicesMapping } from 'utils/app/service';

export function useServices() {
    return useSelector((state) => state.service?.serviceList);
}

export function useServiceList() {
    return useSelector((state) => state.service?.serviceList?.filter((sv) => !sv.isDeleted));
}

export function useCategoryList() {
    return useSelector((state) => state.service?.categoryList);
}

export function useCategories() {
    const categoryList = useCategoryList();

    return orderBy(
        categoryList?.filter((ct) => !ct.isDeleted),
        ['orderIndex'],
        ['desc']
    );
}

export function useCategoriesMapping() {
    const categoryList = useCategoryList();

    return categoryList?.reduce((obj, category) => {
        obj[category?.id] = category;
        return obj;
    }, {});
}

export function useIsFetchingService() {
    return useSelector((state) => state.service?.isFetchingServices);
}

export function useIsFetchingCategories() {
    return useSelector((state) => state.service?.isFetchingCategories);
}

export function useServiceOptions() {
    return useSelector((state) => {
        const serviceList = state.service?.serviceList?.filter((sv) => !sv.isDeleted);
        return serviceList?.map((service) => {
            const categoryName = service?.category?.title;
            const categoryIndex = service?.category?.orderIndex;
            const price = service?.prices?.[0]?.retailPrice;

            return {
                ...service,
                value: service?.id,
                label: `${service.title} ($${price})`,
                categoryName: categoryName,
                categoryIndex: categoryIndex
            };
        });
    });
}

export function useServiceCategoryList() {
    return useSelector((state) => {
        const serviceList = state.service?.serviceList;
        const categoryList = state.service?.categoryList;
        return {
            serviceList,
            categoryList
        };
    });
}

export function useServicesMapping() {
    const services = useServices();
    return convertServicesMapping(services);
}

export function useServiceDetailById(serviceId) {
    const servicesMapping = useServicesMapping();

    return servicesMapping?.[+serviceId];
}

export function useServicesByCategoryId(selectedCategoryId) {
    const categoryList = useCategoryList();

    const categories = orderBy(
        categoryList?.filter((ct) => !ct.isDeleted),
        ['orderIndex'],
        ['desc']
    );

    if (!selectedCategoryId) return [];

    const services = categories?.reduce((obj, category) => {
        obj[category?.id] = category?.services?.filter((s) => !s?.deleted);
        return obj;
    }, {})?.[selectedCategoryId];

    return orderBy(services, ['orderIndex'], ['desc']);
}
