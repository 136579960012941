import React, { useMemo } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Paper } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useLongPress } from 'use-long-press';
import { Resizable } from 're-resizable';
import * as calendarActions from 'views/calender/actions';
import { convertTimeWithDurationToTime, convertTimeToNumRange } from 'utils/timing';
import Booking from './Booking';
import BlockedTime from './BlockedTime';
import { useDragBkSv, useIsDragging, useSnapY } from 'hooks/calendar';
import { convertRatio } from 'utils/app/calendar';
import store from 'store/configStore';
import { MERCHANT_PERMISSIONS, ROW_HEIGHT } from 'const';
import { useStaffById } from 'hooks/staff';
import { isTouchDevice } from 'utils/device';
// import { isTablet } from 'utils/device';

const useStyles = makeStyles((theme) => ({
    root: {},
    hoverOnTop: {
        '&:hover': {
            zIndex: 99999,
            boxShadow: theme.shadows[2]
        }
    }
}));

function BookingResizable({
    isMobile,
    isBlockedTime,
    style,
    staffId,
    bkSvId,
    onRefreshStaffBlockedTimes,
    onReBooking,
    onReSchedule,
    onOpenBooking,
    onCloseBooking,
    findClientId,
    order
}) {
    const theme = useTheme();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [height, setHeight] = React.useState(style.height);
    const ref = React.useRef();
    const [dimension, setDimension] = React.useState({ width: 0, height });
    const [isResizing, setIsResizing] = React.useState(false);
    const SNAP_Y = useSnapY();
    const resizeRef = React.useRef();

    const isDisabledDragDrop = useMemo(() => {
        return isTouchDevice();
    }, []);

    const bind = useLongPress(
        () => {
            const isOpenBooking = store.getState()?.calendar?.isOpenBooking;

            if (isOpenBooking) return;
            dispatch(calendarActions.setDragBkSv(order));
            dispatch(calendarActions.setIsDragging(true));

            const $bkSvs = document.querySelectorAll('[id^="drag_bkSv_"]');

            for (const $item of $bkSvs) {
                $item.style.pointerEvents = 'none';
            }
        },
        {
            threshold: 400,
            captureEvent: true,
            detect: 'both'
        }
    );

    const bindOptions = useMemo(() => {
        if (isDisabledDragDrop) return {};
        return bind;
    }, [bind, isDisabledDragDrop]);

    React.useEffect(() => {
        setHeight(style.height);
        console.log('resizeRef', resizeRef.current);
    }, [style.height]);

    React.useLayoutEffect(() => {
        setDimension(ref.current.getBoundingClientRect());
    }, []);

    return (
        <>
            <Paper
                ref={ref}
                id={`drag_bkSv_${order?.id}`}
                style={{
                    ...style,
                    height: height,
                    position: 'absolute',
                    left: 0,
                    background: theme.palette.common.white,
                    // overflow: 'hidden',
                    color: theme.palette.common.white,
                    borderRadius: 0,
                    cursor: 'pointer',
                    zIndex: isResizing ? 1000 : 3,
                    padding: 1
                    // pointerEvents: isDragging ? 'none' : ''
                    // paddingRight: 2,
                    // paddingTop: 2,
                    // paddingBottom: 2
                }}
                className={`${classes.hoverOnTop}`}
                elevation={0}
            >
                <Resizable
                    ref={resizeRef}
                    enable={{
                        top: false,
                        right: false,
                        bottom: true,
                        left: false,
                        topRight: false,
                        bottomRight: false,
                        bottomLeft: false,
                        topLeft: false
                    }}
                    style={{ width: '100% !important', height }}
                    onResizeStop={(e, direction, ref, d) => {
                        setIsResizing(false);
                        const resizedHeight = Number(ref?.style?.height?.split('px')?.[0]);
                        if (resizedHeight) {
                            setHeight(resizedHeight);
                            const state = store?.getState();
                            const RATIO = convertRatio({
                                zoom: state?.calendar?.zoom,
                                height: ROW_HEIGHT
                            });

                            const newDuration = resizedHeight / RATIO;
                            if (newDuration === order.duration) return;

                            if (!isBlockedTime) {
                                const isHasResizeMoveBookingConfirmPermission = state.auth?.user?.permissions?.includes(
                                    MERCHANT_PERMISSIONS.RESIZE_MOVE_CONFIRMED
                                );

                                if (isHasResizeMoveBookingConfirmPermission) {
                                    let confirm = window.confirm('Are you sure you want to update this booking?');

                                    if (!confirm) {
                                        setHeight(order.duration * RATIO);
                                        resizeRef.current.updateSize({
                                            height: order.duration * RATIO
                                        });
                                        return;
                                    }
                                }

                                const editBody = {
                                    title: order?.booking?.title,
                                    startDate: order?.booking?.startDate,
                                    phone: order?.booking?.client?.phone,
                                    state: 'ClientConfirmed',
                                    startTime: order?.booking?.startTime,
                                    isAllowResize: true,
                                    bookingServices: order?.booking?.bookingServices?.map((sv) => {
                                        if (Number(sv.id) === Number(order.id)) {
                                            return {
                                                ...sv,
                                                serviceId: Number(sv.serviceId),
                                                staffId: Number(sv.staffId),
                                                startTime: sv.startTime,
                                                duration: newDuration
                                            };
                                        }
                                        return {
                                            ...sv,
                                            serviceId: Number(sv.serviceId),
                                            staffId: Number(sv.staffId),
                                            startTime: sv.startTime,
                                            duration: sv.duration
                                        };
                                    })
                                };

                                dispatch(
                                    calendarActions.updateBookingByResize({
                                        orderServiceId: +order?.id,
                                        bkSvIds: order?.booking?.bookingServices?.map((bkSv) => +bkSv?.id),
                                        staffId: +order?.staffId,
                                        oldDuration: order.duration,
                                        newDuration: newDuration,
                                        body: editBody,
                                        bookingId: +order?.bookingId,
                                        successCallback: () => {
                                            console.log('resize success');
                                        },
                                        errorCallback: () => {
                                            console.log('resize failed');
                                        }
                                    })
                                );
                            } else {
                                const newTimeEnd = convertTimeWithDurationToTime(order?.slot?.timeStart, newDuration);

                                const editBody = {
                                    dateBlocked: order?.dateBlocked,
                                    staffId: Number(order?.staffId),
                                    description: order?.description,
                                    slot: convertTimeToNumRange({
                                        startTime: order?.slot?.timeStart,
                                        endTime: newTimeEnd
                                    })
                                };

                                dispatch(
                                    calendarActions.updateBlockedTimeByResize({
                                        id: order?.id,
                                        body: editBody,
                                        staffId: order?.staffId,
                                        newTimeEnd,
                                        oldTimeEnd: order?.slot?.timeEnd,
                                        successCallback: () => {
                                            console.log('resize success');
                                        },
                                        errorCallback: () => {
                                            console.log('resize failed');
                                        }
                                    })
                                );
                            }
                        }
                    }}
                    onResize={(e, direction, ref, d) => {
                        setIsResizing(true);
                        if (+ref?.style?.height?.split('px')?.[0]) {
                            setHeight(+ref?.style?.height?.split('px')?.[0]);
                        }
                    }}
                    snap={{
                        y: SNAP_Y,
                        x: []
                    }}
                >
                    <Grid {...bindOptions} container style={{ width: '100%', height }}>
                        {!isBlockedTime && (
                            <Booking
                                isMobile={isMobile}
                                height={height}
                                staffId={staffId}
                                order={order}
                                onReSchedule={onReSchedule}
                                onReBooking={onReBooking}
                                // isDragHighlight={isDraggableEnable}
                                onOpenBooking={onOpenBooking}
                                onCloseBooking={onCloseBooking}
                                findClientId={findClientId}
                            />
                        )}
                        {isBlockedTime && (
                            <BlockedTime
                                isMobile={isMobile}
                                height={height}
                                order={order}
                                onUpdateOrCreatingSuccess={onRefreshStaffBlockedTimes}
                            />
                        )}
                    </Grid>
                </Resizable>
            </Paper>
            <DragPreview
                isMobile={isMobile}
                isBlockedTime={isBlockedTime}
                dimension={dimension}
                order={order}
                height={height}
                staffId={staffId}
                onReSchedule={onReSchedule}
                onReBooking={onReBooking}
            />
        </>
    );
}

function DragPreview({
    order,
    isBlockedTime,
    dimension,
    height,
    isMobile,
    staffId,
    onReSchedule,
    isDisabledWhenMovingBooking,
    onReBooking
}) {
    const dragBkSv = useDragBkSv();
    const isDragging = useIsDragging();
    const staff = useStaffById(staffId);

    const isDraggableEnable = React.useMemo(() => {
        return +dragBkSv?.id === +order?.id;
    }, [dragBkSv, order]);

    if (!isDraggableEnable) return null;

    return (
        <Grid
            id={`booking_${order?.id}`}
            container
            style={{
                width: dimension.width,
                height,
                position: 'fixed',
                zIndex: 1000,
                pointerEvents: isDragging ? 'none' : ''
            }}
        >
            {!isBlockedTime && (
                <Booking
                    isMobile={isMobile}
                    height={height}
                    staff={staff}
                    order={order}
                    onReSchedule={onReSchedule}
                    onReBooking={onReBooking}
                    isDisabledWhenMovingBooking={isDisabledWhenMovingBooking}
                    isDisabledTooltip={true}
                />
            )}
        </Grid>
    );
}

export default React.memo(BookingResizable);
