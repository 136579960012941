import React from 'react';
import StaffWorkingHours from './StaffWorkingHours';
import ClosedDates from './closedDates';
import StaffMembers from './StaffMembers';
// import UserPermissions from './UserPermissions';
import LayoutTabsContent from '../layout/LayoutTabsContent';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import DateRangeRounded from '@material-ui/icons/DateRangeRounded';
import People from '@material-ui/icons/People';
// import PermIdentitySharp from '@material-ui/icons/PermIdentitySharp';
import { useTranslation } from 'react-i18next';

function StaffTabs() {
    const { t } = useTranslation();
    const tabs = [
        {
            component: <StaffWorkingHours />,
            index: 0,
            icon: <WorkOutlineIcon />,
            title: t(`staff_working_hours`),
            path: 'staff-working-hours'
        },

        { component: <StaffMembers />, index: 1, icon: <People />, title: t(`staff_members`), path: 'members' },
        {
            component: <ClosedDates />,
            index: 2,
            icon: <DateRangeRounded />,
            title: t(`closed_dates`),
            path: 'closed-dates'
        }
        // {
        //     component: <UserPermissions />,
        //     index: 3,
        //     icon: <PermIdentitySharp />,
        //     title: t(`user_permission`),
        //     path: `permission`
        // }
    ];

    return <LayoutTabsContent tabs={tabs} />;
}

export default React.memo(StaffTabs);
