import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { useClosedDatesFormatDate } from 'hooks/staff';
import { useIsOpenSidebarLeft } from 'hooks/app';
import { useBookings, useSelectedDate, useStaffFilteredIds, useToggleCloseDate } from 'hooks/calendar';
import { getDatetimeFormat } from 'utils/timing';
import isEmpty from 'lodash/isEmpty';
import ClosedDateIllustratorSVG from 'assets/images/svg/ClosedDateIllustratorSVG';
import { INDEX_COLUMN_WIDTH, SIDEBAR_LEFT_CONTENT, SIDEBAR_LEFT_WIDTH, TABLE_HEADER_HEIGHT } from 'const';

const useStyles = makeStyles((theme) => ({
    root: {},
    closedDate: {
        position: 'fixed',
        background: theme.palette.common.white,
        zIndex: theme.zIndex.drawer + 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    closedDateOverlay: {
        left: SIDEBAR_LEFT_WIDTH + INDEX_COLUMN_WIDTH,
        width: `calc(100vw - ${SIDEBAR_LEFT_WIDTH}px - ${INDEX_COLUMN_WIDTH}px)`,
        transition: theme.transitions.create(['width', 'left'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.down('sm')]: {
            left: INDEX_COLUMN_WIDTH,
            width: `calc(100vw  - ${INDEX_COLUMN_WIDTH}px)`
        }
    },
    closedDateOverlayShift: {
        left: SIDEBAR_LEFT_WIDTH + SIDEBAR_LEFT_CONTENT + INDEX_COLUMN_WIDTH,
        width: `calc(100vw - ${SIDEBAR_LEFT_WIDTH + SIDEBAR_LEFT_CONTENT}px - ${INDEX_COLUMN_WIDTH}px)`,
        transition: theme.transitions.create(['width', 'left'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    }
}));

function Overlay() {
    const classes = useStyles();
    const closedDatesFormatDate = useClosedDatesFormatDate();
    const isOpenSidebarLeft = useIsOpenSidebarLeft();
    const selectedDate = useSelectedDate();
    const toggleCloseDate = useToggleCloseDate();
    const bookingsByStaff = useBookings();
    const staffFiltered = useStaffFilteredIds();

    const isClosedDate = React.useMemo(() => {
        return Boolean(closedDatesFormatDate.find((clDate) => clDate.date === getDatetimeFormat(selectedDate)));
    }, [closedDatesFormatDate, selectedDate]);

    return (
        <>
            {(isClosedDate && !toggleCloseDate) || (staffFiltered?.length === 0 && !isEmpty(bookingsByStaff)) ? (
                <div
                    className={`${classes.closedDate} ${
                        isOpenSidebarLeft ? classes.closedDateOverlayShift : classes.closedDateOverlay
                    }`}
                    style={{
                        height: `calc(100vh - ${TABLE_HEADER_HEIGHT + 64}px`
                    }}
                >
                    <Grid
                        container
                        wrap="nowrap"
                        justifyContent="center"
                        alignItems="center"
                        direction="column"
                        spacing={1}
                    >
                        <Grid item className={classes.svgClosedDate}>
                            <ClosedDateIllustratorSVG />
                        </Grid>
                        <Grid item>
                            <Typography variant="h3">No schedules today</Typography>
                        </Grid>
                        <Grid
                            item
                            style={{
                                maxWidth: 500,
                                textAlign: 'center'
                            }}
                        >
                            <Typography variant="body2">Today is closed.</Typography>
                        </Grid>
                    </Grid>
                </div>
            ) : undefined}
        </>
    );
}

export default Overlay;
