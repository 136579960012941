import request from './request';
import { OPERATOR } from 'const/condition';
import { encodeURL } from 'utils/queryParams';

const blockedTimeApis = {
    staffBlockedTime: '/staff-blocked-times',
    staffBlockedTimeDetail: (id) => `/staff-blocked-times/${id}`
};

export async function getStaffBlockedTimeApi(range) {
    let query = {
        dateBlocked: {
            [OPERATOR.between]: `${range?.start},${range?.end}`
        }
    };
    const response = await request.get(`${blockedTimeApis.staffBlockedTime}?query=${encodeURL(query)}`);
    return response.data;
}

export async function createBlockedTimeApi({ body }) {
    const response = await request.post(`${blockedTimeApis.staffBlockedTime}`, body);
    return response.data;
}

export async function updateBlockedTimeApi({ id, body }) {
    const response = await request.put(`${blockedTimeApis.staffBlockedTimeDetail(id)}`, body);
    return response.data;
}

export async function deleteBlockedTimeApi(id) {
    const response = await request.delete(blockedTimeApis.staffBlockedTimeDetail(id));
    return response.data;
}
