import { useSelector } from 'react-redux';
import { orderBy } from 'lodash';

export function useTop5Services() {
    return useSelector((state) => state.analyze?.top5Services);
}

export function useTop5Technicians() {
    return useSelector((state) => state.analyze?.top5Technicians);
}

export function useIncomeTicketsData() {
    return useSelector((state) => state.analyze?.incomeTicketsData);
}

export function useTotalIncome() {
    return useSelector((state) =>
        state.analyze?.incomeTicketsData?.reduce((total, next) => {
            return total + next?.income;
        }, 0)
    );
}

export function useTotalTickets() {
    return useSelector((state) =>
        state.analyze?.incomeTicketsData?.reduce((total, next) => {
            return total + next?.tickets;
        }, 0)
    );
}

export function useStaffAnalyze() {
    return useSelector((state) => {
        const tip = state.analyze?.tip;
        const staffs = state.analyze?.staffAnalyze;
        return orderBy(staffs, ['income', 'asc'])
            ?.reverse()
            .map((staff) => {
                const staffTip = tip?.find((t) => +t?.staffId === +staff?.id)?.amount ?? 0;
                return {
                    ...staff,
                    tip: staffTip
                };
            });
    });
}

export function useTotalStaffsExpectedIncome() {
    return useSelector((state) => {
        const staffs = state.analyze?.staffAnalyze;
        return staffs?.reduce((total, staff) => {
            return total + staff?.income;
        }, 0);
    });
}

export function useTotalStaffsActualIncome() {
    return useSelector((state) => {
        return state.analyze?.staffAnalyze?.reduce((total, staff) => {
            return total + staff?.incomeDoneService;
        }, 0);
    });
}
