import * as types from './types';
import { getBookingsApi, printBookingApi } from 'services/bookings';
import { formatQueriesConditions } from 'utils/queryParams';
import { enqueueSnackbar } from 'notifier/actions';

export function fetchBookings({ searchCondition, page, limit, errorCallback, successCallback }) {
    return async function (dispatch) {
        dispatch(_fetchBookingsAction());

        try {
            const query = formatQueriesConditions(searchCondition);

            const response = await getBookingsApi({ query, page, limit });
            dispatch(_fetchBookingsActionSuccess({ bookings: response?.items, total: response?.meta?.totalItems }));
            successCallback && successCallback(response?.meta?.totalPages);
        } catch (error) {
            const { message } = error;
            dispatch(_fetchBookingsActionFailed(message));
            errorCallback && errorCallback(message);
        }
    };
}

function _fetchBookingsAction() {
    return {
        type: types.FETCH_BOOKINGS
    };
}

function _fetchBookingsActionFailed(error) {
    return {
        type: types.FETCH_BOOKINGS_FAILED,
        payload: {
            error
        }
    };
}

function _fetchBookingsActionSuccess({ bookings, total }) {
    return {
        type: types.FETCH_BOOKINGS_SUCCESS,
        payload: {
            bookings,
            total
        }
    };
}

export function printBooking({ bookingId, errorCallback, successCallback }) {
    return async function (dispatch) {
        try {
            const response = await printBookingApi({ bookingId });
            successCallback && successCallback(response?.meta?.totalPages);
            dispatch(
                enqueueSnackbar({
                    message: `Add booking to print queue success`,
                    type: 'info'
                })
            );
        } catch (error) {
            const { message } = error;
            errorCallback && errorCallback(message);
        }
    };
}

export function printBookings({ bookingIds = [], errorCallback, successCallback }) {
    return async function (dispatch) {
        try {
            await Promise.all(bookingIds?.map((bookingId) => printBookingApi({ bookingId })));
            successCallback && successCallback();
            dispatch(
                enqueueSnackbar({
                    message: `Add multiple booking to print queue success`,
                    type: 'info'
                })
            );
        } catch (error) {
            const { message } = error;
            errorCallback && errorCallback(message);
        }
    };
}
