import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import CheckIconSVG from 'assets/images/svg/CheckIconSVG';
import { useBookingCategories, useSelectedServices, useSelectedCategoryIndex, useBookingStaffs } from 'hooks/checkIn';
import { useDispatch } from 'react-redux';
import * as bookingActions from './actions';
import Slider from 'react-slick';
import { ArrowBackTwoTone, ArrowForwardTwoTone } from '@material-ui/icons';
import { useMerchantDetail } from 'hooks/merchant';
import { DEFAULT_CURRENCY, MERCHANT_PERMISSIONS } from 'const';
import { useIsHavingPermission } from 'hooks/auth';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    sRoot: {
        alignSelf: 'baseline',
        width: '100%'
    },
    sBox: {
        background: theme.palette.common.white,
        borderRadius: 50,
        boxShadow: theme.shadows[1],
        padding: `10px 30px`,
        borderBottom: `1px solid rgb(231, 232, 233)`,
        width: '100%',
        position: 'sticky',
        top: 82,
        left: 0,
        zIndex: 100
    },
    sCategory: {
        paddingTop: 20,
        paddingBottom: (props) => {
            return props?.isShowFooter ? 80 : 0;
        }
    },
    sSlider: {
        '& .slick-arrow': {
            color: theme.colors.dimGrey,
            '&.slick-prev::before, &.slick-next::before': {
                color: theme.palette.common.black
            },
            '&.slick-disabled': {
                color: theme.colors.disabledGrey
            }
        }
    },
    sCtTag: {
        background: theme.palette.common.white,
        height: 32,
        textAlign: 'center',
        margin: 0,
        outline: 'none',
        borderRadius: 16,
        display: 'flex !important',
        alignItems: 'center',
        justifyContent: 'center',
        '& .ctTitle': {
            color: theme.colors.primaryText
        },
        '&:hover': {
            background: theme.colors.ghostwhite,
            cursor: 'pointer'
        }
    },
    sCtTagSelected: {
        background: theme.palette.primary.main,
        '& .ctTitle': {
            color: theme.palette.common.white
        },
        '&:hover': {
            background: theme.palette.primary.main,
            cursor: 'default'
        }
    },
    sCategoryDetail: {
        '&:not(:last-child)': {
            paddingBottom: theme.spacing(3)
        }
    },
    sServiceList: {
        background: theme.palette.common.white,
        borderRadius: 8,
        boxShadow: theme.shadows[1]
    },
    sServiceItem: {
        padding: '20px 30px',
        borderBottom: `1px solid rgb(231, 232, 233)`,
        cursor: 'pointer'
    },
    sRadio: {
        width: 20,
        minWidth: 20,
        height: 20,
        borderColor: `rgb(213, 215, 218)`,
        // background: `rgb(242, 242, 247)`,
        border: `1px solid ${theme.colors.primaryText}`,
        borderRadius: theme.radius(1),
        marginRight: 16,
        cursor: 'pointer',
        position: 'relative'
    },
    sRadioSelected: {
        borderColor: theme.palette.primary.main,
        background: theme.palette.primary.main,
        '& svg': {
            width: 10.73,
            height: 8,
            '& path': {
                fill: theme.palette.common.white
            }
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    smb3: {
        marginBottom: theme.spacing(3)
    },
    sPaddingLeftMobile: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(3)
        }
    }
}));

function ServiceChoosing({ isShowFooter }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const slideRef = React.useRef();
    const categories = useBookingCategories();
    const bookingStaffs = useBookingStaffs();
    const selectedServices = useSelectedServices();
    const currentIndex = useSelectedCategoryIndex();
    const merchant = useMerchantDetail();
    const isMerchantHasStaffServiceDuration = useIsHavingPermission(MERCHANT_PERMISSIONS.STAFF_SERVICE_DURATION);

    const currency = React.useMemo(() => {
        return merchant?.currency || DEFAULT_CURRENCY;
    }, [merchant]);

    React.useEffect(() => {
        return slideRef.current?.slickGoTo(currentIndex);
    }, [currentIndex]);

    const possibleStaffIds = React.useMemo(() => {
        return bookingStaffs?.map((staff) => +staff?.id);
    }, [bookingStaffs]);

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        centerPadding: true,
        nextArrow: <ArrowForwardTwoTone />,
        prevArrow: <ArrowBackTwoTone />,
        beforeChange: (current, next) => {},
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3
                }
            }
        ]
    };

    const handleSelectedCategoryOption = ({ option, index }) => {
        dispatch(bookingActions.setCurrentCategoryIndex(index));
        return slideRef.current?.slickGoTo(index);
    };

    const toggleService = (service) => {
        dispatch(bookingActions.addService({ service }));
    };

    React.useEffect(() => {
        const $booking = document.getElementById('booking_');
        const allServices = categories
            ?.slice(0, currentIndex)
            ?.map((category) => category?.services?.length + 1)
            .reduce((a, b) => (a += b), 0);

        const scrollTop = currentIndex === 0 ? 0 : allServices * 80;

        if ($booking) {
            $booking.scrollTo({
                top: scrollTop,
                behavior: 'smooth'
            });
        }
    }, [currentIndex, categories]);

    return (
        <Grid container className={classes.sRoot} direction="column">
            <Grid item className={classes.sBox}>
                <Slider ref={slideRef} className={classes.sSlider} {...settings}>
                    {categories?.map((ct, index) => {
                        return (
                            <Grid
                                item
                                key={ct.id}
                                className={`${classes.sCtTag} ${index === currentIndex ? classes.sCtTagSelected : ''}`}
                                onClick={() => handleSelectedCategoryOption({ index })}
                            >
                                <Typography noWrap variant="body2" className={`ctTitle`}>
                                    {ct.title}
                                </Typography>
                            </Grid>
                        );
                    })}
                </Slider>
                {/* <Grid container direction="row" wrap="nowrap"></Grid> */}
            </Grid>
            <Grid item className={classes.sCategory} style={{ paddingBottom: isShowFooter ? 80 : 0 }}>
                <Grid container direction="column" wrap="nowrap" id={`category-boundary`}>
                    {categories?.map((category, index) => {
                        const { services } = category;

                        if (!services?.length) return null;
                        return (
                            <Grid key={category.id} item id={`category_${index}`} className={classes.sCategoryDetail}>
                                <Grid container direction="column">
                                    <Grid item className={`${classes.smb3} ${classes.sPaddingLeftMobile}`}>
                                        <Typography style={{ fontWeight: 500 }} variant="h4">
                                            {category.title}
                                        </Typography>
                                        <Typography variant="body2">{category.description}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Grid
                                            className={classes.sServiceList}
                                            container
                                            direction="column"
                                            wrap="nowrap"
                                        >
                                            {services.map((service) => {
                                                const prices = service?.prices;
                                                const serviceStaffs = service?.serviceStaffs;

                                                const isValidHasAtLeast1 = serviceStaffs?.some((sSt) =>
                                                    possibleStaffIds?.includes(+sSt?.staffId)
                                                );

                                                if (!isValidHasAtLeast1) return null;

                                                const price = prices?.[0];
                                                const isSelected =
                                                    selectedServices.findIndex((sv) => +sv.id === +service.id) !== -1;
                                                return (
                                                    <Grid
                                                        key={service.id}
                                                        onClick={() => toggleService(service)}
                                                        item
                                                        className={classes.sServiceItem}
                                                    >
                                                        <Grid container alignItems="flex-start" wrap="nowrap">
                                                            <Grid
                                                                item
                                                                className={`${classes.sRadio} ${
                                                                    isSelected ? classes.sRadioSelected : ''
                                                                }`}
                                                                style={{
                                                                    top: isMerchantHasStaffServiceDuration ? 0 : 10
                                                                }}
                                                            >
                                                                {isSelected ? <CheckIconSVG /> : ''}
                                                            </Grid>

                                                            <Grid item style={{ flex: 1 }}>
                                                                <Grid
                                                                    container
                                                                    direction="column"
                                                                    spacing={2}
                                                                    wrap="nowrap"
                                                                >
                                                                    <Grid item>
                                                                        <Grid
                                                                            container
                                                                            justifyContent="space-between"
                                                                            direction="row"
                                                                            wrap="wrap"
                                                                            alignItems="center"
                                                                        >
                                                                            <Grid item>
                                                                                <Grid
                                                                                    container
                                                                                    direction="column"
                                                                                    wrap="nowrap"
                                                                                >
                                                                                    <Grid item>
                                                                                        <Typography variant="body1">
                                                                                            {service.title}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                    {!isMerchantHasStaffServiceDuration && (
                                                                                        <Grid item>
                                                                                            <Typography variant="subtitle1">
                                                                                                {price?.duration || 0}{' '}
                                                                                                mins
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                    )}
                                                                                </Grid>
                                                                            </Grid>

                                                                            <Grid item>
                                                                                <Typography variant="body1">
                                                                                    {currency}
                                                                                    {price?.retailPrice || 0}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                    {service?.description && (
                                                                        <Grid item>
                                                                            <Typography variant="body2">
                                                                                {service?.description}
                                                                            </Typography>
                                                                        </Grid>
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(ServiceChoosing);
