export function getItem(itemName) {
    try {
        const stateStringtify = localStorage.getItem(itemName);
        if (stateStringtify === null) {
            return undefined;
        }
        return JSON.parse(stateStringtify);
    } catch (e) {
        console.log('Errors loading state data');
        return undefined;
    }
}

export function setItem(itemName, state) {
    try {
        const stateStringtify = JSON.stringify(state);
        localStorage.setItem(itemName, stateStringtify);
    } catch (e) {
        console.log('Errors saving data to local storage');
    }
}

export function removeItem(itemName) {
    localStorage.removeItem(itemName);
}
