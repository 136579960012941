import { useSelector } from 'react-redux';

export function useSmsSchedule() {
    return useSelector((state) => state?.sms?.list);
}

export function useSmsClients() {
    return useSelector((state) => state?.sms?.clients);
}

export function useIsFetchingClients() {
    return useSelector((state) => state?.sms?.isFetchingClients);
}
