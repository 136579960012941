import React from 'react';
import { useIsSameToday, useSelectedDate } from 'hooks/calendar';
import { useIsHavingPermissionTest } from 'hooks/auth';
import { MERCHANT_PERMISSIONS } from 'const';
import * as calendarActions from 'views/calender/actions';
import { useDispatch } from 'react-redux';
import { getAppendMerchantTimezoneToDate, getDatetimeFormat } from 'utils/timing';

function HandleFetchingData() {
    const dispatch = useDispatch();
    const selectedDate = useSelectedDate();
    const internalRef = React.useRef();
    const isToday = useIsSameToday();
    const isHavingCheckout = useIsHavingPermissionTest(MERCHANT_PERMISSIONS.CHECK_OUT);

    const fetchBookingsStaffsByDate = React.useCallback(
        (date) => {
            dispatch(
                calendarActions.fetchCombinedDate({
                    date,
                    success: () => {
                        console.log('fetch success');
                    },
                    error: () => {
                        console.log('fetch failed');
                    }
                })
            );
        },
        [dispatch]
    );

    React.useEffect(() => {
        if (isHavingCheckout && isToday) {
            dispatch(
                calendarActions.fetchGiftCodes({
                    query: {
                        startDate: getAppendMerchantTimezoneToDate(getDatetimeFormat(new Date()))
                    },
                    successCallback: () => {
                        console.log('fetchGiftCodes Success');
                    },
                    errorCallback: () => {
                        console.log('fetchGiftCodes Failed');
                    }
                })
            );
        }
    }, [isHavingCheckout, isToday, dispatch]);

    React.useEffect(() => {
        fetchBookingsStaffsByDate(selectedDate);
    }, [selectedDate, fetchBookingsStaffsByDate]);

    React.useEffect(() => {
        internalRef.current = setInterval(() => {
            fetchBookingsStaffsByDate(selectedDate);
        }, 10 * 60 * 1000);

        return () => {
            if (internalRef.current) clearInterval(internalRef?.current);
        };
    }, [fetchBookingsStaffsByDate, selectedDate]);

    return null;
}

export default HandleFetchingData;
