import React from 'react';
import { Typography } from '@material-ui/core';
import SelectClient from './SelectClient';
import ChooseDate from './ChooseDate';
import { SMS_SEND_TYPE } from 'const';
import DialogActions from 'components/dialog/Action';
import DialogTitle from 'components/dialog/Title';
import DialogContent from 'components/dialog/Content';
import { makeStyles } from '@material-ui/core/styles';
import { string, object } from 'yup';
import ButtonBase from 'components/button/Base';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import * as actionSchedule from '../action';
import { getCurrentUTC, getCurrentDateFormat, getNativeDate, getShortTime } from 'utils/timing';
import * as merchantActions from 'merchant/actions';
import NextIcon from '@material-ui/icons/ArrowForwardIos';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import BackIcon from '@material-ui/icons/ArrowBackIos';

const STATE = {
    SELECT_CLIENT: 'SELECT_CLIENT',
    SELECT_TIME: 'SELECT_TIME'
};

const STATE_LABELS = {
    [STATE.SELECT_CLIENT]: 'Select clients',
    [STATE.SELECT_TIME]: 'Select time'
};

const scheduleSmsSchema = object({
    name: string().required('This field is required'),
    message: string().required('This field is required')
});

function getSmsScheduleDate(datetime) {
    if (!datetime) return new Date();
    return getNativeDate(datetime);
}

function getSmsScheduleTime(datetime) {
    if (!datetime) return '';
    return getShortTime(datetime);
}

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 1024,
        margin: '0 auto',
        paddingTop: 30,
        [theme.breakpoints.down('sm')]: {
            paddingTop: 10
        }
    },

    form: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    btnSubmit: {
        position: 'relative'
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    whiteColor: {
        color: theme.palette.common.white
    }
}));

function AddSms({ onClose, isEdit, editSmsSchedule }) {
    const { t } = useTranslation();

    const classes = useStyles();
    const dispatch = useDispatch();

    const [currentState, setCurrentState] = React.useState(STATE.SELECT_CLIENT);
    const [phones, setPhones] = React.useState(isEdit ? editSmsSchedule.phones : []);
    const [isChooseByDateRanges, setIsChooseByDateRanges] = React.useState(false);

    const formik = useFormik({
        initialValues: {
            name: isEdit ? editSmsSchedule.name : '',
            message: isEdit ? editSmsSchedule.message : ''
        },
        validationSchema: scheduleSmsSchema
    });

    const { isSubmitting, isValid } = formik;

    const [sendType, setSendType] = React.useState(isEdit ? editSmsSchedule.sendType : SMS_SEND_TYPE.NOW);
    const [sendDate, setSendDate] = React.useState(
        isEdit ? getSmsScheduleDate(editSmsSchedule.sendDatetime) : new Date()
    );

    const [sendTime, setSendTime] = React.useState(isEdit ? getSmsScheduleTime(editSmsSchedule.sendDatetime) : '00:00');

    const handleNext = () => {
        setCurrentState(STATE.SELECT_TIME);
    };

    const handleChangePhones = React.useCallback((newPhones) => {
        setPhones(newPhones);
    }, []);

    const onClearPhones = React.useCallback(() => {
        setPhones([]);
    }, []);

    const handleChangeSendType = React.useCallback((e) => {
        setSendType(e.target.value);
    }, []);

    const handleChangeSendDate = React.useCallback((value) => {
        setSendDate(value);
    }, []);

    const handleOnChangeSendTime = React.useCallback((value) => {
        setSendTime(value);
    }, []);

    const handleToggleChooseDateRanges = React.useCallback(() => {
        setIsChooseByDateRanges(!isChooseByDateRanges);
        onClearPhones([]);
    }, [isChooseByDateRanges, onClearPhones]);

    const bodyContent = React.useCallback(() => {
        switch (currentState) {
            case STATE.SELECT_CLIENT:
                return (
                    <SelectClient
                        handleNext={handleNext}
                        phones={phones}
                        onChangePhones={handleChangePhones}
                        onClearPhones={onClearPhones}
                        isChooseByDateRanges={isChooseByDateRanges}
                        onToggleChooseDateRange={handleToggleChooseDateRanges}
                    />
                );
            case STATE.SELECT_TIME:
                return (
                    <ChooseDate
                        sendType={sendType}
                        onChangeSendType={handleChangeSendType}
                        sendDate={sendDate}
                        onChangeSendDate={handleChangeSendDate}
                        sendTime={sendTime}
                        onChangeSendTime={handleOnChangeSendTime}
                        {...formik}
                    />
                );

            default:
                return <SelectClient />;
        }
    }, [
        handleToggleChooseDateRanges,
        isChooseByDateRanges,
        currentState,
        formik,
        handleChangePhones,
        handleChangeSendDate,
        handleChangeSendType,
        handleOnChangeSendTime,
        onClearPhones,
        phones,
        sendDate,
        sendTime,
        sendType
    ]);

    const handleClickPrev = () => {
        setCurrentState(STATE.SELECT_CLIENT);
    };

    const handleClickNext = () => {
        setCurrentState(STATE.SELECT_TIME);
    };

    const handleCancel = React.useCallback(() => {
        onClose();
    }, [onClose]);

    const handleSave = React.useCallback(() => {
        const date = getCurrentDateFormat(sendDate);
        const formatUtc = `${date} ${sendTime}`;
        const formatDatetime = getCurrentUTC(formatUtc);

        const body = {
            ...formik.values,
            sendType: Number(sendType),
            phones: phones,
            sendDatetime: formatDatetime
        };

        console.log(body);

        if (!isEdit) {
            dispatch(
                actionSchedule.createSmsSchedule({
                    body: body,
                    errorCallback: () => {
                        console.log('Create sms schedule failed');
                    },
                    successCallback: () => {
                        console.log(`Create sms schedule success`);
                        dispatch(
                            merchantActions.fetchMerchantInfo({
                                successCallback: () => {
                                    console.log('fetch merchant info success');
                                },
                                errorCallback: () => {
                                    console.log('fetch merchant info failed');
                                }
                            })
                        );

                        onClose();
                    }
                })
            );
        } else {
            dispatch(
                actionSchedule.updateSmsSchedule({
                    id: editSmsSchedule?.id,
                    body: body,
                    errorCallback: () => {
                        console.log('Update sms schedule failed');
                    },
                    successCallback: ({ id }) => {
                        console.log(`Update sms schedule ${id} success`);
                        dispatch(
                            merchantActions.fetchMerchantInfo({
                                successCallback: () => {
                                    console.log('fetch merchant info success');
                                },
                                errorCallback: () => {
                                    console.log('fetch merchant info failed');
                                }
                            })
                        );
                        onClose();
                    }
                })
            );
        }
    }, [dispatch, editSmsSchedule, formik.values, isEdit, onClose, phones, sendDate, sendTime, sendType]);

    const actionsContent = React.useCallback(() => {
        switch (currentState) {
            case STATE.SELECT_CLIENT:
                return (
                    <>
                        <ButtonBase startIcon={<CloseIcon />} variant="outlined" onClick={handleCancel}>
                            {t('close')}
                        </ButtonBase>
                        <ButtonBase
                            variant="contained"
                            color="primary"
                            disabled={phones?.length === 0}
                            onClick={handleClickNext}
                            endIcon={<NextIcon />}
                        >
                            {t(`Next`)}
                        </ButtonBase>
                    </>
                );
            case STATE.SELECT_TIME:
                return (
                    <>
                        <ButtonBase startIcon={<BackIcon />} variant="outlined" onClick={handleClickPrev}>
                            {t('Previous')}
                        </ButtonBase>
                        <ButtonBase
                            variant="contained"
                            color="primary"
                            onClick={handleSave}
                            startIcon={<SaveIcon />}
                            disabled={isSubmitting || !isValid}
                        >
                            {t(`Save`)}
                        </ButtonBase>
                    </>
                );
            default:
                return null;
        }
    }, [currentState, handleCancel, handleSave, isSubmitting, isValid, phones, t]);

    return (
        <form className={classes.form}>
            <DialogTitle>
                <Typography variant="h5" className={classes.whiteColor}>
                    {STATE_LABELS?.[currentState]}
                </Typography>
            </DialogTitle>
            <DialogContent>{bodyContent()}</DialogContent>
            <DialogActions>{actionsContent()}</DialogActions>
        </form>
    );
}

export default AddSms;
