import React, { forwardRef } from 'react';
import Avatar from 'components/avatar';
import { getImageUrl } from 'utils/image';
import { getFullName } from 'utils/naming';
import { Button, Typography } from '@material-ui/core';
import { useUpdateTurnCountMutation } from 'services/staff';
import { queryClient } from 'query-client';
import { DragIndicator } from '@material-ui/icons';

const Item = forwardRef(({ staff, withOpacity, isDragging, style, ...props }, ref) => {
    const { mutate } = useUpdateTurnCountMutation({
        onSuccess: () => {
            queryClient.invalidateQueries(['staff']);
        }
    });

    const handleCountChange = ({ staffId, count }) => {
        mutate({
            staffId,
            body: {
                amount: count,
                isOverride: false
            }
        });
    };

    const inlineStyles = {
        opacity: withOpacity ? '0.5' : '1',
        transformOrigin: '50% 50%',
        height: '160px',
        width: '160px',
        borderRadius: '10px',
        position: 'relative',
        cursor: isDragging ? 'grabbing' : 'grab',
        backgroundColor: '#ffffff',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: 2,
        padding: 8,
        alignItems: 'center',
        boxShadow: isDragging
            ? 'rgb(63 63 68 / 5%) 0px 2px 0px 2px, rgb(34 33 81 / 15%) 0px 2px 3px 2px'
            : 'rgb(63 63 68 / 5%) 0px 0px 0px 1px, rgb(34 33 81 / 15%) 0px 1px 3px 0px',
        transform: isDragging ? 'scale(1.05)' : 'scale(1)',
        ...style
    };

    return (
        <div ref={ref} style={inlineStyles} {...props}>
            <div style={{ position: 'absolute', top: 4, left: 4 }}>
                <DragIndicator {...props.handleProps}></DragIndicator>
            </div>
            <Avatar
                style={{
                    width: 32,
                    height: 32
                }}
                size="small"
                src={getImageUrl(staff?.avatar)}
            >
                {staff?.label?.slice(0, 1)}
            </Avatar>
            {getFullName(staff)}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%'
                }}
            >
                <Button
                    color="primary"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleCountChange({
                            staffId: staff?.id,
                            count: -0.5
                        });
                    }}
                    variant="outlined"
                    style={{
                        minWidth: 40
                    }}
                >
                    -
                </Button>

                <Typography
                    style={{
                        color: 'black'
                    }}
                    variant="h5"
                >
                    {staff.turnCount}
                </Typography>
                <Button
                    onClick={() =>
                        handleCountChange({
                            staffId: staff?.id,
                            count: 0.5
                        })
                    }
                    color="primary"
                    variant="outlined"
                    style={{
                        minWidth: 40
                    }}
                >
                    +
                </Button>
            </div>
        </div>
    );
});

export default Item;
