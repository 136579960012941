import { getTotalMinutesByTime, convertTimeToFloat, getNumRangeTimeStart, getNumRangeTimeEnd } from 'utils/timing';

export function formatBlockedTimeByStaff(blockedTimes = []) {
    const newBlockedTimes = {};

    for (let blockedTime of blockedTimes) {
        const staffId = blockedTime?.staffId;
        if (!newBlockedTimes?.[staffId]) {
            newBlockedTimes[staffId] = [];
        }

        const timeStart = getNumRangeTimeStart(blockedTime?.slot);
        const timeEnd = getNumRangeTimeEnd(blockedTime?.slot);
        let start = getTotalMinutesByTime(timeStart);
        let end = getTotalMinutesByTime(timeEnd);

        let duration = Math.abs(end - start);

        newBlockedTimes[Number(staffId)] = [
            ...newBlockedTimes[staffId],
            {
                ...blockedTime,
                duration,
                timeStart: convertTimeToFloat(timeStart),
                timeEnd: convertTimeToFloat(timeEnd),
                slot: {
                    timeStart,
                    timeEnd
                }
            }
        ];
    }

    console.log('newBlockedTimes', newBlockedTimes);

    return newBlockedTimes;
}
