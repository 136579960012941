import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Avatar, Dialog } from '@material-ui/core';
import { NavLink as RouterLink } from 'react-router-dom';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import StorageIcon from '@material-ui/icons/Storage';
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import SmsIcon from '@material-ui/icons/Sms';
import BarChartIcon from '@material-ui/icons/BarChart';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import SettingsIcon from '@material-ui/icons/Settings';
import TurnedIn from '@material-ui/icons/TurnedIn';
import TurnedInNot from '@material-ui/icons/TurnedInNot';
import { useTranslation } from 'react-i18next';
import { useMerchantInfo } from 'hooks/merchant';
import PopperMenu from 'components/popper';
import UserMenuPopup from './UserMenuPopup';
import { CLIENT_URL } from 'config';
import { isAuthorized } from 'utils/app/auth';
import { useMerchantPermissions, useIsSuperUser, useIsHavingPermissionTest } from 'hooks/auth';
import { getImageUrl } from 'utils/image';
import { MERCHANT_PERMISSIONS } from 'const';
import Tooltip from 'components/tooltip';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import PolicySetting from './PolicySetting';
import ChangePassword from './ChangePassword';

const styles = (theme) => ({
    root: {
        width: '100%',
        height: '100vh',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        overflowX: 'hidden',
        background: theme.palette.primary.main
    },

    drawer: {
        '& .MuiDrawer-paperAnchorDockedLeft': {
            borderRight: `1px solid ${theme.colors.border}`
        }
    },
    categories: {
        marginTop: 10
    },

    avatarName: {
        color: theme.palette.common.white
    },
    item: {
        overflow: 'hidden',
        padding: theme.spacing(1),
        borderRadius: theme.radius(2),
        width: 48,
        height: 48,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        '&:hover': {
            background: theme.palette.common.white,
            '& svg': {
                fill: theme.palette.primary.main
            }
        },
        '& svg': {
            fill: theme.palette.common.white
        }
    },

    selected: {
        '& > div': {
            background: theme.palette.common.white
        },
        '& svg': {
            fill: theme.palette.primary.main
        }
    },
    link: {
        textDecoration: 'none'
    },
    sectionName: {
        paddingLeft: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1)
    },
    openIcon: {
        color: theme.palette.common.white,
        marginTop: theme.spacing(1)
    },
    setting: {
        color: theme.colors.ghostwhite,
        cursor: 'pointer',
        position: 'absolute',
        bottom: -5,
        right: -5,
        zIndex: 10,
        width: 20,
        height: 20
    },
    fullWidth: {
        width: '100%'
    },
    expand: {
        flex: 1
    },
    avatar: {
        position: 'relative',
        marginBottom: theme.spacing(2)
    },
    categoryTitle: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(1)
    },
    whiteColor: {
        color: theme.palette.common.white
    }
});

function Navigator({ classes, ...rest }) {
    const { t } = useTranslation();
    const merchant = useMerchantInfo();
    const userPermission = useMerchantPermissions();
    const isSuperUser = useIsSuperUser();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openPolicy, setOpenPolicy] = React.useState(false);
    const [openChangePassword, setOpenChangePassword] = React.useState(false);
    const isHavingAdvanced = useIsHavingPermissionTest(MERCHANT_PERMISSIONS.INTERNAL);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const openSettingPolicy = React.useCallback(() => {
        setOpenPolicy(true);
        setAnchorEl(null);
    }, []);

    const handleCloseSettingPolicy = React.useCallback(() => {
        setOpenPolicy(false);
    }, []);

    const handleOpenChangePasssword = React.useCallback(() => {
        setOpenChangePassword(true);
        setAnchorEl(null);
    }, []);

    const handleCloseChangePasssword = React.useCallback(() => {
        setOpenChangePassword(false);
    }, []);

    const categories = React.useMemo(() => {
        return [
            {
                id: 1,
                title: t('nav_overview'),
                isShow: true,
                children: [
                    {
                        id: t('nav_calendar'),
                        icon: <CalendarTodayIcon />,
                        path: '/calendar',
                        permissions: [],
                        title: 'Calendar'
                    },
                    {
                        id: t('bookings'),
                        icon: <StorageIcon />,
                        path: '/bookings',
                        permissions: [
                            MERCHANT_PERMISSIONS.INTERNAL,
                            MERCHANT_PERMISSIONS.INTERNAL_BASIC,
                            MERCHANT_PERMISSIONS.INTERNAL_MEDIUM
                        ],
                        title: 'Appointments log'
                    },
                    { id: t('nav_clients'), icon: <EmojiPeopleIcon />, path: '/clients', title: 'Clients' },
                    {
                        id: t('nav_services'),
                        icon: <VerticalSplitIcon style={{ width: 20 }} />,
                        path: '/services',
                        permissions: [],
                        title: 'Services'
                    },
                    {
                        id: t('nav_staff'),
                        icon: <RecordVoiceOverIcon />,
                        path: '/staff',
                        permissions: [],
                        title: 'Staff'
                    },
                    { id: t('nav_sms'), icon: <SmsIcon />, path: '/sms', permissions: [], title: 'Sms notification' },
                    {
                        id: t('nav_voucher'),
                        icon: <CardGiftcardIcon />,
                        path: '/gifts',
                        permissions: [MERCHANT_PERMISSIONS.GIFT],
                        title: 'Gifts'
                    },
                    {
                        id: t('nav_analytics'),
                        icon: <BarChartIcon />,
                        path: '/statistic',
                        permissions: [],
                        title: 'Analyze'
                    }
                ]
            },
            {
                id: 2,
                title: isHavingAdvanced ? t('nav_plus') : t(`nav_basic`),
                isShow: isAuthorized({
                    userPermission,
                    permissions: [
                        MERCHANT_PERMISSIONS.INTERNAL,
                        MERCHANT_PERMISSIONS.INTERNAL_BASIC,
                        MERCHANT_PERMISSIONS.INTERNAL_MEDIUM
                    ]
                }),
                children: [
                    // {
                    //     id: t('nav_hide_price_setting'),
                    //     icon: <PricingSettingSVG />,
                    //     path: '/prices',
                    //     permissions: [
                    //         MERCHANT_PERMISSIONS.INTERNAL,
                    //         MERCHANT_PERMISSIONS.INTERNAL_BASIC,
                    //         MERCHANT_PERMISSIONS.INTERNAL_MEDIUM
                    //     ],
                    //     title: 'Hide Price Dates'
                    // },
                    {
                        id: t('nav_analytics'),
                        icon: <TrendingUpIcon />,
                        path: '/analyzes',
                        permissions: [
                            MERCHANT_PERMISSIONS.INTERNAL,
                            MERCHANT_PERMISSIONS.INTERNAL_BASIC,
                            MERCHANT_PERMISSIONS.INTERNAL_MEDIUM
                        ],
                        title: 'Statistics'
                    },
                    {
                        id: t('nav_payment_histories'),
                        icon: <AccountBalanceIcon />,
                        path: '/payment-histories',
                        permissions: [MERCHANT_PERMISSIONS.CHECK_OUT],
                        title: 'Payment History'
                    }
                ]
            },
            {
                id: 3,
                title: 'Admin',
                isShow: isSuperUser,
                children: [
                    {
                        id: `Setting`,
                        icon: <SettingsIcon />,
                        path: '/admin-settings',
                        permissions: [],
                        title: 'Admin'
                    }
                ]
            }
        ];
    }, [isHavingAdvanced, isSuperUser, t, userPermission]);

    const isHasOnlyGift = React.useMemo(() => {
        return userPermission?.length === 1 && userPermission?.[0] === MERCHANT_PERMISSIONS.GIFT;
    }, [userPermission]);

    return (
        <Grid className={classes.root} container direction="column" wrap="nowrap" justifyContent="space-between">
            <Grid item className={classes.expand}>
                <Grid container direction="column">
                    <Grid item className={classes.fullWidth}>
                        <Grid container direction="column" wrap="nowrap" justifyContent="center" alignItems="center">
                            <Grid item className={classes.fullWidth}>
                                <Grid container justifyContent="center" alignItems="center">
                                    <Grid item className={classes.avatar}>
                                        <Avatar alt={merchant?.title} src={getImageUrl(merchant?.logo)} size="large" />
                                        <SettingsIcon className={classes.setting} onClick={handleClick} />
                                        {anchorEl && (
                                            <PopperMenu anchorEl={anchorEl} handleClickAway={handleClose}>
                                                <UserMenuPopup
                                                    openSettingPolicy={openSettingPolicy}
                                                    onClose={handleClose}
                                                    openChangePassword={handleOpenChangePasssword}
                                                />
                                            </PopperMenu>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item className={classes.fullWidth}>
                                <Grid
                                    container
                                    alignItems="center"
                                    justifyContent="center"
                                    wrap="nowrap"
                                    target="_blank"
                                    href={`${CLIENT_URL}/${merchant?.siteName}/booking`}
                                    component="a"
                                    rel="noopener noreferrer"
                                >
                                    <Tooltip placement="right" title="Open online booking">
                                        <Grid item className={`${classes.openIcon}`}>
                                            <OpenInNewIcon />
                                        </Grid>
                                    </Tooltip>
                                </Grid>
                            </Grid>

                            {isHavingAdvanced && (
                                <Grid
                                    container
                                    direction="row"
                                    wrap="nowrap"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <Tooltip placement="right" title="Open in turns">
                                        <Grid
                                            item
                                            target="_blank"
                                            to={'/in-turn'}
                                            component={RouterLink}
                                            rel="noopener noreferrer"
                                            className={`${classes.openIcon}`}
                                        >
                                            <TurnedIn />
                                        </Grid>
                                    </Tooltip>
                                    <Tooltip placement="right" title="Open out turns">
                                        <Grid
                                            item
                                            target="_blank"
                                            to={'/out-turn'}
                                            component={RouterLink}
                                            rel="noopener noreferrer"
                                            className={`${classes.openIcon}`}
                                        >
                                            <TurnedInNot />
                                        </Grid>
                                    </Tooltip>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container direction="column" wrap="nowrap">
                    {categories.map(({ id, children, isShow, title }) => {
                        if (!isShow) return null;
                        return (
                            <Grid item key={id} className={classes.fullWidth}>
                                <Grid container direction="column" spacing={2}>
                                    <Grid item className={classes.categoryTitle}>
                                        <Typography className={classes.whiteColor} variant="body1">
                                            {title}
                                        </Typography>
                                    </Grid>
                                    <Grid item container alignItems="center" direction="column" spacing={1}>
                                        {children?.map(({ id: childId, icon, path, permissions, title }) => {
                                            if (['/services', '/staff', '/sms'].includes(path) && isHasOnlyGift) {
                                                return null;
                                            }

                                            return (
                                                isAuthorized({ userPermission, permissions }) && (
                                                    <Tooltip key={childId} placement="right" title={title}>
                                                        <Grid item>
                                                            <RouterLink
                                                                to={path}
                                                                className={classes.link}
                                                                activeClassName={classes.selected}
                                                            >
                                                                <div className={`${classes.item} icon`}>{icon}</div>
                                                            </RouterLink>
                                                        </Grid>
                                                    </Tooltip>
                                                )
                                            );
                                        })}
                                    </Grid>
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
            <Dialog fullScreen={false} maxWidth={false} open={openPolicy} onClose={handleCloseSettingPolicy}>
                <PolicySetting onClose={handleCloseSettingPolicy} />
            </Dialog>
            <Dialog fullScreen={false} maxWidth={false} open={openChangePassword} onClose={handleCloseChangePasssword}>
                <ChangePassword onClose={handleCloseChangePasssword} />
            </Dialog>
        </Grid>
    );
}

Navigator.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Navigator);
