import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: (props) => props.color || theme.colors.tooltip
    },
    tooltip: {
        backgroundColor: (props) => props.color || theme.colors.tooltip,
        zIndex: 9999,
        fontSize: 13
    },
    tooltipPlacementBottom: {
        marginTop: 5
    }
}));

function BootstrapTooltip({ color, ...rest }) {
    const classes = useStylesBootstrap({ color });

    return <Tooltip arrow classes={classes} {...rest} />;
}

export default React.memo(BootstrapTooltip);
