import React, { useMemo } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, Divider, CircularProgress, IconButton } from '@material-ui/core';
import DialogContent from 'components/dialog/Content';
import DialogTitle from 'components/dialog/Title';
import { getFullName } from 'utils/naming';
import { useMerchantInfo } from 'hooks/merchant';
// import VisaSVG from 'assets/images/svg/VisaSVG';
import CashSVG from 'assets/images/svg/CashSVG';
// import MasterCard from 'assets/images/svg/MasterCard';
import DebitSVG from 'assets/images/svg/DebitSVG';
import GiftPaymentSVG from 'assets/images/svg/GiftPaymentSVG';
import InputBase from 'components/input/Form';
import { getVoucherCodeByCodeNumberApi } from 'services/voucher';
import isEmpty from 'lodash/isEmpty';
import Spinner from 'components/spinner';
import {
    PAYMENT_METHOD_TYPES,
    PAYMENT_TYPE_BY_LABEL,
    CHECKOUT_TYPES,
    CHECKOUT_GIFT_TAX,
    MERCHANT_PERMISSIONS,
    DEFAULT_DURATION
} from 'const';
import * as calendarActions from '../../actions';
import { useDispatch } from 'react-redux';
import { generateTempId } from 'utils/uuid';
import IOSSwitcher from 'components/switch/IOS';
import { findCheckoutByGiftCodeIdApi } from 'services/checkout';
import Button from 'components/button/Base';
import CloseIcon from '@material-ui/icons/Close';
import { getItem, setItem } from 'utils/localStorage';
import { groupBy } from 'lodash';
import { useStaffMapping, useStaffOptions, useStaffsServiceDuration } from 'hooks/staff';
import { useServiceOptions, useServicesMapping } from 'hooks/service';
import { convertServiceGroupOptions } from 'utils/app/booking';
import ReactSelect from 'react-select';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import { useTranslation } from 'react-i18next';
import DeleteSVG from 'assets/images/svg/DeleteSVG';
import { useIsHavingPermission } from 'hooks/auth';

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 1024,
        paddingBottom: theme.spacing(3)
    },
    sidebar: {
        width: 350,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    cards: {
        flex: 1,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3)
    },
    mb2: {
        marginBottom: theme.spacing(1)
    },
    mb3: {
        marginBottom: theme.spacing(2)
    },
    divider: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    total: {
        padding: `2px 4px`,
        borderRadius: 4,
        background: theme.colors.background,
        color: theme.palette.primary.main,
        fontSize: 18,
        fontWeight: 'bold'
    },
    f18: {
        fontSize: 15
    },
    card: {
        borderRadius: 6,
        background: theme.colors.background,
        padding: theme.spacing(3),
        position: 'relative'
    },
    inputText: {
        '& input': {
            fontSize: 30,
            height: 80
        }
    },
    selected: {
        border: `2px solid ${theme.colors.green}`
    },
    checkoutClient: {
        padding: `2px 4px`,
        borderRadius: 4,
        background: theme.colors.background,
        color: theme.palette.primary.main,
        fontSize: 18,
        fontWeight: 'bold'
    },
    suggestion: {
        color: theme.colors.sun,
        cursor: 'pointer'
    },
    warning: {
        color: theme.colors.red,
        fontWeight: 'bold'
    },
    highlight: {
        color: theme.palette.primary.main,
        cursor: 'pointer'
    },
    spinner: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    btnSubmit: {
        position: 'relative'
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    deleted: {
        textDecoration: 'line-through'
    },
    add: {
        color: theme.colors.blue,
        cursor: 'pointer'
    },
    relative: {
        position: 'relative'
    },
    delete: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        cursor: 'pointer'
    },
    whiteColor: {
        color: theme.palette.common.white
    },
    serviceItem: {
        position: 'relative'
    },

    svSpacing: {
        paddingBottom: 12
    },
    spacing: {
        paddingBottom: 12
    },

    item: {
        cursor: 'pointer',
        position: 'relative'
    },

    activeLove: {
        color: theme.colors.red
    },
    normalLove: {
        color: theme.colors.border
    },
    dlFlex: {
        display: 'flex'
    },
    select: {
        '& > div': {
            border: 'none',
            borderBottom: `1px solid ${theme.colors.border}`,
            boxShadow: 'none',
            borderRadius: 0,
            '& > div': {
                padding: 0
            },
            '&:focus': {
                border: 'none',
                borderBottom: `1px solid ${theme.colors.black}`,
                boxShadow: 'none'
            }
        }
    },
    disabled: {
        pointerEvents: 'none'
    },
    heartIcon: {
        display: 'flex',
        cursor: 'pointer'
    },
    bkSv: {
        background: theme.colors.ghostwhite,
        borderRadius: 4,
        marginBottom: theme.spacing(1)
    },
    isNotValid: {
        border: `1px solid ${theme.colors.red}`
    },
    discountItem: {
        display: `flex`,
        justifyContent: `center`,
        alignItems: `center`,
        padding: `20px !important`,
        border: `1px solid ${theme.colors.border}`,
        borderRadius: `10px`,
        cursor: 'pointer'
    }
}));

const STATES = {
    INITIAL: 'INITIAL',
    GIFT_NOT_FOUND: 'GIFT_NOT_FOUND',
    REDEEM_GIFT: 'REDEEM_GIFT'
};

function GiftBox({ id, giftCode, amount, isPaying, onUpdate, onDelete, showDelete, includedTax }) {
    const [giftState, setGiftState] = React.useState(STATES.INITIAL);
    const [codeNumber, setCodeNumber] = React.useState('');

    const [isFetchingVoucherCode, setIsFetchingVoucherCode] = React.useState(false);
    const timerRef = React.useRef();

    const classes = useStyles();
    const theme = useTheme();

    const isValidRedeemInput = React.useMemo(() => {
        return Number(giftCode?.remainingValue) >= Number(amount);
    }, [amount, giftCode]);

    React.useEffect(() => {
        if (timerRef.current) clearTimeout(timerRef.current);
        timerRef.current = setTimeout(async () => {
            if (codeNumber) {
                setIsFetchingVoucherCode(true);
                try {
                    const giftCode = await getVoucherCodeByCodeNumberApi({ codeNumber });
                    if (isEmpty(giftCode)) {
                        setGiftState(STATES.GIFT_NOT_FOUND);
                        onUpdate({
                            id,
                            newGift: { amount: 0, giftCode: null, includedTax: true }
                        });
                    } else {
                        setGiftState(STATES.REDEEM_GIFT);
                        const checkouts = await findCheckoutByGiftCodeIdApi(giftCode?.id);

                        onUpdate({
                            id,
                            newGift: {
                                amount: 0,
                                giftCode,
                                includedTax: checkouts?.filter((co) => !co?.includedTax)?.length ? false : true
                            }
                        });
                    }
                    setIsFetchingVoucherCode(false);
                } catch (error) {
                    setGiftState(STATES.GIFT_NOT_FOUND);
                    onUpdate({
                        id,
                        newGift: { amount: 0, giftCode: null, includedTax: true }
                    });
                    setIsFetchingVoucherCode(false);
                }
            }
        }, 500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [codeNumber]);

    return (
        <Grid item className={`${classes.card} ${classes.relative} ${+amount > 0 ? classes.selected : ``}`}>
            {!includedTax && (
                <Grid
                    style={{
                        position: 'absolute',
                        top: 10,
                        right: 50,
                        width: 'fit-content',
                        borderRadius: 4
                    }}
                    container
                    direction="row"
                    spacing={1}
                >
                    <Grid item>
                        <Typography
                            style={{ padding: `2px 5px`, background: theme.colors.red, borderRadius: 4 }}
                            variant="body2"
                        >
                            No Tax
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                            style={{ padding: `2px 5px`, background: theme.colors.sun, borderRadius: 4 }}
                            variant="body2"
                        >
                            Remember to collect tax
                        </Typography>
                    </Grid>
                </Grid>
            )}
            <Grid container direction="row" alignItems="center">
                <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center' }}>
                    <GiftPaymentSVG width={120} height={100} />
                </Grid>
                {showDelete && (
                    <Grid onClick={() => onDelete(id)} className={classes.delete}>
                        <CloseIcon />
                    </Grid>
                )}
                <Grid item xs={8}>
                    <Grid container direction="column">
                        <Grid item>
                            <InputBase
                                label={'Enter Gift Code'}
                                name="gift"
                                value={codeNumber}
                                disabled={isPaying}
                                onChange={(e) => setCodeNumber(e?.target?.value)}
                                type="text"
                                placeholder="enter gift code"
                            />
                            {isFetchingVoucherCode && (
                                <div className={classes.spinner}>
                                    <Spinner size={40} thick={4} />
                                </div>
                            )}
                        </Grid>
                        {
                            {
                                [STATES.GIFT_NOT_FOUND]: (
                                    <Typography variant="body2" className={classes.warning}>
                                        Gift Not Found
                                    </Typography>
                                ),
                                [STATES.REDEEM_GIFT]: (
                                    <Grid item>
                                        <Grid container direction="column">
                                            <Grid item>
                                                <InputBase
                                                    label={'Enter Amount'}
                                                    name="gift"
                                                    value={amount || ''}
                                                    disabled={isPaying}
                                                    onChange={(e) => {
                                                        onUpdate({
                                                            id,
                                                            newGift: {
                                                                amount: +e.target.value
                                                            }
                                                        });
                                                    }}
                                                    className={`${classes.inputText}`}
                                                    type="number"
                                                    step="any"
                                                    error={!isValidRedeemInput}
                                                    errorText={`Max Amount: $${giftCode?.remainingValue}`}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-end'
                                                }}
                                            >
                                                <Typography
                                                    className={classes.highlight}
                                                    onClick={() =>
                                                        onUpdate({
                                                            id,
                                                            newGift: { amount: giftCode?.remainingValue }
                                                        })
                                                    }
                                                    variant="body2"
                                                >
                                                    Max Amount: ${giftCode?.remainingValue}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                            }[giftState]
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

function TipBox({ id, staffId, amount, onUpdate, onDelete }) {
    const theme = useTheme();
    const classes = useStyles();
    const { t } = useTranslation();
    const staffOptions = useStaffOptions();
    const selectedStaff = React.useMemo(() => {
        return staffOptions?.find((option) => +option?.id === +staffId);
    }, [staffOptions, staffId]);

    return (
        <Grid container direction="row" spacing={2}>
            <Grid item style={{ flex: 1 }}>
                <Grid container direction="column" spacing={1}>
                    <Grid item>
                        <Typography variant="body1" display="inline">
                            {t(`staff`)}
                        </Typography>{' '}
                    </Grid>
                    <Grid item>
                        <ReactSelect
                            className={classes.select}
                            onChange={(option) => {
                                onUpdate({
                                    id,
                                    newTip: {
                                        amount,
                                        staffId: Number(option?.value)
                                    }
                                });
                            }}
                            value={selectedStaff}
                            options={staffOptions}
                            styles={{
                                menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                    minWidth: 500,
                                    borderRadius: theme.radius(3)
                                }),
                                groupHeading: (base) => ({
                                    ...base,
                                    lineHeight: `1.57143`,
                                    fontSize: `0.875rem`,
                                    fontFamily: `"Public Sans", sans-serif`,
                                    fontWeight: 400,
                                    verticalAlign: 'inherit',
                                    borderBottom: 'none',
                                    color: `rgb(99, 115, 129)`
                                }),
                                option: (base) => ({
                                    ...base,
                                    lineHeight: `1.57143`,
                                    fontSize: `0.875rem`,
                                    fontFamily: `"Public Sans", sans-serif`,
                                    fontWeight: 600,
                                    verticalAlign: 'inherit',
                                    borderBottom: 'none',
                                    color: `rgb(33, 43, 54)`,
                                    textTransform: 'none'
                                })
                            }}
                            placeholder={`Choose service`}
                            isMulti={false}
                            components={{
                                DropdownIndicator: () => <ArrowDropDown style={{ color: theme.colors.dropdown }} />,
                                IndicatorSeparator: null
                            }}
                            menuPortalTarget={document.body}
                            menuPosition={'absolute'}
                            menuPlacement={'bottom'}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item style={{ width: 200 }}>
                <Grid container direction="column" spacing={1}>
                    <InputBase
                        label={'Enter Amount'}
                        name="visa"
                        value={amount || ''}
                        onChange={(e) =>
                            onUpdate({
                                id,
                                newTip: {
                                    amount: +e.target.value,
                                    staffId
                                }
                            })
                        }
                        className={classes.inputText}
                        type="number"
                        step="any"
                    />
                </Grid>
            </Grid>

            <Grid item onClick={() => onDelete(id)}>
                <IconButton>
                    <DeleteSVG width={16} height={16} />
                </IconButton>
            </Grid>
        </Grid>
    );
}

function BookingService({ id, staffId, serviceId, onStaffChange, onServiceChange, disabled, onServiceDelete }) {
    const theme = useTheme();
    const classes = useStyles();
    const { t } = useTranslation();
    const staffOptions = useStaffOptions();
    const serviceOptions = useServiceOptions();
    const merchant = useMerchantInfo();

    const svOptions = React.useMemo(() => {
        return convertServiceGroupOptions(serviceOptions, true);
    }, [serviceOptions]);

    const stfOptions = React.useMemo(() => {
        return staffOptions?.map((option) => {
            const stfSvIds = option?.staffServices?.map((sv) => +sv?.serviceId);
            if (!stfSvIds?.includes(+serviceId)) {
                return {
                    ...option,
                    label: `⚠️ ${option?.label}`
                };
            }
            return option;
        });
    }, [serviceId, staffOptions]);

    const selectedService = React.useMemo(() => {
        return serviceOptions?.find((option) => +option?.id === +serviceId);
    }, [serviceOptions, serviceId]);

    const selectedStaff = React.useMemo(() => {
        return staffOptions?.find((option) => +option?.id === +staffId);
    }, [staffOptions, staffId]);

    return (
        <Grid container direction="column">
            <Grid item style={{ marginBottom: theme.spacing(2) }}>
                <Grid container direction="row" spacing={1}>
                    <Grid item style={{ flex: 1 }}>
                        <Grid container direction="column" spacing={1}>
                            <Grid item>
                                <Typography variant="body1" display="inline">
                                    {t(`service`)}
                                </Typography>{' '}
                            </Grid>
                            <Grid item>
                                <ReactSelect
                                    className={classes.select}
                                    onChange={(option) => {
                                        onServiceChange({
                                            id,
                                            serviceId: Number(option?.value)
                                        });
                                    }}
                                    value={selectedService}
                                    options={svOptions}
                                    styles={{
                                        menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 9999,
                                            minWidth: 500,
                                            borderRadius: theme.radius(3)
                                        }),
                                        groupHeading: (base) => ({
                                            ...base,
                                            lineHeight: `1.57143`,
                                            fontSize: `0.875rem`,
                                            fontFamily: `"Public Sans", sans-serif`,
                                            fontWeight: 400,
                                            verticalAlign: 'inherit',
                                            borderBottom: 'none',
                                            color: `rgb(99, 115, 129)`
                                        }),
                                        option: (base) => ({
                                            ...base,
                                            lineHeight: `1.57143`,
                                            fontSize: `0.875rem`,
                                            fontFamily: `"Public Sans", sans-serif`,
                                            fontWeight: 600,
                                            verticalAlign: 'inherit',
                                            borderBottom: 'none',
                                            color: `rgb(33, 43, 54)`,
                                            textTransform: 'none'
                                        })
                                    }}
                                    placeholder={`Choose service`}
                                    isMulti={false}
                                    components={{
                                        DropdownIndicator: () => (
                                            <ArrowDropDown style={{ color: theme.colors.dropdown }} />
                                        ),
                                        IndicatorSeparator: null
                                    }}
                                    menuPortalTarget={document.body}
                                    menuPosition={'absolute'}
                                    menuPlacement={'bottom'}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item style={{ width: 120 }}>
                        <Grid container direction="column" spacing={1}>
                            <Grid item>
                                <Typography variant="body1" display="inline">
                                    {t(`staff`)}
                                </Typography>{' '}
                            </Grid>
                            <Grid item>
                                <ReactSelect
                                    className={classes.select}
                                    onChange={(option) => {
                                        onStaffChange({
                                            id,
                                            staffId: Number(option?.value)
                                        });
                                    }}
                                    value={selectedStaff}
                                    options={stfOptions}
                                    styles={{
                                        menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 9999,
                                            minWidth: 500,
                                            borderRadius: theme.radius(3)
                                        }),
                                        groupHeading: (base) => ({
                                            ...base,
                                            lineHeight: `1.57143`,
                                            fontSize: `0.875rem`,
                                            fontFamily: `"Public Sans", sans-serif`,
                                            fontWeight: 400,
                                            verticalAlign: 'inherit',
                                            borderBottom: 'none',
                                            color: `rgb(99, 115, 129)`
                                        }),
                                        option: (base) => ({
                                            ...base,
                                            lineHeight: `1.57143`,
                                            fontSize: `0.875rem`,
                                            fontFamily: `"Public Sans", sans-serif`,
                                            fontWeight: 600,
                                            verticalAlign: 'inherit',
                                            borderBottom: 'none',
                                            color: `rgb(33, 43, 54)`,
                                            textTransform: 'none'
                                        })
                                    }}
                                    placeholder={`Choose service`}
                                    isMulti={false}
                                    components={{
                                        DropdownIndicator: () => (
                                            <ArrowDropDown style={{ color: theme.colors.dropdown }} />
                                        ),
                                        IndicatorSeparator: null
                                    }}
                                    menuPortalTarget={document.body}
                                    menuPosition={'absolute'}
                                    menuPlacement={'bottom'}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction="row" alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <Typography variant="body2">
                            <strong>Total:</strong> {selectedService?.prices?.[0]?.retailPrice}
                            {merchant.currency}
                        </Typography>
                    </Grid>
                    <Grid item onClick={() => onServiceDelete(id)}>
                        <IconButton>
                            <DeleteSVG width={16} height={16} />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

const PERCENT_OPTIONS = [10, 15, 20, 30];
const AMOUNT_OPTIONS = [5, 10, 15, 20];

function CheckoutBill({ onClose, booking, serviceList, type }) {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const merchant = useMerchantInfo();
    const staffMapping = useStaffMapping();
    const serviceMapping = useServicesMapping();
    // const [giftCode, setGiftCode] = React.useState(null);
    const [isPaying, setIsPaying] = React.useState(false);
    const [gifts, setGifts] = React.useState([{ id: generateTempId(), amount: 0, giftCode: null, includedTax: true }]);
    const [isIncludedTax, setIsIncludedTax] = React.useState(true);
    const [isCheckoutGiftWithTax, setIsCheckoutGiftWithTax] = React.useState(getItem(CHECKOUT_GIFT_TAX) || false);
    const [extraBookingServices, setExtraBookingServices] = React.useState([]);
    const [tips, setTips] = React.useState([]);
    const staffOptions = useStaffOptions();
    const staffsServiceDuration = useStaffsServiceDuration();
    const isHasStaffServiceDurationPermission = useIsHavingPermission(MERCHANT_PERMISSIONS.STAFF_SERVICE_DURATION);
    const serviceOptions = useServiceOptions();
    const [isUsingPercent, setIsUsingPercent] = React.useState(true);
    const [discountPercent, setDiscountPercent] = React.useState(0);

    const handleAddNewGift = React.useCallback(() => {
        setGifts([...gifts, { id: generateTempId(), amount: 0, giftCode: null, includedTax: true }]);
    }, [gifts]);

    const giftsFormatted = React.useMemo(() => {
        return gifts?.map((gift) => ({
            ...gift,
            includedTax: isCheckoutGiftWithTax ? false : gift?.includedTax
        }));
    }, [gifts, isCheckoutGiftWithTax]);

    const handleRemoveGift = React.useCallback(
        (id) => {
            setGifts(gifts?.filter((gift) => gift?.id !== id));
        },
        [gifts]
    );

    const handleGiftChange = React.useCallback(
        ({ id, newGift }) => {
            setGifts(
                gifts?.map((gift) => {
                    if (gift?.id === id) {
                        return { ...gift, ...newGift };
                    }
                    return gift;
                })
            );
        },
        [gifts]
    );

    const totalGiftAmount = React.useMemo(() => {
        return giftsFormatted?.reduce((t, g) => t + (g?.amount || 0), 0);
    }, [giftsFormatted]);

    const totalGiftPaidTaxAmount = React.useMemo(() => {
        return giftsFormatted?.reduce((t, g) => t + (g?.includedTax ? g?.amount || 0 : 0), 0);
    }, [giftsFormatted]);

    const bookingServices = React.useMemo(() => {
        return booking?.bookingServices;
    }, [booking]);

    const totalBookingServices = React.useMemo(() => {
        const totalExBkSvs = extraBookingServices?.reduce((t, bkSv) => {
            const price = serviceMapping?.[bkSv?.serviceId]?.prices?.[0]?.retailPrice;

            return t + (price || 0);
        }, 0);

        const totalBkSvs = bookingServices?.reduce((t, bkSv) => {
            return t + (bkSv?.price || 0);
        }, 0);
        return totalExBkSvs + totalBkSvs;
    }, [bookingServices, serviceMapping, extraBookingServices]);

    const totalGiftServices = React.useMemo(() => {
        return booking?.retailPrice;
    }, [booking]);

    const bookingTotal = React.useMemo(() => {
        return type === CHECKOUT_TYPES.BOOKING ? totalBookingServices : totalGiftServices;
    }, [totalGiftServices, totalBookingServices, type]);

    const discountPercentCastNumber = useMemo(() => {
        return +discountPercent;
    }, [discountPercent]);

    const discountPercentCash = React.useMemo(() => {
        return merchant?.discountPercent;
    }, [merchant]);

    const discountAmount = React.useMemo(() => {
        return isUsingPercent ? (bookingTotal * discountPercentCastNumber) / 100 : discountPercentCastNumber;
    }, [bookingTotal, discountPercentCastNumber, isUsingPercent]);

    const payTotal = React.useMemo(() => {
        return bookingTotal - discountAmount;
    }, [bookingTotal, discountAmount]);

    const TAX_RATE = React.useMemo(() => {
        return isIncludedTax ? merchant?.taxRate : 0;
    }, [merchant, isIncludedTax]);

    const initCashPay = React.useMemo(() => {
        return Number(bookingTotal * (1 - discountPercentCash / 100) * (1 + TAX_RATE / 100))?.toFixed(3);
    }, [bookingTotal, discountPercentCash, TAX_RATE]);

    const [payments, setPayments] = React.useState({
        masterCard: 0,
        visa: 0,
        debit: 0,
        gift: 0,
        cash: initCashPay
    });

    const isValidRedeemInput = React.useMemo(() => {
        return !giftsFormatted?.some((gift) => Number(gift?.amount) > Number(gift?.giftCode?.remainingValue));
    }, [giftsFormatted]);

    const totalTip = React.useMemo(() => {
        return tips?.reduce((t, tip) => t + (+tip?.amount || 0), 0);
    }, [tips]);

    const actualPayment = React.useMemo(() => {
        return (
            (+payments?.masterCard || 0) +
            (+payments?.visa || 0) +
            (+payments?.debit || 0) +
            (+totalGiftAmount || 0) +
            (+payments?.cash || 0)
        );
    }, [payments, totalGiftAmount]);

    const showPayBy = React.useMemo(() => {
        return actualPayment > 0;
    }, [actualPayment]);

    const specialDiscountGift = React.useMemo(() => {
        // return (((+totalGiftPaidTaxAmount || 0) * SPECIAL_DISCOUNT_PERCENT) / 100)?.toFixed(3);
        return (((+totalGiftPaidTaxAmount || 0) * 0) / 100)?.toFixed(3);
    }, [totalGiftPaidTaxAmount]);

    const giftWithoutDiscount = React.useMemo(() => {
        return (+specialDiscountGift + (+totalGiftAmount || 0)).toFixed(3);
    }, [totalGiftAmount, specialDiscountGift]);

    const specialDiscountCash = React.useMemo(() => {
        if (+payments.cash !== 0) {
            const otherPays =
                (+payments?.masterCard || 0) +
                (+payments?.visa || 0) +
                (+payments?.debit || 0) +
                (+totalGiftAmount || 0);

            if (otherPays === 0) return ((+payments.cash * discountPercentCash) / 100).toFixed(3);

            const otherPaysWithoutTax = otherPays / (1 + TAX_RATE / 100);
            const cashWithoutTax = payTotal - otherPaysWithoutTax;
            return ((cashWithoutTax * discountPercentCash) / 100).toFixed(3);
        }
        return 0;
    }, [
        TAX_RATE,
        discountPercentCash,
        payTotal,
        payments.cash,
        payments.debit,
        payments.masterCard,
        payments.visa,
        totalGiftAmount
    ]);

    const totalDiscount = React.useMemo(() => {
        return (+specialDiscountGift + +specialDiscountCash).toFixed(3);
    }, [specialDiscountGift, specialDiscountCash]);

    const payPrice = React.useMemo(() => {
        return Number((payTotal - totalDiscount) * (1 + TAX_RATE / 100))?.toFixed(3);
    }, [payTotal, TAX_RATE, totalDiscount]);

    const lastPricePayment = React.useMemo(() => {
        // return (payPrice - +totalDiscount)?.toFixed(3);
        return payPrice;
    }, [payPrice]);

    const onPaymentValueChange = React.useCallback(
        ({ property, amount }) => {
            setPayments({
                ...payments,
                [property]: amount
            });
        },
        [payments]
    );

    const invalidPayment = React.useMemo(() => {
        const OFFSET_ALLOW = 0.2;
        const payment = actualPayment?.toFixed(3);
        return Math.abs(+payPrice - +payment) > OFFSET_ALLOW;
    }, [actualPayment, payPrice]);

    const suggestions = React.useMemo(() => {
        const suggests = [];
        if (!invalidPayment) return suggests;
    }, [invalidPayment]);

    const leftAmount = React.useMemo(() => {
        return (+lastPricePayment - actualPayment)?.toFixed(3);
    }, [lastPricePayment, actualPayment]);

    const leftCashAmount = React.useMemo(() => {
        const leftTaxAmount = leftAmount / (1 + TAX_RATE / 100);
        return Number(leftTaxAmount * (1 - discountPercentCash / 100) * (1 + TAX_RATE / 100)).toFixed(3);
    }, [TAX_RATE, discountPercentCash, leftAmount]);

    const handleCorrectSuggestion = React.useCallback(({ property, amount }) => {}, []);

    const handlePayment = React.useCallback(() => {
        let checkoutMethods = [];
        let checkoutStaff = [];
        const methodKeys = Object.keys(payments);
        let body = {
            amount: +lastPricePayment + totalTip,
            type,
            discount: discountAmount
        };

        if (type === CHECKOUT_TYPES.BOOKING) {
            body = {
                ...body,
                bookingId: +booking?.id,
                extraBookingServices: extraBookingServices?.map((exSv) => {
                    const defaultDuration =
                        serviceOptions.find((svOpt) => +exSv.serviceId === +svOpt.id)?.prices?.[0].duration ||
                        DEFAULT_DURATION;

                    return {
                        staffId: +exSv?.staffId,
                        serviceId: +exSv?.serviceId,
                        price: serviceMapping?.[+exSv?.serviceId]?.prices?.[0]?.retailPrice,
                        duration: isHasStaffServiceDurationPermission
                            ? staffsServiceDuration?.[exSv?.staffId]?.[exSv?.serviceId] || defaultDuration
                            : defaultDuration
                    };
                })
            };
        } else {
            body = {
                ...body,
                giftCodeId: +booking?.id
            };
        }

        for (const method of methodKeys) {
            const amount = +payments?.[method] || 0;

            if (amount > 0) {
                const paymentType = PAYMENT_TYPE_BY_LABEL?.[method];
                let paymentBody = { amount, paymentType };
                if (paymentType === PAYMENT_METHOD_TYPES.CASH) {
                    paymentBody = {
                        ...paymentBody,
                        specialDiscount: +specialDiscountCash
                    };
                }

                checkoutMethods.push(paymentBody);
            }
        }

        for (const tip of tips) {
            const amount = +tip?.amount || 0;
            const staffId = +tip?.staffId;
            if (amount > 0) {
                checkoutStaff.push({ amount, staffId });
            }
        }

        const validGifts = giftsFormatted?.filter((gift) => gift?.amount > 0);

        if (validGifts?.length) {
            const codes = validGifts?.map((gift) => ({
                id: +gift?.giftCode?.id,
                amount: gift?.amount
            }));

            const giftMethods = validGifts?.map((gift) => ({
                amount: gift?.amount,
                paymentType: gift?.includedTax ? PAYMENT_METHOD_TYPES.GIFT : PAYMENT_METHOD_TYPES.GIFT_TAX,
                specialDiscount: gift?.includedTax ? +specialDiscountGift : 0
            }));

            checkoutMethods = checkoutMethods.concat(giftMethods);

            body = {
                ...body,
                codes
            };
        }

        // if (!checkoutMethods?.length) return;

        body = {
            ...body,
            checkoutMethods,
            checkoutStaff
        };

        if (type === CHECKOUT_TYPES.GIFT) {
            body = {
                ...body,
                notIncludedTax: !isIncludedTax
            };
        }

        setIsPaying(true);

        let bkSvsByStaffId = {};
        const bookingServices = booking?.bookingServices || [];
        for (const bkSv of bookingServices) {
            const staffId = bkSv?.staffId;

            if (!bkSvsByStaffId?.[staffId]) {
                bkSvsByStaffId[staffId] = [];
            }

            bkSvsByStaffId = {
                ...bkSvsByStaffId,
                [staffId]: [...bkSvsByStaffId?.[staffId], +bkSv?.id]
            };
        }

        dispatch(
            calendarActions.checkout({
                type,
                giftCodeId: +booking?.id,
                bkSvsByStaffId,
                body,
                successCallback: () => {
                    console.log('checkout success');
                    setIsPaying(false);
                    onClose && onClose();
                },
                errorCallback: () => {
                    console.log('checkout failed');
                    setIsPaying(false);
                }
            })
        );
    }, [
        totalTip,
        payments,
        lastPricePayment,
        type,
        discountAmount,
        giftsFormatted,
        booking,
        dispatch,
        extraBookingServices,
        serviceOptions,
        serviceMapping,
        isHasStaffServiceDurationPermission,
        staffsServiceDuration,
        specialDiscountCash,
        tips,
        specialDiscountGift,
        isIncludedTax,
        onClose
    ]);

    const change = React.useMemo(() => {
        return +actualPayment - +lastPricePayment;
    }, [actualPayment, lastPricePayment]);

    const groupNames = React.useMemo(() => {
        const group = groupBy(bookingServices, 'staffId');
        return Object.keys(group)?.map((staffId) => {
            const items =
                group?.[staffId]?.map((i) => ({ ...i, serviceName: serviceMapping?.[+i?.serviceId]?.title })) || [];
            const staff = staffMapping?.[+staffId];

            return {
                staffName: getFullName(staff),
                total: items?.reduce((t, bkSv) => t + (+bkSv?.price || 0), 0),
                items
            };
        });
    }, [bookingServices, serviceMapping, staffMapping]);

    const handleAddMoreBkSv = React.useCallback(() => {
        const id = generateTempId();
        const staffId = staffOptions?.[0]?.value;
        setExtraBookingServices([...extraBookingServices, { id, staffId }]);
    }, [extraBookingServices, staffOptions]);

    const handleStaffChange = React.useCallback(
        ({ id, staffId }) => {
            setExtraBookingServices(
                extraBookingServices?.map((bkEx) => {
                    if (bkEx?.id === id) return { ...bkEx, staffId };
                    return bkEx;
                })
            );
        },
        [extraBookingServices]
    );

    const handleServiceChange = React.useCallback(
        ({ id, serviceId }) => {
            setExtraBookingServices(
                extraBookingServices?.map((bkEx) => {
                    if (bkEx?.id === id) return { ...bkEx, serviceId };
                    return bkEx;
                })
            );
        },
        [extraBookingServices]
    );

    const handleDelete = React.useCallback(
        (id) => {
            setExtraBookingServices(extraBookingServices?.filter((bkEx) => bkEx?.id !== id));
        },
        [extraBookingServices]
    );

    const handleAddMoreTip = React.useCallback(() => {
        const staffId = staffOptions?.[0]?.value;
        setTips([...tips, { id: generateTempId(), amount: 0, staffId }]);
    }, [staffOptions, tips]);

    const handleTipChange = React.useCallback(
        ({ id, newTip }) => {
            setTips(
                tips?.map((tip) => {
                    if (tip?.id === id) return { ...tip, ...newTip };
                    return tip;
                })
            );
        },
        [tips]
    );

    const handleDeleteTip = React.useCallback(
        (id) => {
            setTips(tips?.filter((tip) => tip?.id !== id));
        },
        [tips]
    );

    const isNotValidExtraService = React.useMemo(() => {
        return extraBookingServices?.some((exSv) => !exSv?.staffId || !exSv?.serviceId);
    }, [extraBookingServices]);

    return (
        <>
            <DialogTitle onClose={onClose}>
                <Grid container direction="row" alignItems="center" spacing={2}>
                    <Grid item>
                        <Typography className={classes.whiteColor} variant="h5">
                            Checkout detail:
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.checkoutClient} variant="body2">
                            {getFullName(booking?.client) || booking?.title || 'Gift'}
                        </Typography>
                    </Grid>
                    {type === CHECKOUT_TYPES?.GIFT && (
                        <Grid item>
                            <Grid container direction="row" alignItems="center" spacing={2}>
                                <Grid item>
                                    <IOSSwitcher
                                        onChange={(e) => setIsIncludedTax(e?.target?.checked)}
                                        checked={isIncludedTax}
                                        light
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.whiteColor} gutterBottom variant="body2">
                                        Turn on to collect tax
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </DialogTitle>
            <DialogContent className={classes.root}>
                <Grid container direction="row">
                    <Grid item className={classes.cards}>
                        <Grid container direction="column" className={classes.mb3}>
                            <Grid item className={`${classes.card} ${+payments?.cash > 0 ? classes.selected : ``}`}>
                                <Grid container direction="row">
                                    <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center' }}>
                                        <CashSVG width={150} height={102} />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Grid container direction="row" alignItems="center">
                                            <Grid item xs={+payments?.cash > 0 ? 11 : 12} style={{ flex: 1 }}>
                                                <InputBase
                                                    label={'Enter Amount'}
                                                    name="visa"
                                                    value={payments.cash || ''}
                                                    disabled={isPaying}
                                                    onChange={(e) =>
                                                        onPaymentValueChange({
                                                            property: 'cash',
                                                            amount: e.target.value
                                                        })
                                                    }
                                                    className={classes.inputText}
                                                    type="number"
                                                    step="any"
                                                />
                                            </Grid>
                                            {+payments?.cash > 0 && (
                                                <Grid
                                                    xs={1}
                                                    item
                                                    style={{
                                                        display: 'flex',
                                                        position: 'relative',
                                                        top: 5,
                                                        right: -5,
                                                        cursor: 'pointer'
                                                    }}
                                                >
                                                    <IconButton
                                                        size="small"
                                                        onClick={() =>
                                                            setPayments({
                                                                ...payments,
                                                                cash: 0
                                                            })
                                                        }
                                                    >
                                                        <CloseIcon fontSize="small" />
                                                    </IconButton>
                                                </Grid>
                                            )}

                                            {+leftCashAmount > 0 && (+payments?.cash || 0) === 0 && (
                                                <Grid
                                                    xs={12}
                                                    item
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'flex-end'
                                                    }}
                                                >
                                                    <Button
                                                        className={classes.highlight}
                                                        onClick={() =>
                                                            setPayments({
                                                                ...payments,
                                                                cash: +leftCashAmount
                                                            })
                                                        }
                                                        size="small"
                                                        variant="contained"
                                                    >
                                                        Choose value: ${leftCashAmount}
                                                    </Button>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* <Grid container direction="column" className={classes.mb3}>
                            <Grid item className={`${classes.card} ${+payments?.visa > 0 ? classes.selected : ``}`}>
                                <Grid container direction="row" alignItems="center">
                                    <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center' }}>
                                        <VisaSVG width={150} height={102} />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Grid container direction="column">
                                            <Grid item>
                                                <InputBase
                                                    label={'Enter Amount'}
                                                    name="visa"
                                                    value={payments.visa || ''}
                                                    disabled={isPaying}
                                                    onChange={(e) =>
                                                        onPaymentValueChange({
                                                            property: 'visa',
                                                            amount: e.target.value
                                                        })
                                                    }
                                                    className={classes.inputText}
                                                    type="number"
                                                    step="any"
                                                />
                                            </Grid>
                                            {+leftAmount > 0 && (+payments?.visa || 0) === 0 && (
                                                <Grid
                                                    item
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'flex-end'
                                                    }}
                                                >
                                                    <Button
                                                        className={classes.highlight}
                                                        onClick={() =>
                                                            setPayments({
                                                                ...payments,
                                                                visa: +leftAmount
                                                            })
                                                        }
                                                        size="small"
                                                        variant="contained"
                                                    >
                                                        Choose value: ${leftAmount}
                                                    </Button>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid> */}
                        <Grid container direction="column" className={classes.mb3}>
                            <Grid item className={`${classes.card} ${+payments?.debit > 0 ? classes.selected : ``}`}>
                                <Grid container direction="row" alignItems="center">
                                    <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center' }}>
                                        <DebitSVG width={150} height={102} />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Grid container direction="column">
                                            <Grid item>
                                                <InputBase
                                                    label={'Enter Amount'}
                                                    name="debit"
                                                    value={payments.debit || ''}
                                                    disabled={isPaying}
                                                    onChange={(e) =>
                                                        onPaymentValueChange({
                                                            property: 'debit',
                                                            amount: e.target.value
                                                        })
                                                    }
                                                    className={classes.inputText}
                                                    type="number"
                                                    step="any"
                                                />
                                            </Grid>
                                            {+leftAmount > 0 && (+payments?.debit || 0) === 0 && (
                                                <Grid
                                                    item
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'flex-end'
                                                    }}
                                                >
                                                    <Button
                                                        className={classes.highlight}
                                                        onClick={() =>
                                                            setPayments({
                                                                ...payments,
                                                                debit: +leftAmount
                                                            })
                                                        }
                                                        size="small"
                                                        variant="contained"
                                                    >
                                                        Choose value: ${leftAmount}
                                                    </Button>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <Grid container direction="column" className={classes.mb3}>
                            <Grid
                                item
                                className={`${classes.card} ${+payments?.masterCard > 0 ? classes.selected : ``}`}
                            >
                                <Grid container direction="row" alignItems="center">
                                    <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center' }}>
                                        <MasterCard width={120} />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Grid container direction="column">
                                            <Grid item>
                                                <InputBase
                                                    label={'Enter Amount'}
                                                    name="masterCard"
                                                    value={payments.masterCard || ''}
                                                    disabled={isPaying}
                                                    onChange={(e) =>
                                                        onPaymentValueChange({
                                                            property: 'masterCard',
                                                            amount: e.target.value
                                                        })
                                                    }
                                                    className={classes.inputText}
                                                    type="number"
                                                    step="any"
                                                />
                                            </Grid>
                                            {+leftAmount > 0 && (+payments?.masterCard || 0) === 0 && (
                                                <Grid
                                                    item
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'flex-end'
                                                    }}
                                                >
                                                    <Button
                                                        className={classes.highlight}
                                                        onClick={() =>
                                                            setPayments({
                                                                ...payments,
                                                                masterCard: +leftAmount
                                                            })
                                                        }
                                                        size="small"
                                                        variant="contained"
                                                    >
                                                        Choose value: ${leftAmount}
                                                    </Button>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid> */}

                        {type === CHECKOUT_TYPES.BOOKING && (
                            <Grid item className={classes.mb2}>
                                <Grid container direction="row" alignItems="center" spacing={2}>
                                    <Grid item>
                                        <IOSSwitcher
                                            onChange={(e) => {
                                                setIsCheckoutGiftWithTax(e?.target?.checked);
                                                setItem(CHECKOUT_GIFT_TAX, e?.target?.checked);
                                            }}
                                            checked={isCheckoutGiftWithTax}
                                            light
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2">Turn on to collect tax</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        {type === CHECKOUT_TYPES.BOOKING && (
                            <Grid container direction="column" className={classes.mb3}>
                                {giftsFormatted?.map((gift) => {
                                    return (
                                        <Grid key={gift?.id} item className={classes.mb2}>
                                            <GiftBox
                                                onDelete={handleRemoveGift}
                                                showDelete={giftsFormatted?.length > 1}
                                                {...gift}
                                                onUpdate={handleGiftChange}
                                            />
                                        </Grid>
                                    );
                                })}
                                <Grid item container direction="column" alignItems="flex-end">
                                    <Typography variant="body2" onClick={handleAddNewGift} className={classes.add}>
                                        Add more gift method
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}

                        <Grid container direction="column" className={classes.mb3}>
                            <h3>Tips: </h3>
                            {tips?.map((tip) => {
                                return (
                                    <Grid key={tip?.id} item className={classes.mb2}>
                                        <TipBox {...tip} onDelete={handleDeleteTip} onUpdate={handleTipChange} />
                                    </Grid>
                                );
                            })}
                            <Grid item container direction="column" alignItems="flex-end">
                                <Typography variant="body2" onClick={handleAddMoreTip} className={classes.add}>
                                    Add tips
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.sidebar}>
                        <Grid container direction="column">
                            <Grid item className={classes.mb3}>
                                <Typography style={{ color: theme.colors.primaryText }} variant="h5">
                                    Discount
                                </Typography>
                            </Grid>

                            <Grid item className={classes.mb3}>
                                <Grid container direction="column" spacing={2}>
                                    <Grid item>
                                        <Grid container direction="row" alignItems="center" spacing={2}>
                                            <Grid item>
                                                <IOSSwitcher
                                                    onChange={(e) => {
                                                        const usingPercent = e?.target?.checked;
                                                        setDiscountPercent(0);
                                                        setIsUsingPercent(usingPercent);
                                                    }}
                                                    checked={isUsingPercent}
                                                    light
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Typography gutterBottom variant="body2">
                                                    Using percent
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item>
                                        <Grid container spacing={2} direction="row">
                                            {(isUsingPercent ? PERCENT_OPTIONS : AMOUNT_OPTIONS).map((percent) => {
                                                return (
                                                    <Grid
                                                        onClick={() => setDiscountPercent(percent)}
                                                        className={classes.discountItem}
                                                        key={percent}
                                                        item
                                                        xs={3}
                                                    >
                                                        {!isUsingPercent && merchant.currency} {percent}{' '}
                                                        {isUsingPercent && `%`}
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <InputBase
                                            label={`Discount ${isUsingPercent ? `Percent` : `Amount`}`}
                                            name="discount"
                                            value={discountPercent || ''}
                                            disabled={isPaying}
                                            onChange={(e) => setDiscountPercent(e.target?.value)}
                                            className={classes.inputText}
                                            type="number"
                                            step="any"
                                            max={100}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item className={classes.mb3}>
                                <Typography style={{ color: theme.colors.primaryText }} variant="h5">
                                    Bill Details
                                </Typography>
                            </Grid>
                            {type === CHECKOUT_TYPES.GIFT && (
                                <Grid item className={classes.mb2}>
                                    <Grid
                                        container
                                        wrap="nowrap"
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="space-between"
                                    >
                                        <Grid item style={{ marginRight: theme.spacing(2) }}>
                                            <Typography variant="body2">Sold a gift</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body2">${booking?.retailPrice}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                            {type === CHECKOUT_TYPES.BOOKING &&
                                groupNames?.map((group, index) => {
                                    const items = group?.items;
                                    const total = group?.total;
                                    const staffName = group?.staffName;

                                    return (
                                        <Grid item key={index} className={classes.mb2}>
                                            <Grid container wrap="nowrap" direction="column" spacing={1}>
                                                <Grid item>
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        alignItems="center"
                                                        justifyContent="space-between"
                                                    >
                                                        <Grid item>
                                                            <Typography variant="body1">{staffName}</Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="body1">
                                                                {merchant?.currency}
                                                                {total}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    <Grid container direction="column">
                                                        {items?.map((item) => {
                                                            return (
                                                                <Grid
                                                                    key={item?.id}
                                                                    container
                                                                    direction="row"
                                                                    alignItems="center"
                                                                    justifyContent="space-between"
                                                                >
                                                                    <Grid item>
                                                                        <Typography variant="caption">
                                                                            {item?.serviceName}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Typography variant="caption">
                                                                            {merchant?.currency}
                                                                            {item?.price}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            );
                                                        })}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    );
                                })}

                            {type === CHECKOUT_TYPES.BOOKING && (
                                <>
                                    {extraBookingServices?.length > 0 && (
                                        <Grid item className={classes.divider}>
                                            <Divider />
                                        </Grid>
                                    )}

                                    <Grid item>
                                        <Grid container direction="column" spacing={3}>
                                            {extraBookingServices?.length > 0 &&
                                                extraBookingServices?.map((bkSv) => {
                                                    return (
                                                        <Grid
                                                            item
                                                            key={bkSv?.id}
                                                            className={`${classes.bkSv} ${
                                                                !bkSv?.serviceId && classes.isNotValid
                                                            }`}
                                                        >
                                                            <BookingService
                                                                {...bkSv}
                                                                onStaffChange={handleStaffChange}
                                                                onServiceChange={handleServiceChange}
                                                                onServiceDelete={handleDelete}
                                                            />
                                                        </Grid>
                                                    );
                                                })}
                                            <Grid item alignItems="flex-end">
                                                <Button onClick={handleAddMoreBkSv} color="primary" variant="contained">
                                                    Add more service
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>
                            )}

                            <Grid item className={classes.divider}>
                                <Divider />
                            </Grid>
                            <Grid item className={classes.mb2}>
                                <Grid container direction="row" alignItems="center" justifyContent="space-between">
                                    <Grid item>
                                        <Typography variant="body2">Total Services:</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h4">${bookingTotal?.toFixed(3)}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.mb2}>
                                <Grid container direction="row" alignItems="center" justifyContent="space-between">
                                    <Grid item>
                                        <Typography variant="body2">Discount:</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h4">- ${discountAmount?.toFixed(3)}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {specialDiscountCash > 0 && (
                                <>
                                    <Grid item className={classes.divider}>
                                        <Divider />
                                    </Grid>

                                    <Grid item className={classes.mb2}>
                                        <Grid
                                            container
                                            direction="row"
                                            alignItems="center"
                                            justifyContent="space-between"
                                        >
                                            <Grid item>
                                                <Typography variant="body2">Special Discount</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="h4">- ${specialDiscountCash}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>
                            )}

                            <Grid item className={classes.divider}>
                                <Divider />
                            </Grid>

                            <Grid item className={classes.mb2}>
                                <Grid container direction="row" alignItems="center" justifyContent="space-between">
                                    <Grid item>
                                        <Typography variant="body2">HST</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2">{merchant?.taxRate}%</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.divider}>
                                <Divider />
                            </Grid>

                            <Grid item className={classes.mb2}>
                                <Grid container direction="row" alignItems="center" justifyContent="space-between">
                                    <Grid item>
                                        <Typography variant="body2">Sub Total</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2">${payPrice}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item className={classes.mb2}>
                                <Grid container direction="row" alignItems="center" justifyContent="space-between">
                                    <Grid item>
                                        <Typography variant="body2">Tips</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2">${totalTip}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item className={classes.divider}>
                                <Divider />
                            </Grid>

                            <Grid item className={classes.mb2}>
                                <Grid container direction="row" alignItems="center" justifyContent="space-between">
                                    <Grid item>
                                        <Typography variant="h4">Total</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2" className={classes.total}>
                                            ${+lastPricePayment + totalTip}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {showPayBy && (
                                <>
                                    <Grid item className={classes.divider}>
                                        <Divider />
                                    </Grid>

                                    <Grid item className={classes.mb2}>
                                        <Grid
                                            container
                                            direction="row"
                                            alignItems="center"
                                            justifyContent="space-between"
                                        >
                                            <Grid item>
                                                <Typography variant="h4">Paid By</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>
                            )}

                            {(+payments?.cash || 0) > 0 && (
                                <Grid item className={classes.mb3}>
                                    <Grid container direction="row" alignItems="center" justifyContent="space-between">
                                        <Grid item>
                                            <Typography variant="body2">
                                                <CashSVG width={50} height={40} />
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography display="inline" variant="body2">
                                                ${(+payments?.cash || 0)?.toFixed(3)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}

                            {(+totalGiftAmount || 0) > 0 && (
                                <Grid item className={classes.mb3}>
                                    <Grid container direction="row" alignItems="center" justifyContent="space-between">
                                        <Grid item>
                                            <Typography variant="body2">
                                                <GiftPaymentSVG width={50} height={40} />
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                display="inline"
                                                className={classes.deleted}
                                                variant="subtitle2"
                                            >
                                                ${giftWithoutDiscount}
                                            </Typography>{' '}
                                            <Typography display="inline" variant="body2">
                                                ${(+totalGiftAmount || 0)?.toFixed(3)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}

                            {/* {(+payments?.masterCard || 0) > 0 && (
                                <Grid item className={classes.mb2}>
                                    <Grid container direction="row" alignItems="center" justifyContent="space-between">
                                        <Grid item>
                                            <Typography variant="body2">
                                                <MasterCard width={50} height={40} />
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.f18} variant="body2">
                                                ${(+payments?.masterCard || 0)?.toFixed(3)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )} */}

                            {/* {(+payments?.visa || 0) > 0 && (
                                <Grid item className={classes.mb2}>
                                    <Grid container direction="row" alignItems="center" justifyContent="space-between">
                                        <Grid item>
                                            <VisaSVG width={50} height={40} />
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.f18} variant="body2">
                                                ${(+payments?.visa || 0)?.toFixed(3)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )} */}

                            {(+payments?.debit || 0) > 0 && (
                                <Grid item className={classes.mb3}>
                                    <Grid container direction="row" alignItems="center" justifyContent="space-between">
                                        <Grid item>
                                            <Typography variant="body2">
                                                <DebitSVG width={50} height={40} />
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.f18} variant="body2">
                                                ${(+payments?.debit || 0)?.toFixed(3)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}

                            {(+totalTip || 0) > 0 && (
                                <Grid item className={classes.mb3}>
                                    <Grid container direction="row" alignItems="center" justifyContent="space-between">
                                        <Grid item>
                                            <Typography variant="body2">Tips:</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.f18} variant="body2">
                                                ${totalTip?.toFixed(3)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}

                            <Grid item>
                                {suggestions?.length > 0 &&
                                    suggestions?.map((suggestion) => {
                                        return (
                                            <Typography
                                                key={suggestion?.message}
                                                className={classes.suggestion}
                                                align="center"
                                                style={{ marginBottom: theme.spacing(1) }}
                                                variant="body2"
                                                onClick={() =>
                                                    handleCorrectSuggestion({
                                                        property: suggestion?.property,
                                                        amount: suggestion?.amount
                                                    })
                                                }
                                            >
                                                {suggestion?.message}
                                            </Typography>
                                        );
                                    })}

                                {invalidPayment && (
                                    <Typography
                                        className={classes.warning}
                                        style={{ marginBottom: theme.spacing(1) }}
                                        variant="body2"
                                    >
                                        {change > 0
                                            ? `Change $${change?.toFixed(3)}`
                                            : `Not Enough payment (-$${(change * -1)?.toFixed(3)})`}
                                    </Typography>
                                )}

                                <Grid item>
                                    <div className={classes.btnSubmit}>
                                        <Button
                                            fullWidth
                                            type="submit"
                                            color="primary"
                                            variant="contained"
                                            disabled={
                                                invalidPayment ||
                                                isPaying ||
                                                (giftsFormatted?.length && !isValidRedeemInput) ||
                                                isNotValidExtraService
                                            }
                                            className={classes.input}
                                            onClick={handlePayment}
                                        >
                                            Pay
                                        </Button>
                                        {isPaying && <CircularProgress size={24} className={classes.buttonProgress} />}
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </>
    );
}

export default CheckoutBill;
