import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, IconButton } from '@material-ui/core';
import { useFormik } from 'formik';
import { string, object } from 'yup';
import InputBase from 'components/input/Form';
import { useTranslation } from 'react-i18next';
import NativeSelect from 'components/select/NativeForm';
import { timeOptions } from 'data/time';
import { useStaffOptions } from 'hooks/staff';
import { getDatetimeFormat, convertTimeWithDurationToTime, mapTimeToTime12, convertTimeToNumRange } from 'utils/timing';
import moment from 'moment-timezone';
import * as calendarActions from 'views/calender/actions';
import { useDispatch } from 'react-redux';
import PortalDatePicker from 'components/datepicker/PortalMaterialDatePicker';
import DialogContent from 'components/dialog/Content';
import DialogActions from 'components/dialog/Action';
import DialogTitle from 'components/dialog/Title';
import SaveIcon from '@material-ui/icons/Save';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from 'components/button/Base';
import { useSelectedDate } from 'hooks/calendar';
import { useMerchantTime12 } from 'hooks/merchant';

const FOOTER_HEIGHT = 80;
const MAX_HEIGHT = 500;

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 400,
        position: 'relative',
        borderRadius: 0,
        background: theme.palette.common.white,
        boxShadow: theme.shadows[1],
        overflowX: 'hidden',
        overflowY: 'hidden'
    },
    closeIcon: {
        position: 'absolute',
        top: 10,
        right: 10,
        cursor: 'pointer'
    },

    content: {
        height: `calc(${MAX_HEIGHT}px - ${FOOTER_HEIGHT}px - 40px)`,
        overflowY: 'auto',
        overflowX: 'hidden',
        [theme.breakpoints.down('xs')]: {
            height: `calc(100vh - ${FOOTER_HEIGHT}px - 40px)`,
            maxHeight: `calc(100vh - ${FOOTER_HEIGHT}px - 40px)`
        }
    },

    footer: {
        height: FOOTER_HEIGHT,
        borderTop: `1px solid ${theme.colors.border}`
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },

    spacing: {
        paddingBottom: 12
    },
    padding: {
        paddingLeft: `20px !important`,
        paddingRight: `20px !important`,
        [theme.breakpoints.down('xs')]: {
            paddingLeft: `0px !important`,
            paddingRight: `0px !important`
        }
    },
    title: {
        paddingLeft: 20,
        paddingRight: 20
    },
    fixedBottom: {
        position: 'fixed',
        bottom: 0,
        width: '100%',
        left: 0,
        background: theme.palette.common.white
    },
    contentPadding: {
        paddingBottom: FOOTER_HEIGHT
    },
    icon: {
        width: 40,
        height: 40
    },
    item: {
        cursor: 'pointer',
        position: 'relative'
    },
    disabled: {
        opacity: 0.4
    },
    whiteColor: {
        color: theme.palette.common.white
    }
}));

const BookingSchema = object({
    description: string()
});

function getSelectedStartDate(blockedTime) {
    if (!blockedTime.dateBlocked) {
        return new Date();
    }
    return moment(blockedTime?.dateBlocked);
}

function AddBlockedTime({
    staff,
    time,
    onClose,
    onUpdateOrCreatingSuccess,
    isEdit,
    blockedTime,
    isDialog,
    minutes,
    classContent
}) {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const selectedDate = useSelectedDate();
    const TIME_12 = useMerchantTime12();

    const [dateBlocked, setDateBlocked] = React.useState(isEdit ? getSelectedStartDate(blockedTime) : selectedDate);
    const [isDeleting, setIsDeleting] = React.useState(false);
    const [staffId, setStaffId] = React.useState(isEdit ? blockedTime?.staffId : staff?.id);
    const [startTime, setStartTime] = React.useState(isEdit ? blockedTime?.slot?.timeStart : time);

    const [endTime, setEndTime] = React.useState(
        isEdit ? blockedTime?.slot?.timeEnd : convertTimeWithDurationToTime(time, minutes)
    );

    const staffOptions = useStaffOptions();
    const dispatch = useDispatch();

    const timeOptionsWithFormat = React.useMemo(() => {
        return TIME_12
            ? timeOptions.map((time) => {
                  return {
                      ...time,
                      label: mapTimeToTime12(time.value)
                  };
              })
            : timeOptions;
    }, [TIME_12]);

    const formik = useFormik({
        initialValues: {
            description: isEdit ? blockedTime.description : '' || ''
        },
        validationSchema: BookingSchema,
        onSubmit: (values, { setSubmitting, setStatus }) => {
            setStatus({ error: null });

            if (!isEdit) {
                let createBody = {
                    ...values,
                    dateBlocked: moment(`${getDatetimeFormat(dateBlocked)} ${time}`)
                        .utc()
                        .format(),
                    staffId: Number(staffId),
                    slot: convertTimeToNumRange({ startTime, endTime })
                };

                setSubmitting(true);
                // return;
                dispatch(
                    calendarActions.createBlockedTime({
                        body: createBody,
                        successCallback: () => {
                            onUpdateOrCreatingSuccess && onUpdateOrCreatingSuccess();
                            onClose && onClose();
                        },
                        errorCallback: () => {
                            console.log('create booking failed');
                            setSubmitting(false);
                        }
                    })
                );

                setStatus({ error: null });
            } else {
                const editBody = {
                    ...values,
                    dateBlocked: moment(`${getDatetimeFormat(dateBlocked)} ${startTime}`)
                        .utc()
                        .format(),
                    staffId: Number(staffId),
                    slot: convertTimeToNumRange({ startTime, endTime })
                };

                // return;
                setSubmitting(true);

                dispatch(
                    calendarActions.updateBlockedTime({
                        id: blockedTime.id,
                        body: editBody,
                        successCallback: () => {
                            console.log('update booking sucsces');
                            onUpdateOrCreatingSuccess && onUpdateOrCreatingSuccess();
                            onClose && onClose();
                        },
                        errorCallback: () => {
                            console.log('update booking failed');
                            setSubmitting(false);
                        }
                    })
                );

                setStatus({ error: null });
            }
        }
    });

    const handleDeleteBlockedTime = () => {
        setIsDeleting(true);
        dispatch(
            calendarActions.deleteBlockedTime({
                id: blockedTime.id,

                successCallback: () => {
                    onUpdateOrCreatingSuccess && onUpdateOrCreatingSuccess();
                    onClose && onClose();
                },
                errorCallback: () => {
                    setIsDeleting(false);
                    console.log('delete blockedTime failed');
                }
            })
        );
    };

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting } = formik;

    const onDateChange = (date) => {
        setDateBlocked(date);
    };

    const handleStaffChange = (e) => {
        setStaffId(e.target.value);
    };

    const handleStartTimeChange = (e) => {
        setStartTime(e.target.value);
    };

    const handleEndTimeChange = (e) => {
        setEndTime(e.target.value);
    };

    return (
        <form onSubmit={handleSubmit} className={!isDialog ? classes.root : ''}>
            {!isDialog && (
                <IconButton size="small" onClick={onClose} className={classes.closeIcon}>
                    <CloseIcon style={{ color: theme.palette.common.white }} />
                </IconButton>
            )}
            <DialogTitle>
                <Typography className={classes.whiteColor} variant="h5">
                    {isEdit ? t(`update`) : t(`add`)} {t(`blocked_time`)}
                </Typography>
            </DialogTitle>
            <DialogContent
                className={`${isDialog ? classes.contentPadding : ``} ${classes.content} ${
                    classes.padding
                } ${classContent}`}
            >
                <Grid
                    container
                    direction="column"
                    wrap="nowrap"
                    style={{
                        paddingLeft: isDialog ? 20 : '',
                        paddingRight: isDialog ? 20 : ''
                    }}
                >
                    <Grid item className={classes.spacing}>
                        <PortalDatePicker onChange={onDateChange} defaultValue={dateBlocked} label={t(`date`)} />
                    </Grid>
                    <Grid item className={classes.spacing}>
                        <NativeSelect
                            onChange={handleStaffChange}
                            selectedValue={staffId}
                            options={staffOptions}
                            label={t(`staff`)}
                            placeholder={`Choose a staff`}
                        />
                    </Grid>
                    <Grid item className={classes.spacing}>
                        <Grid container spacing={2} justifyContent="space-between">
                            <Grid item style={{ flex: 1 }}>
                                <NativeSelect
                                    onChange={handleStartTimeChange}
                                    selectedValue={startTime}
                                    options={timeOptionsWithFormat}
                                    label={t(`start_time`)}
                                    placeholder={`Choose start time`}
                                />
                            </Grid>
                            <Grid item style={{ flex: 1 }}>
                                <NativeSelect
                                    onChange={handleEndTimeChange}
                                    selectedValue={endTime}
                                    options={timeOptionsWithFormat}
                                    label={t(`end_time`)}
                                    placeholder={`Choose end times`}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.spacing}>
                        <InputBase
                            fullWidth
                            multiline
                            className={classes.input}
                            label={t('description')}
                            placeholder={t('blocked_time_placeholder')}
                            name="description"
                            value={values.description || ''}
                            disabled={isSubmitting}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.description && touched.description}
                            errorText={errors.description}
                            isShowChecked={false}
                            autoFocus={true}
                        />
                    </Grid>
                </Grid>
                {/* </SimpleBar> */}
            </DialogContent>

            <DialogActions>
                <Grid container direction={'row'} alignItems="center" justifyContent="flex-end" spacing={2}>
                    {isEdit ? (
                        <Grid item>
                            <Button
                                disable={isDeleting}
                                onClick={handleDeleteBlockedTime}
                                startIcon={<DeleteIcon style={{ color: theme.palette.common.white }} />}
                                variant="contained"
                                color="secondary"
                            >
                                {t(`delete`)}
                            </Button>
                        </Grid>
                    ) : (
                        <Grid item>
                            <Button disable={isDeleting} onClick={onClose} startIcon={<CloseIcon />} variant="outlined">
                                {t(`close`)}
                            </Button>
                        </Grid>
                    )}

                    <Grid item>
                        <Button
                            startIcon={isEdit ? <SaveIcon /> : <AddCircleIcon />}
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={isSubmitting}
                        >
                            {isEdit ? t(`save`) : t(`add`)}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </form>
    );
}

export default React.memo(AddBlockedTime);
