import request from './request';
import { objectToQueryParams } from 'utils/queryParams';

const serviceApis = {
    services: '/services',
    service: (id) => `/services/${id}`,
    upload: (serviceId) => `/services/${serviceId}/avatar/upload`,
    reorder: `/services/reorder`,
    import: (merchantId) => `/services/import/${merchantId}`
};

export async function createServiceApi({ body }) {
    const object = {
        $eager: `[prices,staffs]`
    };

    const params = objectToQueryParams(object);
    const response = await request.post(`${serviceApis.services}?${params}`, body);
    return response.data;
}

export async function uploadAvatarApi({ file, serviceId }) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    const response = await request.put(serviceApis.upload(serviceId), formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
}

export async function getServicesApi() {
    const object = {
        relations: `prices,serviceStaffs,category`
    };

    const params = objectToQueryParams(object);
    const response = await request.get(`${serviceApis.services}?${params}`);
    return response.data;
}

export async function updateServiceApi({ id, body }) {
    const object = {
        $eager: `[prices,staffs]`
    };

    const params = objectToQueryParams(object);
    const response = await request.put(`${serviceApis.service(id)}?${params}`, body);
    return response.data;
}

export async function deleteServiceApi({ id }) {
    const response = await request.delete(serviceApis.service(id));
    return response.data;
}

export async function reOrderServiceApi(body) {
    const response = await request.post(`${serviceApis.reorder}`, body);
    return response.data;
}

export async function importService({ file, merchantId }) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    const response = await request.post(serviceApis.import(merchantId), formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
}
