import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Grid, useMediaQuery, Dialog } from '@material-ui/core';
import VoucherNewPV from './VoucherNewPV';
import VoucherDetail from './voucherDetail';
import AddVoucher from 'views/voucher/addVoucher';

function VoucherItem(voucher) {
    const theme = useTheme();
    const [isOpenPreviewDialog, setIsOpenPreviewDialog] = React.useState(false);
    const [isOpenEditGiftDialog, setIsOpenEditGiftDialog] = React.useState(false);

    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleOpenPreviewDialog = () => setIsOpenPreviewDialog(true);
    const handleClosePreviewDialog = (e) => {
        stopPropagation(e);
        setIsOpenPreviewDialog(false);
    };

    const handleOpenEditGiftDialog = () => setIsOpenEditGiftDialog(true);

    const handleCloseEditGiftDialog = (e) => {
        stopPropagation(e);
        setIsOpenEditGiftDialog(false);
    };

    const stopPropagation = (e) => {
        e && e.preventDefault();
        e && e.stopPropagation();
    };

    const handleEdit = (e) => {
        stopPropagation(e);
        handleClosePreviewDialog();
        handleOpenEditGiftDialog();
    };

    return (
        <Grid onClick={handleOpenPreviewDialog} container justifyContent="center" alignItems="center">
            <Grid item>
                <VoucherNewPV voucher={voucher}></VoucherNewPV>
            </Grid>
            <Dialog
                fullScreen={fullScreen}
                maxWidth={'lg'}
                padding={0}
                open={isOpenPreviewDialog}
                onClose={handleClosePreviewDialog}
            >
                <VoucherDetail onClose={handleClosePreviewDialog} handleEdit={handleEdit} voucher={voucher} />
            </Dialog>
            <Dialog
                fullScreen={true}
                maxWidth={'lg'}
                padding={0}
                open={isOpenEditGiftDialog}
                onClose={handleCloseEditGiftDialog}
            >
                <AddVoucher isEdit={true} voucherEdit={voucher} onClose={handleCloseEditGiftDialog} />
            </Dialog>
        </Grid>
    );
}

export default React.memo(VoucherItem);
